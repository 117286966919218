import { IColumnFilter, IFilter } from "@/types";
import { translateDebtorStatus } from "@/utils/translate";
import { getActiveUserSearch } from "@/api/user";
import { CreatedFilters } from "@/services/filters/filters";
import projects from "@/mappings/projects";
import {
    getAdvertisersSearch,
    getAdvertiserTags,
    getAllContractors,
    getInvoiceOtherServices,
    getLegalEntitySearch,
} from "@/api/revenue";
import { IOtherService, ITag } from "@/api/types/revenue";
import { getLastDayOfCurrentMonth, getPreviousMonth } from "@/utils/formatDates";
import { filterClass } from '@/services/filters/filterClass';

export class DebtorsFilters  extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Datepicker({
                col: 1,
                id: 'created',
                pickerType: 'date',
                select: [],
                label: 'Дата создания',
                range: true,
                clearable: true,
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'payment_date',
                datepicker: true,
                pickerType: 'date',
                select: [],
                label: 'Дата оплаты',
                range: true,
                clearable: true,
            }),
            new filterClass.ComboboxArray({
                col: 1,
                id: 'external_ids',
                label: 'Номер',
                select: [],
                array: true,
            }),
            new filterClass.ComboboxArray({
                col: 1,
                id: 'ids',
                label: 'ID CRM',
                select: [],
                array: true,
            }),
            new filterClass.Multiselect({
                col: 1,
                id: 'projects',
                label: 'Проекты',
                select: [],
                itemValue: 'abbr',
                type: 'string',
                items: projects,
                template: (item: any): string => item.name || item.service_name || `${item.abbr} - ${item.text}`,
            }),
            new filterClass.Multiselect({
                col: 1,
                id: 'currencies',
                label: 'Валюта',
                select: [],
                items: [],
                type: 'string',
                itemValue: 'code',
                itemText: 'code',
                isCurrencyCatalogue: true,
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'period',
                label: 'Период',
                pickerType: 'month',
                select: [getPreviousMonth('yyyy-MM-dd', 1), getLastDayOfCurrentMonth()],
                defaultValue: [getPreviousMonth('yyyy-MM-dd', 1), getLastDayOfCurrentMonth()],
                range: true,
                isMonthPicker: true,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'contractor_guids',
                apiMethod: getAllContractors,
                label: 'Контрагент',
                itemValue: 'external_id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                apiParseUrl: false,
                template: (item: any): string => `${item.name}`,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'legal_entity_id',
                apiMethod: getLegalEntitySearch,
                label: 'Организация',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'created_by',
                apiMethod: getActiveUserSearch,
                label: 'Ответственный',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'advertiser_ids',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [] as Array<{id: number, name: string}>,
                multiple: true,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'other_service_id',
                apiMethod: getInvoiceOtherServices,
                label: 'Акции',
                itemValue: 'service_id',
                itemText: 'service_name',
                select: [],
                items: [] as IOtherService[],
                multiple: true,
                template: (item: any): string => item.name || item.service_name || `${item.abbr} - ${item.text}`,
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'tag_id',
                apiMethod: getAdvertiserTags,
                label: 'Признак рекламодателя',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [] as ITag[],
                multiple: true,
                template: (item: any): string => item.name || item.service_name || `${item.abbr} - ${item.text}`,
            }),
            new filterClass.Multiselect({
                col: 2,
                id: 'statuses',
                label: 'Статус счёта',
                type: 'string',
                select: [],
                items: [{ id: 'new' }, { id: 'sent' }, { id: 'pending' }, { id: 'partly_paid' }, { id: 'paid' }],
                template: ({ id }) => this.translateStatus(id, translateDebtorStatus),
                itemText: ({ id }) => this.translateStatus(id, translateDebtorStatus),
                itemValue: 'id',
                noDataText: 'Нет статусов',
            }),
        ];
    }

    get periodFilter(): any {
        return this.filters.find(i => i.id === 'period');
    }
}

export const monthNamesInRussian: string[] = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
];

export const debtorsHeadersTable: {[key: string]: string | boolean}[] = [
    { text: 'Дата', value: 'created_at', align: 'left', width: '90px' },
    { text: 'Период', value: 'period', align: 'left', width: '90px' },
    { text: 'Номер счёта', value: 'external_id' },
    { text: 'ID счёта', value: 'invoice_id' },
    { text: 'Контрагент', value: 'contractor' },
    { text: 'Организация', value: 'legal_entity' },
    { text: 'Ответственный', value: 'created_by' },
    { text: 'Проект', value: 'project', align: 'center' },
    { text: 'Выручка за период', value: 'revenue_period', align: 'center' },
    { text: 'Сумма счёта', value: 'total', align: 'center' },
    { text: 'Валюта', value: 'currency', align: 'center' },
    { text: 'Сумма и дата оплат', value: 'payments', align: 'center' },
    { text: 'Статус', value: 'status', align: 'center' },
    { text: 'Сумма взаимозачёта', value: 'netting_sum', align: 'center' },
    { text: 'Сумма прямой выручки', value: 'revenue_direct', align: 'center' },
    { text: 'Акция', value: 'other_services', align: 'center' },
    { text: 'План погашения', value: 'payment_calendars' },
    { text: 'Примечание', value: 'comment', width: '120px' },
    { text: 'Рекламодатель', value: 'advertiser_id', align: 'left' },
    { text: 'Признак рекламодателя', value: 'tag' },
];

export const debtorsFiltersColumns: IColumnFilter[] = [
    {
        name: '',
        items: [
            { title: 'Дата', value: 'created_at' },
            { title: 'Период', value: 'period' },
            { title: 'Номер счёта', value: 'external_id' },
            { title: 'ID счёта', value: 'invoice_id' },
            { title: 'Контрагент', value: 'contractor' },
            { title: 'Организация', value: 'legal_entity' },
            { title: 'Ответственный', value: 'created_by' },
            { title: 'Проект', value: 'project' },
            { title: 'Выручка за период', value: 'revenue_period' },
            { title: 'Сумма счёта', value: 'total' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Валюта', value: 'currency' },
            { title: 'Сумма и дата оплат', value: 'payments' },
            { title: 'Статус', value: 'status' },
            { title: 'Сумма взаимозачёта', value: 'netting_sum' },
            { title: 'Сумма прямой выручки', value: 'revenue_direct' },
            { title: 'Акция', value: 'other_services' },
            { title: 'План погашения', value: 'payment_calendars' },
            { title: 'Примечание', value: 'comment' },
            { title: 'ID рекламодателя', value: 'advertiser_id' },
            { title: 'Признак рекламодателя', value: 'tag' },
        ],
    },
];

export const headers: {[key: string]: string | boolean}[] = [
    { text: 'Валюта', value: 'currency', align: 'left' },
    { text: 'Общая выручка за период', value: 'revenue_period', align: 'right' },
    { text: 'Сумма счетов', value: 'total', align: 'right' },
    { text: 'Сумма оплат', value: 'payments_amount', align: 'right' },
    { text: 'Сумма взаимозачетов', value: 'netting_sum', align: 'right' },
    { text: 'Сумма прямой выручки', value: 'revenue_direct', align: 'right' },
];

export const numberFormatting = [
    {
        slot: 'item.revenue_period',
        key: 'revenue_period',
    },
    {
        slot: 'item.total',
        key: 'total',
    },
    {
        slot: 'item.payments_amount',
        key: 'payments_amount',
    },
    {
        slot: 'item.netting_sum',
        key: 'netting_sum',
    },
    {
        slot: 'item.revenue_direct',
        key: 'revenue_direct',
    },
];
