var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-tooltip',{attrs:{"top":"","content-class":"base-tooltip","nudge-top":_vm.nudgeTop,"disabled":!_vm.isTooltip || _vm.$vuetify.breakpoint.width <= 769},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"custom-edit-btn",class:[
                {'button--with-icon': !(_vm.isIcon || (_vm.isEdit && _vm.isHideTextOnEdit))},
                {'button--mobile': _vm.isHideTextOnMobile},
                {'button--transparent': _vm.isTransparent},
                {'button--blue-line button' : _vm.isOutline},
                {'button--blue': _vm.isEdit && _vm.isOutline},
                {'button--grey-outline' : !_vm.isEdit && _vm.isOutline},
                {'--disabled': _vm.isDisabled},
                {'custom-edit-btn--icon': !_vm.isTextBtn},
                _vm.customBtnClass
            ],attrs:{"ripple":false,"icon":_vm.isIcon || (_vm.isEdit && _vm.isHideTextOnEdit),"disabled":_vm.isDisabled},on:{"click":function($event){$event.preventDefault();return _vm.$emit('toggle')}}},on),[(_vm.isEdit)?_c('SvgCheck',_vm._g({},on)):_c('SvgEdit',_vm._g({staticClass:"button__icon--black"},on)),(_vm.isTextBtn)?_c('span',{staticClass:"edit-btn__text"},[_vm._v(_vm._s(!_vm.isEdit ? 'Редактировать' : _vm.isHideTextOnEdit ? '' : 'Сохранить'))]):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.textBtn ? _vm.textBtn : _vm.isEdit ? 'Сохранить' : 'Редактировать'))])])
}
var staticRenderFns = []

export { render, staticRenderFns }