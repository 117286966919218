
import { Vue, Component, PropSync } from "vue-property-decorator";
import SvgDoubleArrow from "@/assets/icons/double-arrow.svg";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { getCurrencyIcon } from "@/utils/translate";

@Component({
    components: { BaseSelect, SvgDoubleArrow },
})

export default class CurrencyConversion extends Vue {
    @PropSync('value', { required: true }) valueSync!: string;

    items: string[] = ['RUB', 'EUR', 'USD', 'KZT'];

    get getItems(): { id: string, text: string }[] {
        return this.items.map(i => ({ id: i, text: `${i}, ${getCurrencyIcon(i)}` }));
    }

    select(): void {
        this.$emit('select');
    }
}

