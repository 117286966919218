
import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import SvgAffiliates from '@/assets/icons/nav-bar/affiliates.svg';
import { showServerError } from '@/utils';
import { ICampaign, ICampaignTop } from "@/api/types/campaigns";
import { getCampaigns, getTopOfCampaign } from "@/api/campaigns";
import TableWithFrontPagination from "@/components/base/table/TableWithFrontPagination.vue";
import { translateCampaignStatus } from '@/utils/translate';
import Status from '@/components/base/Status.vue';
import { CURRENT_CAMPAIGNS } from "@/configs/global";

@Component({
    components: {
        TableWithFrontPagination,
        SvgAffiliates,
        Status,
    },
})

export default class TopOfCampaigns extends Vue {
    @Mutation('SET_TOTAL_ELEMENTS_DATA_TABLE') setTotalElementsDataTable;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    // по умолчанию выбираем World Wanderers(id=9)
    selectedCampaign = CURRENT_CAMPAIGNS || 9;
    campaigns: ICampaign[] = [];
    itemsTopOfCampaign: ICampaignTop[] = [];
    headers = [
        { text: 'Место', sortable: false, value: 'position', align: 'center' },
        { text: 'ID партнера', sortable: false, value: 'affiliate_id', align: 'center' },
        { text: 'ФИО партнера', sortable: false, value: 'affiliate_name' },
        { text: 'Email', sortable: false, value: 'affiliate_email' },
        { text: 'Менеджер партнера', sortable: false, value: 'affiliate_manager' },
        { text: 'Очки', sortable: false, value: 'points', align: 'center' },
    ];
    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'affiliate_id',
            slot: 'item.affiliate_id',
            routerName: 'affiliate',
            text: 'affiliate_id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'affiliate_id',
            slot: 'item.affiliate_name',
            routerName: 'affiliate',
            text: 'affiliate_name',
        },
    ];
    settings = {
        title: 'Топ по акции',
        loading: true,
    };

    translateCampaignStatus = translateCampaignStatus;

    async changeCampaign(): Promise<void> {
        try {
            if (this.$route.query.campaign !== String(this.selectedCampaign)) {
                this.setQuery();
            }
            this.itemsTopOfCampaign = await getTopOfCampaign(this.selectedCampaign);
        } catch (err) {
            showServerError(err, `Топ по акции для ${this.selectedCampaign} не загружен`);
        } finally {
            this.settings.loading = false;
        }
    }

    created(): void {
        if (!!this.$route.query.campaign) {
            this.selectedCampaign = Number(this.$route.query.campaign);
        } else {
            this.setQuery();
        }
        this.getCampaigns();
    }

    setQuery(): void {
        const query = { campaign: String(this.selectedCampaign) };
        this.$router.replace({ name: this.$router.currentRoute.name || '', query });
    }

    get currentCampaign(): any {
        return this.campaigns.find((item) => item.id === this.selectedCampaign);
    }

    async getCampaigns(): Promise<void> {
        try {
            this.campaigns = await getCampaigns();
            await this.changeCampaign();
        } catch (err) {
            showServerError(err, 'Список акций не загружен');
        }
    }
}
