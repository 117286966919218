
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import FormModal from "@/components/base/FormModal.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import SvgPlus from '@/assets/icons/plus.svg';
import SvgClip from '@/assets/icons/clip.svg';
import SvgMagick from "@/assets/icons/magic-wand-light.svg";
import ImageNotesFile from "@/components/base/ImageNotesFile.vue";
import ImageInput from "@/components/base/form/ImageInput.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import NotesTooltip from "@/components/base/NotesTooltip.vue";
import { TableHeader } from "@/types";
import { toBase64 } from "@/utils";

@Component({
    components: {
        NotesTooltip,
        TableWithFrontPaginationDesign, ImageInput, ImageNotesFile, TrashBtn, FormModal, BaseCard, SvgPlus, SvgMagick, SvgClip },
})

export default class CardNotes extends Vue {
    @Prop({ default: true }) isAddFile!: boolean;
    @Prop({ required: true }) items!: any[];
    @Prop({ default: () => [] }) timeData!: any[];
    @Prop({ required: true }) headers!: TableHeader[];
    @Prop({ default: 'note' }) keyText!: string;
    @Prop({ default: 'images' }) keyImage!: string;

    isShowModal: boolean = false;
    note: string = '';
    image: File | string | null = null;
    editId: number | null = null;
    cashedNote: any = {};
    maxLength = 500;
    rules = [(v: string): any => v.length <= this.maxLength || ''];
    uniqueData = [
        { slot: 'item.text' },
        { slot: 'item.file' },
        { slot: 'item.creator', template: (i: any): void => i.creator.name },

    ];

    createNote(): void {
        this.clearEditId();
        this.isShowModal = true;
    }

    async addNote(): Promise<void> {
        this.isShowModal = false;
        const item = this.items.find(i => i.id == this.cashedNote.id);
        if (item) {
            this.cancelEdit(item);
        }
        const base64Image = this.image ?  await toBase64(this.image as File) : null;

        this.$emit('add', this.note,  base64Image);

        this.note = '';
        this.image = null;
    }

    updateNewImage(image: any): void {
        this.image = image;
    }

    updateImage(image: any, item: any): void {
        item[this.keyImage] = image;
        // если обновляем картинку, минуя режим редактирования, отправляем запрос на бэк
        if (!this.editId) {
            this.$emit('save', item);
        }
    }

    disabledActions(item: any): boolean {
        return this.editId !== item.id && this.cashedNote && Object.keys(this.cashedNote)?.length > 0;
    }

    deleteNote(item: any): any {
        this.$emit('delete', item);
        this.clearEditId();
    }

    cancelEdit(item: any): void {
        item[this.keyText] = this.cashedNote[this.keyText];
        item[this.keyImage] = this.cashedNote[this.keyImage];
        this.clearEditId();
    }

    editNote(item: any): void {
        this.editId = item.id;
        this.cashedNote = { ...item };
    }

    async saveNote(item: any): Promise<void> {
        if (item[this.keyImage]) {
            // image": null - игнорируется, пустая строка - удаление тек.картинки, строка в base64 - добавляет картинку
            const image = typeof item[this.keyImage] === 'string' ? null : await toBase64(item[this.keyImage]);
            item[this.keyImage] = image;
        }
        this.$emit('save', item);
        this.clearEditId();
    }

    clearEditId(): void {
        this.editId = null;
        this.cashedNote = {} as any;
    }

    updateText(text: string, note: any): void {
        text = text.trim();
        if (text.length > 500)  text = text.slice(0, this.maxLength);
        note[this.keyText] = text;
    }
}

