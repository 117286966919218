
import { Component, Vue, Prop } from 'vue-property-decorator';
import StatsCard from '@/components/base/StatsCard.vue';
import FormModal from '@/components/base/FormModal.vue';
import { IUserActiveToken } from '@/api/types/user';
import {
    deleteUserActiveToken,
    getUserActiveToken,
    addToken,
} from '@/api/user';
import { translateTokenTypes } from "@/utils/translate";
import Pagination from '@/components/base/table/Pagination.vue';
import { showServerError } from '@/utils';
import { PAGINATION_PER_PAGE } from '@/configs/global';

@Component({
    components: {
        StatsCard,
        FormModal,
        Pagination,
    },
})
export default class ActiveTokensTable extends Vue {
    @Prop({ type: Object, required: true }) user!: {};
    @Prop({ default: true }) isShowSelectToken!: boolean;
    userRoles: object = {};
    activeTokens: IUserActiveToken[] | undefined = [];
    loading = false;
    tokenTypes: string[] = ['api'];
    // pagination
    total = 0;
    limit = PAGINATION_PER_PAGE;
    // встроенный Vuetify объект
    pagination: {page: number} = {
        page: 1,
    };
    selectedToken = '';
    translateTokenTypes = translateTokenTypes ;
    // name of cols
    headers = [
        {
            text: 'Тип токена',
            sortable: false,
            value: 'type',
            class: 'black--text font-weight-regular',
        },
        {
            text: 'ID',
            sortable: false,
            value: 'id',
            width: '80px',
            class: 'black--text font-weight-regular',
        },
        {
            text: 'Токен',
            sortable: false,
            value: 'token',
            class: 'black--text font-weight-regular',
        },
        {
            text: 'IP',
            sortable: false,
            value: 'ip',
            width: '120px',
            class: 'black--text font-weight-regular',
        },
        {
            text: 'Создан',
            sortable: false,
            value: 'created_at',
            width: '20%',
            class: 'black--text font-weight-regular',
        },
        {
            text: 'Истекает',
            sortable: false,
            value: 'expires_at',
            width: '20%',
            class: 'black--text font-weight-regular',
        },
        {
            text: 'User-agent',
            sortable: false,
            value: 'user_agent',
            class: 'black--text font-weight-regular',
        },
        {
            text: 'Действия',
            align: 'center',
            sortable: false,
            value: 'actions',
            class: 'black--text font-weight-regular',
        },
    ];

    // для модалки с удалением токена
    deletingToken = {
        id: 0,
    };
    actions = [
        {
            callback: ({ id }: {id: number}): void => {
                this.deletingToken.id = id;
            },
        },
    ];

    get refs(): any {
        return this.$refs;
    }

    copyToken(id: number): void {
        const tokenToCopy = this.refs[`token${id}`].$el.querySelector('input');
        tokenToCopy?.select();
        document.execCommand('copy');
    }

    async addToken(): Promise<void> {
        try {
            await addToken(this.$props.user, this.selectedToken);
            await this.getActiveTokens();
        } catch (err) {
            showServerError(err, 'Список активных токенов не загружен');
        }
    }

    async getActiveTokens(offset?: number, limit?: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            this.pagination.page = 1;
        }
        if (limit) {
            this.limit = limit;
        }
        this.loading = true;
        try {
            const { meta, data } = await getUserActiveToken(this.$props.user.account_id, this.$props.user.account_type,
                { offset, limit });
            this.activeTokens = data;
            this.total = meta!.total || data.length;
        } catch (err) {
            showServerError(err, 'Список активных токенов не загружен');
        }
        this.loading = false;
    }

    async logoutUser(): Promise<void> {
        this.loading = true;
        try {
            const index = this.activeTokens!.findIndex((token) => token.id === this.deletingToken.id);
            await deleteUserActiveToken(
                this.$props.user.account_id,
                this.$props.user.account_type,
                this.deletingToken.id,
            );
            this.activeTokens!.splice(index, 1);
            this.deletingToken.id = 0;
        } catch (err) {
            showServerError(err, 'Пользователь не разлогинен');
        }
        this.loading = false;
    }

    mounted(): void {
        this.getActiveTokens(0, this.limit);
    }
}
