
import { Component, PropSync, Ref, Vue, Watch } from "vue-property-decorator";
import FormModal from "@/components/base/FormModal.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getAffiliateSearch } from "@/api/user";
import SvgMagicWandLight from '@/assets/icons/magic-wand-light.svg';
import Loader from "@/components/base/Loader.vue";
import { showServerError } from "@/utils";
import { NotificationMessage } from "@/utils/notification";
import { createSubstitution, patchSubstitutionById } from "@/api/uapi";
import { ISubstitutionItem } from "@/api/types/uapi";


@Component({
    components: {
        Loader,
        BaseAutocomplete,
        FormModal,
        SvgMagicWandLight,
    },
})
export default class DistributionRulesModal extends Vue {
    @PropSync('showModal') showModalSync!: boolean;
    @PropSync('editingData', { type: Object, default: null }) editingDataSync!: ISubstitutionItem;
    @Ref() affiliateInput!: BaseAutocomplete;

    getAffiliateSearch = getAffiliateSearch;
    headers: object[] = [
        { text: 'ID партнёра', value: 'affiliate_id', width: '40%' },
        { text: '%', value: 'percent', width: '50%' },
        { text: '', value: 'actions', sortable: false, width: '10%' },
    ];
    isLoading = false;
    partners: any[] = [];
    firstSearch: boolean[] = [];
    showError = false;
    disabledCreateBtn = true;
    selectedAffiliate: any = null;
    addedAffiliate: any = null;
    id: any = null;
    mode: string = 'create';

    get getTitle(): string {
        return 'Правило распределения трафика';
    }

    @Watch('editingDataSync')
    changeEditingData(): void {
        if (!!this.editingDataSync) {
            this.mode = 'edit';
            this.id = this.editingDataSync.id;
            this.initEditData();
        }
    }

    async initEditData(): Promise<void> {
        this.partners = JSON.parse(this.editingDataSync.parameters);
        this.firstSearch = this.partners.map(() => true);
        const { data }  = await this.getAffiliateSearch({ query: this.editingDataSync.affiliate_id });
        this.addedAffiliate = data[0].id + ' ' + data[0].first_name + ' ' + data[0].last_name;

        this.checkPercentSum();
    }

    addPartner(): void {
        if (this.selectedAffiliate) {
            this.partners.push({
                affiliate_id: '',
                percent: this.partners.length === 0 ? 100 : 0,
            });
            this.checkPercentSum();
        }
    }
    removePartner(index: number): void {
        this.partners.splice(index, 1);
        this.checkPercentSum();
    }
    updateAffiliates(affiliate: number): void {
        this.selectedAffiliate = affiliate;
    }

    updateAffiliate(affiliate: any, index: number): void {
        if (affiliate) {
            if (affiliate.hasOwnProperty('id')) {
                this.partners[index].affiliate_id = affiliate.id;
            } else {
                this.partners[index].affiliate_id = affiliate;
            }
        }
    }
    checkPercentSum(): void {
        if (this.partners !== []) {
            const sum = this.partners.reduce((acc, partner) => acc + partner.percent, 0);
            this.showError = sum !== 100;
        }
        this.disabledCreateBtn = this.showError || this.partners.length === 0;
    }

    closeModal(): void {
        this.partners = [];
        this.firstSearch = [];
        this.id = null;
        this.showModalSync = false;
        this.mode = 'create';
        this.disabledCreateBtn = true;
        this.$emit('close');
        this.clearInputs();
    }

    clearInputs(): void {
        if (!!this.affiliateInput) {
            this.affiliateInput.clearModel();
            this.selectedAffiliate = null;
            this.addedAffiliate = null;
        }
    }

    async createNewRule(): Promise<void> {
        this.isLoading = true;
        try {
            if (!this.editingDataSync) {
                await createSubstitution({
                    affiliate_id: this.selectedAffiliate,
                    substitute_type: 'distribute',
                    parameters: JSON.stringify(this.partners),
                    is_active: true,
                });
            } else {
                await patchSubstitutionById(Number(this.id), {
                    affiliate_id: this.editingDataSync.affiliate_id,
                    substitute_type: 'distribute',
                    parameters: JSON.stringify(this.partners),
                    is_active: true,
                });
            }
            this.closeModal();
            await this.$emit('edit');
            this.showNotification();
        } catch (err) {
            showServerError(err, 'Правило распределения не создано');
        } finally {
            this.isLoading = false;
        }
    }

    showNotification(): void {
        const notificationText = `Правило распределения успешно создано`;
        new NotificationMessage(notificationText, 'success' );
    }
}
