
import { Vue, Component } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { OtherServicesFilters } from "@/services/TablePage/otherServices";
import OtherServicesTable from "@/components/revenue/otherServices/OtherServicesTable.vue";
import { getInvoiceOtherServices } from "@/api/revenue";
import OtherServicesFiltersComponent from "@/components/revenue/otherServices/OtherServicesFiltersComponent.vue";
import { IParamsObject, IServerResponse } from "@/api/types";
import { IFinmediaData, IOtherService } from "@/api/types/revenue";
import { showServerError } from "@/utils";
import CreateOtherService from "@/components/revenue/otherServices/CreateOtherService.vue";

@Component({
    components: { OtherServicesFiltersComponent, TablePageWrapper },
})

export default class OtherServicesList extends Vue {
    filters = new OtherServicesFilters();
    table = {
        component: OtherServicesTable,
        apiMethod: this.getOtherServicePagination,
    };
    createOtherServiceForm = {
        component: CreateOtherService,
    };

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 768;
    }

    get getFiltersComponent(): any {
        return this.isMobile ? undefined : OtherServicesFiltersComponent;
    }

    async getOtherServicePagination(params: IParamsObject): Promise<IServerResponse<IOtherService[] | IFinmediaData> | undefined> {
        try {
            const data = await getInvoiceOtherServices(params);
            if (params.is_finmedia) {
                const services: IOtherService[] = [];
                for (const key in data.data!) {
                    services.push(...data.data![key].services);
                }
                data.data = services;
            }
            return data;
        } catch (err) {
            showServerError(err, 'Прочие услуги не загружен');
        }
    }
}
