
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
    translateDebtorStatus,
} from "@/utils/translate";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { Mutation } from "vuex-class";
import { debtorsHeadersTable, monthNamesInRussian } from "@/services/TablePage/debtors";
import { ITableStatus } from "@/types";

@Component({
    components: {
        AppTableDesign,
    },
})
export default class AppTableDebtors extends Vue {
    @Prop({ required: true }) items!: any[];
    @Prop({ type: String, default: 'id' }) tableItemKey!: string;
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;

    headers: {[key: string]: string | boolean}[] =  debtorsHeadersTable;

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
    ];

    dropDownItems = [
        {
            slot: 'item.payments',
            text: 'payments',
            readonly: true,
            style: 'margin: auto;',
            template: (item: any): string => item && item.amount ?
                `${item.amount ? item.amount.toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '0,00'} ${item.currency || ''} : ${item.date || ''}` :
                '0,00',
        },
        {
            slot: 'item.other_services',
            text: 'other_services',
            readonly: true,
            template: (item: any): string => item && item.service_name ? item.service_name : '',
        },
        {
            slot: 'item.payment_calendars',
            text: 'payment_calendars',
            readonly: true,
            template: (item: any): string => item && item.amount ?
                `${item.amount.toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ${item.currency} : ${item.date}` :
                '0,00',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateDebtorStatus,
        },
    ];

    routes = [
        {
            extraRouteParamName: 'invoice_id',
            routeParam: 'id',
            slot: 'item.invoice_id',
            routerName: 'invoice',
            text: 'invoice_id',
        },
        {
            extraRouteParamName: 'advertiser_id',
            routeParam: 'id',
            slot: 'item.advertiser_id',
            routerName: 'advertiser',
            text: 'advertiser_id',
            template: (item: any): string => item.advertiser_name,
        },
    ];

    uniqueData = [
        {
            slot: 'item.period',
            template: (item: any): string => (item
                ? `${this.getNameOfMonth(item.period.split('-')[1])} ${item.period.split('-')[0]}`
                : ''),
        },
        {
            slot: 'item.contractor',
        },
        {
            slot: 'item.tag',
            template: (item: any): string => `${item.tag.name || ''}`,
        },
        {
            slot: 'item.legal_entity',
        },
        {
            slot: 'item.created_by',
            template: (item: any): string => `${item.created_by.first_name || ''} ${item.created_by.last_name || ''}`,
        },
        {
            slot: 'item.comment',
        },
    ];

    numberFormatting = [
        {
            slot: 'item.revenue_period',
            key: 'revenue_period',
        },
        {
            slot: 'item.total',
            key: 'total',
        },
        {
            slot: 'item.netting_sum',
            key: 'netting_sum',
        },
        {
            slot: 'item.revenue_direct',
            key: 'revenue_direct',
        },
    ];

    created(): void {
        this.setUnsavedDataState(false);
        this.setAbortTransition(false);
    }

    shouldWrap(content: string): boolean {
        const columnWidth = 300;
        const span = document.createElement('span');
        span.textContent = content;
        document.body.appendChild(span);
        const contentWidth = span.offsetWidth;
        document.body.removeChild(span);
        return contentWidth > columnWidth;
    }

    @Watch('items')
    setCurrency(): void {
        this.items.forEach((item) => {
            item.payment_calendars.forEach((payment) => {
                payment.currency = item.currency;
            });
        });
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }


    getTemplate(item: any): string {
        return item.name || item.code || item.master.id;
    }

    getNameOfMonth(monthNumber: number): string | undefined {
        return monthNamesInRussian[monthNumber - 1];
    }
}
