
import { Component, Prop, PropSync, Ref, Vue } from "vue-property-decorator";
import { getAffiliateSearch } from "@/api/user";
import FormModal from "@/components/base/FormModal.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import Loader from "@/components/base/Loader.vue";
import { showNotification, showServerError } from "@/utils";
import { IPostCreateOrUpdateApproval } from "@/api/types/offers";
import { createOrUpdateStatusApproval } from "@/api/offers";
import { eventBus } from "@/eventbus";

@Component({
    methods: { getAffiliateSearch },
    components: {
        BaseAutocomplete,
        Loader,
        FormModal,
    },
})
export default class FormMultiplyApproval extends Vue {
    @Prop({ required: true }) selectedIds!: number[];
    @PropSync('showModal') showModalSync!: boolean;
    @Prop() defaultPartner!: number;
    @Ref() affiliateInput!: BaseAutocomplete;

    isLoading: boolean = false;
    partnerIds: number[] = [];

    get isSaveBtnDisabled(): boolean {
        return this.partnerIds.length === 0;
    }

    async sendListApproval(): Promise<void> {
        this.isLoading = true;
        try{
            const pairs = this.partnerIds.flatMap(partnerId =>
                this.selectedIds.map(offerId => ({
                    offer_id: offerId,
                    affiliate_id: partnerId,
                })),
            );

            const data: IPostCreateOrUpdateApproval = { pairs, status: 'approved' };
            await createOrUpdateStatusApproval(data);
            eventBus.$emit("clear-selected-offers");
            showNotification('Доступы созданы');
            this.closeModal();
        }catch (err){
            showServerError(err, 'Ошибка при создании доступа');
        }finally {
            this.isLoading = false;
        }
    }

    closeModal(): void {
        this.clearInputs();
        eventBus.$emit("close-multiply-approval-modal");
    }

    clearInputs(): void {
        this.partnerIds = [];
        this.affiliateInput.clearModel();
    }

    updateAffiliates(items: number[]): void {
        this.partnerIds = items;
    }
}
