
import { Component, Vue } from 'vue-property-decorator';
import { editSchedule, getOfferSchedule } from '@/api/uapi';
import { showServerError } from '@/utils';
import { IOfferScheduleOptions } from '@/api/types/uapi';

@Component
export default class Schedule extends Vue {
    options: IOfferScheduleOptions = {
        maxAge: 0,
        hourlyLimit: 0,
        rateLimit: 0,
        collect: true,
        hourlyStatus: false,
    };
    loading = false;
    week = [
        {
            name: 'Понедельник',
            value: 1,
            time:  { from: '00:00', till: '00:00' },
            menuFrom: false,
            menuTill: false,
        },
        {
            name: 'Вторник',
            value: 2,
            time:  { from: '00:00', till: '00:00' },
            menuFrom: false,
            menuTill: false,
        },
        {
            name: 'Среда',
            value: 3,
            time:  { from: '00:00', till: '00:00' },
            menuFrom: false,
            menuTill: false,
        },
        {
            name: 'Четверг',
            value: 4,
            time:  { from: '00:00', till: '00:00' },
            menuFrom: false,
            menuTill: false,
        },
        {
            name: 'Пятница',
            value: 5,
            time:  { from: '00:00', till: '00:00' },
            menuFrom: false,
            menuTill: false,
        },
        {
            name: 'Суббота',
            value: 6,
            time:  { from: '00:00', till: '00:00' },
            menuFrom: false,
            menuTill: false,
        },
        {
            name: 'Воскресенье',
            value: 0,
            time:  { from: '00:00', till: '00:00' },
            menuFrom: false,
            menuTill: false,
        },
    ];

    created(): void {
        this.getOfferSchedule();
    }

    async getOfferSchedule(): Promise<void> {
        try {
            const { data: { schedule } } = await getOfferSchedule(Number(this.$route.params.id));
            if (schedule) {
                const { maxAge, collect, hourlyLimit, rateLimit, hourlyStatus } = schedule!;
                this.options = {
                    maxAge, collect, hourlyLimit, rateLimit, hourlyStatus,
                };
                if (!rateLimit) {
                    this.options.rateLimit = 0;
                }
                for (const key of this.week) {
                    if (schedule.days && schedule.days[key.value]) {
                        const { from, till } = schedule.days[key.value];
                        key.time = {
                            from: from,
                            till: till,
                        };
                    } else {
                        key.time = {
                            from: '00:00',
                            till: '00:00',
                        };
                    }
                }
            } else {
                this.options.collect = false;
            }
        } catch (err) {
            showServerError(err, 'Не удалось получить расписание оффера');
        }
    }

    saveTime(value: string, index: number, key: string): void {
        this.week[index].time[key] = value;
        const menuKey = `menu${key[0].toUpperCase() + key.slice(1)}`;
        this.$refs[menuKey]?.[index]?.save(this.week[index].time[key]);
        this.closePicker(index, menuKey);
    }

    closePicker(index: number, menuKey: string): void {
        this.$set(this.week[index], menuKey, false);
    }

    async saveSchedule(): Promise<void> {
        this.loading = true;
        try {
            const days = {};

            for (const key of this.week) {
                days[key.value] = {
                    from: key.time.from,
                    till: key.time.till,
                };
            }

            const schedule = Object.assign({}, { days }, this.options);
            // пустую строку не отправлять, вместо нее значение по умолчанию - 0
            if (Object.is(schedule.hourlyLimit, '')) {
                schedule.hourlyLimit = 0;
            }
            if (Object.is(schedule.rateLimit, '')) {
                schedule.rateLimit = 0;
            }
            await editSchedule(Number(this.$route.params.id), schedule);
        } catch (err) {
            showServerError(err, 'Не удалось сохранить расписание');
        }
        this.loading = false;
        await this.getOfferSchedule();
    }
}
