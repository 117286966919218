import axios, { AxiosResponse } from 'axios';
import { downloadFiles } from '@/api/revenue';
import store from '@/store';
import { API_GATEWAY_URL, API_REVENUE_URL, ENV_TOGGLER, TOKEN } from '@/configs/global';
import { IFile } from '@/api/types/revenue';
import { IServerResponse } from "@/api/types";

// Создаем экземпляр axios
export const axiosInstance = axios.create();

const { CancelToken } = axios;
export let cancel;

axiosInstance.interceptors.request.use((config) => {
    cancel = false;
    const devMode = store.getters.IS_DEV_MODE;
    if (devMode && ENV_TOGGLER) {
        if (config.params) {
            config.params.debug = 1;
        } else {
            config.params = {
                debug: 1,
            };
        }
    }
    config.cancelToken = new CancelToken((c) => {
        cancel = c;
    });
    return config;
});

// Метод для скачивания файлов
export const downloadFile = async (item: IFile, method: any = downloadFiles): Promise<void> => {
    const file = await method(item.id!);
    downloadFileToDisk(file, item);
};

export const downloadFileToDisk = (file: any, item: IFile | string = {}): void => {
    const fileUrl = window.URL.createObjectURL(new Blob([file as Blob]));
    const link = document.createElement('a');
    const name = typeof item === 'string' ? item : `${item.original_name || item.name}.${item.extension}`;
    link.href = fileUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

// скачивание по ссылке
export const downloadImageFromUrl = async (url: string): Promise<void> => {
    const xhr = new XMLHttpRequest();
    const name = url.split('/');
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onload = () => {
        downloadFileToDisk(xhr.response, name[name.length - 1]);
    };
    xhr.onerror = () => {
        console.error('Файл не скачен');
    };
    xhr.send();
};

// Метод добавления файлов
export const addFile = async (file: IFile): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('file', file as Blob);
    return await axiosInstance.post(`${API_REVENUE_URL}/files`, formData);
};

/**
 *  DADATA получение стран
 */
export async function dadataCountry(
    data: any,
): Promise<any> {
    return axiosInstance.request<any>({
        method: 'POST',
        url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/country',
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${TOKEN}`,
            'Content-Type': 'application/json',
            'X-ACCOUNT-TOKEN': '',
        },
        data,
    });
}

/**
 *  DADATA получение города
 */
export async function dadataCity(
    param: any,
): Promise<any> {
    return axiosInstance.request<any>({
        method: 'POST',
        url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${TOKEN}`,
            'Content-Type': 'application/json',
            'X-ACCOUNT-TOKEN': '',
        },
        data: {
            query: param.query,
            locations: [
                { country: '*' }],
            from_bound: { value: 'city' },
            to_bound: { value: 'city' },
        },
    });
}


export async function uploadToS3(
    file: IFile,
): Promise<IServerResponse<any>> {
    const formData = new FormData();
    formData.append('files', file as Blob);
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_GATEWAY_URL + '/s3'}/upload`,
        data: formData,
        headers: {
            'content-type': 'multipart/form-data',
        },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}
