
import { Component,  PropSync, Vue } from "vue-property-decorator";
import { showServerError } from "@/utils";
import { editSignature, getSignature } from "@/api/support";
import FormModal from "@/components/base/FormModal.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import { ISignature } from "@/api/types/support";

@Component({
    components: {
        FormModal, TrashBtn,
    },
})
export default class FormCreateSignature extends Vue {
    @PropSync('showModal') showModalSync!: boolean;

    editSignature = editSignature;

    signatureText: ISignature = {
        text_en: '',
        text_ru: '',
    };
    cacheSignature = {} as ISignature;

    closeModal(): void {
        this.showModalSync = false;
    }

    async getSignature(): Promise<void> {
        try {
            const data = await getSignature();
            this.signatureText = { ...data };
            this.cacheSignature = { ...data };
        } catch (err) {
            showServerError(err, "Не удалось получить подпись");
        }
    }

    mounted(): void {
        this.getSignature();
    }

    async createSignature(): Promise<void> {
        try {
            await this.editSignature(this.signatureText);
            await this.getSignature();
        } catch (err) {
            showServerError(err, "Не удалось создать подпись");
        }
        this.closeModal();
    }

    async cancelCreateSignature(): Promise<void> {
        this.signatureText = { ...this.cacheSignature };
        this.closeModal();
    }
}
