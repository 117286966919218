
import { Component, Vue } from 'vue-property-decorator';
import ServicesStatsSummary from '@/components/orgstats/ServicesStatsSummary.vue';
import { translateServiceType } from '@/utils/translate';
import ServicesStatsDetail from '@/components/orgstats/ServicesStatsDetail.vue';
import StatsCard from '@/components/base/StatsCard.vue';

@Component({
    components: {
        ServicesStatsSummary,
        ServicesStatsDetail,
        StatsCard,
    },
})
export default class ServicesReport extends Vue {
    tabs: Array<{
        id: string;
        name: string;
    }> = [
            { id: 'offer', name: translateServiceType('offer') },
            { id: 'referral_payment', name: translateServiceType('referral_payment') },
            { id: 'other', name: translateServiceType('other') },
        ];
    tabName = 0;
    isShowTabs = false;

    showDetailTabs(isShow: boolean): boolean {
        return this.isShowTabs = isShow;
    }
}
