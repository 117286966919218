var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('form-modal',{attrs:{"custom-class":"form-methods","show-modal":_vm.showModalSync,"max-width":"670px","is-disabled":_vm.isDisabledSaveBtn,"need-hide-btn":false,"need-cancel-btn":true,"title":"Создание способа выплаты","closable":true,"is-need-already-click":false},on:{"close-modal":_vm.closeModal,"cancel":_vm.clearInputs,"save":_vm.createRequestMethod}},[[_c('v-form',{ref:"form",staticClass:"form-methods__form base-form__form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',{staticClass:"base-form__item-label"},[_vm._v(" Партнёр ")]),_c('base-autocomplete',{ref:"affiliateInput",staticClass:"base-form__item-input",attrs:{"data":{
                        errorText: 'Партнер не найден',
                        apiMethod: _vm.getAffiliateSearch,
                        itemValue: 'id',
                        template: _vm.getAffiliateSearchTemplate,
                        placeholder: 'Введите ID или имя партнёра',
                        multiple: true,
                        multiPaste: true,
                        rules: [() => _vm.affiliates.length > 0 || 'Поле обязательно для заполнения'],
                    },"also-search-by":"email","default-search":_vm.defaultAffiliate},on:{"change":_vm.updateAffiliates}}),_c('p',{staticClass:"base-form__item-label"},[_vm._v(" Способ выплаты ")]),_c('base-select',{attrs:{"items":_vm.methods,"value":_vm.method,"item-value":"id","template":_vm.getPaymentMethodsTemplate,"rules":[() => !!_vm.method || 'Поле обязательно для заполнения']},on:{"select":_vm.updateMethod}}),_vm._l((_vm.fields),function(field){return [_c('p',{staticClass:"base-form__item-label"},[_vm._v(" "+_vm._s(field.name)+" ")]),(field.id === 'cardholder_birthday')?_c('date-time-picker-new',{staticClass:"base-form__item-input",attrs:{"value":field.value,"is-inline":true,"available-dates":() => { return { start: null, end: new Date() } },"placeholder":"Выберите дату","without-time":true},on:{"input":(val) => field.value = val}}):(field.id === 'legal_id')?_c('base-autocomplete',{staticClass:"base-form__item-input",attrs:{"data":{
                            errorText: 'Вебмастера не найдены',
                            apiMethod: _vm.searchWebmasters,
                            itemValue: 'guid',
                            itemText: 'name',
                            template: (item) => item.name,
                            placeholder: 'Введите id или название организации',
                            rules: [(v) => !!v || 'Поле обязательно для заполнения'],
                            clearable: true,
                        }},on:{"change":function($event){field.value = $event}},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}):(field.mask)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##'),expression:"'##/##'"}],staticClass:"mt-0 base-form__item-input",attrs:{"clearable":"","hide-details":'auto',"disabled":field.disabled,"rules":field.rules,"placeholder":field.placeholder,"required":""},scopedSlots:_vm._u([(field.value.length > 1)?{key:"append-outer",fn:function(){return [_c('trash-btn',{attrs:{"x-small":""},on:{"clear":function($event){field.value = ''}}})]},proxy:true}:null],null,true),model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"field.value"}}):_c('v-text-field',{staticClass:"mt-0 base-form__item-input",attrs:{"clearable":"","hide-details":'auto',"disabled":field.disabled,"rules":field.rules,"placeholder":field.placeholder,"required":""},scopedSlots:_vm._u([(field.value.length > 1)?{key:"append-outer",fn:function(){return [_c('trash-btn',{attrs:{"x-small":""},on:{"clear":function($event){field.value = ''}}})]},proxy:true}:null],null,true),model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"field.value"}})]}),_c('v-checkbox',{staticClass:"table-filter__checkbox table-filter__checkbox-design pt-0 mb-0",attrs:{"label":"Сделать основным","type":"checkbox","hide-details":"","color":"#1C2F45","ripple":false},model:{value:(_vm.isMakeDefault),callback:function ($$v) {_vm.isMakeDefault=$$v},expression:"isMakeDefault"}})],2),(_vm.loading)?_c('loader'):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }