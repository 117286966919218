/* eslint-disable class-methods-use-this */

/*
Плагин для возможности общения между вкладками
this.$globalEvents.emit('event-name'[, data]);
this.$globalEvents.on('event-name', callbackFn);
this.$globalEvents.off('event-name');
 */
export interface IGlobalEvent {
    name: string,
    callback: (...args: any) => void | Promise<void>,
    args?: any,
}

export class GlobalEvents {
    eventIsRunning = false;
    storage = window.localStorage;
    eventStack: IGlobalEvent[] = [];

    constructor() {
        this.unlisten();
        this.listen();
    }

    emit(eventName: string, transportObject?: any): void {
        this.setItem(eventName, transportObject);
        this.unlisten();

        setTimeout(() => {
            this.removeItem(eventName);
            this.listen();
        });
    }

    on(eventName: string, callback: () => void | Promise<void>, args: any[]): void {
        if (this.findByName(eventName)) {
            throw `GlobalEvents: Event "${eventName}" already exists.`;
        } else {
            const event: IGlobalEvent = {
                name: eventName,
                callback,
            };

            if (args) {
                event.args = args;
            }

            this.eventStack.push(event);
        }
    }

    off(eventName: string): void {
        const event = this.findByName(eventName);
        if (event) {
            const idx = this.eventStack.indexOf(event);
            this.eventStack.splice(idx, 1);
        }
    }

    setItem(key: string, value: any): void {
        this.storage.setItem(key, value || '_evt');
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    findByName(eventName: string): IGlobalEvent | undefined {
        return this.eventStack.find((event) => event.name === eventName);
    }

    parseJSON(val: any): any {
        try {
            return JSON.parse(val);
        } catch (e) {}

        return val;
    }

    eventListener(e: any): void {
        const event = this.findByName(e.key);
        const val = e.newValue;
        const params: any[] = [];

        if (event && !this.eventIsRunning) {
            this.eventIsRunning = true;

            if (event.args) {
                params.push(event.args);
            }
            if (val && val !== '_evt') {
                params.push(this.parseJSON(val));
            }

            event.callback.apply(this, params);

            setTimeout(() => {
                this.eventIsRunning = false;
            }, 10);
        }
    }

    listen(): void {
        window.addEventListener('storage', this.eventListener.bind(this));
    }

    unlisten(): void {
        window.removeEventListener('storage', this.eventListener.bind(this));
    }
}

(window as any).$globalEvents = new GlobalEvents();
