
import { Component, Mixins, Prop } from 'vue-property-decorator';
import SortTableMixin from '@/mixins/sortTableMixin';
import { eventBus } from '@/eventbus';
import { getConversionsList } from '@/api/uapi';
import { GetInfo } from '@/utils/getInfo';
import { getOffersById } from '@/api/offers';
import { showServerError } from '@/utils';
import { IApplicationConversion } from '@/api/types/uapi';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { ITableStatus } from "@/types";
import { translateUAPIConversionsStatus } from "@/utils/translate";

@Component
export default class ApplicationConversions extends Mixins(SortTableMixin) {
    @Prop({ type: String, required: true }) uuid!: string;

    conversions: IApplicationConversion[] = [];
    total = 0;
    limit = PAGINATION_PER_PAGE || 25;
    loading = false;
    offersNames: Array<{id: number, name: string}> = [];

    headers = [
        { text: 'ID', value: 'offer_id', align: 'center', sortable: false },
        { text: 'Название оффера', value: 'offer_name', align: 'center', sortable: false },
        { text: 'Дата создания', value: 'created_at', align: 'center' },
        { text: 'Сумма', value: 'amount', align: 'center' },
        { text: 'Срок', value: 'term', align: 'center' },
        { text: 'Ошибка', value: 'error', align: 'center', sortable: false },
        { text: 'Статус', value: 'status', align: 'right', sortable: false },
    ];

    numberFormatting = [
        {
            slot: 'item.amount',
            key: 'amount',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateUAPIConversionsStatus,
        },
    ];

    uniqueData = [
        {
            slot: 'item.offer_name',
            template: (): void => undefined,
        },
    ];

    created(): void {
        // используется для сортировки
        // нужен в sortTableMixins
        this.field = 'created_at';
        this.description = true;
    }

    getTemplate(item: IApplicationConversion): string {
        const offerName = this.offersNames.find(({ id }) => id === item.offer_id);
        if (offerName) {
            return `${offerName.name} (${item.offer_id})`;
        }
        return item.offer_name;
    }

    async getDataTable(offset = 0, limit = 25, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        this.loading = true;
        try {
            const { meta, data } = await getConversionsList({
                uuid: [this.$props.uuid],
                offset,
                limit,
                ...this.getFiltersParamObject,
                ...this.getSortFilters,
            });
            this.conversions = data;
            this.offersNames = await new GetInfo(data, 'offer_id', getOffersById).getInfoByFields();
            this.total = meta!.total || data.length;
        } catch (err) {
            showServerError(err, 'Конверсии не найдены');
        }
        this.loading = false;
    }
}
