
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IFilter } from "@/types";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import { eventBus } from "@/eventbus";
import uniq from "lodash-es/uniq";

@Component({
    components: { TrashBtn },
})

export default class ComboboxDesign extends Vue {
    @Prop({ required: true }) filter!: IFilter;
    @Prop({ default: false }) isNeedChangeEmit!: boolean;
    @Prop({ default: true }) isShowTrash!: boolean;
    @Prop({ default: 3 }) maxShowItems!: number;

    isShowMenu = false;
    search = '';

    created(): void {
        eventBus.$on('get-input', this.getInput);
    }

    beforeDestroy(): void {
        eventBus.$off('get-input', this.getInput);
    }

    deleteChips(select: string[] | number[], id: string | number): void {
        const index = select!.findIndex(i => i === id);
        select.splice(index, 1);
        if (this.isNeedChangeEmit  && select!.length <= 0) this.$emit('trash', this.filter);
    }

    getInput(): void {
        if (!!this.search) {
            this.filter.select.push(this.search);
        }
    }

    multiPaste(): void {
        if (this.filter.multiPaste && !!this.search) {
            const arr = this.search.trim().split(/[ ,]+/);
            if (arr.length > 1) {
                if (arr[arr.length - 1].length === 1) {
                    this.filter.select = [...this.filter.select, ...arr.slice(0, -1)];
                    this.search = arr[arr.length - 1];
                } else {
                    this.filter.select = [...this.filter.select, ...arr];
                    this.search = '';
                }
            } else if (arr.length === 1 && this.search.endsWith(' ')) {
                this.filter.select = [...this.filter.select, ...arr];
                this.search = '';
            }
        }
    }

    change(): void {
        this.isNeedChangeEmit && this.$emit('change', this.filter.select);
        this.search = '';
    }

    blur(): void {
        if (this.search) {
            this.filter.select = uniq([...this.filter.select, this.search]);
        }
        this.$emit('blur',this.search);
    }

    @Watch('filter.select')
    hideMenu(): void {
        if (this.$props.filter.select && this.$props.filter.select.length <= this.maxShowItems) {
            this.isShowMenu = false;
        }
    }
}
