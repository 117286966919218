
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgReload from "@/assets/icons/reload.svg";

@Component({
    components: { SvgReload },
})

export default class CancelBtn extends Vue {
    @Prop({ default: true }) readonly isTooltip!: boolean;
    @Prop({ default: false }) readonly isIcon!: boolean;
    @Prop({ default: true }) readonly isTextBtn!: boolean;
    @Prop({ default: false }) readonly isDisabled!: boolean;
    @Prop({ default: true }) readonly isBlueOutline!: boolean;
    @Prop({ default: '' }) readonly customBtnClass!: string;
    @Prop({ default: 0 }) readonly nudgeTop!: number;
    @Prop({ default: 'Отменить' }) readonly textTooltip!: string;
}
