import { ActionTree } from 'vuex';
import { IRootState } from '@/store/types';
import { IOffersState } from "@/store/modules/offers/types";
import { showServerError } from "@/utils";

export const actions: ActionTree<IOffersState, IRootState> = {
    GET_SELECTS_ITEMS: async (
        { commit },
        requestParams: { property: string, api: () => Promise<any[]>, items?: any[] }[],
    ): Promise<void> => {
        try {
            await Promise.all(requestParams.map(async req => req.items = await req.api()));
            const data = Object.fromEntries(requestParams.map(i => [i.property, i.items]));
            commit('SET_SELECTS_ITEMS', data);
        } catch (err) {
            showServerError(err, 'Данные не загружены');
        }
    },
};
