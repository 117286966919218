
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { Offer } from "@/services/offers/Offer";
import { translateStatisticsStatus } from "@/utils/translate";
import { IStatTasks } from "@/api/types/revenue";
import { NotificationMessage } from "@/utils/notification";
import { showServerError } from "@/utils";
import FormModal from "@/components/base/FormModal.vue";
import { deleteStatTask } from "@/api/revenue";

@Component({
    components: { FormModal, AppTableDesign },
})

export default class TasksTable extends Vue {
    @Prop({ required: true }) items!: Offer[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    // для отображения статусов в таблице
    statuses = [{ slot: 'item.status', key: 'status', translate: translateStatisticsStatus }];
    deletingStatTask: { id: number } = { id: 0 };
    settings = { title: 'Все загрузки', loading: true };

    headers = [
        { text: 'ID', sortable: false, value: 'id', align: 'center' },
        { text: 'Рекламодатель', sortable: false, value: 'advertiser.name' },
        { text: 'Период', sortable: false, value: 'period' },
        { text: 'Создатель', sortable: false, value: 'created_by.full_name' },
        { text: 'Счёт', sortable: false, value: 'invoice_id' },
        { text: 'Статус статистики', sortable: false, align: 'center', value: 'status' },
        { text: 'Исполнитель', sortable: false, value: 'assignee_to' },
        { text: 'Действия', align: 'center', sortable: false, value: 'actions' },
    ];

    timeData = [
        {
            slot: 'item.period',
            key: 'period',
            dateFormat: 'MM.yyyy',
            isWithTime: false,
        },
    ];

    uniqueData = [
        {
            slot: 'item.assignee_to',
            template: (item: IStatTasks): string => item.assignee_to ? item.assignee_to.first_name + ' ' + item.assignee_to.last_name : '',
        },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'stat-task',
            text: 'id',
        },
        {
            routeParam: 'id',
            slot: 'item.advertiser.name',
            routerName: 'advertiser',
            text: 'name',
            sup: 'advertiser',
        },
        {
            extraRouteParamName: 'invoice_id',
            routeParam: 'id',
            slot: 'item.invoice_id',
            routerName: 'invoice',
            text: 'invoice_id',
        },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: true,
            callback: ({ id }: {id: number}): void => {
                this.deletingStatTask.id = id;
                this.deleteStatTask();
            },
        },
    ];

    async deleteStatTask(): Promise<void> {
        try {
            await deleteStatTask(this.deletingStatTask.id);
            this.deletingStatTask.id = 0;
            new NotificationMessage(`Загрузка ${this.deletingStatTask.id} удалена`, 'info' );
            this.$emit('update-table');
        } catch (err) {
            showServerError(err, 'Не удалось удалить загрузку');
        }
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }
}

