
import { Component, Vue, Prop } from 'vue-property-decorator';
import SvgCheck from "@/assets/icons/check.svg";
import SvgEdit from "@/assets/icons/pencil.svg";

@Component({
    components: {
        SvgEdit, SvgCheck,
    },
})
export default class EditIconBtn extends Vue {
	@Prop({ required: true }) editMode!: boolean;
    @Prop({ default: false }) disabled!: boolean;
}
