import FinmediaInvoiceCreation from "@/views/finMedia/FinmediaInvoiceCreation.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import FinmediaInvoices from "@/views/finMedia/FinmediaInvoiceList.vue";
import FinmediaInvoice from "@/views/finMedia/FinmediaSingle.vue";

export default [
    {
        path: 'invoice-creation/',
        name: 'finmedia-invoice-creation',
        component: FinmediaInvoiceCreation,
        meta: {
            title: 'Создание счёта',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINMEDIA,
            ],
        },
        props: true,
    },
    {
        path: 'invoices',
        name: 'finmedia-invoices',
        component: FinmediaInvoices,
        meta: {
            title: 'Счета Финмедиа',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINMEDIA,
            ],
        },
        props: true,
    },
    {
        path: 'invoice',
        redirect: 'finmedia-invoices',
    },

    {
        path: 'invoice/:id',
        name: 'finmedia-invoice',
        component: FinmediaInvoice,
        meta: {
            title: 'Детальная страница счёта',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINMEDIA,
            ],
        },
        props: true,
    },
];
