
import { Component, Prop, Vue } from 'vue-property-decorator';
import PageLayout from "@/components/layout/PageLayout.vue";
import SurveyHeader from "@/components/support/surveys/SurveyHeader.vue";
import CreateQuestion from "@/components/support/surveys/CreateQuestion.vue";
import { ISurveyQuestion } from "@/api/types/support";
import { SurveyQuestion } from "@/services/surveys/SurveyQuestion";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import Question from "@/components/support/surveys/Question.vue";
import draggable from 'vuedraggable';
@Component({
    components: {
        TrashBtn,
        SurveyHeader,
        PageLayout,
        Question,
        CreateQuestion,
        draggable,
    },
})
export default class SurveyBody extends Vue {
    @Prop({ default: {} }) questions!: SurveyQuestion[] | ISurveyQuestion[];
    @Prop({ default: true }) isTitle!: boolean;
    @Prop({ default: true }) isCreateSurvey!: boolean;

    // TODO Добавить тип
    get questionComponent(): any {
        return this.isCreateSurvey ? CreateQuestion : Question;
    }

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 890;
    }
}
