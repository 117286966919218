var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-card',{staticClass:"link-constructor",attrs:{"title":"Конструктор ссылок"},scopedSlots:_vm._u([{key:"titleAction",fn:function(){return [_c('v-switch',{staticClass:"base-switch link-constructor__switch",class:{'--isOff': !_vm.isTestMode},attrs:{"label":"Тестовый режим","color":"#1C2F45","ripple":false,"hide-details":""},model:{value:(_vm.isTestMode),callback:function ($$v) {_vm.isTestMode=$$v},expression:"isTestMode"}})]},proxy:true}])},[_c('div',{staticClass:"link-constructor__options"},[_vm._l((_vm.getBaseOptions),function(item,index){return _c('div',{key:index,staticClass:"base-list__item link-constructor__option-item"},[_c('span',{staticClass:"base-list__text",class:{'--disabled': item.text === 'Партнёр' && _vm.isTestMode}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('div',{staticClass:"base-list__value"},[(item.type === 'select')?_c('base-select',{attrs:{"value":_vm.$data[item.id],"items":item.items,"item-text":item.itemText,"item-value":item.itemValue,"is-clearable":item.isClearable,"is-disabled":item.isDisabled},on:{"update:value":function($event){return _vm.$set(_vm.$data, item.id, $event)},"select":function($event){return _vm.selectOption(item, $event)}}}):_vm._e(),(item.type === 'autocomplete')?_c('base-autocomplete',{attrs:{"data":{
                        apiMethod: item.apiMethod,
                        template: item.template,
                        itemValue: item.itemValue,
                        disabled: item.isDisabled,
                        clearable: true,
                    },"is-hide-value":_vm.isTestMode},on:{"change":_vm.selectAffiliate}}):_vm._e(),(item.type === 'erid')?_c('v-text-field',{attrs:{"disabled":"","dense":"","hide-details":""},model:{value:(_vm.erid),callback:function ($$v) {_vm.erid=$$v},expression:"erid"}}):_vm._e()],1)])}),_vm._l((10),function(i){return _c('div',{key:`sub${i}`,staticClass:"base-list__item link-constructor__option-item --sub"},[_c('span',{staticClass:"base-list__text"},[_vm._v(" Aff Sub "+_vm._s(i)+" ")]),_c('div',{staticClass:"base-list__value"},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","clearable":""},model:{value:(_vm.subs[i - 1]),callback:function ($$v) {_vm.$set(_vm.subs, i - 1, $$v)},expression:"subs[i - 1]"}})],1)])}),_c('div',{staticClass:"base-list__item link-constructor__option-item --link"},[_c('span',{staticClass:"base-list__text"},[_c('SvgLink'),_vm._v(" Ссылка ")],1),_c('div',{staticClass:"base-list__value"},[_c('v-textarea',{staticClass:"link-constructor__link",attrs:{"value":_vm.getLink,"auto-grow":"","rows":"1","hide-details":"","dense":"","readonly":""},on:{"click":_vm.copyUrl},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('tooltip-button',{attrs:{"text":_vm.getCopyText,"direction":"left","delay":0,"ripple":false,"nudge":10},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('button',{staticClass:"link-constructor__copy-btn",on:{"click":_vm.copyUrl}},[_c('SvgCopy')],1)]},proxy:true}])})]},proxy:true}])})],1)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }