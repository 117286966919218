
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import {
    getSubstitutionList, deleteSubstitution,
} from '@/api/uapi';
import { ISubstitutionItem } from '@/api/types/uapi';
import AppTable from '@/components/base/table/AppTable.vue';
import FormModal from '@/components/base/FormModal.vue';
import Filters from '@/components/base/filters/Filters.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { eventBus } from '@/eventbus';
import { showServerError } from '@/utils';
import DistributionRulesModal from "@/views/uapi/settings/distribution-rules/DistributionRulesModal.vue";

@Component({
    components: {
        DistributionRulesModal,
        AppTable,
        TooltipButton,
        FormModal,
        SvgApplication,
        Filters,
    },
})
export default class DistributionRulesList extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    settings = {
        title: 'Правила распределения трафика',
        loading: false,
    };
    headers = [
        { text: 'ID партнёра', value: 'affiliate_id' },
        { text: 'Правило распределения', value: 'parameters', align: 'left' },
        { text: '', value: 'actions' },
    ];
    showDistributionModal = false;
    editingData: any = null;
    uniqueData = [
        {
            slot: 'item.parameters',
        },
    ];
    items: ISubstitutionItem[] = [];
    total = 0;
    limit = 25;
    deletingSubstitution = 0;
    actions = [
        {
            title: 'Редактировать',
            color: 'success',
            icon: 'edit',
            callback: (item: ISubstitutionItem): void => {
                this.showEditModal(item);
            },
        },
        {
            title: 'Удалить',
            color: 'error',
            icon: 'delete',
            callback: (item: ISubstitutionItem): void => this.showModal(item),
        },
    ];

    created(): void {
        this.setFiltersParamsObject({});
        this.getSubstitutionList(0, this.limit );
    }

    async getSubstitutionList(offset: number, limit: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        try {
            const { meta, data } = await getSubstitutionList({ offset, limit, ...this.getFiltersParamsObject }, true);
            this.items = data;
            this.total = meta!.total!;
        } catch (err) {
            showServerError(err, 'Правила распределения трафика не загружены');
        }
    }

    showModal(item: ISubstitutionItem): void {
        this.deletingSubstitution = item.id!;
    }
    showEditModal(item: any): void {
        this.editingData = item;
        this.showDistributionModal = true;
    }

    async deleteSubstitution(): Promise<void> {
        if (!this.deletingSubstitution) { return; }
        try {
            await deleteSubstitution(this.deletingSubstitution);
            const itemIndex = this.items.findIndex((item) => item.id === this.deletingSubstitution);
            if (itemIndex !== -1) {
                this.items.splice(itemIndex!, 1);
            }
            this.deletingSubstitution = 0;
        } catch (err) {
            showServerError(err, 'Не удалось удалить правило распределения трафика');
        }
    }
    createTableItem(): void {
        this.getSubstitutionList(0, this.limit );
        this.editingData = null;
        this.showDistributionModal = false;
    }
}
