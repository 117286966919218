import { render, staticRenderFns } from "./ActiveLimit.vue?vue&type=template&id=6a542e4a&scoped=true&"
import script from "./ActiveLimit.vue?vue&type=script&lang=ts&"
export * from "./ActiveLimit.vue?vue&type=script&lang=ts&"
import style0 from "./ActiveLimit.vue?vue&type=style&index=0&id=6a542e4a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../.yarn/__virtual__/vue-loader-virtual-872bafd3cb/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-753a6b6da2.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a542e4a",
  null
  
)

export default component.exports