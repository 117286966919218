
import { Component, Vue, Prop } from 'vue-property-decorator';
import FormModal from '@/components/base/FormModal.vue';
import PaginationRight from '@/components/base/table/PaginationRight.vue';
import { ILeadgidLegal } from '@/api/types/payout';
import { PAGINATION_RIGHT_PER_PAGE } from '@/configs/global';

@Component({
    components: {
        FormModal,
        PaginationRight,
    },
})

export default class ContractorsTable extends Vue {
    @Prop({ required: true }) readonly contractors!: ILeadgidLegal[] ;

    headers = [
        {
            text: 'Имя',
            value: 'name',
            divider: true,
            width: '30%',
            class: 'font-weight-regular',
        },
        {
            text: 'Адрес',
            value: 'address',
            class: 'font-weight-regular',
        },
    ];

    // for pagination
    pageCount = 0;
    itemsPerPage = PAGINATION_RIGHT_PER_PAGE;
    itemsPerPageSelect: any[] = [
        {
            value: PAGINATION_RIGHT_PER_PAGE,
            text: PAGINATION_RIGHT_PER_PAGE.toString(),
        },
        {
            value: 20,
            text: '20',
        },
        {
            value: 100,
            text: 'Все',
        },
    ];
    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };

    // изменить число строк в таблице
    changeItems(evt: string): void {
        this.itemsPerPage = parseInt(evt, 10);
    }
}
