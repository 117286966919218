
import { Component, Prop, PropSync, Ref, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class';
import FormModal from "@/components/base/FormModal.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getAffiliateSearch } from "@/api/user";
import SvgMagicWandLight from '@/assets/icons/magic-wand-light.svg';
import { createOrUpdateStatusApproval, getEmployeesOffersV2 } from "@/api/offers";
import Loader from "@/components/base/Loader.vue";
import { showServerError } from "@/utils";
import { NotificationMessage } from "@/utils/notification";
import { IPostCreateOrUpdateApproval, IRawPostCreateNewApproval } from '@/api/types/offers';
import router from "@/router";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
const offers = namespace('offersModule');

@Component({
    methods: { getEmployeesOffersV2, getAffiliateSearch },
    components: {
        BaseSelect,
        Loader,
        BaseAutocomplete,
        FormModal,
        SvgMagicWandLight,
    },
})
export default class FormCreateApproval extends Vue {
    @PropSync('showModal') showModalSync!: boolean;
    @Prop() defaultPartner!: number;
    @Ref() affiliateInput!: BaseAutocomplete;
    @Ref() offersInput!: BaseAutocomplete;
    @offers.Getter('GET_CURRENT_OFFER') getCurrentOffer;

    isLoading:boolean = false;
    approval: IRawPostCreateNewApproval = {
        offers_ids: [],
        affiliates_ids: [],
        status: 'approved',
    };
    statuses: any[] = [{ id: 'approved', name: 'Одобрен' }, { id: 'rejected' , name: 'Отклонён' }];

    checkRoute(route: string): boolean {
        return router.currentRoute.name === route;
    }

    updateStatus(value: any): void {
        this.approval.status = value;
    }

    mounted(): void {
        if (!!this.defaultPartner && this.checkRoute('affiliate')) {
            this.approval.affiliates_ids.push(this.defaultPartner);
        }
        if (this.getCurrentOffer && this.checkRoute('offer')) {
            this.approval.offers_ids.push(this.getCurrentOffer.legacy_id);
        }
    }

    get getTitle(): string {
        return 'Создание доступа';
    }

    get isDisableCreateBtn(): boolean {
        return this.approval.offers_ids.length === 0 || this.approval.affiliates_ids.length === 0;
    }

    closeModal(): void {
        this.clearInputs();
        this.showModalSync = false;
    }

    clearInputs(): void {
        this.approval.offers_ids = [];
        this.approval.affiliates_ids = [];
        this.affiliateInput.clearModel();
        this.offersInput.clearModel();
    }

    updateAffiliates(items: number[]): void {
        this.approval.affiliates_ids = items;
    }
    updateOffers(items: number[]): void {
        this.approval.offers_ids = items;
    }

    async createNewApproval(): Promise<void> {
        this.isLoading = true;
        try {
            const pairs = this.approval.affiliates_ids.flatMap(a => {
                return this.approval.offers_ids.map(o => {
                    return { offer_id: o, affiliate_id: a };
                });
            });
            const data: IPostCreateOrUpdateApproval = { pairs, status: this.approval.status };
            await createOrUpdateStatusApproval(data);
            const filters = {
                affiliate_id: this.approval.affiliates_ids,
                offer_legacy_id: this.approval.offers_ids,
            };
            await this.$emit('edit', filters);
            this.showNotification();
            this.closeModal();
        } catch (err) {
            showServerError(err, 'Доступ не создан');
        } finally {
            this.isLoading = false;
            this.approval.status = 'approved';
        }
    }

    showNotification(): void {
        const notificationText = `Доступ успешно создан`;
        new NotificationMessage(notificationText, 'success' );
    }
}
