
import { Component, Vue } from 'vue-property-decorator';
import { IApplicationItem } from '@/api/types/uapi';
import { getApplicationItem } from '@/api/uapi';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import StatsCard from '@/components/base/StatsCard.vue';
import AppTable from '@/components/base/table/AppTable.vue';
import ApplicationAnalytics from '@/components/uapi/ApplicationAnalytics.vue';
import ApplicationConversions from '@/components/uapi/application/Converions.vue';
import { showServerError } from '@/utils';

@Component({
    components: {
        SvgApplication,
        StatsCard,
        AppTable,
        ApplicationConversions,
        ApplicationAnalytics,
    },
})

export default class Application extends Vue {
    panel: number[] = [];
    uuid = '';
    application: IApplicationItem | null = null;

    mainInfoKeys: {[key: string]: string} = {
        uuid: 'UUID',
        affiliate_id: 'ID партнёра',
        created_at: 'Дата создания',
        amount: 'Сумма',
        term: 'Срок',
        purpose: 'Цель кредита',
        type: 'Тип кредита',
        subid1: 'Sub ID 1',
        subid2: 'Sub ID 2',
        subid3: 'Sub ID 3',
        subid4: 'Sub ID 4',
        subid5: 'Sub ID 5',
        additional_fields: 'Дополнительные поля',
    };

    customerKeys: {[key: string]: string} = {
        gender: 'Пол',
        phone: 'Телефон',
        email: 'E-mail',
        birth_date: 'День рождения',
        passport: 'Паспорт',
        passport_date_of_issue: 'Дата выдачи',
        passport_issued_by: 'Кем выдан',
        passport_unit_code: 'Код подразделения',
        birthplace: 'Место рождения',
        inn: 'ИНН',
        occupation: 'Занятость',
        income: 'Доход',
    };

    async mounted(): Promise<void> {
        try {
            this.uuid = this.$route.params.uuid;
            this.application = await getApplicationItem(this.uuid);
        } catch (err) {
            showServerError(err);
        }
    }

    get fullName(): string {
        const { last_name, first_name, patronymic } = this.application!.customer;
        return `${last_name} ${first_name} ${patronymic}`;
    }

    get fullAddress(): string {
        const {
            country_name, region_name, city_name, street, house, flat,
        } = this.application!.address;
        const regionAndCity = region_name === city_name ? `г. ${city_name}` : `${region_name}, г. ${city_name}`;
        const hasStreet = street ? `, ${street}` : '';
        const hasHouse = house ? `, д. ${house}` : '';
        const hasFlat = flat ? `, кв. ${flat}` : '';
        return `${country_name}, ${regionAndCity} ${hasStreet} ${hasHouse} ${hasFlat}`;
    }

}
