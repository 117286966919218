
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import CardMaterial from '@/components/base/CardMaterial.vue';
import { eventBus } from '@/eventbus';
import AddingServicesTable from '@/components/revenue/AddingServicesTable.vue';
import OfferServicesSelectTable from '@/components/revenue/offerServicesSelectTable.vue';
import { IOfferService, IOtherService } from '@/api/types/revenue';
import Multiselect from '@/components/base/form/Multiselect.vue';
import { getRandomId } from '@/utils';
import { MULTISELECT_TYPE } from '@/mappings/multiSelectDataType';

@Component({
    components: {
        AddingServicesTable,
        CardMaterial,
        OfferServicesSelectTable,
        Multiselect,
    },
})

export default class AddingServices extends Vue {
    @Prop({ required: false }) services!: [];
    @Prop({ required: false }) offerServicesFromFiles!: [];
    @Prop({ required: false }) keyItemSelect!: '';
    @Prop({ required: false }) isCalendarsAdded!: boolean;
    @Prop({ required: false }) showSelect!: boolean;
    @Prop({ required: false }) showItems!: boolean;
    @Prop({ required: false }) accountingStatus!: string;
    @Prop({ required: false }) isClickable!: boolean;
    /* пропс нужен для того, чтобы * прикрепить выпадашку к нужному полю*/
    @Prop({ type: [Number, String], default: 1 }) id!: string | number;
    // опциональный пропс, по нему можем задавать  какие-то дополнительные условия для компонента, в зависимости от места использования
    @Prop({ required: false }) type!: [];
    // Элементы, добавленные в таблицу
    @Prop({ required: true }) tableItems!: [];
    // Если передали этот пропс, значит показываем таблицу добавления услуг по офферам
    @Prop({ required: false }) addingOfferServices!: boolean;
    // Если передали этот пропс, значит показываем таблицу добавления прочил услуг
    @Prop({ required: false }) otherServices!: boolean;
    // Данные для селекта в шапке таблицы
    @Prop({ required: false }) dataForSelect!: boolean;
    // Передаем пропс, если сервис добавления услуг внутри бухгалтерского счёта
    @Prop({ required: false }) isAccounting!: boolean;
    // Index бухгалтерского счёта, используется для добавления новых офферов и прочих услуг
    @Prop({ required: false }) accountIndex!: number;
    // Возможность указать, что услуга относится к прямой выручке
    @Prop({ required: false }) directSupport!: boolean;
    // Заголовки таблицы
    @Prop({ required: false }) tableHeaders!: [];
    // Статусы
    @Prop({ default: () => [] }) statuses!: [];
    @Prop({ default: () => [] }) statusesForSelectTable!: [];
    @Prop({ default: () => [] }) actions!: [];
    // Показывать или нет "Итого" в конце таблицы
    @Prop({ type: Boolean, default: false }) showTotal!: boolean;
    // Иконка валюты
    @Prop({ type: String, required: false }) currencyIcon!: string;
    @Prop({ required: false }) offerServicesSelectTableHeaders!: [];
    @Prop({ default: false }) isPrepayment!: boolean;
    @Prop({ required: false, default: false }) isFinmedia!: string;

    @Getter('GET_EDITING_MODE') getEditingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    // Услуга, выбранная в услугах по офферам
    selectedOfferService: object[] = [];
    // Услуга, выбранная в прочих услугах
    selectedOtherService: object[] = [];
    OTHER_SERVICES = MULTISELECT_TYPE.OTHER_SERVICES;
    SERVICES = MULTISELECT_TYPE.SERVICES;

    getOffersTemplate(item: IOfferService): string {
        const {
            offer_name = '', offer_id = '', goal_name = '', goal_id = '', offer_rate = '',
        } = item;
        return `${offer_name} (${offer_id}) -
            ${goal_name || 'по умолчанию'} (${goal_id})

            Ставка : ${offer_rate}`;
    }

    getOtherTemplate(item: IOtherService): string {
        return item.service_name;
    }

    getKey(item: any): string {
        return item.key;
    }

    get getDataForSelect(): object[] {
        this.$props.dataForSelect?.forEach((item) => {
            if (!item.tempId) {
                item.tempId = getRandomId();
            }
        });
        return this.$props.dataForSelect;
    }

    get showOfferServicesSelectTable(): boolean {
        if (this.showItems) {
            return this.getEditingMode;
        }
        return true;
    }

    get routerName(): string | void {
        return this.$route.name || '';
    }

    get isDisabled(): boolean {
        if (this.$props.isPrepayment) {
            return false;
        }
        if (this.$props.isAccounting && (
            this.$props.accountingStatus === 'paid'
            || this.$props.accountingStatus === 'partly_paid'
        )
        ) {
            return true;
        }
        return !this.getEditingMode;
    }

    changeSelect(data: any): void {
        if (this.$props.addingOfferServices) {
            this.selectedOfferService = data.items;
        } else {
            this.selectedOtherService = data.items;
        }
    }

    addServiceInTable(service: IOfferService[] | IOtherService[], index: number): void {
        const eventName = this.$props.addingOfferServices
            ? 'add-offer-service'
            : 'add-other-service';

        if (this.$props.isAccounting) {
            service.forEach((item) => {
                eventBus.$emit(`${eventName}-accounting`, item, index, eventName);
            });
            return;
        }
        service.forEach((item) => {
            eventBus.$emit(eventName, item);
        });

        this.setUnsavedDataState(true);
    }
}
