
import { Component, Vue } from 'vue-property-decorator';
import AffiliateInvoicesWrap from "@/components/affiliate/affiliateInvoices/AffiliateInvoicesWrap.vue";
import { getMasterAccountMyLeadgidInvoices } from "@/api/org-cabinets-backend";
import {
    MasterAccountMyLeadgidInvoicesColumns,
    MasterAccountMyLeadgidInvoicesFilters, MasterAccountMyLeadgidInvoicesHeaders,
} from "@/services/TablePage/masterAccountMyLeadgidInvoices";

@Component({
    methods: { getMasterAccountMyLeadgidInvoices },
    components: {
        AffiliateInvoicesWrap,
    },
})
export default class MasterAccountMyLeadgidInvoices extends Vue {
    filters = new MasterAccountMyLeadgidInvoicesFilters();
    columns = new MasterAccountMyLeadgidInvoicesColumns();
    tableHeaders = MasterAccountMyLeadgidInvoicesHeaders;

    transformParamsObjBeforeSubmit(params: any): any | Promise<any> {
        delete params.search;
        return [Number(this.$route.params.id), params];
    }
}
