
import { Vue, Component } from "vue-property-decorator";
import { getEmployeesDepartments } from "@/api/offers";
import AdministrationOffersLayout from "@/components/layout/AdministrationOffersLayout.vue";

@Component({
    methods: { getEmployeesDepartments },
    components: { AdministrationOffersLayout },
})

export default class Departments extends Vue {}
