
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IColumnFilter } from "@/types";
import TablePageFilters from "@/components/base/filters/TablePageFilters.vue";
import { surveysFilterColumns, SurveysFilters } from "@/services/TablePage/surveys";
import { PAGINATION_PER_PAGE } from "@/configs/global";

@Component({
    components: {
        TablePageFilters,
    },
})
export default class FiltersSurvey extends Vue {
    @Prop({ default: PAGINATION_PER_PAGE }) limit!: number;

    filters = new SurveysFilters();

    get columns(): IColumnFilter[] {
        return surveysFilterColumns;
    }

    submitFilters(offset: number, limit?: number): void {
        this.$emit('submit', offset, limit);
    }
}
