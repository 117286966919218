
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import Status from '@/components/base/Status.vue';
import SaveBtnTooltip from '@/components/base/buttons/SaveBtnTooltip.vue';
import { IFilter } from '@/types';
import { ICountry, ICurrency } from '@/api/types/catalogue';
import groupBy from 'lodash-es/groupBy';
import DateRange from '@/components/base/pickers/DateRange.vue';
import MultiselectDesign from "@/components/base/form/MultiselectDesign.vue";
import AutocompleteListItem from "@/components/base/form/AutocompleteListItem.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import ComboboxDesign from "@/components/base/form/ComboboxDesign.vue";
import ComboboxArrayDesign from "@/components/base/form/ComboboxArrayDesign.vue";
import MonthPicker from "@/components/base/pickers/MonthPicker.vue";
import MonthPickerRange from "@/components/base/pickers/MonthPickerRange.vue";
import { filterClass } from '@/services/filters/filterClass';
import SvgFalse from "@/assets/icons/false-icon.svg";
import SvgTrue from "@/assets/icons/true-icon.svg";
import WeekPicker from "@/components/base/pickers/WeekPicker.vue";
import AutocompleteFilter from "@/components/base/form/AutocompleteFilter.vue";
import CompareFilter from "@/components/base/form/CompareFilter.vue";

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        CompareFilter,
        AutocompleteFilter,
        WeekPicker,
        SvgTrue,
        SvgFalse, MonthPicker, MonthPickerRange, ComboboxArrayDesign, ComboboxDesign, SelectDesign, AutocompleteListItem, MultiselectDesign, Status, SaveBtnTooltip, DateRange, TrashBtn },
})

export default class FiltersBaseDesign extends Vue {
    @Prop({ type: String, default: 'Фильтры' }) readonly title!: string;
    @Prop({ type: Array, default: () => [], required: true }) readonly filters!: IFilter[];
    @Prop({ default: false }) readonly isGroupBy!: boolean;
    @Prop({ type: Object, default: () => ({}) }) readonly selectedFilters!: object; // for chips
    @Prop({ type: String, default: 'Применить' }) readonly successBtnText!: string;
    @Prop({ type: String, default: 'Сбросить' }) readonly cancelBtnText!: string;
    @Prop({ type: Number, default: 4 }) readonly sizeLG!: number;
    @Prop({ type: Number, default: 4 }) readonly sizeXL!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeMD!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeSM!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeMain!: number;
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isSuccessBtn!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isUsedBlurAutocomplete!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isCancelBtn!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isTrashBtn!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isClearable!: boolean;
    @Prop({ type: Boolean, default: false }) readonly needTooltip!: boolean;
    @Prop({ type: Array, default: ()=>[] }) readonly showColumnTitle!: (string | number)[];
    @Prop({ type: Object, required: false, default: () => ({}) }) readonly emptyConditions!: object;
    @Prop({ type: String, default: 'top' }) readonly tooltipPosition!: string;
    @Prop() readonly disabledDates!: any;
    @catalogue.Getter('GET_CURRENCIES') currencies!: ICurrency[];
    @catalogue.Getter('GET_COUNTRIES') countries!: ICountry[];

    // for data-picker
    visibleDatePickers = {};
    filterClass = filterClass;

    get getAttachClass():string | boolean {
        if (this.$vuetify.breakpoint.width <= 890 || this.$route.name === 'extended-report-advertiser') {
            return false;
        } else {
            return '.data-range__input-calendar-false';
        }
    }

    getContentClass(id: string): string {
        const baseClasses = 'data-range__content data-range__content-filter';
        if (this.$route.name === 'extended-report-advertiser') return baseClasses + ' advertiser-date' + ` ${id}`;
        return this.$route.name?.includes('extended-report') || this.$route.name === 'debtors'
            ? baseClasses + ` ${id}` : baseClasses;
    }

    changeExcluded(filter: IFilter): void {
        if (filter.isExcluded !== undefined) {
            filter.isExcluded = !filter.isExcluded;
        }
    }

    getFormatDatesForDatepicker = getFormatDatesForDatepicker;

    changeDialogStyle(isShowMenu: boolean, id: string): void {
        const filtersDialog = document.querySelector('.filters-dialog.v-dialog--active') as HTMLElement;
        this.visibleDatePickers[id] = isShowMenu;
        if (!!filtersDialog && isShowMenu) {
            filtersDialog.style.setProperty('overflow-x', 'visible', 'important');
        } else if (Object.values(this.visibleDatePickers).every(i => !i)) {
            filtersDialog?.style!.removeProperty('overflow-x');
        }
    }

    changeMultiselectItems({ type, items }: any): void {
        const currentFilter = this.filters.find((filter) => filter.id === type);
        if (currentFilter) {
            currentFilter.select = items;
        }
    }

    clear(filter: IFilter): void {
        filter.clear();
    }

    get hasError(): boolean {
        return Object.keys(this.emptyConditions).length > 0 && this.needTooltip;
    }

    get disableSubmitBtn(): boolean {
        return this.$props.disabled || this.hasError;
    }

    get groupByFilters(): Record<string, IFilter[]> {
        return groupBy(this.filters, (filter) => filter.col);
    }

    created(): void {
        this.filters.forEach((fl) => {
            if (fl.isCurrencyCatalogue) {
                fl.items = this.currencies;
                fl.template = this.getCurrenciesTemplate;
            }
            if (fl.isCountryCatalogue) {
                fl.items = this.countries;
            }
        });
    }

    getCurrenciesTemplate(item: ICurrency): string {
        return item.code;
    }

    submitFilters(): void {
        // TODO проверить на страницах, вынести в createdFilters.submit
        this.filters.forEach((fl) => {
            if (fl.array && fl.supValue && !fl.select?.includes(fl.supValue)) {
                fl.select.push(fl.supValue);
                fl.supValue = '';
            }
        });
        this.$emit('submit');
    }
}
