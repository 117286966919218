
import { Vue, Component, PropSync, Ref, Prop, Watch } from 'vue-property-decorator';
import SvgConstructor from '@/assets/icons/constructor.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";

@Component({
    components: { SvgConstructor, TrashBtn },
})
export default class ConstructorWrap extends Vue {
    @Prop({ type: String, required: true }) readonly attachClass!: string;
    @Prop({ default: 1263 }) readonly mobileWidth!: number;
    @Prop({ default: true }) readonly isNeedAttach!: boolean;
    @Prop({ type: Array, required: true }) readonly tabItems!: string[];

    @PropSync('tab') tabSync!: number | number[];
    @PropSync('isShowConstructor') isShowConstructorSync!: boolean;

    @Ref() readonly filtersConst!: HTMLInputElement;

    currency = false;
    isScroll = true;

    changeTab(e: number | number[]): void {
        this.tabSync = e;
    }
    created(): void {
        this.getTab();
    }
    @Watch('isSmallMobile')
    getTab(): void {
        if (!this.isSmallMobile) {
            this.tabSync = [...this.tabItems.keys()];
        } else {
            this.tabSync = this.tabItems.length - 1;
        }
    }
    toggleConstructor(): void {
        this.isShowConstructorSync = !this.isShowConstructorSync;
        if (this.isShowConstructorSync ) {
            // костыль чтоы получить высоту фильтров, nextTick не работает
            setTimeout(() => {
                // @ts-ignore
                this.isScroll = this.$refs.filtersConst.clientHeight + 200 >= document.body.clientHeight;
            }, 50);
        }
    }

    clearFilters(): void {
        this.$emit('clear');
    }

    get isAttach(): boolean {
        if (!this.isNeedAttach) {
            return false;
        } else {
            return this.$vuetify.breakpoint.width >= this.mobileWidth;
        }
    }

    get isSmallMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 576;
    }
}
