import { AxiosResponse } from 'axios';
import { axiosInstance } from '@/api/index';
import {
    IParamsObject,
    IServerResponse,
} from '@/api/types';
import { ICountry, ICurrency } from '@/api/types/catalogue';
import { API_CATALOGUE_URL, API_CATALOGUE_URL_V3 } from '@/configs/global';

/**
 *  Получение курса валют
 */
export async function getCurrencyRate(
    params?: IParamsObject,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_CATALOGUE_URL_V3}/currency-rate`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}
/**
 *  Получение списка стран
 */
export async function getCountries(
    params?: IParamsObject,
): Promise<ICountry[]> {
    return axiosInstance.request<IServerResponse<ICountry[]>>({
        method: 'GET',
        url: `${API_CATALOGUE_URL}/countries`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<ICountry[]>>) => response.data.data);
}

/**
 *  Получение списка валют
 */
export async function getCurrencies(
    params?: IParamsObject,
): Promise<ICurrency[]> {
    return axiosInstance.request<IServerResponse<ICurrency[]>>({
        method: 'GET',
        url: `${API_CATALOGUE_URL}/currencies`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<ICurrency[]>>) => response.data.data);
}
