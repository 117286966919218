
import { Vue, Component } from "vue-property-decorator";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { namespace } from "vuex-class";
import SvgChart from "@/assets/icons/chart.svg";

const offerStore = namespace('offersModule');

@Component({
    components: { BaseSelect, SvgChart },
})

export default class OfferPeriodSelect extends Vue {
    @offerStore.State('statPeriodForList') statPeriod!: string ;
    @offerStore.Mutation('SET_PERIOD') setPeriod!: Function;

    items = [
        { name: 'За неделю', value: 7 },
        { name: 'За 14 дней', value: 14 },
        { name: 'За 30 дней', value: 30 },
    ];

    selectPeriod(value: number): void {
        this.$emit('select', value);
        this.setPeriod(value);
    }
}
