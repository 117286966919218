
import { Vue, Component, PropSync } from "vue-property-decorator";
import FormModal from "@/components/base/FormModal.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import WeekPicker from "@/components/base/pickers/WeekPicker.vue";
import ImageInput from "@/components/base/form/ImageInput.vue";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import { IBaseAutocomplete } from "@/types";
import { ICountry, ICurrency } from "@/api/types/catalogue";
import {
    addOfferLogo,
    getEmployeesOffersV2,
    getOneEmployeesOffer,
    postOffersTopOfWeek,
} from "@/api/offers";
import { getCurrencyIcon } from "@/utils/translate";
import SvgTrash from "@/assets/icons/trash.svg";
import SvgTrashDark from "@/assets/icons/trash-dark.svg";
import SvgPlus from "@/assets/icons/plus.svg";
import SvgCreate from "@/assets/icons/magic-wand-light.svg";
import { namespace } from "vuex-class";
import { showNotification, showServerError } from "@/utils";
import { eventBus } from "@/eventbus";
import { ICreateOfferTopOfWeek } from "@/api/types/offers";

enum STATUS {
    DRAFT = 'draft',
    PUBLISHED = 'published',
}

const catalogue = namespace('catalogueModule');

@Component({
    methods: { getEmployeesOffersV2, getCurrencyIcon },
    components: { SvgPlus, SvgCreate, SvgTrashDark, EditModeTextField, ImageInput, BaseAutocomplete, BaseSelect, WeekPicker, FormModal, SvgTrash },
})

export default class CreateTopOfWeek extends Vue {
    @PropSync('showModal', { required: true }) showModalSync!: boolean;


    @catalogue.Getter('GET_COUNTRIES') countries!: ICountry[];
    @catalogue.Getter('GET_CURRENCIES') currencies!: ICurrency[];

    STATUS = STATUS;

    countrySelectOptions: IBaseAutocomplete = {
        itemValue: 'code',
        itemText: 'name',
        template: (i: any): any => i.name,
        clearable: true,
    };
    header = [
        { name: 'Страна', type: 'country' },
        { name: 'Неделя', type: 'week' },
        { name: 'Валюта', type: 'currency' },
    ];
    country: string = '';
    currency: string = '';
    period: string[] = [];
    newOffer: any = {};
    offers: any[] = [];
    status: string = STATUS.DRAFT;
    startId: number = 0;

    mounted(): void {
        this.addOfferTemplate();
    }

    get isFullHead(): boolean {
        return !!this.currency && !!this.country && this.period.length > 0;
    }
    get isNewOffer(): boolean {
        return !Object.keys(this.newOffer).every(key => !!this.newOffer[key]);
    }

    get isBlockPublish(): boolean {
        const isBlock = !this.offers.every(i => (typeof i.logo === 'string' && i.logo.length > 0) || i.logo instanceof File);
        if (isBlock) this.status = STATUS.DRAFT;
        return isBlock;
    }

    get isDisableCreate(): boolean {
        return !(this.offers.every(i => !!i.id && !!i.logo && !!i.epc) && this.isFullHead);
    }

    get getLocalId(): number {
        //Для адекватного поведения списка
        const id = this.offers.length === 0 ? 1 : this.offers[this.offers.length - 1].localId + 1;
        return id + this.startId;
    }

    closeTop(): void {
        this.showModalSync = false;
        this.clearTop();
    }

    clearTop(): void {
        this.period = [];
        this.country = '';
        this.currency = '';
        this.status = STATUS.DRAFT;
        this.offers = [];
        eventBus.$emit('clear');
        this.addOfferTemplate();
    }

    addOfferTemplate(): void {
        this.newOffer = { id: undefined, localId: this.getLocalId, epc: 0, logo: null, name: '' };
        this.offers.push(this.newOffer);
    }

    addOffer(): void {
        this.newOffer = {};
        this.addOfferTemplate();
    }

    async chooseOffer(id: number): Promise<void> {
        if (!id) return;
        try {
            const { logo_toprec, name } = await getOneEmployeesOffer(id, { by_legacy_id: 1 });
            this.newOffer.id = id;
            this.newOffer.logo = logo_toprec;
            this.newOffer.name = name;
        } catch (err) {
            showServerError(err, 'Оффер не загружен');
        }
    }

    deleteOffer(item: any): void {
        if (this.offers.length === 1) {
            this.offers = [];
            this.startId++;
            this.addOfferTemplate();
            return;
        }
        if (!item.id || !item.epc) {
            this.newOffer = {};
        }
        const index = this.offers.findIndex((i) => i.id === item.id);
        this.offers.splice(index, 1);
    }

    async createTop(): Promise<void> {
        try {
            await this.uploadLogo();
            const offers = this.offers.map(({ id, epc }) => ({ id, epc }));
            const period = this.period[0];
            const top: ICreateOfferTopOfWeek = { period, offers, country: this.country, currency: this.currency, status: this.status };
            await postOffersTopOfWeek(top);
            showNotification('Топ успешно создан');
            this.showModalSync = false;
            this.$emit('update');
        } catch (err) {
            showServerError(err, 'Топ не создан');
        }
    }

    async uploadLogo(): Promise<void> {
        try {
            const offersWithNewLogo = this.offers.filter(i => i.logo instanceof File);
            await Promise.all(offersWithNewLogo.map(({ id, logo }) => addOfferLogo(logo as File, id as number)));
        } catch (err) {
            showServerError(err, 'Ошибка загрузки логотипа');
        }
    }

}

