
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OfferNote, OfferSingle } from "@/services/offers/OfferSingle";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import CardNotes from "@/components/base/CardNotes.vue";
import NotesTooltip from "@/components/base/NotesTooltip.vue";
import ImageNotesFile from "@/components/base/ImageNotesFile.vue";

@Component({
    components: {
        ImageNotesFile, NotesTooltip, CardNotes,
        TableWithFrontPaginationDesign,
    },
})
export default class OfferCardNotes extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    headers = [
        { text: 'Дата', sortable: false, value: 'created_at' },
        { text: 'Автор', sortable: false, value: 'author' },
        { text: 'Заметка', sortable: false, value: 'text' },
        { text: 'Файл', sortable: false, value: 'file', align: 'center' },
        { text: '', sortable: false, value: 'actions', align: 'center' },
    ];
    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
    ];

    async deleteNote(item: OfferNote): Promise<void> {
        await item.deleteNote(item.id);
        this.updateTable();
    }

    async saveNote(note: OfferNote): Promise<void> {
        const preparedNote = {
            image: note.image,
            text: note.text,
        };
        await note.patchNote(note.id, preparedNote);
        this.updateTable();
    }

    updateTable(): void {
        this.offer.getNotes();
    }

    addNote(note: string, image: any): void {
        this.offer.createNotes(note, image);
    }
}
