
import { Component } from 'vue-property-decorator';
import { RegistrationColumns } from "@/services/TablePage/registrations";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import AffiliatesList from "@/views/affiliates/AffiliatesList.vue";

@Component({
    components: {
        TablePageWrapper,
    },
})

export default class Registrations extends AffiliatesList {
    columns = new RegistrationColumns().columns;
}
