
import {
    Component, Inject, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import AddingServices from '@/components/revenue/AddingServices.vue';
import { eventBus } from '@/eventbus';
import { getCurrencyIcon, translateGoalStatus } from '@/utils/translate';
import {
    getInvoiceOtherServices,
    getOfferServices,
} from '@/api/revenue';

import {
    IInvoice,
    IInvoiceAdvertiser,
    IOfferService,
    IOtherService,
} from '@/api/types/revenue';
import { getRandomId, showServerError } from '@/utils';
import { ITableStatus } from "@/types";

@Component({
    components: {
        AddingServices,
    },
})

export default class InvoiceServices extends Vue {
    @PropSync('invoice', { required: true }) invoiceSync!: IInvoice;
    @PropSync('showWarning', { required: true }) showWarningSync!: boolean;

    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Getter('GET_EDITING_MODE') editingMode;

    @Inject() readonly setUnsavedData!: any;

    get showOfferServices(): boolean {
        return this.getServiceVisibility('offer_services');
    }

    get getCurrencyIcon(): string | undefined {
        return getCurrencyIcon(this.invoiceSync.currency as string);
    }

    get showOtherServices(): boolean {
        return this.getServiceVisibility('other_services');
    }

    get getEditMode(): boolean {
        // если счет уже создан
        if (this.invoiceSync.id) {
            return this.editingMode;
        }
        return true;
    }

    headersForTableSelectInInvoice = [
        { text: 'ID оффера', value: 'offer_id' },
        { text: 'Оффер', value: 'offer_name' },
        { text: 'ID цели', value: 'goal_id' },
        { text: 'Цель', align: 'left', value: 'goal_name' },
        { text: 'Ставка', align: 'right', value: 'offer_rate' },
        { text: 'Валюта', align: 'right', value: 'goal_currency' },
        { text: 'Всего конверсий', align: 'right', value: 'conversions_total' },
        { text: 'Принято конверсий', align: 'right', value: 'conversions_approved' },
        { text: 'Статус цели', align: 'center', value: 'goal_status' },
        { text: '', align: 'right', value: 'actions' },
    ];

    // Заголовки колонок в таблице услуг по офферам внутри счёта
    addingServicesHeaders = [
        { text: 'Оффер', value: 'offer' },
        { text: 'Цель', align: 'left', value: 'goal' },
        { text: 'Ставка', align: 'right', value: 'offer_rate' },
        { text: 'Количество', align: 'right', value: 'offer_qty' },
        { text: 'Итого', align: 'right', value: 'total' },
        { text: '', align: 'right', value: 'actions' },
    ];

    // для отображения статусов в таблице селекта по услугам
    statuses: ITableStatus[] = [
        {
            slot: 'item.goal_status',
            key: 'goal_status',
            translate: translateGoalStatus,
        },
    ];

    // Заголовки колонок в таблице прочих услуг внутри счёта
    otherServicesHeaders = [
        { text: 'Услуга', align: 'left', value: 'service_name' },
        { text: 'Сумма', align: 'left', value: 'service_amount' },
        { text: '', align: 'right', value: 'actions' },
    ];

    // список `оффер+цель`, которые можно добавить в счёт
    offerServiceSelectList: IOfferService[] = [];
    // список `прочие услуги` , которые можно добавить в счёт
    otherServiceSelectList: IOtherService[] = [];

    // если меняется рекламодатель или период, обновляем список офферов и удаляем выбранные услуги(при создании)
    @Watch('invoiceSync.advertiser')
    async changeAdvertiser(advertiser: IInvoiceAdvertiser, oldAdvertiser: IInvoiceAdvertiser): Promise<any> {
        // после сохранения счета, в объект рекла могут добавляться ключи, к-ые пришли с бека
        // из-за чего Watch видит изменения рекла, к-ых по факту не был
        // если id одинаковые, значит сам рекл не изменился ифункцию вызывать не нужно
        if (advertiser.id !== oldAdvertiser.id && this.offerServiceSelectList.length > 0) {
            return;
        }
        if (!this.invoiceSync.hasOwnProperty('id')) {
            await this.clearOffer();
        }
        await this.getOfferServicesForSelect();
    }

    @Watch('invoiceSync.period')
    async changePeriod(): Promise<any> {
        if (!this.invoiceSync.hasOwnProperty('id')) {
            await this.clearOffer();
            await this.getOfferServicesForSelect();
        }
    }

    // Получаем список `оффер+цель` для селекта
    async getOfferServicesForSelect(): Promise<void> {
        try {
            const data = await getOfferServices(this.invoiceSync!.advertiser!.id,
                { period: this.invoiceSync.period });
            this.offerServiceSelectList = Array.isArray(data) ? data : Object.values(data);
            this.offerServiceSelectList.map((offer) => {
                offer.tempId = getRandomId();
            });
        } catch (err) {
            showServerError(err, 'Список оффер + цель не загружен');
        }
    }

    mounted(): void {
        eventBus.$on('add-offer-service', this.addOfferService);
        eventBus.$on('add-other-service', this.addAnotherService);
        eventBus.$on('delete-offer', this.deleteOffer);
        eventBus.$on('calc-total-value', this.calcServiceTotalValue);
    }

    beforeDestroy(): void {
        eventBus.$off('add-offer-service', this.addOfferService);
        eventBus.$off('add-other-service', this.addAnotherService);
        eventBus.$off('delete-offer', this.deleteOffer);
        eventBus.$off('calc-total-value', this.calcServiceTotalValue);
    }

    async created(): Promise<any> {
        if (this.invoiceSync.advertiser?.id) {
            await this.getOfferServicesForSelect();
        }
        try {
            // Получаем список прочих услуг для селекта
            const { data } = await getInvoiceOtherServices({ is_active: true });
            this.otherServiceSelectList = data as IOtherService[];
        } catch (err) {
            showServerError(err, 'Список прочих услуг не загружен');
        }

        this.setUnsavedDataState(false);
    }

    // Проверяем, была ли добавлена услуга
    checkIfTheServiceHasBeenAdded(addedServices: IOtherService[], serviceForAdded: IOtherService): boolean {
        return addedServices
            .some((service) => service.service_id === serviceForAdded.service_id);
    }

    addOfferService(item: IOfferService): void {
        const newItem = { ...item };
        newItem.offer_rate = newItem.goal_revenue_type === 'cpa_flat' ? newItem.goal_revenue_flat : 0;
        newItem.total = 0;
        newItem.offer_qty = 0;
        newItem.tempId = getRandomId();

        this.invoiceSync.offer_services!.push(newItem);
    }

    addAnotherService(item: IOtherService): void {
        const addedServices = this.invoiceSync.other_services;
        const isServiceAlreadyAdded = this.checkIfTheServiceHasBeenAdded(addedServices!, item);

        if (isServiceAlreadyAdded) {
            this.showWarningSync = true;
            return;
        }

        const newItem = { ...item };
        newItem.id = null;
        newItem.tempId = getRandomId();
        newItem.service_amount = 0;
        this.invoiceSync.other_services!.push(newItem);
        this.setUnsavedData(true);
    }

    getServiceVisibility(serviceName: string): boolean {
        if (!this.invoiceSync.id) {
            return true;
        }

        if (this.invoiceSync[serviceName].length > 0) {
            return true;
        }

        return this.invoiceSync[serviceName].length === 0 && this.getEditMode;
    }

    private calcServiceTotalValue(index: number): void {
        if (this.invoiceSync?.offer_services![index]) {
            const currentItem = this.invoiceSync.offer_services![index];
            currentItem.total = Number(currentItem.offer_rate! * currentItem.offer_qty!);
        }
    }

    /**
     * @param {number} deletedItemIndex - индекс элемента, который будет удален
     * @param {string} name - имя массива, из которого будет удален элемент (`offersList`, `servicesList`)
     */
    private async deleteOffer(deletedItemIndex: number, name: string): Promise<void> {
        const arrName = name === 'offersList'
            ? this.invoiceSync.offer_services
            : this.invoiceSync.other_services;

        arrName!.splice(deletedItemIndex, 1);
    }

    private clearOffer(): void {
        this.offerServiceSelectList = [];
        this.invoiceSync.offer_services = [];
    }
}
