
import { Component, Vue } from 'vue-property-decorator';
import PageLayout from "@/components/layout/PageLayout.vue";
import SurveyHeader from "@/components/support/surveys/SurveyHeader.vue";
import Question from "@/components/support/surveys/CreateQuestion.vue";
import SurveyBody from "@/components/support/surveys/SurveyBody.vue";
import { showServerError } from "@/utils";
import { getOneSurvey, getOneSurveyDownload, getVotingUsers } from "@/api/support";
import { Survey } from "@/services/surveys/Survey";
import { SurveyQuestion } from "@/services/surveys/SurveyQuestion";
import SvgFileDownloaded from '@/assets/icons/file-downloaded.svg';
import Status from "@/components/base/Status.vue";
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import { getAffiliateSearch } from '@/api/user';
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import SurveyAffiliateTable from "@/components/support/surveys/SurveyAffiliateTable.vue";
import { getCurrentDate } from "@/utils/formatDates";
import { downloadFileToDisk } from "@/api";
import SvgPlusIcon from "@/assets/icons/plus.svg";
import { IUser } from "@/api/types/user";
import { IVotingUser } from "@/api/types/support";
import { IStatus } from "@/types";
import EditBtn from "@/components/base/buttons/EditBtn.vue";
interface IVotingUserAutocomplete extends IVotingUser {
    itemText: string
}

@Component({
    components: {
        EditBtn,
        SvgPlusIcon,
        SurveyAffiliateTable,
        TableWithFrontPaginationDesign,
        Status,
        SvgFileDownloaded,
        SurveyBody,
        SurveyHeader,
        PageLayout,
        Question,
        BaseAutocomplete,
    },
})
export default class SingleSurvey extends Vue {
    survey: Survey | null = null;
    loadingDownload = false;
    isEditing = false;
    getAffiliateSearch = getAffiliateSearch;
    affiliateSurvey: SurveyQuestion[] = [];
    isShowSurveyByAffiliate = false;
    affiliateFilterTitle = `Сводка по партнёру`;
    votingUsers: IVotingUserAutocomplete[] = [];

    get routeID(): number {
        return Number(this.$route.params.id);
    }

    get getStatus(): IStatus {
        return this.survey?.translateStatus()!;
    }

    goBack(): void {
        this.isEditing ? this.isEditing = false : this.$router.push({ name: 'surveys' });
    }

    async created(): Promise<void> {
        await this.getSurvey();
        await this.getVotingUserList();
    }

    async getSurveyByAffiliate(item: IUser): Promise<void> {
        this.isShowSurveyByAffiliate = !!item?.id;
        try {
            const { questions } =  await getOneSurvey(this.routeID,item.id );
            this.affiliateSurvey = questions.map((i) => new SurveyQuestion(i));
            this.affiliateFilterTitle = `Сводка по партнёру ${item.account_id}`;
        } catch (err) {
            showServerError(err, 'Сводка по партнеру не получена');
        }
    }

    async getVotingUserList(): Promise<void> {
        try {
            const { data } = await getVotingUsers(this.survey?.id!);
            this.votingUsers = data.map(i => ({ ...i, itemText: `${i.account_id} ${i.name}` }));
        } catch (err) {
            showServerError(err, 'Проголосовавшие пользователи не загружены');
        }
    }

    get isShowPage(): boolean {
        return !!(this.survey && this.survey.id);
    }

    get titleSurvey(): string {
        return `Опрос "${this.survey!.title}"`;
    }

    async getSurvey(): Promise<void> {
        try {
            const data =  await getOneSurvey(this.routeID);
            this.survey = new Survey(data);
        } catch (err) {
            showServerError(err, 'Опрос не получен');
        }
    }

    async closeSurvey(): Promise<void> {
        try {
            await this.survey!.close(this.routeID,false);
            await this.getSurvey();
        } catch (err) {
            showServerError(err, 'Опрос не завершен');
        }
    }

    toggleEditing(): void {
        this.isEditing = !this.isEditing;
        // при редактировании скрываем сводку по партнеру
        if (this.isEditing) {
            this.isShowSurveyByAffiliate = false;
        }
    }

    async saveSurvey(): Promise<void> {
        try {
            await this.survey!.editSurvey();
            this.toggleEditing();
        } catch (err) {
            showServerError(err, 'Опрос не сохранен');
        }
    }

    // добавится функционал во втором релизе, не удалять
    async download(): Promise<void> {
        this.loadingDownload = true;
        try {
            const data =  await getOneSurveyDownload(this.routeID);
            const today = getCurrentDate('dd.MM.yyyy');
            const name = `survey_${this.survey!.title}_${today}`;
            downloadFileToDisk(data, { name, extension: 'xlsx' });

        } catch (err) {
            showServerError(err, 'Опрос не скачен');
        } finally {
            this.loadingDownload = false;
        }
    }

    deleteQuestion(question: SurveyQuestion, index: number): void {
        this.survey!.deleteQuestion(question, index);
    }
}
