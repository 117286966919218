
import { Component, Vue } from 'vue-property-decorator';
import StatsCard from '@/components/base/StatsCard.vue';
import Offer from '@/components/uapi/offers/Offer.vue';
import Terms from '@/components/uapi/offers/Terms.vue';
import Geo from '@/components/uapi/offers/Geo.vue';
import Limits from '@/components/uapi/offers/Limits.vue';
import { showServerError } from '@/utils';
import { getOffer, getOfferById } from '@/api/uapi';
import { IOffer } from '@/api/types/uapi';
import Schedule from '@/components/uapi/offers/Schedule.vue';

@Component({
    components: {
        StatsCard,
        Offer,
        Terms,
        Geo,
        Limits,
        Schedule,
    },
})
export default class OfferSingle extends Vue {
    offer = {} as IOffer;
    offerId = 0;
    tab = 0;
    tabs = [
        { id: 'offer', name: 'Оффер', component: Offer },
        { id: 'terms', name: 'Условия', component: Terms },
        { id: 'GEO', name: 'ГЕО', component: Geo },
        { id: 'limits', name: 'Лимиты', component: Limits },
        { id: 'schedule', name: 'Расписание', component: Schedule },
    ];

    created(): void {
        this.getOfferById();
    }

    async getOfferById(): Promise<void> {
        try {
            const { data } = await getOfferById(Number(this.$route.params.id));
            this.offer = data;
        } catch (err) {
            showServerError(err, 'Не удалось получить оффер');
        }
    }
    async getOffer(): Promise<void> {
        try {
            this.offer = await getOffer(this.offerId);
        } catch (err) {
            showServerError(err);
        }
    }
}
