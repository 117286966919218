
import { Component, PropSync, Ref, Vue } from "vue-property-decorator";
import FormModal from "@/components/base/FormModal.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getAffiliateSearch } from "@/api/user";
import {
    getEmployeesBufferAccounts,
    getEmployeesOffersV2,
    postAffiliateToBufferAccountV2,
} from "@/api/offers";
import Loader from "@/components/base/Loader.vue";
import { showServerError, showNotification } from "@/utils";
import { IBufferAccount } from '@/api/types/offers';
import SvgFalse from "@/assets/icons/false-icon.svg";

@Component({
    methods: { getEmployeesOffersV2, getAffiliateSearch },
    components: {
        Loader, SvgFalse,
        BaseAutocomplete,
        FormModal,
    },
})
export default class FormCreateBufferAccount extends Vue {
    @PropSync('showModal') showModalSync!: boolean;
    @Ref() affiliateInput!: BaseAutocomplete;
    @Ref() offersInput!: BaseAutocomplete;
    @Ref() bufferInput!: BaseAutocomplete;

    updatableBuffer: { offers_ids: number[], affiliates_ids: number[], buffer_account_id: number | null, force?: boolean } = {
        offers_ids: [],
        affiliates_ids: [],
        buffer_account_id: null,
    };
    buffers: IBufferAccount[] = [];
    isLoading = false;
    isForceSend  = false;
    conflictingPairs = [];

    get isValidBufferAccount(): boolean {
        for (const key in this.updatableBuffer) {
            if (!this.updatableBuffer[key]) return false;
        }
        return true;
    }

    async getBufferAccounts(): Promise<void> {
        try {
            this.buffers = await getEmployeesBufferAccounts();
        } catch (err) {
            showServerError(err, 'Буферные аккаунты не загружены');
        }
    }

    created(): void {
        this.getBufferAccounts();
    }

    closeModal(): void {
        this.clearInputs();
        this.showModalSync = false;
    }

    clearErrors(): void {
        this.isForceSend = false;
        this.conflictingPairs = [];
    }

    clearInputs(): void {
        for (const key in this.updatableBuffer) {
            this.updatableBuffer[key] = null;
        }
        this.affiliateInput.clearModel();
        this.offersInput.clearModel();
        this.bufferInput.clearModel();
        this.clearErrors();
    }

    parseErrors(errors: string): any {
        if( !errors) return [];
        const pairs = errors.split('accounts: ')![1]?.split(';') || [];
        const objectsArray = pairs.map(item => {
            if (!item) return;
            const parts = item.split(', '); // Разделяем строку на части по запятой и пробелу
            const obj = {};
            parts.forEach(part => {
                const [key, value] = part.split(': '); // Разделяем часть на ключ и значение по двоеточию с пробелом
                obj[key.trim()] = parseInt(value); // Убираем пробелы и парсим значение в целое число
            });
            return obj;
        });
        return objectsArray;
    }

    async updateBuffer(): Promise<void> {
        this.isLoading = true;
        try {
            const data = { ...this.updatableBuffer };
            data.force = this.isForceSend;
            await postAffiliateToBufferAccountV2(data);
            this.isForceSend = false;
            this.$emit('edit');
            showNotification('Парнёр добавлен в буферный оффер');
            this.closeModal();
        } catch (err) {
            if (err?.data?.error?.details && err?.status! === 400) {
                this.conflictingPairs = this.parseErrors(err.data.error.details.affiliates!);
                this.isForceSend = true;
            } else {
                showServerError(err, 'Парнёр не добавлен в буферный оффер');
            }
        } finally {
            this.isLoading = false;
        }
    }
}
