
import {
    Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import Status from '@/components/base/Status.vue';
import { showServerError } from '@/utils';
import { SEARCH_VALUE_DEBOUNCE } from '@/configs/global';

interface IBaseAutocomplete {
    errorText: string;
    apiMethod: () => void;
    itemValue: string;
    itemText?: string;
    placeholder: string;
    template?: () => void;
    key?: string;
    disabled?: boolean;
    clearable?: boolean;
    attach?: boolean;
    menuProps?: any;
}

@Component({
    components: {
        Status,
    },
})
export default class BaseAutocomplete extends Vue {
    @Prop({ required: true }) data!: IBaseAutocomplete;
    @Prop({ required: false, default: false }) needUpdatesItems!: boolean;
    @Prop({ required: false, default: false }) returnObject!: boolean;
    @Prop({ required: false, default: '' }) defaultValue!: string;
    @Prop({ default: 1 }) minSearchLength!: number;
    @PropSync('model', { required: true }) modelSync!: any;
    @PropSync('possibleItems', { type: Array, default: () => [], required: false }) possibleItemsSync!: any;

    search: any = '';
    isLoading = false;
    searchTimerId = 0;
    items: any = [];

    searchDebounce(): void {
        this.search = this.search?.toString().trim();
        const isObject = typeof this.modelSync === 'object';
        const stringFromModel = isObject ? this.modelSync?.itemText?.toString() : this.modelSync;
        const searchComparison = this.search ? this.search!.includes(stringFromModel) : false;
        const isSearch = this.modelSync ? this.search && searchComparison : false;
        if (this.search?.length >= this.minSearchLength && !isSearch) {
            clearTimeout(this.searchTimerId);
            this.searchTimerId = window.setTimeout(() => {
                // добавлена еще одна проверка, для случаев когда успеваем поменять search за SEARCH_VALUE_DEBOUNCE
                if (this.search?.length >= this.minSearchLength) {
                    this.getItems(this.search);
                }
            }, SEARCH_VALUE_DEBOUNCE);
        }
    }

    get itemsForAutocomplete(): any[] {
        return this.possibleItemsSync.length > 0 ? this.possibleItemsSync : this.items;
    }

    async getItems(searchValue: any): Promise<void> {
        this.isLoading = true;
        const queryParams = this.$props.data!.key! ? { [this.$props.data.key]: searchValue  } : { query: searchValue };
        try {
            if (this.needUpdatesItems && !!queryParams) {
                this.$emit('get-items', queryParams);
            } else {
                const { data } = await this.$props.data.apiMethod(queryParams);
                this.items = data.map((item) => ({
                    ...item,
                    itemText: this.$props.data.itemText !== undefined
                        ? item[this.$props.data.itemText]
                        : this.$props.data.template(item),
                }));
            }
        } catch (err) {
            showServerError(err, this.$props.data.errorText);
        }
        this.isLoading = false;
    }

    changeItem(item: number): void {
        this.$emit('change', item);
        this.search = '';
    }

    @Watch('defaultValue')
    updateItems(): void {
        if (!!this.defaultValue) {
            this.getItems(this.defaultValue);
        }
    }
}
