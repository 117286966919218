
import { Vue, Component } from "vue-property-decorator";
import PageLayout from "@/components/layout/PageLayout.vue";
import { ICreateAffiliate } from "@/api/types/user";
import { CreateAffiliate } from "@/services/affiliates/CreateAffiliate";
import BaseCard from "@/components/base/BaseCard.vue";
import CreateAffiliateFields from "@/components/affiliate/createAffiliate/createAffiliateFields.vue";
import Loader from "@/components/base/Loader.vue";
import SvgCreate from "@/assets/icons/magic-wand-light.svg";
import SvgAlarm from "@/assets/icons/false-icon.svg";

@Component({
    components: { Loader, CreateAffiliateFields, BaseCard, PageLayout, SvgCreate, SvgAlarm },
})

export default class CreateAffiliatePage extends Vue {
    affiliate = {} as CreateAffiliate;
    isLoading: boolean = true;

    created(): void {
        this.createAffiliateTemplate();
    }

    async createAffiliateTemplate(): Promise<void> {
        this.affiliate = new CreateAffiliate({} as ICreateAffiliate);
        await this.affiliate.getAllItems();
        this.isLoading = false;
    }

    async create(): Promise<void> {
        this.isLoading = true;
        await this.affiliate.createAffiliate().finally(() => this.isLoading = false);
    }
}

