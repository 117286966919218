
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { getFormatDate } from '@/utils/formatDates';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import FormModal from '@/components/base/FormModal.vue';
import { getNumberOfLines } from '@/utils';
import { IDeletingComment, IFileComment, IFiles } from '@/api/types/revenue';
import { USER_ROLES } from '@/mappings/user-roles';

// Создаём константы для сторов разных модулей
const auth = namespace('authModule');

@Component({
    components: {
        TooltipButton,
        FormModal,
    },
})

export default class StatTaskedStatisticFileComments extends Vue {
    @Getter('GET_EDITING_MODE') editingMode;
    @auth.Getter('GET_USER') user;
    @auth.Getter('GET_ROLE') userRole;

    @Prop({ required: true }) file!: {};
    @Prop({ required: true }) statTaskId!: number;
    @Prop({ required: false, default: true }) commenting!: boolean;
    @Prop({ required: true }) fileIndex!: number;

    newCommentText = '';
    commentEditingId: number | null = null;
    commentsIsHidden = true;
    editingCommentText = '';
    deletingComment: null | IDeletingComment = null;

    getFormatDate = getFormatDate;
    getNumberOfLines = getNumberOfLines;

    addComment(text: string, file: IFiles): void {
        this.$emit('add-comment', text, file);

        this.newCommentText = '';
    }

    get userName(): string {
        return `${this.user!.first_name} ${this.user!.last_name}`;
    }

    get isCommenting(): boolean {
        return !(this.$props.file.comments.length === 1 && !this.$props.file.comments[0].hasOwnProperty('id'))
            && !(this.$props.file.comments.length <= 0 && !this.$props.file.id);
    }

    get isStatManager(): boolean {
        return this.userRole === USER_ROLES.STAT_MANAGER;
    }

    async editComment(comment: IFileComment): Promise<void> {
        if (this.commentEditingId === comment.id) {
            const commentIndex = this.$props.file.comments.findIndex(
                (com) => (com.id ? com.id === comment.id : com.text = comment.text),
            );

            const data = {
                statTaskId: this.$props.statTaskId,
                fileId: this.$props.file.id,
                commentId: comment.id,
                text: this.editingCommentText,
                fileIndex: this.$props.fileIndex,
                commentIndex,
            };

            this.$emit('edit-comment', data);
            this.commentEditingId = null;
            return;
        }

        this.commentEditingId = comment.id!;
        this.editingCommentText = comment.text || '';
    }

    setDeletingComment(comment: IFileComment): void {
        const commentIndex = this.$props.file.comments.findIndex(
            (com) => (com.id ? com.id === comment.id : com.text = comment.text),
        );

        this.deletingComment = {
            statTaskId: this.$props.statTaskId,
            fileId: this.$props.file.id,
            fileIndex: this.$props.fileIndex,
            commentId: comment.id!,
            commentIndex,
        };
    }

    deleteComment(): void {
        this.$emit('delete-comment', this.deletingComment);
        this.deletingComment = null;
    }
}
