
import SvgCheckbox from '@/assets/icons/checkbox.svg';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        SvgCheckbox,
    },
})

export default class AutocompleteListItem extends Vue {
    @Prop({ required: true }) title!: string;
    @Prop({ default: null }) active!: boolean;
    @Prop({ default: false }) isMarker!: boolean;
    @Prop({ default: true }) isNeedCheckbox!: boolean;
    isCheck = false;

    get isActive(): boolean {
        if (this.active !== null) {
            return this.active;
        } else return !this.isCheck;
    }

    deleteItem(): void {
        if (this.isActive){
            this.$emit('delete');
        }
    }
}
