import { IFilter } from '@/types';
import { IFilterDate } from '@/services/filters/filterParser';

export class FilterDateParser {
    protected filterDates: IFilterDate = {};

    constructor(filterDates: IFilterDate) {
        this.filterDates = filterDates;
    }

    parse(filter: IFilter): any {
        if (this.filterDates[filter.id].length === 1) {
            return {
                [`${filter.id}_from`]: this.filterDates[filter.id][0],
                [`${filter.id}_to`]: this.filterDates[filter.id][0],
            };
        } if (this.filterDates[filter.id].length > 1) {
            return this.sortDate(filter, this.filterDates);
        }
    }

    sortDate(filter: IFilter, filterDates: IFilterDate): any {
        if (typeof filterDates[filter.id] === 'string') {
            return {
                [filter.id]: filterDates[filter.id].length === 7
                    ? `${filterDates[filter.id]}-01`
                    : filterDates[filter.id],
            };
        } if (filterDates[filter.id][0].valueOf() > filterDates[filter.id][1].valueOf()) {
            return {
                [`${filter.id}_from`]: this.filterDates[filter.id][1],
                [`${filter.id}_to`]: this.filterDates[filter.id][0],
            };
        }

        const data = {
            from: this.filterDates[filter.id][0],
            to: this.filterDates[filter.id][1],
        };

        return {
            [`${filter.id}_from`]: data.from += filter.isTimeAdder ? ` ${filter.defaultTime}` : "",
            [`${filter.id}_to`]: data.to += filter.isTimeAdder ? ` ${filter.defaultTime}` : "",
        };
    }
}
