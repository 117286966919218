import { GetterTree } from 'vuex';

import { IUapiState } from '@/store/modules/uapi/types';
import { IRootState } from '@/store/types';

export const getters: GetterTree<IUapiState, IRootState> = {
    GET_OFFERS_FILTERS: (state) => state.offersFilters,
    GET_SORT_FILTERS: (state) => state.sortFilters,
    GET_CONVERSIONS_FORECAST_QUERY: (state) => state.conversionsForecastQuery,
};
