
import { Component, Vue, Prop } from 'vue-property-decorator';
import Status from '@/components/base/Status.vue';
import Pagination from '@/components/base/table/Pagination.vue';
import { translateVisibility as translateActive } from "@/utils/translate";

@Component({
    components: {
        Status,
        Pagination,
    },
})
export default class HistoryLimits extends Vue {
    @Prop({ type: Array, default: () => ([]) }) limits!: [];

    headers = [
        { text: 'Дата начала', value: 'start_time', class: 'font-weight-regular' },
        { text: 'Дата окончания', value: 'end_time', class: 'font-weight-regular' },
        { text: 'Дата выполнения', value: 'executed_at', class: 'font-weight-regular' },
        { text: 'Общий лимит', value: 'limit_data.total', class: 'font-weight-regular' },
        { text: 'Только принятые', value: 'limit_data.accepted_only', class: 'font-weight-regular' },
        { text: 'Индивидуальные лимиты', value: 'data-table-expand', width: '10%', class: 'font-weight-regular' },
    ];

    limit = 25;
    pagination = { page: 1 };
    translateActive = translateActive;

    getPaginationData(offset: number, limit: number, isChangeLimit?: boolean): void {
        if (offset === 0 || isChangeLimit) {
            this.pagination.page = 1;
        }
        this.limit = limit;
    }
}
