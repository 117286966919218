
import BaseCard from "@/components/base/BaseCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ViewDateTime from "@/components/base/ViewDateTime.vue";

interface IApprovalPageHeader {
    name: string,
    value: string,
}

@Component({
    components: {
        ViewDateTime,
        BaseCard,
    },
})
export default class ApprovalPageHeader extends Vue {
    @Prop({ required: true }) createdAt!: string;
    @Prop({ required: true }) updatedAt!: string;

    get headers(): IApprovalPageHeader[] {
        return [
            { name: 'Дата создания запроса', value: this.createdAt },
            { name: 'Дата обновления запроса', value: this.updatedAt },
        ];
    }
}

