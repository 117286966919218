
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import OfferLinksConstructor from "@/components/offers/offer/OfferLinksConstructor.vue";
import OfferLinksTable from "@/components/offers/offer/OfferLinksTable.vue";

@Component({
    components: {
        OfferLinksTable,
        OfferLinksConstructor,
    },
})

export default class OfferLinks extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
}
