
import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatDateInputPicker } from '@/utils/formatDates';

@Component({})

export default class ViewDateTime extends Vue {
    @Prop({ default: '' }) value!: string;
    @Prop({ default: false }) isSecond!: boolean;
    @Prop({ default: true }) isLine!: boolean;
    @Prop({ default: true }) isWithTime!: boolean;
    @Prop({ default: 'dd.MM.yyyy' }) dateFormat!: string;
    @Prop({ default: 'currentColor' }) dateColor!: string;
    @Prop({ default: '#9BA4AE' }) timeColor!: string;

    formatDateInputPicker = formatDateInputPicker;
}
