
import { Vue, Component, Ref } from 'vue-property-decorator';
import PageLayout from "@/components/layout/PageLayout.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import { getActiveUserSearch } from "@/api/user";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { translateAdvertiserStatus } from "@/utils/translate";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { IUser } from "@/api/types/user";
import store from "@/store";
import SvgMagicWand from "@/assets/icons/magic-wand-light.svg";
import { showServerError } from "@/utils";
import { createAdvertiser } from "@/api/offers";
import { NotificationMessage } from "@/utils/notification";

@Component({
    components: { SvgMagicWand, BaseSelect, BaseAutocomplete, BaseCard, PageLayout },
})
export default class CreateAdvertiser extends Vue {
    @Ref() managerSearch!: BaseAutocomplete;

    managerDefaultSearch: number | string | undefined = '';
    loadingBtn = false;
    form = {
        name: '',
        manager_id: 0,
        status: '',
    };

    get isCanSave():boolean {
        return !!(this.form.name?.length >= 2 && this.form.manager_id && this.form.status);
    }

    get getManagerData(): any {
        return {
            placeholder: 'Менеджер рекламодателя',
            errorText: 'Менеджер не найден',
            apiMethod: getActiveUserSearch,
            multiple: false,
            itemValue: 'id',
            clearable: true,
            template: (item) => `${item.id} ${item.first_name} ${item.last_name}`,
            maxShowItems: 20,
        };
    }

    get getStatusesData(): any {
        return {
            placeholder: 'Статус рекламодателя',
            items: [
                {
                    id: 1,
                    value: 'active',
                },
                {
                    id: 2,
                    value: 'blocked',
                },
                {
                    id: 3,
                    value: 'rejected',
                },

            ],
            itemValue: 'value',
            template: (status: any) => translateAdvertiserStatus(status.value).text,
        };
    }

    setManager(e: number): void {
        !e ? this.form.manager_id = 0 : this.form.manager_id = e;
    }

    setDefaultUser():void {
        const user: IUser = store.getters['authModule/GET_USER'];
        this.managerDefaultSearch = user.account_id;
    }

    async saveAdvertiser(): Promise<void> {
        this.loadingBtn = true;
        try {
            await createAdvertiser(this.form);
            new NotificationMessage('Рекламодатель создан', 'success' );
            this.$router.push('advertisers');
        } catch (err) {
            showServerError(err, 'Рекламодатель не создан');
        }
        this.loadingBtn = false;
    }

    mounted(): void {
        this.setDefaultUser();
    }

}
