
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import Multiselect from '@/components/base/form/Multiselect.vue';
import StatTaskedFileItem from '@/components/revenue/statTask/StatTaskedFileItem.vue';
import { addFile } from '@/api';
import {
    IFiles,
    IOfferService,
} from '@/api/types/revenue';
import { showServerError } from '@/utils';
import { eventBus } from '@/eventbus';

@Component({
    components: {
        Multiselect,
        StatTaskedFileItem,
    },
})
export default class StatTaskFiles extends Vue {
    @Prop({ type: Array, default: () => ([]) }) possibleItemsForSelect!: IOfferService[];
    @Prop({ type: String, default: 'id' }) itemValue!: string;

    // Выбранный для загрузки файл
    statTaskedFiles: object[] = [];
    // Выбранные файлы
    addedFiles: IFiles[] = [];
    itemsForSelect: IOfferService[] = [];
    addFileLoader = false;

    @Watch('possibleItemsForSelect')
    changeItemsForSelect(value: IOfferService[]): void {
        this.itemsForSelect = [...value];
    }

    created(): void {
        this.itemsForSelect = [...this.$props.possibleItemsForSelect];
        // чтобы при сохранении счета не потерять добавленные, но не сохраненные файлы
        eventBus.$on('save', this.addFiles);
    }

    beforeDestroy(): void {
        eventBus.$off('save', this.addFiles);
    }

    get isAddFileButtonDisabled(): boolean {
        return this.addedFiles?.some((item) => item.offer_services!.length <= 0);
    }

    get isEmptyAddedFiles(): boolean {
        return this.addedFiles.length <= 0;
    }

    async getFiles(): Promise<void> {
        try {
            const input = this.$el.querySelector('.stat-task__file-input input') as HTMLElement;
            if (input) {
                input.blur();
            }
            this.addFileLoader = true;
            for (const fl of this.statTaskedFiles) {
                const fileForStatTask = {} as IFiles;
                this.$set(fileForStatTask, 'offer_services', []);
                this.$set(fileForStatTask, 'comments', []);

                const { data: { data: file } } = await addFile(fl);
                this.$set(fileForStatTask, 'file', file);
                this.addedFiles.push(fileForStatTask);
                this.addFileLoader = false;
            }
        } catch (err) {
            this.addFileLoader = false;
            showServerError(err, 'Файл не найден');
        }
    }

    updatePossibleItemsForSelect(arr: IOfferService[]): void {
        this.itemsForSelect = arr;
    }

    // удалить выбранный файл
    async removeDocumentFile(item: IFiles, index: number): Promise<void> {
        this.addedFiles.splice(index, 1);
        this.itemsForSelect = this.itemsForSelect.concat(item!.offer_services!);
    }

    async addFiles(): Promise<void> {
        if (!this.addedFiles.length) {
            return;
        }
        await this.$emit('add-files', this.addedFiles);
        this.addedFiles = [];
        this.statTaskedFiles = [];
    }
}
