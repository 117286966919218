import { MutationTree } from 'vuex';

import { IRevenueState } from '@/store/modules/revenue/types';

export const mutations: MutationTree<IRevenueState> = {
    SET_ADVERTISER_FIELDS: (state, payload) => {
        state.advertiser = payload;
    },
    SET_ACCOUNT_MANAGER_NAME: (state, payload) => {
        state.accountManagerName = payload;
    },
    SET_ACCOUNTING_AMOUNT: (state, payload) => {
        state.accountingAmounts[payload.fieldName]![payload.index] = payload.result;
    },
    SET_OFFER_REPORT: (state, payload) => {
        state.offerReport = payload;
    },
    SET_FILE_REPORT: (state, payload) => {
        state.fileReport = payload;
    },
    SET_GENERAL_REPORT_OFFERS: (state, payload) => {
        state.generalReportOffers = payload;
    },
    SET_CURRENT_ADVERTISER: (state, payload) => {
        state.currentAdvertiser = payload;
    },
};
