import { Route } from 'vue-router';
import store from '@/store';
import { IFilter } from '@/types';
import { Vue } from 'vue-property-decorator';
import router from "@/router";

export class FilterRouteParser {
    activeFilters = {};
    filters: IFilter[] = [];

    protected $route = {} as Route;

    constructor(filters: IFilter[]) {
        this.$route = router.currentRoute;
        this.filters = filters;
    }

    parse(): void {
        if (!Object.keys(this.$route.query)?.length) return;
        for (const item in this.$route.query) {
            if (this.$route.query[item] === undefined) continue;
            this.filters.forEach((filter) => {
                if (filter.id !== item && !filter.datepicker) return;
                const forChange = filter.parseQueryAndGetChanges(this.$route.query, item, this);
                this.updateActiveFilters(forChange);
            });

            // чтобы не стёрлись фильтры, которых нет в компоненте Filters
            store.commit('SET_FILTERS_PARAMS_OBJECT', {
                ...store.getters['GET_FILTERS_PARAMS_OBJECT'],
                ...this.activeFilters,
            });
        }
    }

    updateActiveFilters(pairs: Record<string, any>): void {
        Object.entries(pairs).forEach(([key, value]) => {
            Vue.set(this.activeFilters, key, value);
        });
    }

    getItemsMasterAccount(f: IFilter, data: any, item: any): void {
        const currentItem = data.find((i) => i.id == item);
        f.items!.push({
            [f.itemValue!]: currentItem.id,
            [f.itemText!]: f.template ? f.template(currentItem) : currentItem,
        });
    }

    getSupSelect(f: IFilter, tempItem: any): any {
        return  {
            [f.itemValue!]: tempItem[f.itemValue!],
            [f.itemText!]: `${tempItem[f.supValue!]} ${tempItem[f.itemText!]}`,
        };
    }

    getBufferSelect(f: IFilter, data: any, item: any): any {
        const bufferItem = data!.find((i) => i.buffer.id! === item);
        return{
            [f.itemValue!]: bufferItem.buffer[f.itemValue!],
            [f.itemText!]: `${bufferItem.buffer[f.itemValue!]} ${bufferItem.buffer[f.itemText!]}`,
        };
    }

    getOfferSelect(f: IFilter, tempItem: any): any {
        return  {
            id: tempItem?.id! || 0,
            [f.itemValue!]: tempItem[f.itemValue!],
            [f.itemText!]: `${tempItem[f.itemValue!]} ${tempItem[f.itemText!]}`,
            status: tempItem.status,
        };
    }

    getBaseSelect(f: IFilter, tempItem: any): any {
        return f.multiselect
            ? {
                [f.itemValue!]: tempItem[f.itemValue!],
                [f.itemText!]: tempItem[f.itemText!],
            }
            : {
                [f.itemValue!]: tempItem[f.itemValue!],
                [f.itemText!]: tempItem[f.itemText!]
                    ? `${tempItem[f.itemValue!]} ${tempItem[f.itemText!]}`
                    : `${tempItem[f.itemValue!]} ${tempItem!.first_name} ${tempItem!.last_name}`,
            };
    }
}
