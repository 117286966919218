
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import CreateOfferGoal from "@/components/offers/createOffer/createOfferGoal.vue";
import SvgPlus from "@/assets/icons/plus.svg";
import { CreateGoal } from "@/services/offers/CreateGoal";
import { ICreateOffer, IPostOfferGoal } from "@/api/types/offers";
import { CreateOffer } from "@/services/offers/CreateOffer";
import SvgMagicWand from "@/assets/icons/magic-wand-light.svg";
import { showNotification } from "@/utils";
import { eventBus } from "@/eventbus";
import { OfferSingle } from "@/services/offers/OfferSingle";

@Component({
    components: { SvgMagicWand, CreateOfferGoal, BaseCard, SvgPlus },
})
export default class OfferGoalsCreate extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    fakeOffer = {} as CreateOffer;
    goals = [] as CreateGoal[];
    lastCreatedGoalId: number = 1;
    defaultGoalId: number = 1;
    isLoading: boolean = false;

    mounted(): void {
        const currency = this.offer.goals[0].payout.currency;
        this.fakeOffer = new CreateOffer({ currency } as ICreateOffer);
        this.addGoal();
    }

    getGoalIndex(id: number): number {
        return this.goals.findIndex(goal => goal.id === id);
    }

    addGoal(): void {
        this.goals.push(new CreateGoal({} as IPostOfferGoal, this.lastCreatedGoalId));
        this.lastCreatedGoalId++;
        this.defaultGoalId = this.goals[0].id;
    }

    deleteGoal(goal: CreateGoal): void {
        const index = this.getGoalIndex(goal.id);
        this.goals.splice(index, 1);
    }

    async createGoals(): Promise<void> {
        this.isLoading = true;
        await Promise.allSettled(this.goals.map(goal => goal.createGoal(this.offer.legacy_id!))).then(res => {
            if (res.every(i => i.status === 'fulfilled')) {
                showNotification('Цели успешно созданы');
                eventBus.$emit("update-offer");
                this.$emit('disable-create-mode');
            } else {
                this.goals = this.goals.filter((item, index) => res[index].status !== 'fulfilled');
                showNotification('Валидные цели были успешно созданы');
            }
        });
        this.isLoading = false;
    }
}
