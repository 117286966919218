
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import SvgConstructor from '@/assets/icons/constructor.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";

@Component({
    components: { SvgConstructor, TrashBtn },
})
export default class FiltersDialog extends Vue {
    @Prop({  required: true }) readonly attachClass!: string;
    @Prop({  default: 'Фильтры' }) readonly titleBtn!: string;
    @Prop({  default: 'Фильтры' }) readonly title!: string;
    @Prop({  default: '' }) readonly contentClass!: string;
    @Prop({  default: 404 }) readonly maxWidth!: string | number;
    @Prop({  default: 404 }) readonly width!: string | number;
    @Prop({ default: false }) readonly isHideBtn!: boolean;
    @PropSync('isShowDialog') isShowDialogSync!: boolean;

    toggleDialog(): void {
        this.isShowDialogSync = !this.isShowDialogSync;
    }

    clearFilters(): void {
        this.$emit('clear');
    }

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= +this.maxWidth;
    }
}
