import { Module } from 'vuex';

import { actions } from '@/store/modules/catalogue/actions';
import { mutations } from '@/store/modules/catalogue/mutations';
import { getters } from '@/store/modules/catalogue/getters';

import { IRootState } from '@/store/types';
import { ICatalogueState } from '@/store/modules/catalogue/types';

export const state: ICatalogueState = {
    countries: [],
    currencies: [],
};

const namespaced = true;

export const catalogueModule: Module<ICatalogueState, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
