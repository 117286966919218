
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import SvgSave from '@/assets/icons/save.svg';
import SvgEdit from '@/assets/icons/edit.svg';

@Component({
    components: {
        SvgSave,
        SvgEdit,
    },
})

export default class ChangeEditingModeButton extends Vue {
    @Mutation('TOGGLE_EDITING_MODE') toggleEditingMode;
    @Getter('GET_EDITING_MODE') editingModeFromStore;

    @Prop({ default: false }) canSave!: boolean;
    @Prop({ default: false }) loading!: boolean;

    created(): void {
        this.toggleEditingMode(false);
    }

    beforeDestroy(): void {
        this.toggleEditingMode(true);
    }

    changeMode(): void {
        const editingMode = !this.editingModeFromStore;
        if (!editingMode && this.$props.canSave) {
            this.$emit('save');
        }
        this.toggleEditingMode(!this.editingModeFromStore);
    }
}
