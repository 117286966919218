
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormModal from '@/components/base/FormModal.vue';
import BaseAutocomplete from '@/components/base/form/BaseAutocomplete.vue';
import Loader from '@/components/base/Loader.vue';
import { IPersonalRates } from "@/api/types/offers";
import { showNotification, showServerError } from "@/utils";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import { deleteEmployeesPersonalRates } from "@/api/offers";

@Component({
    components: {
        BaseAutocomplete,
        FormModal,
        Loader,
        DateTimePickerNew,
    },
})
export default class FormDeletePersonalRate extends Vue {
    @Prop({ default: {} }) deletingRate!: IPersonalRates;

    showStartDate = false;
    startDate: string = '';

    get isShow(): boolean {
        return Object.keys(this.deletingRate).length > 0;
    }

    async deleteOnePersonalRate(): Promise<void> {
        try {
            const data = {
                pairs: [
                    {
                        offer_id: this.deletingRate.offer.legacy_id!,
                        goal_id: this.deletingRate.goal.legacy_id!,
                        affiliate_id: this.deletingRate.affiliate.legacy_id!,
                        start_date: this.showStartDate ? this.startDate : undefined,
                    },
                ],
            };
            await deleteEmployeesPersonalRates(data);
            this.$emit('delete');
            this.hideModal();
            showNotification('Персональная цена удалена');
        } catch (err) {
            showServerError(err, 'Персональная цена не удалена');
        }
    }

    hideModal(): void {
        this.startDate = '';
        this.showStartDate = false;
        this.$emit('hide');
    }
}
