
import { Component, Vue, Prop } from 'vue-property-decorator';
import OffsetBase from './OffsetBase.vue';

@Component({
    components: { OffsetBase },
})

export default class CardMaterial extends Vue {
    @Prop({ type: String, default: '' }) icon!: string;
    @Prop({ type: String, default: 'secondary' }) color!: string;
    @Prop({ type: String, default: '' }) text!: string;
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: Boolean, default: false }) fullWidth!: boolean;
    @Prop({ type: Boolean, default: false }) inline!: boolean;
    @Prop({ type: Object, default: () => ({}) }) params!: {};
    @Prop({ type: [Number, String], default: 10 }) offset!: number | string;
    @Prop({ type: [Number, String], default: 10 }) elevation!: number | string;

    get hasOffset(): boolean {
        return this.$slots.header
            || this.$slots.offset
            || this.$props.title
            || this.$props.text;
    }

    get styles(): {[key: string]: string} | null {
        if (!this.hasOffset) { return null; }

        return {
            marginBottom: `${this.$props.offset}px`,
            marginTop: '0',
        };
    }
}
