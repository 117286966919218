
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IOfferLimits } from '@/api/types/uapi';
import { getOfferLimits } from '@/api/uapi';
import ActiveLimit from '@/components/uapi/offers/limits/ActiveLimit.vue';
import HistoryLimits from '@/components/uapi/offers/limits/HistoryLimits.vue';
import PlannedLimits from '@/components/uapi/offers/limits/PlannedLimits.vue';
import { showServerError } from '@/utils';
import { GetInfo } from '@/utils/getInfo';
import { getUsers } from '@/api/user';

@Component({
    components: {
        ActiveLimit,
        HistoryLimits,
        PlannedLimits,
    },
})
export default class Limits extends Vue {
    @Prop({ type: Number, required: true }) offerId!: number;

    limits: IOfferLimits = {};
    authorNamesPlannedLimit = [];

    created(): void {
        this.getOfferLimits();
    }

    get getHistory(): any {
        return this.limits.history;
    }

    async getOfferLimits(): Promise<void> {
        try {
            this.limits = await getOfferLimits(this.$props.offerId);
            this.authorNamesPlannedLimit = await new GetInfo(this.limits.planned, 'created_by', getUsers)
                .getInfoByFields();
        } catch (err) {
            showServerError(err);
        }
    }
}
