var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form-modal',{staticClass:"accesses-create-form",attrs:{"show-modal":_vm.showModalSync,"max-width":"500","title":"Создание доступа","closable":true,"is-disabled":_vm.isSaveDisabled,"is-need-already-click":false},on:{"close-modal":function($event){_vm.showModalSync=false},"save":_vm.createAccess}},[[_c('div',{staticClass:"accesses-create-form__form"},[_c('div',{staticClass:"accesses-create-form__item"},[_c('p',{staticClass:"accesses-create-form__item-label"},[_vm._v(" Пользователь ")]),_c('base-autocomplete',{ref:"affiliateInput",staticClass:"accesses-create-form__item-input",attrs:{"data":{
                        errorText: 'Пользователь не найден',
                        apiMethod: _vm.getUsersV3,
                        itemValue: 'id',
                        template: (item) => `${item.account_id} ${item.first_name} ${item.last_name}`,
                        placeholder: 'Введите ID или имя пользователя',
                        clearable: true,
                        multiple: false,
                        maxShowItems: 20,
                    },"is-return-object":true},on:{"change":_vm.updateUsers}})],1),_c('div',{staticClass:"accesses-create-form__item"},[_c('p',{staticClass:"accesses-create-form__item-label"},[_vm._v(" Организация ")]),_c('base-autocomplete',{ref:"offersInput",staticClass:"accesses-create-form__item-input",attrs:{"data":{
                        errorText: 'Организация',
                        apiMethod: _vm.getLegalEntitySearch,
                        multiple: true,
                        itemValue: 'external_id',
                        template: (item) => item.name,
                        placeholder: 'Введите название организации',
                        clearable: false,
                        maxShowItems: 20,
                    }},on:{"change":_vm.updateOrgs}})],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }