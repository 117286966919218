
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgUsers from '@/assets/icons/nav-bar/users.svg';
import SvgLoginUser from '@/assets/icons/nav-bar/login-user.svg';
import { namespace } from "vuex-class";
import { INavigationExtraItems } from "@/api/types/revenue";
import { checkRole } from "@/utils/checkRole";
import { USER_ROLES } from "@/mappings/user-roles";
const auth = namespace('authModule');
const loginRoles= [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR];

@Component({
    components: {
        SvgUsers,
        SvgLoginUser,
    },
})
export default class NavAdministration extends Vue {
    @Prop() mini!: boolean;
    @auth.Getter('GET_USER') user;
    @auth.Mutation('SET_LOGIN_AS_PARTNER') setLoginAsPartner;

    administration: INavigationExtraItems[] = [
        { text: 'Пользователи', routeName: 'users', icon: SvgUsers, action: (): any => this.$router.push({ name: 'users' }) },
        { text: 'Войти под партнёром', routeName: '/', icon: SvgLoginUser, action: this.openModal },
    ];

    filterLinks(): INavigationExtraItems[] {
        const items = checkRole(this.administration);
        const login = items.find(i => i.text === 'Войти под партнёром');
        if (login) {
            login.show = loginRoles.some((role) => this.user.roles.includes(role));
        }
        return items;
    }

    get currentLinks(): INavigationExtraItems[] {
        return this.filterLinks().filter(item => item.show);
    }

    openModal(): void {
        this.setLoginAsPartner(true);
    }
}
