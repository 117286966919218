
import { Component, Prop, Vue, PropSync, Watch } from 'vue-property-decorator';
import FormModal from '@/components/base/FormModal.vue';
import { showServerError } from "@/utils";
import { addPartialPaymentMyLeadgidInvoice } from '@/api/payout';
import { NotificationMessage } from '@/utils/notification';
import { ISingleMyLeadgidInvoice, IRespCreatePartialPayment } from '@/api/types/payout';
import { namespace } from 'vuex-class';
import SvgTrash from "@/assets/icons/trash.svg";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";

const catalogue = namespace('catalogueModule');
@Component({
    components: {
        EditModeTextField,
        FormModal,
        SvgTrash,
    },
})
export default class FormAddPayout extends Vue {
    @Prop({ default: () => ({}) }) invoice!: ISingleMyLeadgidInvoice;
    @PropSync('showModal') showModalSync!: boolean;
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencySymbol;

    SvgTrashComponent = SvgTrash;

    amount: number = 0;
    description: string = '';

    @Watch('amount')
    maxAmount(): void {
        this.$nextTick(() => {
            if (this.amount > this.restSum) this.amount = this.restSum;
        });
    }

    mounted(): void {
        this.amount = this.restSum;
    }

    clearAmount(): void {
        this.amount = 0;
    }

    clearDescription(): void {
        this.description = '';
    }

    get paidSum(): number {
        if (this.invoice.paid_status === 'paid') {
            return this.invoice.total_amount;
        }
        if (Array.isArray(this.invoice.partials) && this.invoice.partials.length) {
            return this.invoice.partials.reduce((acc, item) => acc + item.amount, 0);
        }
        return 0;
    }

    get restSum(): number {
        return this.invoice.total_amount - this.paidSum;
    }

    get currency(): string {
        return this.currencySymbol(this.invoice.currency);
    }

    hideModal(): void {
        this.$emit('hide');
    }

    async addPayment(): Promise<void> {
        const data = {
            amount: this.amount,
            description: this.description,
        };
        try {
            const response = await addPartialPaymentMyLeadgidInvoice(this.$route.params.id, data) as IRespCreatePartialPayment;

            new NotificationMessage(`Добавлен платёж суммой в ${this.amount} ${response.currency}`, 'success' );
            this.clearDescription();
            this.$emit('hide-and-reload');
        } catch (err) {
            showServerError(err, 'Платёж не добавлен');
        }
    }
}
