
import { Component, Mixins } from "vue-property-decorator";
import { showServerError } from "@/utils";
import {
    getAffiliateLoginURL,
    getEmployeesAffiliate } from '@/api/user';
import TableMixin from "@/mixins/table";
import PageLayout from "@/components/layout/PageLayout.vue";
import SvgUser from "@/assets/icons/payout/user.svg";
import { IAuthor, IMessage, ITicket } from "@/api/types/support";
import { NotificationMessage } from "@/utils/notification";
import { getTicket } from '@/api/support';
import TicketChat from "@/components/support/ticket/TicketChat.vue";
import TicketAbout from "@/components/support/ticket/TicketAbout.vue";
import { Socket } from "vue-socket.io-extended";
import { namespace } from "vuex-class";
import { TICKET_STATUS } from "@/mappings/ticket-status";
const auth = namespace('authModule');
@Component({
    components: { TicketAbout, TicketChat, PageLayout, SvgUser },
})

export default class TicketSingleWrap extends Mixins(TableMixin) {
    @auth.Getter('GET_USER') userCRM;

    isNewDesignPage = true;
    settings = { loading: false };
    ticket: ITicket = {
        author: 0,
        created_at: "",
        id: 0,
        status: "",
        title: "",
        updated_at: "",
        email: "",
        email_verified: 0,
        is_master: 0,
        master_id: 0,
        full_name: "",
    } as ITicket;
    emailVerified: number | boolean = 0;
    isMaster: number | boolean = 0;
    manager: string = '';
    messages: IMessage[] = [];
    isPartnerManager = false;
    partnerLanguage = 'ru';
    isDataLoaded = false;

    get getTitle(): string {
        return !!this.ticket.title ?
            this.ticket.title.length > 50 ?
                `${this.ticket.title}`.substr(0, 50) + '...' : `${this.ticket.title}`
            : '';
    }

    async getTicket(): Promise<void> {
        try {
            const { data: { ticket, messages } } = await getTicket(this.$route.params.id, 'asc');
            this.ticket = ticket;
            this.messages = messages;
        } catch (err) {
            showServerError(err, 'Не удалось получить сообщения по тикету');
        }
    }

    updateTicket(data: {ticket: any, message: string, signature}) : void {
        this.$socket.client.emit('send_message_from_ticket', data);
        if (this.ticket.status === 'new') {
            this.ticket.status = 'in_progress';
        }
    }

    async created(): Promise<void> {
        await this.getTicket();
        await this.getUserInfo();
        this.$nextTick(() => {
            this.$socket.client.emit('user_open_ticket', { ticket: this.ticket.id });
        });
        this.isDataLoaded = true;
    }

    async getUserInfo(): Promise<void> {
        try {
            const user = await getEmployeesAffiliate(((this.ticket as ITicket).author_account as IAuthor).account_id);
            if (user) {
                this.emailVerified = user.email_verified;
                this.isMaster = user.is_master;
                if (user.manager) this.manager = `${user.manager.id} ${user.manager.first_name} ${user.manager.last_name}`;
                this.isPartnerManager = user.manager?.id === this.userCRM.account_id;
                this.partnerLanguage = user.language;
            }
        } catch (err) {
            showServerError(err);
        }
    }

    showNotification(): void {
        const notificationText = (this.ticket! as ITicket).status === 'resolved' ? 'переоткрыт': 'решён';
        new NotificationMessage( `Тикет ${(this.ticket! as ITicket).id} ${notificationText}`, 'success' );
    }

    async login(): Promise<void> {
        try {
            const { url } = await getAffiliateLoginURL(((this.ticket as ITicket).author_account as IAuthor).account_id);
            window.open(url, '_blank');
        } catch (err) {
            showServerError(err, 'Не удалось авторизоваться как партнёр');
        }
    }

    @Socket('message')
    getMessage (data: {data: IMessage, ticket: number}): void {
        if (this.messages.some(m => m.id === data.data.id)) return;
        if (this.ticket.status === TICKET_STATUS.RESOLVED) {
            this.ticket.status = TICKET_STATUS.REOPEN;
        }
        if (data.ticket === this.ticket.id) {
            this.messages.push(data.data);
        }
    }

    beforeDestroy(): void {
        this.$socket.client.emit('user_close_ticket', { ticket: this.ticket.id });
    }

}
