
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ReportWrap from '@/components/revenue/statTask/report/ReportWrap.vue';

const revenue = namespace('revenueModule');

@Component({
    components: {
        ReportWrap,
    },
})
export default class OfferReport extends Vue {
    @revenue.Getter('GET_OFFER_REPORT') offerReport;

    tabs: Array<{
        id: string;
        name: string;
        component: string;
        selector?: string;
    }> = [
            {
                id: 'summary',
                name: 'Сводная информация',
                component: 'BasicInformation',
            },
            {
                id: 'all',
                name: 'Принято: Все',
                component: 'AcceptedAll',
                selector: 'accepted-all',
            },
            {
                id: 'approved_old',
                name: 'Принято: Ранее',
                component: 'AcceptedPrev',
                selector: 'accepted-prev',
            },
            {
                id: 'approved_new',
                name: 'Принято: Новые',
                component: 'AcceptedNew',
                selector: 'accepted-new',
            },
            {
                id: 'adjust',
                name: 'Принято: Adjust',
                component: 'Adjusts',
                selector: 'adjusts',
            },
            {
                id: 'clone',
                name: 'Принято: Клоны',
                component: 'Clones',
                selector: 'clones',
            },
            {
                id: 'advertiser_duplicate',
                name: 'Дубли: у рекла',
                component: 'AdvertiserDuplicate',
            },
            {
                id: 'lead_gid_duplicate',
                name: 'Дубли: в LeadGid',
                component: 'LeadDuplicate',
                selector: 'leadgid-duplicate',
            },
            {
                id: 'loss',
                name: 'Потери',
                component: 'Losses',
            },
            {
                id: 'extra',
                name: 'Лишние',
                component: 'Extra',
                selector: 'extra',
            },
        ];

    tab = 0;

    get titleReport(): string {
        const goalName = this.offerReport.goal_name || 'По умолчанию';
        const goalId = this.offerReport.goal_id || '0';
        const offerName = this.offerReport.offer_name || '';
        const offerId = this.offerReport.offer_id || '0';
        return `Отчёт по офферу ${offerId} ${offerName} цель ${goalId} ${goalName}`;
    }
}
