
import { Component, Vue } from 'vue-property-decorator';
import PageLayout from "@/components/layout/PageLayout.vue";
import { Survey } from "@/services/surveys/Survey";
import SurveyHeader from "@/components/support/surveys/SurveyHeader.vue";
import Question from "@/components/support/surveys/CreateQuestion.vue";
import SurveyBody from "@/components/support/surveys/SurveyBody.vue";
import { showServerError } from "@/utils";
import { SurveyQuestion } from "@/services/surveys/SurveyQuestion";
import SvgPlusIcon from "@/assets/icons/plus.svg";
import EditBtn from "@/components/base/buttons/EditBtn.vue";

@Component({
    components: {
        EditBtn,
        SurveyBody,
        SurveyHeader,
        PageLayout,
        Question,
        SvgPlusIcon,
    },
})
export default class CreateSurvey extends Vue {
    survey = new Survey();

    deleteQuestion(question: SurveyQuestion, index: number): void {
        this.survey.deleteQuestion(question, index);
    }

    async createSurvey(): Promise<void> {
        try {
            await this.survey.createSurvey();
            await this.$router.push({ name: 'surveys' });
        } catch (err) {
            showServerError(err, 'Опрос не создан');
        }
    }

    get isCanSave(): boolean {
        return this.isValidHead && this.isValidBody;
    }
    get isValidHead(): boolean {
        const s = this.survey;
        return !!s.title && !!s.description && !!s.start_date && !!s.lang;
    }
    get isValidBody(): boolean {
        return this.survey.questions.every(q => !!q.text && q.answers.every(a => !!a.text));
    }
}
