
import { Component, Vue } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { IColumnFilter } from "@/types";
import { DebtorsFilters, debtorsFiltersColumns, headers, numberFormatting } from "@/services/TablePage/debtors";
import { Getter } from "vuex-class";
import { getDebtors, getDebtorsExcel, getDebtorsTotal } from "@/api/revenue";
import AppTableDebtors from "@/components/revenue/AppTableDebtors.vue";
import { downloadExcelFile, showServerError } from "@/utils";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import DebtorsPageWrapper from "@/components/revenue/DebtorsPageWrapper.vue";
import { omit } from "lodash-es";
import { IDebtorsTotal } from "@/api/types/revenue";

@Component({
    components: { DebtorsPageWrapper, AppTableDesign, TablePageWrapper, AppTableDebtors },
})
export default class Debtors extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParams;

    getDebtorsFilters: DebtorsFilters = new DebtorsFilters();
    debtorsTable = {
        component: AppTableDebtors,
        apiMethod: getDebtors,
    };
    items: IDebtorsTotal[] = [];
    headers = headers;
    numberFormatting = numberFormatting;
    downloadOptions = {
        loading: false,
    };

    get getDebtorsFilterColumns(): IColumnFilter[] {
        return debtorsFiltersColumns;
    }

    preparedParams(): object {
        let paramsObj = { ...this.getDefaultFiltersParams, ...this.getFiltersParamsObject };
        paramsObj = { ...omit( paramsObj, 'period') };

        return paramsObj;
    }

    async getExcel(): Promise<void> {
        this.downloadOptions.loading = true;
        const paramsObj = this.preparedParams();
        try {
            const file = await getDebtorsExcel(paramsObj);
            downloadExcelFile(file, `Должники`, 'xlsx');
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
        this.downloadOptions.loading = false;
    }

    async getDebtorsTotal(): Promise<void> {
        try {
            const paramsObj = this.preparedParams();
            this.items = await getDebtorsTotal({ ...paramsObj });
        } catch (err) {
            showServerError(err);
        }
    }
}
