
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { showServerError } from '@/utils';
import { getCashbackEcomSingle } from "@/api/campaigns";
import StatsCard from "@/components/base/StatsCard.vue";
import SvgManager from '@/assets/icons/managers.svg';
import { ICampaignEcomAffiliate } from "@/api/types/campaigns";
import TableWithFrontPagination from "@/components/base/table/TableWithFrontPagination.vue";
interface ICashbackAffiliate{
    name: string;
    value: any;
    router?: boolean;
    to?: string | {};
    template?: (item: any) => string;
}
@Component({
    components: {
        TableWithFrontPagination,
        StatsCard,
        SvgManager,
    },
})
export default class TopOfContest extends Vue {
    @Mutation('SET_TOTAL_ELEMENTS_DATA_TABLE') setTotalElementsDataTable;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParams;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    cashbackAffiliate = {} as ICampaignEcomAffiliate;
    headers = [
        { text: 'Месяц', sortable: false, value: 'month', width: '100px' },
        { text: 'План', sortable: false, value: 'payout_current',align: 'right'  },
        { text: 'Выполнение', sortable: false, value: 'payout_goal', align: 'right'  },
        { text: 'Сумма прироста', sortable: false, value: 'payout_growth', align: 'right'  },
        { text: 'Гарантированный кэшбек', sortable: false, value: 'guaranteed_amount', align: 'right'  },
        { text: 'Кэшбек от прироста', sortable: false, value: 'payout_goal', align: 'right'  },
    ];
    numberFormatting = [
        { slot: 'item.payout_current', key: 'payout_current' },
        { slot: 'item.payout_goal', key: 'payout_goal' },
        { slot: 'item.payout_growth', key: 'payout_growth' },
        { slot: 'item.guaranteed_amount', key: 'guaranteed_amount' },
        { slot: 'item.payout_goal', key: 'payout_goal' },
        { slot: 'item.total_amount', key: 'total_amount' },
    ];
    loading = false;

    created(): void {
        this.getAffiliateCashback();
    }

    get affiliateFields(): ICashbackAffiliate[] {
        return this.cashbackAffiliate ? [
            {
                name: 'ID партнёра:',
                value: this.cashbackAffiliate.affiliate_id,
                router: true,
                to: { name: 'affiliate', params: { id: this.cashbackAffiliate.affiliate_id } },
            },
            { name: 'ФИО партнёра:', value: this.cashbackAffiliate.affiliate_name },
            { name: 'Менеджер партнёра:', value: this.cashbackAffiliate.manager_name, template: this.getManager },
            { name: 'Общий кэшбек:', value: this.cashbackAffiliate.total_amount, template: this.getAmount },
        ] : [];
    }

    getManager(item: string): string {
        return `${this.cashbackAffiliate.manager_id} ${item}`;
    }

    getAmount(item: number): string {
        return item ? item.toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }) : '0,00';
    }

    get title(): string {
        return `Суперкэшбек на eCom: ${this.$route.params.id}`;
    }

    async getAffiliateCashback(): Promise<void> {
        try {
            this.cashbackAffiliate = await getCashbackEcomSingle(+this.$route.params.id);
        } catch (err) {
            showServerError(err, `${this.title} не загружен`);
        } finally {
            this.loading = false;
        }
    }
}
