
import { Component, Vue, Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import Status from '@/components/base/Status.vue';
import FormModal from '@/components/base/FormModal.vue';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { IOfferLimit } from '@/api/types/uapi';
import { addOfferLimit } from '@/api/uapi';
import store from '@/store';
import { translateVisibility as translateActive } from "@/utils/translate";

@Component({
    components: {
        Status,
        FormModal,
    },
})
export default class ActiveLimit extends Vue {
    @Prop({ type: [Object, Array], default: () => ({}) }) activeLimit!: IOfferLimit;
    @Prop({ type: Number, required: true }) offerId!: number;
    items: any[] = [];
    headers = [
        {
            text: 'Только принятые',
            value: 'limit_data.accepted_only',
            align: 'center',
            class: 'font-weight-regular',
            width: '10%',
        },
        {
            text: 'Общий лимит',
            value: 'limit_data.total',
            class: 'font-weight-regular',
        },
        {
            text: 'Дата начала',
            value: 'start_time',
            class: 'font-weight-regular',
        },
        {
            text: 'Дата окончания',
            value: 'end_time',
            class: 'font-weight-regular',
        },
        {
            text: 'Индивидуальные лимиты',
            value: 'data-table-expand',
            align: 'center',
            class: 'font-weight-regular',
            width: '20%',
        },
    ];
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    translateActive = translateActive;
    newLimitModal = false;
    newLimit: IOfferLimit = {
        start_time: '',
        end_time: '',
        limit_data: {
            accepted_only: false,
            caps: [],
        },
    };

    created(): void {
        this.items.push(this.activeLimit);
    }

    get disabledSaveBtn(): boolean {
        return !this.newLimit.start_time || this.disabledAddIndividualLimitBtn;
    }

    get disabledAddIndividualLimitBtn(): boolean {
        let hasEmptyCaps = false;
        if (this.newLimit.limit_data.caps.length) {
            hasEmptyCaps = this.newLimit.limit_data.caps.some((item) => !item.cap
                || (item.ids && !item.ids.length));
        }

        return hasEmptyCaps;
    }

    allowedStartTime(val: string): boolean | void {
        if (this.newLimit.end_time) {
            return new Date(this.newLimit.end_time as string) > new Date(val);
        }
        return true;
    }

    allowedEndTime(val: string): boolean | void {
        if (this.newLimit.start_time) {
            return new Date(this.newLimit.start_time as string) < new Date(val);
        }
        return true;
    }

    openModal(): void {
        if (this.$props.activeLimit && this.$props.activeLimit.limit_data) {
            this.newLimit.limit_data = JSON.parse(JSON.stringify(this.$props.activeLimit.limit_data));

            this.$set(this.newLimit, 'start_time', format(
                new Date(this.$props.activeLimit.start_time),
                'yyyy-MM-dd',
                { locale: ru },
            ));
        }

        this.newLimitModal = true;
    }

    async addNewLimit(): Promise<void> {
        try {
            if (!this.newLimit.end_time) {
                delete this.newLimit.end_time;
            }
            const data = await addOfferLimit(this.$props.offerId, this.newLimit);
            this.newLimitModal = false;
            this.$emit('add-limit', data);
        } catch (err) {
            const message = err?.data?.error?.details?.limitData;
            store.commit('SET_SERVER_ERROR', {
                status: err.status,
                message: 'Не удалось добавить новый лимит',
                subtitle: message || '',
            });
        }
    }
}
