
import { Component, Vue, Prop } from 'vue-property-decorator';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import SvgFileImage from "@/assets/icons/file-image.svg";
import SvgFileImageDelete from "@/assets/icons/file-image-delete.svg";
import SvgFileDownload from "@/assets/icons/file-download-grey.svg";
import SvgReload from "@/assets/icons/reload-double.svg";
import ImageInput from "@/components/base/form/ImageInput.vue";
import { downloadFileToDisk, downloadImageFromUrl } from "@/api";
import { formatBytes } from "@/utils";

@Component({
    components: {
        ImageInput, TrashBtn, SvgFileImageDelete,
        SvgFileImage, SvgFileDownload, SvgReload,
    },
})
export default class ImagePreview extends Vue {
    @Prop({ required: true }) image: any;
    @Prop({ type: String, default: '728' }) maxWidth!: string;
    dialog = false;

    get urlImg(): any {
        if (typeof this.image === 'string') {
            return this.image;
        }
        return URL.createObjectURL(this.image);
    }

    get isImageSaved(): boolean {
        return typeof this.image === 'string';
    }

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 768;
    }

    get fileName(): string {
        if (this.isImageSaved) {
            const name = this.image.split('/');
            return `File: ${name[name.length - 1] || ''}`;
        }
        return `File: ${this.image!.name! || ''}, ${formatBytes(this.image!.size || 0)}`;
    }

    downloadImage(): void {
        if (this.isImageSaved) {
            downloadImageFromUrl(this.image);
        } else {
            downloadFileToDisk(this.image, this.image.name);
        }
    }

}
