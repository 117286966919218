
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import Multiselect from '@/components/base/form/Multiselect.vue';
import Status from '@/components/base/Status.vue';
import SaveBtnTooltip from '@/components/base/buttons/SaveBtnTooltip.vue';
import ColumnsCheckboxes from '@/components/statistics/ColumnsCheckboxes.vue';
import { IFilter } from '@/types';
import { ICurrency } from '@/api/types/catalogue';
import DateRangeOldWrapper from '@/components/base/pickers/DateRangeOldWrapper.vue';
import { filterClass } from '@/services/filters/filterClass';

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        DateRangeOldWrapper,
        ColumnsCheckboxes, Multiselect, Status, SaveBtnTooltip,
    },
})

export default class FiltersBase extends Vue {
    @Prop({ type: String, default: 'Фильтры' }) readonly title!: string;
    @Prop({ type: Array, default: () => [], required: true }) readonly filters!: IFilter[];
    @Prop({ type: Object, default: () => ({}) }) readonly loading!: {[key: string]: boolean};
    @Prop({ default: false }) readonly isGroupBy!: boolean;
    @Prop({ type: Object, default: () => ({}) }) readonly selectedFilters!: object; // for chips
    @Prop({ type: String, default: 'mdi-filter' }) readonly icon!: string;
    @Prop({ type: String, default: 'Применить' }) readonly successBtnText!: string;
    @Prop({ type: String, default: 'Сбросить' }) readonly cancelBtnText!: string;
    @Prop({ type: Number, default: 4 }) readonly sizeLG!: number;
    @Prop({ type: Number, default: 4 }) readonly sizeXL!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeMD!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeSM!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeMain!: number;
    @Prop({ type: Boolean, default: true }) readonly isClearable!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isOpenPanel!: boolean;
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
    @Prop({ type: Boolean, default: false }) readonly needTooltip!: boolean;
    @Prop({ type: String, default: '' }) readonly attachPrefixClass!: string;
    @Prop({ type: Object, required: false, default: () => ({}) }) readonly emptyConditions!: object;
    @Prop() readonly disabledDates!: any;

    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultParamsObject;

    @catalogue.Getter('GET_CURRENCIES') currencies;
    @catalogue.Getter('GET_COUNTRIES') countries;

    panel: number | null = null;
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    filterClass = filterClass;

    selectItem(item: any, filter: any): string {
        return item.text ? item.text : item[filter.itemText] || item;
    }

    changeMultiselectItems({ type, items }: any): void {
        const currentFilter = this.filters.find((filter) => filter.id === type);
        if (currentFilter) {
            currentFilter.select = items;
        }
    }

    @Watch('filters', { immediate: true, deep: true })
    changeFilterValues(): void {
        this.filters.forEach((fl) => {
            if (fl.select?.length || (fl.select && Object.keys(fl.select)?.length > 0 ) || this.isOpenPanel) {
                this.panel = 0;
            }
        });
        this.changeCheckbox();
    }

    clear(filter: any): void {
        filter.clear();
        const el = this.$refs[filter.id]?.[0];
        el.cachedItems = [];
    }

    removeItem(filter: any): void {
        // пока что сделано для фильтров,
        // в которых можно выбрать только один статус
        if (filter.remove !== undefined) {
            filter.select = '';
            const filters = { ...this.getFiltersParamsObject };
            delete filters[filter.id];
            this.setFiltersParamsObject(filters);
            const filtersDefault = { ...this.setDefaultParamsObject };
            delete filtersDefault[filter.id];
            this.setDefaultParamsObject(filters);
        }
    }

    updateSearch(filter: IFilter, value: string | null): void {
        if (filter.apiMethod === undefined || typeof value !== 'string') return;
        const el = this.$refs[filter.id]?.[0];

        if (filter.multiple && filter.autocompleteValue === null) {
            filter.items = filter.select;
            el.cachedItems = [];
        }
        filter.autocompleteValue = value;
        this.$emit('debounce-query-selections', filter.id);
    }

    filterItems(item: any, queryText: string, filter: IFilter): boolean {
        const textOne = item[filter.itemText].toLowerCase();
        const searchText = queryText.toLowerCase();
        if (!filter.alsoSearchBy) return textOne.indexOf(searchText) > -1;
        const textTwo = item[filter.alsoSearchBy].toLowerCase();
        return textOne.indexOf(searchText) > -1 || textTwo!.indexOf(searchText) > -1;
    }

    get hasError(): boolean {
        return Object.keys(this.emptyConditions).length > 0 && this.needTooltip;
    }

    get disableSubmitBtn(): boolean {
        return this.disabled || this.hasError;
    }

    created(): void {
        this.filters.forEach((fl) => {
            if (fl.isCurrencyCatalogue) {
                fl.items = this.currencies;
                fl.template = this.getCurrenciesTemplate;
            }
            if (fl.isCountryCatalogue) {
                fl.items = this.countries;
            }
        });
    }

    getCurrenciesTemplate(item: ICurrency): string {
        return item.code;
    }

    changeCheckbox(): void {
        this.$emit('change-filters');
    }

    checkMultiPaste(filter: IFilter): void {
        const hasMultiValue = filter.select.map((fl) => fl.includes(' ') || fl.includes(',')).includes(true);
        if (filter.checkMultiPaste && hasMultiValue) {
            const separatedSelect = filter.select.filter((fl) => !(fl.includes(' ') || fl.includes(',')));
            let temporarySelect;
            filter.select.forEach((fl) => {
                temporarySelect = fl.trim().replaceAll(',', '').replace(/\s/g, ' ').split(' ');
            });
            filter.select = [...new Set([...separatedSelect, ...temporarySelect])];
        }
    }

    submitFilters(): void {
        this.filters.forEach((fl) => {
            if (fl.array && fl.supValue && !fl.select?.includes(fl.supValue)) {
                fl.select.push(fl.supValue);
                fl.supValue = '';
            }
        });
        this.$emit('submit');
    }
}
