import { showServerError } from '@/utils/index';

export class GetInfo {
    readonly items;
    readonly key;
    readonly method;
    readonly userProps;
    uniqueFields;

    constructor(data: any, key: string, method: any) {
        this.items = data;
        this.key = key;
        this.method = method;

        this.userProps = [ 'account_id', 'first_name', 'last_name' ];

        this.collectAllFields();
    }

    async getInfoByFields(nameParams = 'ids', needUserProps?: boolean, additionalParams?: object): Promise<any> {
        if (!this.uniqueFields) {
            return [];
        }
        try {
            const params: {[nameParams: string]: number[], props?: any} = {
                [nameParams]: this.uniqueFields,
            };

            if (needUserProps) {
                params.props = this.userProps;
            }
            const result = await this.method({ ...params, ...additionalParams });
            return result.data || result;
        } catch (err) {
            showServerError(err, `Не удалось получить информацию по '${this.key}'`);
            return [];
        }
    }

    private collectAllFields() {
        if (this.items && this.items.length) {
            this.uniqueFields = Array.from(new Set(this.items.filter((item) => !!item[this.key]).map((item) => item[this.key])));
        }
    }
}
