
import { Component, Vue, PropSync } from 'vue-property-decorator';
import { IEndpointItem } from '@/api/types/uapi';

@Component
export default class Main extends Vue {
    @PropSync('endpoint') endpointSync!: IEndpointItem;

    hexTokens = {
        A: {
            pattern: /[a-z0-9-_]/,
        },
    };

    get mask(): any {
        const arr: string[] = [];
        arr.length = 50;
        return {
            mask: arr.fill('A').join(''),
            tokens: this.hexTokens,
        };
    }
}
