
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import SvgCheckBtn from '@/assets/icons/check-btn.svg';
import DateTimePicker from "@/components/base/pickers/DateTimePickerNew.vue";
import { Survey } from "@/services/surveys/Survey";
import { getCurrentDate } from "@/utils/formatDates";
import BaseSelect from "@/components/base/design/BaseSelect.vue";

@Component({
    methods: { getCurrentDate },
    components: {
        BaseSelect,
        BaseCard,
        SvgCheckBtn,
        DateTimePicker,
    },
})

export default class SurveyHeader extends Vue {
    @Prop( { default: [] }) survey!: Survey;
    @Prop( { default: false }) isEdit!: boolean;
    @Prop( { default: false }) isCreate!: boolean;

    get langs(): {code: string, text: string}[] {
        return [
            { code: 'ru', text: this.survey?.translateLang('ru') },
            { code: 'en', text: this.survey?.translateLang('en') },
        ];
    }

    get headers(): { id: string, name: string, value: string | number | undefined | null , editable: boolean, isDefaultDate?: boolean, isText: boolean, isShow: boolean, setData?: Function}[] {
        return [
            { id: 'created_by', name: 'Автор', value: this.survey.created_by, editable: false, isText: true, isShow: !this.isCreate },
            { id: 'title', name: 'Название', value: this.survey.title, editable: true, isText: true, isShow: this.isEdit, setData: (data) => this.survey.title = data },
            { id: 'description',name: 'Описание', value: this.survey.description, editable: true, isText: true, isShow: true, setData: (data) => this.survey.description = data },
            { id: 'start_date',name: 'Дата начала', value: this.survey.start_date, editable: true, isDefaultDate: true, isText: false, isShow: true,setData: (date) => this.survey.start_date = date },
            { id: 'end_date',name: 'Дата окончания', value: this.survey.end_date, editable: true, isDefaultDate: false, isText: false, isShow: true, setData: (date) => this.survey.end_date = date },
            { id: 'lang',name: 'Язык', value: this.survey.lang, editable: false, isText: false, isShow: this.isCreate, setData: (data) => this.survey.lang = data },
            { id: 'active_affiliates_count', name: 'Всего проголосовало', value: this.survey.getVotedSum, editable: false, isText: true, isShow: !this.isCreate },
            { id: 'voted_affiliates_count',name: 'Завершили', value: this.survey.voted_affiliates_count || 0, editable: false, isText: true, isShow: !this.isCreate },
            { id: 'progress_affiliates_count',name: 'Не завершили', value: this.survey.progress_affiliates_count || 0, editable: false, isText: true, isShow: !this.isCreate },
        ];
    }
}
