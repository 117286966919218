
import ContractList from "@/components/revenue/advertiser/ContractList.vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from 'vue';
import BaseCard from "@/components/base/BaseCard.vue";
import { Advertiser } from "@/services/advertiser/Advertiser";
import { IContractor } from "@/api/types/revenue";

@Component({
    components: {
        BaseCard,
        ContractList,
    },
})
export default class Contracts extends Vue {
    @Prop({ required: true }) readonly advertiser!: Advertiser;

    get getContractors(): IContractor[] {
        return this.advertiser.contractors || [];
    }
}
