var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form-modal',{staticClass:"approval-create-form",attrs:{"show-modal":_vm.showModalSync,"max-width":"628","title":_vm.getTitle,"need-hide-btn":false,"name-btn-save":"Создать","closable":true,"is-disabled":_vm.isDisableCreateBtn,"is-success-as-create":true},on:{"save":_vm.createNewApproval,"close-modal":_vm.closeModal}},[[_c('div',{staticClass:"approval-create-form__form"},[_c('p',{staticClass:"approval-create-form__item-label"},[_vm._v(" Партнёр ")]),_c('base-autocomplete',{ref:"affiliateInput",staticClass:"approval-create-form__item-input",attrs:{"data":{
                    errorText: 'Партнер не найден',
                    apiMethod: _vm.getAffiliateSearch,
                    itemValue: 'id',
                    template: (item) => `${item.id} ${item.first_name} ${item.last_name}`,
                    placeholder: 'Введите ID или имя партнёра',
                    chipWidth: 132,
                    clearable: true,
                    multiple: true,
                    maxShowItems: 20,
                    multiPaste: true,
                },"default-search":_vm.defaultPartner,"also-search-by":"email","limit-multi-paste":null},on:{"change":_vm.updateAffiliates}}),_c('p',{staticClass:"approval-create-form__item-label"},[_vm._v(" Оффер ")]),_c('base-autocomplete',{ref:"offersInput",staticClass:"approval-create-form__item-input",attrs:{"data":{
                    errorText: 'Оффер не найден',
                    apiMethod: _vm.getEmployeesOffersV2,
                    multiple: true,
                    key: 'search',
                    itemValue: 'legacy_id',
                    template: (item) => item.legacy_id + ' ' + item.name,
                    placeholder: 'Введите ID или название оффера',
                    clearable: true,
                    maxShowItems: 20,
                    chipWidth: 132,
                    multiPaste: true
                },"default-search":_vm.getCurrentOffer ? _vm.getCurrentOffer.legacy_id : ''},on:{"change":_vm.updateOffers}}),_c('p',{staticClass:"approval-create-form__item-label"},[_vm._v(" Статус ")]),_c('base-select',{staticClass:"approval-create-form__item-input approval-create-form__select",attrs:{"items":_vm.statuses,"item-text":"name","value":_vm.approval.status,"is-multiple":false},on:{"update:value":function($event){return _vm.$set(_vm.approval, "status", $event)},"select":_vm.updateStatus}})],1),(_vm.isLoading)?_c('loader'):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }