import { ACCOUNT_TYPE } from '@/mappings/account-type';
import { USER_ROLES } from '@/mappings/user-roles';
import { IStatus } from "@/types";

export const translateBoolean = (item: boolean | number | string): string => {
    switch (item) {
    case 1:
    case 'true' :
    case 'allow':
    case 'disabled':
    case 'active':
    case true: return 'Да';
    case 0:
    case 'reject':
    case 'false' :
    case 'deleted':
    case false: return 'Нет';
    default: return item.toString();
    }
};

export const translateFinancialStatus = (status: string): IStatus => {
    switch (status) {
    case 'new': return { text: 'Новый', color: 'blue' };
    case 'sent': return { text: 'Отправлен в 1С', color: 'purple' };
    case 'pending': return { text: 'Ожидает оплаты', color: 'purple' };
    case 'partly_paid': return { text: 'Частично оплачен', color: 'orange' };
    case 'paid': return { text: 'Оплачен', color: 'green' };
    default: return { text: 'Отсутствует', color: '' };
    }
};

export const translateLang = (status: string): string => {
    switch (status) {
    case 'ru': return 'Русский';
    case 'en': return 'Английский';
    default: return status;
    }
};

export const translatePayoutsRequestStatus = (status: string): IStatus => {
    switch (status) {
    case 'new': return { text: 'Новый', color: 'blue' };
    case 'approved': return { text: 'Одобрен', color: 'green' };
    case 'manager_rejected': return { text: 'Отклонён', color: 'magenta' };
    case 'partner_rejected': return { text: 'Отклонён партнером', color: 'magenta' };
    case 'partner_edited': return { text: 'Изменён', color: 'orange' };
    case 'deleted': return { text: 'Удалён', color: '' };
    default: return { text: '', color: '' };
    }
};

export const translateStatisticsStatus = (status: string | number): IStatus => {
    switch (status) {
    case '0':
    case 0: return { text: 'Без загрузок', color: '' };
    case '1':
    case 1: return { text: 'Все с загрузками', color: '' };
    case 'pending': return { text: 'Ожидает загрузки', color: 'purple' };
    case 'in_progress': return { text: 'Загружается', color: 'orange' };
    case 'done': return { text: 'Загружена', color: 'green' };
    case 'deleted': return { text: 'Удалена', color: '' };
    default: return { text: 'Отсутствует', color: '' };
    }
};

export const translateAccountStatus = (status: string): IStatus => {
    switch (status) {
    case 'pending': return { text: 'Ожидает заполнения', color: 'purple' };
    case 'filled': return { text: 'Заполнен', color: 'green' };
    default: return { text: 'Отсутствует', color: '' };
    }
};

export const translateOfferStatus = (status: string): IStatus => {
    switch (status) {
    case 'pending': return { text: 'В работу', color: 'yellow' };
    case 'in_progress': return { text: 'Завершить', color: 'purple' };
    case 'done': return { text: 'Завершено', color: 'green' };
    default: return { text: 'Новый', color: 'blue' };
    }
};

export const translatePartnerStatus = (status: string): IStatus => {
    switch (status) {
    case 'active': return { text: 'Активен', color: 'green' };
    case 'pending': return { text: 'В ожидании', color: 'purple' };
    case 'blocked': return { text: 'Заблокирован', color: 'red' };
    case 'rejected': return { text: 'Мало опыта', color: 'magenta' };
    case 'deleted': return { text: 'Удалён', color: '' };
    default: return { text: '', color: '' };
    }
};

export const translateSurveyStatus = (status: string): IStatus => {
    switch (status) {
    case 'active': return { text: 'Активен', color: 'green' };
    case 'planned': return { text: 'Запланирован', color: 'purple' };
    case 'completed': return { text: 'Завершён', color: 'orange' };
    case 'deleted': return { text: 'Удалён', color: '' };
    default: return { text: '', color: '' };
    }
};

export const translateOfferStatusApprovals = (status: string): IStatus => {
    switch (status) {
    case 'pending': return { text: 'В ожидании', color: 'purple' };
    case 'approved': return { text: 'Одобрен', color: 'green' };
    case 'rejected': return { text: 'Отклонён', color: 'magenta' };
    case 'paused': return { text: 'На паузе', color: 'orange' };
    case 'expired': return { text: 'Протух', color: 'darkblue' };
    default: return { text: 'Новый', color: 'blue' };
    }
};

export const translateApprovalConfirmations = (item: string | boolean): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да' : return { text, color: 'orange' };
    default : return { text, color: '' };
    }
};

export const translateStatTaskStatus = (status: string): IStatus => {
    switch (status) {
    case 'pending': return { text: 'В работу', color: 'purple' };
    case 'in_progress': return { text: 'В работе', color: 'orange' };
    case 'done': return { text: 'Завершена', color: 'green' };
    case 'deleted': return { text: 'Удалена', color: '' };
    default: return { text: 'Новая', color: 'blue' };
    }
};

export const translateVisibility = (item: boolean | number | string): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return { text: 'Отображается', color: 'green' };
    case 'Нет': return { text: 'Скрыт', color: 'red' };
    default: return { text: 'Не указано', color: '' };
    }
};

export const translateVertical = (item: string): string => {
    switch (item) {
    case 'financial': return 'Финансы';
    case 'e-commerce': return 'E-commerce';
    default: return item;
    }
};

export const translateVerify = (item: string): string => {
    switch (item) {
    case 'true': return 'Подтвержден';
    case 'false': return 'Не подтвержден';
    default: return item;
    }
};

export const translatePayoutMethodType = (item: string): string => {
    switch (item) {
    case 'commission': return 'Комиссия';
    case 'compensation': return 'Компенсация';
    default: return 'Не указано';
    }
};

export const translateUserType = (item: string): IStatus => {
    switch (item) {
    case ACCOUNT_TYPE.EMPLOYEE: return { text: 'Сотрудник', color: '' };
    case ACCOUNT_TYPE.AFFILIATE: return { text: 'Партнёр', color: '' };
    case ACCOUNT_TYPE.SYSTEM: return { text: 'Системный', color: '' };
    case ACCOUNT_TYPE.ADVERTISER: return { text: 'Рекламодатель', color: '' };
    default: return { text: 'Не указано', color: '' };
    }
};

export const translateUserStatus = (item: string): IStatus => {
    switch (item) {
    case 'active': return { text: 'Активен', color: 'green' };
    case 'deleted': return { text: 'Неактивен', color: 'red' };
    case 'pending': return { text: 'В ожидании', color: 'orange' };
    case 'rejected': return { text: 'Отклонён', color: 'magenta' };
    case 'blocked': return { text: 'Заблокирован', color: '' };
    default: return { text: 'Не указано', color: '' };
    }
};

export const translateLeadStatus = (item: string | boolean): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return { text: 'Оплачен', color: 'green' };
    case 'Нет': return { text: 'Не оплачен', color: 'red' };
    default: return { text: '', color: '' };
    }
};

export const translatePayoutMethodStatus = (item: boolean | number | string): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return { text: 'Активна', color: 'green' };
    case 'Нет': return { text: 'Не активен', color: 'red' };
    default: return { text: '', color: '' };
    }
};

export const translatePaymentsRequisite = (item: string, methodId?: string): string => {
    switch (item) {
    case 'company_name': return 'Название компании';
    case 'card_number': return 'Номер карты';
    case 'legal_id': return 'Контрагент';
    case 'cabinet_id': return 'Номер кабинета';
    case 'email': return 'Email';
    case 'legal_name': return 'Название организации';
    case 'bank_name': return 'Название банка';
    case 'account_id': {
        if (methodId === 'adsfin_v1') return 'Номер кабинета';
        return 'Номер кошелька';
    }
    case 'card_expiration': return 'Срок действия';
    case 'cardholder_name': return 'Имя';
    case 'cardholder_lastname': return 'Фамилия';
    case 'cardholder_birthday': return 'Дата рождения';
    case 'cardholder_address': return 'Адрес';
    case 'cardholder_city': return 'Город';
    case 'cardholder_country': return 'Страна';
    case 'address': return 'Адрес';
    case 'code': return 'Код';
    case 'name': return 'Имя';
    case 'vat': return 'VAT-номер';
    case 'reg_number': return 'Рег.номер';
    default: return item;
    }
};

export const getCurrencyIcon = (item: string): string => {
    switch (item) {
    case 'RUB': return '₽';
    case 'UAH': return '₴';
    case 'EUR': return '€';
    case 'USD': return '$';
    case 'KZT': return '₸';
    case 'ARS': return '$';
    case 'AUD': return '$';
    case 'BRL': return 'R$';
    case 'CZK': return 'Kč';
    case 'INR': return '₹';
    case 'MXN': return '$';
    case 'PLN': return 'zł';
    case 'VND': return '₫';
    case 'UYU': return '$';
    default: return item;
    }
};

export const translateUserRole = (item: string): IStatus => {
    switch (item) {
    case USER_ROLES.SUPERVISOR: return { text: 'Супервизор', color: '' };
    case USER_ROLES.AFFILIATE_MANAGER: return { text: 'Менеджер партнёра', color: '' };
    case USER_ROLES.FINANCIER: return { text: 'Финансист', color: '' };
    case USER_ROLES.ACCOUNT_MANAGER: return { text: 'Аккаунт-менеджер', color: '' };
    case USER_ROLES.STAT_MANAGER: return { text: 'Менеджер статистики', color: '' };
    case USER_ROLES.FINMEDIA: return { text: 'Менеджер Финмедиа', color: '' };
    case USER_ROLES.ADMIN: return { text: 'Администратор', color: '' };
    case USER_ROLES.API_OPERATOR: return { text: 'API менеджер', color: '' };
    case USER_ROLES.SUPPORT: return { text: 'Саппорт', color: '' };
    case USER_ROLES.ORGANIZATION_USER: return { text: 'ЛК организаций', color: '' };
    case USER_ROLES.GENERATES_INVOICES: return { text: 'Создание счетов', color: '' };
    case USER_ROLES.SYSTEM_1C: return { text: 'Внешний сервис', color: '' };
    case USER_ROLES.SERVICE_ISSUES_TOKENS: return { text: 'Issues tokens service', color: '' };
    case USER_ROLES.SERVICE_OFFERS: return { text: 'Offer service', color: '' };
    case USER_ROLES.SERVICE_CONTEST: return { text: 'Contest service', color: '' };
    case USER_ROLES.SERVICE_PAYOUT: return { text: 'Payout service', color: '' };
    default: return { text: item, color: '' };
    }
};

export const translateMasterAccountStatus = (item: boolean): IStatus => {
    switch (item) {
    case true: return { text: 'Активен', color: 'green' };
    case false: return { text: 'Удалён', color: 'red' };
    default: return { text: 'Не указано', color: '' };
    }
};
export const translateOtherServiceStatus = (item: boolean | string): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да' : return { text: 'Активна', color: 'green' };
    case 'Нет' : return { text: 'Не активна', color: 'red' };
    default: return { text: 'Не указано', color: '' };
    }
};
export const translateFinanceInvoicesStatus = (item: string): IStatus => {
    switch (item) {
    case 'skipped': return { text: 'Не учитывается', color: '' };
    case 'paid': return { text: 'Оплачен полностью', color: 'green' };
    case 'partially_paid': return { text: 'Оплачен частично', color: 'purple' };
    case 'not_paid': return { text: 'Не оплачен', color: 'red' };
    case 'unknown': return { text: 'Неизвестный статус', color: '' };
    default: return { text: '', color: '' };
    }
};

export const translatePrivateOffers = (item: string|boolean): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да' : return { text, color: 'orange' };
    case 'Нет' : return { text, color: 'mint' };
    default : return { text, color: '' };
    }
};

export const translateTwoFactor = (item: string|boolean): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да' : return { text, color: 'green' };
    case 'Нет' : return { text, color: 'red' };
    default : return { text, color: '' };
    }
};

export const translateReadyForErir = (item: string|boolean): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да' : return { text, color: 'purple' };
    case 'Нет' : return { text, color: 'green' };
    default : return { text, color: '' };
    }
};

export const translateServiceType = (item: string): string => {
    switch (item) {
    case 'offer': return 'По офферам ';
    case 'referral_payment': return 'Реф.отчисления';
    case 'other': return 'Прочие';
    default: return '';
    }
};

export const translateOffersStatus = (item: string): IStatus => {
    switch (item) {
    case 'active': return { text: 'Активен', color: 'green' };
    case 'paused': return { text: 'На паузе', color: 'red' };
    case 'deleted': return { text: 'Удалён', color: '' };
    case 'pending': return { text: 'В ожидании', color: 'purple' };
    case 'expired': return { text: 'Истёк', color: 'darkblue' };
    default: return { text: item, color: '' };
    }
};

export const translateAdvertiserStatus = (item: string): IStatus => {
    switch (item) {
    case 'active': return { text: 'Активен', color: 'green' };
    case 'deleted': return { text: 'Удалён', color: '' };
    case 'blocked': return { text: 'Заблокирован', color: 'red' };
    case 'rejected': return { text: 'На паузе', color: 'purple' };
    case 'pending': return { text: 'В ожидании', color: 'purple' };
    default: return { text: item, color: '' };
    }
};

export const translateCashbackStatus = (item: string): IStatus => {
    switch (item) {
    case 'in_progress': return { text: 'В процессе', color: 'orange' };
    case 'completed': return { text: 'Завершено', color: 'green' };
    default: return { text: '', color: '' };
    }
};

export const translatePersonalRateStatus = (item: string): IStatus => {
    switch (item) {
    case 'active': return { text: 'Активнa', color: 'green' };
    case 'pending': return { text: 'В ожидании', color: 'purple' };
    case 'deleted': return { text: 'Удалена', color: '' };
    case 'paused': return { text: 'На паузе', color: 'red' };
    default: return { text: '', color: '' };
    }
};

export const translateDebtorStatus = (item: string): IStatus => {
    switch (item) {
    case 'new': return { text: 'Новый', color: 'blue' };
    case 'sent': return { text: 'Отправлен в 1С', color: 'purple' };
    case 'pending': return { text: 'Ожидает оплаты', color: 'yellow' };
    case 'partly_paid': return { text: 'Частично оплачен', color: 'orange' };
    case 'paid': return { text: 'Оплачен', color: 'green' };
    default: return { text: '', color: '' };
    }
};

export const translateChargeRequestStatus = (item: string): IStatus => {
    switch (item) {
    case 'new': return { text: 'Новая', color: 'blue' };
    case 'approved': return { text: 'Согласована', color: 'orange' };
    case 'rejected': return { text: 'Отклонена', color: 'red' };
    case 'sent': return { text: 'Отправлена в 1С', color: 'purple' };
    case 'in_progress': return { text: 'В работе', color: 'mint' };
    case 'done': return { text: 'Оплачена', color: 'green' };
    case 'deleted': return { text: 'Удалена', color: '' };
    default: return { text: '', color: '' };
    }
};

export const translatePersonalRateApproved = (isApproved: boolean, isCorrect: boolean): IStatus => {
    switch (true) {
    case (isCorrect && (isApproved || !isApproved)): return { text: 'Корректна', color: 'green' };
    case (!isCorrect && isApproved): return { text: 'Подтверждена', color: 'orange' };
    case (!isCorrect && !isApproved): return { text: 'Некорректна', color: 'magenta' };
    default: return { text: '', color: '' };
    }
};

export const translateMyLeadgidInvoiceStatus = (item: string): IStatus => {
    switch (item) {
    case 'all': return { text: 'Все', color: '' };
    case 'paid': return { text: 'Оплачен', color: 'green' };
    case 'not_paid': return { text: 'Не оплачен', color: 'red' };
    case 'partly_paid':
    case 'partial_paid': return { text: 'Частично оплачен', color: 'orange' };
    default: return { text: '', color: '' };
    }
};

export const translateDirections = (item: string): string => {
    switch (item) {
    case 'ru': return 'Россия';
    case 'eng': return 'Европа';
    default: return item;
    }
};

export const translateLegalTypes = (item: string): string => {
    switch (item) {
    case 'individual': return 'Физ.лицо';
    case 'legal': return 'Юр.лицо';
    default: return item;
    }
};

export const translateConversionStatus = (item: string): IStatus => {
    switch (item) {
    case 'approved': return { text: 'Принято', color: 'green' };
    case 'pending': return { text: 'На рассмотрении', color: 'orange' };
    case 'rejected': return { text: 'Отклонено', color: 'red' };
    case 'all': return { text: 'Все', color: 'blue' };
    default: return { text: '', color: '' };
    }
};

export const translateGoalStatus = (item: boolean | string): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return { text: 'Активна', color: 'green' };
    case 'Нет': return { text: 'Не активна', color: 'red' };
    default: return { text: '', color: '' };
    }
};

export const translatePlannedChangeStatus = (item: boolean | string): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return { text: 'Исполненные', color: 'green' };
    case 'Нет': return { text: 'Запланированные', color: 'red' };
    default: return { text: '', color: '' };
    }
};

export const translateNotificationStatus = (item: boolean | string): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return { text: 'Удалено', color: 'red' };
    case 'Нет': return { text: 'Активно', color: 'green' };
    default: return { text: '', color: '' };
    }
};

export const translateBankDetails = (item: string): string => {
    switch (item) {
    case 'card_number': return 'Номер карты';
    case 'company_name': return 'Название компании';
    case 'card_expiration': return 'Срок действия';
    case 'cardholder_name': return 'Имя';
    case 'cardholder_lastname': return 'Фамилия';
    case 'cardholder_birthday': return 'Дата рождения';
    case 'cardholder_address': return 'Адрес';
    case 'cardholder_city': return 'Город';
    case 'cardholder_country': return 'Страна';
    case 'account_id': return 'Номер кошелька кабинета';
    case 'address': return 'Адрес';
    case 'code': return 'Код';
    case 'IBAN': return 'IBAN';
    case 'SWIFT': return 'SWIFT';
    case 'name': return 'Имя';
    case 'vat': return 'VAT-номер';
    case 'reg_number': return 'Рег.номер';
    case 'legal_id': return 'Название организации';
    case 'email': return 'E-mail';
    case 'cabinet_id': return 'ID кабинета';
    default: return item;
    }
};

export const translatePaymentsStatus = (item: string): IStatus => {
    switch (item) {
    case 'new': return { text: 'Новая', color: 'blue' };
    case 'sent': return { text: 'Отправлена в 1С', color: 'purple' };
    case 'in_progress': return { text: 'На исполнении', color: 'orange' };
    case 'done': return { text: 'Исполнена', color: 'green' };
    default: return { text: '', color: '' };
    }
};

export const translatePaymentsStatusConfirmation = (item: string): IStatus => {
    switch (item) {
    case 'approved': return { text: 'Подтверждена', color: 'green' };
    case 'pending': return { text: 'Новая', color: 'orange' };
    case 'rejected': return { text: 'Отклонена', color: 'red' };
    default: return { text: '', color: '' };
    }
};

export const translatePaymentType = (item: string): string => {
    switch (item) {
    case 'pre_payment': return 'Досрочная выплата';
    case 'invoice_payment': return 'Выплата по счёту';
    default: return item;
    }
};

export const translateTicketStatus = (status: string): IStatus => {
    switch (status) {
    case 'new': return { text: 'Новый', color: 'blue' };
    case 'reopened': return { text: 'Переоткрыт', color: 'mint' };
    case 'in_progress': return { text: 'В работе', color: 'purple' };
    case 'resolved': return { text: 'Решен', color: 'green' };
    default: return { text: '', color: '' };
    }
};

export const translateCampaignStatus = (item: string): IStatus => {
    switch (item) {
    case 'archived': return { text: 'В архиве', color: 'red' };
    case 'summarizing': return { text: 'Подсчёт', color: 'orange' };
    case 'in_progress': return { text: 'Активна', color: 'green' };
    default: return { text: '', color: '' };
    }
};

export const translateOfferStatusTopOfWeek = (status: string): string => {
    switch (status) {
    case 'draft': return 'Черновик';
    case 'published': return 'Опубликованный';
    default: return status;
    }
};
export const translateRatesHistory = (status: string): string => {
    switch (status) {
    case 'create': return 'Создание';
    case 'update': return 'Редактирование';
    case 'delete': return 'Удаление';
    default: return status;
    }
};


export const translateMonth = (date: string): string => {
    switch (date) {
    case '01 (January)': return 'Январь';
    case '02 (February)': return 'Февраль';
    case '03 (March)': return 'Март';
    case '04 (April)': return 'Апрель';
    case '05 (May)': return 'Май';
    case '06 (June)': return 'Июнь';
    case '07 (July)': return 'Июль';
    case '08 (August)': return 'Август';
    case '09 (September)': return 'Сентябрь';
    case '10 (October)': return 'Октябрь';
    case '11 (November)': return 'Ноябрь';
    case '12 (December)': return 'Декабрь';
    default: return date;
    }
};

export const translateTokenTypes = (item: string): string => {
    switch (item) {
    case 'api': return 'API-токен ';
    case 'user': return 'User-токен';
    default: return item;
    }
};

export const translateChecker = (item: boolean): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return { text: 'Да', color: 'green' };
    case 'Нет': return { text: 'Нет', color: 'red' };
    default: return { text: '', color: '' };
    }
};
export const translateForConversionForecast = (item: boolean | string): string => {
    switch (item) {
    case 'true' :
    case true: return 'Подошел';
    case 'false' :
    case false: return 'Не подошел';
    default: return 'Частично подошел ';
    }
};

export const translateTheme = (item: string): string => {
    switch (item) {
    case 'light' : return 'Светлая';
    case 'dark' : return 'Тёмная';
    default: return item;
    }
};

export const translateUAPIConversionsStatus = (item: string): IStatus => {
    switch (item) {
    case 'accepted': return { text: 'Принято', color: 'green' };
    case 'rejected': return { text: 'Отклонено', color: 'red' };
    default: return { text: item, color: '' };
    }
};

export const translateUAPIOfferStatus = (item: boolean | string): IStatus => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return { text: 'enabled', color: 'green' };
    case 'Нет': return { text: 'disabled', color: 'red' };
    default: return { text: '', color: '' };
    }
};

export const translateHasReadyParamsFilter = (item: string): string => {
    const text = translateBoolean(item);
    switch (text) {
    case 'Да': return 'Заполнено';
    case 'Нет': return 'Не заполнено';
    default: return item;
    }
};

