import { MutationTree } from 'vuex';

import { IUapiState } from '@/store/modules/uapi/types';

export const mutations: MutationTree<IUapiState> = {
    SET_OFFERS_FILTERS: (state, payload) => {
        state.offersFilters = payload;
    },
    SET_SORT_FILTERS: (state, payload) => {
        state.sortFilters = payload;
    },
    SET_CONVERSIONS_FORECAST_QUERY: (state, payload) => {
        state.conversionsForecastQuery = payload;
    },
};
