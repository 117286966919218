
import { Component, Mixins } from 'vue-property-decorator';
import { ICountry, IOffer } from '@/api/types/uapi';
import { createOffer, getCountries, getOffersList } from '@/api/uapi';
import Filters from '@/components/base/filters/Filters.vue';
import AppTable from '@/components/base/table/AppTable.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { Getter } from 'vuex-class';
import SortTableMixin from '@/mixins/sortTableMixin';
import { eventBus } from '@/eventbus';
import { showServerError } from '@/utils';
import FormModal from "@/components/base/FormModal.vue";
import BaseAutocomplete from "@/components/base/form/BaseAutocomplete.vue";
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';
import { translateUAPIOfferStatus } from "@/utils/translate";

@Component({
    components: {
        BaseAutocomplete,
        FormModal,
        Filters,
        AppTable,
        SvgApplication,
    },
})
export default class OffersList extends Mixins(SortTableMixin) {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    totalElementDataTable = 0;
    dataTable: IOffer[] = [];
    countries: any[] = [];
    settings = {
        title: 'Офферы',
        loading: false,
    };
    newItem: any = {
        id: '',
        name: '',
        status: '',
    };
    showAddModal = false;

    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'id',
            label: 'Оффер',
            apiMethod: getOffersList,
            itemValue: 'id',
            itemText: 'offer_name',
            select: [],
            items: [],
            multiple: true,
        }),
        new filterClass.Multiselect({
            id: 'countries',
            select: [],
            items: [] as ICountry[],
            label: 'Страна',
            noDataText: 'Нет выбранной страны',
            itemValue: 'id',
            template: (item: ICountry): string => item.name,
        }),
        new filterClass.Select({
            id: 'status',
            label: 'Статус',
            items: ['true', 'false'],
            select: 'true',
            clearable: true,
            translate: translateUAPIOfferStatus,
            remove: true,
        }),
    ];

    headers = [
        {
            text: 'ID оффера',
            value: 'id',
            width: '15%',
            align: 'center',
        },
        {
            text: 'Название оффера',
            value: 'offer_name',
            sortable: false,
        },
        {
            text: 'Страна',
            value: 'geo',
            align: 'center',
            sortable: false,
        },
        {
            text: 'Статус',
            value: 'status',
            align: 'center',
            sortable: false,
        },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.offer_name',
            routerName: 'show-offer',
            text: 'offer_name',
        },
    ];

    statuses = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateUAPIOfferStatus,
        },
    ];

    created(): void {
        // используется для сортировки
        // нужен в sortTableMixins
        this.field = 'id';
        const activeFilters = {};
        this.filters.forEach((fl) => {
            if (fl.select && !fl.multiple) {
                activeFilters[fl.id] = fl.select;
            }
        });
        this.setDefaultFiltersParamObject({ ...activeFilters });
        this.setFiltersParamObject({ ...activeFilters });
        this.getCountriesForFilters();
    }

    hasNecessaryItems(necessaryItems: string[]): boolean {
        for (let i = 0; i < necessaryItems.length; i++) {
            const necessaryItem = necessaryItems[i];
            if (!this.newItem[necessaryItem] || this.newItem[necessaryItem] === "") {
                return false;
            }
        }
        return true;
    }

    async getDataTable(offset: number, limit: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        const paramsObj = { ...this.getFiltersParamsObject, ...this.getSortFilters };
        try {
            const { data, meta } = await getOffersList({ offset, limit, ...paramsObj });
            this.dataTable = data;
            this.totalElementDataTable = meta!.total!;
        } catch (err) {
            showServerError(err, 'Список заявок не загружен');
        }
        this.settings.loading = false;
    }

    async getCountriesForFilters(): Promise<void> {
        try {
            const { data } = await getCountries();
            const countriesFilter = this.filters.find((filter) => filter.id === 'countries');
            if (countriesFilter) {
                countriesFilter.items = data;
                this.countries = data.map((item) => item.isoCode);
            }
        } catch (err) {
            showServerError(err, 'Список стран не загружен');
        }
    }

    async addItem(): Promise<void> {
        try {
            const item = { ...this.newItem };
            this.newItem.status === 'enabled' ? item.status = true : item.status = false;
            await createOffer(this.newItem);
            await this.getDataTable(0, this.limit);
            this.showAddModal = false;
            this.$set(this.newItem, 'id', '');
            this.$set(this.newItem, 'name', '');
            this.$set(this.newItem, 'status', '');
        } catch (err) {
            showServerError(err, 'Не удалось добавить оффер');
        }
    }

    closeModal(): void {
        this.showAddModal = false;
        this.$set(this.newItem, 'id', '');
        this.$set(this.newItem, 'name', '');
        this.$set(this.newItem, 'status', '');
    }
}
