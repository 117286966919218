import { ISurvey, ISurveyAnswer, ISurveyQuestion } from "@/api/types/support";
import { SurveyQuestion } from "@/services/surveys/SurveyQuestion";
import { getCurrentDate, getFormatDate } from "@/utils/formatDates";
import { deleteSurvey, patchStatusSurvey, patchSurvey, postSurvey } from "@/api/support";
import { translateSurveyStatus } from "@/utils/translate";
import { IStatus } from "@/types";

interface PostQuestionDTO {
    position: number;
    type: string;
    text: string;
    answers?: ISurveyAnswer[]
}

interface PatchQuestionDTO {
    position: number;
    type: string;
    text: string;
    id?: number;
    answers?: ISurveyAnswer[]
}

interface SurveyDTO {
    title: string;
    description: string;
    start_date: string | null;
    end_date: string | null;
    lang: string;
}

export interface PostSurveyDTO extends SurveyDTO {
    questions: PostQuestionDTO[];
}

export interface PatchSurveyDTO extends SurveyDTO {
    questions: PatchQuestionDTO[];
    id:number;
}

export class Survey implements ISurvey {
    id?: number;
    title: string = '';
    description: string = '';
    start_date: string = '';
    end_date: string | null = null;
    lang: string = 'ru';
    language: string = 'ru';
    created_at?: string = getCurrentDate();
    updated_at?: string = getCurrentDate();
    created_by?: string = '';
    updated_by?: string = '';
    status?: string = '';
    active_affiliates_count? = 0;
    voted_affiliates_count? =0;
    progress_affiliates_count? =0;

    questions: SurveyQuestion[] = [new SurveyQuestion(), new SurveyQuestion(), new SurveyQuestion()];

    constructor(survey?: ISurvey) {
        if (survey !== undefined) {
            this.active_affiliates_count = survey.active_affiliates_count || 0;
            this.voted_affiliates_count = survey.voted_affiliates_count || 0;
            this.progress_affiliates_count = survey.progress_affiliates_count || 0;
            this.questions = survey.questions ? survey.questions.map(i => new SurveyQuestion(i)) : [];
            this.id = survey.id;
            this.created_at = survey.created_at;
            this.updated_at = survey.updated_at;
            this.created_by = survey.created_by;
            this.updated_by = survey.updated_by;
            this.status = survey.status;
            this.title = survey.title;
            this.description = survey.description || '';
            this.start_date = survey.start_date;
            this.end_date = survey.end_date;
            this.language = survey.language || 'ru';
            this.lang = survey.lang || 'ru';
        }
    }

    translateLang(item: string): string {
        switch (item) {
        case 'ru':
            return 'Русский';
        case 'en':
            return 'Английский';
        default:
            return item;
        }
    }

    translateStatus(status: string = this.status!): IStatus {
        return translateSurveyStatus(status);
    }

    deleteQuestion(question: SurveyQuestion | ISurveyQuestion, i?: number): void {
        const index = this.id ? this.questions.findIndex((i) => i.id === question.id) : i;
        if (index || (index === 0)) {
            this.questions.splice(index, 1);
        }
    }

    async createSurvey(): Promise<void> {
        const questions: PostQuestionDTO[] = [];

        this.questions.forEach((i, index) => {
            const ans = i.answers.map((i, index) =>  {
                i.position = index;
                delete i.count;
                return i;
            });
            const item: PostQuestionDTO = {
                position: index,
                type: i.type,
                text: i.text,
                answers: [...ans],
            };
            if (i.isTextType) {
                delete item.answers;
            }
            questions.push(item);
        });

        await postSurvey({
            title: this.title,
            description: this.description,
            start_date: this.start_date ? getFormatDate(this.start_date, 'yyyy-MM-dd HH:mm:ss') : null,
            end_date: this.end_date ? getFormatDate(this.end_date, 'yyyy-MM-dd HH:mm:ss') : null,
            lang: this.lang,
            questions,
        });
    }

    async editSurvey(): Promise<void> {
        const cloneQuestion = JSON.parse(JSON.stringify(this.questions));
        const questions: PatchQuestionDTO[] = [];

        cloneQuestion.forEach((i, index) => {
            const ans = i.answers.map((i, index) =>  {
                i.position = index;
                delete i.count;
                delete i.users;
                return i;
            });
            const item: PatchQuestionDTO = {
                position: index,
                type: i.type,
                text: i.text,
                answers: [...ans],
            };
            if (i.id) {
                item.id = i.id;
            }
            if (i.type === 'text') {
                delete item.answers;
            }
            questions.push(item);
        });
        await patchSurvey(this.id as number, {
            id: this.id as number,
            title: this.title,
            description: this.description,
            start_date: this.start_date ? getFormatDate(this.start_date, 'yyyy-MM-dd HH:mm:ss') : null,
            end_date: this.end_date ? getFormatDate(this.end_date, 'yyyy-MM-dd HH:mm:ss') : null,
            lang: this.lang,
            questions,
        });
    }


    get isSurveyActive(): boolean {
        return  this.status === 'active';
    }

    get isSurveyDeleted(): boolean {
        return  this.status === 'deleted';
    }

    get isCanEdit(): boolean {
        return this.status === 'planned' || this.isSurveyActive;
    }

    get getVotedSum(): number {
        return this.progress_affiliates_count! + this.voted_affiliates_count! || 0;
    }

    get isSurveyQuestions(): boolean {
        return this.questions.length > 0;
    }

    async close(id: number, is_active: boolean): Promise<void> {
        await patchStatusSurvey(id,{ is_active });
    }

    async delete(id: number): Promise<void> {
        await deleteSurvey(id);
    }

    addQuestion(): void {
        const question = new SurveyQuestion();
        this.questions.push(question);
    }
}
