
import { Component, Vue } from 'vue-property-decorator';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import StatsCard from '@/components/base/StatsCard.vue';
import { showServerError } from '@/utils';
import AgentReportInfo from '@/components/orgstats/AgentReportInfo.vue';
import SvgOfferList from '@/assets/icons/nav-bar/offer-list.svg';
import { Getter, namespace } from 'vuex-class';
import TableWithFrontPagination from "@/components/base/table/TableWithFrontPagination.vue";
import { getAgentReport } from '@/api/org-cabinets-backend';
import { IAgentReport } from '@/api/types/org-cabinets-backend';

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        TableWithFrontPagination,
        SvgApplication,
        StatsCard,
        SvgOfferList,
        AgentReportInfo,
    },
})
export default class AgentReport extends Vue {
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencyIcon;
    @Getter('GET_EDITING_MODE') editingMode;

    agentReport = {} as IAgentReport;
    agentReportId: string = '';
    settings = { title: 'Офферы', loading: true };
    headers = [
        { text: 'Юр. лицо', sortable: false, value: 'legal.name' },
        { text: 'Партнёр', sortable: false, value: 'affiliate.account_id' },
        { text: 'Оффер', sortable: false, value: 'offer_name' },
        { text: 'Лидов всего', sortable: false, value: 'leads_total' },
        { text: 'Уник. кликов', sortable: false, value: 'clicks_unique' },
        { text: 'Цена', sortable: false, value: 'sale_price' },
        { text: 'Кол-во', sortable: false, value: 'qty' },
        { text: 'Итого', sortable: false, value: 'sale_total' },
        { text: 'Создан', sortable: false, value: 'created_at', width: '180px' },
        { text: 'Обновлён', sortable: false, value: 'updated_at', width: '180px' },
    ];
    numberFormatting = [
        {
            slot: 'item.sale_price',
            key: 'sale_price',
        },
        {
            slot: 'item.sale_total',
            key: 'sale_total',
        },
    ];

    get title(): string {
        return `Отчет агента ${this.agentReportId}`;
    }

    get isAgentReportNotEmpty(): boolean {
        return Object.keys(this.agentReport).length > 0;
    }

    async getAgentReports(): Promise<void> {
        this.settings.loading = true;
        try {
            this.agentReport = await getAgentReport(this.agentReportId);
        } catch (err) {
            showServerError(err, 'Отчет агента не загружен');
        } finally {
            this.settings.loading = false;
        }
    }

    created(): void {
        this.agentReportId = this.$route.params.id;
        this.getAgentReports();
    }

}
