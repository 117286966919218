import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';

export class InputFilter extends BaseFilter {
    isSubmitEmptySelect?: boolean;
    type?: 'number' | 'string';

    constructor(config: ConstructorType<InputFilter, never>) {
        super(config);
        this.select = config.select ?? '';
        this.isSubmitEmptySelect = config.isSubmitEmptySelect;
        this.type = config.type;
    }
}
