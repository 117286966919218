
import { Component, Prop, Vue } from "vue-property-decorator";
import { INote } from "@/api/types/user";
import { Affiliate } from "@/services/affiliates/Affiliate";
import BaseCard from "@/components/base/BaseCard.vue";
import NotesTooltip from "@/components/base/NotesTooltip.vue";
import FormModal from "@/components/base/FormModal.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import SvgPlus from '@/assets/icons/plus.svg';
import SvgActions from "@/assets/icons/actions.svg";
import SvgMagick from "@/assets/icons/magic-wand-light.svg";
import CardNotes from "@/components/base/CardNotes.vue";
import ImageNotesFile from "@/components/base/ImageNotesFile.vue";

@Component({
    components: { ImageNotesFile, CardNotes, NotesTooltip, ViewDateTime, TrashBtn, FormModal, BaseCard, SvgPlus, SvgActions, SvgMagick },
})

export default class AffiliateNotes extends Vue {
    @Prop({ required: true }) affiliate!: Affiliate;

    editId: number | null = null;
    cashedNote: INote = {} as INote;
    headers = [
        { text: 'Дата', value: 'created_at', sortable: false, align: 'start', width: '100px' },
        { text: 'Автор', value: 'creator', sortable: false, width: '100px' },
        { text: 'Заметка', value: 'text', sortable: false },
        { text: 'Файл' , value: 'file', sortable: false, align: 'center' },
        { text: '', value: 'actions', sortable: false, width: '40px' },
    ];
    tableHeight?: number | string = 'fit-content';
    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
    ];

    get getNotes(): INote[] {
        return this.affiliate.notes! || [];
    }

    get isLoadingTable(): boolean {
        return this.affiliate.isLoadingData;
    }

    mounted(): void {
        setTimeout(() => this.setTableHeight(), 1000);
    }

    setTableHeight(): void {
        const card = document.querySelector('.affiliate-single__about-card')?.querySelector('.base-card__text');
        this.tableHeight = card?.clientHeight;
    }

    saveNote(note: INote): void {
        this.affiliate.editNote(note);
    }

    addNote(note: string, image: any): void {
        this.affiliate.addNote({ note, image });
    }

    deleteNote(item: INote): void {
        this.affiliate.deleteNote(item.id);
    }
}

