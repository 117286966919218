import { Component, Vue } from 'vue-property-decorator';
import { IColumnFilter } from "@/types";

@Component({
    components: {},
})
export default class ColumnsSubs extends Vue {
    filterCols: IColumnFilter[] = [];
    indicatorCols: IColumnFilter[] = [];
    selected: string[] = [];
    clearSubs(): void {
        if (!this.isMobile) {
            const subs = this.filterCols.find(i => i.name === 'Sub ID');
            if (subs) {
                subs.value = false;
            }
        }
    }

    clearIndicators(): void {
        if (!this.isMobile) {
            const items = this.indicatorCols.map(i => i.items.map(j => j.value)).reduce((acc, it) => [...acc, ...it], []);
            this.selected = this.selected.filter(i => !items.includes(i) && i !== 'dm_offer_currency');
        }
    }

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 577;
    }

}
