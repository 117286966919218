
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import TablePagination from "@/components/statistics/extended-report/TablePagination.vue";
import Status from "@/components/base/Status.vue";
import { PAGINATION_RIGHT_PER_PAGE } from "@/configs/global";
import CardMaterial from "@/components/base/CardMaterial.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import TdStatus from "@/components/base/TableUI/TdStatus.vue";
import TdNumber from "@/components/base/TableUI/TdNumber.vue";
import TdRoute from "@/components/base/TableUI/TdRoute.vue";
import ActionSvg from "@/assets/icons/actions.svg";
import SvgActions from "@/assets/icons/actions.svg";
import TdActions from "@/components/base/TableUI/TdActions.vue";
import draggable from "vuedraggable";
import DraggableTableItem from "@/components/base/table/DraggableTableItem.vue";
import SvgDrag from "@/assets/icons/drag-icon.svg";
import QuestionMarkTooltip from "@/components/base/QuestionMarkTooltip.vue";
import TableFooterButtons from "@/components/base/TableUI/TableFooterButtons.vue";

interface ITdNumber {
    slot: string,
    key: string,
    sup?: string,
    format?: string,
    currency?: string,
    currencySymbol?: boolean,
    isMono?: boolean,
}

interface ITdAction {
    icon: string,
    color: string,
    title?: string,
    callback?: Function,
    disabled?: Function,
    isTooltip?: boolean,
    router?: string
}

@Component({
    components: {
        TableFooterButtons,
        QuestionMarkTooltip,
        DraggableTableItem,
        TdRoute,
        TdNumber,
        TdStatus,
        TdActions,
        ViewDateTime,
        CardMaterial,
        Status,
        TablePagination,
        ActionSvg,
        SvgActions,
        SvgDrag,
        draggable,
    },
})
export default class TableWithFrontPagination extends Vue {
    @Prop({ type: Boolean, default: true }) isDense!: boolean;
    @Prop({ type: Array, default: () => [] }) statuses!: [];
    @Prop({ type: Boolean, default: false }) customHeader!: boolean;
    @Prop({ type: Array, required: true, default: () => [] }) items!: [];
    @Prop({ type: String, default: 'id' }) tableItemKey!: string;
    @Prop({ type: Array, required: true }) headers!: any[];
    @Prop({ type: Array, default: () => [] }) routes!: [];
    @Prop({ type: Array, default: () => [] }) uniqueData!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) timeData!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) actions!: ITdAction[];
    @Prop({ type: Boolean, default: true }) loading!: boolean;
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: [String, Array], default: '' }) sortBy!: string | [];
    @Prop({ type: [Array, Boolean], default: true }) sortDesc!: boolean | [];
    @Prop({ type: Boolean, default: true }) isIcon!: boolean;
    @Prop({ type: Boolean, default: true }) disableSort!: boolean;
    @Prop({ type: String, default: 'primary' }) activeColor!: string;
    @Prop({ type: Array, default: () => [] }) numberFormatting!: ITdNumber[];
    @Prop({ type: String, default: 'Нет данных' }) noDataText!: string;
    @Prop({ default: null }) itemsPerPageSelect!: Array<{}> | null;
    @Prop({ type: String }) icon!: string;
    @Prop({ type: Number, default: PAGINATION_RIGHT_PER_PAGE }) itemsPerPage!: number;
    @Prop({ type: Boolean, default: false }) rowSelect!: boolean;
    @Prop({ type: Boolean, default: false }) isDraggable!: boolean;
    @Prop({ type: Array, default: () => [] }) footerButtons!: Array<{}>;


    @PropSync('selected', { type: Array, default: () => ([]) }) selectedSync!: any[];

    // for pagination
    pageCount = 0;

    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };

    handleClick(evt: any): void {
        this.$emit('row-click', evt);
    }

    get isShowFooterButtons(): boolean {
        return this.selectedSync.length > 0 && this.footerButtons.length > 0;
    }

    get isAllItemsSelected(): boolean {
        return this.selectedSync.length === this.items.length;
    }

    get isNotNullSelectedItems(): boolean {
        return this.selectedSync.length > 0 && this.selectedSync.length < this.items.length;
    }

    get paginatedItems(): object[] {
        const startIndex = (this.pagination.page - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.items.slice(startIndex, endIndex);
    }

    get getHeaders(): any {
        if (this.isDraggable) {
            const headersCopy = JSON.parse(JSON.stringify(this.headers));
            const dragHead = { text: '', value: 'drag', align: 'center', width: '20px' };
            const firstIconPosition = 1;
            const lastIconPosition = this.headers.length - 1;
            headersCopy.splice(lastIconPosition, 0, dragHead);
            headersCopy.splice(firstIconPosition, 0, dragHead);
            return headersCopy;
        }
        return this.headers;
    }

    inputHeaderCheckbox(): void {
        this.selectedSync = this.isAllItemsSelected ? [] : this.items;
    }
}
