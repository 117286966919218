
import { Component, Mixins } from "vue-property-decorator";
import { getTicketsList } from "@/api/support";
import { showServerError } from "@/utils";
import TableMixin from "@/mixins/table";
import SvgSign from "@/assets/icons/sign.svg";
import ListLayout from "@/components/layout/ListLayout.vue";
import TicketsFilter from "@/components/support/TicketsFilter.vue";
import { headers, statuses, routes, timeData } from "@/services/TablePage/tickets";
import FormCreateSignature from "@/components/support/FormCreateSignature.vue";

@Component({
    components: {
        FormCreateSignature,
        SvgSign,
        ListLayout,
        TicketsFilter,
    },
})
export default class Tickets extends Mixins(TableMixin) {
    isNewDesignPage = true;
    headers = headers;
    statuses = statuses;
    routes = routes;
    timeData = timeData;
    apiMethod = getTicketsList;
    settings = {
        title: "Поддержка",
        loading: false,
    };

    showModal: boolean = false;

    mounted(): void {
        this.getTicketsList();
    }

    async getTicketsList(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, "Не удалось получить список тикетов");
        }
        this.settings.loading = false;
    }

    async submitData(offset: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Список пользователей не загружен');
        }
    }
}
