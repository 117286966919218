
import { Component, Vue } from 'vue-property-decorator';
import StatsCard from '@/components/base/StatsCard.vue';
import SvgDownload from '@/assets/icons/nav-bar/upload-creation.svg';
import { downloadExcelFile, showServerError } from '@/utils';
import { getFileReport } from '@/api/uapi';
import Loader from "@/components/base/Loader.vue";

@Component({
    components: {
        Loader,
        StatsCard,
        SvgDownload,
    },
})
export default class OfferSingle extends Vue {
    message = '';
    file = '';
    loading = false;

    created(): void {
        this.file = this.$route.query.filename as string;
        this.getFileReport();
    }

    async getFileReport(): Promise<void> {
        try {
            this.loading = true;
            this.message = `Идет процесс скачивания`;
            const response = await getFileReport(this.file);
            if (response.hasOwnProperty('meta')) {
                if (response.meta?.success) {
                    switch (response.data?.status) {
                    case 'in_progress':
                        this.message = `Файл ${this.file}  находится в обработке. Откройте страницу позже, чтобы скачать его.`;
                        break;
                    case 'error':
                        this.message = 'При загрузке файла возникла ошибка.';
                        break;
                    case 'deleted':
                        this.message = 'Запрашиваемый файл удалён. Проверьте правильность ссылки.';
                        break;
                    }
                } else {
                    this.message = `Файл ${this.file} не найден. Проверьте правильность ссылки.`;
                }
            } else {
                downloadExcelFile(response, 'Выгрузка', 'csv');
                this.message = `Файл ${this.file} готов`;
                this.loading = false;
            }
        } catch (err) {
            this.loading = false;
            showServerError(err, 'Не удалось получить выгрузку');
        }
    }
}
