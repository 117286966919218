
import { Vue, Component, Prop } from "vue-property-decorator";
import SvgDownload from "@/assets/icons/download.svg";

@Component({
    components: { SvgDownload },
})

export default class DownloadBtn extends Vue {
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) tooltip!: string;
    @Prop({ default: false }) loading!: boolean;
    @Prop({ default: false }) isSmall!: boolean;
}

