
import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";
import SvgRangeCalendar from '@/assets/icons/range-calendar.svg';
import { formatDateInputPicker, getLastDayOfMonth } from "@/utils/formatDates";

@Component({
    components: { SvgRangeCalendar },
})
export default class MonthPickerRange extends Vue {
    @PropSync('value', { required: true }) valueSync!: string[];
    @Prop({ type: String, default: '' }) readonly label!: string;

    pickerValue: string[] = ['', ''];
    menu: boolean = false;

    get getValue(): string {
        return this.pickerValue[0] && this.pickerValue[1] ?
            `${formatDateInputPicker(this.pickerValue[0], 'LLLL yyyy')} - ${formatDateInputPicker(this.pickerValue[1], 'LLLL yyyy')}` : '';
    }

    clear(): void {
        this.valueSync = [];
        this.pickerValue = [];
        this.$emit('clear-date');
    }

    formatPickerValue(value: string[]): void {
        if(value?.length === 1) {
            this.pickerValue = [formatDateInputPicker(value[0], 'yyyy-MM')];
        } else if (value.length === 2) {
            this.pickerValue = [formatDateInputPicker(value[0], 'yyyy-MM'), formatDateInputPicker(value[1], 'yyyy-MM')];
        } else {
            this.pickerValue = value;
        }
    }

    created(): void {
        this.formatPickerValue(this.valueSync);
    }

    @Watch('valueSync')
    updatePickerValue(value: string[]): void {
        this.formatPickerValue(value);
    }

    updateValue(value: string[]): void {
        if (value?.length === 1) {
            this.valueSync = [value[0] + '-01'];
        } else if (value.length === 2) {
            const lastDay = getLastDayOfMonth(value[1]).getDate();
            this.valueSync = [value[0] + '-01', value[1] + `-${lastDay}`];
        } else {
            this.valueSync = value;
        }
    }

    mounted(): void {
        document.addEventListener('click', this.handleClickOutside);
    }

    beforeDestroy(): void {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside(event: MouseEvent): void {
        const menuContentEl = this.$refs.menuContent as Element;
        if (menuContentEl && !menuContentEl.contains(event.target as Node)) {
            if (this.pickerValue.length === 1) {
                this.pickerValue = [this.pickerValue[0], this.pickerValue[0]];
                this.valueSync = [this.pickerValue[0] + '-01', this.pickerValue[0] + '-01'];
            }
            if (this.pickerValue[0] === '' && this.pickerValue[1] === '') {
                this.menu = true;
            } else {
                if (this.menu) this.$emit('update', this.valueSync.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()));
                this.menu = false;
            }
        }
    }
}
