
import { Component, Vue } from 'vue-property-decorator';
import TabsReport from "@/components/orgstats/TabsReport.vue";
import ReportContent from "@/components/orgstats/ReportContent.vue";
import {
    getAffiliateAgentReport,
} from '@/api/org-cabinets-backend';
@Component({
    components: {
        ReportContent,
        TabsReport,
    },
})
export default class AffiliatesAgentReport extends Vue {
    getAffiliateAgentReport = getAffiliateAgentReport;

}
