import { Module } from 'vuex';

import { actions } from '@/store/modules/uapi/actions';
import { mutations } from '@/store/modules/uapi/mutations';
import { getters } from '@/store/modules/uapi/getters';

import { IUapiState } from '@/store/modules/uapi/types';
import { IRootState } from '@/store/types';

export const state: IUapiState = {
    offersFilters: {},
    sortFilters: {},
    conversionsForecastQuery: {},
};

const namespaced: boolean = true;

export const uapiModule: Module<IUapiState, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
