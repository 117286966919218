import { Module } from 'vuex';
import { getters } from '@/store/modules/statistics/getters';
import { mutations } from '@/store/modules/statistics/mutations';
import { actions } from '@/store/modules/statistics/actions';
import { IRootState } from '@/store/types';
import { IStatisticsState } from '@/store/modules/statistics/types';

export const state: IStatisticsState = {
    conversionsReportId: '',
    conversionsReportInProgress: false,
    conversionsReportLoading: false,
    conversionsReportProgress: 0,
    conversionsReportStatus: '',
    conversionsReportSSE: null,
};

const namespaced = true;

export const statisticsModule: Module<IStatisticsState, IRootState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
