var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form-modal',{staticClass:"partner-search-form",attrs:{"show-modal":_vm.showModalSync,"max-width":"628","title":'Создать доступы',"need-hide-btn":true,"is-success-as-create":true,"name-btn-save":'Создать',"closable":true,"is-disabled":_vm.isSaveBtnDisabled},on:{"update:showModal":function($event){_vm.showModalSync=$event},"update:show-modal":function($event){_vm.showModalSync=$event},"hide-modal":_vm.closeModal,"close-modal":_vm.closeModal,"save":_vm.sendListApproval}},[[_c('div',{staticClass:"partner-search-form__form"},[_c('div',{staticClass:"partner-search-form__item-label"},[_vm._v(" Партнёры ")]),_c('base-autocomplete',{ref:"affiliateInput",staticClass:"partner-search-form__item-input",attrs:{"data":{
                    errorText: 'Партнер не найден',
                    apiMethod: _vm.getAffiliateSearch,
                    itemValue: 'id',
                    template: (item) => `${item.id} ${item.first_name} ${item.last_name}`,
                    placeholder: 'Введите  ID или имя партнёра',
                    chipWidth: 132,
                    clearable: true,
                    multiple: true,
                    maxShowItems: 20,
                    multiPaste: true,
                },"default-search":_vm.defaultPartner,"also-search-by":"email","limit-multi-paste":null},on:{"change":_vm.updateAffiliates}})],1),(_vm.isLoading)?_c('loader'):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }