var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form-modal',{attrs:{"show-modal":_vm.isLoginAsPartner,"max-width":"486","title":"Войти под партнёром","need-hide-btn":false,"need-cancel-btn":false,"name-btn-save":"Войти","closable":true,"custom-class":"form-login-partner","is-disabled":!_vm.affiliate},on:{"save":_vm.login,"close-modal":_vm.closeModal}},[[_c('div',{staticClass:"base__item col-12 flex-nowrap"},[_c('p',{staticClass:"base__title text-left"},[_vm._v(" ID/Имя/Email ")]),_c('p',{staticClass:"base__value base__value--full-width title flex-grow-1"},[_c('base-autocomplete',{staticClass:"affiliate-autocomplete",attrs:{"data":{
                        errorText: 'Партнер не найден',
                        apiMethod: _vm.getAffiliateSearch,
                        itemValue: 'id',
                        template: (item) => `${item.id} ${item.first_name} ${item.last_name} ${item.email}`,
                        placeholder: 'Выберите партнёра',
                        multiple: false,
                        multiPaste: false,
                    },"is-trash":true},on:{"change":_vm.updateAffiliates}})],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }