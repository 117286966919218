import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';

export class ComboboxFilter extends BaseFilter {
    combobox? = true;
    itemValue: string;
    itemText: string | ((item: any) => string);
    multiPaste?: boolean;
    template?: (item: any) => any;
    type?: 'number' | 'string';

    constructor(config: ConstructorType<ComboboxFilter, 'combobox'>) {
        super(config);
        this.select = config.select ?? [];
        this.items = config.items ?? [];
        this.itemValue = config.itemValue;
        this.itemText = config.itemText;
        this.multiPaste = config.multiPaste ?? false;
        this.template = config.template;
        this.type = config.type;
    }
}
