
import { Component, Vue, Prop } from 'vue-property-decorator';
import Status from '@/components/base/Status.vue';

@Component({
    components: { Status },
})

export default class LoadingList extends Vue {
    @Prop({ required: true }) readonly items!: [];
    @Prop({ default: 'Загружаются' }) readonly title!: string;

    get loadingItems(): [] {
        return this.$props.items.filter((item) => item.status !== 'done');
    }
}
