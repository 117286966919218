
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BaseButton extends Vue {
    @Prop({ default: '' }) color!: string;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop({ default: false }) isSmall!: boolean;
    @Prop({ default: false }) isXSmall!: boolean;
    @Prop({ default: false }) isNoShadow!: boolean;
    @Prop({ default: false }) isWithIcon!: boolean;
    @Prop({ default: false }) isXSmallOnMobile!: boolean;
    @Prop({ default: false }) isSmallOnMobile!: boolean;

    get getClasses(): string {
        const classes = {
            '--small': this.isSmall,
            '--x-small': this.isXSmall,
            '--no-shadow': this.isNoShadow,
            '--disabled': this.isDisabled,
            '--with-icon': this.isWithIcon,
            '--small-on-mobile': this.isSmallOnMobile,
            '--x-small-on-mobile': this.isXSmallOnMobile,
            [`--${this.color}`]: !!this.color,
        };
        return Object.keys(classes).filter((key) => classes[key]).join(' ');
    }
}
