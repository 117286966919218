
import { Component, Vue } from 'vue-property-decorator';
import SubstitutionContent from '@/components/uapi/SubstitutionContent.vue';

@Component({
    components: {
        SubstitutionContent,
    },
})
export default class SubstitutionSingle extends Vue {
}
