
import {
    Component, Provide, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import AddingServices from '@/components/revenue/AddingServices.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import CustomerOrder from '@/components/revenue/invoice/CustomerOrder.vue';
import { eventBus } from '@/eventbus';
import Header from '@/components/revenue/invoice/Header.vue';
import Netting from '@/components/revenue/invoice/Netting.vue';
import FormModal from '@/components/base/FormModal.vue';
import SvgInvoiceCreation from '@/assets/icons/nav-bar/invoice-creation.svg';
import { getCurrencyIcon } from '@/utils/translate';

import { getOneInvoice, saveInvoice } from '@/api/revenue';

import {
    ICustomerOrders, IFiles,
    IInvoice,
    IInvoiceAdvertiser,
    IOfferService,
    IOtherService,
    IStatTasks,
} from '@/api/types/revenue';
import { IUser } from '@/api/types/user';
import { getRandomId, showServerError } from '@/utils';
import StatTaskedStatistic from '@/components/revenue/statTask/StatTaskedStatistic.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import SaveBtnTooltipInvoice from '@/components/revenue/invoice/SaveBtnTooltipInvoice.vue';
import BookKeepingTop from '@/components/revenue/invoice/BookKeepingTop.vue';
import InvoiceServices from '@/components/revenue/invoice/InvoiceServices.vue';
import { NETTING_TYPE } from '@/mappings/netting-type';
import { USER_ROLES } from '@/mappings/user-roles';
import {
    ALLOWED_DIFFERENCE_NETTING,
    CHANGE_ROUTE_DEBOUNCE,
    MAX_AMOUNT_INVOICE_DISCREPANCY_KZT, MAX_AMOUNT_INVOICE_DISCREPANCY_RU,
} from '@/configs/global';
import { NotificationMessage } from "@/utils/notification";

// Создаём константы для стора revenueModule
const revenue = namespace('revenueModule');
const auth = namespace('authModule');
const invoice = namespace('invoiceModule');

@Component({
    components: {
        InvoiceServices,
        BookKeepingTop,
        SaveBtnTooltipInvoice,
        TooltipButton,
        StatTaskedStatistic,
        CustomerOrder,
        AddingServices,
        StatsCard,
        Header,
        Netting,
        FormModal,
        SvgInvoiceCreation,
    },
})

export default class InvoiceContent extends Vue {
    // геттеры для передачи условий о заполнености полей
    get invoiceEmptyConditions(): object {
        const {
            isEmptyProject, isEmptyContractor, isEmptyAccountingServices,
            isEmptyAccountingOfferServices, isEmptyAccountingOtherServices,
        } = this;
        return {
            isEmptyProject,
            isEmptyContractor,
            isEmptyAccountingServices,
            isEmptyAccountingOfferServices,
            isEmptyAccountingOtherServices,
        };
    }

    get emptyConditions(): object {
        const {
            isEmptyInvoiceOfferServices, isEmptyAccountingServices, isEmptySomeOtherServices,
            isEmptyAccountingOfferServices, isEmptyAccountingOtherServices, isEmptyStatTaskFiles,
            isErrorOfNetting, isClearServicesCustomerOrder,
        } = this;
        return {
            isEmptyInvoiceOfferServices,
            isEmptyAccountingServices,
            isEmptySomeOtherServices,
            isEmptyAccountingOfferServices,
            isEmptyAccountingOtherServices,
            isEmptyStatTaskFiles,
            isErrorOfNetting,
            isClearServicesCustomerOrder,
            isEmptyServices: this.invoice.offer_services?.length === 0 && this.invoice.other_services?.length === 0,
            isEmptyRateQty: this.invoice.offer_services!.length > 0 && this.isEmptyInvoiceOfferServices,
            isEmptyCurrency: !this.invoice.currency,
        };
    }

    get showIsDraftCheckbox(): boolean {
        if (this.sendInvoiceTo1c) {
            return false;
        }
        if (this.invoice.id) {
            return this.getEditMode && this.isDraftInvoice || (!this.isDraftInvoice && this.getUnsavedDataState);
        }
        return this.getEditMode;
    }

    get isDraftInvoice(): boolean {
        return !!this.invoice.is_draft;
    }

    get isSaveInvoiceButtonDisabled(): boolean {
        if (this.invoice.is_draft) {
            this.showSumError = false;
            return false;
        }
        // если счет не полностью покрывается взаимозачетом, показываем предупреждение:
        // "Сумма заказов не может быть меньше итого к выставлению , более чем на maxDiscrepancy(лимит зависит от валюты)"
        if (!this.isClearServicesCustomerOrder) {
            this.discrepancy = +this.getInvoiceAmount - +this.getTotalSumOfAllCustomerOrders;
            const maxDiscrepancy = this.invoice.currency  === 'KZT' ? MAX_AMOUNT_INVOICE_DISCREPANCY_KZT : MAX_AMOUNT_INVOICE_DISCREPANCY_RU;
            this.showSumError = this.discrepancy > maxDiscrepancy;
        } else {
            this.showSumError = false;
        }

        return this.isEmptyFields
            || this.isEmptyAccountingServices
            || this.showSumError
            || !this.invoice.currency
            || this.isEmptyProject
            || this.isErrorOfNetting
            || this.isClearServicesCustomerOrder
            || this.isEmptyContractor;
    }

    get isEmptyFields(): boolean {
        const isNetting = this.invoice.netting!.length !== 0;
        if (this.invoice.offer_services?.length! > 0) {
            this.isEmptyInvoiceOfferServices = this.invoice.offer_services!
                .some((item) => !item.offer_qty || !item.offer_rate);
        } else {
            this.isEmptyInvoiceOfferServices = false;
        }

        if (this.invoice.other_services?.length! > 0) {
            this.isEmptySomeOtherServices = this.invoice.other_services!
                .some((item) => !item.service_amount);
        } else {
            this.isEmptySomeOtherServices = false;
        }

        if (this.invoice?.customer_orders?.length! > 0) {
            this.isEmptyAccountingServices = this.invoice.customer_orders!
                .some((item) => item.offer_services!.length === 0
                    && item.other_services!.length === 0 && !isNetting);
            this.invoice.customer_orders!.forEach((inv) => {
                if (inv.other_services) {
                    this.isEmptyAccountingOtherServices = inv.other_services!
                        .some((item) => !item.service_amount);
                }
            });

            this.invoice.customer_orders!.forEach((inv) => {
                this.isEmptyAccountingOfferServices = inv.offer_services!
                    .some((item) => !item.offer_qty || !item.offer_rate);
            });
        }

        this.isEmptyStatTaskFiles = !!(
            this.invoice.stat_task
            && this.invoice.stat_task.id
            && !this.invoice.stat_task.files!.length
        );

        return this.isEmptyInvoiceOfferServices
            || this.isEmptyAccountingServices
            || this.isEmptySomeOtherServices
            || this.isEmptyAccountingOfferServices
            || this.isEmptyAccountingOtherServices
            || this.isEmptyStatTaskFiles;
    }

    get isShowNettingPanel(): boolean {
        return this.editingMode || (!this.editingMode && this.invoice.netting?.length as number > 0);
    }

    get getCurrencyIcon(): string {
        return this.invoice.currency ? getCurrencyIcon(this.invoice.currency) : '';
    }

    get getEditMode(): boolean {
        // если счет уже создан
        if (this.invoice.id) {
            return this.editingMode;
        }
        return true;
    }

    get isInvoiceAlreadyExist(): boolean {
        return !!this.invoice.id;
    }

    // Посчитать сумму услуг по офферам
    get getTotalAmountOffersList(): number {
        if (this.invoice.offer_services) {
            return Object.assign(this.invoice.offer_services
                .map((item) => item.total))
                .reduce((a, b) => Number(a) + Number(b), 0);
        }
        return 0;
    }

    // Посчитать `итого к выставлению` =
    // `(сумма услуг по офферам + сумма прочих услуг) - взаимозачёт`
    get getInvoiceAmount(): number {
        const sumOfNetting = this.totalSumOfNetting ? this.totalSumOfNetting : 0;
        return (Number(this.getOfferAndServicesAmount) - sumOfNetting) || 0;
    }

    get getOfferAndServicesAmount(): number {
        return (this.getTotalAmountOffersList + this.getTotalAmountServicesList) || 0;
    }

    // Посчитать сумму прямой выручки всех бухгалтерских счетов
    get getTotalDirectIncomeOfAllAccounts(): number {
        const allOfferServicesFromCustomerOrders = this.invoice.customer_orders!
            .filter((item) => item.offer_services!.length > 0);
        const offerServicesItems = allOfferServicesFromCustomerOrders.map((item) => item.offer_services);

        const offersWithDirectRevenue: IOfferService[] = [];
        offerServicesItems.forEach((invoiceAccount) => {
            const newItem = invoiceAccount!.filter((offerService) => offerService.offer_direct);
            offersWithDirectRevenue.push(newItem as IOfferService);
        });

        const itemsWithTotal: IOfferService[] = [];
        offersWithDirectRevenue.forEach((offerServices) => {
            // @ts-ignore
            const newItem = offerServices.filter((item) => item.total);
            itemsWithTotal.push(newItem);
        });

        const combinedArrayWithTotals: IOfferService[] = [];
        itemsWithTotal.forEach((item) => {
            // @ts-ignore
            item.map((val) => combinedArrayWithTotals.push(val.total));
        });

        return combinedArrayWithTotals
            .reduce((a, b) => Number(a) + Number(b), 0) || 0;
    }

    get getTotalSumOfAllCustomerOrders(): number {
        if (this.invoice.customer_orders) {
            return this.invoice.customer_orders
                .map((item) => item.sumOfInvoice || 0)
                .reduce((a, b) => Number(a) + Number(b), 0);
        }
        return 0;
    }

    // Посчитать сумму прочих услуг
    get getTotalAmountServicesList(): number {
        if (this.invoice.other_services) {
            return Object.assign(this.invoice.other_services
                .map((item) => item.service_amount || 0))
                .reduce((a, b) => Number(a) + Number(b), 0);
        }
        return 0;
    }

    get currentFunctionInCustomerOrder(): void | {} {
        if (this.modalDeletingCalendar) {
            return this.deletingPaymentCalendar!.serviceName === 'offerService'
                ? this.addAccountingOfferService : this.addAccountingOtherService;
        }
        return () => ({});
    }

    get isErrorOfNetting(): boolean {
        return this.nettingErrorText?.length > 0 && this.totalSumOfNetting -
            ALLOWED_DIFFERENCE_NETTING > this.getOfferAndServicesAmount;
    }

    get isClearServicesCustomerOrder(): boolean {
        return this.nettingErrorText?.length > 0
            && this.totalSumOfNetting === this.getOfferAndServicesAmount
            && this.invoice?.customer_orders?.length! > 0 && this.invoice.customer_orders!
                .some((item) => item.offer_services!.length > 0
                    || item.other_services!.length > 0);
    }

    @Getter('GET_EDITING_MODE') editingMode;
    @Getter('GET_UNSAVED_DATA_STATE') getUnsavedDataState;
    @Getter('GET_ABORT_TRANSITION') getAbortTransition;
    @Getter('GET_ROUTE_TO_TRANSITION') getRouteToTransition;
    @Mutation('TOGGLE_EDITING_MODE') toggleEditingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;
    @auth.Getter('GET_ROLE') role;
    @auth.Getter('GET_USER') user;
    @revenue.Mutation('SET_ADVERTISER_FIELDS') setAdvertiserFields;
    @revenue.Getter('GET_ADVERTISER_FIELDS') getAdvertiserFields;
    @invoice.Getter('GET_ADVERTISER') getAdvertiserInvoice;
    @invoice.Mutation('SET_ADVERTISER') setAdvertiserInvoice;

    invoice: IInvoice = {
        offer_services: [],
        other_services: [],
        netting: [],
        customer_orders: [],
        advertiser: {} as IInvoiceAdvertiser,
        currency: '',
        created_by: {} as IUser,
        period: null,
        is_draft: false,
        stat_task: {
            files: [] as IFiles[],
        } as IStatTasks,
    };

    // Данные для взаимозачета
    typeOfInvoice = 'new';
    showMainContent = true;
    // тип взаимозачёта
    nettingType: any = NETTING_TYPE.NONE;
    // общая сумма по взаимозачёту
    totalSumOfNetting = 0;
    nettingErrorText: string = '';
    panel: number[] = [];
    showUnsavedChangesModal = false;
    showCantBeSavedModal = false;
    initialDataRecieved = false;
    showSumError = false;
    discrepancy: string | number = '';
    isNewInvoice = false;
    invoiceCurrency: string[] = [];
    isEmptyProject = true;
    isEmptyContractor = true;
    isEmptyAccountingServices = true;
    isEmptyAccountingOfferServices: boolean | void = true;
    isEmptyAccountingOtherServices: boolean | void = true;
    isEmptyInvoiceOfferServices = true;
    isEmptyStatTaskFiles = true;
    isEmptySomeOtherServices = true;
    isPrepayment = false;
    // модалка для удаления бух счета
    deletingAccountIndex: null | number = null;
    // для сброса календаря выплат
    deletingPaymentCalendar: null | {
        item: any,
        index: number,
        serviceName: string,
    } = null;
    modalDeletingCalendar = false;
    // модалка для предупреждения, если добавляются одинаковые сервисы
    showWarning = false;
    saveStatTaskModal = false;
    sendInvoiceTo1c = false;
    loading = false;

    @Watch('invoice.customer_orders', { immediate: true, deep: true })
    findEmptySelect(): void {
        if (this.invoice.customer_orders!.length > 0) {
            this.isEmptyProject = this.invoice.customer_orders!
                .some((item) => !item.project);

            this.isEmptyContractor = this.invoice.customer_orders!
                .some((inv) => !inv!.contractor);
        }
    }

    @Watch('getAbortTransition')
    getAbortTransitionState(): void {
        if (this.getAbortTransition) {
            this.beforeRouteLeave();
        }
    }

    beforeRouteLeave(): void {
        if (this.getUnsavedDataState) {
            if (this.isSaveInvoiceButtonDisabled) {
                this.showCantBeSavedModal = true;
                return;
            }
            this.showUnsavedChangesModal = true;
        }
    }

    goToRouteWithoutSave(): void {
        this.showUnsavedChangesModal = false;
        this.setUnsavedDataState(false);
        this.$router.push({ name: this.getRouteToTransition });
    }

    async saveStatTaskFromModal(): Promise<void> {
        this.showUnsavedChangesModal = false;
        this.saveStatTaskModal = true;
        try {
            await this.save();
            this.setUnsavedDataState(false);
            await this.$router.push({ name: this.getRouteToTransition });
        } catch (err) {
            showServerError(err, 'Ошибка при сохранении');
        }
        this.setUnsavedDataState(false);
    }

    stayOnCurrentPage(): void {
        this.showCantBeSavedModal = false;
        this.setAbortTransition(false);
    }

    closeUnsavedChangesModal(): void {
        this.showUnsavedChangesModal = false;
        this.setAbortTransition(false);
    }

    @Provide() setUnsavedData(state: boolean): void {
        if (this.typeOfInvoice === 'old') {
            this.setUnsavedDataState(state);
        }
    }

    async created(): Promise<void> {
        const invoiceCreationUrl = '/revenue/invoice/creation';

        // Создание счета
        if (this.$route?.path === invoiceCreationUrl) {
            this.toggleEditingMode(true);

            // Если создаем счет со страницы рекла, `this.invoice.advertiser_id` берем из стора
            // получаем из store поля для создания счета
            this.invoice.advertiser = this.getAdvertiserInvoice;
            this.invoiceCurrency = this.invoice.advertiser!.currency || [];
        }

        // Создание счета с навбара
        if (this.$route?.path === '/revenue/invoice-creation') {
            this.toggleEditingMode(true);
        }

        // Просмотр и редактирование счета
        if (this.$route?.path !== invoiceCreationUrl && this.$route?.path !== '/revenue/invoice-creation') {
            this.typeOfInvoice = 'old';

            // если открываем созданный счет
            const invoiceId = Number(this.$route.params.id);

            try {
                this.invoice = await getOneInvoice(invoiceId);
                this.sendInvoiceTo1c = !this.invoice.is_draft;
                this.nettingType = this.invoice.netting![0]?.type || NETTING_TYPE.NONE;

                // Если счёт имеет финансовый статус `Оплачен`, то редактировать его нельзя.
                if (this.invoice.financial_status === 'paid' && !(this.user.roles.some((role) => role === USER_ROLES.SUPERVISOR
                    || role === USER_ROLES.ADMIN))) {
                    this.toggleEditingMode(false);
                }

                // Разрешаем редактировать счет в любом статусе, если в счёте есть только
                // один бухгалтерский счет и в нём только одна прочая услуга - "Предоплата" или у юзера роль Супервизор
                const customerOrders = this.invoice.customer_orders!;
                const accountingOtherServices = customerOrders[0]?.other_services!;
                this.isPrepayment = this.role === USER_ROLES.SUPERVISOR || (customerOrders!.length === 1
                    && accountingOtherServices
                    && accountingOtherServices.length === 1
                    && accountingOtherServices[0].service_name === 'Предоплата');

                if (this.invoice.stat_task === null) {
                    this.invoice.stat_task = {} as IStatTasks;
                } else {
                    this.invoice.stat_task!.files!.forEach((file) => {
                        file.offer_services!.forEach((service) => {
                            const offerServiceInInvoice = this.invoice.offer_services!.find((item) => item.offer_id === service.offer_id
                                    && item.goal_id === service.goal_id
                                    && !item.tempId);
                            if (offerServiceInInvoice) {
                                const randomId = getRandomId();
                                service.tempId = randomId;
                                offerServiceInInvoice.tempId = randomId;
                            }
                        });
                    });
                }
                this.invoiceCurrency = this.invoice.advertiser!.currency || [];
                this.setAdvertiserInvoice(this.invoice.advertiser!);

                // При просмотре созданного счета, бек присылает некоторые ненужные поля в advertiser'e
                // которые нужно удалить
                const fieldsForDeletion = ['status', 'tag', 'invoicing_dates', 'legal_entity'];

                fieldsForDeletion.forEach((fieldName) => delete this.invoice.advertiser![fieldName]);

                if (this.invoice.netting!.length) {
                    this.totalSumOfNetting = this.invoice!.netting!
                        .reduce((acc, item) => acc + (item.type === NETTING_TYPE.WM
                            ? item.paid_sum
                            : item!.amount!)!, 0);
                }
            } catch (err) {
                showServerError(err, 'Счет не найден');
            }

            // При просмотре счета делаем открытой по умолчанию вкладку `для бухгалтерии`
            this.panel.push(2);
            // И вкладку "взаимозачет", если там что-то есть
            if (this.invoice.netting?.length !== 0) {
                this.panel.push(1);
            }
        }
        this.setUnsavedDataState(false);
    }

    mounted(): void {
        eventBus.$on('customer-order-amount-changed', this.customerOrderAmountChanged);
        // для бухгалтерских счетов
        eventBus.$on('add-offer-service-accounting', this.checkForPaymentCalendars);
        eventBus.$on('add-other-service-accounting', this.checkForPaymentCalendars);
        eventBus.$on('calc-total-value-accounting', this.calcServiceTotalValueAccounting);
        eventBus.$on('delete-accounting-offer', this.deleteAccountingOffer);
        eventBus.$on('set-deleting-invoice-index', this.setDeletingAccountIndex);
    }

    beforeDestroy(): void {
        eventBus.$off('customer-order-amount-changed', this.customerOrderAmountChanged);
        // для бухгалтерских счетов
        eventBus.$off('add-offer-service-accounting', this.checkForPaymentCalendars);
        eventBus.$off('add-other-service-accounting', this.checkForPaymentCalendars);
        eventBus.$off('calc-total-value-accounting', this.calcServiceTotalValueAccounting);
        eventBus.$off('delete-accounting-offer', this.deleteAccountingOffer);
        eventBus.$off('set-deleting-invoice-index', this.setDeletingAccountIndex);

        this.setAdvertiserFields({});
        this.toggleEditingMode(true);
    }

    // Проверяем, была ли добавлена услуга
    checkIfTheServiceHasBeenAdded(addedServices: IOtherService[], serviceForAdded: IOtherService): boolean {
        return addedServices
            .some((service) => service.service_id === serviceForAdded.service_id);
    }

    changedSumOfInvoice(value: number, index: number): void {
        this.$set(this.invoice.customer_orders![index], 'sumOfInvoice', value);
    }

    setDeletingAccountIndex(index: number): void {
        this.deletingAccountIndex = index;
    }

    removeAccount(): void {
        // Проверка свой счет или нет только при редактировании,
        // при создании счета проверки не нужны
        if (this.invoice.id && (this.role === USER_ROLES.ACCOUNT_MANAGER) && this.invoice.advertiser) {
            if (this.user!.account_id === this.invoice.advertiser!.account_manager!.account_id) {
                    this.invoice.customer_orders!.splice(this.deletingAccountIndex!, 1);
            }
        } else {
            this.invoice.customer_orders!.splice(this.deletingAccountIndex!, 1);
        }

        this.setUnsavedData(true);
        this.deletingAccountIndex = null;
    }

    // Функция вызывается, когда нажимаем на `expansion-panel`,
    // при первом клике на неё, у нас должен сразу создаться заказ
    createCustomerOrder(): void {
        if (!this.isCustomerOrderWasCreated) {
            this.addCustomerOrder();
        }
    }

    // был ли создан заказ
    get isCustomerOrderWasCreated(): boolean {
        return this.invoice.customer_orders!.length > 0;
    }

    addCustomerOrder(): void {
        const newOrder: ICustomerOrders = {
            offer_services: [],
            other_services: [],
            payment_calendars: [],
            sumOfInvoice: 0,
        };
        this.isNewInvoice = true;
        // Если бухгалтерский счёт не был создан, то при создании в него прокидываем
        // услуги по офферам и прочие услуги из обычного счёта
        if (!this.isCustomerOrderWasCreated) {
            const newOffersList: any[] = JSON.parse(JSON.stringify(this.invoice.offer_services));
            const newServicesList = JSON.parse(JSON.stringify(this.invoice.other_services));
            newOrder.offer_services = [...newOffersList];
            newOrder.other_services = [...newServicesList];

            newOrder.offer_services.forEach((item) => {
                item.offer_direct = false;
                item.id = null;
                item.tempId = getRandomId();
            });
            newOrder.other_services.forEach((item) => {
                item.id = null;
                item.tempId = getRandomId();
            });
        }

        this.invoice.customer_orders!.push(newOrder);

        this.setUnsavedData(true);
    }

    checkForPaymentCalendars(item: IOtherService | IOfferService, index: number, eventName: string): void {
        const serviceName = eventName.includes('offer') ? 'offerService' : 'otherService';
        this.deletingPaymentCalendar = {
            item,
            index,
            serviceName,
        };
        if (this.invoice.customer_orders![index].payment_calendars!.length > 0) {
            this.modalDeletingCalendar = true;
        } else if (serviceName === 'offerService') {
            this.addAccountingOfferService();
        } else {
            this.addAccountingOtherService();
        }
    }

    addAccountingOfferService(): void {
        const { item, index } = this.deletingPaymentCalendar!;
        if (this.invoice.customer_orders![index].payment_calendars!.length) {
            this.invoice.customer_orders![index].payment_calendars! = [];
        }
        const newItem: IOfferService = { ...item };

        newItem.offer_direct = false;
        newItem.id = null;
        newItem.tempId = getRandomId();

        this.invoice.customer_orders![index].offer_services!.push(newItem);

        this.setUnsavedData(true);
        this.deletingPaymentCalendar = null;
        this.modalDeletingCalendar = false;
    }

    addAccountingOtherService(): void {
        const { item, index } = this.deletingPaymentCalendar!;
        if (this.invoice.customer_orders![index].payment_calendars!.length) {
            this.invoice.customer_orders![index].payment_calendars! = [];
        }
        const addedServices = this.invoice.customer_orders![index].other_services!;
        const isServiceAlreadyAdded = this.checkIfTheServiceHasBeenAdded(addedServices!, item);

        if (isServiceAlreadyAdded) {
            this.showWarning = true;
            return;
        }

        const newItem = { ...item };
        newItem.id = null;

        this.invoice.customer_orders![index].other_services!.push(newItem as IOtherService);

        this.setUnsavedData(true);
        this.deletingPaymentCalendar = null;
        this.modalDeletingCalendar = false;
    }

    isIOtherService(object: any): object is IOtherService {
        return 'service_id' in object;
    }
    // Посчитать `итого` оффера, добавленного в таблицу бухгалтерского счета
    calcServiceTotalValueAccounting(index: number, service: IOtherService | IOfferService): void {
        const key: string = this.isIOtherService(service) ? 'other_services' : 'offer_services';
        const newArrItems = this.invoice.customer_orders!.map((item) => item[key]);
        // Бухгалтерских счетов может быть несколько, тут мы получаем индекс счёта,
        // в котором произошли изменения
        const accountIndex = newArrItems!.map((item) => item!.indexOf(service)).findIndex((num) => num !== -1);
        const currentItem = this.invoice.customer_orders![accountIndex][key][index];
        if (this.isIOtherService(service)) {
            this.$set(currentItem, 'total',  Number(currentItem.service_amount));
        } else {
            currentItem.total = (currentItem.offer_rate && currentItem.offer_qty)
                ? Number(currentItem.offer_rate * currentItem.offer_qty)
                : 0;
        }
    }

    customerOrderAmountChanged(): void {
        this.setUnsavedData(true);
    }

    showNotification(): void {
        let notificationText = '';
        if (this.$route?.path !== '/revenue/invoice/creation' && this.$route?.path !== '/revenue/invoice-creation') {
            notificationText = 'Счет успешно отредактирован';
        } else {
            notificationText = 'Счет успешно создан';
        }
        new NotificationMessage(notificationText, 'success' );
    }

    async save(): Promise<void> {
        this.loading = true;
        // слушаем событие, чтобы добавить в счет файлы, которые "не до конца" добавлены в счет
        eventBus.$emit('save');
        // копируем счет чтоб в случае ошибки не слетали все данные
        const copyInvoice = { ...this.invoice };

        for (const item of copyInvoice.customer_orders!) {
            // С бэка приходит `legal_entity`, этот объект ипользуем для отрисовки компонентов,
            // а отправлять мы должны только строку `legal_id`
            // делаем это только для уже созданного счета
            if (copyInvoice.id && item.id && !item.legal_id) {
                item!.legal_id = item!.legal_entity ? item.legal_entity!.id as number : item!.legal_id as number;
            }

            // С бэка приходит массив `files` для каждого бух счета,
            // но отправлять мы должны только id файлов в массиве `fileIds`
            if (item!.files?.length) {
                item.file_ids = [];
                item!.files.forEach((file) => {
                    if (file.id) {
                        item!.file_ids!.push(file.id);
                    }
                });
                delete item.files;
            }
        }
        this.$set(copyInvoice, 'advertiser_id', copyInvoice.advertiser!.id);
        if (!copyInvoice!.hasOwnProperty('id')) {
            delete copyInvoice.advertiser;
        }
        if (copyInvoice.stat_task && Object.keys(copyInvoice.stat_task)!.length > 0) {
            const advertiserId = copyInvoice.stat_task.advertiser_id || copyInvoice.advertiser_id;
            this.$set(copyInvoice.stat_task, 'advertiser_id', advertiserId);
            copyInvoice.stat_task!.files!.forEach((file) => {
                file.offer_services!.forEach((service) => {
                    if (!file.id) {
                        delete service.id;
                    }
                });
            });
        }
        // если файлов нет отправляем пустой объект
        if (copyInvoice!.stat_task?.files && copyInvoice.stat_task!.files!.length <= 0) {
            copyInvoice.stat_task = {} as IStatTasks;
        }
        try {
            const result = await saveInvoice(copyInvoice, copyInvoice.id ? copyInvoice.id : undefined);
            this.showNotification();

            if (result) {
                this.sendInvoiceTo1c = !result.is_draft;

                if (this.saveStatTaskModal) {
                    this.typeOfInvoice = 'old';
                    this.saveStatTaskModal = false;
                } else if (this.typeOfInvoice === 'new') {
                    this.typeOfInvoice = 'old';
                    setTimeout(() => {
                        this.$router.push({ name: 'invoices' });
                    }, CHANGE_ROUTE_DEBOUNCE);
                } else {
                    if (!this.sendInvoiceTo1c) {
                        setTimeout(() => {
                            this.$router.push({ name: 'invoices' });
                        });
                    } else {
                        this.invoice = result;
                    }
                    this.toggleEditingMode(false);
                }
                this.setUnsavedData(false);
            }
        } catch (err) {
            showServerError(err, 'Счет не сохранен');
        }
        this.loading = false;
    }

    private deleteAccountingOffer(deletedItemIndex: number, name: string, accountIndex: number): void {
        const itemName = name === 'offersList' ? 'offer_services' : 'other_services';

        this.invoice.customer_orders![accountIndex][itemName]!.splice(deletedItemIndex, 1);

        if (this.invoice.customer_orders![accountIndex].payment_calendars!.length > 0) {
            this.invoice.customer_orders![accountIndex].payment_calendars! = [];
        }
    }
}
