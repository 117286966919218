import { GetterTree } from 'vuex';
import { IRootState } from '@/store/types';
import { ICatalogueState } from '@/store/modules/catalogue/types';

export const getters: GetterTree<ICatalogueState, IRootState> = {
    GET_CURRENCIES: (state) => state.currencies,
    GET_COUNTRIES: (state) => state.countries,
    GET_CURRENT_CURRENCY_ICON: (state) => (item) => state.currencies.find((currency) => currency.code === item)?.symbol || '',
    GET_CURRENT_COUNTRY: (state) => (item) => state.countries.find((country) => country.code === item) || {},
};
