
import { Vue, Component, Prop } from "vue-property-decorator";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import { TableHeader } from "@/types";
import { namespace } from "vuex-class";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import OfferChangeHistoryItem from "@/components/offers/offer/OfferChangeHistoryItem.vue";

const offers = namespace('offersModule');

@Component({
    components: { OfferChangeHistoryItem, TableWithFrontPaginationDesign, SvgArrow },
})

export default class OfferChangeHistory extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @offers.Action('GET_SELECTS_ITEMS') getSelectItems;
    @offers.State('selects_items') selectsItems!: { [key: string]: any[] | undefined };

    isLoading: boolean = true;

    headers: TableHeader[] = [
        { text: 'Дата/время обновления', value: 'changed_at', sortable: false },
        { text: 'Объект', value: 'translated_subject', sortable: false },
        { text: 'ID Объекта', value: 'subject_id', sortable: false, align: 'center' },
        { text: 'Какое поле изменено', value: 'translated_filed', sortable: false },
        { text: 'Старое значение', value: 'old_value', sortable: false, width: '350px' },
        { text: 'Новое значение', value: 'new_value', sortable: false, width: '350px' },
        { text: 'Кем изменено', value: 'changed_by', sortable: false, align: 'center' },
    ];

    uniqueData = [
        { slot: 'item.old_value' },
        { slot: 'item.new_value' },
        { slot: 'item.subject_id' },
    ];

    timeData = [
        {
            slot: 'item.changed_at',
            key: 'changed_at',
            isLine: false,
        },
    ];

    async created(): Promise<void> {
        await this.offer.getHistoryChanges().finally(() => this.isLoading = false);
    }
}

