
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { Route } from 'vue-router';
import Filters from '@/components/base/filters/Filters.vue';
import CardMaterial from '@/components/base/CardMaterial.vue';
import { exportConversionsReport, getConversionsReportList, postConversionsReport } from '@/api/revenue';
import { changeTopScrollWidth, showServerError } from '@/utils';
import SvgCompare from '@/assets/icons/compare.svg';
import Status from '@/components/base/Status.vue';
import Pagination from '@/components/base/table/Pagination.vue';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { IConversionChangesList } from '@/api/types/revenue';
import { IFilter } from '@/types';
import StatsCard from '@/components/base/StatsCard.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import { downloadFileToDisk } from '@/api';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        CardMaterial,
        StatsCard,
        TooltipButton,
        SvgCompare,
        Status,
        Pagination,
        Filters,
    },
})
export default class ConversionsReport extends Vue {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamsObject;

    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParams;

    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    report: IConversionChangesList[] = [];

    reportId: number | null = null;

    filters: IFilter[] = [
        new filterClass.Select({
            id: 'change_type',
            label: 'Что изменилось',
            select: '',
            items: [
                {
                    text: 'Цена',
                    value: 'price',
                },
                {
                    text: 'Статус',
                    value: 'status',
                },
            ],
        }),
        new filterClass.Input({
            id: 'offer_id',
            label: 'ID оффера',
            select: '',
        }),
        new filterClass.Input({
            id: 'transaction_id',
            label: 'Transaction ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'sub_id',
            label: 'Sub ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'lead_id',
            label: 'Lead ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'affiliate_id',
            label: 'Вебмастер',
            select: '',
        }),
        new filterClass.Datepicker({
            id: 'lead_date',
            label: 'Дата лида',
            select: [],
            pickerType: 'date',
            range: true,
        }),
        new filterClass.Datepicker({
            id: 'change_date',
            label: 'Дата изменения',
            select: [],
            pickerType: 'date',
            range: true,
        }),
        new filterClass.SelectWithHeaders({
            id: 'status',
            label: 'Статус',
            select: '',
            items: [
                {
                    header: 'Старый статус',
                },
                {
                    value: {
                        key: 'old_status',
                        value: 'approved',
                    },
                    text: 'approved',
                },
                {
                    value: {
                        key: 'old_status',
                        value: 'rejected',
                    },
                    text: 'rejected',
                },
                {
                    value: {
                        key: 'old_status',
                        value: 'pending',
                    },
                    text: 'pending',
                },
                {
                    divider: true,
                },
                {
                    header: 'Новый статус',
                },
                {
                    value: {
                        key: 'new_status',
                        value: 'approved',
                    },
                    text: 'approved',
                },
                {
                    value: {
                        key: 'new_status',
                        value: 'rejected',
                    },
                    text: 'rejected',
                },
                {
                    value: {
                        key: 'new_status',
                        value: 'pending',
                    },
                    text: 'pending',
                },
            ],
        }),
    ];

    headers = [
        {
            text: 'ID оффера',
            value: 'offer_id',
            divider: true,
        },
        {
            text: 'Transaction ID',
            value: 'transaction_id',
            divider: true,
        },
        {
            text: 'Sub ID',
            value: 'sub_id',
            divider: true,
        },
        {
            text: 'Lead ID',
            value: 'lead_id',
            divider: true,
        },
        {
            text: 'Вебмастер',
            value: 'affiliate',
            divider: true,
        },
        {
            text: 'Дата лида',
            value: 'created',
            divider: true,
        },
        {
            text: 'Дата обновления',
            value: 'updated',
            divider: true,
        },
        // Старый статус
        {
            text: 'Доход',
            value: 'old_values.revenue',
            divider: true,
        },
        {
            text: 'Выплата',
            value: 'old_values.payout',
            divider: true,
        },
        {
            text: 'Прибыль',
            value: 'old_values.profit',
            divider: true,
        },
        {
            text: 'Статус',
            value: 'old_values.status',
            divider: true,
        },
        // Новый статус
        {
            text: 'Доход',
            value: 'new_values.revenue',
            divider: true,
        },
        {
            text: 'Выплата',
            value: 'new_values.payout',
            divider: true,
        },
        {
            text: 'Прибыль',
            value: 'new_values.profit',
            divider: true,
        },
        {
            text: 'Статус',
            value: 'new_values.status',
            divider: true,
        },
    ];

    loading = {
        updateReport: false,
        export: false,
        list: false,
        download: false,
    };

    // для пагинации
    itemsPerPage = PAGINATION_PER_PAGE;

    pageCount = 0;

    pagination: { page: number } = {
        page: 1,
    };

    get title(): string {
        return `Отчет по изменениям цен и статусов ${this.$route.params.id}`;
    }

    created(): void {
        const routeQuery = this.$route.query;
        const statusesKeys = ['old_status', 'new_status'];

        this.filters.forEach((filter) => {
            if (filter.selectWithHeaders) {
                statusesKeys.forEach((key) => {
                    if (routeQuery.hasOwnProperty(key)) {
                        filter.select = {
                            value: {
                                value: routeQuery[key],
                                key,
                            },
                            text: routeQuery[key],
                        };
                    }
                });
            }
        });

        this.reportId = Number(this.$route.params.id);
        this.setDefaultFiltersParamsObject({});
        if (Object.keys(this.$route.query)?.length <= 0) {
            this.getConversionsReport();
        }
    }

    async updateReport(): Promise<void> {
        this.loading.updateReport = true;
        try {
            await postConversionsReport(this.reportId!);
            await this.getConversionsReport();
        } catch (err) {
            showServerError(err, 'Не удалось обновить отчет');
        }
        this.loading.updateReport = false;
    }

    async downloadReport(): Promise<void> {
        this.loading.download = true;
        try {
            const file = await exportConversionsReport(this.reportId!);
            downloadFileToDisk(file, { name: this.title, extension: 'xlsx' });
        } catch (err) {
            showServerError(err, 'Не удалось скачать отчет');
        }
        this.loading.download = false;
    }

    updated(): void {
        changeTopScrollWidth('#conversions-change');
    }

    clearFilters(): void {
        this.getConversionsReport();
    }

    // изменить число строк в таблице
    changeItems(offset: number, limit: number, isChangeLimit: boolean): void {
        if (offset === 0 || isChangeLimit) {
            this.pagination.page = 1;
        }
        if (limit) {
            this.itemsPerPage = limit;
        }
        this.itemsPerPage = limit;
    }

    async getConversionsReport(): Promise<void> {
        const filters = this.getFiltersParamsObject;
        this.loading.list = true;
        try {
            const { data } = await getConversionsReportList(this.reportId!, { ...filters });
            this.report = data;
        } catch (err) {
            showServerError(err);
        }
        this.loading.list = false;
    }

    goTo(router: Route & { name: string }, params: any): void {
        this.$router.push(router, params);
    }
}
