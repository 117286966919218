
import { Component, Vue } from 'vue-property-decorator';
import SvgOfferList from '@/assets/icons/nav-bar/offer-list.svg';
import { IColumnFilter } from '@/types';
import {
    getEmployeesOffersV2,

} from '@/api/offers';
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import CategoriesTable from "@/components/offers/CategoriesTable.vue";
import { CategoriesOfferColumns, CategoriesOfferFilters } from "@/services/TablePage/categoriesOffer";

@Component({
    components: {
        TablePageWrapper,
        SvgOfferList,
    },
})
export default class OfferCategories extends Vue {
    columns: IColumnFilter[] = new CategoriesOfferColumns().columns;
    filters: CategoriesOfferFilters = new CategoriesOfferFilters();

    categoriesTable = {
        component: CategoriesTable,
        apiMethod: getEmployeesOffersV2,
        paramsModifier: this.paramsModifier,
    };

    paramsModifier(params: any): any {
        return { ...params, ...{ has_unfilled_category: true } };
    }
}
