
import { Vue, Component, Prop } from "vue-property-decorator";
import { IContractor } from "@/api/types/revenue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import SvgEdit from "@/assets/icons/tooltip-edit.svg";
import SvgCheck from "@/assets/icons/check.svg";
import { getNumEnding } from "@/utils";

@Component({
    components: { SvgCheck, TrashBtn, SvgEdit },
})

export default class AddressChip extends Vue {
    @Prop({ required: true }) contractor!: IContractor;

    isShow: boolean = false;

    get getChipText(): string {
        const ends = ['адрес', 'адреса', 'адресов'];
        const quantity = this.contractor.addresses.length;
        return `+ ${ quantity } ${ getNumEnding(quantity, ends) }`;
    }
}

