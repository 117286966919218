import Vue from 'vue';

export default Vue.directive('table-scroll', {
    inserted(el, binding) {
        if (!binding.hasOwnProperty('value') || binding.value) {
            if (window.matchMedia('(min-width: 960px)').matches) {
                const dataTable = el.querySelector('.v-data-table__wrapper') as HTMLElement;
                const topScrollWrapper = el.querySelector('.top-scroll-wrapper') as HTMLElement;
                if (topScrollWrapper) {
                    topScrollWrapper.style.display = 'none';
                    topScrollWrapper.addEventListener('scroll', scrollTable);
                }
                dataTable.addEventListener('scroll', scrollTable);
            }
        }
    },
});

const scrollTable = ({ target }) => {
    const dataTable = target.closest('.v-data-table__wrapper');
    const topScrollWrapper = dataTable.querySelector('.top-scroll-wrapper');
    if (target === dataTable && !!topScrollWrapper) {
        topScrollWrapper!.scrollLeft = target.scrollLeft;
    } else {
        dataTable!.scrollLeft = target.scrollLeft;
    }
};
