
import { Component, Mixins } from 'vue-property-decorator';
import { Mutation, namespace } from 'vuex-class';
import SvgInvoices from '@/assets/icons/nav-bar/invoices.svg';
import TableMixin from '@/mixins/table';
import { IFilter, ITableStatus } from '@/types';
import {
    getFinmediaInvoices,
    getInvoiceOtherServices,
    deleteInvoice,
} from '@/api/revenue';
import { translateFinancialStatus } from '@/utils/translate';
import { getActiveUserSearch } from '@/api/user';
import { showServerError } from '@/utils';
import { IFinmediaData } from '@/api/types/revenue';
import FormModal from '@/components/base/FormModal.vue';
import { USER_ROLES } from '@/mappings/user-roles';
import { NotificationMessage } from "@/utils/notification";
import { filterClass } from '@/services/filters/filterClass';

const auth = namespace('authModule');

@Component({
    components: {
        FormModal,
        SvgInvoices,
    },
})
export default class FinmediaInvoices extends Mixins(TableMixin) {
    @Mutation('SET_TOTAL_ELEMENTS_DATA_TABLE') setTotalElementsDataTable;
    @auth.Getter('GET_ROLE') role;

    settings = {
        title: 'Все счета Финмедиа',
        loading: true,
    };
    apiMethod = getFinmediaInvoices;
    rowsPerPage = 25;
    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'ids',
            label: 'ID счета',
            select: [],
        }),
        new filterClass.Select({
            id: 'project',
            label: 'Проекты',
            select: '',
            noDataText: 'Нет выбранных проектов',
            itemValue: 'key',
            items: [],
            changeSelect: this.changeSelect,
            template: (item: any): string => item.value,
        }),
        new filterClass.Autocomplete({
            id: 'created_by',
            apiMethod: getActiveUserSearch,
            label: 'Создатель',
            itemValue: 'id',
            itemText: 'name',
            select: [],
            items: [],
            multiple: true,
        }),
        new filterClass.Input({
            id: 'contractor_name',
            label: 'Контрагент',
        }),
        new filterClass.Select({
            id: 'financial_status',
            label: 'Финансовый статус',
            items: ['new', 'sent', 'pending', 'partly_paid', 'paid'],
            select: '',
            translate: translateFinancialStatus,
        }),
        new filterClass.Datepicker({
            id: 'period',
            label: 'Период',
            pickerType: 'month',
            range: true,
            select: [],
        }),
    ];

    headers = [
        { text: 'ID', value: 'id', align: 'center' },
        { text: 'Проект', value: 'customer_orders[0].project' },
        { text: 'Создатель', value: 'customer_orders[0].created_by.full_name' },
        { text: 'Контрагент', value: 'customer_orders[0].contractor.name' },
        { text: 'Сумма', value: 'total', align: 'right' },
        { text: 'Статус', value: 'financial_status', align: 'center' },
        { text: 'Период', value: 'period' },
        { text: 'Действия', align: 'center', value: 'actions' },
    ];

    get filtersByRole(): IFilter[] {
        if (this.role !== USER_ROLES.ACCOUNT_MANAGER) {
            return this.filters;
        }
        return this.filters.filter((i) => i.id !== 'created_by');
    }

    // для модалки
    deletingInvoice: {id: number} = {
        id: 0,
    };

    actions = [
        {
            title: 'Удалить',
            color: 'danger',
            icon: 'delete',
            callback: (item: any): void => {
                this.deletingInvoice.id = item.id;
            },
        },
    ];

    // для отображения статусов в таблице
    statuses: ITableStatus[] = [
        {
            slot: 'item.financial_status',
            key: 'financial_status',
            translate: translateFinancialStatus,
        },
    ];

    numberFormatting = [
        {
            slot: 'item.total',
            key: 'total',
            format: 'toLocaleString("ru-RU")',
        },
    ];

    // для отображения роутов в таблице
    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'finmedia-invoice',
            text: 'id',
        },
    ];

    created(): void {
        this.getFinmediaInvoice();
        this.getOtherServices();
    }

    changeSelect({ type, items }: any): void {
        const currentFilter = this.filters.find((filter) => filter.id === type);
        if (currentFilter) {
            currentFilter.select = items;
        }
    }

    showNotification(id: number): void {
        const notificationText = `Счёт ${id} удалён`;
        new NotificationMessage(notificationText, 'info' );
    }

    async deleteOneInvoice(): Promise<void> {
        try {
            const index = this.dataTable.findIndex((item) => item.id === this.deletingInvoice.id);
            await deleteInvoice(this.deletingInvoice.id);
            this.showNotification(this.deletingInvoice.id);
            this.dataTable.splice(index, 1);
            this.deletingInvoice.id = 0;
        } catch (err) {
            showServerError(err, 'Счет не может быть удален');
        }
    }

    async getFinmediaInvoice(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Список рекламодателей не загружен');
        }
    }

    async getOtherServices(): Promise<void> {
        const currentFilter = this.filters.find((fl) => fl.id === 'project');
        try {
            const { data }= await getInvoiceOtherServices({ is_finmedia: true, is_active: true });
            for (const item in data as IFinmediaData) {
                if (data.hasOwnProperty(item)) {
                    currentFilter!.items!.push(data[item].name);
                }
            }
        } catch (err) {
            showServerError(err, 'Список проектов не загружен');
        }
    }
}
