
import { Component, Vue } from 'vue-property-decorator';
import PageLayout from "@/components/layout/PageLayout.vue";
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import EditedNotification from "@/components/notifications/EditedNotification.vue";

@Component({
    components: {
        EditedNotification,
        PageLayout,
    },
})
export default class CreateNotification extends Vue {
    notification = new EmployeeNotification();
}
