
import { Component, Vue } from 'vue-property-decorator';
import StatsCard from '@/components/base/StatsCard.vue';
import { ILegalEntityOrg, ILegalEntitySettingsOrg } from '@/api/types/revenue';
import { getOneLegalEntity, patchLegalEntity } from '@/api/revenue';
import { showServerError } from '@/utils';

@Component({
    components: {
        StatsCard,
    },
})

export default class LegalEntityClass extends Vue {
    legalEntity = {} as ILegalEntityOrg;

    settingsLegalEntity = {} as ILegalEntitySettingsOrg;

    // validate
    isShowError = false;

    shownMessage = '';

    get legalEntityID(): number {
        return Number(this.$route.params.id);
    }

    async updateLegalEntity(): Promise<void> {
        try {
            await patchLegalEntity(this.legalEntityID, this.settingsLegalEntity);
            this.showValidate(false, 'Настройки сохранены');
        } catch {
            this.showValidate(false, 'Настройки не сохранены');
        }
    }

    private async created() {
        const ID = this.legalEntityID;
        try {
            this.legalEntity = await getOneLegalEntity(ID);
            this.settingsLegalEntity = this.legalEntity.settings!;
        } catch (err) {
            showServerError(err, 'Юр. лицо не найдено');
        }
    }

    private showValidate(isError, message) {
        this.isShowError = isError;
        this.shownMessage = message;
        setTimeout(() => {
            this.isShowError = false;
            this.shownMessage = '';
        }, 550);
    }
}

