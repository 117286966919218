
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import { CONTEST_NAME } from "@/mappings/contests";
import { CAMPAIGN_NAME } from "@/mappings/campaigns";
import SvgFormulaOfSuccess from "@/assets/icons/formula-of-success.svg";
import SvgTimeOfTheFirst from "@/assets/icons/timeOfTheFirst.svg";

@Component({
    components: {
        SvgFormulaOfSuccess, SvgTimeOfTheFirst,
    },
})
export default class OfferContestList extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    getContest(name: string): { icon: string, color: string } | null {
        switch (name) {
        case CONTEST_NAME.SUCCESS_FORMULA:
            return  { icon: SvgFormulaOfSuccess, color: 'red' };
        case CONTEST_NAME.TIME_OF_THE_FIRST:
            return { icon: SvgTimeOfTheFirst, color: 'red' };
        default:
            return null;
        }
    }
    iconCampaign(name: string): string {
        switch (name) {
        case CAMPAIGN_NAME.KAZAKH_PEAKS:
            return '/img/campaigns/kazakhPeaks.jpeg';
        case CAMPAIGN_NAME.WORLD_WANDERERS:
            return '/img/campaigns/worldWanderers.jpeg';
        default:
            return '';
        }
    }
}
