export const getCookie = (name: string): string | undefined => {
    const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)",
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: any, options: any = {}): void => {

    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options,
        expires: undefined,
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (const optionKey in options) {
        updatedCookie += "; " + optionKey;
        const optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
};


export const deleteCookie = (name: string): void => {
    setCookie(name, "", {
        'max-age': -1,
    });
};
