import EmployeeConversions from "@/views/statistics/EmployeeConversions.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import ExtendedReportPage from "@/views/statistics/ExtendedReport.vue";
import { Route } from "vue-router";

export default  [
    {
        path: 'conversions-report',
        name: 'conversions-report',
        component: EmployeeConversions,
        props: (route: Route): any => ({ ...route.query }),
        meta: {
            title: 'Отчёт по конверсиям',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'extended-report',
        name: 'extended-report',
        component: ExtendedReportPage,
        props: (route: Route): any => ({ ...route.query }),
        meta: {
            title: 'Расширенный отчет',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'extended-report/advertiser',
        name: 'extended-report-advertiser',
        component: ExtendedReportPage,
        props: (route: Route): any => ({ ...route.query }),
        meta: {
            title: 'Отчет по рекламодателям',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
];
