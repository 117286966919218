
import { Component, Vue } from 'vue-property-decorator';
import { getConversionsReport, postConversionsReport } from '@/api/revenue';
import { showServerError } from '@/utils';
import CardMaterial from '@/components/base/CardMaterial.vue';
import { IConversionChanges } from '@/api/types/revenue';
import Loader from '@/components/base/Loader.vue';

@Component({
    components: {
        CardMaterial,
        Loader,
    },
})
export default class ConversionsChange extends Vue {
    report: IConversionChanges | {} = {};
    loadingReport = false;
    reportId: number | null = null;
    headers = [
        { text: 'Доход', value: 'revenue' },
        { text: 'Выплата', value: 'payout' },
        { text: 'Прибыль', value: 'profit' },
        { text: 'Конверсий принято', value: 'approved' },
        { text: 'Конверсий отклонено', value: 'rejected' },
        { text: '', value: '' },
    ];

    // проверяет есть ли разница в ценах или статусах в отчете по полю diff
    // если все значения в объекте diff === 0, то отчет пустой
    get showDetailsBtn(): boolean {
        const { diff } = (this.report as IConversionChanges).summary;
        if (diff) {
            const values = Object.values(diff);
            return values.some((value) => value !== 0);
        }
        return true;
    }

    get isReportRun(): boolean {
        /* если в tasks что-то есть, значит отчет уже запускался */
        return !!(this.report as IConversionChanges).tasks && !!(this.report as IConversionChanges).tasks!.length;
    }

    created(): void {
        this.reportId = Number(this.$route.params.id!);
        this.checkReport();
    }

    async checkReport(): Promise<void> {
        this.loadingReport = true;
        try {
            const { data } = await getConversionsReport(this.reportId!);
            this.report = data;
        } catch (err) {
            showServerError(err, 'Не удалось загрузить отчет');
        }
        this.loadingReport = false;
    }

    async updateReport(): Promise<void> {
        this.loadingReport = true;
        try {
            await postConversionsReport(this.reportId!);
            await this.checkReport();
        } catch (err) {
            showServerError(err, 'Не удалось обновить отчет');
        }
        this.loadingReport = false;
    }

    getValue(key: string, value: number): string | number {
        const keys = ['revenue', 'payout', 'profit'];
        if (keys.includes(key) && value) {
            return value.toLocaleString('ru-RU', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });
        }
        return value;
    }
}
