import { IColumnFilter, IFilter } from "@/types";
import { getActiveUserSearch } from "@/api/user";
import { translateLang, translateSurveyStatus } from "@/utils/translate";
import { CreatedFilters } from "@/services/filters/filters";
import { FilterRouteParser } from "@/services/filters/filterRouteParser";
import { filterClass } from '@/services/filters/filterClass';

export const surveysFilterColumns: IColumnFilter[] = [
    {
        name: '',
        items: [
            { title: 'Язык', value: 'lang' },
            { title: 'Автор', value: 'created_by' },
            { title: 'Статус', value: 'status' },
            { title: 'Дата начала', value: 'start_date' },
            { title: 'Дата окончания', value: 'end_date' },
            { title: 'Дата создания', value: 'created_at' },
            { title: 'Дата обновления', value: 'updated_at' },
        ],
    },
];

class SurveyRouteParser extends FilterRouteParser {
    constructor(props) {
        super(props);
    }

    getSupSelect(f: IFilter, tempItem: any): any {
        return  {
            [f.itemValue!]: tempItem[f.itemValue!],
            [f.itemText!]: f.template ? f.template(tempItem) : tempItem,
        };
    }
}

export class SurveysFilters extends CreatedFilters {
    constructor() {
        super();
        this.routeParser = SurveyRouteParser;
    }
    getFilters(): IFilter[] {
        return  [
            new filterClass.Autocomplete({
                id: 'created_by',
                apiMethod: getActiveUserSearch,
                label: 'Автор',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                template: (item: any): string => `${item.id} ${item.first_name} ${item.last_name}`,
            }),
            new filterClass.Multiselect({
                id: 'status',
                label: 'Статус',
                type: 'string',
                select: [],
                items: [{ id: 'active' }, { id: 'planned' }, { id: 'deleted' }, { id: 'completed' }],
                template: ({ id }): string => this.translateStatus(id, translateSurveyStatus),
                itemText: ({ id }): string => this.translateStatus(id, translateSurveyStatus),
                itemValue: 'id',
                noDataText: 'Нет статусов',
            }),
            new filterClass.Multiselect({
                id: 'language',
                label: 'Язык',
                type: 'string',
                select: [],
                items: [{ id: 'ru' }, { id: 'en' }],
                template: ({ id }): string => translateLang(id),
                itemText: ({ id }): string => translateLang(id),
                itemValue: 'id',
                noDataText: 'Нет статусов',
            }),
            new filterClass.Datepicker({
                id: 'date',
                label: 'Дата создания',
                pickerType: 'date',
                range: true,
                select: [],
                clearable: true,
            }),
        ];
    }
}
