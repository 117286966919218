import { ActionTree } from 'vuex';
import { IAuthState } from '@/store/modules/auth/types';
import { IRootState } from '@/store/types';
import { getLogout, postLogin } from '@/api/auth';
import store from '@/store';

export const actions: ActionTree<IAuthState, IRootState> = {
    AUTHORIZATION_LOGIN_METHOD: async (
        { commit, dispatch },
        data,
    ): Promise<void> => {
        commit('AUTH_REQUEST');
        try {
            const { is_2fa_enable, need2fa } = await postLogin(data);

            if (is_2fa_enable && !store.getters['authModule/GET_TWO_FACTORS_STATUS']) {
                commit('SET_TWO_FACTORS_STATUS', true);
            } else if (store.getters['authModule/GET_TWO_FACTORS_STATUS'] && need2fa) {
                throw new Error('Неверный код');
            } else {
                commit('AUTH_SUCCESS');
            }
        } catch (error: any) {
            await dispatch('AUTHORIZATION_LOGOUT_METHOD');
            commit('AUTH_ERROR');
            throw error;
        }
    },

    AUTHORIZATION_LOGOUT_METHOD: async ({ commit }): Promise<void> => {
        await getLogout();
        commit('AUTH_LOGOUT');
    },
};
