import { IParamsObject, IServerResponse } from "@/api/types";
import { axiosInstance } from "@/api/index";
import { API_CAMPAIGNS_URL, CASHBACK_ECOM } from "@/configs/global";
import { AxiosResponse } from "axios";
import { ICampaign, ICampaignEcom, ICampaignEcomAffiliate, ICampaignTop } from "@/api/types/campaigns";

/**
 *  Получение списка акций
 */
export async function getCampaigns(
    params?: IParamsObject,
): Promise<ICampaign[]> {
    return axiosInstance.request<IServerResponse<ICampaign[]>>({
        method: 'GET',
        url: `${API_CAMPAIGNS_URL}/employees/campaigns`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<ICampaign[]>>) => response.data.data);
}
/**
 *  Получение топ по акциям
 */
export async function getTopOfCampaign(
    id: number,
): Promise<ICampaignTop[]> {
    return axiosInstance.request<IServerResponse<ICampaignTop[]>>({
        method: 'GET',
        url: `${API_CAMPAIGNS_URL}/employees/campaigns/${id}/top`,
    })
        .then((response: AxiosResponse<IServerResponse<ICampaignTop[]>>) => response.data.data);
}

/**
 *  Получение топ по акциям
 */
export async function getCashbackEcom(
    params: IParamsObject,
): Promise<IServerResponse<ICampaignEcom[]>> {
    return axiosInstance.request<IServerResponse<ICampaignEcom[]>>({
        method: 'GET',
        url: `${API_CAMPAIGNS_URL}/employees/campaigns/${CASHBACK_ECOM}/cashbacks`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<ICampaignEcom[]>>) => response.data);
}

/**
 *  Получение топ по акциям
 */
export async function getCashbackEcomSingle(
    id: number,
): Promise<ICampaignEcomAffiliate> {
    return axiosInstance.request<IServerResponse<ICampaignEcomAffiliate>>({
        method: 'GET',
        url: `${API_CAMPAIGNS_URL}/employees/campaigns/${CASHBACK_ECOM}/cashbacks/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<ICampaignEcomAffiliate>>) => response.data.data);
}
