
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import { IEditableOfferFields, IItemsFromOffers } from "@/api/types/offers";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import {
    getEmployeesDepartments,
    getEmployeesOfferVerticals,
    getEmployeesPayouts,
    getEmployeesProducts,
    getEmployeesStats,
    getEmployeesTargetActions,
    getEmployeesTraffics,
} from "@/api/offers";
import { eventBus } from "@/eventbus";
import BaseCard from "@/components/base/BaseCard.vue";
import { namespace } from "vuex-class";

const offers = namespace('offersModule');

interface ICategory {
    id: string;
    items: IItemsFromOffers[];
    isMultiple?: boolean;
    key: number;
    api: () => Promise<any>
}

@Component({
    components: {
        BaseCard,
        BaseSelect,
    },
})
export default class OfferCardCategory extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @offers.Action('GET_SELECTS_ITEMS') getSelectItems;
    @offers.State('selects_items') selectsItems!: { [key: string]: any[] | undefined };

    isEdit: boolean = false;
    offerData: IEditableOfferFields = {} as IEditableOfferFields;
    categories: ICategory[] = [
        { id: 'verticals', items: [], key: 0, api: getEmployeesOfferVerticals },
        { id: 'departments', items: [], key: 0, api: getEmployeesDepartments },
        { id: 'traffics', items: [], key: 0, api: getEmployeesTraffics },
        { id: 'products', items: [], isMultiple: true, key: 0, api: getEmployeesProducts },
        { id: 'payouts', items: [], key: 0, api: getEmployeesPayouts },
        { id: 'stats', items: [], key: 0, api: getEmployeesStats },
        { id: 'target_actions', items: [], isMultiple: true, key: 0, api: getEmployeesTargetActions },
    ];

    editInfo(edit: boolean): void {
        if (edit) {
            this.getItems();
            this.setValues();
        } else {
            this.updateOffer();
        }
        this.isEdit = edit;
    }

    created(): void {
        this.setValues();
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    getValue(id: string): string {
        const value = this.offer[id] as IItemsFromOffers[];
        return value.length > 0 ? value.map(i => i.name).join(', ') : 'Не указано';
    }

    setValues(): void {
        this.categories.forEach(cat => {
            const values = this.offer[cat.id];
            this.offerData[cat.id] = values.length > 0
                ? (cat.isMultiple ? values.map((i: IItemsFromOffers ) => i.id) : values[0].id)
                : (cat.isMultiple ? [] : undefined);
        });
    }

    async getItems(): Promise<void> {
        try {
            this.isLoadingSync = true;
            if (this.categories.every(cat => cat.items.length > 0)) return;

            const requestParams = {};
            const setItems = () => {
                this.categories.forEach(cat => {
                    const items = this.selectsItems[cat.id] || [];
                    if (items.length === 0) {
                        requestParams[cat.id] = { property: cat.id, api: cat.api };
                    } else {
                        cat.items = items;
                    }
                });
            };
            setItems();
            if (Object.values(requestParams).length === 0) return;
            await this.getSelectItems(Object.values(requestParams));
            setItems();
        } finally {
            this.isLoadingSync = false;
        }
    }

    async updateOffer(): Promise<void> {
        this.isLoadingSync = true;
        const data: IEditableOfferFields = {} as IEditableOfferFields ;
        for (const key in this.offerData) {
            const value = this.offerData[key];
            if (value) data[key] = Array.isArray(value) ? value : [value];
        }
        const isUpdate = await this.offer.editOffer(data);
        if (isUpdate) eventBus.$emit("update-offer");
        this.isLoadingSync = false;
    }
}
