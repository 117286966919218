
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";

@Component({
    components: { BaseSelect },
})

export default class FormSelect extends Vue {
    @PropSync('product')  productSync!: any;
    @Prop({ required: true }) isEdit!: boolean;
    @Prop({ required: true }) param!: any;

    value: any = '';

    get items(): any[] {
        const objectArray = Object.entries(this.param.options);
        const options: { key:string, value: any }[] = [];
        objectArray.forEach(([key, value]) => {
            options.push({
                key,
                value,
            });
        });
        return options;
    }

    get isMultiple(): boolean {
        return this.param.type === 'multi_select';
    }

    mounted(): void {
        if (!this.productSync.params[this.param.param]) {
            this.value = this.isMultiple ? [] : '';
        } else {
            this.value = this.productSync.params[this.param.param];
        }
    }

    chipsValue(): string[] {
        return this.isMultiple ? this.productSync.params[this.param.param] : [this.productSync.params[this.param.param]];
    }

    updateSelect(i: any): void {
        this.value = i;
    }

    @Watch('value')
    updateProduct(): void {
        if (this.productSync.params[this.param.param] === undefined && !this.value.length) return;
        this.$set(this.productSync.params, this.param.param, this.value);
    }
}
