
import { Component, Mixins } from 'vue-property-decorator';
import TableMixin from '@/mixins/table';
import SvgAffiliatesList from '@/assets/icons/nav-bar/affiliates-list.svg';
import { getAffiliateSearch, getActiveUserSearch } from '@/api/user';
import { IFilter } from '@/types';
import { showServerError } from '@/utils';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { getFormatDate } from '@/utils/formatDates';
import { getAntifraudReports } from '@/api/antifraud';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        SvgAffiliatesList,
    },
})
export default class AntifraudDailyReport extends Mixins(TableMixin) {
    getFormatDate = getFormatDate;

    headers = [
        { text: 'ID партнёра', sortable: false, value: 'account_id', align: 'center' },
        { text: 'ID менеджера партнёра', sortable: false, value: 'manager_id' },
        { text: 'Дата проверки', sortable: false, value: 'period_from' },
        { text: 'Дата формирования отчёта', sortable: false, value: 'created_at' },
        { text: 'Наличие источникa', sortable: false, value: 'referer_score' },
        { text: 'User agent', sortable: false, value: 'user_agent_score' },
        { text: 'IP', sortable: false, value: 'ip_score' },
        { text: 'CR', sortable: false, value: 'cr_score' },
        { text: 'Уникальность', sortable: false, value: 'uniqueness_score' },
        { text: 'Офферы за заявку', sortable: false, value: 'target_application_score' },
        { text: 'Итоговый балл', sortable: false, value: 'score' },
    ];

    filters: IFilter[] = [
        new filterClass.Datepicker({
            id: 'period',
            label: 'Дата проверки',
            pickerType: 'date',
            select: [],
            range: true,
        }),
        new filterClass.Autocomplete({
            id: 'account_id',
            label: 'Партнер',
            apiMethod: getAffiliateSearch,
            itemValue: 'id',
            itemText: 'name',
            select: '',
            items: [],
            multiple: false,
            alsoSearchBy: 'email',
        }),
        new filterClass.Autocomplete({
            id: 'manager_id',
            apiMethod: getActiveUserSearch,
            label: 'Менеджер',
            itemValue: 'id',
            itemText: 'name',
            select: '',
            items: [],
            multiple: false,
        }),
    ];

    // для отображения роутов в таблице
    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'account_id',
            slot: 'item.account_id',
            routerName: 'affiliate',
            text: 'account_id',
        },
    ];

    uniqueData = [
        {
            slot: 'item.name',
            template: (item: any): string => (item
                ? `${item.account_id} ${item.name}`
                : ''),
        },
        {
            slot: 'item.manager',
            template: (item: any): string => (item.manager
                ? `${item.manager_id} ${item.manager_name}`
                : ''),
        },
        {
            slot: 'item.name',
            template: (item: any): string => (item
                ? `${item.account_id} ${item.name}`
                : ''),
        },
        {
            slot: 'item.period_from',
            template: (item: any): string => (item
                ? `${item.period_from.split(' ')[0]}`
                : ''),
        },
        {
            slot: 'item.created_at',
            template: (item: any): string => (item
                ? `${item.created_at.split(' ')[0]}`
                : ''),
        },
    ];

    paginationItemsPerPage = PAGINATION_PER_PAGE;
    apiMethod = getAntifraudReports;
    settings = { title: 'Отчёт по партнёрскому трафику', loading: true };

    mounted(): void {
        this.getReportsPagination();
    }

    async getReportsPagination(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Список партнеров не загружен');
        }
    }
}
