
import { Component, Mixins } from 'vue-property-decorator';
import TableMixin from '@/mixins/table';
import SvgAffiliatesList from '@/assets/icons/nav-bar/affiliates-list.svg';
import { IFilter } from '@/types';
import { showServerError } from '@/utils';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { getLegalEntitySearch } from '@/api/revenue';
import { getAgentReports } from '@/api/org-cabinets-backend';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        SvgAffiliatesList,
    },
})
export default class AgentReportList extends Mixins(TableMixin) {
    headers = [
        { text: 'ID', sortable: false, value: 'id', align: 'center' },
        { text: 'Код', sortable: false, value: 'code' },
        { text: 'Период', sortable: false, value: 'period' },
        { text: 'Организация', sortable: false, value: 'legal.name' },
        { text: 'Покупатель', sortable: false, value: 'customer_name' },
        { text: 'Сумма', sortable: false, value: 'amount' },
        { text: 'Валюта', sortable: false, value: 'currency' },
        { text: 'Создан', sortable: false, value: 'created_at', width: '180px' },
        { text: 'Обновлён', sortable: false, value: 'updated_at', width: '180px' },
    ];
    numberFormatting = [
        {
            slot: 'item.amount',
            key: 'amount',
        },
    ];
    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'ids',
            label: 'ID',
            select: [],
        }),
        new filterClass.ComboboxArray({
            id: 'codes',
            label: 'Номер счета в 1C',
            select: [],
        }),
        new filterClass.Datepicker({
            id: 'period',
            label: 'Период',
            pickerType: 'date',
            select: [],
            range: true,
        }),
        new filterClass.Autocomplete({
            id: 'leadgid_org_guids',
            label: 'Организация',
            apiMethod: getLegalEntitySearch,
            itemValue: 'external_id',
            itemText: 'name',
            select: [],
            multiple: true,
            apiParseUrl: false,
            type: 'string',
        }),
        new filterClass.Multiselect({
            id: 'currency',
            type: 'string',
            label: 'Выберите валюту',
            noDataText: 'Нет выбранной валюты',
            select: [],
            items: [],
            itemValue: 'code',
            isCurrencyCatalogue: true,
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Создан',
            pickerType: 'date',
            select: [],
            range: true,
        }),
    ];

    // для отображения роутов в таблице
    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'agent-report',
            text: 'id',
        },
    ];

    paginationItemsPerPage = PAGINATION_PER_PAGE;
    apiMethod = getAgentReports;
    settings = { title: 'Отчеты агентов', loading: true };

    mounted(): void {
        this.getAgentPagination();
    }

    async getAgentPagination(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Отчеты агентов не загружены');
        }
    }
}
