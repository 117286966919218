var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('filters',{attrs:{"title":"Фильтры","filters":_vm.filters,"limit":_vm.limit},on:{"clear":_vm.clearFilters,"submit":_vm.getDataTable}})],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('app-table',{attrs:{"title":_vm.settings.title,"items":_vm.dataTable,"headers":_vm.headers,"routes":_vm.routes,"total-element-data-table":_vm.totalElementDataTable,"loading":_vm.settings.loading,"disable-sort":false,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.updateSortBy],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.updateSortDesc],"get-page-data":_vm.getDataTable},scopedSlots:_vm._u([{key:"above-table",fn:function(){return [_c('v-btn',{staticClass:"mt-2 ml-auto",attrs:{"disabled":!_vm.canExport,"color":"success","loading":_vm.loadingDownload},on:{"click":function($event){_vm.choosingFields = true}}},[_vm._v(" Выгрузка ")])]},proxy:true}])},[_c('SvgApplication',{attrs:{"slot":"icon","width":"28","height":"28","fill":"#fff"},slot:"icon"})],1),_c('form-modal',{staticStyle:{"max-width":"800px!important"},attrs:{"custom-class":"selection-modal","show-modal":_vm.choosingFields,"need-hide-btn":false,"need-accept-btn":false,"persistent":false,"closable":true,"no-needs-title":true},on:{"close-modal":function($event){_vm.choosingFields = false}}},[_vm._t("default",function(){return [[_c('p',{staticClass:"base__title text-left",staticStyle:{"max-width":"30%"}},[_vm._v(" Поля: ")]),_c('p',{staticClass:"base__value title pa-2",staticStyle:{"width":"70%","overflow-y":"scroll","overflow-x":"hidden","max-height":"480px"}},[_c('multiselect',{attrs:{"multiselect-data":{
                                value: _vm.defaultFields,
                                itemValue: 'id',
                                items: _vm.fields,
                                label: 'Выберите поля',
                                noDataText: 'Ничего не найдено',
                                template: _vm.getTemplate,
                            }},on:{"change-multiselect-items":_vm.changeSelect}})],1),_c('v-btn',{staticClass:"ml-auto mt-2",attrs:{"color":"success","loading":_vm.loadingDownload},on:{"click":_vm.downloadApplications}},[_vm._v(" Выгрузить ")])]]})],2),_c('form-modal',{attrs:{"custom-class":"export-modal","show-modal":_vm.showModal,"need-hide-btn":false,"need-accept-btn":false,"persistent":false,"closable":true,"no-needs-title":true},on:{"close-modal":function($event){_vm.showModal = false}}},[_vm._t("default",function(){return [_vm._v(" Отчет отправлен на почту "+_vm._s(_vm.user.email)+" ")]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }