
import { Component, Prop, Vue, Watch, PropSync } from 'vue-property-decorator';
import omit from 'lodash-es/omit';
import reduce from 'lodash-es/reduce';
import getByKey from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { changeTopScrollWidth, waitForConditionReturnTrue } from '@/utils';
import { eventBus } from '@/eventbus';
import TableScroll from '@/directives/TableScroll';
import Status from '@/components/base/Status.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import MultiButton from '@/components/base/buttons/MultiButton.vue';
import CardMaterial from '@/components/base/CardMaterial.vue';
import AppTableRouter from '@/components/base/TableUI/AppTableRouter.vue';
import Pagination from '@/components/base/table/Pagination.vue';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import SvgScrollArrNext from '@/assets/icons/scroll-arr-next.svg';
import SvgScrollArrPrev from '@/assets/icons/scroll-arr-prev.svg';
import TdStatus from "@/components/base/TableUI/TdStatus.vue";
import TdRoute from "@/components/base/TableUI/TdRoute.vue";
import TdActions from "@/components/base/TableUI/TdActions.vue";
import TdNumber from "@/components/base/TableUI/TdNumber.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import SvgActions from "@/assets/icons/actions.svg";
import SvgPause from "@/assets/icons/pause.svg";
import SvgPlay from "@/assets/icons/play.svg";
import TdDropDown from "@/components/base/TableUI/TdDropDown.vue";
import { ITableStatus, TableHeader } from '@/types';
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import ThSort from "@/components/base/TableUI/ThSort.vue";
import QuestionMarkTooltip from "@/components/base/QuestionMarkTooltip.vue";
import TableFooterButtons from "@/components/base/TableUI/TableFooterButtons.vue";

interface ISection {
    section: string,
    description?: string,
    style?: string,
    colspan?: number,
}

@Component({
    components: {
        TableFooterButtons,
        QuestionMarkTooltip,
        ThSort,
        BaseSelect,
        TdDropDown,
        ViewDateTime,
        TdNumber,
        TdActions,
        TdRoute,
        TdStatus,
        Status,
        TooltipButton,
        MultiButton,
        CardMaterial,
        AppTableRouter,
        Pagination,
        SvgScrollArrNext,
        SvgScrollArrPrev,
        SvgActions,
        SvgPause,
        SvgPlay,
    },
    directives: {
        TableScroll,
    },
})

export default class AppTableDesign extends Vue {
    @Prop({ type: Boolean, default: false }) customHeader!: boolean;
    @Prop({ type: Boolean, default: false }) sectionHeader!: boolean;
    @Prop({ type: Boolean, default: true }) isShowSelect!: boolean;
    @Prop({ type: Boolean, default: false }) classId!: boolean;
    @Prop({ type: Boolean, default: true }) renderPagination!: boolean;
    @Prop({ type: Boolean, default: false }) rowSelect!: boolean;
    @Prop({ type: Boolean, default: true }) isIcon!: boolean;
    @Prop({ type: Boolean, default: true }) loading!: boolean;
    @Prop({ type: Boolean, default: false }) expand!: boolean;
    @Prop({ type: Boolean, default: true }) hideDefaultFooter!: boolean;
    @Prop({ type: Number, default: 0 }) totalElementDataTable!: number;
    @Prop({ type: String, default: 'primary' }) activeColor!: string;
    @Prop({ type: String, default: 'id' }) tableItemKey!: string;
    @Prop({ default: null }) addRowClass!: any;
    @Prop({ default: '' }) search!: string;
    @Prop({ type: Array, default: () => [] }) statuses!: ITableStatus[] | [];
    @Prop({ type: Array, default: () => [] }) routes!: [];
    @Prop({ type: Array, default: () => [] }) dropDownItems!: [];
    @Prop({ type: Array, required: true }) items!: [];
    @Prop({ type: Array, required: true }) headers!: any[];
    @Prop({ type: Array, default: () => [] }) booleanItems!: [];
    @Prop({ type: Array, default: () => [] }) actions!: [];
    @Prop({ type: Array, default: () => [] }) numberFormatting!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) uniqueData!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) timeData!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) images!: Array<{}>;
    @Prop({ type: Boolean, default: false }) needTopScroll!: boolean;
    @Prop({ type: [String, Number], default: '' }) id!: number | string;
    @Prop({ type: Boolean, default: true }) disableSort!: boolean;
    @Prop({ type: Boolean, default: false }) isNotFound!: boolean;
    @Prop({ type: String, default: 'Ничего не найдено' }) noDataText!: string;
    @Prop({ type: String, default: 'Ничего не найдено' }) noResultText!: string;
    @Prop({ type: Boolean, default: false }) truncateStatus!: boolean;
    @Prop({ type: Boolean, default: true }) isSetOffsetToUrl!: boolean;
    @Prop({ type: Boolean, default: true }) needFilterColumns!: boolean;
    @Prop({ type: Array, default: () => [] }) footerButtons!: Array<{}>;
    @Prop({ type: Boolean, default: false }) isHideDefaultFooterButtons!: boolean;
    @Prop() lockedRow!: any;
    @Prop({ type: Number, default: 0 }) itemsPerPage!: number;
    @Prop({ type: Boolean, default: true }) isShowScrollButtons!: boolean;

    @PropSync('options', { type: Object, default: () => ({}) }) optionsSync!: {};
    @PropSync('sortBy', { type: String, default: '' }) sortBySync!: string;
    @PropSync('sortDesc', { type: Boolean, default: false }) sortDescSync!: boolean;
    @PropSync('selected', { type: Array, default: () => ([]) }) selectedSync!: any[];

    getByKey = getByKey;

    filteredHeaders: TableHeader[] = [];
    isResendData: boolean = false;
    singleSelect = false;
    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };
    selectedLimit = PAGINATION_PER_PAGE || 25;
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    // для скрола
    scrollX = 0;
    refTable: any = null;

    closeTooltips(): void {
        const tooltips = this.$refs['mark-tooltip'] as QuestionMarkTooltip[];
        if( tooltips ){
            tooltips.forEach(i => i.closeTooltip());
        }
        if (!tooltips || tooltips.length === 0) return;
        tooltips.forEach(i => i.closeTooltip());
    }

    get isScrollBtns(): boolean {
        if (this.refTable) {
            // @ts-ignore
            const overWidth = this.$refs['tableBase'].$el.offsetWidth + 50 > document.body.clientWidth;
            return this.$props.items?.report && (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) && overWidth;
        } else {
            return true;
        }
    }

    get isAllItemsSelected(): boolean {
        return this.selectedSync?.length === this.items.length - this.disabledCount;
    }

    get isNotNullSelectedItems(): boolean {
        return this.selectedSync?.length > 0 && this.selectedSync.length < this.$props.items.length;
    }

    get isShowFooterButtons(): boolean {
        return this.selectedSync?.length > 0;
    }

    get getSections(): ISection[] {
        const result: ISection[] = [];
        this.filteredHeaders.forEach(item => {
            const head: ISection = { section: '' };
            head.section = item.section || '';
            item.sectionDescription && (head.description = item.sectionDescription);
            result.push(head);
        });
        const table = {};
        const res = result.filter(({ section }) => section === '' || !table[section] && (table[section] = 1));
        this.rowSelect && res.unshift({ section: '' });
        res.forEach(item => {
            const sectionLength = result.filter(i => i.section && i.section === item.section).length;
            if (item.section) {
                item.style = `width: ${sectionLength === 1 ? item.section.length + 150 + 'px' : 'auto'}`;
            }
            item.colspan = sectionLength || 1;
        });
        return res;
    }

    get disabledCount(): number {
        return this.items.reduce((acc: number, item: any) => {
            if (item.isSelectable === false) acc += 1;
            return acc;
        }, 0);
    }

    inputHeaderCheckbox(): void {
        if (this.isAllItemsSelected) {
            this.$emit('update:selected', []);
        } else {
            const selected = this.items.reduce((acc: any[], item: any) => {
                if (item.isSelectable !== false) {
                    acc.push(item);
                }
                return acc;
            }, []);
            this.$emit('update:selected', selected);
        }
    }

    scrollXNext(): void {
        this.scrollX += 250;
        // @ts-ignore
        this.$refs['vs'].scrollTo({ x: `${this.scrollX}` }, 500);
    }

    scrollXPrev(): void {
        if (this.scrollX)
            this.scrollX -= 250;
        // @ts-ignore
        this.$refs['vs'].scrollTo({ x: `${this.scrollX}` }, 500);
    }

    scrollToTop(): void {
        // @ts-ignore
        const table = this.$refs['tableBase'];
        const wrapper = document.getElementsByClassName('__panel')[0];
        // @ts-ignore
        this.$vuetify.goTo(table, { container: wrapper });
    }

    created(): void {
        // @ts-ignore
        this.refTable = this.$refs['tableBase'];
        eventBus.$on('go-to-first-page', this.goToFirstPage);
        if (this.$props.itemsPerPage) {
            this.selectedLimit = this.$props.itemsPerPage;
        }
    }

    goToFirstPage(): void {
        this.pagination.page = 1;
    }

    @Watch('items')
    goToPrevPage(): void {
        if (this.isNotFound) {
            this.pagination.page = 1;
        } else if (this.items?.length <= 0 && this.pagination.page > 1) {
            this.pagination.page = --this.pagination.page;
            this.isResendData = true;
        }
    }

    itemClass(item: any): string {
        const defaultClass = 'table-row';
        return this.addRowClass ? `${defaultClass} ${this.addRowClass(item)}` : defaultClass;
    }

    @Watch('pagination.page')
    cleanSelected(): void {
        this.selectedSync = [];
    }

    @Watch('selectedSync')
    resetFooterSelect(): void {
        if (this.selectedSync.length === 0) {
            this.$emit('reset-select');
        }
    }

    @Watch('$route.query')
    cleanSelectedByFilterChange(value: any, oldValue: any): void {
        const IGNORE_QUERY_PARAMS = ['columns'];

        const prev = omit(oldValue, IGNORE_QUERY_PARAMS);
        const current = omit(value, IGNORE_QUERY_PARAMS);

        const diff = reduce(
            current,
            (result, value, key) => isEqual(value, prev[key]) ? result : result.concat(value),
            [],
        );

        if (diff.length) {
            this.selectedSync = [];
        }
    }

    @Watch('filteredHeaders', { immediate: true })
    async setVerticalDividers(): Promise<void> {
        if (!this.sectionHeader) return;

        await this.$nextTick();
        if (this.loading) {
            await waitForConditionReturnTrue(() => !this.loading);
        }

        const dividerPositions = this.filteredHeaders.reduce((acc: number[], curr, i) => {
            if (
                curr.section && this.filteredHeaders[i + 1]
                && curr.section !== this.filteredHeaders[i + 1].section
            ) {
                if (this.rowSelect) {
                    i++;
                }
                return [...acc, i];
            } else {
                return [...acc];
            }
        }, []);
        const firstDiv = this.filteredHeaders.findIndex(i => i.section);
        dividerPositions.unshift(firstDiv);

        const el = this.$el;
        const oldDividers = el.querySelectorAll('.section-divider');
        oldDividers.forEach(i => i?.classList.remove('section-divider'));
        const tableRows = el.querySelectorAll('.table-row');
        const tableHeadRow = el!.querySelector('.v-data-table-header')!.firstChild;
        const setDivider = (row) => {
            dividerPositions.forEach((i) => {
                const rowSection = row.childNodes[i] as HTMLElement;
                rowSection?.classList.add('section-divider');
            });
        };
        setDivider(tableHeadRow);
        tableRows.forEach(row => setDivider(row));
    }

    updated(): void {
        if (!this.needFilterColumns) {
            this.filteredHeaders = this.headers;
        }
        if (!this.$props.needTopScroll) {
            return;
        }
        changeTopScrollWidth(`#${this.$props.id}`);
    }

    getPaginationData(offset: number, limit: number, isChangeLimit?: boolean): void {
        this.selectedLimit = limit;
        if (isChangeLimit) {
            this.$emit('get-page-data', 0, limit, isChangeLimit);
        } else {
            this.$emit('get-page-data', offset, limit);
        }
        this.isResendData = false;
        this.scrollToTop();
    }

    beforeDestroy(): void {
        eventBus.$off('go-to-first-page', this.goToFirstPage);
    }

    @Watch('$route.query.columns', { immediate: true })
    updateTableHeaders(): void {
        if (this.needFilterColumns && this.$route.query?.columns) {
            this.filteredHeaders = [];
            this.headers?.forEach((header: TableHeader) => {
                if (header.fixed) {
                    return this.filteredHeaders.push(header);
                }
                if (Array.isArray(this.$route.query.columns) && this.$route.query.columns.includes(header.value)) {
                    return this.filteredHeaders.push(header);
                } else if (header.value === this.$route.query.columns) {
                    return this.filteredHeaders.push(header);
                }
            });
        } else {
            this.filteredHeaders = this.headers;
        }
    }
}
