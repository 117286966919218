
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import ReportLayout from '@/components/layout/ReportLayout.vue';
import { downloadExcelFile, showServerError } from '@/utils';
import { postEmployeeExtendedReport } from '@/api/stats';
import { IExtendedReport } from '@/api/types/stats';
import { TableHeader } from '@/types';
import { getDifferenceInDays } from '@/utils/formatDates';
import { eventBus } from '@/eventbus';
import ReportTable from '@/components/statistics/extended-report/ReportTable.vue';
import StatisticReportMixin, { IExportFormat } from '@/mixins/statisticReport';
import { extendedBaseHeaders, columnsExtendedReport, ExtendedReportFilters } from "@/services/TablePage/extendedReport";
import ReportConstructor from "@/components/statistics/extended-report/ReportConstructor.vue";
import ReportFilters from "@/components/statistics/extended-report/ReportFilters.vue";

@Component({
    components: {
        ReportFilters,
        ReportConstructor,
        ReportLayout,
        ReportTable,
    },
})
export default class ExtendedReport extends StatisticReportMixin {
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Prop({ type: String, required: true }) readonly attachClass!: string;

    search = '';
    isByDateClick: 'conversion' | 'conversion-click' = 'conversion';
    headers: TableHeader[] = extendedBaseHeaders();
    filteredHeaders: TableHeader[] = [];
    filterClass = new ExtendedReportFilters();
    items = {} as IExtendedReport;
    pagination: { page: number } = {
        page: 1,
    };
    isNotFoundReport = false;
    currency = false;
    settings = {
        title: 'Расширенный отчет',
        loading: false,
        loadingExport: false,
        loadingUpdate: false,
        convertCurrency: '',
    };
    sortByFromUrl: string = '';

    get isShowLoader(): boolean {
        return Object.keys(this.items).length >= 0 && this.items.report && this.items.report.length > 0;
    }

    searchTable(search: string): void {
        this.search = search;
    }

    updateTableHeaders(): void {
        this.filteredHeaders = [];
        if (this.getFiltersParamsObject?.columns) {
            this.headers?.forEach((header) => {
                if (this.getFiltersParamsObject.columns.includes(header.value) && header.value !== 'dm_lead_date') {
                    return this.filteredHeaders.push(header);
                }
                if (this.getFiltersParamsObject?.group === header.value) {
                    this.filteredHeaders.push(header);
                }
            });
        }
    }

    // изменение фильтров и их отправка с датапикера над таблицей который
    changeFilters(e: any): void {
        this.changeFiltersFromLayout(this.filterClass.filters, e);
        if (e) {
            this.getReports();
        }
    }

    concatFiltersForSend(): any {
        const columns = [...this.getFiltersParamsObject.columns];

        const isNeedAffiliateId = columns.includes('dm_affiliate_name') && !columns.includes('dm_affiliate_id');
        if (isNeedAffiliateId) columns.push('dm_affiliate_id');
        const isNeedOfferId = columns.includes('dm_offer_name') && !columns.includes('dm_offer_id');
        if (isNeedOfferId) columns.push('dm_offer_id');

        const offerCols = ['dm_offer_goal_id', 'dm_offer_goal_name', 'dm_offer_name', 'dm_offer_id'];
        const needOfferStatus = offerCols.some(i => columns.includes(i));
        if (needOfferStatus) columns.push('dm_offer_status');

        if (this.getFiltersParamsObject.group && this.getFiltersParamsObject.group !== 'without_lead_date') {
            columns.splice(columns.findIndex((col) => col.includes('dm_lead_date')), 1);
            columns.push(this.getFiltersParamsObject.group);
        }
        const data: any = {
            columns,
            filters: this.filterClass.concatFiltersForSend(this.getFiltersParamsObject),
        };

        if (this.sortByFromUrl) {
            data.sorting = this.getSortBy()
                .map((column) => {
                    if (column === 'without_lead_date') return;
                    return {
                        column,
                        direction: this.$route.query?.sort_desc!.toString() === 'true' ? 'desc' : 'asc',
                    };
                })
                .filter(Boolean) as { column: string; direction: string }[];
        }

        return data;
    }

    created(): void {
        if (this.$route.name === 'extended-report-advertiser') this.settings.title = "Отчет по рекламодателям";

        if (!this.$route.query.sort_by || !this.$route.query.hasOwnProperty('sort_desc')) {
            const query = { ...this.$route.query };

            if (!this.$route.query.sort_by && this.getSortBy().length) {
                query.sort_by = this.getSortBy()[0];
            }
            if (!this.$route.query.hasOwnProperty('sort_desc')) {
                query.sort_desc = 'true';
            }

            this.$router.replace({
                name: this.$route.name || '',
                query,
            });
        }

        if (this.$route.query.hasOwnProperty('convert_currency')) {
            this.settings.convertCurrency = this.$route.query.convert_currency as string;
        }
        if (this.$route.query.date_click === 'conversion' || this.$route.query.date_click === 'conversion-click') {
            this.isByDateClick = this.$route.query.date_click;
        } else {
            this.updateQueryString({ date_click: this.isByDateClick });
        }
        this.filterClass.addSubsToFilters();
    }

    mounted(): void {
        if (Object.keys(this.$route.query).filter(item => !['columns', 'offset', 'group'].includes(item)).length > 0) {
            if (!!this.settings.convertCurrency) {
                this.updateQueryString({ convert_currency: this.settings.convertCurrency });
            }
            this.getReports();
        }
    }

    findColumnInGetFiltersParams(columns: string[]): boolean {
        if (!this.getFiltersParamsObject.columns) return false;
        return columns.some((n) => this.getFiltersParamsObject.columns?.indexOf(n) !== -1 || false);
    }

    // ограничения по выбору дат в фильтре Период
    getAllowedDate(): { start: Date | null, end: Date | null } {
        const filtersAndStartLimit = this.filterClass.getAllowedDate();
        const end = new Date();
        const checkedFilters = filtersAndStartLimit.filter(f => this.findColumnInGetFiltersParams(f.filters));
        if (checkedFilters.length > 1) {
            checkedFilters.forEach((f: any) => f.diff = getDifferenceInDays(f.start, end));
            const diffs = checkedFilters.map((f: any) => f.diff);
            const minDiff = Math.min.apply(null, diffs);
            const startDate = checkedFilters.find((f: any) => f.diff === minDiff)?.start;
            return { start: startDate || null, end };
        } else if (checkedFilters.length === 1) {
            const startDate = checkedFilters[0].start;
            return { start: startDate || null, end };
        } else {
            return { start: null, end };
        }
    }


    async exportReport(item: IExportFormat): Promise<any> {
        this.settings.loadingExport = true;
        const data = this.concatFiltersForSend();
        try {
            // @ts-ignore
            const { headers } = item;
            const responseType = item.responseType;

            headers.lang = 'ru';
            headers['usernames-as-string'] = 'true';
            headers['convert-currency'] = this.settings.convertCurrency || undefined;

            const report = await postEmployeeExtendedReport({ data, headers, responseType });

            let file = report;
            if (item.format === 'csv') {
                file = this.translateReportHeader(report, columnsExtendedReport);
            }

            downloadExcelFile(file, 'Расширенный отчет', item.format);
        } catch (err) {
            showServerError(err, 'Расширенный отчет не загружен');
        }
        this.settings.loadingExport = false;
    }

    changeCurConversion(): void {
        this.updateQueryString({ convert_currency: this.settings.convertCurrency });
        this.getReports();
    }

    changeDateClick(): void {
        this.updateQueryString({ date_click: this.isByDateClick });
        this.getReports();
    }

    async getReports(): Promise<void> {
        this.settings.loading = true;
        const data = this.concatFiltersForSend();

        const headers: {
            'conversions-source-type': 'conversion' | 'conversion-click',
            'convert-currency'?: string,
            'usernames-as-string': string
        } = {
            'conversions-source-type': this.isByDateClick,
            'convert-currency': this.settings.convertCurrency || undefined,
            'usernames-as-string': 'true',
        };
        const excluded = this.filterClass.getFilterExcluded();
        const operators = this.filterClass.getMetricOperatorsForQuery();
        this.updateQueryString({ ...{ excluded }, ...operators, ...{ date_click: this.isByDateClick } });
        this.updateTableHeaders();
        try {
            const report = await postEmployeeExtendedReport({ data, headers });
            this.items = report?.data || [];
            this.isNotFoundReport = this.items.report?.length <= 0;
        } catch (err) {
            showServerError(err, 'Расширенный отчет не загружен');
        } finally {
            this.settings.loading = false;
            this.settings.loadingUpdate = false;
        }
    }

    clearConstructorFilters(): void {
        this.clearFilters(this.filterClass.filters);
        this.updateQueryString({});
        this.clearTableData();
        const params = {
            columns: this.getFiltersParamObject.columns, date_to: this.getFiltersParamObject.date_to,
            date_from: this.getFiltersParamObject.date_from, group: this.getFiltersParamObject.group,
        };
        this.setFiltersParamsObject(params);
        this.filterClass.clearExcluded(this.getFiltersParamObject);
    }

    clearTableData(): void {
        this.items = {} as IExtendedReport;
        this.isNotFoundReport = false;
    }

    @Watch('getFiltersParamsObject', { deep: true, immediate: true })
    clearDate(): void {
        // сбрасываем до текущей даты в фильтре по дате при добавлении группировок гео, метрик или Sub1-Sub10
        const cols = ['dm_aff_sub1', 'dm_aff_sub2', 'dm_aff_sub3', 'dm_aff_sub4', 'dm_aff_sub5', 'dm_aff_sub6', 'dm_aff_sub7', 'dm_aff_sub8', 'dm_aff_sub9', 'dm_aff_sub10', 'dm_geo_country', 'dm_geo_city', 'dm_ua_device', 'dm_ua_os_name', 'dm_ua_browser_name', 'mt_clicks_total', 'mt_clicks_unique', 'mt_cr_u', 'mt_cr_t', 'mt_epc_u', 'mt_epc_t', 'mt_epc_adv', 'dm_buffer_account_used', 'dm_buffer_account_id', 'dm_buffer_account_name'];
        const isNotSupportedDate = this.getAllowedDate().start === null || new Date(this.getAllowedDate().start!) > new Date(this.filterClass.filters[0].select[0]);
        if (this.findColumnInGetFiltersParams(cols) && isNotSupportedDate) {
            this.filterClass.filters[0].select = this.filterClass.filters[0].defaultValue;
            this.filterClass.filters[0].allowedDates = this.getAllowedDate();
        } else {
            this.filterClass.filters[0].allowedDates = () => this.getAllowedDate();
            eventBus.$emit('check-allowed-dates', this.getAllowedDate());
        }
    }

    @Watch('$route.query.sort_by', { immediate: true })
    setSortByQuery(value: string | string[]): void {
        if (value === 'undefined') {
            this.sortByFromUrl = '';
        } else {
            this.sortByFromUrl = Array.isArray(value) ? value[0] : value;
        }
    }

    getSortBy(): string[] {
        const columns = this.getFiltersParamsObject?.columns;

        if (!Array.isArray(columns)) return [];

        // если есть такой столбец, то ставим сортировка из урла
        if (columns.includes(this.sortByFromUrl) && this.sortByFromUrl !== 'dm_lead_date') return [this.sortByFromUrl];

        // иначе ставим дефолтную сортировку по дате или кликам
        if (columns.includes('dm_lead_date')) {
            return this.getFiltersParamsObject.group && [this.getFiltersParamsObject.group] || ['dm_lead_date'];
        }
        if (columns.includes('mt_clicks_total')) {
            return ['mt_clicks_total'];
        }

        return [];
    }
}
