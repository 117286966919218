
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";

@Component({})

export default class FormTextField extends Vue {
    @PropSync('product') productSync!: any;
    @Prop({ required: true }) isEdit!: boolean;
    @Prop({ required: true }) param!: any;

    toFixedFields: string[] = ['psk_from', 'psk_to'];
    textValue: string | number = '';

    get isFireFox(): boolean {
        return navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;
    }

    changeInput(evt: KeyboardEvent): void {
        if (this.param.type !== 'number' || !this.isFireFox) return;
        const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace'];
        const keyPressed: string = evt.key;

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault();
        }
        this.productSync.params[this.param.param] = Number(this.productSync.params[this.param.param]);
    }

    updatedNumber(e: string | number): void {
        if (this.param.type === 'number') {
            const event: number = Number(e);

            if (this.toFixedFields.includes(this.param.param)) {
                this.productSync.params[this.param.param] = event.toFixed(3);
            } else {
                this.productSync.params[this.param.param] = event;
            }
        }
    }
}
