import { ICommission, IDateTime, IEmployeeMethod, ILimit } from "@/api/types/payout";
import {
    translateBoolean,
    translateDirections,
    translateLegalTypes,
    translatePayoutMethodStatus,
    translateVisibility,
} from "@/utils/translate";
import { addNewCommission, deleteCommission, patchEmployeeMethod, updateCommission } from "@/api/payout";
import { showNotification, showServerError } from "@/utils";
import isEqual from "lodash-es/isEqual";

export interface IAboutItem {
    id: string,
    type: string,
    name: string,
    value: any,
}

export interface IEditableAboutItem extends IAboutItem {
    isEditable?: boolean,
    isMultiple?: boolean,
    items?: { id: boolean | string, text: string }[]
}

export class EmployeeMethod implements IEmployeeMethod {
    id: string;
    name: {
        ru: string;
        en: string;
    };
    directions: string[];
    legal_types: string[];
    cabinet: boolean;
    currency: string;
    is_active: boolean;
    partner_fields_required: boolean;
    manager_fields_required: boolean;
    manager_fields_keys: string[];
    partner_fields_keys: string[];
    is_partner_visible: boolean;
    created_at: IDateTime | string;
    updated_at: IDateTime | string;
    monthly_limit: ILimit[];
    images: {
        light: string;
        dark: string;
    };
    commissions: any[];

    statusItems: { id: boolean, text: string }[] = [];
    partnerVisibilityItems: { id: boolean, text: string }[] = [];
    countryItems: { id: string, text: string }[] = [];
    legalTypesItems: { id: string, text: string }[] = [];
    cabinetItems: { id: boolean, text: string }[] = [];

    constructor(data: IEmployeeMethod) {
        this.id = data.id;
        this.name = data.name;
        this.directions = data.directions;
        this.legal_types = data.legal_types;
        this.cabinet = data.cabinet;
        this.currency = data.currency;
        this.is_active = data.is_active;
        this.partner_fields_required = data.partner_fields_required;
        this.manager_fields_required = data.manager_fields_required;
        this.manager_fields_keys = data.manager_fields_keys;
        this.partner_fields_keys = data.partner_fields_keys;
        this.is_partner_visible = data.is_partner_visible;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.monthly_limit = data.monthly_limit;
        this.images = data.images;
        this.commissions = data.commissions;
        this.setItems();
    }

    private setItems(): void {
        const configurations = [
            { items: [{ id: true }, { id: false }], translationFunction: translatePayoutMethodStatus, targetProperty: 'statusItems', isStatus: true },
            { items: [{ id: true }, { id: false }], translationFunction: translateVisibility, targetProperty: 'partnerVisibilityItems', isStatus: true },
            { items: [{ id: true }, { id: false }], translationFunction: translateBoolean, targetProperty: 'cabinetItems' },
            { items: [{ id: 'ru' }, { id: 'eng' }], translationFunction: translateDirections, targetProperty: 'countryItems' },
            { items: [{ id: 'individual' }, { id: 'legal' }], translationFunction: translateLegalTypes, targetProperty: 'legalTypesItems' },
        ];

        configurations.forEach(config => {
            this[config.targetProperty] = config.items.map(i => {
                return { id: i.id, text: config.isStatus ? config.translationFunction(i.id).text : config.translationFunction(i.id) };
            });
        });
    }

    get getAboutItems(): IAboutItem[] {
        return [
            { id: 'is_active', type: 'select', name: 'Статус', value: translatePayoutMethodStatus(this.is_active) },
            { id: 'is_partner_visible', type: 'select', name: 'Видимость партнёрам', value: translateVisibility(this.is_partner_visible) },
            { id: 'directions', type: 'select', name: 'Направления', value: this.directions.map(i => translateDirections(i)) },
            { id: 'legal_types', type: 'select', name: 'Тип юр.лица', value: this.legal_types.map(i => translateLegalTypes(i)) },
            { id: 'cabinet', type: 'select', name: 'Агентский кабинет', value: translateBoolean(this.cabinet) },
            { id: 'created_at', type: 'date', name: 'Дата создания', value: this.created_at },
            { id: 'updated_at', type: 'date', name: 'Дата обновления', value: this.updated_at },
        ];
    }

    get getAboutItemsForEdit(): IEditableAboutItem[] {
        const items = JSON.parse(JSON.stringify(this.getAboutItems)) as IEditableAboutItem[];
        return items.map(item => {
            if (item.type === 'date') return item;
            item = { ...item, isEditable: true };
            if (item.id === 'is_active') return { ...item, items: this.statusItems, value: this[item.id] };
            if (item.id === 'is_partner_visible') return { ...item, items: this.partnerVisibilityItems, value: this[item.id] };
            if (item.id === 'directions') return { ...item, items: this.countryItems, isMultiple: true, value: this[item.id] };
            if (item.id === 'legal_types') return { ...item, items: this.legalTypesItems, isMultiple: true, value: this[item.id] };
            if (item.id === 'cabinet') return { ...item, items: this.cabinetItems, value: this[item.id] };
            return item;
        });
    }


    //TODO cabinet --> is_cabinet
    async updateEmployeeMethod(data: any): Promise<void> {
        const dataToSend = {
            name_ru: this.name.ru,
            name_en: this.name.en,
        };
        let isRequiredFieldsNotBeChanged = true;
        let isOnlyRequiredFields = true;
        const requiredFields = ['directions', 'legal_types', 'is_active', 'is_partner_visible'];

        for (const key in data) {
            if (requiredFields.includes(key)) {
                dataToSend[key] = data[key];
                !isEqual(this[key], data[key]) && (isRequiredFieldsNotBeChanged = false);
            } else if (this[key] !== data[key]) {
                this[key] !== data[key] && (dataToSend[key] = data[key]);
                isOnlyRequiredFields = false;
            }
        }

        if (isOnlyRequiredFields && isRequiredFieldsNotBeChanged) return;
        if (!isOnlyRequiredFields && isRequiredFieldsNotBeChanged) {
            requiredFields.forEach(key => dataToSend[key] = this[key]);
        }

        try {
            await patchEmployeeMethod(this.id, dataToSend);
            showNotification('Метод оплаты успешно обновлён');
        } catch (err) {
            showServerError(err, 'Ошибка обновления метода оплаты');
        }
    }

    async crateOrUpdateCommission(data: ICommission[]): Promise<void> {
        try {
            for (const commission of data) {
                const originalCommission = this.commissions.find(c => c.id === commission.id);

                if (originalCommission && isEqual(originalCommission, commission)) continue;

                const data: ICommission = {
                    currency: commission.currency,
                    amount: commission.type !== 'percent' ? !!commission.amount ? commission.amount : 0 : 0,
                    percent: commission.type !== 'amount' ? !!commission.percent ? commission.percent : 0 : 0,
                    type: commission.type !== 'percent' ? commission.type !== 'amount' ? 'mixed' : 'amount' : 'percent',
                    lower_threshold: !!commission.lower_threshold ? Number(commission.lower_threshold) : 0,
                    upper_threshold: !!commission.upper_threshold ? Number(commission.upper_threshold) : 99999999.99,
                };

                if (commission.type === 'fixed') {
                    delete data.percent;
                } else if (commission.type === 'percent') {
                    delete data.amount;
                }
                if (!!commission.id) {
                    await updateCommission(this.id, commission.id, data);
                } else {
                    await addNewCommission(this.id, data);
                }
                showNotification('Комиссии успешно обновлены');
            }
        } catch (err) {
            showServerError(err, 'Ошибка при обновлении комиссий');
        }
    }

    async deleteCommission(id: number): Promise<void> {
        try {
            await deleteCommission(this.id, id);
            showNotification('Комиссия успешно удалена');
        } catch (err) {
            showServerError(err, 'Ошибка при удалении комиссии');
        }
    }

}
