import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';
import { IFilter } from '@/types';

export class SelectWithHeadersFilter extends BaseFilter {
    selectWithHeaders? = true;

    constructor(config: ConstructorType<SelectWithHeadersFilter, never>) {
        super(config);
        this.select = config.select ?? '';
        this.items = config.items;
    }

    getValueForSubmit(filter: IFilter = this): Record<string, any> {
        return {
            [filter.select.value?.key]: filter.select.value?.value,
        };
    }
}
