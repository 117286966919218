import { ActionTree } from 'vuex';
import { IRootState } from '@/store/types';
import { ICatalogueState } from '@/store/modules/catalogue/types';
import { getCountries, getCurrencies } from "@/api/catalogue";
import { showServerError } from "@/utils";

export const actions: ActionTree<ICatalogueState, IRootState> = {
    GET_DICTIONARY: async (
        { commit },
    ): Promise<void> => {
        try {
            const [currencies, countries] = await Promise.all([getCurrencies(), getCountries()]);
            commit('SET_COUNTRIES', countries);
            commit('SET_CURRENCIES', currencies);
        } catch (err) {
            showServerError(err, 'Словари не загружены');
        }
    },
};
