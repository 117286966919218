
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Editor from '@crm-ui/ckeditor';
import { IEditableOfferFields } from "@/api/types/offers";
import { eventBus } from "@/eventbus";
import BaseCard from "@/components/base/BaseCard.vue";

import { delay } from "@/utils";

@Component({
    components: {
        BaseCard,
        ckeditor: CKEditor.component,
    },
})
export default class OfferCardDescription extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    isEdit: boolean = false;
    description: string = '';
    editor = Editor;
    descHeight: number = 0;

    get config(): any {
        return {
            language: 'ru',
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'fontColor',
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'link',
                    'horizontalLine',
                ],
            },
            fontColor: {
                colorPicker: {
                    // Use 'hex' format for output instead of 'hsl'.
                    format: 'hex',
                },
                colors: [
                    {
                        color: '#0DA800',
                        label: 'Green',
                    },
                    {
                        color: '#F51E44',
                        label: 'Red',
                    },
                ],
                documentColors: 0,
            },
            allowedContent: true,
            forcePasteAsPlainText: true,
        };
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.description = this.offer.description;
        } else {
            this.updateOffer();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    mounted(): void {
        this.calcDescHeight();
    }

    onEditorReady( editor: any ): void  {
        editor.editing.view.document.on('clipboardInput', (evt, data) => {
            data.content = editor.data.htmlProcessor.toView(data.dataTransfer.getData('text/plain'));
        }, { priority: 'high' } );

    }

    async calcDescHeight(): Promise<void> {
        await delay(1000);
        const leftCol = document.querySelector('.offer-main__first-column');
        const rightCol = document.querySelector('.offer-main__second-column');
        const cards = rightCol?.querySelectorAll('.base-card');
        let height = leftCol?.clientHeight!;
        cards?.forEach((card) => {
            if (!card.classList.contains('offer-card-description')) {
                height = height - card.clientHeight;
            }
        });
        this.descHeight = height - 170;
    }

    async updateOffer(): Promise<void> {
        const data: IEditableOfferFields = { description: this.description } as IEditableOfferFields;
        const isUpdate = await this.offer.editOffer(data);
        if (isUpdate) eventBus.$emit("update-offer");
    }
}
