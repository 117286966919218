
import { Mixins, Component } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { getCurrentDate } from '@/utils/formatDates';
import { IApplicationConversion } from '@/api/types/uapi';
import FormModal from '@/components/base/FormModal.vue';
import { getConversionsCsv, getConversionsList } from '@/api/uapi';
import Filters from '@/components/base/filters/Filters.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { showServerError } from '@/utils';
import { eventBus } from '@/eventbus';
import { getOffersById } from '@/api/offers';
import { GetInfo } from '@/utils/getInfo';
import SortTableMixin from '@/mixins/sortTableMixin';
import { IFilter, ITableStatus } from '@/types';
import { filterClass } from '@/services/filters/filterClass';
import { translateUAPIConversionsStatus } from "@/utils/translate";

// Создаём константы для стора uapiModule
const uapi = namespace('uapiModule');
const auth = namespace('authModule');

@Component({
    components: {
        Filters,
        SvgApplication,
        FormModal,
    },
})
export default class Conversions extends Mixins(SortTableMixin) {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @uapi.Getter('GET_OFFERS_FILTERS') getOffersFilters;
    @auth.Getter('GET_USER') user;

    showModal = false;
    canExport = true;
    loadingDownload = false;
    currentDate = getCurrentDate('yyyy-MM-dd');
    offersNames: Array<{id: number, name: string}> = [];
    apiMethod = getConversionsList;
    dataTable: IApplicationConversion[] = [];
    totalElementDataTable = 0;
    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'affiliate_id',
            label: 'ID партнера',
            select: [],
            supValue: '',
        }),
        new filterClass.ComboboxArray({
            id: 'offer_id',
            label: 'ID оффера',
            select: [],
            supValue: '',
        }),
        new filterClass.ComboboxArray({
            id: 'lead_id',
            label: 'ID лида рекламодателя',
            select: [],
            supValue: '',
        }),
        new filterClass.Select({
            id: 'status',
            label: 'Статус',
            select: '',
            items: ['accepted', 'rejected'],
            clearable: true,
            remove: true,
            translate: translateUAPIConversionsStatus,
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Период',
            pickerType: 'date',
            range: true,
            select: [this.currentDate],
            defaultValue: [this.currentDate],
        }),
    ];

    headers = [
        {
            text: 'ID партнера',
            value: 'affiliate_id',
            sortable: false,
        },
        {
            text: 'ID-лида рекламодатетя',
            value: 'lead_id',
            sortable: false,
        },
        {
            text: 'Оффер (ID)',
            value: 'offer_name',
            sortable: false,
        },
        {
            text: 'Статус',
            value: 'status',
            sortable: false,
        },
        {
            text: 'Ошибка',
            value: 'error',
            sortable: false,
        },
        {
            text: 'Дата создания',
            value: 'created_at',
        },
    ];

    uniqueData = [
        {
            slot: 'item.offer_name',
            template: (): void => undefined,
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateUAPIConversionsStatus,
        },
    ];

    settings = {
        title: 'Конверсии',
        loading: false,
    };

    created(): void {
        // используется для сортировки
        // нужен в sortTableMixins
        this.field = 'created_at';
        this.description = true;
        /* offerFilters - это фильтры, которые передаются, если мы попадаем
        * на эту страницу с отчета по офферам
        */
        const offerFilters = this.getOffersFilters;
        const defaultFilters = {};
        const activeFilters = {};
        this.filters.forEach((fl) => {
            if (fl.select) {
                if (fl.array && fl.select.length) {
                    defaultFilters[fl.id] = fl.select;
                    activeFilters[fl.id] = fl.select;
                } else if (fl.datepicker) {
                    defaultFilters[`${fl.id}_from`] = fl.select[0];
                    defaultFilters[`${fl.id}_to`] = fl.select[0];
                    activeFilters[`${fl.id}_from`] = fl.select[0];
                    activeFilters[`${fl.id}_to`] = fl.select[0];
                    if (offerFilters[`${fl.id}_from`] && offerFilters[`${fl.id}_to`]) {
                        if (offerFilters[`${fl.id}_from`] === offerFilters[`${fl.id}_to`]) {
                            fl.select = [offerFilters[`${fl.id}_from`]];
                        } else {
                            fl.select = [offerFilters[`${fl.id}_from`], offerFilters[`${fl.id}_to`]];
                        }
                        activeFilters[`${fl.id}_from`] = offerFilters[`${fl.id}_from`];
                        activeFilters[`${fl.id}_to`] = offerFilters[`${fl.id}_to`];
                    }
                } else {
                    defaultFilters[fl.id] = fl.select;
                    activeFilters[fl.id] = fl.select;
                }
            }

            if (offerFilters[fl.id]) {
                fl.select = offerFilters[fl.id];
                activeFilters[fl.id] = fl.select;
            }
        });

        const defaultParams = JSON.parse(JSON.stringify({ ...defaultFilters }));
        const activeParams = JSON.parse(JSON.stringify({ ...activeFilters }));
        this.setDefaultFiltersParamObject(defaultParams);
        this.setFiltersParamObject(activeParams);
    }

    getTemplate(item: IApplicationConversion): string | number {
        const offerName = this.offersNames!.find(({ id }) => id === item.offer_id);
        if (offerName) {
            return `${offerName.name} (${item.offer_id})`;
        }
        return item.offer_id;
    }

    async getDataTable(offset?: number, limit?: number, isChangeLimit?: boolean): Promise<void> {
        this.canExport = true;
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        this.settings.loading = true;
        const paramsObj = { ...this.getFiltersParamsObject, ...this.getSortFilters };
        try {
            const { meta, data } = await getConversionsList({ offset, limit, ...paramsObj });
            this.dataTable = data;
            this.offersNames = await new GetInfo(data, 'offer_id', getOffersById).getInfoByFields();
            this.totalElementDataTable = meta!.total || data.length;
        } catch (err) {
            showServerError(err);
        }
        this.settings.loading = false;
    }

    async downloadConversions(): Promise<void> {
        this.loadingDownload = true;
        try {
            await getConversionsCsv({ ...this.getFiltersParamsObject, ...this.getSortFilters });
            this.showModal = true;
        } catch (err) {
            showServerError(err, 'Не удалось получить выгрузку по конверсиям');
        } finally {
            this.canExport = false;
            this.loadingDownload = false;
        }
    }
    beforeDestroy(): void {
        this.setFiltersParamsObject({});
        this.setDefaultFiltersParamsObject({});
    }
}
