import { MutationTree } from 'vuex';
import { IAuthState } from '@/store/modules/auth/types';

export const mutations: MutationTree<IAuthState> = {
    AUTH_REQUEST(state) {
        state.status = 'loading';
    },
    AUTH_SUCCESS(state) {
        state.status = 'success';
    },
    AUTH_ERROR(state) {
        state.status = 'error';
    },
    AUTH_LOGOUT(state) {
        state.status = '';
    },
    SET_USER(state, user) {
        state.user = user;
    },
    SET_TWO_FACTORS_STATUS(state, payload) {
        state.is_2fa_enabled = payload;
    },
    SET_LOGIN_AS_PARTNER(state, payload) {
        state.is_login_as_partner = payload;
    },
};
