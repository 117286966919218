import { MutationTree } from 'vuex';

import { IInvoiceState } from '@/store/modules/invoice/types';

export const mutations: MutationTree<IInvoiceState> = {
    SET_ADVERTISER: (state, payload) => {
        state.advertiser = payload;
    },
    SET_PERIOD: (state, payload) => {
        state.period = payload;
    },
    SET_TIMER(state, payload) {
        state.timer.push(payload);
    },
    CLEAR_TIMER(state) {
        state.timer = [];
    },

};
