
import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";
import SvgRangeCalendar from "@/assets/icons/range-calendar.svg";
import { formatDateInputPicker, getWeek } from "@/utils/formatDates";

@Component({
    components: { SvgRangeCalendar },
})

export default class WeekPicker extends Vue {
    @PropSync('value', { required: true }) valueSync!: string[];
    @Prop({ default: '' }) readonly label!: string;
    @Prop({ default: true }) readonly isOutlined!: boolean;
    @Prop({ default: true }) readonly isNeedIcon!: boolean;
    @Prop({ default: false }) readonly isFullDates!: boolean;

    pickerValue: string[] = [];
    menu: boolean = false;
    daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

    get getValue(): string {
        return this.pickerValue.length > 0 ? this.getValueText : '';
    }

    get getValueText(): string {
        let formats = ['d', 'd MMMM yyyy'];
        if (this.isFullDates) {
            formats = ['d MMMM yyyy', 'd MMMM yyyy'];
        }
        return `${formatDateInputPicker(this.pickerValue[0], formats[0])} - ${formatDateInputPicker(this.pickerValue[1], formats[1])}`;
    }

    getDay(date: number): string {
        const i = new Date(date).getDay();
        return this.daysOfWeek[i];
    }

    input(dates: string[]): void {
        const day = dates[0];
        const week = getWeek(day).map(String);
        this.valueSync = this.pickerValue = week;
        this.menu = false;
        this.$emit('change');
    }

    created(): void {
        if (this.valueSync) this.updatePickerValue();
    }

    @Watch('valueSync')
    updatePickerValue(): void {
        this.pickerValue = this.valueSync;
    }
}

