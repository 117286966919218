
import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgCopy from '@/assets/icons/copy.svg';
import { showNotification } from "@/utils";

interface IHint {
    name: string;
    value: string
}

@Component({ components: { SvgCopy } })
export default class OfferLinksHint extends Vue {
    @Prop({ default: false }) isInline!: boolean;

    hints: IHint[] = [
        { name: 'ID партнёра', value: '{affiliate_id}' },
        { name: 'Transaction ID', value: '{transaction_id}' },
    ];

    async copyHint(hint: string): Promise<void> {
        try {
            await navigator.clipboard.writeText(hint);
            showNotification('Подсказка скопирована');
        } catch (err) {
            console.error(err);
        }
    }
}
