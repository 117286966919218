import { Module } from 'vuex';

import { actions } from '@/store/modules/administration/actions';
import { mutations } from '@/store/modules/administration/mutations';
import { getters } from '@/store/modules/administration/getters';

import { IRootState } from '@/store/types';

export const state: any = {
};

const namespaced = true;

export const administrationModule: Module<any, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
