
import { Component, Vue } from 'vue-property-decorator';
import TabsReport from "@/components/orgstats/TabsReport.vue";
import ReportContent from "@/components/orgstats/ReportContent.vue";
import {
    getOfferReport,
} from '@/api/org-cabinets-backend';

@Component({
    components: {
        ReportContent,
        TabsReport,
    },
})
export default class Offers1CInvoice extends Vue {
    getOfferReport = getOfferReport;
}
