
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgTrash from '@/assets/icons/trash.svg';
@Component({
    components: { SvgTrash },
})

export default class TrashBtn extends Vue {
    @Prop({ default: true }) readonly isIcon!: boolean;
    @Prop({ default: '' }) readonly title!: string;
    @Prop({ default: true }) readonly isBorderless!: boolean;
    @Prop({ default: false }) readonly isGrey!: boolean;
    @Prop({ default: false }) readonly isMobile!: boolean;
}
