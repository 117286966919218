import { GetterTree } from 'vuex';

import { IInvoiceState } from '@/store/modules/invoice/types';
import { IRootState } from '@/store/types';

export const getters: GetterTree<IInvoiceState, IRootState> = {
    GET_ADVERTISER: (state) => state.advertiser,
    GET_PERIOD: (state) => state.period,

    GET_TIMER(state) {
        return state.timer ? state.timer : [];
    },
};
