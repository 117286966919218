import { AxiosResponse, ResponseType } from 'axios';
import { axiosInstance } from '@/api/index';
import { API_STATS_URL } from '@/configs/global';
import { IServerResponse } from "@/api/types";
import { IExtendedReport } from "@/api/types/stats";

/**
 * Получение расширенного отчета для сотрудника
 */
export async function postEmployeeExtendedReport(
    {
        data,
        responseType = 'json',
        headers,
    }: {
        data: any
        responseType?: ResponseType
        headers?: {
            Accept?: string
            'conversions-source-type'?: 'conversion' | 'conversion-click'
            'convert-currency'?: string
            'usernames-as-string'?: string
        }
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<IExtendedReport>>({
        method: 'POST',
        headers: { ...headers },
        url: `${API_STATS_URL}/employees/stats`,
        data,
        responseType,
    })
        .then((response: AxiosResponse<IServerResponse<IExtendedReport>>) => response.data);
}

export async function filterByGeoOrOS(
    params: {
        type: string,
        search: string,
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<string[]>>({
        method: 'GET',
        url: `${API_STATS_URL}/employees/filters`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<string[]>>) => response.data);
}
