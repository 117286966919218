
import {
    Component, Vue, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { PAGINATION_PER_PAGE, PAGINATION_TOTAL_VISIBLE } from '@/configs/global';
import { eventBus } from "@/eventbus";
import { Getter } from "vuex-class";

@Component
export default class Pagination extends Vue {
    @Prop({ type: Number, default: 0 }) readonly totalElementDataTable!: number;
    @Prop({ type: Number, default: PAGINATION_PER_PAGE }) selectedLimit!: number;
    @Prop({ type: Number, default: PAGINATION_PER_PAGE }) limit!: number;
    @Prop({ type: Array, default: () => [PAGINATION_PER_PAGE, 50, 100] }) itemsPerPageSelect!: number[];
    @Prop({ type: Boolean, default: false }) isResendData!: boolean;
    @Prop({ type: Boolean, default: true }) isShowSelect!: boolean;
    @Prop({ type: Boolean, default: true }) isSetOffsetToUrl!: boolean;
    @PropSync('pagination', { type: Object }) paginationSync!: {page: number};

    @Getter('GET_UNSAVED_DATA_STATE') getUnsavedDataState;

    totalVisible = PAGINATION_TOTAL_VISIBLE;

    changePage(): void {
        if (this.getUnsavedDataState) {
            this.paginationSync.page = +this.$route.query.offset / this.selectedLimit + 1;
        } else {
            this.setOffsetUrl();
        }
        this.$emit('get-pagination-data', this.paginationOffset, this.selectedLimit);
    }

    setOffsetUrl(): void {
        if (!this.isSetOffsetToUrl) return;
        if (+this.$route.query.offset !== this.paginationOffset) {
            const query = { ...this.$route.query, ...{ offset: this.paginationOffset.toString() } };
            // @ts-ignore
            this.$router.replace({ name: this.$router.currentRoute.name, query });
        }
    }

    mounted(): void {
        eventBus.$on('go-to-first-page', this.setOffsetUrl);
        this.paginationSync.page = Math.ceil((+this.$route.query.offset || 0) + this.selectedLimit ) /  this.selectedLimit;
    }

    @Watch('isResendData')
    reSendDateTable(): void {
        if (this.isResendData) {
            this.$emit('get-pagination-data', this.paginationOffset, this.selectedLimit);
        }
    }

    get paginationOffset(): number {
        return (this.paginationSync.page * this.selectedLimit) - this.selectedLimit;
    }

    get paginationPages(): number {
        if (this.selectedLimit === null
            || this.$props.totalElementDataTable === null) {
            return 0;
        }
        return Math.ceil(this.$props.totalElementDataTable / this.selectedLimit);
    }

    // watch необходим, потому что в фильтрах обновляются урл параметры, и затирается страница
    @Watch('$route.query')
    updateUrl(value: any, oldValue: any): void {
        if (value.offset !== oldValue.offset) {
            this.setOffsetUrl();
        }
    }

    beforeDestroy(): void {
        eventBus.$off('go-to-first-page', this.setOffsetUrl);
    }

}
