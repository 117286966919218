
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import TagSelect from "@/components/base/TagSelect.vue";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import SvgRangeCalendar from "@/assets/icons/range-calendar.svg";
import { getFormatDate } from "@/utils/formatDates";
import { AFFILIATE_FIELD, CreateAffiliate, IAffiliateField } from "@/services/affiliates/CreateAffiliate";
import { createAffiliateTag, getAffiliateTags } from "@/api/user";
import MessengerSelect from "@/components/base/form/MessengerSelect.vue";
import MaskedTextField from "@/components/base/form/MaskedTextField.vue";

@Component({
    components: { MaskedTextField, MessengerSelect, SvgRangeCalendar, DateTimePickerNew, TagSelect, BaseAutocomplete },
})

export default class createAffiliateFields extends Vue {
    @Prop({ required: true }) options!: IAffiliateField[];
    @PropSync('affiliate', { required: true }) affiliateSync!: CreateAffiliate;

    AFFILIATE_FIELD = AFFILIATE_FIELD;
    getAffiliateTags = getAffiliateTags;
    createAffiliateTag = createAffiliateTag;

    isShowPassword: boolean = false;

    changeValue(property: string, value: any): void {
        this.affiliateSync[property] = value;
    }

    setDate(property: string, value: string): void {
        if (value === '') delete this.affiliateSync[property];
        this.affiliateSync[property] = getFormatDate(value, 'yyyy-MM-dd');
    }
}

