
import { Component, Vue, Prop } from 'vue-property-decorator';
import PaginationRight from '@/components/base/table/PaginationRight.vue';
import Status from '@/components/base/Status.vue';
import AppTableRouter from '@/components/base/TableUI/AppTableRouter.vue';
import { PAGINATION_RIGHT_50_PER_PAGE } from '@/configs/global';
import CardMaterial from '@/components/base/CardMaterial.vue';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import TdStatus from "@/components/base/TableUI/TdStatus.vue";

@Component({
    components: {
        PaginationRight,
        CardMaterial,
        Status,
        AppTableRouter,
        TdStatus,
    },
})

export default class TableWithFrontPagination extends Vue {
    @Prop({ type: Array, default: () => [] }) statuses!: [];
    @Prop({ type: Boolean, default: false }) customHeader!: boolean;
    @Prop({ type: Array, required: true }) items!: [];
    @Prop({ type: Array, required: true }) headers!: [];
    @Prop({ type: Array, default: () => [] }) uniqueData!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) routes!: [];
    @Prop({ type: Boolean, default: true }) loading!: boolean;
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String, default: '' }) sortBy!: string;
    @Prop({ type: Boolean, default: true }) isIcon!: boolean;
    @Prop({ type: Boolean, default: true }) disableSort!: boolean;
    @Prop({ type: String, default: 'primary' }) activeColor!: string;
    @Prop({ type: Array, default: () => [] }) numberFormatting!: Array<{}>;
    @Prop({ default: null }) itemsPerPageSelect!: Array<{}> | null;
    @Prop({ type: String }) icon!: string;
    @Prop({ type: Number, default:  PAGINATION_RIGHT_50_PER_PAGE }) itemsPerPage!: number;

    PAGINATION_RIGHT_PER_PAGE = PAGINATION_RIGHT_50_PER_PAGE;
    // for pagination
    pageCount = 0;
    limit = PAGINATION_RIGHT_50_PER_PAGE;
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;

    get perPageSelect(): any[] {
        return this.itemsPerPageSelect ? this.itemsPerPageSelect : [
            {
                value: PAGINATION_RIGHT_50_PER_PAGE,
                text: PAGINATION_RIGHT_50_PER_PAGE.toString(),
            },
            {
                value: 100,
                text: '100',
            },
        ];
    }

    mounted(): void {
        this.limit =  this.itemsPerPage;
    }

    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };

    // изменить число строк в таблице
    changeItems(evt: string): void {
        this.limit = parseInt(evt, 10);
    }

    handleClick(evt: any): void {
        this.$emit('row-click', evt);
    }
}
