
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IOffersDelay } from '@/api/types/uapi';
import { IOffer } from '@/api/types/offers';
import { getAffiliateSearch } from '@/api/user';
import { IAffiliateItem } from '@/api/types/user';
import BaseAutocomplete from '@/components/base/form/BaseAutocomplete.vue';
import { IParamsObject, IServerResponse } from '@/api/types';
import { getOffersList } from "@/api/uapi";

interface IAutocompleteField {
    id: string;
    label: string;
    placeholder: string;
    items: any[],
    apiMethod: (params?: IParamsObject) => Promise<IServerResponse<object>>,
    key?: string;
    itemValue: string;
    search?: string;
    template: (item: any) => string,
}

@Component({
    components: {
        BaseAutocomplete,
    },
})
export default class DelayModalContent extends Vue {
    @Prop({ type: Object, required: true }) delay!: IOffersDelay;

    searchTimerId = 0;

    // autocomplete fields for creating new delay
    autocompleteFields: IAutocompleteField[] = [
        {
            id: 'offer_id',
            label: 'ID или название оффера',
            placeholder: 'Выберите ID или название оффера',
            items: [],
            apiMethod: getOffersList,
            itemValue: 'id',
            template: (item: IOffer): string => `${item.id} ${item.offer_name}`,
        },
        {
            id: 'affiliate_id',
            label: 'ID партнёра',
            placeholder: 'Выберите ID партнёра',
            items: [],
            apiMethod: getAffiliateSearch,
            key: 'query',
            itemValue: 'id',
            template: (item: IAffiliateItem): string => `${item.id} ${item.first_name} ${item.last_name}`,
        },
    ];

    created(): void {
        this.autocompleteFields.forEach((field) => {
            field.search = '';
        });
    }
}
