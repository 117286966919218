
import { Component, Provide, Vue } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';
import StatsCard from '@/components/base/StatsCard.vue';
import { getMasterAccountFinanceInvoice } from '@/api/org-cabinets-backend';
import { IFinanceInvoiceSingle } from '@/api/types/org-cabinets-backend';
import Status from '@/components/base/Status.vue';
import Header from '@/components/orgstats/masterAccount/Header.vue';
import { translateFinanceInvoicesStatus } from '@/utils/translate';
import AccountTable from '@/components/orgstats/masterAccount/AccountTable.vue';
import LegalEntity from '@/components/orgstats/masterAccount/LegalEntity.vue';
import FinanceInvoiceHeader from '@/components/orgstats/masterAccount/FinanceInvoiceHeader.vue';
import { showServerError } from '@/utils';

@Component({
    components: {
        FinanceInvoiceHeader,
        LegalEntity,
        AccountTable,
        StatsCard,
        Status,
        Header,
    },
})
export default class MasterAccountFinanceInvoice extends Vue {
    translateFinanceInvoicesStatus = translateFinanceInvoicesStatus;
    invoice = {} as IFinanceInvoiceSingle;
    panel = [0];
    headers = [
        {
            text: 'ID',
            value: 'leadgid_invoice_id',
            align: 'center',
            class: 'font-weight-regular',
        },
        {
            text: 'Вебмастер',
            value: 'affiliate',
            class: 'font-weight-regular',
        },
        {
            text: 'Сумма',
            value: 'amount',
            class: 'font-weight-regular',
            align: 'right',
        },
        {
            text: 'Валюта',
            value: 'currency',
            class: 'font-weight-regular',
        },
        {
            text: 'Дата выставления',
            value: 'period',
            class: 'font-weight-regular',
        },
    ];

    get invoiceTitle(): string {
        return `Приходная накладная от ${(format(parseISO(this.invoice.period), 'dd.MM.yyyy'))}`;
    }

    get isEmptyInvoice(): boolean {
        return Object.keys(this.invoice).length <= 0;
    }

    @Provide() get masterAccountID(): string {
        return this.$route.params?.id;
    }

    get masterAccountInvoiceID(): string {
        return this.$route.params?.invoice_id;
    }

    async getFinanceInvoice(): Promise<void> {
        try {
            const { data } = await getMasterAccountFinanceInvoice(this.masterAccountInvoiceID);
            this.invoice = data;
        } catch (err) {
            showServerError(err, 'Не удалось получить список счетов 1С');
        }
    }

    created(): void {
        this.getFinanceInvoice();
    }
}
