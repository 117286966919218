var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"other-services-filters"},_vm._l((_vm.filters.filters),function(filter){return _c('div',{key:filter.id,staticClass:"other-services-filters__item"},[(filter.id === 'is_active')?_c('base-select',{attrs:{"items":_vm.getStatusItems(filter),"value":filter.select,"is-clearable":true,"is-outlined":true,"placeholder":filter.label},on:{"update:value":function($event){return _vm.$set(filter, "select", $event)},"select":function($event){return _vm.selectFilter(filter, $event)}}}):_vm._e(),(filter.id === 'created_by_account_id')?_c('base-autocomplete',{attrs:{"data":{
                apiMethod: filter.apiMethod,
                itemValue: filter.itemValue,
                template: filter.template,
                label: filter.label,
                clearable: true
            },"default-search":filter.select,"possible-items":filter.items,"is-outlined":true},on:{"change":function($event){return _vm.selectFilter(filter, $event)}}}):_vm._e(),(filter.id === 'is_finmedia')?_c('v-checkbox',{staticClass:"table-filter__checkbox table-filter__checkbox-design",attrs:{"label":filter.label,"value":filter.itemText || true,"type":"checkbox","hide-details":"","color":"#1C2F45","ripple":false},on:{"change":function($event){return _vm.selectFilter(filter, $event)}},model:{value:(filter.select),callback:function ($$v) {_vm.$set(filter, "select", $$v)},expression:"filter.select"}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }