var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h3',{staticClass:"title"},[_vm._v(" Добавление новой отложки ")]),_c('v-row',_vm._l((_vm.autocompleteFields),function(field){return _c('v-col',{key:field.id,attrs:{"cols":"12"}},[_c('base-autocomplete',{attrs:{"data":{
                    apiMethod: field.apiMethod,
                    itemValue: field.itemValue,
                    template: field.template,
                    placeholder: field.placeholder,
                    key: field.key,
                },"model":_vm.delay[field.id]},on:{"update:model":function($event){return _vm.$set(_vm.delay, field.id, $event)}}})],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":"Отложка","placeholder":"0"},model:{value:(_vm.delay.delay),callback:function ($$v) {_vm.$set(_vm.delay, "delay", _vm._n($$v))},expression:"delay.delay"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }