
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import DateRange from "@/components/base/pickers/DateRange.vue";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import { IFilter } from "@/types";

@Component({
    components: { SelectDesign, DateRange },
})
export default class ReportFilterByDate extends Vue {
    @PropSync('filter', { required: true }) filterSync!: IFilter;
    @Prop({ default: undefined }) groupFilter!: IFilter;
    @Prop({ default: false }) readonly isDisabledSubmit!: boolean;
    @Prop({ default: false }) readonly isTop!: boolean;
    @Prop({ default: true }) readonly isTrashBtn!: boolean;
    @Prop({ default: true }) readonly isSubmitBtn!: boolean;
    @Prop({ default: false }) readonly isShowOuterGrouping!: boolean;

    get indexSelect(): string {
        return this.$vuetify.breakpoint.width <= 890 ? '' : 'Дата';
    }
}
