import OffersList from "@/views/offers/OffersList.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import Offer from "@/views/offers/OffersSingle.vue";
import CreateOffer from "@/views/offers/CreateOfferPage.vue";
import OfferCategories from "@/views/offers/Categories.vue";
import Approvals from "@/views/offers/approval/Approvals.vue";
import Approval from "@/views/offers/approval/Approval.vue";
import PersonalRates from "@/views/offers/personalRate/PersonalRates.vue";
import PersonalRate from "@/views/offers/personalRate/PersonalRate.vue";
import TopOfWeek from "@/views/offers/TopOfWeek.vue";
import Goal from "@/views/offers/Goal.vue";

export default [
    {
        path: 'all',
        name: 'offers-list',
        component: OffersList,
        meta: {
            title: 'Офферы',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'offer/:id',
        name: 'offer',
        component: Offer,
        meta: {
            title: 'Детальная страница оффера',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'offer-goal/:id',
        name: 'offer-goal',
        component: Goal,
        meta: {
            title: 'Детальная страница цели оффера',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'create-offer',
        name: 'create-offer',
        component: CreateOffer,
        meta: {
            title: 'Создание оффера',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.SUPERVISOR,
            ],
        },
    },
    {
        path: 'categories',
        name: 'categories',
        component: OfferCategories,
        meta: {
            title: 'Категории офферов',
            requiresAuth: true,
            roles: [USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR,
            ],
        },
    },
    {
        path: 'approvals',
        name: 'approvals',
        component: Approvals,
        meta: {
            title: 'Доступы партнеров',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT,
            ],
        },
    },
    {
        path: 'approval/:id',
        name: 'approval',
        component: Approval,
        meta: {
            title: 'Детальная страница запроса',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT,
            ],
        },
    },
    {
        path: 'personal-rates',
        name: 'personal-rates',
        component: PersonalRates,
        meta: {
            title: 'Индивидуальные цены',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'personal-rate/:id',
        name: 'personal-rate',
        component: PersonalRate,
        meta: {
            title: 'Детальная страница индивидуальной цены',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'top-of-week',
        name: 'top-of-week',
        component: TopOfWeek,
        meta: {
            title: 'Топ за неделю',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT,
            ],
        },
    },
];
