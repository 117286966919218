import { USER_ROLES } from "@/mappings/user-roles";
import Antifraud from "@/views/antifraud/Antifraud.vue";
import CheckingAffiliates from "@/views/antifraud/CheckingAffiliates.vue";
import AntifraudDailyReport from "@/views/antifraud/AntifraudDailyReport.vue";

export default  [
    {
        path: 'check',
        name: 'check',
        meta: {
            title: 'Проверка на фрод',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
        component: Antifraud,
    },
    {
        path: 'checking-affiliates',
        name: 'checking-affiliates',
        meta: {
            title: 'Проверяемые партнёры',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
        component: CheckingAffiliates,
    },
    {
        path: 'antifraud-report',
        name: 'antifraud-report',
        meta: {
            title: 'Ежедневный отчёт',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
        component: AntifraudDailyReport,
    },
];
