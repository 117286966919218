
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component

export default class AppTableRouter extends Vue {
@Prop({ type: String, default: '' }) routerName!: string;
@Prop({ type: Object, default: () => ({}) }) params!: {};
@Prop({ type: Object, default: () => ({}) }) query!: {};
@Prop({ type: [Number, String], default: '' }) text!: number | string;
@Prop({ type: Boolean, default: false }) isShowIcon!: boolean;
@Prop({ type: Boolean, default: false }) readonly!: boolean;
@Prop({}) icon!: any;
@Prop({}) asideIcon!: any;
}
