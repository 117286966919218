
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getFormatDate } from "@/utils/formatDates";
import { translateLang, translatePartnerStatus, translateTheme } from "@/utils/translate";
import Status from "@/components/base/Status.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import SvgTrue from "@/assets/icons/true-icon.svg";
import SvgFalse from "@/assets/icons/false-icon.svg";
import SvgStar from "@/assets/icons/star.svg";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import {
    createAffiliateTag,
    getAffiliateSearch,
    getAffiliateTags,
    getActiveUserSearch,
    getTrafficSource,
} from "@/api/user";
import { IAffiliateManager, ITrafficSource } from "@/api/types/user";
import { showServerError } from "@/utils";
import FormModal from "@/components/base/FormModal.vue";
import ComboboxArrayDesign from "@/components/base/form/ComboboxArrayDesign.vue";
import Loader from "@/components/base/Loader.vue";
import TagSelect from "@/components/base/TagSelect.vue";
import EditBtn from "@/components/base/buttons/EditBtn.vue";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import { ICountry } from "@/api/types/catalogue";
import MessengerSelect from "@/components/base/form/MessengerSelect.vue";
import SvgCheckDark from '@/assets/icons/check-dark.svg';
import SvgTelegram from "@/assets/icons/telegram.svg";
import SvgWhatsapp from "@/assets/icons/whatsapp.svg";
import SvgSkype from "@/assets/icons/skype.svg";
import SvgWeChat from "@/assets/icons/wechat.svg";
import PhoneNumberInput from "@/components/base/PhoneNumberInput.vue";
const offers = namespace('offersModule');
const catalogue = namespace('catalogueModule');

export interface IAboutItem {
    type: string,
    name: string,
    value: any,
    subtype?: string;
    isVerified?: boolean,
    isWithoutTime?: boolean,
    isMaster?: boolean,
    isLink?: boolean,
    isEditable?: boolean,
    editMethod?: Function,
    managerData?: IAffiliateManager,
    isBlockable?: boolean,
    isChip?: boolean,
    isMultiple?: boolean,
    items?: any[],
    newValue?: any;
    btnMethod?: Function,
    hasButton?: boolean;
    isDisabledBtn?: boolean;
}

interface IMessengerItem {
    type: string;
    logo: SVGElement;
}

@Component({
    components: { MessengerSelect, DateTimePickerNew, EditBtn, TagSelect, Loader, ComboboxArrayDesign,
        FormModal, BaseAutocomplete, BaseSelect, Status, SvgTrue, SvgFalse, SvgStar, SvgCheckDark,
        SvgTelegram, SvgSkype, SvgWhatsapp, SvgWeChat, PhoneNumberInput },
})

export default class AffiliateAboutItem extends Vue {
    @Prop({ required: true }) item!: IAboutItem;
    @Prop({ required: true }) isEdit!: boolean;

    @offers.Getter('GET_VERTICAL_NAME') getVerticalName;
    @offers.Getter('GET_VERTICALS') getVerticals;
    @catalogue.Getter('GET_COUNTRIES') countries!: ICountry[];

    createAffiliateTag = createAffiliateTag;
    getAffiliateTags = getAffiliateTags;
    translatePartnerStatus = translatePartnerStatus;
    translateTheme = translateTheme;
    translateLang = translateLang;
    getFormatDate = getFormatDate;

    value: any = '';
    statusItems = [{ id: 'active' }, { id: 'pending' }, { id: 'deleted' }, { id: 'blocked' }, { id: 'rejected' }];
    themeItems: any[] = [{ id: 'light', name: 'Светлая' }, { id: 'dark', name: 'Тёмная' }];
    langItems: any[] = [{ id: 'en', name: 'Английский' }, { id: 'ru', name: 'Русский' }];
    trafficSources: ITrafficSource[] = [];
    messengerItems: IMessengerItem[] = [
        { type: 'telegram', logo: SvgTelegram },
        { type: 'whatsapp', logo: SvgWhatsapp },
        { type: 'skype', logo: SvgSkype },
        { type: 'wechat', logo: SvgWeChat },
    ];

    get getStatusItems(): {id: string, text: string}[] {
        return this.statusItems.map(i => {
            return { id: i.id, text: translatePartnerStatus(i.id).text };
        });
    }

    get getManagerSelectData(): any {
        return {
            apiMethod: getActiveUserSearch,
            template: (i) => `${i.first_name} ${i.last_name} ${i.id}`,
            itemValue: 'id',
            multiple: false,
            multiPaste: false,
        };
    }

    get getAffiliateSelectData(): any {
        return {
            apiMethod: getAffiliateSearch,
            template: (i) => `${i.id} ${i.first_name} ${i.last_name}`,
            itemValue: 'id',
        };
    }

    get isDisabled(): boolean {
        if (!this.isEdit || !this.item.isBlockable) {
            return false;
        }
        return !this.value || (Array.isArray(this.value) && this.value.length === 0);
    }

    getType(): string {
        return this.item.type;
    }

    getItems(type: string): any[] {
        switch (type) {
        case 'theme': return this.themeItems;
        case 'vertical': return this.getVerticals;
        case 'traffic': return this.trafficSources;
        case 'country': return this.countries;
        case 'language': return this.langItems;
        default: return [];
        }
    }

    get maxShowItems(): number {
        const value = this.item.value?.slice(0,3)!.map(i => this.getTranslate(this.item.type)(i)).join();
        if (value?.length > 20 && this.item.value?.length >= 1) return 2;
        return 3;
    }

    getTranslate(type: string): Function {
        switch (type) {
        case 'theme': return translateTheme;
        case 'vertical': return this.translateVertical;
        case 'traffic': return this.getTrafficSourceName;
        case 'tags': return this.getName;
        case 'language': return translateLang;
        case 'country': return this.getName;
        default: return () => ({});
        }
    }

    getName(id: number): string {
        return this.item.items!.find(i => i.id === id)?.name!;
    }

    getTrafficSourceName(id: number): string {
        return this.trafficSources?.find(i => i.id === id)?.name!;
    }

    getPhoneData( data: any ): void{
        this.value = data.number;
    }

    changeValue(value: string): void {
        this.value = value;
    }

    translateVertical(id: number): string {
        return this.getVerticalName(id);
    }

    editValue(): void {
        this.item.newValue = this.value;
    }

    setValue(): void {
        delete this.item.newValue;
        if (this.item.type === 'switch') return;
        this.value = Array.isArray(this.item.value) && this.item.type !== 'tags' ? [...this.item.value] : this.item.value;
    }

    created(): void {
        if (this.item.type === 'traffic') {
            this.getTrafficSources();
        }
        this.updateSwitch();
    }

    @Watch('item.value')
    updateSwitch(): void {
        if (this.item.type !== 'switch') return;
        this.value = this.item.value;
    }

    async getTrafficSources(): Promise<void> {
        try {
            this.trafficSources = await getTrafficSource();
        } catch (err) {
            showServerError(err, 'Ошибка загрузки источников трафика');
        }
    }
}

