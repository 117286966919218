
import {
    Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { Route } from 'vue-router';
import { getFormatDatesForDatepicker, getFormatDate } from '@/utils/formatDates';
import FormModal from '@/components/base/FormModal.vue';
import {
    getAdvertisersSearch,
    getOneInvoice,
} from '@/api/revenue';
import {
    IAdvertiser,
    IStatTasks,
} from '@/api/types/revenue';
import store from '@/store';
import { MAX_LIMIT_FOR_SELECT, SEARCH_VALUE_DEBOUNCE } from '@/configs/global';

const revenue = namespace('revenueModule');

@Component({
    components: {
        FormModal,
    },
})

export default class StatTaskHeader extends Vue {
    @Prop() readonly isStatTask!: boolean;
    @PropSync('statTaskObject', { required: true }) statTaskObjectSync!: IStatTasks;
    @Prop() readonly currentStatus!: string;
    @Prop({ type: Boolean, default: false }) isAnotherAdvertiser!: boolean;

    @Getter('GET_EDITING_MODE') editingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @revenue.Mutation('SET_ADVERTISER_FIELDS') setAdvertiserFields;
    @revenue.Getter('GET_ADVERTISER_FIELDS') getAdvertiserFields;

    // рекламодатель на которыго хотим сменить тукущего реламодателя
    newAdvertiser: any = {};
    isShowModalDeleteOffer = false;
    // for autocomplete
    foundItemsAdvertisers: Array<{id: number, name: string}> = [];
    selectedItemAdvertiser = '';
    loadingAdvertisers = false;
    // Показывается ли меню датапикера
    isMenuWasShown = false;
    invoiceSearchNumberId: number | null = null;
    invoiceSearchLoading = false;
    // Найден ли счёт
    isInvoiceWasFound = false;
    isShowError = false;
    showMessage = '';
    invoice: any = {};
    searchTimerId = 0;
    checkTimerId = 0;

    getFormatDate = getFormatDate;
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;

    setAdvertiser(evt: IAdvertiser): void {
        if (this.statTaskObjectSync.items!.length > 0) {
            this.isShowModalDeleteOffer = true;
            this.newAdvertiser = evt;
        } else {
            this.statTaskObjectSync.advertiser = evt;
            this.$emit('update-stat-task');
        }
    }

    get assignee(): string {
        return `${this.statTaskObjectSync.assignee_to!.first_name} ${this.statTaskObjectSync.assignee_to!.last_name}`;
    }

    get isShowAssignee(): boolean {
        return this.$props.isStatTask && this.$props.currentStatus !== 'pending';
    }

    get nameChangeInvoiceIdBtn(): string {
        return !this.$props.isStatTask ? this.isInvoiceFoundNotError ? 'Отвязать' : 'Привязать' :
            (!!this.invoiceSearchNumberId && !!this.statTaskObjectSync.invoice_id) ? 'Отвязать' : 'Привязать';
    }

    get InvoiceNumberReadonly(): boolean {
        return !this.editingMode && (!this.$props.isStatTask ? this.isInvoiceFoundNotError :
            (!!this.invoiceSearchNumberId && !!this.statTaskObjectSync.invoice_id));
    }

    @Watch('statTaskObjectSync.period')
    getOffers(): void {
        // проверяем указаны ли рекл и период
        const { advertiser, period } = this.statTaskObjectSync;
        if (period && advertiser!.id && !this.$props.isAnotherAdvertiser) {
            this.$emit('update-stat-task');
        }
    }


    clearOfferServices(): void {
        if (this.statTaskObjectSync.items!.length > 0) {
            this.isShowModalDeleteOffer = true;
        } else {
            this.clearAdvertiserRelatedData();
        }
    }

    clearAdvertiserRelatedData(): void {
        this.statTaskObjectSync.advertiser = {} as IAdvertiser;
        this.invoiceSearchNumberId = null;
        this.statTaskObjectSync.items = [];
        this.statTaskObjectSync.files = [];
        this.$emit('clear-offer');
        if ((this.newAdvertiser && this.newAdvertiser.id) || Object.keys(this.newAdvertiser).length > 0) {
            this.statTaskObjectSync.advertiser = this.newAdvertiser;
            this.$emit('update-stat-task');
        }
        this.newAdvertiser = {};
        this.isShowModalDeleteOffer = false;
    }

    @Watch('selectedItemAdvertiser')
    searchAdvertiser(inputValue: string): void {
        if (this.selectedItemAdvertiser) {
            clearTimeout(this.searchTimerId);
            this.loadingAdvertisers = true;
            this.searchTimerId = window.setTimeout(() => {
                this.getFoundedAdvertiser(inputValue);
            }, SEARCH_VALUE_DEBOUNCE); /* 850ms задержка */
        }
    }

    goTo(router: Route & { name: string }, params: any): void {
        if (!this.editingMode) {
            this.$router.push(router, params);
        }
    }

    clearValidate(): void {
        this.invoiceSearchNumberId = null;
        this.isShowError = false;
        this.showMessage = '';
        this.isInvoiceWasFound = false;
        this.statTaskObjectSync.invoice_id = null;
    }

    changeInvoiceId(): void {
        if (this.isInvoiceWasFound && !this.isShowError
        || (!!this.invoiceSearchNumberId && this.statTaskObjectSync.invoice_id)) {
            this.invoiceSearchNumberId = null;
            this.isInvoiceWasFound = false;
            this.statTaskObjectSync.invoice_id = null;
        } else {
            this.searchInvoiceById();
        }

        this.setUnsavedDataState(true);
        this.statTaskObjectSync.invoice_id = null;
    }

    async searchInvoiceById(): Promise<void> {
        if (!this.invoiceSearchNumberId) {
            this.statTaskObjectSync.invoice_id = null;
            return;
        }
        try {
            this.invoice = await getOneInvoice(this.invoiceSearchNumberId);
            this.invoiceSearchLoading = true;
            this.isInvoiceWasFound = true;
            if (this.invoice.advertiser.id === this.statTaskObjectSync.advertiser!.id && this.isFoundStatTask) {
                this.statTaskObjectSync.invoice_id = this.invoiceSearchNumberId;
            } else {
                this.isShowError = true;
            }
            if (this.isShowError) {
                if (this.invoice.stat_task && this.invoice.stat_task.id) {
                    this.showMessage = ` К счету ID: ${this.invoiceSearchNumberId
                    } уже привязана задача ID: ${this.invoice.stat_task.id}`;
                } else {
                    this.showMessage = `Счет ID: ${this.invoiceSearchNumberId}
                    относится к рекламодателю ${this.invoice.advertiser.name}`;
                }
            } else {
                this.showMessage = '';
            }
        } catch {
            this.isInvoiceWasFound = false;
            this.isShowError = true;
            this.showMessage = `Счет ${this.invoiceSearchNumberId} не найден`;
            store.commit('SET_SERVER_ERROR', {});
        }
        this.invoiceSearchLoading = false;
        this.setUnsavedDataState(true);
    }

    beforeDestroy(): void {
        // При удалении компонента, чистим в сторе данные, которые нужны были для данной страницы
        this.setAdvertiserFields({});
    }

    get isInvoiceLinked(): boolean {
        return this.isInvoiceFoundNotError && !!this.invoiceSearchNumberId
            || !!(this.$props.isStatTask && this.invoiceSearchNumberId && this.isInvoiceFoundNotError);
    }

    get isFoundStatTask(): boolean {
        if (this.$props.isStatTask) {
            if (this.invoice.stat_task && this.invoice.stat_task.id) {
                return this.invoice.stat_task.id === this.statTaskObjectSync.id;
            }
            return true;
        }
        if (this.invoice.stat_task && this.invoice.stat_task.id) {
            return false;
        }
        return true;
    }

    get isInvoiceFoundNotError(): boolean {
        return this.isInvoiceWasFound && !this.isShowError;
    }

    get advertiserName(): string {
        return this.statTaskObjectSync.advertiser
            ? `${this.statTaskObjectSync.advertiser.id} ${
                this.statTaskObjectSync.advertiser.name}` : '';
    }

    get createdName(): string {
        return this.statTaskObjectSync.created_by
            ? `${this.statTaskObjectSync.created_by.first_name} ${
                this.statTaskObjectSync.created_by.last_name}` : '';
    }

    // получаем список реклов на лету
    private async getFoundedAdvertiser(inputValue: string): Promise<void> {
        const result = await getAdvertisersSearch({ query: inputValue, limit: MAX_LIMIT_FOR_SELECT });
        this.loadingAdvertisers = false;
        this.foundItemsAdvertisers = result.data;
        for (const item of this.foundItemsAdvertisers) {
            item.name = `${item.id} ${item.name}`;
        }
    }

    private created(): void {
        this.invoiceSearchNumberId = this.statTaskObjectSync.invoice_id! || this.invoiceSearchNumberId;
        //  для создания загрузки со страницы рекла или счета
        const advertiser = this.getAdvertiserFields!;
        if (advertiser!.id) {
            // получаем из store поля для создания задачи на загрузку статистики
            this.statTaskObjectSync.advertiser = advertiser;
            this.foundItemsAdvertisers.push({
                id: advertiser.id,
                name: `${advertiser.id} ${advertiser.name}`,
            });
            this.setAdvertiser(advertiser);
        }

        this.setUnsavedDataState(false);
    }
}
