import { CreatedFilters } from "@/services/filters/filters";
import { IFilter } from "@/types";
import { filterClass } from "@/services/filters/filterClass";
import { translatePayoutMethodStatus, translateVisibility } from "@/utils/translate";
import store from "@/store";

export class EmployeesMethodsFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Select({
                id: 'is_active',
                label: 'Статус',
                items: [1, 0],
                type: 'number',
                clearable: true,
                select: 1,
                remove: true,
                translate: (i) => this.translateStatus(i, translatePayoutMethodStatus),
            }),
            new filterClass.Select({
                id: 'is_partner_visible',
                label: 'Видимость партнерам',
                items: [1, 0],
                type: 'number',
                clearable: true,
                remove: true,
                translate: (i) => this.translateStatus(i, translateVisibility),
            }),
        ];
    }

    setDefaultFilters(): any {
        const query = this.getQueryFilters();
        const activeFilters: object[] = [];
        const dateParam = {};
        this.filters.forEach((filter) => {
            if (filter.select.toString().length > 0 ) {
                activeFilters.push({
                    [filter.id]: filter.select,
                });
            }
            // если есть квери параметры, очищаем селект
            if (Object.keys(query).length > 0 && filter.select)  {
                filter.select = '';
            }
        });
        const paramsObj = { ...Object.assign({}, ...activeFilters), ...dateParam };
        store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', paramsObj);
        if (Object.keys(query).length <= 0) {
            store.commit('SET_FILTERS_PARAMS_OBJECT', paramsObj);
        }
    }
}
