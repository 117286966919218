
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import FormTextField from "@/components/offers/offer/products/FormTextField.vue";

@Component({
    components: { FormTextField },
})

export default class FormLink extends Vue {
    @PropSync('product')  productSync!: any;
    @Prop({ required: true }) isEdit!: boolean;
    @Prop({ required: true }) param!: any;
}
