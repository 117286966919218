
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardMaterial from '@/components/base/CardMaterial.vue';
import SvgPaymentInvoices from '@/assets/icons/payment-invoices.svg';
import CustomerOrderTop from '@/components/revenue/invoice/CustomerOrderTop.vue';
import PaymentCalendar from '@/components/revenue/invoice/PaymentCalendar.vue';
import Payments from '@/components/revenue/invoice/Payments.vue';
import AddingServicesTable from '@/components/revenue/AddingServicesTable.vue';
import { ICustomerOrders, IOfferService } from '@/api/types/revenue';
import { translateFinancialStatus, getCurrencyIcon } from '@/utils/translate';
import Status from '@/components/base/Status.vue';

@Component({
    components: {
        CardMaterial,
        SvgPaymentInvoices,
        CustomerOrderTop,
        PaymentCalendar,
        Payments,
        AddingServicesTable,
        Status,
    },
})
export default class PaymentInvoices extends Vue {
    @Prop({ type: Object, default: {} }) customerOrder!: ICustomerOrders;
    @Prop({ type: String, default: '' }) currencyIcon!: string;
    @Prop({ type: Number, default: '' }) totalAmount!: number;

    // Заголовки колонок в таблице услуг по офферам внутри бухгалтерского счёта
    // с возможность указать прямую выручку
    offerServicesHeaders = [
        { text: 'Прямая выручка', value: 'offer_direct' },
        { text: 'Оффер', value: 'offer' },
        { text: 'Цель', align: 'left', value: 'goal' },
        { text: 'Ставка', align: 'right', value: 'offer_rate' },
        { text: 'Количество', align: 'right', value: 'offer_qty' },
        { text: 'Итого', align: 'left', value: 'total' },
        { text: '', align: 'right', value: 'actions' },
    ];

    otherServicesHeaders = [
        { text: 'Услуга', align: 'left', value: 'service_name' },
        { text: 'Сумма', align: 'left', value: 'service_amount' },
        { text: '', align: 'right', value: 'actions' },
    ];

    translateFinancialStatus = translateFinancialStatus;

    getCurrencyIcon = getCurrencyIcon;

    // Посчитать сумму прямой выручки
    getTotalDirectIncomeOfOneAccount(item: any): number {
        return item.offer_services!
            .filter((offer: IOfferService) => offer.offer_direct)
            .map((i: IOfferService) => i.total)
            .reduce((a: number, b) => Number(a) + Number(b), 0);
    }
}
