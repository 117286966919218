
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getSummaryReport } from '@/api/revenue';
import { showServerError } from '@/utils';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { IReportSummary, IReportSummaryItem } from '@/api/types/revenue';

@Component
export default class BasicInformation extends Vue {
    @Prop({ type: Number, required: true }) reportId!: number;
    @Prop({ default: null }) report!: any;

    getFormatDatesForDatepicker = getFormatDatesForDatepicker;

    baseTitles: {[key: string]: string} = {
        approved_all: 'Принято всего: ',
        approved_old: 'Принято ранее: ',
        approved_new: 'Принято новых: ',
        adjust_link: ' Принято adjust: ',
        clones_link: 'Принято клоны: ',
        duplicates_adv: 'Дубли у рекла: ',
        duplicates_leadgid: 'Дубли в LeadGid: ',
        loss: 'Потери: ',
        extra: 'Лишние: ',
    };
    data = {} as IReportSummary;
    loading = false;
    dialogs: {[key: string]: boolean} = {};

    mounted(): void {
        this.getSummary();
    }

    calcTotal(value: IReportSummaryItem[]): number {
        const initialValue = 0;
        return value.reduce((accumulator, currentValue: any) => accumulator + currentValue.total, initialValue);
    }

    async getSummary(): Promise<void> {
        this.loading = true;
        try {
            if (this.$props.report) {
                this.data = this.$props.report;
            } else {
                const { data } = await getSummaryReport(this.$props.reportId);
                this.data = data;
            }
            let approvedAll: IReportSummaryItem[] = [];

            // если нет данных за период приходить Number (0), поэтому нужна проверка на массив
            if (this.data.approved_new instanceof Array) {
                approvedAll = this.data.approved_new;
            }
            if (this.data.approved_old instanceof Array) {
                approvedAll = [...approvedAll, ...this.data.approved_old];
            }
            if (this.data.adjust_link instanceof Array) {
                approvedAll = [...approvedAll, ...this.data.adjust_link];
            }
            if (this.data.clones_link instanceof Array) {
                approvedAll = [...approvedAll, ...this.data.clones_link];
            }
            let result;
            if (approvedAll.length > 0) {
                // группируем по периоду и счтаем в каждом периоде тотал
                result = approvedAll.reduce((acc: IReportSummaryItem[], obj: IReportSummaryItem) => {
                    const index = acc.findIndex((elm: IReportSummaryItem) => elm.period === obj.period);
                    if (index === -1) {
                        return acc.concat({ ...obj });
                    }
                    acc[index].total += obj.total;
                    return acc;
                }, []);
            } else {
                result = 0;
            }

            this.$set(this.data, 'approved_all', result);
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }
}
