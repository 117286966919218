
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import StatsCard from '@/components/base/StatsCard.vue';
import OnOff2fa from '@/components/layout/OnOff2fa.vue';
import ActiveTokensTable from '@/components/administration/ActiveTokensTable.vue';
import { translateUserRole } from '@/utils/translate';
import { USER_ROLES } from '@/mappings/user-roles';

const auth = namespace('authModule');

@Component({
    components: {
        OnOff2fa,
        StatsCard,
        ActiveTokensTable,
    },
})
export default class Profile extends Vue {
    @auth.Getter('GET_USER') userCRM;

    get userCRMRoles(): [] {
        return this.userCRM.roles.filter((role) => role !== USER_ROLES.EMPLOYEE);
    }

    getTemplate(item: any): string {
        return translateUserRole(item).text;
    }
}
