
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { getExtra } from '@/api/revenue';
import Report from '@/mixins/report';
import AppTable from '@/components/base/table/AppTable.vue';
import { translateConversionStatus, translateLeadStatus } from '@/utils/translate';
import { eventBus } from '@/eventbus';
import { changeTopScrollWidth, showServerError } from '@/utils';
import { IFilter, ITableStatus } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        AppTable,
    },
})
export default class Extra extends Mixins(Report) {
    @Prop({ type: Number, required: true }) reportId!: number;
    @Prop({ default: null }) report!: any;

    filters: IFilter[] = [
        new filterClass.Input({
            id: 'transaction_id',
            label: 'Transaction ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'lead_id',
            label: 'Lead ID',
            select: '',
            type: 'number',
        }),
        new filterClass.Input({
            id: 'advertiser_sub_id',
            label: 'Sub ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'affiliate_id',
            label: 'ID веба',
            select: '',
            type: 'number',
        }),
        new filterClass.Select({
            id: 'status',
            label: 'Статус',
            items: [],
            select: '',
            uuid: 'extra',
        }),
        new filterClass.Select({
            id: 'is_paid',
            label: 'Статус оплаты',
            items: [],
            translate: (i) => translateLeadStatus(i).text,
            select: '',
            uuid: 'extra',
        }),
        new filterClass.Select({
            id: 'payout',
            label: 'Выплата',
            items: [],
            select: '',
            uuid: 'extra',
        }),
        new filterClass.Select({
            id: 'revenue',
            label: 'Доход',
            items: [],
            select: '',
            uuid: 'extra',
        }),
        new filterClass.Datepicker({
            id: 'updated',
            label: 'Дата обновления',
            pickerType: 'month',
            select: '',
            range: true,
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Дата создания',
            pickerType: 'month',
            select: '',
            range: true,
        }),
    ];

    headers = [
        { text: 'Transaction ID', value: 'conversion.transaction_id' },
        { text: 'Sub ID', value: 'conversion.advertiser_sub_id' },
        { text: 'Lead ID', value: 'conversion.lead_id' },
        { text: 'ID веба', value: 'conversion.affiliate_id' },
        { text: 'Имя веба', value: 'conversion.affiliate_name' },
        { text: 'Статус лида', value: 'conversion.status' },
        { text: 'Статус оплаты', value: 'conversion.is_paid' },
        { text: 'Выплата', value: 'conversion.payout' },
        { text: 'Доход', value: 'conversion.revenue' },
        { text: 'Прибыль', value: 'profit' },
        { text: 'Дата лида', value: 'conversion.created' },
        { text: 'Дата обновления', value: 'conversion.updated' },
    ];

    // для отображения статусов в таблице
    statuses: ITableStatus[] = [
        {
            slot: 'item.conversion.is_paid',
            sup: 'conversion',
            key: 'is_paid',
            translate: translateLeadStatus,
        },
        {
            slot: 'item.conversion.status',
            sup: 'conversion',
            key: 'status',
            translate: translateConversionStatus,
        },
    ];

    mounted(): void {
        this.getExtra();
        eventBus.$off('change-extra', changeTopScrollWidth);
    }

    async getExtra(): Promise<void> {
        this.loading = true;
        try {
            if (this.$props.report) {
                this.initialData = this.$props.report.extra;
                this.total = this.$props.report.extra!.length || 0;
                this.headers.unshift({
                    text: 'Offer',
                    value: 'offer',
                });
            } else {
                const { meta, data } = await getExtra(this.$props.reportId);
                this.initialData = data;
                this.total = meta!.total!;
            }
            this.filteredData = [...this.initialData];
            this.getPaginationData(0, this.limit);
            this.fillFiltersItems();
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }

    beforeDestroy(): void {
        eventBus.$off('change-accepted-all', changeTopScrollWidth);
    }
}
