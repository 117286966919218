
import { Component, PropSync, Vue } from "vue-property-decorator";
import FormModal from "@/components/base/FormModal.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { translateDirections } from "@/utils/translate";
import { showServerError } from "@/utils";
import { createEmployeeMethod } from "@/api/payout";

@Component({
    components: {
        FormModal,
        BaseSelect,
    },
})
export default class FormCreateEmployeeMethod extends Vue {
  @PropSync('showModal') showModalSync!: boolean;

  employeeMethod = {
      nameRU: '',
      nameEN: '',
      direction: [],
      legalType: [],
      isAgencyCabinet: false,
  };

  countryItems: any[] = [{ id: 'ru' }, { id: 'eng' }];
  legalTypes: any[] = [{ id: 'individual', name: 'Физ.лицо' }, { id: 'legal' , name: 'Юр.лицо' }];
  get getCountryItems(): {id: string, text: string}[] {
      return this.countryItems.map(i => {
          return { id: i.id, text: translateDirections(i.id) };
      });
  }

  get isDisableCreateBtn(): boolean {
      return false;
  }

  updateDirection(value: any): void {
      this.employeeMethod.direction = value;
  }

  updateLegalType(value: any): void {
      this.employeeMethod.legalType = value;
  }

  closeModal(): void {
      this.showModalSync = false;
      this.resetFields();
  }

  resetFields(): void {
      this.employeeMethod = {
          nameRU: '',
          nameEN: '',
          direction: [],
          legalType: [],
          isAgencyCabinet: false,
      };
  }

  async createEmployeeMethod(): Promise<void> {
      try {
          const data = {
              is_cabinet: this.employeeMethod.isAgencyCabinet,
              directions: this.employeeMethod.direction,
              legal_types: this.employeeMethod.legalType,
              name_ru: this.employeeMethod.nameRU,
              name_en: this.employeeMethod.nameEN,
              method_id: this.employeeMethod.nameEN + '_v1',
          };
          await createEmployeeMethod(data);
          this.$emit('edit');
      } catch (err) {
          showServerError(err, 'Ошибка при создании способа выплаты.');
      } finally {
          this.closeModal();
      }
  }

}
