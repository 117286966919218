
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import SvgTrash from "@/assets/icons/trash.svg";
import { getCurrencyIcon } from "@/utils/translate";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { EmployeeMethod } from "@/services/administration/EmployeeMethod";
import { ICommission } from "@/api/types/payout";
import isEqual from "lodash-es/isEqual";

@Component({
    components: {
        TableWithFrontPaginationDesign,
        BaseSelect,
        EditModeTextField,
        SvgTrash,
        BaseCard,
    },
})

export default class EmployeeMethodCommissions extends Vue {
    @Prop({ required: true }) employeeMethod!: EmployeeMethod;
    @Inject() readonly translateCurrency!: any;
    @Inject() readonly getEmployeeMethod!: any;

    commissions: ICommission[] = [];
    isEdit = false;
    isSaveProcess = false;
    allCurrencies = ['RUB', 'EUR', 'USD', 'anything'];
    tableHeaders = [
        { text: 'Валюта', value: 'currency', sortable: false },
        { text: 'От', value: 'lower_threshold', sortable: false },
        { text: 'До', value: 'upper_threshold', sortable: false },
        { text: 'Тип', value: 'type', sortable: false },
        { text: 'Сумма %', value: 'percent', sortable: false, class: 'd-none', cellClass: 'd-none' },
        { text: 'Сумма', value: 'amount', sortable: false },
    ];
    commissionTypeOptions = [
        { id: 'amount', text: 'Фиксированная' },
        { id: 'percent', text: 'Процент' },
        { id: 'mixed', text: 'Комбинированная' },
    ];
    uniqueData = [
        { slot: 'item.currency' },
        { slot: 'item.lower_threshold' },
        { slot: 'item.upper_threshold' },
        { slot: 'item.type' },
        { slot: 'item.amount' },
        { slot: 'item.percent' },
    ];
    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            callback: async (item: any): Promise<void> => await this.deleteCommission(item),
        },
    ];

    get currentCommissionsTableHeaders(): any[] {
        const percentHeader = this.tableHeaders.find(h => h.value === 'percent');
        percentHeader!.class = this.isEdit ? '' : 'd-none';
        percentHeader!.cellClass = this.isEdit ? '' : 'd-none';
        const action = { text: '', value: 'actions', align: 'center', width: '20px' };
        return this.isEdit ? [...this.tableHeaders, action] : this.tableHeaders;
    }

    get isSaveDisabled(): boolean {
        const isNotValid = this.commissions.some(c => {
            const lowerThanUpper = !!c.lower_threshold && !!c.upper_threshold && c.lower_threshold >= c.upper_threshold;
            const notType = !c.type;
            const zeroUpper = c.upper_threshold === 0;
            const zeroAmount = ['mixed', 'amount'].includes(c.type) && (Number(c.amount) === 0 || !c.amount);
            const zeroPercent = ['mixed', 'percent'].includes(c.type) && (Number(c.percent) === 0 || !c.percent);
            return lowerThanUpper || notType || zeroUpper || zeroAmount || zeroPercent;
        });

        return isNotValid || this.isSaveProcess;
    }

    changeType(item: any): void {
        item.amount = 0;
        item.percent = 0;
    }

    translateType(type: string): string {
        return this.commissionTypeOptions.find(c => c.id === type)!.text;
    }

    getAvailableCurrenciesForItem(itemCurrency: string): any[] {
        const currencies = this.isEdit ? [...this.allCurrencies, itemCurrency] : this.allCurrencies;
        return currencies.map(c => ({ id: c, text: this.translateCurrency(c) }));
    }

    addNewCommission(): void {
        if (this.allCurrencies.length > 0) {
            const newCommission = {
                currency: this.allCurrencies[0],
                lower_threshold: '',
                upper_threshold: '',
                type: '',
                amount: 0,
                percent: 0,
            };
            this.commissions = [...this.commissions, newCommission];
        }
    }

    mounted(): void {
        this.resetChanges();
    }

    async deleteCommission(commission: ICommission): Promise<void> {
        this.commissions = this.commissions.filter(c => c !== commission);
        if (commission.id) {
            await this.employeeMethod.deleteCommission(commission.id);
            await this.getEmployeeMethod();
        }
    }

    prepareCurrencyIcon(currency: string): string {
        if (currency === 'anything') return '';
        return getCurrencyIcon(currency);
    }

    getAmount(item: any): string {
        return item.type === 'mixed' ? `${item.percent}% + ${(item.amount).toLocaleString('ru')}${this.prepareCurrencyIcon(item.currency)}` :
            item.type === 'percent' ? `${item.percent}%` : `${(item.amount).toLocaleString('ru')} ${this.prepareCurrencyIcon(item.currency)}`;
    }

    async editMode(isEdit: boolean): Promise<void> {
        if (isEdit) {
            this.commissions.length === 0 && this.addNewCommission();
        } else if (!isEqual(this.commissions, this.employeeMethod.commissions)) {
            this.isSaveProcess = true;
            this.commissions.forEach((item) => {
                item.lower_threshold = item.lower_threshold === '' ? 0 : parseInt(item.lower_threshold as string);
                item.upper_threshold = item.upper_threshold === '' ? 99999999.99 : parseInt(item.upper_threshold as string);
                item.amount = parseInt(item.amount! as string);
                item.percent = parseInt(item.percent! as string);
            });
            await this.employeeMethod.crateOrUpdateCommission(this.commissions);
            await this.getEmployeeMethod();
            this.resetChanges();
            this.isSaveProcess = false;
        }
        this.isEdit = isEdit;
    }

    resetChanges(): void {
        this.commissions = JSON.parse(JSON.stringify(this.employeeMethod.commissions));
        this.isEdit = false;
    }
}
