
import { Component, Vue } from 'vue-property-decorator';
import SvgAdvertisers from '@/assets/icons/nav-bar/advertisers.svg';
import Filters from '@/components/base/filters/Filters.vue';
import AppTable from '@/components/base/table/AppTable.vue';
import FinanceInvoices from '@/components/orgstats/FinanceInvoices.vue';
import { getFinanceInvoices } from '@/api/org-cabinets-backend';

@Component({
    components: {
        Filters,
        SvgAdvertisers,
        AppTable,
        FinanceInvoices,
    },
})
export default class PageFinanceInvoices extends Vue {
    apiMethod = getFinanceInvoices;
}
