
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { TableHeader } from "@/types";
import { Offer } from "@/services/offers/Offer";
import { IOffer } from "@/api/types/offers";

@Component({
    components: { AppTableDesign },
})

export default class CategoriesTable extends Vue {
    @Prop({ required: true }) items!: Offer[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    headers:TableHeader[] = [
        { text: 'ID оффера', sortable: false, value: 'legacy_id', align: 'center' },
        { text: 'Название оффера', sortable: false, value: 'name' },
        { text: 'Менеджер', sortable: false, value: 'manager' },
        { text: 'Направление', sortable: false, value: 'departments' },
        { text: 'Тип трафика', sortable: false, value: 'traffics' },
        { text: 'Тип продукта', sortable: false, value: 'products' },
        { text: 'Статистика', sortable: false, value: 'stats' },
        { text: 'Выплата', sortable: false, value: 'payouts' },
        { text: 'Целевое действие', sortable: false, value: 'target_actions' },
        { text: 'Вертикаль', sortable: false, value: 'verticals' },
        { text: '', align: 'center', value: 'actions', fixed: true },
    ];

    actions = [
        {
            title: 'Редактировать',
            color: 'success',
            icon: 'SvgEdit',
            callback: ({ legacy_id }: any): void => {
                if (legacy_id) {
                    const url = `https://acc.leadgid.com/admin/offers/edit_details/${legacy_id}`;
                    window.open(url, '_blank');
                }
            },
        },
    ];

    // для отображения роутов в таблице
    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.name',
            routerName: 'offer',
            text: 'name',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.legacy_id',
            routerName: 'offer',
            text: 'legacy_id',
        },
    ];

    uniqueData = [
        {
            slot: 'item.manager',
            template: (item: IOffer): string => `${item.manager!.name || ''}`,
        },
        {
            slot: 'item.traffics',
            template: (item: IOffer): string => this.getTemplateForUniqData('traffics', item),
        },
        {
            slot: 'item.stats',
            template: (item: IOffer): string => this.getTemplateForUniqData('stats', item),
        },
        {
            slot: 'item.departments',
            template: (item: IOffer): string => this.getTemplateForUniqData('departments', item),
        },
        {
            slot: 'item.products',
            template: (item: IOffer): string => this.getTemplateForUniqData('products', item),
        },
        {
            slot: 'item.payouts',
            template: (item: IOffer): string => this.getTemplateForUniqData('payouts', item),
        },
        {
            slot: 'item.target_actions',
            template: (item: IOffer): string => this.getTemplateForUniqData('target_actions', item),
        },
        {
            slot: 'item.verticals',
            template: (item: IOffer): string => `${item.verticals!.map((item) => item.name).join(', ') || ''}`,
        },
    ];

    getTemplateForUniqData(key: string, item: IOffer): string {
        return item[key].map((i) => i.name).toString();
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }
}

