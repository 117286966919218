
import { Component, Vue, Prop } from 'vue-property-decorator';
import Message from '@/components/support/Message.vue';
import { IMessage, ITicket } from '@/api/types/support';
import SvgReload from "@/assets/icons/reload-white.svg";
import SvgCheck from "@/assets/icons/check.svg";

@Component({
    components: {
        SvgCheck, SvgReload, Message,
    },
})
export default class Messages extends Vue {
    @Prop({ type: Array, default: () => ([]) }) messages!: IMessage[];
    @Prop({ default: 'ru' }) language!: string;
    @Prop({ required: true }) ticket!: ITicket;

    mounted(): void {
        this.scrollToBottom();
    }

    updated(): void {
        this.scrollToBottom();
    }

    getTicketTopic(id: number): string {
        return this.messages[0].id === id ? this.ticket.title : '';
    }

    scrollToBottom(): void {
        const messages = document.querySelector('.messages');
        messages!.scrollTop = messages!.scrollHeight;
    }

    get groupedMessages(): object {
        const groups = {};
        this.messages.forEach((message) => {
            const date = message.created_at.split(' ')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(message);
        });
        return groups;
    }

    formatDate(dateString: string): any {
        const [year, month, day] = dateString.split('-');
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        return new Intl.DateTimeFormat('ru-RU', { day: 'numeric', month: 'long' }).format(date);
    }
}
