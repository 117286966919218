
import { Component, InjectReactive, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import FormModal from '@/components/base/FormModal.vue';
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import { getAffiliateSearch } from '@/api/user';
import Loader from '@/components/base/Loader.vue';
import { showServerError } from '@/utils';
import { NotificationMessage } from '@/utils/notification';
import { getLegalEntities } from '@/api/revenue';
import TrashBtn from '@/components/base/buttons/TrashBtn.vue';
import {
    getOrganizations,
    getCurrencies,
    getDds,
    getProjects,
    getAffiliateInvoices,
    createChargeRequest,
    getEmployeeAffiliateMethods,
} from '@/api/payout';
import { IMyLeadgidInvoice } from '@/api/types/payout';
import { searchWebmasters } from '@/api/org-cabinets-backend';
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { getCurrencyRate } from '@/api/catalogue';
import { getCurrentDate } from "@/utils/formatDates";

enum LEADGID_ORG  {
    RU_RATE = 'Лидгид Россия (валюта)',
    EUROPE = 'Лидгид Европа'
}
enum CONRACTOR_GUID {
    CONTRACT = 'contract',
    TETHER = 'fc2ff2df-5e63-11ec-911b-b42e99837b27',
}

@Component({
    methods: { searchWebmasters, getLegalEntities, getAffiliateSearch },
    components: {
        BaseSelect,
        TrashBtn,
        Loader,
        BaseAutocomplete,
        FormModal,
    },
})

export default class FormChargeRequest extends Vue {
    @PropSync('showModal') showModalSync!: boolean;
    @Ref() affiliateInput!: BaseAutocomplete;
    @Ref() offersInput!: BaseAutocomplete;

    isLoading: boolean = false;
    request: any = {
        invoice_ids: [],
        affiliate_ids: [],
        rate_amount: null,
    } as unknown as any;
    currencies: any = [];
    organizations: any = [];
    @InjectReactive() readonly contractors;
    contractorsVisible: any = [];
    requisites: any = [];
    dds: any = [];
    projects: any = [];
    unpaidInvoices: IMyLeadgidInvoice[] = [];
    isDisableCreateBtn = true;


    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 890;
    }

    get isShowCurrencyTrash(): boolean {
        return (this.request.currency_guid?.length > 0 && this.currencies.length > 1) || (this.request.amount?.toString().length > 0);
    }

    // Если способ выплаты указан По договору
    get isContract(): boolean {
        return this.request.contractor_guid === CONRACTOR_GUID.CONTRACT;
    }

    // Если способ выплаты Tether и Лидгид Европа
    get isLeadgidEuropeTether(): boolean {
        return this.request.contractor_guid === CONRACTOR_GUID.TETHER &&  this.projects.find((p) => p.guid === this.request.project_guid)?.name ===  LEADGID_ORG.EUROPE;
    }

    // Если проект указан Лидгид Россия (валюта)
    get isLeadgidRussia(): boolean {
        return this.projects.find((p) => p.guid === this.request.project_guid)?.name === LEADGID_ORG.RU_RATE;
    }

    get getTitle(): string {
        return 'Создание заявки';
    }

    created(): void {
        this.getDataForSelects();
    }

    async getDataForSelects(): Promise<void> {
        const [currencies, organizations, dds, projects] = await Promise.all([
            getCurrencies(),
            getOrganizations(),
            getDds(),
            getProjects(),
        ]);
        this.currencies = currencies;
        this.organizations = organizations;
        this.contractorsVisible = this.contractors;
        this.dds = dds;
        this.projects = projects;
        this.setDefaultValues();
    }

    @Watch('request', { immediate: true, deep: true })
    disableCreateBtn(): void {
        const requiredProps = [
            'currency_guid',
            'org_guid',
            'dds_guid',
            'project_guid',
            'contractor_guid',
            'affiliate_ids',
            'amount',
            'invoice_ids',
        ];

        if (this.isContract) {
            requiredProps.push('webmaster_guid');
        } else if (this.isLeadgidRussia || this.isLeadgidEuropeTether) {
            requiredProps.push('requisites');
        } else {
            requiredProps.push('attachment');
        }
        if (this.request.requisites === 'Другое') {
            requiredProps.push('requisites_custom');
        }
        if (this.request.rate_amount && !this.request.rate_currency_guid) {
            requiredProps.push('rate_currency_guid');
        } else if (this.request.rate_currency_guid && !this.request.rate_amount) {
            requiredProps.push('rate_amount');
        }

        const hasAllProps = requiredProps.every(prop => {
            return this.request.hasOwnProperty(prop)
                && this.request[prop] !== null
                && this.request[prop] !== undefined
                && this.request[prop] !== '';
        });
        this.isDisableCreateBtn = !hasAllProps;
        if (!this.isContract) this.request.webmaster_guid = '';
    }

    @Watch('request.project_guid')
    projectGuidWatch(): void {
        if (this.isLeadgidRussia) {
            this.contractorsVisible = this.contractors;
        } else {
            this.contractorsVisible = this.contractors.filter((c) => c.guid !== CONRACTOR_GUID.CONTRACT);
        }
    }

    @Watch('request.affiliate_ids')
    async affiliateIdsWatch(): Promise<void> {
        await this.updateRequisitesList();
    }

    @Watch('request.contractor_guid')
    async contractorGuidWatch(): Promise<void> {
        await this.updateRequisitesList();
    }

    async updateRequisitesList(): Promise<void> {
        if (this.isContract) {
            this.requisites = [];
            return;
        }
        if (this.request.contractor_guid && this.request.affiliate_ids.length) {
            const methodsMap = {
                Tether: ['tether_v1'],
                WMZ: ['webmoney_v1'],
                Capitalist: ['capitalist_v1', 'capitalist_kz_v1'],
                TransferGo: ['transfer_v1'],
            };
            const methodName = this.contractors.find((c) => c.guid === this.request.contractor_guid).full_name;
            const methods = (await getEmployeeAffiliateMethods({
                offset: 0,
                limit: 100,
                statuses: ['approved'],
                partner_ids: this.request.affiliate_ids,
                method_ids: methodsMap[methodName],
            })).data;
            this.requisites = Array.from(new Set(
                methods.map((m) => m.partner_data[Object.keys(m.partner_data)[0]]),
            ));
            this.requisites.push('Другое');
        }
    }

    closeModal(): void {
        this.clearInputs();
        this.showModalSync = false;
    }

    clearInputs(): void {
        Object.keys(this.request).forEach(key => {
            this.request[key] = Array.isArray(this.request[key]) ? [] : null;
        });
        this.setDefaultValues();
    }

    addWebmaster(webmaster: { address: string, guid: string, itemText: string, name: string }): void {
        this.$set(this.request, 'webmaster_guid', webmaster.guid);
    }

    deleteInvoices(): void {
        this.unpaidInvoices = [];
        this.request.invoice_ids = [];
    }

    async updateAffiliates(items: []): Promise<void> {
        this.request.affiliate_ids = items;
        if (items.length <= 0) {
            this.deleteInvoices();
        } else {
            await this.getUnpaidInvoice();
        }
    }

    async getUnpaidInvoice(): Promise<void> {
        try {
            const { data } = await getAffiliateInvoices({
                offset: 0, limit: 500,
                affiliate_id: [...this.request.affiliate_ids],
                paid_status: ['partial_paid', 'not_paid'],
            });
            this.unpaidInvoices = data;
        } catch (err) {
            showServerError(err, 'Список неоплаченных счетов не загружен');
        }
    }

    async createChargeRequest(): Promise<void> {
        this.isLoading = true;
        try {
            this.request.attachment = this.request.attachment as Blob;
            const formData = new FormData();
            formData.append('org_guid', this.request.org_guid);
            formData.append('dds_guid', this.request.dds_guid);
            formData.append('project_guid', this.request.project_guid);
            formData.append('contractor_guid', this.isContract ? this.request.webmaster_guid : this.request.contractor_guid);
            this.request.affiliate_ids.forEach((item) => formData.append('affiliate_ids[]', item));
            formData.append('amount', this.request.amount as string);
            formData.append('currency_guid', this.request.currency_guid);
            this.request.invoice_ids.forEach((item) => formData.append('invoice_ids[]', item as string));
            formData.append('comment', this.request.comment || '');
            if (this.isLeadgidRussia || this.isLeadgidEuropeTether) {
                if (this.request.requisites === 'Другое') {
                    formData.append('requisites', this.request.requisites_custom as string);
                } else {
                    formData.append('requisites', this.request.requisites);
                }
            } else {
                const fileName = this.request.attachment.name.replace(/[()]/g, '');
                formData.append('attachment', this.request.attachment as Blob, fileName);
            }
            if (this.request.rate_amount) formData.append('rate_amount', this.request.rate_amount as string);
            if (this.request.rate_currency_guid) formData.append('rate_currency_guid', this.request.rate_currency_guid);
            await createChargeRequest(formData);
            this.$emit('edit');
            this.showNotification();
            this.closeModal();
        } catch (err) {
            showServerError(err, 'Заявка на расход не создана');
        } finally {
            this.isLoading = false;
        }
    }

    setDefaultValues(): void {
        this.request.invoice_ids = [];
        this.request.affiliate_ids = [];
        if (this.currencies.length === 1) {
            this.request.currency_guid = this.currencies[0].guid;
        }
        if (this.organizations.length === 1) {
            this.request.org_guid = this.organizations[0].guid;
        }
        if (this.contractors.length === 1) {
            this.request.contractor_guid = this.contractors[0].guid;
        }
        if (this.dds.length === 1) {
            this.request.dds_guid = this.dds[0].guid;
        }
        if (this.projects.length === 1) {
            this.request.project_guid = this.projects[0].guid;
        }
    }

    showNotification(): void {
        const notificationText = `Заявка успешно создана`;
        new NotificationMessage(notificationText, 'success');
    }

    clearCurrency(item: string): void {
        if (item) {
            this.getCurrencyCourse();
        } else {
            this.request.currency_guid = '';
        }
    }

    clearRateCurrency(item: string): void {
        if (item) return;
        this.request.rate_currency_guid = '';
    }

    // получение курса валют на определенную дату
    async getCurrencyCourse(): Promise<void> {
        this.request.rate_amount = '';
        if (!this.request.rate_currency_guid) return;
        try {
            await this.getCurrencyRate();
        } catch (err) {
            if (err.status === 404) {
                await this.getCurrencyRateFromBank();
            } else {
                showServerError(err, 'Не удалось получить информацию по курсу валют');
            }
        }
    }

    // получение курса валют с банка
    async getCurrencyRateFromBank(): Promise<void> {
        try {
            await this.getCurrencyRate('cb');
        } catch (err) {
            if (err.status === 404) {
                await this.getCurrencyRateFromAPI();
            } else {
                showServerError(err, 'Не удалось получить информацию по курсу валют');
            }
        }
    }

    // получение курса валют с API
    async getCurrencyRateFromAPI(): Promise<void> {
        try {
            await this.getCurrencyRate('api');
        } catch (err) {
            showServerError(err, 'Не удалось получить информацию по курсу валют');
        }
    }

    async getCurrencyRate(source = 'excel'): Promise<void> {
        const date = getCurrentDate('yyyy-MM-dd');
        const currency_from = this.currencies.find(c => c.guid === this.request.rate_currency_guid)?.name;
        const currency_to = this.currencies.find(c => c.guid === this.request.currency_guid)?.name;
        if (currency_to === currency_from) return;
        const data = await getCurrencyRate({
            source,
            currency_from,
            currency_to,
            date,
        });
        this.$set(this.request, 'rate_amount', data.rate || '');
    }
}
