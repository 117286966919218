
import { Component, Vue, Prop } from 'vue-property-decorator';
import { translateBankDetails } from '@/utils/translate';

@Component
export default class RequiredFieldsList extends Vue {
    @Prop({ default: () => [] }) items!: string[];

    @Prop({ default: '' }) title!: string;

    getTemplate(item: any): string {
        return translateBankDetails(item);
    }
}
