
import {
    Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { Mutation, namespace } from 'vuex-class';
import {
    ICustomerOrders,
    IContractor,
    ILegalEntityOrg,
} from '@/api/types/revenue';
import { getRandomId, showServerError } from '@/utils';
import { getLegalEntities, updateCustomerOrderStatus } from '@/api/revenue';
import { translateFinancialStatus } from '@/utils/translate';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import Status from '../../base/Status.vue';
import TimeoutRefreshBtn from '@/components/base/buttons/TimeoutRefreshBtn.vue';
import { eventBus } from '@/eventbus';
import store from '@/store';
import { MAX_LIMIT_FOR_SELECT } from '@/configs/global';
import { IStatus } from "@/types";

const invoice = namespace('invoiceModule');

@Component({
    components: {
        TooltipButton,
        Status,
        TimeoutRefreshBtn,
    },
})
export default class CustomerOrderTop extends Vue {
    @invoice.Getter('GET_ADVERTISER') getAdvertiser;
    @invoice.Getter('GET_TIMER') getTimerValue;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    @PropSync('customerOrder', { required: false }) customerOrderSync!: ICustomerOrders;
    @Prop({ required: false }) isNewInvoice!: boolean;
    @Prop({ type: Boolean, default: false }) accountingIsDisabled!: boolean;
    @Prop({ required: false }) accountIndex!: number;
    @Prop({ required: false }) accountsLength!: number;
    @Prop({ type: Boolean, required: false, default: false }) isDraftInvoice!: boolean;

    selectedEntities = {} as ILegalEntityOrg | null;
    selectedProject = '';
    accountingEntities: ILegalEntityOrg[] = [];
    contractors: IContractor[] = [];
    selectedAdvertiserContractor = {} as IContractor | null;
    advertiserContractors: IContractor[] = [];
    projectsList: string[] = [];
    waitingTime: number = 180;

    @Watch('getAdvertiser')
    changeDependentData(): void {
        this.projectsList = this.getAdvertiser!.projects || this.projectsList;
        this.advertiserContractors = this.getAdvertiser!.contractors || this.advertiserContractors;
    }

    // вочер нужен, если панель для бухгалтерии развернули раньше,
    // чем выбрали рекла
    @Watch('projectsList')
    addSelectedProject(): void {
        if (this.projectsList.length === 1) {
            this.selectedProject = this.projectsList[0];
            this.$emit('set-accounting-fields', this.selectedProject, 'project');
        }
    }

    // вочер нужен, если панель для бухгалтерии развернули раньше, чем выбрали рекла
    @Watch('getContractorItems')
    addSelectedAdvertiserContractor(): void {
        if (!this.customerOrderSync.contractor) {
            if (this.contractors.length === 1) {
                this.selectedAdvertiserContractor = this.contractors[0];
                this.$emit('set-accounting-fields', this.selectedAdvertiserContractor, 'contractor');
            } else {
                const contractor = this.contractors.find((v) => v.is_default);
                if (contractor) {
                    this.selectedAdvertiserContractor = contractor;
                    this.$emit('set-accounting-fields', this.selectedAdvertiserContractor, 'contractor');
                }
            }
        }
    }

    @Watch('customerOrderSync', { immediate: true, deep: true })
    updateCustomerOrderFields(): void {
        if (this.customerOrderSync.legal_entity) {
            this.selectedEntities = this.customerOrderSync.legal_entity;
        }
        if (this.customerOrderSync.project) {
            this.selectedProject = this.customerOrderSync.project;
        }
        if (this.customerOrderSync.contractor) {
            this.selectedAdvertiserContractor = this.customerOrderSync.contractor;
        }
    }

    get translateFinancialStatus(): IStatus {
        return translateFinancialStatus(this.customerOrderSync.status!);
    }

    get getContractorItems(): IContractor[] {
        // для сортировки массива, чтобы удаленные контрагенты поместить в конец списка итемов селекта
        const arrayWithoutDeletedContractors: IContractor[] = [];
        const arrayOfDeletedContractors: IContractor[] = [];

        this.advertiserContractors?.forEach((c) => {
            c.deleted_at === null ? arrayWithoutDeletedContractors.push(c) : arrayOfDeletedContractors.push(c);
        });

        // если счет был создан на удаленного контрагента тогда показываем всех контрагентов,
        // иначе показываем только неудаленных контрагентов
        return (this.customerOrderSync.hasOwnProperty('id')
            && this.customerOrderSync.contractor?.deleted_at)
            ? [...arrayWithoutDeletedContractors, ...arrayOfDeletedContractors]
            : arrayWithoutDeletedContractors;
    }

    created(): void {
        this.projectsList = this.getAdvertiser!.projects as string[];
        this.advertiserContractors = this.getAdvertiser!.contractors! || [];
        this.contractors = this.getContractorItems;
        if (this.customerOrderSync.legal_entity) {
            this.selectedEntities = this.customerOrderSync.legal_entity;
        }
        if (this.customerOrderSync.project) {
            this.selectedProject = this.customerOrderSync.project;
        }
        if (this.customerOrderSync.contractor) {
            this.selectedAdvertiserContractor = this.customerOrderSync.contractor;
        }
        this.getLegalEntities();
    }

    async getLegalEntities(): Promise<void> {
        try {
            const result = await getLegalEntities({ limit: MAX_LIMIT_FOR_SELECT });
            this.accountingEntities = result.data.filter((item) => item.settings!.is_active);
            const newItem: ILegalEntityOrg = {
                id: getRandomId(),
                external_id: '',
                name: 'Не указан',
                settings: {
                    is_active: true,
                },
            };
            this.accountingEntities.unshift(newItem);
        } catch (err) {
            store.commit('SET_SERVER_ERROR', { status: err.status, message: 'Список юр.лиц не загружен' });
        }
    }

    removeAccount(num: number): void {
        eventBus.$emit('set-deleting-invoice-index', num);
    }

    updateStatus(): void {
        if (this.customerOrderSync.id) {
            this.updateCustomerOrderStatus(this.customerOrderSync.id);
        }
    }

    async updateCustomerOrderStatus(id: number): Promise<void> {
        try {
            await updateCustomerOrderStatus([{ id, version: '', state: '' }]);
        } catch (err) {
            showServerError(err, 'Ошибка при обновлении статуса');
        }
    }
}
