
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IApplicationTrace } from '@/api/types/uapi';

@Component
export default class Trace extends Vue {
    @Prop({ type: Array, default: () => [] }) trace!: IApplicationTrace[];

    showTrace = false;

    traceHeaders = [
        {
            text: 'Дата создания',
            value: 'createdAt',
        },
        {
            text: 'Этап',
            value: 'step',
        },
        {
            text: 'Инфо',
            value: 'payload',
        },
    ];
}
