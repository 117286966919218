
import { Vue, Component, Prop, PropSync, Ref } from "vue-property-decorator";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import SvgCheck from "@/assets/icons/check-btn.svg";
import SvgCheckbox from "@/assets/icons/checkbox.svg";
import { VSelect, VAutocomplete } from 'vuetify/lib';
import AutocompleteListItem from "@/components/base/form/AutocompleteListItem.vue";
import differenceWith from 'lodash-es/differenceWith';
import intersectionWith from 'lodash-es/intersectionWith';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";

@Component({
    components: { TrashBtn, AutocompleteListItem, SvgArrow, SvgCheck, SvgCheckbox },
})

export default class BaseSelect extends Vue {
    @Prop({ required: true }) items!: any[];
    @Prop({ default: 'id' }) itemValue!: string;
    @Prop({ default: '' }) placeholder!: string;
    @Prop({ default: 'text' }) itemText!: string;
    @Prop({ default: false }) isMultiple!: boolean;
    @Prop({ default: false }) isOutlined!: boolean;
    @Prop({ default: false }) isAutocompleteMode!: boolean;
    @Prop({ default: false }) isImageSelection!: boolean;
    @Prop({ default: false }) isTrash!: boolean;
    @Prop({ default: false }) isReturnObject!: boolean;
    @Prop({ default: '' }) imageSrc!: string;
    @Prop({ default: false }) isTop!: boolean;
    @Prop({ default: false }) isSmall!: boolean;
    @Prop({ default: true }) isAttach!: boolean;
    @Prop({ default: () => [] }) rules!: any[];
    @Prop({ default: 3 }) maxShowItems!: number;
    @Prop({ default: null }) template!: Function;
    @Prop({ default: false }) isClearable!: boolean;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop({ default: () => ({}) }) menuProps!: any;
    @Prop({ default: 'Ничего не найдено' }) noDataText!: string;
    @PropSync('value', { required: true }) valueSync!: any;
    @Prop({ type: Boolean, default: true }) ellipsisText!: boolean;

    @Ref() baseSelect!: typeof VAutocomplete | typeof VSelect;

    searchInput: string | null = '';
    key: number = 0;
    get getMode(): any {
        return this.isAutocompleteMode ? VAutocomplete : VSelect;
    }

    get isArray(): boolean {
        return Array.isArray(this.valueSync);
    }

    get getTooltipText(): string {
        const text = this.valueSync.map((value: any) => {
            const item = this.sortedItems.find(item => item[this.itemValue] === value);
            return !item ? '' : this.template ? this.template(item) : item[this.itemText];
        });
        return text.splice(this.maxShowItems).join(', ');
    }

    get isShowCustomSelection(): boolean {
        return !!this.template|| this.isImageSelection;
    }

    isCheck(item: any): boolean {
        const value = this.isReturnObject ? this.valueSync[this.itemValue] : this.valueSync;
        return !this.isMultiple && item[this.itemValue] === value;
    }

    isCheckCheckbox(item: any): boolean {
        return this.isReturnObject ?
            this.valueSync.some(i => i[this.itemValue] === item[this.itemValue]) :
            this.valueSync.includes(item[this.itemValue]);
    }

    handleClear(e: KeyboardEvent): void {
        if (e.key !== 'Backspace' || !this.isImageSelection) return;
        this.valueSync.pop();
    }

    select(item: any): void {
        const value = this.isReturnObject ? item : item[this.itemValue];
        if (this.isMultiple) {
            this.multiselect(value);
        } else {
            this.valueSync = value;
            this.key++;
            this.$emit('select', value);
        }
    }

    get sortedItems(): any[] {
        if (this.valueSync?.length > 0) {
            const difference = differenceWith(
                this.items,
                this.valueSync,
                (arrValue: any, othValue) => arrValue[this.itemValue] === othValue,
            );
            const intersection = intersectionWith(
                this.items,
                this.valueSync,
                (arrValue: any, othValue) => arrValue[this.itemValue] === othValue,
            );

            return [...intersection, ...difference];
        } else return this.items;
    }

    multiselect(value: any): void {
        const index = this.isArray ?
            this.isReturnObject ?
                this.valueSync.findIndex(i => i[this.itemValue] === value[this.itemValue]) :
                this.valueSync.indexOf(value) :
            -1;

        if (index > -1) {
            this.valueSync.splice(index, 1);
        } else if (this.isArray) {
            this.valueSync.push(value);
        }
    }

    resetSelected(): void {
        if (this.isAutocompleteMode && !this.searchInput) {
            this.key++;
        }
        this.$emit('blur', this.valueSync);
    }

    created(): void {
        if (this.valueSync === null) this.clear();
    }

    clear(): void {
        this.valueSync = this.isMultiple ? [] : '';
        this.$emit('select', this.valueSync);
    }
}

