import { ISurveyAnswer, ISurveyQuestion, ISurveyTextAnswer } from "@/api/types/support";

export class SurveyQuestion implements ISurveyQuestion {
    id?: number;
    position? = 0;
    text: string = '';
    type: 'one_variant' | 'multi_variant' | 'one_variant_text' | 'multi_variant_text' | 'text' = 'multi_variant';
    answers_count?: number = 0;
    answers: ISurveyAnswer[] = [{ text: '', count: 0, position: 0 }, { text: '', count: 0, position: 1 }];
    text_answers?: ISurveyTextAnswer[] = [];

    constructor(question?: ISurveyQuestion) {
        if (question !== undefined) {
            this.id = question.id;
            this.text = question.text;
            this.type = question.type;
            this.answers_count = question.answers_count;
            this.text_answers = question.text_answers ? [...question.text_answers] : [];
            this.answers = [...question.answers];
        }
    }

    translate(type: 'one_variant' | 'multi_variant' | 'one_variant_text' | 'multi_variant_text' | 'text' = this.type): string {
        switch (type) {
        case 'multi_variant':
        case 'multi_variant_text':
            return 'Несколько ответов';
        case 'one_variant':
        case 'one_variant_text':
            return 'Один ответ';
        case 'text':
            return 'Свой вариант';
        default:
            return type;
        }
    }

    get isVariantAndText(): boolean {
        return this.type === 'multi_variant_text' || this.type === 'one_variant_text';
    }

    get isAnswerCount(): boolean {
        return this.answers_count! > 0;
    }

    get isTextAnswerFull(): boolean {
        return this.text_answers!.length > 0;
    }

    get isAnswerFull(): boolean {
        return this.answers.length > 0;
    }

    get isRadioType(): boolean {
        return this.type === 'one_variant' || this.type === 'one_variant_text';
    }

    get isMultiType(): boolean {
        return this.type === 'multi_variant';
    }

    get isTextType(): boolean {
        return this.type === 'text';
    }

    addAnswer(): void {
        this.answers.push({ text: '', count: 0, position: this.answers.length });
    }

    delete(index: number): void {
        this.answers.splice(index, 1);
    }

    changeType(type: string = 'text'): string {
        if (type === 'text') {
            switch (this.type) {
            case 'multi_variant':
                return this.type = 'multi_variant_text';
            case 'one_variant':
                return this.type = 'one_variant_text';
            default:
                return this.type;
            }
        } else {
            switch (this.type) {
            case 'multi_variant_text':
                return this.type = 'multi_variant';
            case 'one_variant_text':
                return this.type = 'one_variant';
            default:
                return this.type;
            }
        }
    }
}
