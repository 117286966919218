
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { addFile } from '@/api';
import BtnDownload from '@/components/revenue/advertiser/BtnDownload.vue';
import { IFile } from '@/api/types/revenue';
import { showServerError } from '@/utils';

@Component({
    components: {
        BtnDownload,
    },
})

export default class Documents extends Vue {
    @Getter('GET_EDITING_MODE') editingMode;

    @Prop({ required: true }) readonly files!: [];

    // Выбранный для загрузки файл
    documentFiles: IFile[] = [];
    btnLoading = false;

    async addDocumentFile(): Promise<void> {
        this.btnLoading = true;
        try {
            for (const fl of this.documentFiles) {
                const { data: { data: file } } = await addFile(fl);
                this.$props.files!.push(file);
            }
        } catch (err) {
            showServerError(err, 'Файл не найден');
        }
        this.btnLoading = false;
        this.documentFiles = [];
    }

    async removeDocumentFile(index: number): Promise<void> {
        this.$props.files.splice(index, 1);
    }
}
