import { AxiosResponse, ResponseType } from 'axios';
import { axiosInstance } from '@/api/index';
import { IServerResponse } from '@/api/types';
import { API_CONVERSION_PROXY } from '@/configs/global';
import { IEmployeeConversion, IEmployeeConversionJob, IEmployeeConversionProgress } from '@/api/types/conversions-report';

/**
 *  Получение отчета по конверсиям для сотрудника
 */
export async function getEmployeeConversions(
    params: any,
    headers?: any,
    responseType?: ResponseType,
): Promise<any> {
    return axiosInstance.request<IServerResponse<IEmployeeConversion>>({
        method: 'POST',
        headers: { ...headers },
        url: `${API_CONVERSION_PROXY}employees/report`,
        data: params,
        responseType,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeConversion>>) => response.data);
}

/**
 *  Создание задачи на получение на скачивание отчета
 */
export async function getEmployeeConversionsJob(
    data: any,
    headers?: any,
): Promise<IEmployeeConversionJob>{
    return axiosInstance.request<IServerResponse<IEmployeeConversionJob>>({
        method: 'POST',
        headers: { ...headers },
        url: `${API_CONVERSION_PROXY}employees/report/job`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeConversionJob>>) => response.data.data);
}

/**
 *  Прогресс формирования
 */
export async function getEmployeeConversionsProgress(
    params: any,
): Promise<IEmployeeConversionProgress> {
    return axiosInstance.request<IServerResponse<IEmployeeConversionProgress>>({
        method: 'GET',
        url: `${API_CONVERSION_PROXY}employees/report/job/status`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeConversionProgress>>) => response.data.data);
}


/**
 *  Прогресс скачивания отчета в zip
 */
export async function getEmployeeConversionsDownload(
    params: any,
): Promise<any> {
    return axiosInstance.request<IServerResponse<IEmployeeConversionProgress>>({
        method: 'GET',
        url: `${API_CONVERSION_PROXY}employees/report/job/download`,
        params,
        responseType: 'blob',
    })
        .then((response: any) => response.data);
}

/**
 *  Список формирующихся отчётов
 */
export async function getEmployeeConversionsJobs(): Promise<IEmployeeConversionProgress[]> {
    return axiosInstance.request<IServerResponse<IEmployeeConversionProgress[]>>({
        method: 'GET',
        url: `${API_CONVERSION_PROXY}employees/report/jobs`,
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeConversionProgress[]>>) => response.data.data);
}

/**
 *  Отмена формирования отчёта
 */
export async function cancelEmployeeConversionsJob(
    id: string,
): Promise<IEmployeeConversionProgress[]> {
    return axiosInstance.request<IServerResponse<IEmployeeConversionProgress[]>>({
        method: 'DELETE',
        url: `${API_CONVERSION_PROXY}employees/report/job`,
        params: { id },
    })
        .then((response: AxiosResponse<IServerResponse<IEmployeeConversionProgress[]>>) => response.data.data);
}
