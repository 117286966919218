
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import OfferCard from "@/components/offers/offer/OfferCard.vue";
import { eventBus } from "@/eventbus";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { IEditableOfferGoalFields, IOfferGoal } from "@/api/types/offers";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { TableHeader } from "@/types";
import { namespace } from 'vuex-class';
import { getDigitsBeforeComma } from "@/utils/personalRate/rate";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
const catalogue = namespace('catalogueModule');
@Component({
    methods: { getDigitsBeforeComma },
    components: {
        DateTimePickerNew,
        TableWithFrontPaginationDesign,
        EditModeTextField,
        BaseSelect,
	    OfferCard,
    },
})
export default class GoalPayoutAndRevenue extends Vue {
    @Prop({ required: true }) offer!: OfferSingle | null;
    @Prop({ required: true }) goal!: any;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @catalogue.Getter("GET_CURRENCIES") currencies;
    isEdit = false;

    offerGoalData: IEditableOfferGoalFields = { action_date: '' };

    headersRevenue: TableHeader[] = [
        { text: 'Тип', sortable: false, value: 'revenue.type' },
        { text: 'Сумма', sortable: false, value: 'revenue.amount' },
    ];
    headersPayout: TableHeader[] = [
        { text: 'Тип', sortable: false, value: 'payout.type' },
        { text: 'Сумма', sortable: false, value: 'payout.amount' },
    ];

    textFields = [
        { slot: 'item.revenue.amount', key: 'revenue' },
        { slot: 'item.payout.amount', key: 'payout' },
    ];

    selects = [
        { slot: 'item.revenue.type', key: 'type', sup: 'revenue', optKey: 'amount' },
        { slot: 'item.payout.type', key: 'type', sup: 'payout', optKey: 'amount' },
    ];

    uniqueDataPayout = [
        {
            slot: 'item.payout.type',
            template: (item: IOfferGoal): string => item && item.payout ? this.typeTranslate(item.payout.type) : '',
        },
        {
            slot: 'item.payout.amount',
            template: (item: IOfferGoal): string => item && item.payout ? this.amountTranslate(item.payout) : '',
        },
    ];

    uniqueDataRevenue = [
        {
            slot: 'item.revenue.type',
            template: (item: IOfferGoal): string => item && item.revenue ? this.typeTranslate(item.revenue.type) : '',
        },
        {
            slot: 'item.revenue.amount',
            template: (item: IOfferGoal): string => item && item.revenue ? this.amountTranslate(item.revenue) : '',
        },
    ];

    created(): void {
        this.setDefaults();
    }

    clearAmount(sub: string): void {
        this.offerGoalData[sub].amount = "0";
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllOfferGoalData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setDefaults(): void {
        this.offerGoalData.payout = JSON.parse(JSON.stringify(this.goal.payout));
        this.offerGoalData.revenue = JSON.parse(JSON.stringify(this.goal.revenue));
    }

    typeTranslate(type: string): string {
        return type === 'fixed' ? 'Фиксированная' : 'Процент';
    }

    getSymbol(item: any): string {
        return item.type === 'fixed' ? this.getCurrency(item.currency) : '%';
    }

    amountTranslate(item: any): string {
        return item.hasOwnProperty('amount') && item.hasOwnProperty('currency') ?
            item.type === 'fixed' ?
                `${this.formatting(item.amount)} ${this.getCurrency(item.currency)}` :
                `${this.formatting(item.amount) } %` :
            '';
    }

    formatting(amount: string): string {
        return parseFloat(amount).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    getCurrency(code: string): string {
        return this.currencies.find(c => c.code === code)?.symbol || code;
    }

    async updateAllOfferGoalData(): Promise<void> {
        this.isLoadingSync = true;
        const goalData = JSON.parse(JSON.stringify(this.offerGoalData));
        goalData.revenue.amount = Number(goalData.revenue.amount);
        goalData.payout.amount = Number(goalData.payout.amount);
        const data = { ...this.goal, ...goalData };
        const isOfferGoalDataSaved = await this.offer?.editGoal(data);
        if (isOfferGoalDataSaved) {
            delete this.offerGoalData.action_date;
            eventBus.$emit("update-goal");
        }
        this.isLoadingSync = false;
    }
}
