
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgMagicWand from "@/assets/icons/magic-wand.svg";

@Component({
    components: { SvgMagicWand },
})

export default class CreateBtn extends Vue {
    @Prop({ default: true }) readonly isTooltip!: boolean;
    @Prop({ default: true }) readonly isTextBtn!: boolean;
    @Prop({ default: false }) readonly isDisabled!: boolean;
    @Prop({ default: true }) readonly isBlueOutline!: boolean;
    @Prop({ default: false }) readonly customBtnClass!: boolean;
    @Prop({ default: 0 }) readonly nudgeTop!: number;
    @Prop({ default: undefined }) readonly route!: any;
}
