
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { countries } from '@/utils/countries';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Component({
    components: { parsePhoneNumberFromString },
})

export default class PhoneNumberInput extends Vue {
    @Prop({ default: '' }) phoneNumber!: string;

    isDropdownOpen: boolean = false;
    isFieldFocus: boolean = false;
    searchQuery: string = '';
    filteredCountries: any[] = countries;
    localPhoneNumber: string = '';
    currentCode: string = '';


    get parsePhoneData(): any{
        const phoneNumber = "+" + this.currentCode + this.localPhoneNumber;
        return parsePhoneNumberFromString( phoneNumber );
    }

    @Watch('localPhoneNumber')
    onPhoneNumberChanged(): void {
        this.localPhoneNumber = this.localPhoneNumber.replace(/[^\d+]/g, '');
        this.updatePhoneNumber();
    }

    selectCountry( country: any ): void {
        this.currentCode = country[2];

        this.toggleDropdown();
        this.updatePhoneNumber();
        this.searchQuery = '';
        this.filterCountries();
    }

    onPaste() : void {
        setTimeout( () => {
            this.formatPhoneNumber('paste');
        }, 0);
    }

    formatPhoneNumber( event: any ): void {
        let parsePhoneData;
        if( event && event === 'paste' ){
            const inputElement = this.$refs.phoneNumber as HTMLInputElement;
            const phoneValue = inputElement.value;
            const defaultCountry = phoneValue.startsWith('8') ? 'RU' : undefined;

            parsePhoneData = parsePhoneNumberFromString(phoneValue, defaultCountry);
        }else{
            parsePhoneData = parsePhoneNumberFromString( this.localPhoneNumber );
        }

        if( parsePhoneData && parsePhoneData.country ){
            const lCountries = [...countries];
            const idxPrevCountry = lCountries.findIndex(innerArr => innerArr.includes( parsePhoneData.country!.toLocaleLowerCase() ));
            this.localPhoneNumber = parsePhoneData.number.slice( lCountries[idxPrevCountry][2].length + 1);
            this.currentCode = countries[idxPrevCountry][2];
        }else{
            this.localPhoneNumber = this.localPhoneNumber.replace('+', '');
        }
    }

    created(): void {
        this.localPhoneNumber = this.phoneNumber;
        this.formatPhoneNumber( null );
    }
    mounted(): void {
        document.addEventListener('click', this.handleOutsideClick);
    }
    beforeDestroy(): void {
        document.removeEventListener('click', this.handleOutsideClick);
    }

    updatePhoneNumber(): void {
        this.$emit('input', {
            "number": "+" + this.currentCode + this.localPhoneNumber,
            "valid": !!this.parsePhoneData,
        });
    }

    handleOutsideClick(event: MouseEvent): void {
        const target = event.target as Node;
        const dropdown = this.$refs.dropdown as HTMLElement;

        if (this.isDropdownOpen && dropdown && !dropdown.contains(target)) {
            this.isDropdownOpen = false;
        }
    }

    filterCountries(): void {
        const query = this.searchQuery.replace('+', '').toLowerCase();
        this.filteredCountries = countries.filter(country =>
            country[0].toLowerCase().includes(query) ||
            country[2].startsWith(query),
        );
    }

    toggleDropdown(): void{
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    restrictKeyPhone(event: KeyboardEvent): any {
        const key = event.key;

        if (event.ctrlKey || event.metaKey) {
            return;
        }

        if (
            !/[\d+]/.test(key) &&
            key !== 'Backspace' &&
            key !== 'Delete' &&
            key !== 'ArrowLeft' &&
            key !== 'ArrowRight'
        ) {
            event.preventDefault();
        }
    }
}
