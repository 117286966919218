
import { Component, Mixins } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import Filters from '@/components/base/filters/Filters.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import FormModal from '@/components/base/FormModal.vue';
import { IOfferAccess, IUapiAffiliate } from '@/api/types/uapi';
import {
    getAccessList, addNewAccess, getOffersList, getAffiliatesList, deleteAccessIds,
} from '@/api/uapi';
import { showNotification, showServerError } from '@/utils';
import { MAX_LIMIT_FOR_SELECT, PAGINATION_PER_PAGE, SEARCH_VALUE_DEBOUNCE } from '@/configs/global';
import SortTableMixin from '@/mixins/sortTableMixin';
import { eventBus } from '@/eventbus';
import { GetInfo } from '@/utils/getInfo';
import { getOffersById } from '@/api/offers';
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

interface IField {
    id: string;
    label: string;
    placeholder: string;
    items: any[];
    apiMethod: any;
    itemText: string;
    loader: boolean;
    autocompleteValue?: string;
}

@Component({
    components: {
        Filters,
        SvgApplication,
        FormModal,
    },
})
export default class Access extends Mixins(SortTableMixin) {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    offersNames: Array<{id: number, name: string}> = [];
    affiliatesNames: IUapiAffiliate[] = [];
    dataTable: IOfferAccess[] = [];
    totalElementDataTable = 0;
    limit = PAGINATION_PER_PAGE || 25;
    deletingSelected: boolean = false;
    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'offer_id',
            label: 'ID или название офферов',
            apiMethod: getOffersList,
            placeholder: 'Выберите ID или название оффера',
            itemValue: 'id',
            itemText: 'offer_name',
            select: [],
            items: [],
            multiple: true,
        }),
        new filterClass.Autocomplete({
            id: 'affiliate_id',
            label: 'ID или имя партнёров',
            placeholder: 'Выберите ID или имя партнёра',
            apiMethod: getAffiliatesList,
            itemValue: 'id',
            itemText: 'name',
            items: [],
            multiple: true,
        }),
    ];
    headers = [
        {
            value: 'offer_id',
            text: 'ID оффера',
            align: 'center',
        },
        {
            value: 'offer_name',
            text: 'Название оффера',
            sortable: false,
        },
        {
            value: 'affiliate_id',
            text: 'ID партнёра',
            align: 'center',
        },
        {
            value: 'affiliate_name',
            text: 'Имя партнёра',
            sortable: false,
        },
        {
            value: 'created_at',
            text: 'Дата создания',
        },
        {
            text: 'Пользователь',
            value: 'created_by',
            align: 'center',
            type: 'date',
        },
    ];
    // for autocomplete in create access
    autocompleteFields: IField[] = [
        {
            id: 'offer_id',
            label: 'ID или название оффера',
            placeholder: 'Выберите ID или название оффера',
            items: [],
            apiMethod: getOffersList,
            itemText: 'offer_name',
            loader: false,
        },
        {
            id: 'affiliate_id',
            label: 'ID партнёра',
            placeholder: 'Выберите ID партнёра',
            items: [],
            apiMethod: getAffiliatesList,
            itemText: 'name',
            loader: false,
        },
    ];
    selectAllItems = false;
    searchTimerId = 0;
    newAccess: {
        offer_id?: number[];
        affiliate_id?: number[];
    } = {};
    // для модалки с удалением доступа
    deletingAccess: { id: number } = {
        id: 0,
    };
    selectedIds: number[] = [];
    uniqueData = [
        {
            slot: 'item.offer_name',
            template: (): void => undefined,
        },
        {
            slot: 'item.affiliate_name',
            template: (): void => undefined,
        },
    ];
    newAccessModal = false;
    settings = {
        title: 'Доступы',
        loading: false,
    };

    getTemplateForOffer(item: IOfferAccess): string {
        const offerName = this.offersNames.find(({ id }) => id === item.offer_id);
        if (offerName) {
            return `${offerName.name} (${item.offer_id})`;
        }
        return '-';
    }

    getTemplateForAffiliate(item: IOfferAccess): string {
        const affiliateName = this.affiliatesNames.find(({ id }) => id === item.affiliate_id);
        if (affiliateName) {
            return affiliateName.name;
        }
        return '-';
    }

    created(): void {
        // используется для сортировки
        // нужен в sortTableMixins
        this.field = 'created_at';
        this.autocompleteFields.forEach((field) => {
            // костыль. Нужен, чтобы в автокомплите не отображалось
            // "Ничего не найдено", если еще ничего не начали писать
            // @ts-ignore
            field.autocompleteValue = '';
        });
        this.setDefaultFiltersParamsObject({});
        this.setFiltersParamsObject({});
    }

    async getDataTable(offset: number, limit: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        this.settings.loading = true;
        const paramsObj = { ...this.getFiltersParamsObject, ...this.getSortFilters };
        try {
            const { meta, data } = await getAccessList({ offset, limit, ...paramsObj });
            this.dataTable = data;
            this.totalElementDataTable = meta!.total!;
            this.offersNames = await new GetInfo(data, 'offer_id', getOffersById).getInfoByFields();
            this.affiliatesNames = await new GetInfo(data, 'affiliate_id', getAffiliatesList).getInfoByFields();
        } catch (err) {
            showServerError(err);
        }
        this.settings.loading = false;
    }

    async addNewAccess(): Promise<void> {
        try {
            if (this.newAccess.offer_id && this.newAccess.affiliate_id) {
                await addNewAccess(this.newAccess);
                await this.getDataTable(0, this.limit);
                this.newAccessModal = false;
                this.newAccess = {};
            }
        } catch (err) {
            showServerError(err);
        }
    }

    selectAll(e: any): void {
        this.selectAllItems = !this.selectAllItems;
        if (this.selectAllItems) {
            this.selectedIds = e.items
                .filter((item: { id: number; }) => this.dataTable.some((dataTableItem: {
                    id: number;
                }) => dataTableItem.id === item.id))
                .map((item: { id: number; }) => item.id);
        } else {
            this.selectedIds = [];
        }
    }

    selectItem(items: any): void {
        if (this.totalElementDataTable === 1) {
            this.selectAllItems = !this.selectAllItems;
        }
        this.selectedIds = items
            .filter((item: { id: number; }) => this.dataTable.some((dataTableItem: { id: number; }) => dataTableItem.id === item.id))
            .map((item: { id: number; }) => item.id);
    }

    async deleteSelectedAccesses(): Promise<void> {
        try {

            await deleteAccessIds(this.selectedIds);
            this.dataTable = this.dataTable.filter(item => !this.selectedIds.includes(item.id));
            this.$set(this, 'selectedIds', [] );
            this.deletingSelected = false;
            this.totalElementDataTable = this.dataTable.length;
            showNotification('Доступы успешно удален');
        } catch (err) {
            showServerError(err, 'Доступы не могут быть удалены');
        }
    }

    // отсроченные запросы на получение данных для селекта
    debounceQuerySelections(field: IField): void {
        if (field.autocompleteValue) {
            clearTimeout(this.searchTimerId);
            this.searchTimerId = window.setTimeout(() => {
                this.getDataForSelect(field);
            }, SEARCH_VALUE_DEBOUNCE); /* 850ms задержка */
        }
    }

    // запросы на получение данных для селекта
    async getDataForSelect(field: IField): Promise<void> {
        field.loader = true;
        try {
            // .replace необходим, тк при разной вставке id в поле размер пробела может быть разным
            const splittingValue = field.autocompleteValue!
                .trim()
                .replace(/\s/g, ' ')
                .split(' ');
            const params = {
                [splittingValue.length > 1 ? 'id' : 'query']: splittingValue.length > 1
                    ? splittingValue : field.autocompleteValue!,
                limit: MAX_LIMIT_FOR_SELECT,
            };
            const { data: result } = await field.apiMethod(params);
            if (!field.items) {
                this.$set(field, 'items', []);
            }

            result.forEach((item) => {
                field.items.push({
                    id: item.id,
                    [field.itemText]: `${item.id} ${item[field.itemText]}`,
                });

                if (splittingValue.length > 1) {
                    if (!this.newAccess[field.id]) {
                        this.newAccess[field.id] = [];
                    }
                    if (!this.newAccess[field.id].includes(item.id)) {
                        this.newAccess[field.id].push(item.id);
                    }
                    field.autocompleteValue = '';
                }
            });
            field.loader = false;
        } catch (err) {
            field.loader = false;
            showServerError(err, 'Список не загружен');
        }
    }
}
