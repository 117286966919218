
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SurveyQuestion } from "@/services/surveys/SurveyQuestion";

@Component({
    components: {
    },
})

export default class SurveyAffiliateTable extends Vue {
    @Prop({ required: true }) items!: SurveyQuestion[];
}
