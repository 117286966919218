
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import difference from "lodash-es/difference";
import intersection from "lodash-es/intersection";
import { eventBus } from "@/eventbus";
import { IColumnFilter } from "@/types";
import MultiselectDesign from "@/components/base/form/MultiselectDesign.vue";

@Component({
    components: { MultiselectDesign, TrashBtn },
})
export default class ColumnsCheckboxes extends Vue {
    // выбранные чекбоксы
    @PropSync('selected', { required: true }) selectedSync!: any;
    @Prop({ default: '' }) readonly title!: string;
    @Prop({ default: 'Сбросить' }) readonly trashText!: string;
    @Prop({ default: true }) readonly isTrashBtn!: boolean;
    @Prop({ default: false }) readonly isTrashBtnImportant!: boolean;
    @Prop({ default: false }) readonly isTitleCheckbox!: boolean;
    @Prop({ default: false }) readonly isHideColTitle!: boolean;
    @Prop({ default: () => [], required: true }) readonly checkboxes!: IColumnFilter[];

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 576;
    }

    getSelect(selectItem: { filter: string[] }): string[] {
        let arr: string[] = [];
        selectItem.filter.forEach(i => {
            arr = [...arr, ...this.selectedSync.filter(j => j.includes(i))];
        });
        return arr;
    }

    changeSelect(subs: any): void {
        const selectItem = this.checkboxes.find(i => i.key === subs.type);
        const selected = this.getSelect(selectItem as { filter: string[] });
        const selectWithOutSubs = difference(this.selectedSync, selected);
        this.selectedSync = [...selectWithOutSubs, ...subs.items];
    }

    clearSelectedItems(): void {
        const items = this.checkboxes.map(i => i.items.map(j => j.value)).reduce((acc, it) => [...acc, ...it], []);
        this.selectedSync = this.selectedSync.filter(i => !items.includes(i) && i !== 'dm_offer_currency');
        this.$emit('trash');
    }

    toggleSectionCheckboxes(column: any): void {
        const e = column.items.map(i => i.value);
        if (column.value && column.hasOwnProperty('value')) {
            this.selectedSync = [...new Set([...this.selectedSync, ...e])];
        } else {
            this.selectedSync = difference(this.selectedSync, e);
        }
    }

    toggleTitleCheckbox(column: any): void {
        if (this.isTitleCheckbox && column.hasOwnProperty('value')) {
            const e = column.items.map(i => i.value);
            // проверяем после того как обновился this.selectedSync, сработал v-model на чекбоксе
            this.$nextTick(() => {
                column.value = intersection(this.selectedSync, e).length >= e.length;
            });
        }
    }

    clearDisabled(): void {
        this.checkboxes.forEach((item) => item.items.forEach((el) => el.disabled = false));
    }

    mounted(): void {
        eventBus.$on('click-nav-drawer', this.clearDisabled);
        if (this.isTitleCheckbox) {
            this.checkboxes.forEach(i => this.toggleTitleCheckbox(i));
        }
    }

    beforeDestroy(): void {
        eventBus.$off('click-nav-drawer', this.clearDisabled);
        this.clearDisabled();
    }
}
