
import { Component, Mixins, Prop } from 'vue-property-decorator';
import FiltersBaseDesign from '@/components/base/filters/FiltersBaseDesign.vue';
import BaseCard from "@/components/base/BaseCard.vue";
import FiltersMixin from '@/mixins/filters';
import { IFilter } from "@/types";

@Component({
    components: { BaseCard, FiltersBaseDesign },
})

export default class FiltersDesign extends Mixins(FiltersMixin) {
    @Prop({ type: Boolean, default: false }) readonly isTrashBtn!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isSuccessBtn!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isCancelBtn!: boolean;
    @Prop({ type: Array, default: () => [] }) readonly showColumnTitle!: string[];
    @Prop({ type: String, default: 'top' }) readonly tooltipPosition!: string;
    @Prop({ type: Boolean, default: true }) readonly isUsedBlurAutocomplete!: boolean;
    @Prop({ type: Number, default: 0 }) readonly byTab!: number;

    get getModalText(): string {
        const ids = this.notFoundIds.join(', ');
        return `Не найдены: ${ids}`;
    }

    get getFilters(): IFilter[] {
        if (this.byTab) {
            return this.classFilters.filters.filter(f => f.tab === this.byTab);
        } else {
            return this.classFilters.filters;
        }
    }
}
