import { MutationTree } from 'vuex';
import { ICatalogueState } from '@/store/modules/catalogue/types';

export const mutations: MutationTree<ICatalogueState> = {
    SET_COUNTRIES(state, countries) {
        state.countries = countries;
    },
    SET_CURRENCIES(state, currencies) {
        state.currencies = currencies;
    },
};
