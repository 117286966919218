import store from "@/store";
import { SHOW_NOTIFICATION_TIME } from '@/configs/global';

export interface INotificationOptions {
    closeText?: string;
}

export class NotificationMessage {
    protected text: string = '';
    protected type: string = 'success';

    constructor(
        text: string,
        type: string,
        timeout: number = SHOW_NOTIFICATION_TIME,
        options: INotificationOptions = {},
    ) {
        this.text = text;
        this.type = type;
        this.notify(text, type, timeout, options);
    }

    private notify(
        text: string,
        type: string,
        timeout: number,
        options: INotificationOptions,
    ) {
        store.commit('SET_NOTIFICATION', { text, type, ...options });

        if (timeout !== 0) {
            setTimeout(() => {
                store.commit('RESET_NOTIFICATION');
            }, timeout ?? SHOW_NOTIFICATION_TIME);
        }
    }
}
