
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import { showServerError } from "@/utils";
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import NotificationCreateHeader from "@/components/notifications/NotificationCreateHeader.vue";
import NotificationTable from "@/components/notifications/NotificationTable.vue";
import NotificationEditor from "@/components/notifications/NotificationEditor.vue";
import NotificationOfferRecipient from "@/components/notifications/NotificationOfferRecipient.vue";
import NotificationAffiliateRecipient from "@/components/notifications/NotificationAffiliateRecipient.vue";
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";
import SvgMagicWand from '@/assets/icons/magic-wand-light.svg';
import FormModal from "@/components/base/FormModal.vue";
import { IServerError, IServerResponse } from "@/api/types";
import EditBtn from "@/components/base/buttons/EditBtn.vue";

@Component({
    components: {
        EditBtn,
        FormModal,
        SvgMagicWand,
        FiltersDialog,
        NotificationEditor,
        NotificationTable,
        NotificationCreateHeader,
        BaseCard,
        NotificationAffiliateRecipient,
        NotificationOfferRecipient,
    },
})
export default class EditedNotification extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;
    @Prop({ default: false }) isEditing!: boolean;

    isShowModal = false;
    isShowFilters = false;
    isUnfoundedUsers = false;
    isDisableBtn = false;

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 1038;
    }

    get componentSelectRecipient(): any {
        return this.notification?.isOffer ? NotificationOfferRecipient : NotificationAffiliateRecipient;
    }

    get isPinned(): boolean {
        if (!this.notification.pinned_to && !this.notification.pinned_from) {
            return false;
        } else {
            return !(this.notification.pinned_to && this.notification.pinned_from);
        }
    }

    get isDisabledSaveBtn(): boolean {
        return this.isDisableBtn || !(this.notification?.event && this.notification?.published_at && (this.notification?.message_ru || this.notification?.message_en)) || this.isPinned || this.isUnfoundedUsers;
    }

    save(): void {
        // дизаблим после первого клика
        this.isUnfoundedUsers = true;
        this.isEditing ? this.editNotification() : this.createNotification();
    }

    showModal(): void {
        this.notification?.isFullAffiliates || this.notification?.isOffer || this.notification.id  ? this.save() : this.isShowModal = true;
    }

    async createNotification(): Promise<void> {
        try {
            this.isDisableBtn = true;
            await this.notification?.createNotification();
            this.isUnfoundedUsers = false;
            await this.$router.push({ name: 'notifications-list' });
        } catch (err) {
            this.showSaveError(err);
        } finally {
            this.isDisableBtn = false;
        }
    }

    showSaveError(err: IServerResponse<IServerError>): void {
        if (err.data?.error!.details?.users!) {
            this.isUnfoundedUsers = true;
            showServerError({} as IServerResponse<IServerError>, 'С выбранными офферами не работает ни один партнёр');
        } else {
            this.isUnfoundedUsers = false;
            showServerError(err, 'Уведомление не создано');
        }
    }

    async editNotification(): Promise<void> {
        try {
            await this.notification?.editNotification();
            this.$emit('toggle');
        } catch (err) {
            this.showSaveError(err);
        }
    }

    @Watch('notification.offers', { immediate: true, deep: true })
    changeOffers(): void {
        this.isUnfoundedUsers = false;
    }
}
