import Vue from 'vue';
import vuescroll from 'vuescroll';

// You can set global config here.
Vue.use(vuescroll, {
    ops: {
        rail: {
            opacity: '0',
            background: undefined,
            border: '1px solid transparent',
            size: '5px',
        },
        bar: {
            background: '#CED6E3',
            keepShow: false,
            size: '6px',
            minSize: 0.2,
        },
        scrollButton: {
            enable: false,
        },
        scrollPanel: {
            easing: 'easeInQuad',
            speed: 800,
        },
        vuescroll: {
            wheelScrollDuration: 0,
            wheelDirectionReverse: false,
            locking: true,
            checkShifKey: true,
        },
    },
});
