var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form-modal',{staticClass:"approval-create-form",attrs:{"show-modal":_vm.showModalSync,"max-width":"628","title":_vm.getTitle,"need-hide-btn":false,"need-accept-btn":false,"closable":true},on:{"close-modal":_vm.closeModal},scopedSlots:_vm._u([{key:"custom-btn",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 justify-space-between approval-create-form__create-btn"},[_c('v-btn',{staticClass:"button button--blue",attrs:{"disabled":_vm.disabledCreateBtn},on:{"click":_vm.createNewRule}},[_c('SvgMagicWandLight',{staticClass:"mr-2"}),_c('span',[_vm._v(_vm._s(_vm.mode === 'create' ? 'Создать' : 'Сохранить'))])],1),_c('v-btn',{staticClass:"button button--blue",on:{"click":_vm.addPartner}},[_c('span',[_vm._v("Добавить")])])],1)]},proxy:true}])},[[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"approval-create-form__item-label my-2 mr-5"},[_vm._v(" Партнёр ")]),(_vm.editingDataSync === null)?_c('base-autocomplete',{ref:"affiliateInput",staticClass:"approval-create-form__item-input",staticStyle:{"width":"70%"},attrs:{"data":{
                    errorText: 'Партнер не найден',
                    apiMethod: _vm.getAffiliateSearch,
                    itemValue: 'id',
                    template: (item) => `${item.id} ${item.first_name} ${item.last_name}`,
                    placeholder: 'Введите ID или имя партнёра',
                }},on:{"change":_vm.updateAffiliates}}):_c('p',{staticClass:"approval-create-form__item-label my-2 mr-5"},[_vm._v(" "+_vm._s(_vm.addedAffiliate)+" ")])],1),(_vm.partners.length > 0)?_c('v-data-table',{staticClass:"partners-table pt-4",attrs:{"headers":_vm.headers,"items":_vm.partners,"item-key":"affiliate_id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.affiliate_id",fn:function({ item, index }){return [_c('base-autocomplete',{staticClass:"approval-create-form__item-input",attrs:{"default-search":item.affiliate_id,"data":{
                        errorText: 'Партнер не найден',
                        apiMethod: _vm.getAffiliateSearch,
                        itemValue: 'id',
                        template: (el) => `${el.id} ${el.first_name} ${el.last_name}`,
                        placeholder: 'Введите ID партнёра',
                    }},on:{"change":function($event){return _vm.updateAffiliate($event, index)}}})]}},{key:"item.percent",fn:function({ item }){return [_c('v-text-field',{staticClass:"ma-0",attrs:{"type":"number","min":"0","max":"100"},on:{"input":_vm.checkPercentSum},model:{value:(item.percent),callback:function ($$v) {_vm.$set(item, "percent", _vm._n($$v))},expression:"item.percent"}})]}},{key:"item.actions",fn:function({ index }){return [_c('v-icon',{staticClass:"charge-icon",on:{"click":function($event){return _vm.removePartner(index)}}},[_vm._v(" mdi-close ")])]}}],null,false,3645499940)}):_vm._e(),(_vm.showError)?_c('div',{staticClass:"error-message"},[_vm._v(" Сумма процентов должна быть равна 100. ")]):_vm._e(),(_vm.isLoading)?_c('loader'):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }