
import { Component, Vue } from 'vue-property-decorator';
import FinanceInvoices from '@/components/orgstats/FinanceInvoices.vue';
import { getMasterAccountFinanceInvoices } from '@/api/org-cabinets-backend';

@Component({
    components: {
        FinanceInvoices,
    },
})
export default class MasterAccountFinanceInvoices extends Vue {
    apiMethod = getMasterAccountFinanceInvoices;
}
