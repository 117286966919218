
import { Component, Mixins } from 'vue-property-decorator';
import { IApplicationsList, ICountry } from '@/api/types/uapi';
import { IFilter } from '@/types';
import { getCurrentDate } from '@/utils/formatDates';
import {
    getApplications, getCountries, getStreams, getApplicationsCsv, getFields, getCities, getRegions,
} from '@/api/uapi';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { Getter, Mutation, namespace } from 'vuex-class';
import { showServerError } from '@/utils';
import FormModal from '@/components/base/FormModal.vue';
import SortTableMixin from '@/mixins/sortTableMixin';
import Filters from '@/components/base/filters/Filters.vue';
import { eventBus } from '@/eventbus';
import Multiselect from "@/components/base/form/Multiselect.vue";
import omit from "lodash-es/omit";
import { filterClass } from '@/services/filters/filterClass';

const auth = namespace('authModule');

@Component({
    components: {
        Multiselect,
        Filters,
        SvgApplication,
        FormModal,
    },
})
export default class Applications extends Mixins(SortTableMixin) {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    @auth.Getter('GET_USER') user;

    currentDate = getCurrentDate('yyyy-MM-dd');
    dataTable: IApplicationsList[] = [];
    totalElementDataTable = 0;
    loadingDownload = false;
    showModal = false;
    choosingFields = false;
    canExport = true;
    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'offer_id',
            label: 'ID оффера',
            select: [],
            supValue: '',
        }),
        new filterClass.ComboboxArray({
            id: 'UUID',
            label: 'UUID заявки',
            select: [],
            supValue: '',
        }),
        new filterClass.ComboboxArray({
            id: 'affiliate_id',
            label: 'ID партнера',
            select: [],
            supValue: '',
        }),
        new filterClass.ComboboxArray({
            id: 'first_name',
            label: 'Имя',
            supValue: '',
            select: [],
        }),
        new filterClass.ComboboxArray({
            id: 'last_name',
            label: 'Фамилия',
            supValue: '',
            select: [],
        }),
        new filterClass.ComboboxArray({
            id: 'middle_name',
            label: 'Отчество',
            supValue: '',
            select: [],
        }),
        new filterClass.Multiselect({
            id: 'country',
            label: 'Страна',
            select: [],
            items: [],
            noDataText: 'Нет выбранной страны',
            itemValue: 'id',
            template: (item: ICountry): string => item.name,
        }),
        new filterClass.Autocomplete({
            id: 'region',
            label: 'Регион',
            select: [],
            apiMethod: getRegions,
            autocomplete: true,
            itemValue: 'id',
            itemText: 'name',
            key:'q',
            items: [],
            template: (item: any): string => `${item.id} ${item.name}`,
            multiple: true,
            multiPaste: true,
        }),
        new filterClass.Autocomplete({
            id: 'city',
            apiMethod: getCities,
            autocomplete: true,
            label: 'Город',
            itemValue: 'id',
            itemText: 'name',
            key:'q',
            items: [],
            template: (item: any): string => `${item.id} ${item.name}`,
            multiple: true,
            multiPaste: true,
        }),
        new filterClass.ComboboxArray({
            id: 'phone',
            label: 'Телефон',
            select: [],
            supValue: '',
        }),
        new filterClass.Autocomplete({
            id: 'stream',
            label: 'Поток',
            apiMethod: getStreams,
            itemValue: 'id',
            itemText: 'name',
            select: [],
            multiple: true,
        }),
        new filterClass.ComboboxArray({
            id: 'passport',
            label: 'Паспорт',
            select: [],
            supValue: '',
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Период',
            pickerType: 'date',
            range: true,
            select: [this.currentDate],
            defaultValue: [this.currentDate],
        }),
    ];

    settings = {
        title: 'Заявки',
        loading: true,
    };

    headers = [
        {
            text: 'UUID',
            value: 'uuid',
            sortable: false,
        },
        {
            text: 'ID партнера',
            value: 'affiliate_id',
            sortable: false,
        },
        {
            text: 'Телефон',
            value: 'phone',
            width: '20%',
            sortable: false,
        },
        {
            text: 'Дата создания',
            value: 'created_at',
        },
    ];

    // для отображения роутов в таблице
    routes = [
        {
            routeParam: 'uuid',
            slot: 'item.uuid',
            routerName: 'show_application',
            text: 'uuid',
        },
    ];

    availableFields: object = {
        "c.first_name": "Имя клиента-заявителя",
        "c.last_name": "Фамилия клиента-заявителя",
        "c.patronymic": "Отчество клиента-заявителя",
        "c.phone": "Номер мобильного телефона клиента c кодом оператора",
        "c.passport": "Номер паспорта",
        "c.passport_issue_date": "Дата выдачи паспорта",
        "c.passport_issued_by": "Кем выдан паспорт",
        "c.passport_unit_code": "Код подразделения",
        "c.birth_date": "Дата рождения",
        "c.email": "Адрес e-mail клиента",
        "addr.city_raw": "Город проживания",
    };
    fields: any[] = [];
    defaultFields: any[] = [];
    selectedFields: any = [];

    created(): void {
        // используется для сортировки
        // нужен в sortTableMixins
        this.field = 'created_at';
        this.description = true;
        const activeFilters = {};
        this.filters.forEach((fl) => {
            if (fl.select) {
                if (fl.array) {
                    if (fl.select.length) {
                        activeFilters[fl.id] = fl.select;
                    }
                } else if (fl.datepicker) {
                    activeFilters[`${fl.id}_from`] = fl.select[0];
                    activeFilters[`${fl.id}_to`] = fl.select[0];
                } else {
                    activeFilters[fl.id] = fl.select;
                }
            }
        });
        const paramsObj = { ...activeFilters };
        this.setDefaultFiltersParamObject(paramsObj);
        this.setFiltersParamObject(paramsObj);
        this.getCountriesForFilters();
        this.getFieldsForExport();
    }

    getTemplate(item: any): string {
        return item.value;
    }

    get allFields(): boolean {
        return this.selectedFields.length === this.fields.length;
    }

    async getDataTable(offset?: number, limit?: number, isChangeLimit?: boolean): Promise<void> {
        this.canExport = true;
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        const paramsObj = { ...this.getFiltersParamsObject, ...this.getSortFilters };
        this.settings.loading = true;
        try {
            const { meta, data } = await getApplications({ offset, limit, ...paramsObj });
            this.dataTable = data;
            this.totalElementDataTable = meta!.total || data.length;
        } catch (err) {
            showServerError(err, 'Список заявок не загружен');
        }
        this.settings.loading = false;
    }

    changeSelect(data: any): void {
        this.selectedFields = data.items;
    }

    async getCountriesForFilters(): Promise<void> {
        try {
            const { data } = await getCountries();
            const countryFilter = this.filters.find((filter) => filter.id === 'country');
            if (countryFilter) {
                countryFilter.items = data;
            }
        } catch (err) {
            showServerError(err, 'Список стран не загружен');
        }
    }

    async getFieldsForExport(): Promise<void> {
        try {
            const { data } = await getFields();
            this.fields = Object.keys(data).map(key => ({ [key]: data[key] })).map((item) => {
                return { id : Object.keys(item)[0], value : Object.values(item)[0] };
            });
            this.defaultFields = Object.keys(this.availableFields).map(key => ({ [key]: this.availableFields[key] })).map((item) => {
                return { id : Object.keys(item)[0], value : Object.values(item)[0] };
            });
        } catch (err) {
            showServerError(err, 'Список полей для выгрузки не загружен');
        }
        this.changeSelect({ items: this.defaultFields.map((item) => item.id) });
    }

    async downloadApplications(): Promise<void> {
        this.loadingDownload = true;
        try {
            if (!this.allFields) {
                this.setFiltersParamObject({ ...this.getFiltersParamObject, select: this.selectedFields });
            } else {
                this.setFiltersParamObject({ ...omit(this.getFiltersParamObject, 'select') });
            }
            await getApplicationsCsv(this.getFiltersParamObject);
            this.showModal = true;
        } catch (err) {
            showServerError(err, 'Не удалось получить выгрузку по заявкам');
        }
        this.canExport = false;
        this.loadingDownload = false;
        this.choosingFields = false;
    }

    beforeDestroy(): void {
        this.setFiltersParamsObject({});
        this.setDefaultFiltersParamsObject({});
    }

    clearFilters(): void {
        this.getDataTable(0, this.limit);
    }
}
