
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import SvgCheck from '@/assets/icons/check.svg';
import SaveBtnTooltip from "@/components/base/buttons/SaveBtnTooltip.vue";
import { ICreatePersonalRate, IPersonalRates } from "@/api/types/offers";
import { Mutation } from "vuex-class";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";

@Component({
    components: { EditModeTextField, SaveBtnTooltip, SvgCheck },
})

export default class BulkEditing extends Vue {
    @PropSync('editedItems', { type: Array }) editedItemsSync!: ICreatePersonalRate[];
    @PropSync('items', { type: Array }) itemsSync!: IPersonalRates[];

    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    menu = false;
    editedRate: {revenue: number | null, payout: number | null, percent: number | null, isDefaultRevenue: boolean} = {
        revenue: null,
        payout: null,
        percent: null,
        isDefaultRevenue: false,
    };

    isSubmit = false;

    get isDisabledPercent(): boolean {
        return !!this.editedRate.payout;
    }

    get isDisabledPayout(): boolean {
        return !!this.editedRate.percent;
    }

    countPayout(): void {
        if (this.editedRate.percent && this.editedRate.percent > 100) {
            this.$set(this.editedRate, 'percent', 100);
        } else if (this.editedRate.percent && this.editedRate.percent < 0) {
            this.$set(this.editedRate, 'percent', null);
        }
    }

    openMenu(): void {
        this.menu = true;
        this.isSubmit = false;
        this.editedRate = {
            revenue: null,
            payout: null,
            percent: null,
            isDefaultRevenue: false,
        };
    }
    @Watch('menu')
    toggleScrollButtonVisibility(): void {
        const buttonDiv = document.querySelector('.extended-report-table__scroll-btns');
        (buttonDiv as HTMLElement).style.display  = this.menu ? 'none' : 'flex';
    }

    editRate(): void {
        this.editAllRates();
        this.isSubmit = true;
        this.menu = false;
    }

    calcPayout(value: any, newValue: any): number {
        return newValue.percent ?
            (value.personal_rates.revenue.amount * newValue.percent) / 100 :
            newValue.revenue && !newValue.payout && value.personal_rate_payout_percent ?
                (+newValue.revenue * +value.personal_rate_payout_percent) / 100 :
                +newValue.payout! || value.personal_rates.payout.amount;
    }

    editAllRates(): void {
        const rate = this.editedRate;
        this.editedItemsSync = [];
        this.itemsSync.forEach( i => {
            // если выплата в процентах, тогда не применяем массовое редактирование
            if (i.personal_rates.payout.type !== 'fixed') return;

            i.personal_rates.revenue.amount = rate.isDefaultRevenue ? i.goal.revenue.amount : +rate.revenue! || i.personal_rates.revenue.amount;
            i.personal_rates.payout.amount = this.calcPayout(i, rate);
            if (i.personal_rates.payout.amount > i.personal_rates.revenue.amount) {
                i.personal_rate_payout_percent = 100;
                i.personal_rates.payout.amount = i.personal_rates.revenue.amount;
            } else {
                i.personal_rate_payout_percent = rate.payout! ? (i.personal_rates.payout.amount / i.personal_rates.revenue.amount * 100).toFixed(1) :
                    rate.percent || i.personal_rate_payout_percent;
            }
            this.editedItemsSync.push({
                affiliate_id: i.affiliate.legacy_id,
                goal_id: i.goal.legacy_id,
                offer_id: i.offer.legacy_id,
                id: i.personal_rates.id,
                revenue: i.personal_rates.revenue.amount,
                payout: i.personal_rates.payout.amount,
                start_date: i.personal_rates.action_date,
            });
        });
        this.$emit('update:editedItems', this.editedItemsSync);
        this.setUnsavedDataState(true);
    }

    changeCheckbox(): void {
        this.editedRate.revenue =  null;
    }
}
