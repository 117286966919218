import AffiliatesList from "@/views/affiliates/AffiliatesList.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import AffiliateSingleWrap from "@/views/affiliates/AffiliateSingleWrap.vue";
import MasterAccountList from "@/views/affiliates/MasterAccountList.vue";
import BufferAccounts from "@/views/affiliates/BufferAccounts.vue";
import Registrations from "@/views/affiliates/Registrations.vue";
import ChangeMethodRequests from "@/views/affiliates/affiliateMethods/List.vue";
import ChangeMethodRequest from "@/views/affiliates/affiliateMethods/Single.vue";
import Payments from "@/views/affiliates/payments/Payments.vue";
import Payment from "@/views/affiliates/payments/Payment.vue";
import CreatePayment from "@/views/affiliates/payments/CreatePayment.vue";
import AffiliateInvoices from "@/views/affiliates/invoices/AffiliateInvoices.vue";
import AffiliateInvoice from "@/views/affiliates/invoices/AffiliateInvoice.vue";
import ChargeRequest from "@/views/affiliates/chargeRequest/ChargeRequest.vue";
import ChargeRequests from "@/views/affiliates/chargeRequest/ChargeRequests.vue";
import CreateAffiliatePage from "@/views/affiliates/CreateAffiliatePage.vue";

export default [
    {
        path: 'all',
        name: 'affiliates-list',
        component: AffiliatesList,
        meta: {
            title: 'Список партнёров',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'affiliate/:id',
        name: 'affiliate',
        component: AffiliateSingleWrap,
        meta: {
            title: 'Детальная страница партнера',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'create-affiliate',
        name: 'create-affiliate',
        component: CreateAffiliatePage,
        meta: {
            title: 'Создание партнёра',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'masters-accounts',
        name: 'masters-accounts',
        meta: {
            title: 'Мастер-аккаунты',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR],
        },
        component: MasterAccountList,
    },
    {
        path: 'buffer-accounts',
        name: 'buffer-accounts',
        meta: {
            title: 'Буферные аккаунты',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
        component: BufferAccounts,
    },
    {
        path: 'registrations',
        name: 'registrations',
        component: Registrations,
        meta: {
            title: 'Список регистраций',
            requiresAuth: true,
            roles: [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR, USER_ROLES.FINANCIER, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'change-method-requests',
        name: 'change-method-requests',
        meta: {
            title: 'Запросы на изменение способа выплат',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER,
            ],
        },
        component: ChangeMethodRequests,
    },
    {
        path: 'change-method-request/:id',
        name: 'change-method-request',
        props: true,
        meta: {
            title: 'Запрос на изменение способа выплат',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER,
            ],
        },
        component: ChangeMethodRequest,
    },
    {
        path: 'payments',
        name: 'payments',
        meta: {
            title: 'Список досрочек',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT,
            ],
        },
        component: Payments,
    },
    {
        path: 'payment/:id',
        name: 'payment',
        meta: {
            title: 'Досрочка',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT,
            ],
        },
        component: Payment,
    },
    {
        path: 'create-payment',
        name: 'create-payment',
        meta: {
            title: 'Создание досрочки',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT,
            ],
        },
        component: CreatePayment,
    },
    {
        path: 'affiliate-invoices',
        name: 'affiliate-invoices',
        meta: {
            title: 'Партнёрские счета',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
        component: AffiliateInvoices,
    },
    {
        path: 'affiliate-invoice/:id',
        name: 'affiliate-invoice',
        meta: {
            title: 'Партнерский счет',
            requiresAuth: true,
            roles: [
                USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT, USER_ROLES.STAT_MANAGER, USER_ROLES.API_OPERATOR,
            ],
        },
        component: AffiliateInvoice,
    },
    {
        path: 'charge-requests',
        name: 'charge-requests',
        meta: {
            title: 'Заявки на расход',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT,
            ],
        },
        component: ChargeRequests,
    },
    {
        path: 'charge-requests/:id',
        name: 'charge-request',
        component: ChargeRequest,
        meta: {
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.SUPPORT,
            ],
        },
    },
];
