
import { Component, Prop, Vue } from "vue-property-decorator";
import { IApprovalInfoCard } from "@/api/types/offers";
import BaseCard from "@/components/base/BaseCard.vue";
import { translateApprovalConfirmations, translateOffersStatus, translatePartnerStatus } from "@/utils/translate";
import Status from "@/components/base/Status.vue";
import { IStatus } from "@/types";

@Component({
    components: {
        Status,
        BaseCard,
    },
})

export default class ApprovalPageInfo extends Vue {
    @Prop ({ required: true }) approval!: IApprovalInfoCard[];

    translateConfirmation(status: string | number): IStatus {
        return translateApprovalConfirmations(!!status);
    }

    translateStatus(status: string | number, isOffer: boolean): IStatus {
        status = status.toString();
        return isOffer ? translateOffersStatus(status) : translatePartnerStatus(status);
    }
}
