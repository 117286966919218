
import { Component, Provide, Vue } from "vue-property-decorator";
import { showServerError } from "@/utils";
import { getOneEmployeeMethod } from "@/api/payout";
import PageLayout from "@/components/layout/PageLayout.vue";
import EmployeeMethodFields from "@/components/administration/employeesMethods/EmployeeMethodFields.vue";
import EmployeeMethodLimits from "@/components/administration/employeesMethods/EmployeeMethodLimits.vue";
import EmployeeMethodCommissions from "@/components/administration/employeesMethods/EmployeeMethodCommissions.vue";
import EmployeeMethodAbout from "@/components/administration/employeesMethods/EmployeeMethodAbout.vue";
import EmployeeMethodLogos from "@/components/administration/employeesMethods/EmployeeMethodLogos.vue";
import { EmployeeMethod } from "@/services/administration/EmployeeMethod";

@Component({
    components: {
        PageLayout,
        EmployeeMethodAbout,
        EmployeeMethodCommissions,
        EmployeeMethodFields,
        EmployeeMethodLimits,
        EmployeeMethodLogos,
    },
})

export default class EmployeeMethodSingleWrap extends Vue {
    isLoaded: boolean = false;
    employeeMethod = {} as EmployeeMethod;

    @Provide()
    translateCurrency(item: string): string {
        if (item === 'anything') {
            return 'Остальные';
        } else return item;
    }

    get getTitle(): string {
        return this.isLoaded ? this.employeeMethod.name.ru! : '';
    }

    get employeeMethodID(): string {
        return this.$route.params.id;
    }

    async created(): Promise<void> {
        await this.getEmployeeMethod();
        document.title = this.getTitle;
    }

    @Provide()
    async getEmployeeMethod(): Promise<void> {
        try {
            const data = await getOneEmployeeMethod(this.employeeMethodID);
            this.isLoaded = true;
            this.employeeMethod = new EmployeeMethod(data);
        } catch (err) {
            showServerError(err, 'Способ оплаты не найден');
        }

    }
}

