
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormModal from '@/components/base/FormModal.vue';
import { showServerError } from "@/utils";
import { patchMyLeadgidInvoiceStatus } from '@/api/payout';
import { NotificationMessage } from '@/utils/notification';
import { ISingleMyLeadgidInvoice } from '@/api/types/payout';

@Component({
    components: {
        FormModal,
    },
})
export default class FormUpdatePaidStatus extends Vue {
    @Prop({ default: () => ({}) }) invoice!: ISingleMyLeadgidInvoice;

    get isShow(): boolean {
        return !!this.invoice;
    }

    get isPaid(): boolean {
        return this.invoice?.is_paid;
    }

    get title(): string {
        return this.isPaid ? 'Отменить оплату счёта' : 'Оплатить счёт';
    }

    hideModal(): void {
        this.$emit('hide');
    }

    async changeStatus(): Promise<void> {
        const status = this.isPaid ? 'unpaid' : 'paid';
        try {
            await patchMyLeadgidInvoiceStatus(this.$route.params.id, { status });
            this.showNotification();
            this.$emit('hide-and-reload');
        } catch (err) {
            showServerError(err, 'Статус не изменен');
        }
    }

    showNotification(): void {
        const notificationText = this.isPaid ? `не оплачен` : 'оплачен';
        new NotificationMessage(`Счёт ${this.$route.params.id} ${notificationText}`, 'success' );
    }
}
