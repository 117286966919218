
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ITableFooterButton, ITableStatus, TableHeader } from "@/types";
import { Affiliate } from "@/services/affiliates/Affiliate";
import ItemTableList from "@/components/base/TableUI/ItemTableList.vue";
import SvgStar from '@/assets/icons/star.svg';
import SvgTrue from '@/assets/icons/true-icon.svg';
import SvgFalse from '@/assets/icons/false-icon.svg';
import { showNotification, showServerError } from "@/utils";
import { translatePartnerStatus } from "@/utils/translate";
import {
    batchAssignManager,
    getAffiliateLoginURL,
    getAffiliateSearch, getActiveUserSearch,
    massChangeAffiliatesStatus,
} from "@/api/user";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { addNewSlaveForMasterAccount, getPayoutMasterAccounts, makeNewMasterAccount } from "@/api/payout";
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";
import SvgEdit from "@/assets/icons/pencil.svg";
import SvgCheck from "@/assets/icons/check.svg";
import { ITag } from "@/api/types/user";

@Component({
    components: { FiltersDialog, BaseAutocomplete, BaseSelect, ItemTableList, AppTableDesign, SvgEdit, SvgCheck },
})
export default class AffiliateTable extends Vue {
    @Prop({ required: true }) items!: Affiliate[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;
    @Prop({ default: false }) isDetailPage!: boolean;

    SvgTrue = SvgTrue;
    SvgFalse = SvgFalse;

    selected: Affiliate[] = [];
    statusSelect: string = '';
    managerSelect: number | string = '';
    masterSelect: number | string = '';
    masterAccountId: number | string = '';
    newMaster: boolean = false;
    isLocalLoader: boolean = false;
    isShowFooterDialog: boolean = false;

    headers: TableHeader[] = [
        { text: 'ID пар-ра', sortable: false, value: 'id', align: 'center' },
        { text: 'User ID', sortable: false, value: 'user_id', align: 'center' },
        { text: 'Имя', sortable: false, value: 'full_name', align: 'left', width: 150 },
        { text: 'Никнейм', sortable: false, value: 'nickname', align: 'left' },
        { text: 'Мастер-аккаунт', sortable: false, value: 'master_id', align: 'center' },
        { text: 'Имя мастер-аккаунта', sortable: false, value: 'master_account_name', align: 'left', width: 150 },
        { text: 'Почта', sortable: false, value: 'email', align: 'left' },
        { text: 'Статус почты', sortable: false, value: 'email_verified', align: 'center' },
        { text: 'Статус телефона', sortable: false, value: 'phone_verified', align: 'center' },
        { text: 'Аккаунт-менеджер', sortable: false, value: 'manager.full_name', align: 'left', width: 150 },
        { text: 'Вертикаль', sortable: false, value: 'verticals', align: 'left', width: '86px' },
        { text: 'Страна', sortable: false, value: 'country', align: 'left' },
        { text: 'Город', sortable: false, value: 'city', align: 'left' },
        { text: 'Язык', sortable: false, value: 'language', align: 'left' },
        { text: 'Статус', sortable: false, value: 'status', align: 'left' },
        { text: 'Источник трафика', sortable: false, value: 'traffic_sources', align: 'left' },
        { text: 'Реферер', sortable: false, value: 'referer_url', align: 'left' },
        { text: 'Utm source', sortable: false, value: 'utms.utm_source' },
        { text: 'Utm medium', sortable: false, value: 'utms.utm_medium' },
        { text: 'Utm campaign', sortable: false, value: 'utms.utm_campaign' },
        { text: 'Utm term', sortable: false, value: 'utms.utm_term' },
        { text: 'Gid', sortable: false, value: 'utms.gid' },
        { text: 'Aid', sortable: false, value: 'utms.aid' },
        { text: 'Gclid', sortable: false, value: 'utms.gclid' },
        { text: 'Creative', sortable: false, value: 'utms.creative' },
        { text: 'Placement', sortable: false, value: 'utms.placement' },
        { text: 'Rand', sortable: false, value: 'utms.rand' },
        { text: 'Зарегистрирован', sortable: false, value: 'signup_at', align: 'left', width: '120px' },
        { text: 'Создан', sortable: false, value: 'created_at', align: 'left' },
        { text: 'Обновлён', sortable: false, value: 'updated_at', align: 'left' },
        { text: 'Последний логин', sortable: false, value: 'last_login', align: 'left' },
        { text: 'Теги', sortable: false, value: 'tags', align: 'left', width: '120' },
        { text: 'Действия', sortable: false, align: 'center', value: 'actions', width: '50px', fixed: true },
    ];

    actions = [
        {
            title: 'Залогиниться',
            color: '#FFFFFF',
            icon: 'SvgUser',
            isTooltip: true,
            callback: (item: any): void => {
                this.login(item.id);
            },
        },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'affiliate',
            text: 'id',
        },
        {
            routeParam: 'id',
            slot: 'item.user_id',
            routerName: 'affiliate',
            text: 'user_id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'master_id',
            slot: 'item.master_id',
            routerName: 'affiliate',
            template: (item: Affiliate): any => {
                return item.is_master === 0 && !item.master_id ? item.id : item.master_id;
            },
            icon: SvgStar,
            showIcon: (item: Affiliate): boolean => {
                return !!item.master_id && item.id !== item.master_id;
            },
            tooltip: (item: Affiliate): string => {
                return !!item.master_id && item.id !== item.master_id ? 'Мастер' : '';
            },
            tooltipClass: 'base-tooltip',
        },
    ];

    timeData = [
        {
            slot: 'item.signup_at',
            key: 'signup_at',
            isLine: false,
        },
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
        {
            slot: 'item.updated_at',
            key: 'updated_at',
            isLine: false,
        },
        {
            slot: 'item.last_login',
            key: 'last_login.created_at',
            isLine: false,
        },
    ];

    uniqueData = [
        {
            slot: 'item.traffic_sources',
        },
        {
            slot: 'item.referer_url',
        },
        {
            slot: 'item.email_verified',
        },
        {
            slot: 'item.phone_verified',
        },
        {
            slot: 'item.verticals',
        },
        {
            slot: 'item.language',
        },
        {
            slot: 'item.manager.full_name',
            template: (item: any): string => `${item.manager?.first_name! || ''} ${item.manager?.last_name! || ''}`,
        },
    ];

    verifiedStatuses = [
        {
            slot: 'item.email_verified',
            value: 'email_verified',
            trueText: 'Подтверждена',
            falseText: 'Не подтверждена',
        },
        {
            slot: 'item.phone_verified',
            value: 'phone_verified',
            trueText: 'Подтверждён',
            falseText: 'Не подтверждён',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translatePartnerStatus,
        },
    ];

    dropDownItems = [
        {
            slot: 'item.tags',
            text: 'tags',
            readonly: true,
            template: (i: ITag): string => i.name,
        },
    ];

    async login(id: number): Promise<void> {
        try {
            const { url } = await getAffiliateLoginURL(id);
            window.open(url, '_blank');
        } catch (err) {
            showServerError(err, 'Не удалось авторизоваться как партнёр');
        }
    }

    get isNotMobile(): boolean {
        return this.$vuetify.breakpoint.width >= 769;
    }

    get getAttach(): string {
        return `.attach-${this.$route.name}`;
    }

    toggleDialog(): void {
        this.isShowFooterDialog = !this.isShowFooterDialog;
    }

    get getManagerData(): any {
        return {
            apiMethod: getActiveUserSearch,
            errorText: 'Менеджер не найден',
            placeholder: 'Менеджер',
            itemValue: 'id',
            template: (i) => `${i.id} ${i.first_name} ${i.last_name}`,
            multiple: false,
            menuProps: { top: this.isNotMobile },
        };
    }

    get getMasterData(): any {
        return {
            apiMethod: getAffiliateSearch,
            errorText: 'Мастер аккаунт не найден',
            placeholder: 'Мастер аккаунт',
            itemValue: 'id',
            template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
            disabled: this.isDisabledMasterSelect,
            multiple: false,
            menuProps: { top: this.isNotMobile },
        };
    }

    get isDisabledMasterSelect(): boolean {
        return this.selected.some(i => i.is_master === 1 || i.master_id !== null);
    }

    changeManager(id: number): void {
        this.managerSelect = id;
    }

    async changeMaster(id: number): Promise<void> {
        try {
            this.masterSelect = id;
            const masterAcc = await getPayoutMasterAccounts({ offset: 0, limit: 100, affiliate_ids: [id] });
            if (masterAcc.data.length > 0) {
                this.masterAccountId = masterAcc.data[0].id;
            } else {
                this.masterAccountId = id;
                this.newMaster = true;
            }
        } catch (err) {
            showServerError(err, 'Мастер аккаунт не определён');
        }
    }

    get isLoading(): boolean {
        return this.loading || this.isLocalLoader;
    }

    get isDisabledFooterButton(): boolean {
        return ![this.statusSelect, this.managerSelect, this.masterSelect].some(i => !!i);
    }

    get footerButtons(): ITableFooterButton[] | undefined {
        if (this.isNotMobile) {
            return [
                {
                    text: 'Изменить',
                    textOfConfirm: 'Подтвердить?',
                    color: 'green',
                    icon: 'check',
                    disabled: this.isDisabledFooterButton,
                    action: this.changeSelected,
                },
            ];
        }
    }

    get footerSelectItems(): { id: string, text: string }[] {
        const statuses = ['active', 'pending', 'deleted', 'blocked', 'rejected'];
        return statuses.map(id => {
            return {
                id,
                text: translatePartnerStatus(id).text,
            };
        });
    }

    async changeSelected(): Promise<void> {
        try {
            this.isLocalLoader = true;
            if (this.statusSelect) {
                const affiliates = this.selected.map(i => i.id!);
                const data = { affiliate_ids: affiliates, status: this.statusSelect };
                await massChangeAffiliatesStatus(data);
            }
            if (this.managerSelect) {
                const affiliate_ids = this.selected.map(i => i.id!);
                const data = { affiliate_ids, manager_id: +this.managerSelect };
                await batchAssignManager(data);
            }
            if (this.masterSelect) {
                const slaves = this.selected.map(i => (i.id!));

                if (this.newMaster) {
                    const data = { master: { id: +this.masterAccountId }, slaves };
                    await makeNewMasterAccount(data);
                } else {
                    await addNewSlaveForMasterAccount(+this.masterAccountId, { slaves });
                }
                this.newMaster = false;
            }
            if (this.managerSelect) {
                showNotification('Изменения происходят не сразу. Партнёры добавлены в очередь.');
            } else {
                showNotification('Партнёры успешно обновлены');
            }
        } catch (err) {
	        if (err.status === 429) {
		        showServerError(err, 'Изменения происходят не сразу. Партнёры добавлены в очередь.');
	        } else {
		        showServerError(err, 'Партнёры не обновлены');
	        }
        } finally {
            this.selected = [];
            this.isLocalLoader = false;
            this.$emit("update-table");
        }
    }

    get refs(): any {
        return this.$refs;
    }

    get getRoutes(): any {
        return this.isDetailPage ?
            this.routes.map(r => {
                return r.slot === 'item.id' ? {
                    ...r,
                    icon: SvgStar,
                    showIcon: (item: Affiliate): boolean => {
                        return !!item?.is_master!;
                    },
                    tooltip: (item: Affiliate): string => {
                        return !!item?.is_master! ? 'Мастер' : '';
                    },
                    tooltipClass: 'base-tooltip',
                } : r;
            }) : this.routes;
    }

    async copyRefer(id: number): Promise<void> {
        const range = document.createRange();
        range.selectNode(this.refs[`link${id}`] as Element);
        window.getSelection()!.removeAllRanges();
        window.getSelection()!.addRange(range);
        document.execCommand('copy');
        window.getSelection()!.removeAllRanges();
        showNotification('Ссылка скопирована');
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }

    resetSelect(): void {
        this.statusSelect = '';
        this.managerSelect = '';
        this.masterSelect = '';
    }
}
