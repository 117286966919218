
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import {
    getApplicationsFields, getConversionStatuses,
    getPossibleEndpointCondition, getPossibleStageCondition,
    getPossibleStreamCondition,
} from '@/api/uapi';
import { ICondition, IConditionOption } from '@/api/types/uapi';
import StatsCard from '@/components/base/StatsCard.vue';
import CardMaterial from '@/components/base/CardMaterial.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import { showServerError } from '@/utils';
import ConditionGeo from '@/components/uapi/conditions/ConditionGeo.vue';

@Component({
    components: {
        ConditionGeo,
        StatsCard,
        CardMaterial,
        TooltipButton,
    },
})
export default class Condition extends Vue {
    @Prop({ type: Boolean, default: false }) isBtnLoading!: boolean;
    @Prop({ type: String, required: true }) routeName!: string;
    @PropSync('condition') conditionSync!: ICondition;

    applicationFields: Array<{field: string; name: string}> = [];

    possibleConditions: IConditionOption[] = [];

    conversionStatuses: string[] = [];

    get isGeoType(): boolean {
        return this.conditionSync!.type === 'geo';
    }

    get isConversionType(): boolean {
        return this.conditionSync!.type === 'conversion_status';
    }

    @Watch('conditionSync.type')
    changeType(type: string): void {
        if (type === 'geo') {
            if (!this.conditionSync.value_object) {
                this.$set(this.conditionSync, 'value_object', {
                    country_id: [],
                    region_id: [],
                    city_id: [],
                    exclude: false,
                });
            }
        } else if (type === 'conversion_status') {
            this.getData(getConversionStatuses, true);
        }
    }

    get fieldIsRequired(): boolean {
        if ((this.conditionSync as ICondition).type && this.possibleConditions.length) {
            const selectedStreamCondition: IConditionOption | undefined = this.possibleConditions
                .find((item: IConditionOption) => item.code === (this.conditionSync as ICondition).type);
            return selectedStreamCondition!.isFieldRequired;
        }
        return false;
    }

    get isDisabledBtn(): boolean {
        if (this.isGeoType) {
            return !!(this.conditionSync.value_object && !this.conditionSync.value_object.country_id.length);
        } else {
            return !(this.conditionSync as ICondition).type || !(this.conditionSync as ICondition).value;
        }
    }

    created(): void {
        this.getApplicationsFields();
        this.checkRouteName();
    }

    async checkRouteName(): Promise<void> {
        switch (this.routeName) {
        case 'stream':
            return this.getData(getPossibleStreamCondition);
        case 'endpoint':
            return this.getData(getPossibleEndpointCondition);
        case 'stage':
            return this.getData(getPossibleStageCondition);
        }
    }

    async getApplicationsFields(): Promise<void> {
        try {
            const { data } = await getApplicationsFields();
            this.applicationFields = data;
        } catch (err) {
            showServerError(err);
        }
    }

    async getData(getData: () => any, isStatus?: boolean):  Promise<void> {
        try {
            const { data } = await getData();
            if (!isStatus) {
                this.possibleConditions = data;
            } else {
                this.conversionStatuses = data;
            }
        } catch (err) {
            showServerError(err);
        }
    }

    saveCondition(): void {
        if (this.isGeoType) {
            this.conditionSync.value = 'geo';
            this.conditionSync.field = '';
        } else {
            delete this.conditionSync.value_object;
        }
        this.conditionSync.field_name = '';
        this.$emit('save-condition');
    }
}
