
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getFormatDate } from '@/utils/formatDates';
import { IAgentReport } from '@/api/types/org-cabinets-backend';

@Component({})

export default class AgentReportInfo extends Vue {
    @Prop({ required: true }) report!: IAgentReport;

    getFormatDate = getFormatDate;

    reportInfo: { name: string; value: string }[] = [];

    mounted(): void {
        this.reportInfo = [
            {
                name: 'ID:',
                value: this.$props.report.id,
            },
            {
                name: 'Код:',
                value: this.$props.report.code,
            },
            {
                name: 'Период:',
                value: getFormatDate(this.$props.report.period.substr(0, 7), 'LLLL yyyy') || '',
            },
            {
                name: 'Организация:',
                value: this.$props.report.legal.name,
            },
            {
                name: 'Сумма:',
                value: this.$props.report.amount,
            },
            {
                name: 'Покупатель:',
                value: this.$props.report.customer_name,
            },
            {
                name: 'Валюта:',
                value: this.$props.report.currency,
            },
            {
                name: 'Создан:',
                value: this.$props.report.created_at || '',
            },
            {
                name: 'Обновлён:',
                value: this.$props.report.updated_at || '',
            },

        ];
    }

}
