
import {
    Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { Route } from 'vue-router';
import { getFormatDatesForDatepicker, getFormatDate } from '@/utils/formatDates';
import StatsCard from '@/components/base/StatsCard.vue';
import ChangeEditingModeButton from '@/components/base/buttons/ChangeEditingModeButton.vue';
import {
    getAdvertisersSearch,
    getLegalEntities,
} from '@/api/revenue';
import {
    ICustomerOrders,
    IAdvertiser,
    IContractor,
    IInvoice,
    ILegalEntityOrg, IInvoiceAdvertiser,
} from '@/api/types/revenue';
import FormModal from '@/components/base/FormModal.vue';
import { showServerError } from '@/utils';
import HeaderStatuses from '@/components/revenue/invoice/HeaderStatuses.vue';
import HeaderError from '@/components/revenue/invoice/HeaderError.vue';
import { MAX_LIMIT_FOR_SELECT, SEARCH_VALUE_DEBOUNCE } from '@/configs/global';
import { USER_ROLES } from '@/mappings/user-roles';

const auth = namespace('authModule');
const invoice = namespace('invoiceModule');

@Component({
    components: {
        HeaderStatuses,
        HeaderError,
        StatsCard,
        ChangeEditingModeButton,
        FormModal,
    },
})

export default class Header extends Vue {
    @Prop() loading!: boolean;
    @PropSync('invoice', { required: true }) invoiceSync!: IInvoice;

    get isAdvertiserReadonly(): boolean {
        return !!(this.invoiceSync && this.invoiceSync.id) || (this.$route.path === '/revenue/invoice/creation');
    }

    get advertiser(): string {
        return this.invoiceSync.advertiser?.id
            ? `${this.invoiceSync.advertiser.id} ${this.invoiceSync.advertiser.name}` : '';
    }

    get accountManagerName(): string {
        const manager = this.invoiceSync.advertiser?.account_manager!;
        return `${manager?.first_name! || ''} ${manager?.last_name! || ''}`;
    }

    // for autocomplete
    get isInvalidAdvertiser(): boolean {
        return this.currentAdvertiser
                && Object.entries(this.currentAdvertiser).length === 0
                && this.currentAdvertiser.constructor === Object
                && !this.selectedItemAdvertiser
                || !this.currentAdvertiser && !this.selectedItemAdvertiser;
    }

    get paramsForAdvertiserLink(): number {
        return this.invoiceSync.advertiser_id ? this.invoiceSync.advertiser_id : this.invoiceSync.advertiser!.id;
    }

    get currencyItems(): string[] {
        const currencyItems = this.invoiceCurrency.length > 0
            ? this.invoiceCurrency || []
            : this.$props.currencyList || [];
        if (currencyItems.length === 1) {
            this.invoiceSync.currency = currencyItems[0];
        }
        return typeof currencyItems === 'string'
            ? currencyItems.split(' ')
            : currencyItems;
    }

    get noPayments(): boolean {
        return this.isPrepayment || this.invoiceSync.financial_status !== 'paid'
                && this.invoiceSync.financial_status !== 'partly_paid';
    }

    get isRoleSupervisor(): boolean {
        return this.role === USER_ROLES.SUPERVISOR;
    }

    get isRoleAdmin(): boolean {
        return this.role === USER_ROLES.ADMIN;
    }

    get isShowEditingModeButton(): boolean {
        return !!(
            this.invoiceSync!.id &&
            this.isCanEdit &&
            (this.noPayments || this.isRoleSupervisor || (this.isFinmedia && this.isRoleAdmin))
        );
    }

    get isShowCurrency(): boolean {
        return !(this.currencyItems.length === 0 && this.currentAdvertiser && this.currentAdvertiser.id);
    }

    get isShowAccountManager(): boolean {
        return !!(!this.$props.isFinmedia && (this.accountManagerName || this.currentAccountManager));
    }

    get isObjectLegalEntity(): boolean {
        return this.customerOrder && Object.keys(this.customerOrder.legal_entity as object).length > 0;
    }

    get isCanEdit(): boolean {
        if (this.isFinmedia && this.role === USER_ROLES.FINMEDIA) {
            return true;
        }

        if (this.role === USER_ROLES.ACCOUNT_MANAGER) {
            let editingCapability;
            if (this.currentAdvertiser && this.currentAdvertiser.account_manager) {
                editingCapability = (
                    this.user!.account_id === this.currentAdvertiser.account_manager!.account_id
                    || this.currentAdvertiser.managers.find((manager) => manager.manager_id === this.user!.account_id)
                );
                this.showMainContentSync = !(this.currentAdvertiser.account_manager && !editingCapability);
                return editingCapability;
            }
            editingCapability = this.invoiceSync!.advertiser && this.invoiceSync!.advertiser!.account_manager
                    && (
                        this.user!.account_id === this.invoiceSync!.advertiser!.account_manager!.account_id
                        || this.invoiceSync!.advertiser!.managers!
                            .find((manager) => manager.manager_id === this.user!.account_id)
                    );
            return editingCapability;
        }
        this.showMainContentSync = true;
        return true;
    }

    get getEditMode(): boolean {
        if (!this.invoiceSync?.id) {
            return false;
        }
        return !this.editingMode;
    }

    get getCreatedByName(): string | void {
        let accountManagerName = '';
        const invoiceCreatorName = this.invoiceSync?.created_by?.full_name;

        if (
            this.invoiceSync
            && this.invoiceSync.advertiser
            && this.invoiceSync.advertiser.account_manager
        ) {
            accountManagerName = this.invoiceSync.advertiser.account_manager?.full_name || '';
        } else {
            return invoiceCreatorName;
        }

        if (invoiceCreatorName) {
            if (this.$props.isFinmedia) {
                return invoiceCreatorName;
            }
            if (invoiceCreatorName !== accountManagerName) {
                return invoiceCreatorName;
            }
        }
    }

    @Prop({ required: false }) customerOrder!: ICustomerOrders;

    @Prop({ required: false, default: false }) isFinmedia!: boolean;

    @Prop({ required: false }) currencyList!: [];

    @Prop({ required: false }) projectList!: [];

    @Prop({ required: false }) advertiserContractors!: IContractor;

    @Prop({ default: false }) canSave!: boolean;

    @Prop({ default: false }) isPrepayment!: boolean;

    @Prop({ default: false }) isInvoiceAlreadyExist!: boolean;

    @PropSync('showMainContent', { type: Boolean }) showMainContentSync!: boolean;

    @Getter('GET_EDITING_MODE') editingMode;

    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    @Mutation('TOGGLE_EDITING_MODE') toggleEditingMode;

    @auth.Getter('GET_ROLE') role;

    @auth.Getter('GET_USER') user;

    @invoice.Mutation('SET_ADVERTISER') setAdvertiserInvoice;

    @invoice.Mutation('SET_PERIOD') setPeriodInvoice;

    menu = false;

    // for autocomplete
    foundItemsAdvertisers: IAdvertiser[] = [];

    selectedItemAdvertiser = '';

    advertisersLoading = false;

    searchTimerId = 0;

    entities: any[] = [];

    searchedEntitie = '';

    accountingEntitiesCopy: ILegalEntityOrg[] = [];

    contractors: IContractor[] = [];

    searchedContractor = '';

    contractorsCopy: IContractor[] = [];

    currentAdvertiser = {} as IInvoiceAdvertiser;

    // рекламодатель, на которого хотим сменить текущего рекламодателя
    // newAdvertiser: any = {};
    currentAccountManager: string | undefined | null = '';

    isShowModalDeleteOffer = false;

    invoiceCurrency: string[] = [];

    finmediaInvoiceEditing = false;

    getFormatDate = getFormatDate;

    getFormatDatesForDatepicker = getFormatDatesForDatepicker;

    getSearchedEntities(): void | string[] {
        if (!this.searchedEntitie) {
            this.entities = this.accountingEntitiesCopy;
            return;
        }

        this.entities = this.accountingEntitiesCopy.filter((item) => item.name!.toLowerCase().indexOf(this.searchedEntitie.toLowerCase()) > -1);
    }

    getSearchedContractors(): void | string[] {
        if (!this.searchedContractor) {
            this.contractors = this.contractorsCopy;
            return;
        }

        this.contractors = this.contractorsCopy.filter((item) => item.name!.toLowerCase().indexOf(this.searchedContractor.toLowerCase()) > -1);
    }

    changedCurrency(): void {
        this.setUnsavedDataState(true);
    }

    goTo(router: Route & { name: string }, params: any): void {
        if (!this.editingMode) {
            this.$router.push(router, params);
        }
    }

    advertiserHasChanged(advertiser: IInvoiceAdvertiser): void {
        this.currentAdvertiser = advertiser;
        if (advertiser) {
            this.currentAccountManager = this.currentAdvertiser.account_manager
                ? this.currentAdvertiser.account_manager!.full_name : null;
            this.invoiceCurrency = advertiser.currency || [];

            if (this.currencyItems.length === 1) {
                this.invoiceSync.currency = this.currencyItems[0];
            }
        }
        this.invoiceSync.advertiser = advertiser;
        this.setAdvertiserInvoice(advertiser);
        this.setPeriodInvoice(this.invoiceSync.period);
    }

    @Watch('currentAdvertiser')
    clearOffer(): void {
        if (!this.currentAdvertiser) {
            this.setAdvertiserInvoice('');
        }
    }

    @Watch('$props.advertiserContractors')
    setContractors(): void {
        if (this.contractors.length === 0 && this.$props.advertiserContractors.length > 0) {
            this.contractors = [...this.$props.advertiserContractors];
            this.contractorsCopy = [...this.contractors];
        }
    }

    setPeriod(period: string): void {
        this.invoiceSync.period = period;
        this.setPeriodInvoice(period);
        this.setUnsavedDataState(true);
    }

    // очищаем связанные с рекламодателем данные (оффер и услуги)
    clearOfferServices(): void {
        if (this.invoiceSync.offer_services!.length > 0
            || this.invoiceSync.other_services!.length > 0
            || this.invoiceSync.currency) {
            this.isShowModalDeleteOffer = true;
        } else {
            this.clearAdvertiserRelatedData();
        }
    }

    clearAdvertiserRelatedData(): void {
        this.currentAdvertiser = {} as IInvoiceAdvertiser;
        this.currentAccountManager = null;
        this.invoiceSync.offer_services = [];
        this.invoiceSync.other_services = [];
        this.invoiceSync.customer_orders = [];
        this.invoiceSync.netting = [];
        this.invoiceSync.currency = null;
        this.invoiceSync.advertiser = {} as IInvoiceAdvertiser;
        this.isShowModalDeleteOffer = false;
    }

    @Watch('selectedItemAdvertiser')
    searchAdvertiser(inputValue: string): void {
        if (this.selectedItemAdvertiser) {
            clearTimeout(this.searchTimerId);
            this.advertisersLoading = true;
            this.searchTimerId = window.setTimeout(() => {
                this.getFoundedAdvertiser(inputValue);
            }, SEARCH_VALUE_DEBOUNCE); /* 850ms задержка */
        }
    }

    @Watch('invoiceSync.id')
    edit(): void {
        if (this.invoiceSync!.id && !this.isCanEdit) {
            this.toggleEditingMode(false);
        }
    }

    async created(): Promise<void> {
        if (this.$route.name === 'finmedia-invoice') {
            this.finmediaInvoiceEditing = true;
        }

        if (this.$route.name === 'finmedia-invoice-creation') {
            // Получаем список Юр. лиц для бухгалтерского счёта
            const getLegalEntity = getLegalEntities({ limit: MAX_LIMIT_FOR_SELECT });

            try {
                const result = await getLegalEntity;
                this.entities = result.data.filter((item) => item.settings!.is_active);
                this.accountingEntitiesCopy = [...this.entities];
            } catch (err) {
                showServerError(err, 'Список юр.лиц не загружен');
            }

            if (this.$props.advertiserContractors) {
                this.contractors = this.$props.advertiserContractors;
            }
        }
        // Если период не установлен, по умолчанию выставляем значение равное предыдущему месяцу
        if (!this.invoiceSync.period) {
            const lastMonthTimestamp = new Date();
            lastMonthTimestamp.setDate(1);
            lastMonthTimestamp.setMonth(lastMonthTimestamp.getMonth() - 1);
            const date: string = new Date(lastMonthTimestamp).toISOString().substr(0, 7);
            this.invoiceSync.period = date;
        }
    }

    beforeDestroy(): void {
        if (this.invoiceSync.id && !this.isCanEdit) {
            this.toggleEditingMode(true);
        }
    }

    // получаем список реклов на лету
    private async getFoundedAdvertiser(inputValue: string) {
        const result: { data: IAdvertiser[] } = await getAdvertisersSearch({ query: inputValue, status: ['active', 'rejected', 'pending', 'blocked'], limit: MAX_LIMIT_FOR_SELECT });
        this.advertisersLoading = false;
        this.foundItemsAdvertisers = result.data;
        for (const item of this.foundItemsAdvertisers) {
            item.name = `${item.id} ${item.name}`;
        }
    }
}
