import NotificationsPage from "@/views/notifications/List.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import CreateNotification from "@/views/notifications/CreateNotification.vue";
import NotificationPage from "@/views/notifications/Single.vue";

export default [
    {
        path: 'notifications',
        name: 'notifications-list',
        component: NotificationsPage,
        meta: {
            title: 'Центр уведомлений',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.NOTIFICATION_MANAGER],
        },
    },
    {
        path: 'create-notification',
        component: CreateNotification,
        name: 'create-notification',
        meta: {
            title: 'Создание уведомления',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.NOTIFICATION_MANAGER],
        },
    },
    {
        path: 'notification/:id',
        component: NotificationPage,
        name: 'notification-detail',
        meta: {
            title: 'Уведомление',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.NOTIFICATION_MANAGER],
        },
    },
];
