
import { Vue, Component, Prop } from "vue-property-decorator";
import { getFormatDate } from "@/utils/formatDates";
import { translateTicketStatus } from "@/utils/translate";
import Status from "@/components/base/Status.vue";
import SvgTrue from "@/assets/icons/true-icon.svg";
import SvgFalse from "@/assets/icons/false-icon.svg";
import SvgStar from "@/assets/icons/star.svg";

export interface IAboutItem {
    type: string,
    name: string,
    value: any,
    subtype?: string,
    isVerified?: boolean,
    isMaster?: boolean,
    isLink?: boolean,
}

@Component({
    methods: { translateTicketStatus },
    components: { Status, SvgTrue, SvgFalse, SvgStar },
})

export default class TicketAboutItem extends Vue {
    @Prop({ required: true }) item!: IAboutItem;

    getFormatDate = getFormatDate;
}

