import { CreatedFilters } from "@/services/filters/filters";
import { IColumnFilter, IFilter } from "@/types";
import { CreatedColumns } from "@/services/columns/columns";
import { getUserSearch } from "@/api/user";
import {
    translateAccountStatus,
    translateFinancialStatus,
    translateStatisticsStatus,
} from "@/utils/translate";
import { filterClass } from "@/services/filters/filterClass";
import { getAdvertisersSearch } from "@/api/revenue";
import store from "@/store";
import { USER_ROLES } from "@/mappings/user-roles";
import { IUser } from "@/api/types/user";
import { getLastDayOfCurrentMonth, getPreviousMonth } from "@/utils/formatDates";

export class InvoicesAdvertisersColumns extends CreatedColumns {
    constructor() {
        super();
    }
    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'ID', value: 'id' },
                    { title: 'Период', value: 'period' },
                    { title: 'Рекламодатель', value: 'advertiser.name' },
                    { title: 'Аккаунт-менеджер', value: 'advertiser.account_manager.full_name' },
                    { title: 'Статус статистики', value: 'statistics_status' },
                    { title: 'Финансовый статус', value: 'financial_status' },
                    { title: 'Сумма услуг', value: 'total_by_invoice' },
                    { title: 'Сумма взаимозачёта', value: 'netting_total_paid_sum' },
                    { title: 'Сумма заказов покупателя', value: 'orders_total' },
                    { title: 'Акция', value: 'other_services' },
                    { title: 'Действия', value: 'actions' },
                ],
            },
        ];
    }

    get getColumnsForAdvertiserDetailPage(): IColumnFilter[] {
        const blockedCols = ['advertiser.name', 'advertiser.account_manager.full_name'];
        return this.getFilteredColumns(blockedCols);
    }
}

export class InvoicesAdvertisersFilters extends CreatedFilters {
    user = store.getters['authModule/GET_USER'];
    constructor() {
        super();
        if (this.isSetUser) this.setUser();
    }

    get isAccountManager(): boolean {
        const user: IUser = store.getters['authModule/GET_USER'];
        return user?.roles?.includes(USER_ROLES.ACCOUNT_MANAGER) && this.checkRoute('invoices');
    }

    get isSetUser(): boolean {
        return this.isAccountManager && (this.lengthQueryFilters <= 0 );
    }

    getTemplate(item: any): string {
        return item.name || item.code || item.master.id;
    }

    clearFilters(): void {
        const user: IUser = store.getters['authModule/GET_USER'];
        if (this.isAccountManager) {
            store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', { created_by: [user.account_id] });
        }
        super.clearFilters();
        if (this.isAccountManager) this.setUser();
    }

    setUser(): void {
        const filter =  this.filters.find(f=> f.id === 'created_by');
        if (!filter) return;

        const value = {
            id: this.user.account_id,
            account_id: this.user.account_id,
            name: `${this.user.account_id} ${this.user.first_name} ${this.user.last_name}`,
        };

        filter!.select = [value];
        filter!.defaultValue = [value];
        filter!.items!.push(value);
    }

    getFilters(): IFilter[] {
        const advertiser = store.getters['revenueModule/GET_CURRENT_ADVERTISER'];
        const isAdvertiser = advertiser && this.checkRoute('advertiser');
        const periodValue = [getPreviousMonth('yyyy-MM-dd', 1), getLastDayOfCurrentMonth()];
        return [
            new filterClass.Select({
                id: 'account_status',
                label: 'Статус',
                itemValue: 'value',
                itemText: 'name',
                items: ['pending', 'filled'],
                translate: (status) => this.translateStatus(status, translateAccountStatus),
            }),
            new filterClass.Select({
                id: 'statistics_status',
                label: 'Статус статистики',
                select: '',
                apiParseUrl: false,
                items: ['0', '1', 'pending', 'in_progress', 'done'],
                translate: (status) => this.translateStatus(status, translateStatisticsStatus),
            }),
            new filterClass.Select({
                id: 'financial_status',
                label: 'Финансовый статус',
                items: ['new', 'sent', 'pending', 'partly_paid', 'paid'],
                select: '',
                translate: (status) => this.translateStatus(status, translateFinancialStatus),
            }),
            new filterClass.Autocomplete({
                id: 'advertiser_id',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                select: isAdvertiser ? [advertiser] : [],
                defaultValue: isAdvertiser ? [advertiser] : [],
                items: [] as Array<{id: number, name: string}>,
                multiple: true,
                isHidden: isAdvertiser,
            }),
            new filterClass.Autocomplete({
                id: 'account_manager_id',
                apiMethod: getUserSearch,
                label: 'Аккаунт-менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                isHidden: isAdvertiser,
            }),
            new filterClass.Autocomplete({
                id: 'created_by',
                apiMethod: getUserSearch,
                label: 'Создатель счёта',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                template: (item) => `${item.id} ${item.first_name} ${item.last_name}`,
                multiple: true,
            }),
            new filterClass.Datepicker({
                id: 'period',
                label: 'Период',
                pickerType: 'month',
                isMonthPicker: true,
                range: true,
                select: this.isAccountManager || isAdvertiser ? [] : periodValue,
                defaultValue: this.isAccountManager || isAdvertiser ? [] : periodValue,
            }),
        ];
    }
}
