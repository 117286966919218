import { IParamsObject, IServerResponse } from '@/api/types';
import { axiosInstance } from '@/api/index';
import { API_ANTIFRAUD_URL } from '@/configs/global';
import { AxiosResponse } from 'axios';
import { IAntifraudAffiliate, IAntifraudReport } from '@/api/types/antifraud';

export async function getReports(
    params: {
        affiliate_id?: number;
        period_from?: string;
        period_to?: string;
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<IAntifraudReport>>({
        method: 'GET',
        url: `${API_ANTIFRAUD_URL}/reports/check`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IAntifraudReport>>) => response.data.data);
}

/**
 * Получение списка проверяемых партнеров
 */
export async function getCheckingAffiliates(
    params?: IParamsObject,
): Promise<IServerResponse<IAntifraudAffiliate[]>> {
    return axiosInstance.request<IServerResponse<IAntifraudAffiliate[]>>({
        method: 'GET',
        url: `${API_ANTIFRAUD_URL}/affiliates`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IAntifraudAffiliate[]>>) => response.data);
}

/**
 * Удаление проверяемого партнера
 */
export async function deleteCheckingAffiliates(
    data: {
        ids: number[];
    },
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'DELETE',
        url: `${API_ANTIFRAUD_URL}/affiliates`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 * Добавление проверяемого партнера
 */
export async function postCheckingAffiliates(
    data: {
        ids: number[];
    },
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_ANTIFRAUD_URL}/affiliates`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 * Получение списка отчетов по партнерскому трафику
 */
export async function getAntifraudReports(
    params?: IParamsObject,
): Promise<IServerResponse<IAntifraudAffiliate[]>> {
    return axiosInstance.request<IServerResponse<IAntifraudAffiliate[]>>({
        method: 'GET',
        url: `${API_ANTIFRAUD_URL}/reports`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IAntifraudAffiliate[]>>) => response.data);
}
