
import { Vue, Component, Prop } from "vue-property-decorator";
import { TableHeader } from "@/types";
import { IAuthorization } from "@/api/types/user";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import AffiliateChangeHistoryItem from "@/components/affiliate/AffiliateChangeHistoryItem.vue";
import { translateAffiliateField } from "@/services/affiliates/Affiliate";
import TdRoute from "@/components/base/TableUI/TdRoute.vue";

@Component({
    components: { TdRoute, AffiliateChangeHistoryItem, TableWithFrontPaginationDesign },
})

export default class AffiliateChangeHistoryTable extends Vue {
    @Prop({ required: true }) items!: IAuthorization[];
    @Prop({ default: true }) loading!: boolean;
    @Prop({ required: true }) userId!: number;

    isLoading: boolean = false;

    headers: TableHeader[] = [
        { text: 'Дата/время обновления', value: 'changed_at', sortable: false },
        { text: 'Объект', value: 'subject', sortable: false },
        { text: 'ID Объекта', value: 'subject_id', sortable: false, align: 'center' },
        { text: 'Какое поле изменено', value: 'field', sortable: false },
        { text: 'Старое значение', value: 'old_value', sortable: false },
        { text: 'Новое значение', value: 'new_value', sortable: false },
        { text: 'Кем изменено', value: 'changed_by', sortable: false, align: 'center' },
    ];

    uniqueData = [
        { slot: 'item.subject_id' },
        { slot: 'item.old_value' },
        { slot: 'item.new_value' },
        {
            slot: 'item.subject',
            template: (item: any): string => translateAffiliateField(item.subject),
        },
        {
            slot: 'item.field',
            template: (item: any): string => translateAffiliateField(item.field),
        },
    ];

    timeData = [
        {
            slot: 'item.changed_at',
            key: 'changed_at',
            isLine: false,
        },
    ];

    partnerMethodRoute = {
        routeParam: 'id',
        extraRouteParamName: 'subject_id',
        routerName: 'change-method-request',
        text: 'subject_id',
    };

    get isLoadingTable(): boolean {
        return this.loading || this.isLoading;
    }
}

