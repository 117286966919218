
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import ImageInput from "@/components/base/form/ImageInput.vue";
import { uploadToS3 } from "@/api";

@Component({
    components: { ImageInput },
})

export default class FormLogo extends Vue {
    @PropSync('product') productSync!: any;
    @Prop({ required: true }) isEdit!: boolean;
    @Prop({ required: true }) param!: any;

    async updateLogo(e: any): Promise<void> {
        const { data } = await uploadToS3(e);
        if (data?.url) {
            this.productSync.params[this.param.param] = data.url;
        }
    }
}
