import {
    BaseFilter,
    InputFilter,
    SelectFilter,
    SelectWithHeadersFilter,
    MultiSelectFilter,
    AutocompleteFilter,
    ComboboxFilter,
    ComboboxArrayFilter,
    DatepickerFilter,
    CheckboxFilter,
    CompareFilter,
} from './classes';

export * from './classes';

export const filterClass = {
    Base: BaseFilter,
    Input: InputFilter,
    Select: SelectFilter,
    SelectWithHeaders: SelectWithHeadersFilter,
    Multiselect: MultiSelectFilter,
    Autocomplete: AutocompleteFilter,
    Combobox: ComboboxFilter,
    ComboboxArray: ComboboxArrayFilter,
    Datepicker: DatepickerFilter,
    Checkbox: CheckboxFilter,
    Compare: CompareFilter,
};
