var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-card',[_c('div',{staticClass:"create-payment__body",class:{'create-payment__body-with-invoice-block': _vm.isInvoice}},[(_vm.isInvoice)?_c('div',{staticClass:"create-payment__invoice-block"},[_c('div',{staticClass:"base-list__item"},[_c('div',{staticClass:"base-list__text"},[_vm._v(" Счёт ")]),_c('div',{staticClass:"base-list__value"},[_c('base-autocomplete',{staticClass:"create-payment__select-invoice-input",attrs:{"data":{
                            placeholder: 'Выберите счёт',
                            itemText: 'itemText',
                            itemValue: 'id',
                            template: (item) => item.itemText,
                            menuProps: {
                                maxHeight: 180
                            }
                        },"possible-items":_vm.unpaidInvoices,"is-return-object":true},on:{"change":_vm.setInvoice}})],1)]),_c('div',{staticClass:"base-list__item"},[_c('div',{staticClass:"base-list__text"},[_vm._v(" Доступно к выплате ")]),_c('div',{staticClass:"base-list__value"},[_c('v-text-field',{attrs:{"value":_vm.getAvailablePaidSum,"disabled":"","hide-details":""}})],1)]),_c('div',{staticClass:"base-list__item"},[_c('div',{staticClass:"base-list__text"},[_vm._v(" Сумма выплаты ")]),_c('div',{staticClass:"base-list__value"},[_c('edit-mode-text-field',{attrs:{"value":_vm.paidSumValue,"suffix":_vm.unpaidInvoice.currency,"digits-before-comma":9,"is-sticky-suffix":true},on:{"update:value":function($event){_vm.paidSumValue=$event}}})],1)])]):_c('div',{staticClass:"base-list__item"},[_c('div',{staticClass:"base-list__text"},[_vm._v(" Сумма выплаты ")]),_c('div',{staticClass:"base-list__value"},[_c('div',{staticClass:"create-payment__payout-sum"},[_c('edit-mode-text-field',{staticClass:"create-payment__payout-sum__number",attrs:{"value":_vm.paidSumValue,"digits-before-comma":9},on:{"update:value":function($event){_vm.paidSumValue=$event}}}),_c('base-autocomplete',{staticClass:"create-payment__payout-sum__currency",attrs:{"data":{
                            itemValue: 'itemValue',
                            itemText: 'itemText',
                            template: (item) => item.itemText
                        },"is-auto-select-first":true,"possible-items":_vm.getCurrencyItems},on:{"change":function($event){return _vm.input('currency', $event)}}})],1)])]),_c('div',{staticClass:"base-list__item"},[_c('div',{staticClass:"base-list__text"},[_vm._v(" Период ")]),_c('div',{staticClass:"base-list__value"},[_c('date-time-picker-new',{ref:"datepicker",attrs:{"without-time":true,"is-inline":true,"available-dates":() => ({ start: null, end: null, days: [1, 15] })},on:{"dayclick":_vm.onDayClick},model:{value:(_vm.paymentSync.period),callback:function ($$v) {_vm.$set(_vm.paymentSync, "period", $$v)},expression:"paymentSync.period"}})],1)]),_c('div',{staticClass:"base-list__item"},[_c('div',{staticClass:"base-list__text"},[_vm._v(" Способ выплаты ")]),_c('div',{staticClass:"base-list__value"},[_c('base-autocomplete',{attrs:{"data":{
                        itemValue: 'id',
                        template: (item) => item.itemText,
                        menuProps: {auto: true},
                    },"possible-items":_vm.methods},on:{"change":_vm.changeMethod}})],1)]),_vm._l((_vm.requisites),function(item){return _c('div',{key:item,staticClass:"base-list__item"},[_c('div',{staticClass:"base-list__text"},[_vm._v(" "+_vm._s(_vm.translatePaymentsRequisite(item))+": ")]),_c('div',{staticClass:"base-list__value"},[(_vm.isContract)?_c('base-autocomplete',{staticClass:"base__value base__value--full-width",attrs:{"data":{
                        errorText: 'Вебмастера не найдены',
                        apiMethod: _vm.searchWebmasters,
                        itemValue: 'guid',
                        itemText: 'name',
                        placeholder: 'Введите id или название организации',
                        rules: [(v) => !!v || 'Поле не должно быть пустым'],
                        template: (i) => i.itemText,
                    },"is-return-object":true},on:{"change":function($event){return _vm.addRequisite('contract', $event)}}}):_c('v-text-field',{attrs:{"value":_vm.getRequisiteName(item),"rules":[(v) => !!v || 'Поле не должно быть пустым'],"type":"text","required":"","hide-details":"","hint":_vm.hintRequisite()},on:{"input":function($event){return _vm.addRequisite(item, $event)}}})],1)])})],2),_c('div',{staticClass:"base-list__item create-payment__comment"},[_c('div',{staticClass:"base-list__text"},[_vm._v(" Комментарий ")]),_c('div',{staticClass:"base-list__value"},[_c('v-textarea',{staticClass:"base-textarea",attrs:{"auto-grow":"","outlined":"","hide-details":""},model:{value:(_vm.paymentSync.comment),callback:function ($$v) {_vm.$set(_vm.paymentSync, "comment", $$v)},expression:"paymentSync.comment"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }