
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import SvgAdvertisers from '@/assets/icons/nav-bar/advertisers.svg';
import { IFilter } from '@/types';
import store from '@/store';
import {
    searchWebmasters,
} from '@/api/org-cabinets-backend';
import PaginationRight from '@/components/base/table/PaginationRight.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import Filters from '@/components/base/filters/Filters.vue';
import ReportTable from '@/components/orgstats/ReportTable.vue';
import { IOrgstatsReport } from '@/api/types/payout';
import { getLegalEntitySearch } from '@/api/revenue';
import { getLegalEntitySearchGuid, getWebmastersSearchGuid } from '@/api/orgstats';
import FilterGuid from '@/mixins/filterGuid';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        ReportTable,
        SvgAdvertisers,
        PaginationRight,
        StatsCard,
        Filters,
    },
})
export default class ReportContent extends Mixins(FilterGuid) {
    @Prop({ default: false }) isOffer!: boolean;
    @Prop({ required: true }) reportApiMethod!: any;

    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;

    headers = [
        { text: 'Валюта', sortable: false, value: 'currency' },
        { text: 'Уникальные клики', sortable: false, value: 'clicks' },
        { text: 'Всего', sortable: false, value: 'leads_total' },
        { text: 'Принято', sortable: false, value: 'leads_approved' },
        { text: 'CR', sortable: false, value: 'metric_cr' },
        { text: 'AR', sortable: false, value: 'metric_ar' },
        { text: 'EPC', sortable: false, value: 'metric_epc' },
        { text: 'EPL', sortable: false, value: 'metric_epl' },
        { text: 'Выплата (принято)', sortable: false, value: 'payout_total', align: 'right' },
    ];

    settings = {
        title: 'Отчёт по партнёрам',
        loading: false,
    };

    dataTable: IOrgstatsReport[] = [];
    isNotFound = false;
    filters: IFilter[] = [];
    isParseRouteUrl = false;
    payoutTotal: number = 0;

    get textErrorField(): string[] {
        const errorFields: string[] = [];
        if (!this.getFiltersParamsObject.hasOwnProperty('period_from')
                && !this.getFiltersParamsObject.hasOwnProperty('period_to')) {
            errorFields.push('период');
        }
        return errorFields;
    }

    created(): void {
        this.setDefaultFiltersParams({});
        this.setFiltersParamsObject({});
        if (this.$props.isOffer) {
            this.headers.splice(1, 0, { text: 'ID оффера', sortable: false, value: 'offer_id' });
        } else {
            this.headers.splice(1, 0, { text: 'Вебмастер', sortable: false, value: 'webmaster_name' });
        }
        this.filters = [
            new filterClass.Autocomplete({
                id: 'leadgid_org_guids',
                label: 'Организация',
                apiMethod: getLegalEntitySearch,
                itemValue: 'external_id',
                itemText: 'name',
                select: [],
                multiple: true,
                apiParseUrl: false,
                isErrorIcon: false,
                type: 'string',
            }),
            new filterClass.Autocomplete({
                id: 'webmaster_guids',
                label: 'Вебмастер',
                apiMethod: searchWebmasters,
                itemValue: 'guid',
                itemText: 'name',
                select: [],
                type: 'string',
                multiple: true,
                apiParseUrl: false,
            }),
            new filterClass.ComboboxArray({
                id: 'offer_ids',
                label: 'ID Оффера',
                select: [],
                type: 'number',
            }),
            new filterClass.Datepicker({
                id: 'period',
                label: 'Период',
                pickerType: 'month',
                select: [],
                isErrorIcon: true,
                range: true,
            }),
        ];
    }

    mounted(): void {
        this.isParseRouteUrl = Object.keys(this.$route.query).length > 1;
        // без указания фильтров запрос на бек не нужен
        if (this.isParseRouteUrl) {
            this.getReportPagination();
        }
    }

    clearFilters(): void {
        this.isNotFound = false;
        this.dataTable = [];
        this.setFiltersParamsObject({});
        this.payoutTotal = 0;
    }

    getFilterWidthGuids(): void {
        if (this.isParseRouteUrl) {
            this.getFilterGuidItems(this.filters, 'webmaster_guids', getWebmastersSearchGuid);
            this.getFilterGuidItems(this.filters, 'leadgid_org_guids', getLegalEntitySearchGuid);
        }
    }

    async getReportPagination(): Promise<void> {
        this.settings.loading = true;
        try {
            await this.getFilterWidthGuids();
            const result = await this.$props.reportApiMethod(this.getFiltersParamsObject);
            this.dataTable = [];
            this.payoutTotal = result instanceof Array ? 0 : result.payout_summary;
            for (const i in result.items) {
                if (result.items.hasOwnProperty(i)) {
                    this.dataTable = [...result.items[i]];
                }
            }
            this.isNotFound = this.dataTable.length <= 0;
        } catch (err) {
            const emptyRequiredFields = this.textErrorField?.length > 0
                ? `Не указаны обязательные поля: ${this.textErrorField}`
                : '';
            const message = this.$props.isOffer ? 'Отчёт по офферам не загружен' : 'Отчёт по партнёрам не загружен';
            store.commit('SET_SERVER_ERROR', {
                status: err.status,
                message,
                subtitle: emptyRequiredFields,
            });
        }
        this.settings.loading = false;
    }
}
