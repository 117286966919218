
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({})

export default class TooltipButton extends Vue {
    @Prop({ type: Boolean, default: false }) small!: boolean;
    @Prop({ type: Boolean, default: false }) xSmall!: boolean;
    @Prop({ type: Boolean, default: false }) isDisabled!: boolean;
    @Prop({ type: Boolean, default: false }) dark!: boolean;
    @Prop({ type: Boolean, default: false }) flat!: boolean;
    @Prop({ type: Boolean, default: true }) ripple!: boolean;
    @Prop({ type: Boolean, default: true }) isIcon!: boolean;
    @Prop({ type: String, default: '' }) text!: string;
    @Prop({ type: Number, default: 700 }) delay!: number;
    @Prop({ type: String, default: 'add' }) icon!: string;
    @Prop({ type: String, default: '' }) color!: string;
    @Prop({ type: String, default: 'top' }) direction!: string;
    @Prop({ type: String, default: 'bottom' }) nudgeDirection!: string;
    @Prop({ type: Number, default: 0 }) nudge!: number;
    @Prop({ type: Boolean, default: false }) isLoading!: boolean;
    @Prop({ default: undefined }) transition!: any;
    @Prop({ type: Object }) router!: {} | undefined;

    get editingMode(): boolean {
        return this.getEditingMode;
    }

    get getNudgeDirection(): string {
        return `nudge-${this.nudgeDirection}`;
    }

    get isDisabledTooltip(): boolean {
        return this.$vuetify.breakpoint.width <= 769;
    }

    @Getter('GET_EDITING_MODE') getEditingMode;

    click(): void {
        this.$emit('click');
    }
}
