
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOfferService, ICustomerOrders } from '@/api/types/revenue';
import { NETTING_TYPE } from '@/mappings/netting-type';

// Создаём константы для стора revenueModule
const revenue = namespace('revenueModule');

@Component
export default class BookKeepingTop extends Vue {
    @Prop() customerOrders!: ICustomerOrders[];
    @Prop({ type: Number, default: 0 }) totalAmountOffersList!: number;
    @Prop({ type: Number, default: 0 }) totalAmountServicesList!: number;
    @Prop({ type: Number, default: 0 }) invoiceAmount!: number;
    @Prop({ type: Number, default: 0 }) offerAndServicesAmount!: number;
    @Prop({ type: Number, default: 0 }) totalSumOfNetting!: number;
    @Prop({ type: String, default: 'none' }) nettingType!: string;
    @Prop({ type: String, default: '' }) getCurrencyIcon!: string;

    @revenue.Getter('GET_ACCOUNTING_AMOUNT') getAccountingAmount;

    NETTING_TYPE = NETTING_TYPE;

    get sumCustomerOrders(): number {
        // сумма по всем бух.счетам
        return this.$props.customerOrders!.reduce((acc, { sumOfInvoice }) => acc + sumOfInvoice!, 0);
    }

    // Посчитать сумму прямой выручки всех бухгалтерских счетов
    get getTotalDirectIncomeOfAllAccounts(): number {
        const allOfferServicesFromCustomerOrders = this.$props.customerOrders!
            .filter((item) => item.offer_services!.length > 0);
        const offerServicesItems = allOfferServicesFromCustomerOrders.map((item) => item.offer_services);

        const offersWithDirectRevenue: IOfferService[] = [];
        offerServicesItems.forEach((invoiceAccount) => {
            const newItem = invoiceAccount!.filter((offerService) => offerService.offer_direct);
            offersWithDirectRevenue.push(newItem as IOfferService);
        });

        const itemsWithTotal: IOfferService[] = [];
        offersWithDirectRevenue.forEach((offerServices) => {
            // @ts-ignore
            const newItem = offerServices.filter((item) => item.total);
            itemsWithTotal.push(newItem);
        });

        const combinedArrayWithTotals: IOfferService[] = [];
        itemsWithTotal.forEach((item) => {
            // @ts-ignore
            item.map((val) => combinedArrayWithTotals.push(val.total));
        });

        return combinedArrayWithTotals
            .reduce((a, b) => Number(a) + Number(b), 0) || 0;
    }
}
