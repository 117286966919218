
import { Component, Mixins } from 'vue-property-decorator';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { getEndpoints } from '@/api/uapi';
import { IEndpointList } from '@/api/types/uapi';
import { eventBus } from '@/eventbus';
import { showServerError } from '@/utils';
import { GetInfo } from '@/utils/getInfo';
import { getUsers } from '@/api/user';
import { IUser } from '@/api/types/user';
import SortTableMixin from '@/mixins/sortTableMixin';
import { Getter } from 'vuex-class';

@Component({
    components: {
        SvgApplication,
    },
})
export default class Endpoints extends Mixins(SortTableMixin) {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    headers = [
        {
            text: 'ID',
            value: 'id',
            align: 'center',
        },
        {
            text: 'Кампания',
            value: 'endpoint',
        },
        {
            text: 'Подключено потоков',
            value: 'streams',
            align: 'center',
            sortable: false,
        },
        {
            text: ' Дата создания',
            value: 'created_at',
        },
        {
            text: 'Автор',
            value: 'created_by',
            align: 'center',
        },
    ];
    totalElementDataTable = 0;
    authorNames: IUser[] = [];
    dataTable: IEndpointList[] = [];
    settings = {
        title: 'Кампании',
        loading: false,
    };
    uniqueData = [
        {
            slot: 'item.created_by',
            template: (): void => undefined,
        },
        {
            slot: 'item.streams',
            template: (item: IEndpointList): number => item.streams.length,
        },
    ];
    routes = [
        {
            slot: 'item.id',
            routerName: 'edit-endpoint',
            routeParam: 'endpointId',
            extraRouteParamName: 'id',
            text: 'id',
        },
    ];

    created(): void {
        // используется для сортировки
        // нужен в sortTableMixins
        this.field = 'id';
    }

    async getDataTable(offset?: number, limit?: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        this.settings.loading = true;
        const paramsObj = { ...this.getFiltersParamsObject, ...this.getSortFilters };
        try {
            const { meta, data } = await getEndpoints({ offset, limit , ...paramsObj });
            this.dataTable = data;
            this.totalElementDataTable = meta!.total!;
            this.authorNames = await new GetInfo(this.dataTable, 'created_by', getUsers).getInfoByFields();
        } catch (err) {
            showServerError(err, 'Не удалось загрузить список кампаний');
        }
        this.settings.loading = false;
    }

    getTemplate(item: IEndpointList): number | string {
        const authorName = this.authorNames.find(({ account_id }) => account_id === item.created_by);
        if (authorName) {
            return `${authorName.first_name} ${authorName.last_name}`;
        }
        return item.created_by!;
    }
}
