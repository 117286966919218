
import { Component, Vue } from 'vue-property-decorator';
import StatsCard from '@/components/base/StatsCard.vue';
import { getAffiliateSearch } from '@/api/user';
import { showServerError } from '@/utils';
import { MAX_LIMIT_FOR_SELECT, SEARCH_VALUE_DEBOUNCE } from '@/configs/global';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { getReports } from '@/api/antifraud';
import { IAntifraudReport } from '@/api/types/antifraud';

@Component({
    components: { StatsCard },
})
export default class Antifraud extends Vue {

    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    period = [];
    menu = false;
    loading = false;
    currentReportExists = false;
    currentReport = {} as IAntifraudReport;

    affiliate: {
        account_id?: number,
        name?: string,
    } = {};

    autocomplete: {
        searchValue?: string,
        searchTimerId: number,
        affiliates: Array<{name: string, account_id: number}>,
        loading: boolean,
    } = {
            searchTimerId: 0,
            affiliates: [],
            loading: false,
        };

    commonReportKeys: {[key: string]: string} = {
        referer_score: 'Наличие источников в referer',
        user_agent_score: 'Повторение user agent',
        ip_score: 'Повторение IP-адреса',
        cr_score: 'Средний CR',
        uniqueness_score: 'Уникальность кликов',
        target_application_score: 'Доля кликов на офферы за заявку',
        score: 'Итоговый балл',
    };

    get currentReportProperties(): boolean {
        return Object.keys(this.currentReport).length > 0;
    }

    created(): void {
        this.autocomplete.searchValue = '';
    }

    debounceGetAffiliateSearch(): void {
        if (this.autocomplete.searchValue) {
            clearTimeout(this.autocomplete.searchTimerId);
            this.autocomplete.searchTimerId = window.setTimeout(() => {
                const params: {
                    query: string,
                    limit: number,
                    show_is_deleted: number,
                } = {
                    query: this.autocomplete.searchValue || '',
                    limit: MAX_LIMIT_FOR_SELECT,
                    show_is_deleted: 1,
                };
                this.getAffiliateSearch(params);
            }, SEARCH_VALUE_DEBOUNCE); /* 850ms задержка */
        }
    }

    async getAffiliateSearch(params: {
        query: string,
        limit: number,
        show_is_deleted: number,
    }): Promise<void> {
        this.autocomplete.loading = true;
        try {
            const { data } = await getAffiliateSearch(params);
            data.forEach((item) => {
                this.autocomplete.affiliates.push({
                    name: `${item.id} ${item.first_name} ${item.last_name}/${item.email}`,
                    account_id: item.id!,
                });
            });
        } catch (err) {
            showServerError(err, 'Не удалось получить список партнёров');
        }
        this.autocomplete.loading = false;
    }

    async check(): Promise<void> {
        try {
            this.loading = true;
            this.currentReportExists = true;
            this.currentReport = await getReports({
                affiliate_id: this.affiliate.account_id,
                period_from: this.period.reduce((a, b) =>  a < b ? a : b),
                period_to: this.period.reduce((a, b) =>  a > b ? a : b),
            });
        } catch (err) {
            showServerError(err, 'Не удалось загрузить отчет');
            this.currentReportExists = false;
        }
        this.loading = false;
    }

    clear(): void {
        this.period = [];
        this.currentReport = {} as IAntifraudReport;
        this.affiliate = {};
        this.currentReportExists = false;
    }
}
