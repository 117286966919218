var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tag-select"},[_c('base-autocomplete',{staticClass:"tag-select__tag-input --blue-dashed",attrs:{"data":{
            itemText: 'name',
            itemValue: 'id',
            multiple: true,
            maxShowItems: 100,
            template: (i) => i.name
        },"no-need-trash":true,"is-create-mode":true,"create-btn-text":"Новый тег","possible-items":_vm.tags,"default-search":_vm.savedDefaultValue},on:{"change":function($event){_vm.valueSync = $event},"open-create-modal":function($event){_vm.isShowModal = true},"blur":_vm.blurTag}}),(_vm.isLoading)?_c('loader'):_vm._e(),_c('form-modal',{attrs:{"show-modal":_vm.isShowModal,"title":"Создание новых тегов","custom-class":"tag-select__creation-modal","max-width":"670","max-height":"270","need-hide-btn":false,"is-need-already-click":false,"closable":true,"is-disabled":_vm.newTags.length === 0},on:{"close-modal":_vm.closeModal,"save":_vm.saveNewTag}},[[_c('combobox-array-design',{attrs:{"filter":{id: 'tag', select: _vm.newTags},"is-need-change-emit":true,"max-show-items":100},on:{"change":function($event){_vm.newTags = $event},"trash":function($event){_vm.newTags = []}}})]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }