
import { Vue, Component, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { Affiliate } from "@/services/affiliates/Affiliate";
import { IEmployeeAffiliateMethod } from "@/api/types/payout";
import {
    translatePaymentsRequisite,
    translatePayoutsRequestStatus,
} from "@/utils/translate";
import SvgStar from "@/assets/icons/star.svg";
import FormModal from "@/components/base/FormModal.vue";
import TitleActions from "@/components/affiliate/TitleActions.vue";
import { ITableStatus } from "@/types";

@Component({
    components: { TitleActions, FormModal, TableWithFrontPaginationDesign, BaseCard, SvgStar },
})

export default class AffiliatePaymentInfo extends Vue {
    @Prop({ required: true }) affiliate!: Affiliate;

    isLoading: boolean = false;
    isShowModal: boolean = false;
    methodId: string | number = '';
    selectedStatusFilter: string = 'all';
    selectedCabinetFilter: boolean | null = null;

    headers = [
        { text: 'Способ выплаты', value: 'method_name' },
        { text: 'Реквизиты', value: 'requisites' },
        { text: 'Дата создания', value: 'created_at' },
        { text: 'Дата обновления', value: 'updated_at' },
        { text: 'Статус', align: 'center', value: 'status' },
        { text: '', value: 'actions', sortable: false, width: '40px' },
    ];

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
        },
        {
            slot: 'item.updated_at',
            key: 'created_at',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translatePayoutsRequestStatus,
        },
    ];

    actions = [
        {
            title: 'Отклонить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            disabled: (item: any): boolean => {
                return ['manager_rejected', 'partner_rejected', 'deleted'].includes(item.status);
            },
            callback: ({ id }: {id: number }): void => {
                this.rejectOrApprove('reject', id);
            },
        },
        {
            title: 'Принять',
            color: '#1B1B1D',
            icon: 'SvgCheck',
            disabled: (item: any): boolean => {
                return ['approved', 'deleted'].includes(item.status);
            },
            callback: ({ id }: {id: number }): void => {
                this.rejectOrApprove('approve', id);
            },
        },
    ];

    uniqueData = [
        {
            slot: 'item.requisites',
        },
        {
            slot: 'item.method_name',
        },
    ];

    get isLoadingTable(): boolean {
        return this.affiliate.isLoadingData || this.isLoading;
    }

    get getItems(): IEmployeeAffiliateMethod[] {
        const items = this.affiliate.payment_info!;
        if (!!items && items.length > 0) {
            const defaultMethodIndex = items.findIndex(i => i.is_default);
            const defaultMethod = items.splice(defaultMethodIndex, 1)[0];
            items.unshift(defaultMethod);
        }
        return items;
    }

    showModal(id: string | number): void {
        this.methodId = id;
        this.isShowModal = true;
    }

    hideModal(): void {
        this.methodId = '';
        this.isShowModal = false;
    }

    async makeDefault(): Promise<void> {
        this.isLoading = true;
        await this.affiliate.makeDefaultMethod(this.methodId);
        this.hideModal();
        this.isLoading = false;
    }

    async rejectOrApprove(type: string, id: number): Promise<void> {
        this.isLoading = true;
        await this.affiliate[`${type}Method`](id, this.selectedStatusFilter, this.selectedCabinetFilter);
        this.isLoading = false;
    }

    getRequisites(i: IEmployeeAffiliateMethod): { value: string, type: string }[] {
        const isPartnerData = !Array.isArray(i?.partner_data!) && i?.partner_data!.length !== 0;
        const result = [] as { value: string, type: string }[];
        const constructor = (items: any) => {
            for (const key in items) {
                if (key !== 'legal_id') {
                    const type = translatePaymentsRequisite(key);
                    const value = String(items[key]);
                    result.push({ value, type });
                }
            }
        };

        if (isPartnerData) {
            constructor(i?.partner_data!);
        } else {
            constructor(i?.manager_data);
        }

        return result;
    }
}

