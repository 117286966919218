
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import Navigation from '@/components/layout/Navigation.vue';
import EnvToggler from '@/components/layout/EnvToggler.vue';
import MainContent from '@/components/layout/MainContent.vue';
import ErrorModal from '@/components/base/ErrorModal.vue';
import SvgClose from'@/assets/icons/close.svg';
import store from '@/store';

const statistics = namespace('statisticsModule');

@Component({
    name: 'App',
    components: {
        ErrorModal,
        EnvToggler,
        Navigation,
        MainContent,
        SvgClose,
    },
})
export default class App extends Vue {
    @Getter('GET_NOTIFICATION') notification;
    @Mutation('SET_NOTIFICATION') setNotification;
    @Mutation('RESET_NOTIFICATION') resetNotification;
    @Mutation('SET_IS_MINI_NAV') setIsMiniNav;
    @Getter('IS_MINI_NAV') isMiniNav;
    @statistics.Action('CHECK_CONVERSIONS_REPORT') checkConversionsReport;
    @statistics.Action('CANCEL_BIG_CONVERSIONS_REPORT') cancelBigReport;

    get routerName(): string | void {
        return this.$route.name || '';
    }

    get error(): {} {
        return store.getters.GET_SERVER_ERROR;
    }

    async mounted(): Promise<void> {
        this.resetNotification();
        try {
            await this.checkConversionsReport();
        } catch(err) {
            console.error(err);
        }

        if (!window.noAdBlock) {
            store.commit('SET_SERVER_ERROR',
                { message: 'AdBlock включен!' });
        }
    }

    beforeDestroy(): void {
        this.setIsMiniNav(true);
    }
}
