
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import EditIconBtn from "@/components/base/buttons/EditIconBtn.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getEmployeesOffersV2, getOfferTrackingDomainsList, getOfferTrackingList } from "@/api/offers";
import { IEditableOfferFields, IOffer, IOfferSearch, ITracking, ITrackingDomain } from "@/api/types/offers";
import { getNumEnding } from "@/utils";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { translateBoolean, translateOffersStatus } from "@/utils/translate";
import QuestionMarkTooltip from "@/components/base/QuestionMarkTooltip.vue";
import CookiesSelect from "@/components/base/form/CookiesSelect.vue";
import cookiesSelectItems from "@/mappings/cookies-select-items";
import MaskedTextField from "@/components/base/form/MaskedTextField.vue";
import { eventBus } from "@/eventbus";
import { namespace } from "vuex-class";

const offers = namespace('offersModule');

interface ITrackingOptions {
    type: string;
    id: string;
    supId?: string;
    supValue?: string;
    items?: ITracking[] | ITrackingDomain[];
    itemText?: string;
    itemValue?: string;
    api?: () => Promise<ITracking[] | ITrackingDomain[]>;
    key?: number;
    tooltip?: string
    trueValue?: boolean | string;
    falseValue?: boolean | string;
}

@Component({
    components: {
        MaskedTextField,
        CookiesSelect,
        QuestionMarkTooltip,
        BaseSelect,
        BaseAutocomplete,
        EditIconBtn, BaseCard,
    },
})
export default class OfferCardTracking extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @offers.Action('GET_SELECTS_ITEMS') getSelectItems;
    @offers.State('selects_items') selectsItems!: { [key: string]: any[] | undefined };

    isEdit = false;
    offerData: IEditableOfferFields = {} as IEditableOfferFields;
    redirect: IOfferSearch | null = null;
    translateBoolean = translateBoolean;
    cookiesSelectItems = cookiesSelectItems;
    cookiesValue = 0;
    userCookiesValue = 0;

    trackingOptions: ITrackingOptions[] = [
        { type: 'select', id: 'tracking_protocol', supId: 'type', supValue: 'name', items: [], itemText: 'name', itemValue: 'type', api: getOfferTrackingList, key: 0 },
        { type: 'redirect', id: 'redirect_offer_id' },
        { type: 'session', id: 'session_hours' },
        { type: 'hours', id: '' },
        { type: 'switch', id: 'approve_conversions' },
        { type: 'switch', id: 'multiple_conversions' },
        { type: 'select', id: 'tracking_domain', supId: 'id', supValue: 'domain', items: [], itemText: 'domain', itemValue: 'id', api: getOfferTrackingDomainsList, key: 0 },
        { type: 'switch', id: 'deep_links_enabled' },
        { type: 'switch', trueValue: 'allow', falseValue: 'reject', id: 'non_unique_click_attribution' },
        { type: 'switch', trueValue: 'disabled', falseValue: 'reject', id: 'proactive_click_fraud_prevention' },
        { type: 'switch', id: 'enforce_secure_tracking_link' },
    ];

    get getTrackingOptions(): ITrackingOptions[] {
        return this.isEdit && this.cookiesValue === 0 ? this.trackingOptions : this.trackingOptions.filter(i => i.type !== 'hours');
    }

    get getCookiesDefaultValues(): { [key: number]: string } {
        return Object.fromEntries(this.cookiesSelectItems.map(i => ([i.id, i.text])));
    }

    get getSessionHoursValue(): string {
        const ends = ['час', 'часа', 'часов'];
        const v = this.offer.session_hours;
        return this.getCookiesDefaultValues[v] || `${v} ${getNumEnding(v, ends)}`;
    }

    get getRedirectData(): any {
        return {
            errorText: 'Оффер не найден',
            apiMethod: getEmployeesOffersV2,
            key: 'search',
            itemValue: 'legacy_id',
            clearable: true,
            template: (i: IOffer) => i.legacy_id + ' ' + i.name + this.getRedirectOfferStatus(i),
            placeholder: 'Выберите оффер',
        };
    }

    getValue(item: ITrackingOptions): number | string | boolean {
        const targetProp = this.offer[item.id];
        const value = item.supValue && targetProp ? targetProp[item.supValue] : targetProp;
        return item.id === 'approve_conversions' ? !value : value;
    }

    setValues(): void {
        const options = this.trackingOptions.filter(opt => !['redirect', 'hours', 'session'].includes(opt.type));
        const booleanValues = ['multiple_conversions', 'deep_links_enabled'];
        options.forEach(opt => {
            const value = this.offer[opt.id] && opt.supId ? this.offer[opt.id][opt.supId] : this.offer[opt.id];
            this.offerData[opt.id] = booleanValues.includes(opt.id) ? !!value : opt.id === 'approve_conversions' ? !value : value;
        });
        this.selectCookies(this.offer.session_hours!);
    }

    editInfo(isEdit: boolean): void {
        if (isEdit) {
            this.getItems();
            this.setValues();
        } else {
            this.updateOffer();
        }
        this.isEdit = isEdit;
        if (!isEdit) {
            setTimeout(() => this.setRedirectWidth(), 1000);
        }
    }

    getRedirectOfferStatus(item: IOffer): string {
        return item.status ? ' (' + translateOffersStatus(item.status).text + ')' : '';
    }

    getRedirectOffer(item: IOfferSearch): void {
        this.redirect = item;
    }

    selectCookies(value: number): void {
        const isDefValue = this.getCookiesDefaultValues[value];
        if (isDefValue && value !== 0) {
            this.offerData.session_hours = value;
            this.cookiesValue = value;
        } else {
            this.cookiesValue = 0;
            this.userCookiesValue = value;
            this.offerData.session_hours = value;
        }
    }

    selectUserCookies(value: number): void {
        const val = value > 17119 ? 17119 : value;
        this.userCookiesValue = val;
        this.offerData.session_hours = val;
    }

    async getItems(): Promise<void> {
        try {
            this.isLoadingSync = true;
            const selects = this.trackingOptions.filter(opt => opt.type === 'select');
            if (selects.every(s => s.items && s.items.length > 0)) return;

            const requestParams = {};
            const setItems = () => {
                selects.forEach(select => {
                    const items = this.selectsItems[select.id] || [];
                    if (items.length === 0) {
                        requestParams[select.id] = { property: select.id, api: select.api };
                    } else {
                        select.items = items;
                    }
                });
            };
            setItems();
            if (Object.values(requestParams).length === 0) return;
            await this.getSelectItems(Object.values(requestParams));
            setItems();
        } finally {
            this.isLoadingSync = false;
        }
    }

    get isRedirectChanged(): boolean {
        return (this.redirect !== null || this.offer.redirect_offer_id !== null)
            && this.redirect?.legacy_id !== this.offer.redirect_offer_id;
    }

    async updateOffer(): Promise<void> {
        this.isLoadingSync = true;
        const redirectValue = this.redirect?.id ? this.redirect?.id : null;
        const isUpdateRedirect = this.isRedirectChanged ?
            await this.offer.setRedirect(redirectValue) : false;
        const isUpdate = await this.offer.editOffer(this.offerData);
        if (isUpdateRedirect || isUpdate) eventBus.$emit("update-offer");
        this.isLoadingSync = false;
    }

    mounted(): void {
        window.addEventListener('resize', this.setRedirectWidth);
        setTimeout(() => this.setRedirectWidth(), 500);
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.setRedirectWidth);
    }

    setRedirectWidth(): void {
        const redirectWrap = document.querySelector('.offer-card-tracking__redirect-wrap') as HTMLElement;
        const redirectLink = redirectWrap.querySelector('.offer-card-tracking__redirect-link') as HTMLElement;
        const textWidth = (this.$vuetify.breakpoint.width <= 576 ? 175 : 250) + (this.$vuetify.breakpoint.width > 1240 ? 60 : 20);
        redirectWrap.style.width = `${this.$el.clientWidth - textWidth}px`;
        if (redirectLink) {
            redirectLink.style.width = redirectWrap.clientWidth > redirectLink.clientWidth ? 'fit-content' : 'inherit';
        }
    }
}
