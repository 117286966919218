
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { IFilter, TableHeader } from "@/types";
import SvgOffers from "@/assets/icons/notification/offers.svg";
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";

@Component({
    components: {
        FiltersDialog,
        BaseAutocomplete,
        SelectDesign,
        TableWithFrontPaginationDesign,
        BaseCard,
        SvgOffers,
    },
})
export default class NotificationDetailTable extends Vue {
    @Prop({ required: true }) items!: any[];
    @Prop({ required: true }) icon!: any;
    @Prop({ required: true }) filters!: IFilter[];
    @Prop({ required: true }) title!: string;
    @Prop({ type: Array, default: () => [] }) headers!: TableHeader[];
    @Prop({ type: Array, default: () => [] }) statuses!: [];
    @Prop({ type: Array, default: () => [] }) routes!: [];

    isShowFilters = false;

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 700;
    }

    compareFilter(item: any, filter: IFilter): boolean {
        return filter.select ? item[filter.id] === filter.select : true;
    }

    get filteredItems(): any {
        return this.items.filter(i => this.filters.every(filter => this.compareFilter(i, filter)));
    }
}
