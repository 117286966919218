
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { IFilter } from "@/types";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import { COMPARE_OPERATORS } from "@/services/filters/classes";

@Component({
    components: { SvgArrow },
})
export default class CompareFilter extends Vue {
    @Prop({ required: true }) filter!: IFilter;
    @Ref() firstValue!: any;

    mainValue: number | null = null;
    addValue: number | null = null;
    compareType: string | COMPARE_OPERATORS = COMPARE_OPERATORS.GT;
    types = [
        { text: '>', value: COMPARE_OPERATORS.GT },
        { text: '≥', value: COMPARE_OPERATORS.GTE },
        { text: '<', value: COMPARE_OPERATORS.LT },
        { text: '≤', value: COMPARE_OPERATORS.LTE },
        { text: '↔', value: COMPARE_OPERATORS.BETWEEN },
    ];

    get isBetween(): boolean {
        return this.compareType === COMPARE_OPERATORS.BETWEEN;
    }

    mounted(): void {
        this.setValuesFromFilter();
    }

    setValuesFromFilter(): void {
        const select = this.filter.select;
        if (Array.isArray(select) && select.length > 0) {
            this.mainValue = select[0] || null;
            this.addValue = select[1] || null;
            this.compareType = this.filter.operator!;
        }
    }

    updateType(): void {
        if (this.isBetween) {
            this.firstValue.isFocused = true;
        }

        this.updateFilter();
    }

    updateValue(value: string, isAddValue: boolean = false): void {
        const updateField = (field: string) => {
            this.$nextTick(() => this[field] = Number(value.replace(/[^0-9]/g, '')));
        };

        updateField(isAddValue ? 'addValue' : 'mainValue');
        this.updateFilter();
    }

    updateValueOnBlur(): void {
        if (!this.addValue) return;
        if (this.addValue <= this.mainValue!) {
            this.addValue = this.mainValue! + 1;
        }
        this.updateFilter();
    }

    updateFilter(): void {
        this.filter.select = !!this.addValue ? [+this.mainValue!, +this.addValue!] : [+this.mainValue!];
        this.filter.operator = this.compareType;
    }

    @Watch('filter', { deep: true })
    onFilterChange(): void {
        if (this.filter.select.length === 0) {
            this.mainValue = null;
            this.addValue = null;
            this.compareType = COMPARE_OPERATORS.GT;
        }
    }
}
