
import { Vue, Component, PropSync } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import { IEmployeePayment } from "@/api/types/payout";
import { translatePaymentsStatusConfirmation, translatePaymentType } from "@/utils/translate";
import { getFormatDatesForDatepicker } from "@/utils/formatDates";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { searchWebmasters } from "@/api/org-cabinets-backend";
import { getOnePayment, patchPayment } from "@/api/payout";
import { showNotification, showServerError } from "@/utils";
import SvgReset from "@/assets/icons/reload.svg";
import Status from '@/components/base/Status.vue';
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import EditBtn from "@/components/base/buttons/EditBtn.vue";

@Component({
    components: { EditBtn, EditModeTextField, BaseAutocomplete, DateTimePickerNew, ViewDateTime, BaseCard, Status, SvgReset },
})

export default class PaymentDetail extends Vue {
    @PropSync('payment', { required: true }) paymentSync!: IEmployeePayment;

    isEditMode: boolean = false;
    isPartnerData: boolean = false;
    sumValue: number = 0;
    periodValue: string | null = null;
    commentValue: string = '';
    earlyPaymentID: string = '';
    requisites: {
        account_id?: string;
        card_number?: string;
        legal_id?: string;
        legal_name?: string;
        cabinet_id?: string;
        email?: string;
    } = {};

    searchWebmasters = searchWebmasters;

    async created(): Promise<void> {
        this.earlyPaymentID = this.$route.params.id;
        await this.getPayment();
    }

    get getSum(): string {
        const sum = Number(this.paymentSync.paid_sum).toFixed(2);
        const parts = sum.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return `${parts.join(',')} ${this.paymentSync.currency}`;
    }

    get disabledDates(): number[] {
        const days: number[] = [];
        for (let i = 1; i <= 31; i++) {
            if (i !== 1 && i !== 15) days.push(i);
        }
        return days;
    }

    get isDisableEditMode(): boolean {
        return this.paymentSync.status === 'done';
    }

    get isDisableSave(): boolean {
        return this.isEditMode && (!this.sumValue || !this.requisiteValue || (this.isContract && !this.requisites.legal_id));
    }

    get isContract(): boolean {
        return this.paymentSync.method_id === 'contract_v1';
    }

    get requisiteValue(): string {
        return this.requisites.account_id! || this.requisites.cabinet_id! || this.requisites.card_number! || this.requisites.legal_name! || this.requisites.legal_id! || this.requisites.email!;
    }

    getCardInfo(accType: string): string {
        const obj = this.paymentSync[accType];
        if (!obj) return '';
        return `${obj.account_id} ${obj.first_name} ${obj.last_name}`;
    }

    get getManagerName(): string {
        const obj = this.paymentSync.by_manager ? this.paymentSync.created_by : this.paymentSync.affiliate?.manager;
        if (!obj) return '';
        return `${obj.id} ${obj.first_name} ${obj.last_name}`;
    }

    get getDataForCard(): any[] {
        return [
            {
                route: 'affiliate',
                param: this.paymentSync.affiliate?.account_id,
                text: 'Аккаунт списания',
                value: this.getCardInfo('affiliate'),
            },
            {
                route: 'affiliate',
                param: this.paymentSync.receiver?.account_id!,
                text: 'Аккаунт зачисления',
                value: this.getCardInfo('receiver'),
            },
            {
                text: 'Менеджер',
                value: this.getManagerName,
            },
            {
                text: 'Кем создано',
                value: this.paymentSync.by_manager ? 'Менеджер' : 'Партнёр',
            },
            {
                isStatus: true,
                text: 'Статус подтверждения',
                value: this.paymentSync.status_confirmation!,
                translate: translatePaymentsStatusConfirmation,
            },
            {
                text: 'Код',
                value: this.paymentSync.code || '',
            },
            {
                text: 'Тип выплаты',
                value: translatePaymentType(this.paymentSync.payment_type!),
            },
            {
                editType: 'period',
                text: 'Период',
                value: getFormatDatesForDatepicker(this.paymentSync.period!, 'date'),
            },
            {
                isChip: true,
                route: 'affiliate-invoice',
                param: this.paymentSync.invoice_id,
                text: 'ID счёта',
                value: this.paymentSync.invoice_id || '',
            },
            {
                isDate: true,
                text: 'Дата создания',
                value: this.paymentSync.created_at,
            },
            {
                text: 'Способ выплаты',
                value: this.paymentSync.method_name?.ru,
            },
            {
                editType: 'requisite',
                text: 'Реквизиты',
                value: this.requisiteValue,
            },
            {
                editType: 'sum',
                text: 'Сумма',
                value: this.getSum,
            },
            {
                editType: 'comment',
                text: 'Комментарий',
                value: this.paymentSync.comment || '',
            },
        ];
    }

    get isNotChanged(): boolean {
        const cardData = {
            requisites: this.requisites,
            sum: +this.sumValue,
            period: this.periodValue,
            comment: this.commentValue,
        };
        const paymentData = {
            requisites: this.isPartnerData ? this.paymentSync.requisite!.partner_data : this.paymentSync.requisite!.manager_data,
            sum: this.paymentSync.paid_sum,
            period: this.paymentSync.period,
            comment: this.paymentSync.comment,
        };
        return JSON.stringify(cardData) === JSON.stringify(paymentData);
    }

    checkRules(value: any, name: string): boolean | string {
        if (name === 'card_number') {
            return value.replace(/\d{16,18}/g, '').length > 0 ? '' : true;
        } if (name === 'account_id' && this.paymentSync.method_id === 'qiwi_v1') {
            return value.replace(/7\d{10}/g, '').length > 0 ? '' : true;
        } if (name === 'account_id' && this.paymentSync.method_id === 'webmoney_v1') {
            return value.replace(/Z\d{12}/g, '').length > 0 ? '' : true;
        } if (name === 'account_id' && this.paymentSync.method_id === 'yandex_v1') {
            return value.replace(/\w{15}/g, '').length > 0 ? '' : true;
        }
        return !!value || '';
    }

    async getPayment(): Promise<void> {
        try {
            const { data } = await getOnePayment(this.earlyPaymentID);
            this.paymentSync = data;
            this.isPartnerData = data.requisite!.partner_data
                && Object.keys(data.requisite!.partner_data).length > 0;
            const requisites = this.isPartnerData ? data.requisite!.partner_data : data.requisite!.manager_data;
            this.requisites = { ...requisites };
            this.sumValue = data.paid_sum!;
            this.periodValue = data.period!;
            this.commentValue = data.comment!;
        } catch (err) {
            showServerError(err, 'Досрочка не загружена');
        }
    }

    updateRequisite(name: string, value: string): void {
        this.requisites[name] = value;
    }

    async updatePayment(): Promise<void> {
        this.isEditMode = !this.isEditMode;
        if (this.isNotChanged) return;
        const partnerData = this.isPartnerData ? this.requisites : {};
        const managerData = this.isPartnerData ? {} : this.requisites;
        if (!this.isEditMode) {
            try {
                await patchPayment(this.paymentSync.id!, {
                    paid_sum: Number(this.sumValue),
                    period: this.periodValue,
                    comment: this.commentValue,
                    requisite: {
                        manager_data: managerData,
                        partner_data: partnerData,
                    },
                });
                showNotification('Изменения сохранены');
                await this.getPayment();
            } catch (err) {
                showServerError(err, 'Ошибка сохранения');
            }
        }
    }

    resetValues(): void {
        this.periodValue = this.paymentSync.period!;
        this.sumValue = this.paymentSync.paid_sum!;
        this.commentValue = this.paymentSync.comment!;
        const requisites = this.isPartnerData ? this.paymentSync.requisite!.partner_data : this.paymentSync.requisite!.manager_data;
        this.requisites = { ...requisites };
        this.isEditMode = false;
    }
}

