
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AppTableDesign from '@/components/base/table/AppTableDesign.vue';
import { ITableFooterButton, TableHeader } from '@/types';
import Loader from '@/components/base/Loader.vue';
import ViewDateTime from '@/components/base/ViewDateTime.vue';
import { IMyLeadgidInvoice, IMyLeadgidInvoiceSummaryItem } from '@/api/types/payout';
import { patchPaidStatus } from '@/api/payout';
import { showServerError } from '@/utils';
import { NotificationMessage } from '@/utils/notification';
import { USER_ROLES } from '@/mappings/user-roles';
import { Getter, namespace } from 'vuex-class';
import { getWebmastersSearchGuid } from "@/api/orgstats";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import uniq from "lodash-es/uniq";
import { sortBy } from "lodash-es";
import { IUser } from "@/api/types/user";
import {
    AffiliateInvoicesNumberFormatting,
    AffiliateInvoicesRoutes,
    AffiliateInvoicesStatuses, AffiliateInvoicesTimeData,
    AffiliateInvoicesUniqueData,
} from "@/services/TablePage/affiliateInvoices";

const auth = namespace('authModule');

@Component({
    components: {
        Loader,
        AppTableDesign,
        ViewDateTime,
        SvgArrow,
    },
})
export default class AppTableInvoices extends Vue {
    @Prop({ required: true }) items!: IMyLeadgidInvoice[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;
    @Prop({ default: false }) isAdmin!: boolean;
    @Prop({ default: null }) tableHeaders!: TableHeader[] | null;
    @Prop({ default: null }) summary?: IMyLeadgidInvoiceSummaryItem[] | null;

    @auth.Getter('GET_USER') user!: IUser;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    selected: IMyLeadgidInvoice[] = [];
    isLoading: boolean = false;
    webmasters: any = [];
    isShowSum: boolean = false;
    routes = AffiliateInvoicesRoutes;
    statuses = AffiliateInvoicesStatuses;
    numberFormatting = AffiliateInvoicesNumberFormatting;
    timeData = AffiliateInvoicesTimeData;

    headers: TableHeader[] = [
        { text: 'ID счёта', value: 'id', align: 'center', width: 64, fixed: true },
        { text: 'Партнёр', value: 'affiliate_id', align: 'center'  },
        { text: 'Валюта', value: 'currency', fixed: true },
        { text: 'Доход', value: 'total_revenue', align: 'right' },
        { text: 'Выплата', value: 'total_amount', align: 'right' },
        { text: 'Выплачено', value: 'partials_amount', align: 'right' },
        { text: 'Остаток', value: 'rest_amount', align: 'right' },
        { text: 'Прибыль', value: 'total_profit', align: 'right' },
        { text: 'Способ выплаты', value: 'method' },
        { text: 'Реквизиты', value: 'requisites' },
        { text: 'Период оплаты', value: 'pay_period', align: 'right' },
        { text: 'Дата создания', value: 'created_at', width: 90, align: 'right' },
        { text: 'Дата обновления', value: 'updated_at', width: 90, align: 'right' },
        { text: 'Статус', value: 'paid_status' },
    ];

    footerButtons: ITableFooterButton[] = [
        {
            text: 'Оплатить',
            textOfConfirm: 'Оплатить?',
            color: 'green',
            icon: 'check',
            action: (): Promise<void> => this.changePaidStatus('paid'),
        },
        {
            text: 'Отменить оплату',
            textOfConfirm: 'Отменить оплату?',
            color: 'red',
            icon: 'cancel',
            action: (): Promise<void> => this.changePaidStatus('not_paid'),
        },
    ];

    uniqueData = [ ...AffiliateInvoicesUniqueData,
        {
            slot: 'item.requisites',
            template: (item: IMyLeadgidInvoice): string => {
                if (item.paid_by_payment_method && item.paid_by_payment_method.requisite) {
                    item = this.prepareRequisite(item);

                    if (item.paid_by_payment_method?.method_id === "contract_v1") {
                        return item.paid_by_payment_method.requisite.legal_name;
                    } else {
                        return Object.values(item.paid_by_payment_method.requisite).join(', ');
                    }
                }
                return '';
            },
        },
        {
            slot: 'item.current_payment_method.requisite',
            template: (item: IMyLeadgidInvoice): string => {
                if (item.current_payment_method && item.current_payment_method.requisite) {
                    item = this.prepareRequisite(item);
                    if (item.paid_by_payment_method?.method_id === "contract_v1") {
                        return item.current_payment_method.requisite.legal_name;
                    } else {
                        return Object.values(item.current_payment_method.requisite).join(', ');
                    }
                }
                return '';
            },
        },
    ];

    prepareRequisite(item: IMyLeadgidInvoice): IMyLeadgidInvoice {
        if (item.paid_by_payment_method?.method_id !== 'contract_v1') return item;
        const webmaster = this.webmasters.find(
            (w) => w.guid === item.paid_by_payment_method.requisite.legal_id,
        );
        if (webmaster) {
            item.paid_by_payment_method.requisite.legal_id = webmaster.name;
        }
        return item;
    }

    created(): void {
        if(this.tableHeaders) this.headers = this.tableHeaders;
    }

    async getWebmasters(): Promise<void> {
        const dataContract = this.items.filter((i) => i.paid_by_payment_method?.method_id === 'contract_v1');
        if (dataContract.length > 0) {
            const guids = [...new Set(dataContract.map((j) => j.paid_by_payment_method?.requisite.legal_id))];
            this.webmasters = await getWebmastersSearchGuid(guids as []);
        }
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, this.getFiltersParamsObject, limit);
    }

    async changePaidStatus(paid_status: 'paid' | 'not_paid'): Promise<void> {
        try {
            this.isLoading = true;
            const payload = this.selected.map((item) => ({ invoice_id: item.id, paid_status }));
            await patchPaidStatus({ payload });
            new NotificationMessage(paid_status === 'paid' ? `Счета оплачены` : `Оплаты отменены`, 'success');
        } catch (err) {
            showServerError(err, paid_status === 'paid' ? 'Не удалось оплатить' : 'Не удалось отменить оплату');
        } finally {
            this.selected = [];
            this.isLoading = false;
            this.$emit('update-table');
        }
    }

    get canSelect(): boolean {
        const ROLES = [
            USER_ROLES.SUPERVISOR,
            USER_ROLES.FINANCIER,
            USER_ROLES.ADMIN,
        ];
        return this.user.roles.some((role) => role in ROLES);
    }

    selectItem(props: any): void {
        this.selected = props;
    }

    selectAllToggle(props: any): void {
        if (this.selected.length != this.items.length) {
            this.selected = props.items;
        } else this.selected = [];
    }

    clearSelectedItems(): void {
        this.selected = [];
    }

    get getCurrenciesList(): string[] {
        const defaultOrder = ['RUB', 'KZT', 'USD', 'EUR', 'PLN'];
        //  если тотал запрашиваем с бека, всегда отображем все валюты(а не только те, которые на данной странице)
        const uniqueCurrencies = this.summary?.length ? this.summary.map(i=> i.currency) : uniq(this.items.map(item => item.currency));
        return sortBy(uniqueCurrencies, (item) => {
            const i = defaultOrder.indexOf(item);
            return i === -1 ? defaultOrder.length : i;
        });
    }

    get getRows(): string[] {
        return this.isShowSum ? this.getCurrenciesList : [this.getCurrenciesList[0]];
    }

    getIndent(index: number): number {
        const max = this.getRows.length - 1;
        const rowHeight = 32;
        return (max - index) * rowHeight;
    }

    get getDisplayedHeaders(): string[] {
        const isWithoutColumns = !this.$route.query.columns;
        const displayed = this.$route.query.columns! as string[] || [];
        const fixed = this.headers.filter(h => h.fixed).map(h => h.value);
        const headers = this.headers.map(h => h.value);
        if (isWithoutColumns) return headers;
        const all = [...displayed, ...fixed];
        return headers.filter(h => all.includes(h));
    }

    getSum(head: string, currency: string): number | string {
        if (!['total_revenue','total_amount', 'partials_amount', 'rest_amount', 'total_profit'].includes(head)) return '';
        if (this.summary && this.summary.length) return this.getReadySum(head, currency);
        const allItemsByCurrency = this.items.filter(i => i.currency === currency);
        const allValues = allItemsByCurrency.map(i => i[head]);
        if (allValues.length === 0) return '';
        return allValues.reduce((acc, cur) => +acc + +cur).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    getReadySum(head: string, currency: string): number | string {
        const namesFromApi = {
            partials_amount: 'payment_amount',
            rest_amount: 'remain_payment_amount',
        };

        const value =  this.summary?.find((item) => item.currency === currency)?.[namesFromApi[head] || head];
        return value.toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    @Watch('items', { immediate: true })
    updateItems(): void {
        this.getWebmasters();
    }
}
