
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";
import ColumnsCheckboxes from "@/components/statistics/ColumnsCheckboxes.vue";
import FiltersDesign from "@/components/base/filters/FiltersDesign.vue";
import SvgColumns from "@/assets/icons/columns.svg";
import { IColumnFilter, IFilter } from '@/types';
import ColumnsWrapDesign from "@/components/base/filters/ColumnsWrapDesign.vue";
import { PAGINATION_PER_PAGE } from "@/configs/global";
import {
    autoNotificationsFilterColumns,
    notificationsFilterColumns,
} from "@/services/TablePage/notifications";
import { Getter, Mutation } from "vuex-class";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";

@Component({
    components: {
        TrashBtn,
        ColumnsWrapDesign,
        SvgColumns,
        FiltersDesign,
        ColumnsCheckboxes,
        FiltersDialog,
    },
},
)
export default class NotificationsFilters extends Vue {
    @Prop({ default: PAGINATION_PER_PAGE }) limit!: number;
    @Prop({ default:'.table-page' }) attachClass!: string;
    @Prop({ required: true }) isShowCategory!: boolean;
    @PropSync('selected', { required: true }) selectedSync!: any;
    @PropSync('categories', { required: true }) selectedCategoriesSync!: any;
    @Prop({ required: true }) filters!: any;
    @Prop({ default: [] }) checkboxes!: IColumnFilter[];
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    isShowColumns = false;
    isShowFilters = false;
    filtersTab = 0;
    tabItems = ['Фильтры', 'Категории'];

    get columns(): IColumnFilter[] {
        return this.$route.query?.is_auto! === 'true' ? autoNotificationsFilterColumns : notificationsFilterColumns;
    }

    submitFilters(offset?: number, limit?: number): void {
        this.isShowFilters = false;
        this.$emit('submit', offset, limit);
    }

    // сбросить категории
    clearCategory(): void {
        this.$emit('clear-category');
    }

    // сбросить фильтры, очистить кэш у автокомплита
    clearFilters(): void {
        this.filters.filters.map((filter: IFilter) => filter.clear());
        this.$emit('clear');
    }

    // очистить все фильтры и категории
    clearAllFilters(): void {
        this.$emit('clear-all');
    }

    get isSmallMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 576;
    }
}
