
import { Component, Mixins } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import TableMixin from '@/mixins/table';
import { showServerError } from '@/utils';
import { getEmployeeAffiliateMethods } from '@/api/payout';
import ChangeMethodSettings from "@/components/affiliate/affiliateMethods/ChangeMethodSettings.vue";
import ListLayout from "@/components/layout/ListLayout.vue";
import FiltersMethods from '@/components/affiliate/affiliateMethods/FiltersMethods.vue';
import AppTableMethods from '@/components/affiliate/affiliateMethods/AppTableMethods.vue';
import FormMethod from '@/components/affiliate/affiliateMethods/FormMethod.vue';
import CreateBtn from "@/components/base/buttons/CreateBtn.vue";

@Component({
    components: {
        CreateBtn,
        ChangeMethodSettings,
        ListLayout,
        FiltersMethods,
        AppTableMethods,
        FormMethod,
    },
})
export default class ChangeMethodRequests extends Mixins(TableMixin) {
    @Getter('GET_ENV_MODE') getEnvMode;
    @Mutation('SET_TOTAL_ELEMENTS_DATA_TABLE') setTotalElementsDataTable;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;

    isNewDesignPage = true;
    apiMethod = getEmployeeAffiliateMethods;
    settings = {
        title: 'Способы выплат партнёров',
        loading: false,
    };
    isShowModal = false;

    async mounted(): Promise<void> {
        await this.getEmployeeMethodsPagination();
    }

    async getEmployeeMethodsPagination(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Список способов выплат не загружен');
        }
        this.settings.loading = false;
    }

    showCreate(): void {
        this.isShowModal = true;
    }
}
