
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { translateChargeRequestStatus } from "@/utils/translate";
import { showServerError } from "@/utils";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { Mutation, namespace } from "vuex-class";
import { ITableFooterButton, ITableStatus, TableHeader } from "@/types";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import { NotificationMessage } from "@/utils/notification";
import { patchChargeRequest } from "@/api/payout";
import { USER_ROLES } from "@/mappings/user-roles";

const catalogue = namespace('catalogueModule');
const auth = namespace('authModule');

@Component({
    components: {
        ViewDateTime,
        AppTableDesign,
    },
})
export default class AppTableChargeRequests extends Vue {
    @auth.Getter('GET_USER') user;
    @Prop({ required: true }) items!: any[];
    @Prop({ type: String, default: 'id' }) tableItemKey!: string;
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;
    @Prop({ default: false }) isEditRate!: boolean;

    @PropSync('editedItems', { type: Array }) editedItemsSync!: {id?: number, affiliate_id?: number, goal_id?: number, offer_id?: number, revenue?: number, payout?:number, start_date?:string}[];

    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencyIcon;

    selected: any[] = [];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: true,
            disabled: (item: any): boolean => {
                return !(!item.is_merged && (item.status === 'new' || item.status === 'rejected'));
            },
            callback: (item: any): void => {
                this.deleteRequest(item, 'deleted');
            },
        },
    ];

    headers: TableHeader[] = [
        { text: 'ID', sortable: false, value: 'id', align: 'center', width: '100px', fixed: true },
        { text: 'Внешний ID', value: 'code', align: 'center'  },
        { text: 'Автор', value: 'creator' },
        { text: 'Партнёр', value: 'affiliate_ids' },
        { text: 'Организация', value: 'legal' },
        { text: 'Способ выплаты', value: 'contractor' },
        { text: 'Сумма', value: 'amount', align: 'right' },
        { text: 'Валюта', value: 'currency', align: 'right' },
        { text: 'Статья ДДС', value: 'dds', align: 'right' },
        { text: 'Проект', value: 'project', align: 'right' },
        { text: 'Создано', value: 'created_at' },
        { text: 'Обновлено', value: 'updated_at' },
        { text: 'Статус', value: 'status' },
        { text: '', sortable: false, align: 'center', value: 'actions', width: '50px', fixed: true },
    ];

    // для отображения статусов в таблице
    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateChargeRequestStatus,
        },
    ];
    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'charge-request',
            text: 'id',
        },
    ];
    multiroutes = [
        {
            routeParam: 'id',
            slot: 'item.affiliate_ids',
            routerName: 'affiliate',
            text: 'affiliate_ids',
        },
    ];
    uniqueData = [
        {
            slot: 'item.contractor',
            template: (item: any): string => `${item.contractor.full_name || ''}`,
        },
        {
            slot: 'item.creator',
            template: (item: any): string => `${item.creator.id || ''} ${item.creator.first_name || ''} ${item.creator.last_name || ''}`,
        },
        {
            slot: 'item.legal',
            template: (item: any): string => !!item.legal ? `${item.legal.full_name || ''}` : '',
        },
        {
            slot: 'item.currency',
            template: (item: any): string => `${item.currency.name || ''}`,
        },
        {
            slot: 'item.dds',
            template: (item: any): string => `${item.dds.name || ''}`,
        },
        {
            slot: 'item.project',
            template: (item: any): string => `${item.project.name || ''}`,
        },
        { slot: 'item.created_at' },
        { slot: 'item.updated_at' },
        { slot: 'item.amount' },
    ];

    get footerButtons(): ITableFooterButton[] {
        return [
            {
                text: `Отклонить ${this.selected.length > 1 ? 'заявки' : 'заявку'}`,
                textOfConfirm: 'Подтвердить?',
                color: 'red',
                icon: 'cancel',
                action: this.rejectSelectedCharges,
            },
            {
                text: `Принять ${this.selected.length > 1 ? 'заявки' : 'заявку'}`,
                textOfConfirm: 'Подтвердить?',
                color: 'green',
                icon: 'check',
                action: this.approveSelectedCharges,
            },
        ];
    }

    created(): void {
        this.setUnsavedDataState(false);
        this.setAbortTransition(false);
    }

    async deleteRequest(request: any, status: string): Promise<void> {
        const ID: string = request.id;
        try {
            await patchChargeRequest([Number(ID)], status);
        } catch (err) {
            showServerError(err);
        } finally {
            this.$emit('update-table');
        }
    }

    get hasNeededRoles(): boolean {
        return this.user?.roles?.includes(USER_ROLES.SUPERVISOR) || this.user?.roles?.includes(USER_ROLES.ADMIN);
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }


    getTemplate(item: any): string {
        return item.name || item.code || item.master.id;
    }

    async approveSelectedCharges(): Promise<void> {
        try {
            this.setUnsavedDataState(false);
            await patchChargeRequest(this.idsCharges, 'approved');
            new NotificationMessage('Заявки на расход одобрены', 'success');
            this.$emit('update-table', this.$route.query.offset);
        } catch (err) {
            showServerError(err, 'Заявки на расход не подтверждены');
        } finally {
            this.selected = [];
        }
    }

    get idsCharges(): number[] {
        return this.selected.map(i => i.id);
    }

    async rejectSelectedCharges(): Promise<void> {
        try {
            this.setUnsavedDataState(false);
            await patchChargeRequest(this.idsCharges, 'rejected');
            new NotificationMessage('Заявки на расход отклонены', 'info');
            this.$emit('update-table', this.$route.query.offset);
        } catch (err) {
            showServerError(err, 'Заявки на расход не отклонены');
        } finally {
            this.selected = [];
        }
    }
}
