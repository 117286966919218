
import { Component, Vue, Prop } from 'vue-property-decorator';
import SvgMonday from '@/assets/icons/nav-bar/monday.svg';
import SvgAtlassian from '@/assets/icons/nav-bar/atlassian.svg';
import SvgBrandmark from '@/assets/icons/nav-bar/brandmark.svg';
import store from '@/store';

@Component({
    components: {
        SvgAtlassian,
        SvgMonday,
        SvgBrandmark,
    },
})
export default class QuickLinks extends Vue {
    @Prop({ type: Boolean, default: true }) mini!: boolean;

    get usersRole(): string[] {
        return store.getters['authModule/GET_USER'].roles;
    }

    links = [
        {
            title: 'Wiki',
            href: 'https://leadgid.atlassian.net/wiki/spaces/faq/overview?homepageId=479330473',
            icon: SvgAtlassian,
            show : true,
        },
        {
            title: 'Monday',
            modal: true,
            menu: false,
            icon: SvgMonday,
            links: [
                {
                    title: 'Запрос выплаты',
                    href: 'https://leadgidwebvork.monday.com/boards/666732634/views/11071624',
                },
                {
                    title: 'Отклонение / правка цен',
                    href: 'https://leadgidwebvork.monday.com/boards/658485653',
                },
            ],
            show : true,
        },
        {
            title: 'Stata',
            href: 'https://stata.leadgid.ru/',
            icon: SvgBrandmark,
            show : this.usersRole.includes('ROLE_ADMIN') || this.usersRole.includes('ROLE_STAT_MANAGER'),

        },
    ];
}
