
import { Vue, Component, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import { ITicket } from "@/api/types/support";
import SvgFlame from "@/assets/icons/flame.svg";
import SvgArrow from "@/assets/icons/arrow-up-dark.svg";
import TicketAboutItem, { IAboutItem } from "@/components/support/ticket/TicketAboutItem.vue";
import SvgFileDownloaded from "@/assets/icons/file-downloaded.svg";

@Component({
    components: { SvgFileDownloaded, TicketAboutItem, SvgFlame, SvgArrow, BaseCard },
})

export default class AffiliateAbout extends Vue {
    @Prop({ required: true }) ticket!: ITicket;
    @Prop({ default: '' }) manager!: string;
    @Prop({ default: 0 }) emailVerified!: number;

    isLoading: boolean = false;
    hide: boolean = false;

    created(): void {
        this.hide = this.isMobile;
    }

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 890;
    }

    get getItems(): IAboutItem[] {
        return [
            {
                type: 'status',
                name: 'Статус',
                value: this.ticket.status,
            },
            {
                type: 'chip',
                name: 'ID партнёра',
                value: [this.ticket?.author_account?.account_id],
                isLink: true,
            },
            {
                type: 'master',
                subtype: 'chip',
                name: 'Master ID',
                value: [this.ticket?.author_account?.master_id],
                isMaster: true,
                isLink: this.ticket?.author_account?.master_id !== null,
            },
            {
                type: 'text',
                name: 'ФИО',
                value: this.ticket?.author_account?.account_name,
            },
            {
                type: 'text',
                name: 'E-mail',
                value: this.ticket?.author_account?.account_email,
                isVerified: !!this.emailVerified,
            },
            {
                type: 'text',
                name: 'Менеджер',
                value: this.manager,
            },
            {
                type: 'date',
                name: 'Создан',
                value: this.ticket.created_at,
            },
            {
                type: 'date',
                name: 'Обновлён',
                value: this.ticket.updated_at,
            },
        ];
    }
}
