
import { Vue, Component } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import PaymentsTable from "@/components/affiliate/payments/PaymentsTable.vue";
import { getPayments } from "@/api/payout";
import { downloadExcelFile, showServerError } from "@/utils";
import { Getter, namespace } from "vuex-class";
import { PaymentsColumns, PaymentsFilters } from "@/services/TablePage/payments";
const auth = namespace('authModule');

@Component({
    components: { TablePageWrapper },
})

export default class Payments extends Vue {
    @auth.Getter('GET_USER') user;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    columns = new PaymentsColumns().columns;
    filters: PaymentsFilters = new PaymentsFilters();

    createPaymentForm = {
        route: 'create-payment',
    };

    table = {
        component: PaymentsTable,
        apiMethod: getPayments,
    };

    downloadOptions = {
        loading: false,
    };

    async getExcel(): Promise<void> {
        this.downloadOptions.loading = true;
        const params = this.getFiltersParamsObject;
        try {
            const file = await getPayments(params, 'xlsx');
            downloadExcelFile(file, 'Досрочки', 'xlsx');
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
        this.downloadOptions.loading = false;
    }

    async mounted(): Promise<void>{
        await this.filters.getEmployeeMethods();
    }
}

