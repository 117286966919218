
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import Messages from "@/components/support/Messages.vue";
import { IMessage, ITicket } from "@/api/types/support";
import { TICKET_STATUS } from "@/mappings/ticket-status";
import { closeTicket, reopenTicket } from "@/api/support";
import { showNotification, showServerError } from "@/utils";
import SvgSend from "@/assets/icons/send.svg";
import SvgCheck from "@/assets/icons/check.svg";
import SvgReload from "@/assets/icons/reload-white.svg";
import FormCreateSignature from "@/components/support/FormCreateSignature.vue";

enum TYPE_SIGNATURE {
    SELF,SUPPORT
}

@Component({
    components: { FormCreateSignature, SvgReload, SvgCheck, SvgSend, Messages,BaseCard },
})

export default class TicketChat extends Vue {
    @Prop({ required: true }) ticket!: ITicket;
    @Prop({ default: false }) isPartnerManager!: boolean;
    @Prop({ default: 'ru' }) partnerLanguage!: string;
    @Prop({ required: true }) messages!: IMessage[];

    message = '';
    search = '';
    typeSignature = TYPE_SIGNATURE.SELF;
    TYPE_SIGNATURE = TYPE_SIGNATURE;
    showModal = false;

    get textBtn(): string {
        return this.ticket.status === TICKET_STATUS.RESOLVED ? 'Переоткрыть' : 'Завершить тикет';
    }

    get canAddMessage(): boolean {
        return (this.ticket as ITicket).status !== TICKET_STATUS.RESOLVED;
    }

    get author(): string {
        return this.ticket.author_account?.account_name || '';
    }

    mounted(): void {
        this.typeSignature = this.isPartnerManager ? TYPE_SIGNATURE.SELF : TYPE_SIGNATURE.SUPPORT;
    }

    async changeTicketStatus(): Promise<void> {
        try {
            if ((this.ticket! as ITicket).status === TICKET_STATUS.RESOLVED) {
                await reopenTicket((this.ticket! as ITicket).id);
            } else {
                await closeTicket((this.ticket! as ITicket).id);
                this.message = '';
            }
            this.showNotification();
            this.$emit('update-ticket');
        } catch (err) {
            showServerError(err);
        }
    }

    addTicketMessage(): void {
        this.$emit('socket-ticket',{ ticket: this.ticket, message: this.message, has_personal_signature: this.typeSignature === TYPE_SIGNATURE.SELF });
        this.message = '';
    }

    showNotification(): void {
        const notificationText = (this.ticket! as ITicket).status === TICKET_STATUS.RESOLVED ? 'переоткрыт': 'решён';
        showNotification(`Тикет ${(this.ticket! as ITicket).id} ${notificationText}`);
    }

    toggleShowModalSignature(): void {
        this.showModal =  !this.showModal;
    }

    moveToBottom(): void {
        const hiddenElement = this.$el.querySelector('.ticket-chat__actions');
        hiddenElement?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
}

