
import { Component, Vue } from 'vue-property-decorator';
import { showServerError } from "@/utils";
import { getOneNotification } from "@/api/notifications";
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import PageLayout from "@/components/layout/PageLayout.vue";
import ShowingNotification from "@/components/notifications/ShowingNotification.vue";
import EditedNotification from "@/components/notifications/EditedNotification.vue";
import NotificationOffers from "@/components/notifications/NotificationOffers.vue";
import NotificationAffiliates from "@/components/notifications/NotificationAffiliates.vue";
import PageTabs from "@/components/base/PageTabs.vue";

@Component({
    beforeRouteEnter(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        next(vm => {
            if (from.fullPath !== '/notification-center/notifications') {
                vm.beforeListRoute = from.fullPath;
            }
        });
    },
    components: {
        PageTabs,
        ShowingNotification,
        PageLayout,
        EditedNotification,
        NotificationOffers,
        NotificationAffiliates,
    },
})

export default class NotificationPage extends Vue {
    notification: EmployeeNotification | null = null;
    isEditing = false;
    tabItems = [
        { tab: 'Основная информация', component: ShowingNotification },
        { tab: 'Офферы', component: NotificationOffers },
        { tab: 'Партнеры', component: NotificationAffiliates },
    ];
    tab: number = 0;
    beforeListRoute = '/notification-center/notifications';

    get bodyComponent(): any {
        if (this.isEditing) {
            if (this.notification) {
                this.notification.affiliates = [];
                this.notification.offers = [];
            }
            return EditedNotification;
        } else {
            return this.tabItems[this.tab].component;
        }
    }

    changeTab(tab: number, isHistory: boolean): void {
        this.tab = tab;
        if (isHistory) {
            this.setTabToUrl();
        }
    }

    setTabToUrl(): void {
        const query = { tab: this.tab.toString() };
        this.$router.replace({ name: this.$router.currentRoute.name || '', query }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

    async getNotification(): Promise<void> {
        try {
            const data =  await getOneNotification(this.$route.params.id);
            this.notification = new EmployeeNotification(data);
        } catch(err) {
            showServerError(err, 'Уведомление не получено');
        }
    }

    created(): void {
        this.getNotification();
        if (this.$route.query.tab !== undefined) {
            this.tab = Number(this.$route.query.tab) || 0;
        } else {
            this.setTabToUrl();
        }
    }

    updated(): void {
        if (Number(this.$route.query.tab) !== this.tab) {
            this.setTabToUrl();
        }
    }

    editNotification(): void {
        this.isEditing = !this.isEditing;
        if (!this.isEditing) {
            this.getNotification();
        }
    }

    backToList(): void {
        this.$router.push({ path: this.beforeListRoute });
    }
}
