
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getRateUtil } from '@/utils/personalRate/rate';
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import { ICreatePersonalRate, IOfferGoal, IPersonalRate } from '@/api/types/offers';
import TrashBtn from '@/components/base/buttons/TrashBtn.vue';
import { namespace } from 'vuex-class';
import SvgExpandMore from '@/assets/icons/expand-more.svg';
import { createMask } from "@/utils";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        EditModeTextField,
        BaseAutocomplete,
        TrashBtn,
        SvgExpandMore,
    },
})
export default class TodoItem extends Vue {
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencyIcon;

    @Prop({ default: {} }) rate!: ICreatePersonalRate;
    @Prop({ default: {} }) index!: number;
    @Prop({ default: {} }) offerGoals!: IOfferGoal[];
    @Prop({ default: {} }) personalRate!: IPersonalRate;
    isDisabledPayout = false;

    getRateUtil = getRateUtil;
    createMask = createMask;

    get toggleDisabledPayout(): boolean {
        return this.rate.goal!.payout?.type !== 'fixed';
    }

    get isShowField(): boolean {
        return (!!this.rate.goal && this.rate.is_expand) || !!(this.offerGoals.length === 1 && this.rate!.goal);
    }

    get goalItems(): any[] {
        return this.offerGoals.filter((goal) =>
            (!this.personalRate.rates.find((r) => r.goal && r.goal_id === goal.legacy_id)) || this.rate.goal_id === goal.legacy_id) || [];
    }

    get getRateRevenue(): string {
        return this.getRate('revenue');
    }

    get getRatePayout(): string {
        return this.getRate('payout');
    }

    checkRules(v: number | string): boolean | string {
        return (!!Number(v) && Number(v) >= 0) || '';
    }

    updateGoal(goal: IOfferGoal, rate: any): void {
        const rateValues = {
            goal_id: goal.legacy_id,
            goal: goal,
            offer_id: rate.offer_id,
            is_expand: true,
            payout: null,
            revenue: goal.revenue ? parseFloat(goal.revenue.amount as any) : null,
            percent: null,
        };
        for (const key in rateValues) {
            this.$set(rate, key, rateValues[key]);
        }
    }

    @Watch('rate', { deep: true })
    updateFields(): void {
        this.isDisabledPayout = this.toggleDisabledPayout;
    }

    countPayout(key: string): void {
        if (this.rate.revenue !== null) {
            if (key === 'percent') {
                if (+this.rate.percent! > 100 ) {
                    this.rate.percent = 100;
                }
                if (!this.isDisabledPayout) {
                    this.rate.payout = Math.floor(((+this.rate.revenue! * +this.rate.percent!) / 100) * 100) / 100;
                }
            }
            if (key === 'payout' || (key === 'revenue' && this.rate.payout)) {
                if (Number(this.rate.payout) > Number(this.rate.revenue!)) {
                    this.rate.payout = +this.rate.revenue!;
                }
                this.rate.percent = Math.floor(((+this.rate.payout! / +this.rate.revenue!) * 100) * 100) / 100 || 0;

            }
        }
    }

    removeRate(index: number): void {
        this.personalRate.rates.splice(index, 1);
        if (this.personalRate.rates?.length < 1) {
            this.$set(this.personalRate.rates, this.personalRate.rates.length, { id: 0, payout: null, goal_id: null, revenue: null, is_expand: true });
        } else if (this.personalRate.rates?.length <= 2) {
            this.personalRate.rates.forEach(rate => rate.is_expand = true);
        }
    }

    getRate(key: string): string {
        const goal = this.rate.goal!;
        const type = goal[key].type;
        const currentCurrency = this.currencyIcon(goal[key].currency);
        const fixedAmount = parseFloat(goal[key].amount).toLocaleString('ru-RU',{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
        const amount = parseFloat(goal[key].amount);
        return this.getRateUtil(type, fixedAmount, currentCurrency, amount);
    }
}
