
import { Component, Vue, Prop } from 'vue-property-decorator';
import { markNotificationsAsRead } from '@/api/notifications';
import { showServerError } from '@/utils';

@Component({})

export default class PopupMenu extends Vue {
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: Array, default: () => [] }) items!: [];

  async markAsRead(id: number): Promise<void> {
      try {
          await markNotificationsAsRead({ notification_ids: [id] });
          this.$emit('read-notification', id);
      } catch (err) {
          showServerError(err, 'Уведомление не прочитано');
      }
  }

  async markAllASRead(): Promise<void> {
      try {
          const notificationIds = this.$props.items.map((item) => item.id);
          await markNotificationsAsRead({ notification_ids: notificationIds });
          this.$emit('read-notifications');
      } catch (err) {
          showServerError(err, 'Уведомления не прочитаны');
      }
  }
}
