
import { Vue, Component } from "vue-property-decorator";
import { getEmployeesCountries } from "@/api/offers";
import AdministrationOffersLayout from "@/components/layout/AdministrationOffersLayout.vue";

@Component({
    methods: { getEmployeesCountries },
    components: { AdministrationOffersLayout },
})

export default class Countries extends Vue {}
