
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})

export default class OffsetBase extends Vue {
    @Prop({ type: Boolean, default: false }) fullWidth!: boolean;
    @Prop({ type: [Number, String], default: 0 }) offset!: number | string;

    get classes(): {} {
        return {
            'v-offset--full-width': this.$props.fullWidth,
        };
    }

    get styles(): {} {
        return {
            top: '-5px',
            marginBottom: `-${this.$props.offset}px`,
        };
    }
}
