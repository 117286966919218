
import { Component, Vue } from 'vue-property-decorator';
import StatsCard from '@/components/base/StatsCard.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import StreamMain from '@/components/uapi/streams/Stream.vue';
import Stages from '@/components/uapi/streams/stages/List.vue';
import { IEndpointItem, IStream } from '@/api/types/uapi';
import { addNewStream, editStream, getStream } from '@/api/uapi';
import { showNotification, showServerError } from '@/utils';
import ConditionsStream from '@/components/uapi/streams/conditionsStream.vue';

@Component({
    components: {
        StatsCard,
        TooltipButton,
        StreamMain,
        ConditionsStream,
        Stages,
    },
})
export default class Stream extends Vue {
    title = '';
    stream: IStream = {
        name: '',
        endpoints: [],
        quota: '',
        subid_stamp: {},
    };

    get canEdit(): boolean {
        return !this.$route.query.version;
    }

    get maskSubID(): {} {
        const arr: string[] = [];
        arr.length = 15;
        return {
            mask: arr.fill('B').join(''),
            tokens: {
                B: {
                    pattern: /[a-z-_.:0-9]/,
                },
            },
        };
    }

    created(): void {
        if (this.$route.params.streamId) {
            this.getStream();
        } else {
            this.title = 'Создание потока';
        }
    }

    async getStream(): Promise<void> {
        try {
            const { version } = this.$route.query;
            const { data } = await getStream(this.$route.params.streamId, { version });
            this.stream = data;
            if (!this.stream.subid_stamp) {
                this.stream.subid_stamp = Array(10).fill("");
            }
            this.title = this.stream.name;
            if (version) {
                this.title += ` <span class="grey--text text--darken-2">(ver. ${version})</span>`;
            }
        } catch (err) {
            showServerError(err);
        }
    }

    async postStream(): Promise<void> {
        try {
            if (this.stream && this.stream.subid_stamp) {
                // Фильтрация subid_stamp, удаляем те, у которых значение равно 0
                const filteredSubidStamp = Object.entries(this.stream.subid_stamp)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([key, value]) => value !== "")
                    .reduce((obj, [key, value]) => {
                        obj[key] = value;
                        return obj;
                    }, {});

                // обновляем subid_stamp только если есть хотя бы одно не нулевое значение
                if (Object.keys(filteredSubidStamp).length > 0) {
                    this.stream.subid_stamp = filteredSubidStamp;
                } else {
                    // очищаем subid_stamp, если все значения равны 0 или не указаны
                    this.stream.subid_stamp = {};
                }
            }

            if (this.stream.id) {
                if (this.stream.endpoints) {
                    this.stream.endpoints = [...this.stream.endpoints!].map((endpoint) => ((endpoint as IEndpointItem).id
                        ? (endpoint as IEndpointItem).id!
                        : endpoint));
                }
                const { data } = await editStream(this.stream.id, this.stream);
                this.stream = data;
                showNotification('Изменения сохранены');
            } else {
                const { data } = await addNewStream(this.stream);
                this.stream = data;
                await this.$router.push({ name: 'stream-edit', params: { streamId: String(data.id!) } });
                showNotification('Сохранено');
            }
        } catch (err) {
            showServerError(err);
        }
    }
}
