
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { downloadFile } from '@/api';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import { IFile, IOfferService } from '@/api/types/revenue';
import { STATS_TASK_STATUS } from '@/mappings/statsTaskStatus';
import { showServerError } from '@/utils';

const revenue = namespace('revenueModule');

@Component({
    components: {
        TooltipButton,
    },
})

export default class StatTaskedStatisticFile extends Vue {
    @Prop({ required: false, default: false }) canBeDeleted!: boolean;
    @Prop({ required: true }) readonly statisticFile!: {};
    @Prop({ required: false }) readonly statsTaskStatus!: string;

    @Getter('GET_EDITING_MODE') editingMode;
    @revenue.Mutation('SET_FILE_REPORT') setFileReport;

    loading = false;
    offerServicesHeaders = [
        { text: 'Оффер', value: 'offer_name', class: 'text-left' },
        { text: 'ID цели', value: 'goal_id', class: 'text-left' },
        { text: 'Название цели', value: 'goal_name', class: 'text-left' },
        { text: 'Ставка', value: 'offer_rate', class: 'text-right' },
        { text: 'Текущая ставка', value: 'offer_start_rate', class: 'text-right' },
        { text: 'Количество', value: 'offer_qty', class: 'text-right' },
    ];

    // получаем сгруппированный объект по офферам
    get offerServices(): IOfferService[] {
        return this.$props.statisticFile.offer_services.reduce((acc, offer) => {
            (acc[`${offer.offer_id} ${offer.offer_name}`] = acc[`${offer.offer_id} ${offer.offer_name}`] || []).push({
                goal_id: offer.goal_id,
                goal_name: offer.goal_name,
                offer_rate: offer.offer_rate,
                offer_start_rate: offer.offer_start_rate || 0,
                offer_qty: offer.offer_qty,
            });
            return acc;
        }, {});
    }

    get routerName(): string | void {
        return this.$route.name || '';
    }

    get isDisabledBtnDownload(): boolean {
        return this.routerName === 'stat-task-creation'
            || this.routerName === 'invoice-creation-from-navigation'
            || this.routerName === 'invoice-creation';
    }

    get isCanBeDeleted(): boolean {
        // TODO сейчас, когда нет отчета, приходит [] тогда как отчет это объект.
        // После того как на бэке вольют 721 ветку в мастер
        // отсутствие отчета будет приходить как null
        // и можно будет убрать блок `if (report instanceof Array)`
        const hasReport = this.$props.statisticFile.offer_services.some(({ report }) => {
            if (report instanceof Array) {
                return report.length;
            }
            return report;
        });
        return !hasReport;
    }

    // скачивание файла
    async downloadStatisticFile(item: IFile): Promise<void> {
        this.loading = true;
        try {
            await downloadFile(item);
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }

    openFileReport(): void {
        this.setFileReport(this.$props.statisticFile);
        this.$router
            .push({
                name: 'file-report',
                params: {
                    id: this.$route.params.id as string,
                    fileId: this.$props.statisticFile.id!.toString(),
                },
            });
    }

    get isStatTask(): boolean {
        const offersWithReport: [] = this.$props.statisticFile.offer_services.filter((item) => item.report?.id);
        return this.$route.name === 'stat-task'
            && offersWithReport.length > 0
            && this.$props.statsTaskStatus === STATS_TASK_STATUS.DONE;
    }
}
