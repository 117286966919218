
import { Component, Mixins } from 'vue-property-decorator';
import {
    addStageCondition,
    editStageCondition,
} from '@/api/uapi';
import { showServerError } from '@/utils';
import ConditionContent from '@/components/uapi/conditions/ConditionContent.vue';
import ConditionGeo from '@/components/uapi/conditions/ConditionGeo.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import StreamCondition from "@/mixins/streamCondition";

@Component({
    components: {
        ConditionContent,
        ConditionGeo,
        StatsCard,
        TooltipButton,
    },
})
export default class ConditionStage extends Mixins(StreamCondition) {
    isBtnLoading = false;

    async saveCondition(): Promise<void> {
        const streamCondition: any = this.getConditionsToSend();
        this.isBtnLoading = true;
        const { streamId, stageId } = this.$route.params;
        try {
            if (this.condition!.id) {
                await editStageCondition(streamId, stageId, this.condition!.id, streamCondition!);
            } else {
                const { data } = await addStageCondition(streamId, stageId, streamCondition!);
                this.condition = data;
                await this.$router.push({ name: 'stream-edit', params: { streamId: this.$route.params.streamId } });
            }
        } catch (err) {
            showServerError(err);
        } finally {
            this.isBtnLoading = false;
        }
    }
}
