
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISummary } from '@/api/types/stats';
import { TableHeader } from '@/types';

@Component({
    components: {
    },
})
export default class ExtendedReportTableFooter extends Vue {
    @Prop({ required: true, default: [] }) numberFormatting!: { slot: string, key: string }[];
    @Prop({ required: true }) headers!: TableHeader[];
    @Prop({ required: true }) item!: ISummary;
}
