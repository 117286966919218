
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import StatsCard from '@/components/base/StatsCard.vue';
import { showServerError } from '@/utils';
import { ISingleMyLeadgidInvoice } from '@/api/types/payout';
import { getOneMyLeadgidInvoice } from '@/api/payout';
import InvoiceHeader from '@/components/affiliate/affiliateInvoices/Header.vue';
import PartialPaymentsTable from '@/components/affiliate/affiliateInvoices/PartialPaymentsTable.vue';
import Loader from '@/components/base/Loader.vue';
import { USER_ROLES } from '@/mappings/user-roles';
import LeadsTable from '@/components/affiliate/affiliateInvoices/LeadsTable.vue';
import BackArrow from '@/assets/icons/back-arrow.svg';

const auth = namespace('authModule');
@Component({
    components: {
        BackArrow,
        Loader,
        StatsCard,
        PartialPaymentsTable,
        InvoiceHeader,
        LeadsTable,
    },
})
export default class AffiliateInvoice extends Vue {
    @auth.Getter('GET_USER') user;

    invoice = {} as ISingleMyLeadgidInvoice;
    loading = false;

    get title(): string {
        return `Счёт ID ${this.invoice.id}`;
    }

    get isShowInvoice(): boolean {
        return Object.keys(this.invoice).length > 0;
    }

    get isCanPay(): boolean {
        return this.user.roles.some((role) => role === USER_ROLES.SUPERVISOR || role === USER_ROLES.FINANCIER
            || role === USER_ROLES.ADMIN);
    }

    async created(): Promise<void> {
        await this.getInvoice();
    }

    async getInvoice(): Promise<void> {
        try {
            this.loading = true;
            this.invoice = await getOneMyLeadgidInvoice(this.$route.params.id);
        } catch (err) {
            showServerError(err, 'Счет my-leadgid не найден');
        }
        this.loading = false;
    }
}
