
import { Component, Prop, Vue } from 'vue-property-decorator';
import { downloadFile } from '@/api';
import { showServerError } from '@/utils';
import SvgFileDownloaded from "@/assets/icons/file-download-grey.svg";

@Component({
    components: {
        SvgFileDownloaded,
    },
})

export default class BtnDownload extends Vue {
    @Prop({ required: true }) readonly item!: {};
    @Prop({}) readonly apiMethod!: object;

    loading = false;

    // скачивание файла
    async downloadCustomFile(): Promise<void> {
        this.loading = true;
        try {
            await downloadFile(this.item, this.apiMethod);
        } catch (err) {
            showServerError(err, 'Файл не найден');
        }
        this.loading = false;
    }
}
