
import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";
import SvgRangeCalendar from '@/assets/icons/range-calendar.svg';
import { formatDateInputPicker } from "@/utils/formatDates";

@Component({
    components: { SvgRangeCalendar },
})

export default class MonthPicker extends Vue {
    @PropSync('value', { required: true }) valueSync!: string;
    @Prop({ type: String, default: '' }) readonly label!: string;


    pickerValue: string = '';
    menu: boolean = false;

    get getValue(): string {
        return this.pickerValue ? formatDateInputPicker(this.pickerValue, 'LLLL yyyy') : '';
    }

    change(): void {
        this.valueSync = this.pickerValue;
        this.menu = false;
    }

    clear(): void {
        this.valueSync = this.pickerValue = '';
    }

    created(): void {
        if (this.valueSync) this.updatePickerValue();
    }

    @Watch('valueSync')
    updatePickerValue(): void {
        this.pickerValue = this.valueSync;
    }
}

