
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    components: {},
})

export default class DraggableTableItem extends Vue {
    @Prop({ default: '' }) itemClass!: string;
    @Prop({ default: () => ({}) }) item!: any;
    @Prop({ default: () => ([]) }) headers!: any[];

    columnName(header: any): string {
        return `item.${header.value}`;
    }
    getAlignment(header: any): string {
        const align = header.align ? header.align : "left";
        return `text-align: ${align}`;
    }
    getSlotValue(item: any, header: any): any {
        const val = item[header.value];
        return val ? val : '';
    }
}

