
import { Vue, Component, Watch } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import UsersTable from "@/components/administration/users/UsersTable.vue";
import { getUsersV3 } from "@/api/user";
import { UsersFilters } from "@/services/TablePage/users";
import FormCreateUser from "@/components/administration/users/FormCreateUser.vue";
import { Route } from "vue-router";
import { Getter, Mutation } from "vuex-class";

@Component({
    components: { TablePageWrapper },
})

export default class List extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    filters = new UsersFilters();
    table = {
        component: UsersTable,
        apiMethod: getUsersV3,
        paramsModifier: this.setFields,
    };
    createMethodForm = {
        component: FormCreateUser,
    };
    setFields(params: any): any {
        return { ...params, ...{ field: ['id','account_id', 'first_name', 'last_name', 'email', 'account_type', 'status'] } };
    }

    @Watch('$route')
    reloadPageIfRouteChanged(newRoute: Route): void {
        if (newRoute.name === 'user-creation') {
            const query = this.getFiltersParamsObject;
            const offset = this.$route.query.offset;
            const allQuery = { ...query, offset };
            // @ts-ignore
            this.$refs.wrapper.showCreateForm();
            this.$router.push({ name: 'users' });
            this.$router.replace({ name: this.$router.currentRoute.name || '', query: allQuery }).catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                    throw error;
                }
            });
        }
    }

}
