
import { Component, Prop, Vue, Watch, PropSync } from 'vue-property-decorator';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { translateUserType } from '@/utils/translate';
import { changeTopScrollWidth } from '@/utils';
import { eventBus } from '@/eventbus';
import TableScroll from '@/directives/TableScroll';
import Status from '@/components/base/Status.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import MultiButton from '@/components/base/buttons/MultiButton.vue';
import CardMaterial from '@/components/base/CardMaterial.vue';
import AppTableRouter from '@/components/base/TableUI/AppTableRouter.vue';
import Pagination from '@/components/base/table/Pagination.vue';
import { PAGINATION_PER_PAGE } from '@/configs/global';

@Component({
    components: {
        Status,
        TooltipButton,
        MultiButton,
        CardMaterial,
        AppTableRouter,
        Pagination,
    },
    directives: {
        TableScroll,
    },
})

export default class Table extends Vue {
    @Prop({ type: Boolean, default: false }) classId!: boolean;
    @Prop({ type: Boolean, default: false }) customHeader!: boolean;
    @Prop({ type: Boolean, default: true }) renderPagination!: boolean;
    @Prop({ type: Boolean, default: false }) lockedRow!: boolean;
    @Prop({ type: Boolean, default: true }) isIcon!: boolean;
    @Prop({ type: Boolean, default: true }) loading!: boolean;
    @Prop({ type: Boolean, default: false }) expand!: boolean;
    @Prop({ type: Boolean, default: false }) needSearch!: boolean;
    @Prop({ type: Boolean, default: true }) hideDefaultFooter!: boolean;
    @Prop({ type: Number, default: 0 }) totalElementDataTable!: number;
    @Prop({ type: Number, default: PAGINATION_PER_PAGE }) selectedLimit!: number;
    @Prop({ type: Number, default: PAGINATION_PER_PAGE }) paginationLimit!: number;
    @Prop({ type: String, default: 'primary' }) activeColor!: string;
    @Prop({ type: String, default: 'id' }) tableItemKey!: string;
    @Prop({ type: String }) icon!: string;
    @Prop({ default: null }) addRowClass!: any;
    @Prop({ type: String, default: 'Поиск...' }) searchPlaceholder!: string;
    @Prop({ type: Array, default: () => [] }) statuses!: any[];
    @Prop({ type: Array, default: () => [] }) routes!: any[];
    @Prop({ type: Array, required: true }) items!: any[];
    @Prop({ type: Array, required: true }) headers!: any[];
    @Prop({ type: Array, default: () => [] }) booleanItems!: any[];
    @Prop({ type: Array, default: () => [] }) actions!: any[];
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: Array, default: () => [] }) numberFormatting!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) uniqueData!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) images!: Array<{}>;
    @Prop({ type: Boolean, default: false }) needTopScroll!: boolean;
    @Prop({ type: [String, Number], default: '' }) id!: number | string;
    @Prop({ type: Boolean, default: true }) disableSort!: boolean;
    @Prop({ type: Boolean, default: true }) isShowSelect!: boolean;
    @Prop({ type: Array, default: () => [PAGINATION_PER_PAGE, 50, 100] }) itemsPerPageSelect!: number[];
    @Prop({ type: String, default: 'Ничего не найдено' }) noDataText!: string;
    @Prop({ type: String, default: 'Ничего не найдено' }) noResultText!: string;

    @PropSync('options', { type: Object, default: () => ({}) }) optionsSync!: {};
    @PropSync('sortBy', { type: String, default: '' }) sortBySync!: string;
    @PropSync('sortDesc', { type: Boolean, default: false }) sortDescSync!: boolean;

    isResendData: boolean = false;
    search = '';
    // for select table row
    selected: object[] = [];
    singleSelect = false;
    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    translateUserType = translateUserType;

    created(): void {
        eventBus.$on('go-to-first-page', this.goToFirstPage);
        eventBus.$on('clear-selected', this.clearSelected);
    }

    goToFirstPage(): void {
        this.pagination.page = 1;
    }

    @Watch('items')
    goToPrevPage(): void {
        if (this.items?.length <= 0 && this.pagination.page > 1) {
            this.pagination.page = --this.pagination.page;
            this.isResendData = true;
        }
    }

    itemClass(item: any): string {
        return this.addRowClass ? this.addRowClass(item) : '';
    }

    updated(): void {
        if (!this.$props.needTopScroll) {
            return;
        }
        changeTopScrollWidth(`#${this.$props.id}`);
    }

    selectAllToggle(props: any): void {
        this.$emit('select-all-toggle', props);
    }

    selectItem(props: any): void {
        props.value ? this.selected.push(props.item) : this.selected = this.selected.filter((el) => el !== props.item);
        this.$emit('select-item', this.selected);
    }

    clearSelected(): void {
        this.$emit('clear-selected');
        this.selected = [];
    }

    getPaginationData(offset: number, limit: number, isChangeLimit?: boolean): void {
        this.clearSelected();
        this.selectedLimit = limit;
        if (isChangeLimit) {
            this.$emit('get-page-data', 0, limit, isChangeLimit);
        } else {
            this.$emit('get-page-data', offset, limit);
        }
        this.isResendData = false;
    }

    beforeDestroy(): void {
        eventBus.$off('go-to-first-page', this.goToFirstPage);
        eventBus.$off('clear-selected', this.clearSelected);
    }
}
