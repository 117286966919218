
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { IFinanceInvoice } from '@/api/types/org-cabinets-backend';
import Filters from '@/components/base/filters/Filters.vue';
import AppTable from '@/components/base/table/AppTable.vue';
import { searchWebmasters } from '@/api/org-cabinets-backend';
import { eventBus } from '@/eventbus';
import { showServerError } from '@/utils';
import { getLegalEntitySearch } from '@/api/revenue';
import { translateFinanceInvoicesStatus } from '@/utils/translate';
import SvgReceipt from '@/assets/icons/nav-bar/receipt.svg';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { getLegalEntitySearchGuid, getWebmastersSearchGuid } from '@/api/orgstats';
import FilterGuid from '@/mixins/filterGuid';
import { IFilter, ITableStatus } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        Filters,
        SvgReceipt,
        AppTable,
    },
})
export default class FinanceInvoices extends Mixins(FilterGuid) {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;

    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    @Prop({ required: true }) apiMethod!: any;

    @Prop({ default: false }) isMasterAccount!: boolean;

    items: IFinanceInvoice[] = [];

    isClear = false;

    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'invoice_ids',
            label: 'Партнёрский счёт',
            select: [],
        }),
        new filterClass.ComboboxArray({
            id: 'codes',
            label: 'Номер счета в 1C',
            select: [],
        }),
        new filterClass.Multiselect({
            id: 'currency',
            label: 'Выберите валюту',
            noDataText: 'Нет выбранной валюты',
            select: [],
            items: [],
            itemValue: 'code',
            type: 'string',
            isCurrencyCatalogue: true,
        }),
        new filterClass.Datepicker({
            id: 'period',
            label: 'Дата создания',
            pickerType: 'date',
            select: [],
            range: true,
        }),
        new filterClass.Autocomplete({
            id: 'leadgid_legal_guids',
            apiMethod: getLegalEntitySearch,
            label: 'Организация',
            itemValue: 'external_id',
            itemText: 'name',
            select: [],
            type: 'string',
            multiple: true,
            apiParseUrl: false,
        }),
        new filterClass.Autocomplete({
            id: 'webmaster_legal_guids',
            apiMethod: searchWebmasters,
            label: 'Вебмастер',
            itemValue: 'guid',
            itemText: 'name',
            multiple: true,
            select: [],
            type: 'string',
            apiParseUrl: false,
        }),
        new filterClass.Select({
            id: 'paid_status',
            label: 'Статус',
            items: ['skipped', 'paid', 'partially_paid', 'not_paid'],
            translate: this.translateStatus,
            select: '',
        }),
    ];

    headers = [
        {
            text: 'ID',
            value: 'id',
        },
        {
            text: 'Номер счёта 1С',
            value: 'code',
        },
        {
            text: 'ID вебмастеров',
            value: 'affiliate_ids',
        },
        {
            text: 'Партнёрские счета',
            value: 'leadgid_invoices_ids',
        },
        {
            text: 'Вебмастер',
            value: 'webmaster_legal',
        },
        {
            text: 'Организация',
            value: 'leadgid_legal',
        },
        {
            text: 'Валюта',
            value: 'currency',
        },
        {
            text: 'Сумма счёта 1С',
            value: 'amount',
            align: 'right',
        },
        {
            text: 'Сумма партнёрских счетов',
            value: 'leadgid_invoices_amount',
            align: 'right',
        },
        {
            text: 'Разница',
            value: 'amount_diff',
            align: 'right',
        },
        {
            text: 'Дата',
            value: 'period',
            width: '150',
        },
        {
            text: 'Статус',
            value: 'paid_status',
            width: '170',
            align: 'center',
        },
    ];

    uniqueData = [
        {
            slot: 'item.affiliate_ids',
            template: (items: IFinanceInvoice): string => items.affiliate_ids.join(', '),
        },
        {
            slot: 'item.leadgid_invoices_ids',
            template: (items: IFinanceInvoice): string => items.leadgid_invoices_ids!.join(', '),
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.paid_status',
            key: 'paid_status',
            translate: translateFinanceInvoicesStatus,
        },
    ];

    numberFormatting = [
        {
            slot: 'item.amount',
            key: 'amount',
        },
        {
            slot: 'item.leadgid_invoices_amount',
            key: 'leadgid_invoices_amount',
        },
        {
            slot: 'item.amount_diff',
            key: 'amount_diff',
        },
    ];

    limit = PAGINATION_PER_PAGE;

    total = 0;

    isParseRouteUrl = false;

    settings = {
        title: 'Счета 1C',
        loading: false,
    };

    // для отображения роутов в таблице
    routes = [
        {
            getParams(id: any, route: any): any {
                return {
                    id: route.params?.id,
                    invoice_id: id,
                };
            },
            routerName: this.routerName,
            slot: 'item.id',
            text: 'id',
        },
    ];

    get routerName(): string {
        return this.isMasterAccount ? 'master-account-finance-invoice' : 'finance-invoice';
    }

    created(): void {
        this.setDefaultFiltersParams({});
        this.setFiltersParamsObject({});
    }

    mounted(): void {
        this.getFinanceInvoices(+this.$route.query.offset || 0, this.limit);
    }

    translateStatus(status: string): any {
        return translateFinanceInvoicesStatus(status);
    }

    async getFinanceInvoices(offset: number, limit: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        try {
            if (!this.isClear) {
                await this.getFilterWidthGuids();
            }
            const { meta, data } = await this.$props.apiMethod(this.$route.params.id,
                { offset, limit, ...this.getFiltersParamsObject });
            this.items = data;
            this.total = meta!.total!;
        } catch (err) {
            showServerError(err, 'Не удалось получить список счетов 1С');
        }
        this.isClear = false;
        this.settings.loading = false;
    }

    clearFilters(): void {
        this.isClear = true;
        this.getFinanceInvoices(0, this.limit);
    }

    getFilterWidthGuids(): void {
        this.getFilterGuidItems(this.filters, 'webmaster_legal_guids', getWebmastersSearchGuid);
        this.getFilterGuidItems(this.filters, 'leadgid_legal_guids', getLegalEntitySearchGuid);
    }
}
