
import { Component, PropSync, Vue } from 'vue-property-decorator';
import chunk from 'lodash-es/chunk';
import { ISingleMyLeadgidInvoice } from '@/api/types/payout';
import BaseCard from '@/components/base/BaseCard.vue';
import { namespace } from 'vuex-class';

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        BaseCard,
    },
})
export default class MyLeadgidInvoicePayments extends Vue {
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencySymbol;

    @PropSync('invoice',{ required: true, default: () => ({})  }) invoiceSync!: ISingleMyLeadgidInvoice;

    get paidSum(): number {
        if (this.invoiceSync.paid_status === 'paid') {
            return this.invoiceSync.total_amount;
        }
        if (Array.isArray(this.invoiceSync.partials) && this.invoiceSync.partials.length) {
            return this.invoiceSync.partials.reduce((acc, item) => acc + item.amount, 0);
        }
        return 0;
    }

    get getPaidSum(): string {
        return this.formatSum(this.paidSum);
    }

    get restSum(): string {
        return this.formatSum(this.invoiceSync.total_amount - this.paidSum);
    }

    formatSum(value: number): string {
        const sum = Number(value).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
        return `${sum} ${this.currencySymbol(this.invoiceSync.currency)}`;
    }

    get getDataForCard(): any[] {
        return [
            {
                text: 'Доход',
                value: this.formatSum(this.invoiceSync.total_revenue),
            },
            {
                text: 'Выплата',
                value: this.formatSum(this.invoiceSync.total_amount),
            },
            {
                text: 'Прибыль',
                value: this.formatSum(this.invoiceSync.total_profit),
            },
            {
                text: 'Уже выплачено',
                value: this.getPaidSum,
            },
            {
                text: 'Остаток',
                value: this.restSum,
            },
        ];
    }

    get fieldsColumns(): any[] {
        const fields = this.getDataForCard.filter((f) => !f.hidden);

        return this.$vuetify.breakpoint.width <= 959
            ? chunk(fields, Math.ceil(fields.length / 2))
            : [fields];
    }
}
