import { Module } from 'vuex';

import { actions } from '@/store/modules/invoice/actions';
import { mutations } from '@/store/modules/invoice/mutations';
import { getters } from '@/store/modules/invoice/getters';

import { IInvoiceState } from '@/store/modules/invoice/types';
import { IRootState } from '@/store/types';

export const state: IInvoiceState = {
    advertiser: '',
    period: '',
    timer: [],
};

const namespaced = true;

export const invoiceModule: Module<IInvoiceState, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
