
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import AppTable from '@/components/base/table/AppTable.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import Condition from '@/components/uapi/conditions/ConditionContent.vue';
import FormModal from '@/components/base/FormModal.vue';
import { ICondition } from '@/api/types/uapi';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';

@Component({
    components: {
        AppTable,
        SvgApplication,
        Condition,
        FormModal,
        TooltipButton,
    },
})
export default class Conditions extends Vue {
    @Prop() items!: ICondition[];
    @Prop({ type: String, default: 'primary--text' }) theadColor!: string;
    @Prop({ type: Boolean, default: true }) canEdit!: boolean;
    @Prop({ type: Number }) stageId!: number | null;
    @Prop({ type: Boolean, default: true }) showAddBtn!: boolean;
    @Prop({ type: Boolean, default: false }) loading!: boolean;

    deleteModal = false;

    get headers(): DataTableHeader[] {
        const items: DataTableHeader[] = [
            { value: 'type_name', text: 'Тип условия' },
            { value: 'value', text: 'Значение' },
            { value: 'field_name', text: 'Поле' },
        ];
        if (this.$props.canEdit) {
            items.push({ value: 'actions', text: '' });
        }
        return items;
    }

    actions = [
        {
            title: 'Редактировать',
            color: 'success',
            icon: 'edit',
            callback: (condition: ICondition): void => this.pushToEditRouter(condition),
        },
        {
            title: 'Удалить',
            color: 'error',
            icon: 'delete',
            callback: (condition: ICondition): void => this.showDeleteModal(condition),
        },
    ];

    condition: {} = {};

    get isDisabledBtn(): boolean {
        return !(this.condition as ICondition).type || !(this.condition as ICondition).value;
    }

    deleteCondition(): void {
        this.$emit('delete-condition', (this.condition as ICondition).id!);
        this.deleteModal = false;
    }

    pushToEditRouter(condition: ICondition): void {
        this.$emit('push-to-edit-router', condition.id);
    }

    showDeleteModal(condition: ICondition): void {
        if (condition.id) {
            this.$set(this, 'condition', condition);
            this.deleteModal = true;
        }
    }
}
