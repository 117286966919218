
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { ScrollPicker } from "vue-scroll-picker";
import "vue-scroll-picker/dist/style.css";

@Component({
    components: {
        ScrollPicker,
    },
})

export default class TimePicker extends Vue {
    @PropSync('value', { type: Object, required: true }) valueSync!: { hour: Date, minutes: Date};
    @Prop( { default: false }) isOnlyHour!: boolean;
    isMounted = false;
    manualInput = this.getCurrentTimeValue;

    mounted(): void {
        // костыль
        // нужен, чтобы этот компонент отрисовался раньше,
        // чем scroll picker, иначе scroll picker
        // не будет работать в модалках
        setTimeout(() => {
            this.isMounted = true;
            this.manualInput = this.getCurrentTimeValue;
        }, 500);
    }

    manualTimeInput(): void {
        const [hour, minutes] = this.manualInput.split(':');
        const validHours = +hour >= 0 && +hour < 24;
        const validMinutes = +minutes >= 0 && +minutes < 60;

        if (validHours && validMinutes) {
            this.$emit('update:value', Object.assign(this.valueSync, { hour, minutes }));
        } else {
            this.manualInput = this.getCurrentTimeValue;
        }
    }

    get getCurrentTimeValue(): string {
        return this.isMounted ? `${this.valueSync.hour}:${this.valueSync.minutes}` : '';
    }

    get hours(): Array<number | string> {
        const hours: Array<number | string> = [];
        for (let i = 0; i < 24; i++) {
            if (i < 10) {
                hours.push(`0${i}`);
            } else {
                hours.push(i);
            }
        }
        return hours;
    }

    get minutes(): Array<number | string> {
        const minutes: Array<number | string> = [];
        for (let i = 0; i < 60; i=i+1) {
            if (i < 10) {
                minutes.push(`0${i}`);
            } else {
                minutes.push(i);
            }
        }
        return minutes;
    }

    changeTime(key: string, value: string): void {
        this.$emit('update:value', Object.assign(this.valueSync, { [key]: value }));
        this.manualInput = this.getCurrentTimeValue;
    }

}

