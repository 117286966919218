
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getLoss } from '@/api/revenue';
import { showServerError } from '@/utils';
import AppTable from '@/components/base/table/AppTable.vue';

@Component({
    components: {
        AppTable,
    },
})
export default class Losses extends Vue {
    @Prop({ type: Number, required: true }) reportId!: number;
    @Prop({ default: null }) report!: any;

    duplicates = [];
    loading = false;
    total = 0;

    headers = [
        { text: 'ID', value: 'id' },
        { text: 'Key', value: 'key' },
    ];

    uniqueData = [
        {
            slot: 'item.offer',
            template: ({ conversion }: any): string => `${conversion.offer_id}`,
        },
    ];

    created(): void {
        this.getLoss();
    }

    async getLoss(): Promise<void> {
        this.loading = true;
        try {
            if (this.$props.report) {
                this.duplicates = this.$props.report.loss;
                this.total = this.$props.report.loss!.length || 0;
                this.headers.unshift({
                    text: 'Offer',
                    value: 'offer',
                });
            } else {
                const { meta, data } = await getLoss(this.$props.reportId);
                this.duplicates = data;
                this.total = meta!.total!;
            }
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }
}
