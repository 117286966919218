
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})

export default class SaveBtnTooltip extends Vue {
    @Prop({ required: true }) emptyConditions!: object;
    @Prop({ default: 'top' }) position!: string;

    get emptyConditionsText(): object {
        return {
            isEmptyOfferServices: 'В услугах по офферам не указаны ставка или количество',
            isEmptyPeriod: 'Необходимо выбрать период',
            isEmptyAdvertiser: 'Необходимо выбрать рекламодателя',
            isEmptyOffer: 'Необходимо добавить услуги по офферу',
            isExtraOffer: 'Не все офферы фигурируют в файлах',
            isEmptyAccountingServices: 'В счет необходимо добавить услуги',
            isEmptyAccountingOtherServices: 'В услугах не указана цена или количество',
            isEmptyProject: 'Необходимо выбрать проект',
            isEmptyContactor: 'Необходимо выбрать контрагента',
            isEmptyLegalEntity: 'Необходимо выбрать юр.лицо',
            isEmptyCurrency: 'Необходимо выбрать валюту',
            isEmptySortingConditions: 'Необходимо выбрать тип сортировки',
            isEmptyColumns: 'Необходимо выбрать столбцы',
            isEmptyParams: 'Необходимо выбрать показатели',
            isEmptyAffiliateId: 'Необходимо указать ID партнера',
            isEmptyCountry: 'Необходимо выбрать страну',
            isAlertBulkEditing: 'Офферы с выплатой в процентах не будут изменены',
            isEmptyFilters: 'Отфильтруйте отчёт по одному из параметров: <br>' +
                '- Счёт<br>' +
                '- Conversion ID<br>' +
                '- Transaction ID<br>' +
                'Либо фильтр по дате + один из:<br>' +
                    '- Оффер<br>' +
                    '- Партнёр<br>'+
                    '- Оффер + Sub ID рекламодателя<br>' +
                    '- Рекламодатель<br>',
        };
    }
}
