
import { Component, Mixins } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import TableMixin from '@/mixins/table';
import SvgLegalEntities from '@/assets/icons/nav-bar/legal-entities.svg';
import { getLegalEntities, getLegalEntitySearch } from '@/api/revenue';
import { showServerError } from '@/utils';
import { translateVisibility } from '@/utils/translate';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { IFilter, ITableStatus } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        SvgLegalEntities,
    },
})

export default class LegalEntities extends Mixins(TableMixin) {
    @Mutation('SET_TOTAL_ELEMENTS_DATA_TABLE') setTotalElementsDataTable;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;

    // name of cols
    headers = [
        { text: 'ID', sortable: false, value: 'id' },
        { text: 'Название', sortable: false, value: 'name' },
        { text: 'Видимость', sortable: false, value: 'settings.is_active' },
    ];

    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'ids',
            apiMethod: getLegalEntitySearch,
            label: 'ID юр.лица',
            itemValue: 'id',
            itemText: 'name',
            select: [],
            items: [],
            multiple: true,
        }),
        new filterClass.Select({
            id: 'is_active',
            label: 'Видимость',
            select: '',
            items: ['true', 'false', 'Все'],
            dontUseValue: 'Все',
            translate: translateVisibility,
        }),
    ];

    // для отображения роутов в таблице
    routes = [
        {
            routeParam: 'id',
            slot: 'item.name',
            routerName: 'legal-entity',
            text: 'name',
        },
    ];

    // для отображения булевых значений в таблице
    statuses: ITableStatus[] = [
        {
            key: 'is_active',
            slot: 'item.settings.is_active',
            sup: 'settings',
            translate: translateVisibility,
        },
    ];
    paginationItemsPerPage = PAGINATION_PER_PAGE;
    apiMethod = getLegalEntities;
    settings = {
        title: 'Наши юр.лица',
        loading: true,
    };

    mounted(): void {
        this.getLegalEntitiesPagination();
    }

    async getLegalEntitiesPagination(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Список счетов не загружен');
        }
    }
}
