import { IColumnFilter, IFilter } from "@/types";
import { translateChargeRequestStatus } from "@/utils/translate";
import { getActiveUserSearch, getAffiliateSearch } from "@/api/user";
import { CreatedFilters } from "@/services/filters/filters";
import { USER_ROLES } from "@/mappings/user-roles";
import store from "@/store";
import { filterClass } from '@/services/filters/filterClass';

export class ChargeRequestFilters extends CreatedFilters {
    // текущий юзер
    user = store.getters['authModule/GET_USER'];

    constructor() {
        super();
        // проставляем текущего юзера, если в query параметрах пусто(при переходе по прямой ссылке)
        if (this.isDefaultFilterByCreator && this.lengthQueryFilters <= 0 ) {
            this.setUser();
        }
    }

    get isDefaultFilterByCreator(): boolean {
        return this.user?.roles?.includes(USER_ROLES.AFFILIATE_MANAGER);
    }

    setUser(): void {
        const filter = this.filters.find(f => f.id === 'created_by_acc_ids');
        if (!filter) return;

        const creator = {
            id: this.user.id,
            account_id: this.user.account_id,
            name: `${this.user.account_id} ${this.user.first_name} ${this.user.last_name}`,
        };
        filter.select = [creator];
        filter.items!.push(creator);
    }

    setContractors(contractors: any): void{
        const filter = this.filters.find(f => f.id === 'contractor_guids');
        if (!filter) return;
        filter.items = contractors;
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Autocomplete({
                id: 'created_by_acc_ids',
                apiMethod: getActiveUserSearch,
                label: 'Автор',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_ids',
                apiMethod: getAffiliateSearch,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                multiple: true,
                multiPaste: true,
            }),
            new filterClass.Multiselect({
                id: 'contractor_guids',
                label: 'Способ выплаты',
                itemValue: 'guid',
                itemText: 'full_name',
                type: 'string',
                template: (item: any): string => item.full_name,
            }),
            new filterClass.Multiselect({
                id: 'statuses',
                label: 'Статус заявки',
                type: 'string',
                items: [{ id: 'new' }, { id: 'approved' }, { id: 'rejected' }, { id: 'sent' }, { id: 'in_progress' }, { id: 'done' }, { id: 'deleted' }],
                template: ({ id }) => this.translateStatus(id, translateChargeRequestStatus),
                itemText: ({ id }) => this.translateStatus(id, translateChargeRequestStatus),
                itemValue: 'id',
                noDataText: 'Нет статусов',
            }),
            new filterClass.ComboboxArray({
                id: 'ids',
                label: 'ID заявки',
                select: [],
            }),
            new filterClass.Datepicker({
                id: 'created_at',
                pickerType: 'date',
                label: 'Дата создания',
                range: true,
                clearable: true,
            }),
        ];
    }

    clearFilters(): void {
        if (this.isDefaultFilterByCreator)  {
            store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', { created_by_acc_ids: [this.user.account_id] });
        }
        super.clearFilters();
        if (this.isDefaultFilterByCreator) this.setUser();
    }
}

export const chargeRequestFiltersColumns: IColumnFilter[] = [
    {
        name: '',
        items: [
            { title: 'Внешний ID', value: 'code' },
            { title: 'Автор', value: 'creator' },
            { title: 'Партнёр', value: 'affiliate_ids' },
            { title: 'Организация', value: 'legal' },
            { title: 'Способ выплаты', value: 'contractor' },
            { title: 'Сумма', value: 'amount' },
            { title: 'Валюта', value: 'currency' },
            { title: 'Статья ДДС', value: 'dds' },
            { title: 'Проект', value: 'project' },
            { title: 'Создано', value: 'created_at' },
            { title: 'Обновлено', value: 'updated_at' },
            { title: 'Статус', value: 'status' },
        ],
    },
];
