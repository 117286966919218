
import { Vue, Component, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import { Affiliate, IAffiliateBalance } from "@/services/affiliates/Affiliate";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import SvgReload from "@/assets/icons/reload-double.svg";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import SvgCheck from "@/assets/icons/check-btn.svg";
import TitleActions from "@/components/affiliate/TitleActions.vue";

@Component({
    components: { TitleActions, TableWithFrontPaginationDesign, BaseCard, SvgReload, SvgArrow, SvgCheck },
})

export default class AffiliateBalance extends Vue {
    @Prop({ required: true }) affiliate!: Affiliate;

    isLoading: boolean = false;

    headers = [
        { text: 'Валюта', value: 'currency', sortable: false, align: 'left' },
        { text: 'Принято', value: 'limit', sortable: false, align: 'right'  },
        { text: 'Следующая выплата', value: 'date', sortable: false, align: 'center' },
        { text: 'Сумма к выплате ', value: 'amount', sortable: false, align: 'right'  },
        { text: 'Сумма счетов', value: 'invoiceSum', sortable: false , align: 'right' },
        { text: 'Сумма досрочек', value: 'payoutSum', sortable: false, align: 'right'  },
    ];

    get isLoadingTable(): boolean {
        return this.isLoading || this.affiliate.isLoadingData;
    }

    get getItems(): IAffiliateBalance[] {
        return this.affiliate.balance!;
    }
}

