import { ActionTree } from 'vuex';
import { IStatisticsState } from '@/store/modules/statistics/types';
import { IRootState } from '@/store/types';
import {
    cancelEmployeeConversionsJob,
    getEmployeeConversionsDownload,
    getEmployeeConversionsJobs,
    getEmployeeConversionsProgress,
} from '@/api/conversions-report';
import { delay, downloadExcelFile, showServerError } from '@/utils';
import { API_CONVERSION_PROXY } from '@/configs/global';

enum EJobStatuses {
    PENDING = 'pending',
    IN_PROGRESS = 'in_progress',
    FETCHING = 'fetching',
    COMPRESSING = 'compressing',
    UPLOADING = 'uploading',
    COMPLETED = 'completed',
    FAILED = 'failed',
    STOPPED = 'stopped',
}
const activeJobStatuses = [
    EJobStatuses.PENDING,
    EJobStatuses.IN_PROGRESS,
    EJobStatuses.FETCHING,
    EJobStatuses.COMPRESSING,
    EJobStatuses.UPLOADING,
];

export const actions: ActionTree<IStatisticsState, IRootState> = {
    EXPORT_BIG_CONVERSIONS_REPORT: async ({ getters, commit, dispatch }, jobId: string): Promise<void> => {
        try {
            commit('SET_CONVERSIONS_REPORT_ID', jobId);
            commit('SET_CONVERSIONS_REPORT_IN_PROGRESS', true);
            commit('SET_CONVERSIONS_REPORT_LOADING', true);

            // SSE реализация обновления прогресса
            // await dispatch('SSE_PROGRESS_REPORT', jobId);

            await dispatch('GET_PROGRESS_REPORT', jobId);

            if (getters.GET_CONVERSIONS_REPORT_STATUS === EJobStatuses.COMPLETED) {
                // если вкладка активна, то сразу скачиваем
                if (!document.hidden) {
                    await dispatch('DOWNLOAD_BIG_REPORT', jobId);
                } else {
                    // иначе ждём когда вкладка станет активной
                    const downloadCallback = async () => {
                        try {
                            // проверяем статус, чтобы убедиться,
                            // что отчёт ещё существует и сформирован,
                            // и тогда скачиваем
                            await dispatch('GET_PROGRESS_REPORT', jobId);
                            if (getters.GET_CONVERSIONS_REPORT_STATUS === EJobStatuses.COMPLETED) {
                                await dispatch('DOWNLOAD_BIG_REPORT', jobId);
                            }
                        } finally {
                            window.removeEventListener('focus', downloadCallback);
                        }
                    };
                    window.addEventListener('focus', downloadCallback);
                }
            }
        } finally {
            commit('RESET_CONVERSIONS_REPORT');
            commit('RESET_NOTIFICATION', {}, { root: true });
        }
    },
    GET_PROGRESS_REPORT: async ({ getters, commit, dispatch }, id: string): Promise<void> => {
        // задержка для корректного определения активной вкладки
        await new Promise((resolve) => setTimeout(resolve, 100));
        // чтобы при неактивной вкладке не пинговался статус задачи
        if (!document.hidden) {
            const { progress, status } = await getEmployeeConversionsProgress({ id, timestamp: new Date().getTime() });
            commit('SET_CONVERSIONS_REPORT_PROGRESS', progress);
            commit('SET_CONVERSIONS_REPORT_STATUS', status);
            await dispatch('UPDATE_PROGRESS_NOTIFICATION', progress);

            if (getters.GET_CONVERSIONS_REPORT_PROGRESS < 100) {
                await delay(500);
                if (activeJobStatuses.includes(getters.GET_CONVERSIONS_REPORT_STATUS)) {
                    await dispatch('GET_PROGRESS_REPORT', id);
                }
            }
        } else {
            await new Promise((resolve, reject) => {
                const checkCallback = async () => {
                    window.removeEventListener('focus', checkCallback);
                    try {
                        await dispatch('GET_PROGRESS_REPORT', id);
                        resolve(null);
                    } catch (e) {
                        reject(e);
                    }
                };
                window.addEventListener('focus', checkCallback);
            });
        }
    },
    SSE_PROGRESS_REPORT: async ({ commit, getters, dispatch }, jobId) => {
        // SSE реализация обновления прогресса
        await new Promise((resolve, reject) => {
            const eventSource = new EventSource(`${API_CONVERSION_PROXY}employees/report/job/sse?id=${jobId}`);
            commit('SET_CONVERSIONS_REPORT_SSE', eventSource);
            const stopSSE = () => {
                getters.GET_CONVERSIONS_REPORT_SSE.close();
                commit('SET_CONVERSIONS_REPORT_SSE', null);
                commit('RESET_CONVERSIONS_REPORT');
                commit('RESET_NOTIFICATION', {}, { root: true });
            };
            eventSource.addEventListener('message', async ({ data }) => {
                if (data.id === jobId) {
                    commit('SET_CONVERSIONS_REPORT_PROGRESS', data.progress);
                    commit('SET_CONVERSIONS_REPORT_STATUS', data.status);

                    if (data.status === EJobStatuses.FAILED) {
                        stopSSE();
                        reject(new Error('job failed'));
                    }
                    if (data.status === EJobStatuses.STOPPED) {
                        stopSSE();
                        resolve(null);
                    }
                    if (data.progress === 100 || data.status === EJobStatuses.COMPLETED) {
                        await dispatch('DOWNLOAD_BIG_REPORT', jobId);
                        stopSSE();
                        resolve(null);
                    }
                }
            });
            eventSource.addEventListener('error', reject);
        });
    },
    DOWNLOAD_BIG_REPORT: async ({}, id: string): Promise<void> => {
        const report = await getEmployeeConversionsDownload({ id });
        downloadExcelFile(report, 'Отчет по конверсиям', 'zip');
    },
    UPDATE_PROGRESS_NOTIFICATION: ({ commit }, progress: number) => {
        commit('SET_NOTIFICATION', {
            text: `Выгрузка формируется: ${progress.toFixed(0)}%`,
            type: 'info',
            progress,
            closeText: 'Отменить выгрузку',
            isConversionsReport: true,
        }, { root: true });
    },
    CHECK_CONVERSIONS_REPORT: async ({ commit, dispatch }) => {
        commit('RESET_CONVERSIONS_REPORT');

        const jobs = await getEmployeeConversionsJobs();
        if (jobs?.length) {
            for (const job of jobs) {
                if (job.status === 'completed') {
                    await dispatch('DOWNLOAD_BIG_REPORT', job.id);
                }
            }

            const activeJob = jobs.find((job) => activeJobStatuses.includes(job.status as EJobStatuses));
            if (activeJob) {
                commit('SET_CONVERSIONS_REPORT_PROGRESS', activeJob.progress);
                try {
                    await dispatch('EXPORT_BIG_CONVERSIONS_REPORT', activeJob.id);
                } catch (err) {
                    if (err.status !== 404) {
                        showServerError(err, 'Отчет по конверсиям не загружен');
                    }
                    commit('RESET_CONVERSIONS_REPORT');
                    commit('RESET_NOTIFICATION', {}, { root: true });
                }
            }
        }
    },
    CANCEL_BIG_CONVERSIONS_REPORT: async ({ getters, commit }): Promise<void> => {
        await cancelEmployeeConversionsJob(getters.GET_CONVERSIONS_REPORT_ID);
        commit('RESET_CONVERSIONS_REPORT');
        commit('RESET_NOTIFICATION', {}, { root: true });
    },
};
