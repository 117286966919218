
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
    getOfferGeo,
    editOfferGeo,
    getOfferRegions,
    editOffersRegion,
    getOfferCities,
    editOfferCities,
    deleteOfferCities,
    downloadOfferCities,
    downloadOfferRegions,
} from '@/api/uapi';
import { getCities } from '@/api/uapi';
import { IGeoRegions, IOfferGeo } from '@/api/types/uapi';
import { showServerError } from '@/utils';
import { SEARCH_VALUE_DEBOUNCE } from "@/configs/global";
import { downloadFileToDisk } from "@/api";

@Component({
    components: {
    },
})
export default class Geo extends Vue {
    @Prop({ type: Number, required: true }) offerId!: number;

    geo: IOfferGeo = {};

    allRegions: IGeoRegions = {};

    selectedRegions = {
        active: [],
        available: [],
    };

    loading = false;

    copySelectedRegions = {
        active: {},
        available: {},
    };

    allRegionsPagination = {};

    limit = 10;

    timerId = 0;

    paginationPage = {
        active: 1,
        available: 1,
    };

    cities: any[] = [];
    selectedCity: number[] | null = null;
    possibleCities: any[] = [];
    apiMethod = getCities;
    search: string = '';
    kladrCities: string = '';
    searchActiveCity: string = '';
    filteredCities: any[] = [];

    @Watch('paginationPage.active')
    changeCopySelectedActiveRegions(value: number): void {
        if (this.copySelectedRegions.active[value]) {
            this.selectedRegions.active = this.copySelectedRegions.active[value];
        }
    }


    @Watch('paginationPage.available')
    changeCopySelectedAvailableRegions(value: number): void {
        if (this.copySelectedRegions.available[value]) {
            this.selectedRegions.available = this.copySelectedRegions.available[value];
        }
    }

    searchCities(): void {
        if (this.search) {
            clearTimeout(this.timerId);
            this.timerId = window.setTimeout(() => {
                this.getPossibleItems();
            }, SEARCH_VALUE_DEBOUNCE);
        }
    }

    getCityName(city: any): string {
        return `${city.name} (${city.region_name})`;
    }

    filterActiveCities(): void {
        const search = this.searchActiveCity.toLowerCase();
        this.filteredCities = this.cities.filter(city =>
            city.name.toLowerCase().includes(search) ||
        city.region_name.toLowerCase().includes(search),
        );
    }
    async removeCity(city: any): Promise<void> {
        this.cities = this.cities.filter(c => c.id !== city.id);
        this.filteredCities = this.filteredCities.filter(c => c.id !== city.id);
        try {
            await deleteOfferCities(this.offerId, [city.id]);
            await this.updateCities();
        } catch (err) {
            showServerError(err);
        }
    }

    async getPossibleItems(): Promise<void> {
        try {
            const { data } = await this.apiMethod({
                q: this.search,
            });
            this.possibleCities = data;
        } catch (err) {
            showServerError(err);
        }
    }

    get disabledActiveBtn(): boolean {
        const hasCopySelectedActiveRegions = Object.values(this.copySelectedRegions.active)
            .some((item: any) => item.length);
        const hasSelectedActiveRegions = !!this.selectedRegions.active.length;
        return !hasCopySelectedActiveRegions && !hasSelectedActiveRegions;
    }

    get disabledAvailableBtn(): boolean {
        const hasCopySelectedAvailableRegions = Object.values(this.copySelectedRegions.available)
            .some((item: any) => item.length);
        const hasSelectedAvailableRegions = !!this.selectedRegions.available.length;
        return !hasCopySelectedAvailableRegions && !hasSelectedAvailableRegions;
    }

    get activeRegionsPage(): number {
        if (this.allRegions.active) {
            return Math.ceil(this.allRegions.active.length / this.limit);
        }
        return 0;
    }

    get availableRegionsPage(): number {
        if (this.allRegions.available) {
            return Math.ceil(this.allRegions.available.length / this.limit);
        }
        return 0;
    }

    created(): void {
        this.getOfferGeo();
        this.getOfferRegions();
        this.updateCities();
    }

    addRangeRegions(event: MouseEvent, key: string, region_id: number): void {
        if (event.shiftKey && this.selectedRegions[key].length) {
            const regions =  this.selectedRegions[key];
            const regionsLength = regions.length;
            const lastSelectedRegion = regions[regionsLength - 1];

            const indexLastSelectedRegion = this.allRegions[key].findIndex(({ id }) => id === lastSelectedRegion);
            const indexSelectedRegion = this.allRegions[key].findIndex(({ id }) => id === region_id);
            let rangeRegions;
            if (indexSelectedRegion < indexLastSelectedRegion) {
                rangeRegions = this.allRegions[key].slice(indexSelectedRegion + 1, indexLastSelectedRegion + 1);
            } else {
                rangeRegions = this.allRegions[key].slice(indexLastSelectedRegion, indexSelectedRegion);
            }
            rangeRegions.forEach(({ id }) => {
                if (!regions.includes(id)) {
                    regions.push(id);
                }
            });
        }
    }

    async getOfferGeo(): Promise<void> {
        try {
            this.geo = await getOfferGeo(this.$props.offerId);
        } catch (err) {
            showServerError(err);
        }
    }

    async editOfferGeo(): Promise<void> {
        try {
            this.geo = await editOfferGeo(this.$props.offerId, this.geo);
        } catch (err) {
            showServerError(err);
        }
    }

    async getOfferRegions(): Promise<void> {
        try {
            const { data } = await getOfferRegions(this.$props.offerId);
            this.allRegions = data;
            this.getPaginationData('active', 0);
            this.getPaginationData('available', 0);
        } catch (err) {
            showServerError(err);
        }
    }

    clearSelectedRegions(key: string): void {
        this.copySelectedRegions[key] = {};
        this.selectedRegions[key] = [];
    }

    getPaginationData(key: string, offset: number): void {
        if (offset === 0) {
            this.paginationPage[key] = 1;
        }
        this.allRegionsPagination[key] = this.allRegions[key].slice(offset, this.limit + offset);
    }

    changePaginationPage(num: number, key: string): void {
        this.copySelectedRegions[key][this.paginationPage[key]] = [...this.selectedRegions[key]];
        this.paginationPage[key] = num;
        this.getPaginationData(key, num * this.limit - this.limit);
    }

    async addRegions(from: string, to: string): Promise<void> {
        try {
            this.loading = true;
            const selectedFromRegions = new Set();
            for (const page in this.copySelectedRegions[from]) {
                if (this.copySelectedRegions[from].hasOwnProperty(page)) {
                    this.copySelectedRegions[from][page].forEach((item) => selectedFromRegions.add(item));
                }
            }
            this.selectedRegions[from].forEach((region) => selectedFromRegions.add(region));
            [...selectedFromRegions].forEach((id) => {
                const currentRegion = this.allRegions[from].find((item) => item.id === id);
                if (currentRegion) {
                    this.allRegions[to].push(currentRegion);
                }
            });
            this.allRegions[from] = this.allRegions[from].filter((region) => !this.allRegions[to].includes(region));
            const { data } = await editOffersRegion(
                this.$props.offerId,
                { region_id: this.allRegions.active!.map(({ id }) => id) },
            );
            this.allRegions = data;
            this.getPaginationData('active', 0);
            this.getPaginationData('available', 0);
            await this.updateCities();
            this.selectedRegions.active = [];
            this.selectedRegions.available = [];
            this.copySelectedRegions.active = {};
            this.copySelectedRegions.available = {};
            this.loading = false;
        } catch (err) {
            this.loading = false;
            showServerError(err);
        }
    }

    async updateCities(): Promise<void> {
        try {
            const { data } = await getOfferCities(this.$props.offerId);
            this.cities = data;
            this.filteredCities = this.cities;
        } catch (err) {
            showServerError(err);
        }
    }

    async saveCities(): Promise<void> {
        try {
            const city_id = this.selectedCity ? this.selectedCity : [];
            const city_code = this.kladrCities.trim().split(/[\s,\.]+/).filter((item) => item.length > 0);
            await editOfferCities(this.$props.offerId, { city_id, city_code });
            this.selectedCity = null;
            this.kladrCities = '';
            await this.updateCities();
            await this.getOfferRegions();
        } catch (err) {
            showServerError(err);
        }
    }

    async downloadCities(): Promise<void> {
        try {
            const file = await downloadOfferCities(this.$props.offerId);
            const name = `cities`;
            downloadFileToDisk(file, { name, extension: 'csv' });
        } catch (err) {
            showServerError(err, 'Не удалось получить выгрузку');
        }
    }

    async downloadRegions(): Promise<void> {
        try {
            const file = await downloadOfferRegions(this.$props.offerId);
            const name = `regions`;
            downloadFileToDisk(file, { name, extension: 'csv' });
        } catch (err) {
            showServerError(err, 'Не удалось получить выгрузку');
        }
    }
}
