
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import { SurveyQuestion } from "@/services/surveys/SurveyQuestion";
import SvgText from '@/assets/icons/survey/text.svg';
import SvgRadio from '@/assets/icons/survey/radio.svg';
import SvgCheckbox from '@/assets/icons/survey/checkbox.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import draggable from 'vuedraggable';

@Component({
    components: {
        TrashBtn,
        BaseCard, SvgText, SvgRadio, SvgCheckbox, draggable,
    },
})

export default class CreateQuestion extends Vue {
    @Prop({ default: {} }) question!: SurveyQuestion;
    @Prop({ default: 0 }) index!: number;

    isShowTextAnswer = false;

    get items(): {value: string, icon: string}[] {
        if (this.isShowTextAnswer) {
            return  [
                { value: 'one_variant_text', icon: SvgRadio },
                { value: 'multi_variant_text', icon: SvgCheckbox },
                { value: 'text', icon: SvgText },
            ];
        } else {
            return [
                { value: 'one_variant', icon: SvgRadio },
                { value: 'multi_variant', icon: SvgCheckbox },
                { value: 'text', icon: SvgText },
            ];

        }
    }

    mounted(): void {
        this.isShowTextAnswer = !(this.question.type === 'multi_variant' || this.question.type === 'one_variant');
    }

    deleteQuestion(index: number): void {
        this.question.delete(index);
    }

    get isShowAnswerTrash(): boolean {
        if (this.question.isRadioType || this.question.isMultiType) {
            return this.question.answers.length >= 2;
        } else {
            return this.question.answers.length >= 1;
        }
    }

    changeSurveyType(): void {
        if (this.question.isVariantAndText && !this.question.isAnswerFull) {
            this.question.addAnswer();
            this.question.addAnswer();
        }
        else if (this.question.isTextType) {
            this.question.answers = [];
        }
    }

    switchFocus(index: number): void {
        const refName = `survey-question__answer_${index + 1}`;
        const target = this.$refs[refName];
        if (target) {
            target[0].focus();
        }
    }
}

