
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import FormModal from "@/components/base/FormModal.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { IBaseAutocomplete, TableHeader } from "@/types";
import { getActiveUserSearch } from "@/api/user";
import { Advertiser } from "@/services/advertiser/Advertiser";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { IAdvertiserManagers } from "@/api/types/revenue";
import { eventBus } from "@/eventbus";

@Component({
    components: {
        BaseAutocomplete,
        TableWithFrontPaginationDesign, FormModal, BaseCard },
})

export default class AdditionalManagers extends Vue {
    @Prop({ required: true }) advertiser!: Advertiser;

    isShowModal: boolean = false;
    isShowDeleteModal: boolean = false;
    isLoading: boolean = false;
    managerId: number | null = null;
    managerName: string = '';

    headers: TableHeader[] = [
        { text: 'Имя', sortable: false, value: 'user.first_name' },
        { text: 'Фамилия', sortable: false, value: 'user.last_name' },
        { text: '', sortable: false, value: 'actions', align: 'center', width: '30px' },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: false,
            callback: (item: IAdvertiserManagers): void => {
                this.prepareDeleteForm(item);
            },
        },
    ];

    get getItems(): IAdvertiserManagers[] {
        if (!this.advertiser || !this.advertiser.managers) return [];
        return this.advertiser.managers.filter(item => !item.is_primary);
    }

    get getManagerData(): IBaseAutocomplete {
        return {
            apiMethod: getActiveUserSearch,
            errorText: 'Менеджер не найден',
            placeholder: 'Менеджер',
            itemValue: 'id',
            template: (i: any) => `${i.id} ${i.first_name} ${i.last_name}`,
            multiple: false,
            isChip: true,
        };
    }

    changeManager(id: number): void {
        this.managerId = id;
    }

    async addManager(): Promise<void> {
        if (!this.managerId) return;
        const id = String(this.managerId);
        this.isLoading = true;
        await this.advertiser.addManager(id);
        this.closeModal();
    }

    prepareDeleteForm(manager: IAdvertiserManagers): void {
        this.managerId = manager.id;
        this.managerName = manager.user.full_name!;
        this.isShowDeleteModal = true;
    }

    async deleteManager(): Promise<void> {
        if (!this.managerId) return;
        this.isLoading = true;
        await this.advertiser.deleteManager(this.managerId);
        this.closeModal();
    }

    closeModal(): void {
        this.isLoading = false;
        this.isShowModal = false;
        this.isShowDeleteModal = false;
        this.managerId = null;
        this.managerName = '';
        eventBus.$emit('clear');
    }
}

