import { IColumnFilter, IFilter } from "@/types";
import { CreatedFilters } from "@/services/filters/filters";
import { filterClass } from "@/services/filters/filterClass";
import { getAffiliateSearch, getActiveUserSearch } from "@/api/user";
import { translatePayoutsRequestStatus } from "@/utils/translate";
import { searchEmployeeMethods } from "@/api/payout";
import { showServerError } from "@/utils";

export const AffiliateMethodsColumns: IColumnFilter[] =  [
    {
        name: '',
        items: [
            { title: 'ID запроса', value: 'id' },
            { title: 'ID партнёра', value: 'affiliate_id' },
            { title: 'ФИО партнёра', value: 'affiliate_name' },
            { title: 'Статус партнёра', value: 'affiliate_status' },
            { title: 'Менеджер партнёра', value: 'affiliate_manager' },
            { title: 'Способ выплаты', value: 'method_name' },
            { title: 'Реквизиты', value: 'requisite' },
            { title: 'Основной', value: 'is_default', default: false },
            { title: 'Статус запроса', value: 'status' },
            { title: 'Дата создания', value: 'created_at' },
            { title: 'Дата обновления', value: 'updated_at' },
        ],
    },
];

export class AffiliateMethodsFilters extends CreatedFilters {
    constructor() {
        super();
        (async () => await this.getMethods())();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Multiselect({
                id: 'statuses',
                label: 'Статус заявки',
                type: 'string',
                select: [{ id: 'new' }],
                itemValue: 'id',
                items: [
                    { id: 'new' },
                    { id: 'approved' },
                    { id: 'manager_rejected' },
                    { id: 'partner_rejected' },
                    { id: 'partner_edited' },
                    { id: 'deleted' },
                ],
                defaultValue: ['new'],
                template: ({ id }) => this.translateStatus(id, translatePayoutsRequestStatus),
                itemText: ({ id }) => this.translateStatus(id, translatePayoutsRequestStatus),
            }),
            new filterClass.Autocomplete({
                id: 'partner_ids',
                apiMethod: getAffiliateSearch,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                multiple: true,
                multiPaste: true,
            }),
            new filterClass.Autocomplete({
                id: 'partner_manager_ids',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                template: (item: any): string => `${item.id} ${item.first_name} ${item.last_name}`,
            }),
            new filterClass.ComboboxArray({
                id: 'ids',
                type: 'number',
                label: 'ID запроса',
                select: [],
                items: [],
            }),
            new filterClass.Datepicker({
                id: 'created',
                pickerType: 'date',
                select: [],
                label: 'Дата создания',
                range: true,
                clearable: true,
            }),
            new filterClass.Multiselect({
                id: 'method_ids',
                label: 'Способ оплаты',
                itemValue: 'id',
                itemText: this.getPaymentMethodsTemplate,
                select: [],
                items: [],
                type: 'string',
                template: this.getPaymentMethodsTemplate,
            }),
        ];
    }
    getPaymentMethodsTemplate(item: any): string {
        return item.name.ru;
    }
    async getMethods(): Promise<void> {
        const filter = this.filters.find((fl) => fl.id === 'method_ids');
        if (!filter) return;

        try {
            const data = await searchEmployeeMethods();
            filter.items = data;
        } catch (err) {
            showServerError(err, 'Список методов не загружен');
        }
    }
}
