
import { Component, Prop, Vue } from 'vue-property-decorator';
import { downloadAdvertiserContract } from '@/api/revenue';
import { IContract, IContractor } from "@/api/types/revenue";
import SvgFileDownloaded from "@/assets/icons/file-download-grey.svg";
import { downloadFile } from "@/api";
import { showServerError } from "@/utils";
import Loader from "@/components/base/Loader.vue";

@Component({
    components: {
        Loader,
        SvgFileDownloaded,
    },
})

export default class ContractList extends Vue {
    @Prop({ required: true }) readonly contractor!: IContractor;

    isLoading: boolean = false;

    get getContracts(): IContract[] {
        return this.contractor.contracts || [];
    }

    async download(item: IContract): Promise<void> {
        try {
            this.isLoading = true;
            await downloadFile(item, downloadAdvertiserContract);
        } catch (err) {
            showServerError(err, 'Файл не найден');
        } finally {
            this.isLoading = false;
        }
    }
}
