
import { Component, ProvideReactive, Vue } from "vue-property-decorator";
import PageLayout from "@/components/layout/PageLayout.vue";
import Status from '@/components/base/Status.vue';
import { showServerError } from "@/utils";
import { translateChargeRequestStatus } from "@/utils/translate";
import ChargeRequestPageHeader from "@/components/affiliate/charge-requests/ChargeRequestPageHeader.vue";
import { getChargeRequest, patchChargeRequest } from "@/api/payout";
import { IStatus } from "@/types";
import Loader from "@/components/base/Loader.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import ChargeRequestPageBody from "@/components/affiliate/charge-requests/ChargeRequestPageBody.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import { namespace } from "vuex-class";
import SvgCheck from "@/assets/icons/check.svg";
import SvgClose from "@/assets/icons/close.svg";

const auth = namespace('authModule');

@Component({
    components: {
        SvgCheck, SvgClose,
        ChargeRequestPageBody,
        ChargeRequestPageHeader,
        PageLayout,
        Status, Loader, BaseCard,
    },
})

export default class ChargeRequestBody extends Vue {
    chargeRequestNumber: string = '';
    currentRequest: any = {};
    isLoading: boolean = false;
    @auth.Getter('GET_USER') user;

    get getTitle(): string {
        return `Заявка на расход №${this.chargeRequestNumber}`;
    }

    @ProvideReactive()
    get isNewRequest(): boolean {
        return this.currentRequest.status === 'new';
    }

    get getStatus(): IStatus {
        return translateChargeRequestStatus(this.currentRequest.status);
    }

    get hasNeededRoles(): boolean{
        return this.user?.roles?.includes(USER_ROLES.SUPERVISOR) || this.user?.roles?.includes(USER_ROLES.ADMIN);
    }

    async getCurrentRequest(): Promise<void> {
        try {
            this.currentRequest = await getChargeRequest(this.chargeRequestNumber);
        } catch (err) {
            showServerError(err,  'Заявка на расход не найден');
        }
    }

    async changeRequestStatus(status: string): Promise<void> {
        this.isLoading = true;
        const ID: string = this.currentRequest.id;
        try {
            await patchChargeRequest([Number(ID)], status);
        } catch (err) {
            showServerError(err);
        } finally {
            this.currentRequest.status = status;
            this.isLoading = false;
        }
    }

    created(): void {
        this.chargeRequestNumber = this.$route.params.id;
        this.getCurrentRequest();
    }
}

