
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseCard from '@/components/base/BaseCard.vue';
import ViewDateTime from '@/components/base/ViewDateTime.vue';
import Status from '@/components/base/Status.vue';
import StarOutlined from '@/assets/icons/star-outlined.svg';
import { IEmployeeAffiliateMethod } from '@/api/types/payout';

enum STATUS {
    APPROVED = 'approved',
}

interface IInfoRow {
    name: string;
    value: any;
    type?: string;
    isMethod?: boolean;
    route?: string;
    param?: number;
}

@Component({
    components: {
        BaseCard,
        ViewDateTime,
        Status,
        StarOutlined,
    },
})
export default class PayoutMethodInfo extends Vue {
    @Prop( { required: true }) employeeAffiliateMethod!: IEmployeeAffiliateMethod;

    get getInfoFields(): IInfoRow[] {
        const {
            affiliate_id,
            affiliate_name,
            affiliate_manager,
            created_at,
            updated_at,
            method,
        } = this.employeeAffiliateMethod;

        const affiliate = affiliate_id ? `${affiliate_id} ${affiliate_name}` : '';
        const manager = affiliate_manager ? `${affiliate_manager.first_name} ${affiliate_manager.last_name}` : '';

        return [
            {
                name: 'Партнёр',
                value: affiliate,
                route: 'affiliate',
                param: affiliate_id,
            },
            {
                name: 'Менеджер партнёра',
                value: manager,
            },
            {
                name: 'Дата создания',
                value: created_at,
                type: 'date',
            },
            {
                name: 'Дата изменения',
                value: updated_at,
                type: 'date',
            },
            {
                isMethod: true,
                name: 'Способ выплат',
                value: this.getPaymentMethodsTemplate(method),
            },
        ];
    }

    getPaymentMethodsTemplate(item: any): string {
        return item?.name.ru;
    }

    get isNotDefault(): boolean {
        return this.employeeAffiliateMethod.is_default === false && this.employeeAffiliateMethod.status === STATUS.APPROVED;
    }
}
