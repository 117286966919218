
import { Component, Vue } from 'vue-property-decorator';
import FinmediaInvoiceContent from '@/components/revenue/invoice/FinmediaInvoiceContent.vue';

@Component({
    components: { FinmediaInvoiceContent },
})

export default class FinmediaInvoiceCreation extends Vue {
}
