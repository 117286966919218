
import { Component, Mixins, Prop } from 'vue-property-decorator';
import LogoutMixin from '@/mixins/logout';
import NavNotifications from '@/components/layout/NavNotifications.vue';
import QuickLinks from '@/components/layout/QuickLinks.vue';
import SvgUsers from '@/assets/icons/nav-bar/users.svg';
import SvgExitDoor from '@/assets/icons/nav-bar/exit-door.svg';

@Component({
    components: {
        QuickLinks,
        NavNotifications,
        SvgUsers,
        SvgExitDoor,
    },
})
export default class NavAside extends Mixins(LogoutMixin) {
    @Prop({ type: Boolean, default: true }) mini!: boolean;
    @Prop({ type: Array, default: () => ([]) }) links!: [];

    get currentLinks(): [] {
        if (this.$vuetify.breakpoint.lgAndUp) {
            return this.$props.links;
        } else {
            // на планшетах и мобилках не показываем уведомления в боковом меню
            return this.$props.links.filter((link) => link.key !== 'notifications');
        }
    }

    logoutUser(): void {
        this.$socket.client.disconnect();
        this.logout();
    }
}
