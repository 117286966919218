
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { getClonesReport, addClone } from '@/api/revenue';
import AppTable from '@/components/base/table/AppTable.vue';
import Report from '@/mixins/report';
import LoadingList from '@/components/revenue/statTask/report/LoadingList.vue';
import { IReportAdjustOrClone } from '@/api/types/revenue';
import { eventBus } from '@/eventbus';
import { changeTopScrollWidth, showServerError } from '@/utils';
import { IFilter, ITableStatus } from '@/types';
import { filterClass } from '@/services/filters/filterClass';
import { translateConversionStatus } from "@/utils/translate";

@Component({
    components: {
        AppTable,
        LoadingList,
    },
})
export default class Clones extends Mixins(Report) {
    @Prop({ type: Number, required: true }) reportId!: number;
    @Prop({ default: null }) report!: any;

    allClones: IReportAdjustOrClone[] = [];
    newClone = '';
    filters: IFilter[] = [
        new filterClass.Input({
            id: 'transaction_id',
            label: 'Transaction ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'lead_id',
            label: 'Lead ID',
            select: '',
            type: 'number',
        }),
        new filterClass.Input({
            id: 'advertiser_sub_id',
            label: 'Sub ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'affiliate_id',
            label: 'ID веба',
            select: '',
            type: 'number',
        }),
        new filterClass.Select({
            id: 'status',
            label: 'Статус лида',
            items: [],
            select: '',
            uuid: 'clones',
        }),
        new filterClass.Select({
            id: 'payout',
            label: 'Выплата',
            items: [],
            select: '',
            uuid: 'clones',
        }),
        new filterClass.Select({
            id: 'revenue',
            label: 'Доход',
            items: [],
            select: '',
            uuid: 'clones',
        }),
        new filterClass.Datepicker({
            id: 'updated',
            label: 'Дата обновления',
            pickerType: 'month',
            select: '',
            range: true,
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Дата создания',
            pickerType: 'month',
            select: '',
            range: true,
        }),
    ];

    headers = [
        { text: 'Transaction ID', value: 'conversion.transaction_id' },
        { text: 'Sub ID', value: 'conversion.advertiser_sub_id' },
        { text: 'Lead ID', value: 'conversion.lead_id' },
        { text: 'ID веба', value: 'conversion.affiliate_id' },
        { text: 'Имя веба', value: 'conversion.affiliate_name' },
        { text: 'Валюта', value: 'conversion.currency' },
        { text: 'Выплата', value: 'conversion.payout' },
        { text: 'Доход', value: 'conversion.revenue' },
        { text: 'Статус лида', value: 'conversion.status' },
        { text: 'Дата лида', value: 'conversion.created' },
        { text: 'Дата обновления', value: 'conversion.updated' },
    ];

    // для отображения статусов в таблице
    statuses: ITableStatus[] = [
        {
            slot: 'item.conversion.status',
            sup: 'conversion',
            key: 'status',
            translate: translateConversionStatus,
        },
    ];

    mounted(): void {
        this.getClonesReport();
        eventBus.$on('change-clones', changeTopScrollWidth);
    }

    async getClonesReport(): Promise<void> {
        this.loading = true;
        try {
            let clones;
            if (this.$props.report) {
                clones = this.$props.report.clones_link;
                this.headers.unshift({
                    text: 'Offer',
                    value: 'offer',
                });
            } else {
                const { data } = await getClonesReport(this.$props.reportId);
                clones = data;
            }
            this.allClones = clones;
            this.initialData = clones.flatMap((el) => el.conversions);
            this.filteredData = [...this.initialData];
            this.fillFiltersItems();
            this.total = this.initialData.length;
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }

    async addClone(): Promise<void> {
        try {
            await addClone(this.$props.reportId, { url: this.newClone });
            await this.getClonesReport();
            this.newClone = '';
        } catch (err) {
            showServerError(err);
        }
    }

    beforeDestroy(): void {
        eventBus.$on('change-clones', changeTopScrollWidth);
    }
}
