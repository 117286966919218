import { USER_ROLES } from "@/mappings/user-roles";
import MasterAccount from "@/views/orgstats/masterAccount/Single.vue";
import MasterAccounts from "@/views/orgstats/masterAccount/List.vue";
import MasterAccountMyLeadgidInvoices from "@/views/orgstats/masterAccount/MyLeadgidInvoices.vue";
import MasterAccountFinanceInvoices from "@/views/orgstats/masterAccount/FinanceInvoices.vue";
import MasterAccountFinanceInvoice from "@/views/orgstats/masterAccount/FinanceInvoice.vue";
import PageFinanceInvoices from "@/views/orgstats/FinanceInvoices.vue";
import AffiliatesReport from "@/views/orgstats/affiliates/AffiliatesReport.vue";
import Affiliates1CInvoice from "@/views/orgstats/affiliates/Affiliates1CInvoice.vue";
import AffiliatesAgentReport from "@/views/orgstats/affiliates/AffiliatesAgentReport.vue";
import OfferReport from "@/views/orgstats/offers/OffersReport.vue";
import Offers1CInvoice from "@/views/orgstats/offers/Offers1CInvoice.vue";
import OffersAgentReport from "@/views/orgstats/offers/OffersAgentReport.vue";
import ServicesReport from "@/views/orgstats/ServicesReport.vue";
import AgentReportList from "@/views/orgstats/agentReport/AgentReportList.vue";
import AgentReport from "@/views/orgstats/agentReport/AgentReport.vue";
import Accesses from "@/views/orgstats/Accesses.vue";

export default [
    {
        path: 'master-account/:id',
        name: 'master-account',
        props: true,
        meta: {
            title: 'Мастер аккаунт',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: MasterAccount,
    },
    {
        path: 'master-accounts',
        name: 'master-accounts',
        meta: {
            title: 'Мастер-аккаунты',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: MasterAccounts,
    },
    {
        path: 'master-account/:id/invoices-my-leadgid',
        name: 'master-account-invoices-my-leadgid',
        meta: {
            title: 'Счета my.leadgid',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: MasterAccountMyLeadgidInvoices,
    },
    {
        path: 'master-account/:id/finance-invoices',
        name: 'master-account-finance-invoices',
        meta: {
            title: 'Счета 1С',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: MasterAccountFinanceInvoices,
    },
    {
        path: 'master-account/:id/finance-invoice/:invoice_id',
        name: 'master-account-finance-invoice',
        meta: {
            title: 'Счет 1С',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: MasterAccountFinanceInvoice,
    },
    {
        path: 'finance-invoices',
        name: 'finance-invoices',
        meta: {
            title: 'Счета 1С',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: PageFinanceInvoices,
    },
    {
        path: 'finance-invoice/:invoice_id',
        name: 'finance-invoice',
        meta: {
            title: 'Счет 1С',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: MasterAccountFinanceInvoice,
    },
    {
        path: 'affiliate-report',
        name: 'affiliate-report',
        component: AffiliatesReport,
        meta: {
            title: 'Отчёт по партнерам',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        children: [
            {
                path: '1c-invoice',
                name: 'affiliate-1c-invoice',
                meta: {
                    title: 'Счет из 1С',
                    requiresAuth: true,
                    roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
                    ],
                },
                component: Affiliates1CInvoice,
            },
            {
                path: 'agent-report',
                name: 'affiliate-agent-report',
                meta: {
                    title: 'Отчёт агента',
                    requiresAuth: true,
                    roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
                    ],
                },
                component: AffiliatesAgentReport,
            },
        ],
    },
    {
        path: 'offer-report',
        name: 'offer-report',
        meta: {
            title: 'Отчёт по офферам',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: OfferReport,
        children: [
            {
                path: '1c-invoice',
                name: 'offer-1c-invoice',
                meta: {
                    title: 'Счет из 1С',
                    requiresAuth: true,
                    roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
                    ],
                },
                component: Offers1CInvoice,
            },
            {
                path: 'agent-report',
                name: 'offer-agent-report',
                meta: {
                    title: 'Отчёт агента',
                    requiresAuth: true,
                    roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
                    ],
                },
                component: OffersAgentReport,
            },
        ],
    },
    {
        path: 'services-report',
        name: 'services-report',
        meta: {
            title: 'Отчёт по услугам',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: ServicesReport,
    },
    {
        path: 'agent-reports',
        name: 'agent-reports',
        meta: {
            title: 'Отчёты агентов',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        component: AgentReportList,
    },
    {
        path: 'agent-report/:id',
        name: 'agent-report',
        component: AgentReport,
        meta: {
            title: 'Отчет агента',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
    },
    {
        path: 'accesses',
        name: 'accesses',
        meta: {
            title: 'Доступы',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
        component: Accesses,
    },
];
