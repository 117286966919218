
import { Component, Vue } from 'vue-property-decorator';
import StatsCard from '@/components/base/StatsCard.vue';
import { getOneMasterAccount } from '@/api/org-cabinets-backend';
import { showServerError } from '@/utils';
import { IMasterAccount } from '@/api/types/org-cabinets-backend';
import Status from '@/components/base/Status.vue';
import Header from '@/components/orgstats/masterAccount/Header.vue';
import { translateMasterAccountStatus } from '@/utils/translate';
import AccountTable from '@/components/orgstats/masterAccount/AccountTable.vue';
import LegalEntity from '@/components/orgstats/masterAccount/LegalEntity.vue';

@Component({
    components: {
        LegalEntity,
        AccountTable,
        StatsCard,
        Status,
        Header,
    },
})
export default class MasterAccount extends Vue {
    translateMasterAccountStatus = translateMasterAccountStatus;
    masterAccount = {} as IMasterAccount;
    panel = [0];
    headers = [
        {
            text: 'ID',
            value: 'account_id',
            align: 'center',
            width: '150px',
            class: 'font-weight-regular',
        },
        {
            text: 'Имя',
            value: 'name',
            class: 'font-weight-regular',
        },
        {
            text: 'Почта',
            value: 'email',
            class: 'font-weight-regular',
        },
        {
            text: 'Статус',
            value: 'active',
            class: 'font-weight-regular',
        },
    ];

    get masterAccountID(): string {
        return this.$route.params.id;
    }

    get isEmptyMasterAccount(): boolean {
        return Object.keys(this.masterAccount).length <= 0;
    }

    async created(): Promise<void> {
        try {
            this.masterAccount = await getOneMasterAccount(this.masterAccountID);
        } catch (err) {
            showServerError(err, 'Мастер аккаунт не найден');
        }
    }
}
