
import { Vue, Component, Prop } from "vue-property-decorator";
import AdvertiserAbout from "@/components/revenue/advertiser/AdvertiserAbout.vue";
import AdditionalManagers from "@/components/revenue/advertiser/AdditionalManagers.vue";
import AdvertisersAffiliates from "@/components/revenue/advertiser/AdvertisersAffiliates.vue";
import AdvertiserContractors from "@/components/revenue/advertiser/AdvertiserContractors.vue";
import { Advertiser } from "@/services/advertiser/Advertiser";

@Component({
    components: {
        AdvertiserContractors,
        AdvertisersAffiliates,
        AdditionalManagers,
        AdvertiserAbout,
    },
})

export default class AdvertiserSingle extends Vue {
    @Prop({ required: true }) advertiser!: Advertiser;
}

