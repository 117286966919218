
import {
    Component, Vue, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import {
    PAGINATION_PER_PAGE,
    REPORT_PAGINATION_TOTAL_VISIBLE,
} from '@/configs/global';

@Component
export default class TablePagination extends Vue {
    @Prop({ type: Number, default: 0 }) readonly totalElementDataTable!: number;
    @Prop({ type: Number, default: PAGINATION_PER_PAGE }) selectedLimit!: number;
    @Prop({ type: Boolean, default: false }) isResendData!: boolean;
    @PropSync('pagination', { type: Object }) paginationSync!: {page: number};

    totalVisible = REPORT_PAGINATION_TOTAL_VISIBLE;

    @Watch('isResendData')
    reSendDateTable(): void {
        if (this.isResendData) {
            this.$emit('get-pagination-data', this.paginationOffset, this.selectedLimit);
        }
    }

    get paginationOffset(): number {
        return (this.paginationSync.page * this.selectedLimit) - this.selectedLimit;
    }

    get paginationPages(): number {
        if (this.selectedLimit === null
            || this.$props.totalElementDataTable === null) {
            return 0;
        }
        return Math.ceil(this.$props.totalElementDataTable / this.selectedLimit);
    }
}
