
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import AddingServices from '@/components/revenue/AddingServices.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import CustomerOrder from '@/components/revenue/invoice/CustomerOrder.vue';
import { eventBus } from '@/eventbus';
import Header from '@/components/revenue/invoice/Header.vue';
import FormModal from '@/components/base/FormModal.vue';
import SvgInvoiceCreation from '@/assets/icons/nav-bar/invoice-creation.svg';
import SaveBtnTooltip from '@/components/base/buttons/SaveBtnTooltip.vue';
import { getCurrencyIcon } from '@/utils/translate';

import {
    saveInvoice,
    getOneAdvertiser,
    getOneInvoice,
    getInvoiceOtherServices,
} from '@/api/revenue';
import { getRandomId, showServerError } from '@/utils';
import {
    ICustomerOrders,
    IInvoice,
    IContractor,
    IFinmediaData,
    IOtherService,
} from '@/api/types/revenue';
import { CHANGE_ROUTE_DEBOUNCE } from '@/configs/global';
import { USER_ROLES } from '@/mappings/user-roles';
import { NotificationMessage } from "@/utils/notification";

const revenue = namespace('revenueModule');
const auth = namespace('authModule');

@Component({
    components: {
        CustomerOrder,
        AddingServices,
        StatsCard,
        Header,
        FormModal,
        SvgInvoiceCreation,
        SaveBtnTooltip,
    },
})

export default class FinmediaInvoiceContent extends Vue {
    // геттеры для передачи условий о заполнености полей
    get invoiceEmptyConditions(): object {
        const {
            isEmptyAccountingServices, isEmptyAccountingOtherServices, isEmptyProject,
            isEmptyContactor, isEmptyCurrency,
        } = this;
        return {
            isEmptyAccountingServices,
            isEmptyAccountingOtherServices,
            isEmptyProject,
            isEmptyContactor,
            isEmptyCurrency,
        };
    }

    get isSaveInvoiceButtonDisabled(): boolean {
        if (this.invoice.customer_orders!.length > 0) {
            this.isEmptyAccountingServices = this.invoice.customer_orders!
                .some((item) => item.other_services!.length === 0);
        }

        return this.isEmptyAccountingServices
            || this.isEmptyFields
            || this.isEmptyCurrency
            || this.isEmptyProject
            || this.isEmptyContactor;
    }

    get isEmptyFields(): boolean {
        if (this.invoice.customer_orders!.length > 0) {
            this.invoice.customer_orders!.forEach((invoice) => {
                this.isEmptyAccountingOtherServices = invoice.other_services!.some((item) => !item.service_amount || !item.service_qty);
            });
        }

        return this.isEmptyAccountingOtherServices;
    }

    get isEmptyCurrency(): boolean {
        return !this.invoice.currency;
    }

    get isSupervisor(): boolean {
        return this.role === USER_ROLES.SUPERVISOR;
    }

    get isAdmin(): boolean {
        return this.role === USER_ROLES.ADMIN;
    }

    @Getter('GET_UNSAVED_DATA_STATE') getUnsavedDataState;
    @Getter('GET_ABORT_TRANSITION') getAbortTransition;
    @Getter('GET_ROUTE_TO_TRANSITION') getRouteToTransition;
    @Getter('GET_EDITING_MODE') editingMode;
    @Mutation('TOGGLE_EDITING_MODE') toggleEditingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;
    @Mutation('SET_NOTIFICATION') setNotification;
    @auth.Getter('GET_ROLE') role;
    @revenue.Mutation('SET_ADVERTISER_FIELDS') setAdvertiserFields;
    @revenue.Mutation('SET_ACCOUNT_MANAGER_NAME') setAccountManagerName;
    @revenue.Getter('GET_ADVERTISER_FIELDS') getAdvertiserFields;
    @revenue.Getter('GET_ACCOUNT_MANAGER_NAME') getAccountManagerName;
    @revenue.Getter('GET_ACCOUNTING_AMOUNT') getAccountingAmount;

    isEmptyAccountingServices = true;
    isEmptyAccountingOtherServices: boolean = true;
    showUnsavedChangesModal = false;
    showCantBeSavedModal = false;
    invoice: IInvoice = {
        offer_services: [],
        other_services: [],
        netting: [],
        customer_orders: [],
        currency: '',
    };
    finmediaData = {};
    finmediaProjects = [];
    finmediaServices = [];
    typeOfInvoice = 'new';
    // акаунт менеджер, найденный по ID
    accountManagerName: string | undefined = '';
    // рекламодатель, найденный по ID
    advertiserName = '';
    advertiserId: number | null = null;
    // был ли создан бухгалтерский счёт
    isAccountInvoiceWasCreated = false;
    isNewInvoice = false;
    invoiceProjects: string[] = [];
    advertiserContractors: IContractor[] = [];
    invoiceCurrency: string[] = [];
    isEmptyProject = true;
    isEmptyContactor = true;
    isFinmedia = true;
    finmediaInvoiceUrl = '/finmedia/invoice-creation';
    loading = false;
    // для сброса календаря выплат
    deletingPaymentCalendar: null | {
        item: IOtherService;
        index: number;
    } = null;
    modalDeletingCalendar = false;
    getCurrencyIcon = getCurrencyIcon;

    @Watch('invoice.customer_orders')
    findEmptySelect(): boolean {
        this.isEmptyProject = !this.invoice.customer_orders!
            .some((item) => item.project);
        if (this.invoice.customer_orders!.length > 0) {
            this.invoice.customer_orders!.forEach((invoice) => this.isEmptyContactor = !invoice!.contractor);
        }
        return this.isEmptyProject || this.isEmptyContactor;
    }

    @Watch('getAbortTransition')
    getAbortTransitionState(): void {
        if (this.getAbortTransition) {
            this.beforeRouteLive();
        }
    }

    get getFinmediaServices(): IOtherService[] | void {
        if (this.invoice.customer_orders![0]
            && this.invoice.customer_orders![0].project
            && this.invoice.customer_orders![0].project.key
        ) {
            this.finmediaServices = this.finmediaData[this.invoice.customer_orders![0].project.key].services;

            return this.finmediaServices;
        }
        if (this.invoice.customer_orders![0] && typeof this.invoice.customer_orders![0].project === 'string') {
            for (const [key] of Object.entries(this.finmediaData)) {
                if (this.invoice.customer_orders![0].project === this.finmediaData[key].name) {
                    this.finmediaServices = this.finmediaData[key].services;
                    return this.finmediaServices;
                }
            }
        }
    }

    beforeRouteLive(): void {
        if (this.getUnsavedDataState) {
            if (this.isSaveInvoiceButtonDisabled) {
                this.showCantBeSavedModal = true;
                return;
            }
            this.showUnsavedChangesModal = true;
        }
    }

    goToRouteWithoutSave(): void {
        this.showUnsavedChangesModal = false;
        this.setUnsavedDataState(false);

        this.$router.push({ name: this.getRouteToTransition });
    }

    async saveStatTaskFromModal(): Promise<void> {
        this.showUnsavedChangesModal = false;
        try {
            await this.saveInvoice();
            this.setUnsavedDataState(false);
            await this.$router.push({ name: this.getRouteToTransition });
        } catch (err) {
            showServerError(err, 'Ошибка при сохранении');
        }
        this.setUnsavedDataState(false);
    }

    stayOnCurrentPage(): void {
        this.showCantBeSavedModal = false;
        this.setAbortTransition(false);
    }

    setUnsavedData(state: boolean): void {
        if (this.typeOfInvoice === 'old') {
            this.setUnsavedDataState(state);
        }
    }

    async created(): Promise<void> {
        try {
            // Получаем список проектов, в каждом проекте находится список услуг
            const { data }= await getInvoiceOtherServices({ is_finmedia: true, is_active: true });
            this.finmediaData = data as IFinmediaData;
            for (const [key, value] of Object.entries(this.finmediaData)) {
                const projectItem = {
                    key,
                    // @ts-ignore
                    value: value.name,
                };
                // @ts-ignore
                this.finmediaProjects.push(projectItem);
            }
        } catch (err) {
            showServerError(err, 'Проекты не найдены');
        }

        // Создание счета
        if (this.$route.path === this.finmediaInvoiceUrl) {
            // Панель `для бухгалтерии` должна быть раскрыта
            this.addCustomerOrder();

            // Рекламодатель - всегда фиксированный ID 2201. Подставляем автоматически и не даём редактировать.
            const finmediaAdvertiserId = 2201;

            try {
                const advertiser = await getOneAdvertiser(finmediaAdvertiserId);
                this.invoice.advertiser_id = advertiser.id;
                this.advertiserContractors = advertiser.contractors!;
                if (advertiser.currency) {
                    this.invoiceCurrency = advertiser.currency;
                }
                if (advertiser.currency!.length === 1) {
                    this.invoice.currency = advertiser.currency![0];
                }
            } catch (err) {
                showServerError(err, 'Рекламодатель не найден');
            }
        }

        // Просмотр и редактирование счета
        if (this.$route.path !== '/finmedia/invoice-creation') {
            if (this.invoice.customer_orders!.length > 0) {
                this.isAccountInvoiceWasCreated = true;
            }

            this.typeOfInvoice = 'old';
            this.isAccountInvoiceWasCreated = true;

            // если открываем созданный счет
            const invoiceId = Number(this.$route.params.id);

            if (this.$route.params.id) {
                try {
                    this.invoice = await getOneInvoice(invoiceId);

                    // Если счёт имеет финансовый статус `Оплачен`, то редактировать его нельзя.
                    if (this.invoice.financial_status === 'paid' && !(this.isSupervisor || this.isAdmin)) {
                        this.toggleEditingMode(false);
                    }

                    this.invoiceCurrency = this.invoice.advertiser!.currency || [];
                    this.advertiserContractors = this.invoice.advertiser!.contractors! || [];

                    if (this.invoice && this.invoice.advertiser && this.invoice.advertiser.projects) {
                        this.invoiceProjects = this.invoice.advertiser.projects;
                    }

                    // При просмотре созданного счета, бек присылает некоторые ненужные поля в advertiser'e
                    // которые нужно удалить
                    const fieldsForDeletion = ['status', 'tag', 'invoicing_dates', 'legal_entity'];

                    fieldsForDeletion.forEach((fieldName) => delete this.invoice.advertiser![fieldName]);

                    if (this.invoice.advertiser) {
                        this.advertiserName = this.invoice.advertiser.name;
                        this.accountManagerName = this.invoice.advertiser.account_manager
                            ? this.invoice.advertiser.account_manager.full_name
                            : '';
                    }
                } catch (err) {
                    showServerError(err, 'Счет не найден');
                }
            }

            if (this.invoice.advertiser && this.invoice.advertiser.id) {
                this.advertiserId = this.invoice.advertiser.id;
            }
        }

        this.setUnsavedDataState(false);
    }

    mounted(): void {
        eventBus.$on('customer-order-amount-changed', this.customerOrderAmountChanged);
        eventBus.$on('calc-total-value-accounting', this.calcServiceTotalValueAccounting);

        eventBus.$on('add-other-service-accounting', this.checkForPaymentCalendars);
        eventBus.$on('delete-accounting-offer', this.deleteAccountingOffer);
    }

    beforeDestroy(): void {
        eventBus.$off('customer-order-amount-changed', this.customerOrderAmountChanged);

        eventBus.$off('add-other-service-accounting', this.checkForPaymentCalendars);
        eventBus.$off('delete-accounting-offer', this.deleteAccountingOffer);

        this.setAdvertiserFields({});
        this.setAccountManagerName('');
        this.toggleEditingMode(true);
    }

    changedSumOfInvoice(value: number, index: number): void {
        this.$set(this.invoice.customer_orders![index], 'sumOfInvoice', value);
    }

    addCustomerOrder(): void {
        const newAccountingItem: ICustomerOrders = {
            offer_services: [],
            other_services: [],
            payment_calendars: [],
            sumOfInvoice: 0,
            legal_entity: {},
        };
        this.isNewInvoice = true;

        this.isAccountInvoiceWasCreated = true;

        this.invoice.customer_orders!.push(newAccountingItem);
        this.invoice.customer_orders!.forEach((item) => {
            if (this.invoiceProjects!.length === 1) {
                item.project = this.invoiceProjects[0];
            }
            if (this.advertiserContractors!.length === 1) {
                item.contractor = this.advertiserContractors[0];
            } else {
                const contractor = this.advertiserContractors.find((v) => v.is_default);
                if (contractor) {
                    item.contractor = contractor;
                }
            }
        });
        this.setUnsavedData(true);
    }

    checkForPaymentCalendars(item: IOtherService, index: number): void {
        this.deletingPaymentCalendar = {
            item,
            index,
        };
        if (this.invoice.customer_orders![index].payment_calendars!.length > 0) {
            this.modalDeletingCalendar = true;
        } else {
            this.addAccountingOtherService();
        }
    }

    addAccountingOtherService(): void {
        const { item, index } = this.deletingPaymentCalendar!;
        if (this.invoice.customer_orders![index].payment_calendars!.length > 0) {
            this.invoice.customer_orders![index].payment_calendars! = [];
        }

        const newItem = { ...item };
        this.$set(newItem, 'id', getRandomId());
        this.$set(newItem, 'tempId', getRandomId());
        this.$set(newItem, 'service_qty', 0);
        this.$set(newItem, 'service_amount', 0);
        this.$set(newItem, 'total', 0);

        this.invoice.customer_orders![index].other_services!.push(newItem);

        this.setUnsavedData(true);
        this.deletingPaymentCalendar = null;
        this.modalDeletingCalendar = false;
    }

    // Посчитать `итого` прочей услуги в счете финмедиа
    calcServiceTotalValueAccounting(index: number): void {
        const currentItem = this.invoice.customer_orders![0].other_services![index];
        if (currentItem) {
            currentItem.total = (currentItem.service_amount && currentItem.service_qty)
                ? Number(currentItem.service_amount * currentItem.service_qty)
                : 0;
        }
    }

    customerOrderAmountChanged(): void {
        this.setUnsavedData(true);
    }

    removeExtraFields(): void {
        // Поля, которые будут удалены
        const fieldsForDeletionOnContractor = [
            'addresses',
            'contracts',
            'invoicing_dates',
            'address',
            'inn',
            'is_default',
        ];

        fieldsForDeletionOnContractor.forEach(
            (fieldName) => delete this.invoice.customer_orders![0].contractor![fieldName],
        );
    }

    showNotification(): void {
        const notificationText = this.invoice.id ? 'Счёт успешно отредактирован' : 'Счёт успешно создан';
        new NotificationMessage(notificationText, 'success' );
    }

    async saveInvoice(): Promise<void> {
        this.loading = true;
        // специальный флаг для бэка
        this.invoice.is_finmedia = true;

        if (this.invoice && this.invoice.customer_orders) {
            if (this.typeOfInvoice === 'new') {
                this.invoice.customer_orders[0].project = this.invoice.customer_orders[0].project!.value;
                this.invoice.customer_orders[0].legal_id = this.invoice.customer_orders[0].legal_entity!.id as number | null;
            }
        }

        for (const item of this.invoice.customer_orders!) {
            // С бэка приходит `legal_entity`, этот объъект ипользуем для отрисовки компонентов,
            // а отправлять мы должны только строку `legal_id`
            // делаем это только для уже созданного счета
            if (item.id && this.invoice.id) {
                item!.legal_id = item!.legal_entity ? item.legal_entity!.id as number : item!.legal_id;
            }

            // С бэка приходит массив `files` для каждого бух счета,
            // но отправлять мы должны только id файлов в массиве `fileIds`
            if (item!.files?.length) {
                item.file_ids = [];
                item!.files.forEach((file) => {
                    if (file.id) {
                        item!.file_ids!.push(file.id);
                    }
                });
                delete item.files;
            }
        }

        // Подготавливаем объект счета к отправке на сервер:
        // удаляем ненужные поля
        this.removeExtraFields();

        try {
            const result = await saveInvoice(this.invoice, this.invoice.id ? this.invoice.id : undefined);
            this.showNotification();

            if (result) {
                this.invoice = result;

                // Удаляем из стора данные, которые нужны были для создания счета
                this.setAdvertiserFields({});
                this.setAccountManagerName({});

                if (this.typeOfInvoice === 'new') {
                    this.typeOfInvoice = 'old';
                    setTimeout(() => {
                        this.$router.push({ name: 'finmedia-invoices' });
                    }, CHANGE_ROUTE_DEBOUNCE);
                }

                this.setUnsavedData(false);
            }
        } catch (err) {
            showServerError(err, 'Счет не сохранен.');
        }
        this.loading = false;
    }

    private deleteAccountingOffer(deletedItemIndex: number, name: string, accountIndex: number): void {
        const itemName = name === 'offersList' ? 'offer_services' : 'other_services';

        this.invoice.customer_orders![accountIndex][itemName]!.splice(deletedItemIndex, 1);

        if (this.invoice.customer_orders![accountIndex].payment_calendars!.length > 0) {
            this.invoice.customer_orders![accountIndex].payment_calendars! = [];
        }
    }
}
