
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Status from '@/components/base/Status.vue';
import { IOffer } from '@/api/types/uapi';
import { createOffer } from "@/api/uapi";
import { showServerError } from "@/utils";
import FormModal from "@/components/base/FormModal.vue";
import { translateUAPIOfferStatus } from "@/utils/translate";
import { IStatus } from "@/types";

@Component({
    components: {
        FormModal,
        Status,
    },
})
export default class Offer extends Vue {
    @Prop({ type: Object, required: true }) offer!: IOffer;
    showEditModal = false;
    editedItem: any = {
        id: '',
        name: '',
        status: '',
    };

    @Watch('offer')
    updateOffer(): void {
        this.editedItem.id = this.$props.offer.id;
        this.editedItem.name = this.$props.offer.offer_name;
        this.editedItem.status = this.status;
    }
    get status(): IStatus {
        return translateUAPIOfferStatus(this.offer.status);
    }
    async editItem(): Promise<void> {
        try {
            this.editedItem.status === 'enabled' ? this.editedItem.status = true : this.editedItem.status = false;
            await createOffer(this.editedItem);
            this.showEditModal = false;
            this.editedItem = {
                id: '',
                name: '',
                status: '',
            };
            this.$emit('update');
        } catch (err) {
            showServerError(err, 'Не удалось добавить оффер');
        }
    }
}
