
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import ColumnsCheckboxes from "@/components/statistics/ColumnsCheckboxes.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";

@Component({
    components: {
        TrashBtn,
        ColumnsCheckboxes,
    },
},
)
export default class ColumnsWrapDesign extends Vue {
    // выбранные чекбоксы
    @PropSync('selected', { required: true }) selectedSync!: any;
    @Prop({ default: () => [], required: true }) readonly checkboxes!: any[];
    @Prop({ default: true }) readonly isTitleCheckbox!: boolean;
    @Prop({ default: false }) dynamicColumns!: boolean;

    clearColumns(): void {
        this.selectedSync = this.checkboxes.flatMap((i) => i.items.map((j) => {
            if (!j.hasOwnProperty('default')) {
                return j.value;
            }
        }));
        this.$nextTick(() => {
            this.checkboxes.forEach((i) => {
                if (i.hasOwnProperty('value')) {
                    i.value = i.items.every(j => this.selectedSync.includes(j.value));
                }
            });
            this.updateUrl();
        });
        this.$emit('clear');
    }
    // обновляем урл согласно массиву selectedSync
    mounted(): void {
        this.$nextTick(() => {
            this.updateUrl();
        });
    }

    updateSelected(): void {
        this.updateUrl();
        this.$emit('close');
        if (this.dynamicColumns) this.$emit('submit');
    }

    // watch необходим, потому что в фильтрах обновляются параметры, и затирают columns
    @Watch('$route.query')
    updateUrl(): void {
        const data = { columns: [...this.selectedSync] };
        const query = Object.keys(this.$route.query)?.length > 0 ? { ...this.$route.query, ...data } : data ;
        this.$router.replace({ name: this.$router.currentRoute.name || '', query }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

}
