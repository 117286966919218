
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import EditIconBtn from "@/components/base/buttons/EditIconBtn.vue";
import SvgReload from "@/assets/icons/reload.svg";
import SvgPlus from "@/assets/icons/plus.svg";

@Component({
    components: {
        SvgPlus,
	    SvgReload, EditIconBtn, BaseCard,
    },
})
export default class OfferCard extends Vue {
    @Prop({ default: '' }) title!: string;
    @Prop({ default: '' }) comment!: string;
    @Prop({ default: false }) isEditDisabled!: boolean;
    @Prop({ default: true }) needActionBtn!: boolean;
    @Prop({ default: false }) needCreateBtn!: boolean;
    @Prop({ default: false }) isEditOnMounted!: boolean;

    editMode = false;

    mounted(): void {
        if (this.isEditOnMounted) this.editMode = true;
    }

    toggleEdit(): void {
        this.editMode = !this.editMode;
        this.$emit('toggle', this.editMode);
    }

    cancel(): void {
        this.editMode = false;
        this.$emit('cancel');
    }

    create(): void {
        this.$emit('create');
    }
}
