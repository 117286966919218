
import {
    Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import FormModal from '@/components/base/FormModal.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import StatTaskFiles from '@/components/revenue/statTask/StatTaskFiles.vue';
import StatTaskedStatisticList from '@/components/revenue/statTask/StatTaskedStatisticList.vue';
import Status from '@/components/base/Status.vue';
import { getOfferServices } from '@/api/revenue';
import {
    IFileComment, IFiles, IOfferService, IStatTasks,
} from '@/api/types/revenue';
import { getRandomId, showServerError } from '@/utils';
import { translateStatisticsStatus } from '@/utils/translate';
import Multiselect from '@/components/base/form/Multiselect.vue';
import { IStatus } from "@/types";

@Component({
    components: {
        Multiselect,
        StatTaskedStatisticList,
        StatsCard,
        Status,
        FormModal,
        StatTaskFiles,
    },
})

export default class StatTaskedStatistic extends Vue {
    @PropSync('stats', { required: true }) statsSync!: IStatTasks;

    isLoading = false;

    get assignee(): string {
        return `${this.statsSync.assignee_to?.first_name} ${
            this.statsSync.assignee_to?.last_name}`;
    }

    get statTaskId(): any {
        return this.statsSync?.id || null;
    }

    get isShowAssignee(): boolean {
        return !!(this.statsSync.assignee_to?.id);
    }

    get isAddStatTaskNotification(): boolean {
        return (!this.statTasksFiles || !this.statTasksFiles.length)
            && !this.editingMode;
    }

    get translateStatTaskStatus(): IStatus {
        return translateStatisticsStatus(this.statsSync.status!);
    }

    get itemsForSelect(): IOfferService[] {
        const statTaskOffers: IOfferService[] = this.statTasksFiles.flatMap(({ offer_services }) => offer_services);
        const result: IOfferService[] = [];
        if (statTaskOffers.length <= 0) {
            return this.offerServices.map((offer) => {
                offer.tempId = offer.tempId || getRandomId();
                return offer;
            });
        }
        this.offerServices.forEach((item) => {
            const isMatch = statTaskOffers
                .find((offer) => (item.tempId === offer.tempId));
            if (!isMatch) {
                result.push(item);
            }
        });
        return result;
    }

    @Getter('GET_EDITING_MODE') editingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    @Prop({ required: false }) readonly invoiceId!: number | null;
    @Prop({ required: true }) readonly offerServices!: IOfferService[];
    @Prop({ required: true }) readonly advertiserId!: number;
    @Prop({ required: true }) readonly period!: string;

    // validate for save or edit of StatTask
    isShowError = false;
    isShowMessage = '';
    statTasksFiles: IFiles[] = [];
    deletingFile: IFiles | null = null;
    // модалка-предупрждение при добавление одинаковых файлов к офферу
    showWarning = false;
    offerServiceSelect: IOfferService[] = [];

    setUnsavedData(state: boolean): void {
        const typeOfInvoice = this.$props.invoiceId ? 'old' : 'new';
        if (typeOfInvoice === 'old') {
            this.setUnsavedDataState(state);
        }
    }

    async created(): Promise<void> {
        this.statTasksFiles = this.statsSync?.files || [];
        await this.getOfferServices();
    }

    @Watch('period')
    async changePeriod(): Promise<void> {
        await this.getOfferServices();
    }

    onFileDelete(): void {
        this.isShowError = false;
        this.isShowMessage = '';
        const itemIndex = this.statTasksFiles.indexOf(this.deletingFile!);
        this.statsSync.files!.splice(itemIndex, 1);
        this.setUnsavedDataState(true);
        this.deletingFile = null;
    }

    addNewComment(currentIndex: string, fileComment: IFileComment): void {
        this.isShowError = false;
        this.isShowMessage = '';
        this.statsSync.files![currentIndex].comments.push(fileComment);
    }

    getOfferForCompare(offer: IOfferService): IOfferService {
        // до `...result` идет перечисление полей, которые нужно удалить
        const {
            report, total,
            status, goal_revenue_type, goal_revenue_percent,
            conversions_total, conversions_approved,
            ...result
        } = offer;
        return result;
    }

    // Получаем список `оффер+цель`
    async getOfferServices(): Promise<any> {
        try {
            this.offerServiceSelect = await getOfferServices(this.$props.advertiserId,
                { period: this.$props.period });
        } catch (err) {
            showServerError(err, 'Список оффер + цель не загружен');
        }
    }

    addFiles(files: IFiles[]): void {
        this.isLoading = true;
        files.forEach((file) => {
            for (const item of file.offer_services!) {
                if (item.hasOwnProperty('goal_revenue_flat')) {
                    item.offer_start_rate = item.goal_revenue_flat;
                } else if (item.hasOwnProperty('offer_start_rate')) {
                    break;
                } else {
                    // если мы редактируем счет, то начальная ставка оффер цели с бэка не приходит,
                    // поэтому делаем поиск  в таблице возможных офферов и берем от туда начальную ставку
                    item.offer_start_rate = this.offerServiceSelect.find(
                        (offer) => offer.goal_id === item.goal_id
                            && offer.offer_id === item.offer_id,
                    )?.goal_revenue_flat;
                }

                delete item.goal_revenue_type;
                delete item.goal_revenue_flat;
                delete item.goal_revenue_percent;
            }

            if (!this.statTasksFiles) {
                this.statTasksFiles = [];
            }
            this.statTasksFiles.push(file);
            const item =  {
                invoice_id: this.$props.invoiceId as number,
                period: this.$props.period as string,
                advertiser_id: this.$props.advertiserId as number,
                files: this.statTasksFiles,
            };
            this.statsSync = { ...this.statsSync, ...item };
        });
        this.isLoading = false;
        // отменяем сообщение об ошибке
        this.isShowError = false;
        this.isShowMessage = '';
        this.setUnsavedData(true);
    }
}
