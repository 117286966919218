export enum USER_ROLES {
    SUPERVISOR = 'ROLE_FINANCE_SUPERVISOR',
    AFFILIATE_MANAGER = 'ROLE_AFFILIATE_MANAGER',
    BUFFER_MANAGER = 'ROLE_BUFFER_MANAGER',
    FINANCIER = 'ROLE_FINANCIER',
    ACCOUNT_MANAGER = 'ROLE_ACCOUNT_MANAGER',
    STAT_MANAGER = 'ROLE_STAT_MANAGER',
    FINMEDIA = 'ROLE_FINMEDIA',
    ADMIN = 'ROLE_ADMIN',
    EMPLOYEE = 'ROLE_EMPLOYEE',
    API_OPERATOR = 'ROLE_API_OPERATOR',
    SUPPORT = 'ROLE_SUPPORT',
    ORGANIZATION_USER = 'ROLE_ORGANIZATION_USER',
    GENERATES_INVOICES = 'ROLE_GENERATES_INVOICES',
    SYSTEM_1C = 'ROLE_1C',
    SERVICE_PAYOUT = 'ROLE_SERVICE_PAYOUT',
    SERVICE_ISSUES_TOKENS = 'ROLE_SERVICE_ISSUES_TOKENS',
    SERVICE_OFFERS = 'ROLE_SERVICE_OFFERS',
    SERVICE_CONTEST = 'ROLE_SERVICE_CONTEST',
    NOTIFICATION_MANAGER = 'ROLE_NOTIFICATIONS_MANAGER'
}
