
import { Component, Vue } from 'vue-property-decorator';
import { getAffiliateLoginURL, getAffiliateSearch } from '@/api/user';
import { showServerError } from '@/utils';
import FormModal from "@/components/base/FormModal.vue";
import { namespace } from "vuex-class";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
const auth = namespace('authModule');

@Component({
    components: { BaseAutocomplete, FormModal },
})
export default class LoginAsPartner extends Vue {
    @auth.Getter('GET_LOGIN_AS_PARTNER') isLoginAsPartner;
    @auth.Mutation('SET_LOGIN_AS_PARTNER') setLoginAsPartner;
    affiliate: number | null = null;
    getAffiliateSearch = getAffiliateSearch;

    closeModal(): void {
        this.setLoginAsPartner(false);
    }

    updateAffiliates(item: any): void{
        this.affiliate = item;
    }

    async login(): Promise<void> {
        try {
            const { url } = await getAffiliateLoginURL(this.affiliate!);
            window.open(url, '_blank');
        } catch (err) {
            showServerError(err, 'Не удалось авторизоваться как партнёр');
        } finally {
            this.closeModal();
        }
    }

    beforeDestroy(): void {
        this.closeModal();
    }
}
