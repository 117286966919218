
import { Component, Vue, PropSync } from 'vue-property-decorator';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Editor from '@crm-ui/ckeditor';
import { ICreateOffer } from "@/api/types/offers";
import BaseCard from "@/components/base/BaseCard.vue";

@Component({
    components: {
        BaseCard,
        ckeditor: CKEditor.component,
    },
})
export default class CreateOfferDescription extends Vue {
    @PropSync('offer', { required: true }) offerSync!: ICreateOffer;

    editor = Editor;

    get config(): any {
        return {
            placeholder: 'Введите текст описания...',
            language: 'ru',
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'fontColor',
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'link',
                    'horizontalLine',
                ],
            },
            fontColor: {
                colors: [
                    {
                        color: 'hsl(0, 0%, 0%)',
                        label: 'Black',
                    },
                    {
                        color: 'hsl(118, 41%, 62%)',
                        label: 'Green',
                    },
                    {
                        color: 'hsl(349, 100%, 66%)',
                        label: 'Red',
                    },
                    {
                        color: 'hsl(209, 90%, 64%)',
                        label: 'Blue',
                    },
                ],
                documentColors: 0,
            },
        };
    }
}
