import { USER_ROLES } from "@/mappings/user-roles";
import TopOfCampaigns from "@/views/campaigns/TopOfCampaigns.vue";
import CashbackEcom from "@/views/campaigns/CashbackEcom.vue";
import CashbackEcomSingle from "@/views/campaigns/CashbackEcomSingle.vue";

export default [
    {
        path: 'top-of-campaigns',
        name: 'top-of-campaigns',
        meta: {
            title: 'Топ по акциям',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER,
            ],
        },
        component: TopOfCampaigns,
    },
    {
        path: 'cashback-ecom',
        name: 'cashback-ecom',
        meta: {
            title: 'Суперкэшбек на eCom',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER,
            ],
        },
        component: CashbackEcom,
    },
    {
        path: 'cashback-ecom/:id',
        name: 'cashback-ecom-detail',
        meta: {
            title: 'Детальная страница суперкэшбек на eCom',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ACCOUNT_MANAGER,
            ],
        },
        component: CashbackEcomSingle,
    },
];
