import { AxiosResponse } from 'axios';
import { axiosInstance } from '@/api/index';

import {
    IParamsObject,
    IServerResponse,
} from '@/api/types';

import { API_ORG_CABINETS_BACKEND_URL, API_REVENUE_URL } from '@/configs/global';
import { IServiceStatsDetail, IServiceStatsSummary } from '@/api/types/orgstats';

/**
 * Получение сумарной статистики организации в отчете по услугам
 */
export async function getServiceStatsSummary(
    params?: IParamsObject,
): Promise<IServiceStatsSummary[]> {
    return axiosInstance.request<IServerResponse<IServiceStatsSummary[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/service-summary`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IServiceStatsSummary[]>>) => response.data.data);
}

/**
 * Получение сумарной статистики организации в отчете по услугам
 */
export async function getServiceStatsDetail(
    params?: IParamsObject,
): Promise<IServerResponse<IServiceStatsDetail[]>> {
    return axiosInstance.request<IServerResponse<IServiceStatsDetail[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/services`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IServiceStatsDetail[]>>) => response.data);
}

/**
 *  Получение вебмастеров по гуиду
 */
export async function getLegalEntitySearchGuid(
    guids: [],
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/legal-entities/search`,
        params: {
            guids,
        },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Получение организаций по гуиду
 */
export async function getWebmastersSearchGuid(
    guids: [],
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/webmasters/search`,
        params: {
            guids,
        },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}
