
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IFilter } from "@/types";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import AutocompleteListItem from "@/components/base/form/AutocompleteListItem.vue";
import SvgArrow from "@/assets/icons/arrow-down.svg";

@Component({
    components: { SvgArrow, AutocompleteListItem, TrashBtn },
})
export default class SelectDesign extends Vue {
    @Prop({ required: true }) filter!: IFilter;
    @Prop({ default: () => ({}) }) item!: object;
    @Prop({ default: '' }) index!: string | number;
    @Prop({ default: '' }) attach!: string;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) isSmall!: boolean;
    @Prop({ default: false }) isNoChips!: boolean;

    selectItem(item: any, filter: any): string {
        return item.text ? item.text : item[filter.itemText] || item;
    }

    getNameItem(item: any, filter: any): string {
        return filter.translate ? filter.translate(this.selectItem(item, filter)) : this.selectItem(item, filter);
    }

    removeItem(filter: any): void {
        // пока что сделано для фильтров,
        // в которых можно выбрать только один вариант
        if ('remove' in filter) {
            filter.select = '';
            if (typeof filter.remove === "function") {
                filter.remove();
            }
        }
    }
}
