
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import NotificationMessage from "@/components/notifications/NotificationMessage.vue";
import NotificationDetailHeader from "@/components/notifications/NotificationDetailHeader.vue";
import SvgEdit from '@/assets/icons/pencil.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import EditBtn from "@/components/base/buttons/EditBtn.vue";

@Component({
    components: {
        EditBtn,
        TrashBtn,
        NotificationDetailHeader,
        NotificationMessage,
        BaseCard,
        SvgEdit,
    },
})
export default class ShowingNotification extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;

    async deleteNotification(): Promise<void> {
        await this.notification.deleteNotification();
        if (this.notification.is_deleted) {
            await this.$router.push({ name: 'notifications-list' });
        }
    }
}
