
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import FormTextField from "@/components/offers/offer/products/FormTextField.vue";
import FormSelect from "@/components/offers/offer/products/FormSelect.vue";
enum UNIT_TYPE {
    CURRENCY = 'currency',
    PERCENT = 'percent',
    TIME = 'time'
}
@Component({
    components: { FormTextField, FormSelect },
})

export default class FormRange extends Vue {
    @PropSync('product') productSync!: any;
    @Prop({ required: true }) isEdit!: boolean;
    @Prop({ required: true }) param!: any;

    getValue(item: any) : string {
        if (item.options) {
            const param = this.productSync.params[item.param];
            return `(${item.options[param]})`;
        } else {
            return `${item.name} ${this.productSync.params[item.param]}`;
        }
    }

    get unitTypeValue(): string {
        switch (this.param.unit_type) {
        case UNIT_TYPE.PERCENT:
            return '%';
        case UNIT_TYPE.CURRENCY:
            return this.productSync.currency;
        default:
            return '';
        }
    }

    get isShowUnitType(): boolean {
        return this.param.unit_type && (this.param.unit_type === UNIT_TYPE.PERCENT || this.param.unit_type === UNIT_TYPE.CURRENCY);
    }
}
