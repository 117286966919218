import { MutationTree } from 'vuex';
import { IStatisticsState } from './types';

export const mutations: MutationTree<IStatisticsState> = {
    SET_CONVERSIONS_REPORT_ID: (state, payload) => {
        state.conversionsReportId = payload;
    },
    SET_CONVERSIONS_REPORT_IN_PROGRESS: (state, payload) => {
        state.conversionsReportInProgress = payload;
    },
    SET_CONVERSIONS_REPORT_LOADING: (state, payload) => {
        state.conversionsReportLoading = payload;
    },
    SET_CONVERSIONS_REPORT_PROGRESS: (state, payload) => {
        state.conversionsReportProgress = payload;
    },
    SET_CONVERSIONS_REPORT_STATUS: (state, payload) => {
        state.conversionsReportStatus = payload;
    },
    SET_CONVERSIONS_REPORT_SSE: (state, payload) => {
        state.conversionsReportSSE = payload;
    },
    RESET_CONVERSIONS_REPORT: (state) => {
        state.conversionsReportId = '';
        state.conversionsReportInProgress = false;
        state.conversionsReportLoading = false;
        state.conversionsReportProgress = 0;
        state.conversionsReportStatus = '';
    },
};
