
import { Component, Vue } from 'vue-property-decorator';
import TabsReport from "@/components/orgstats/TabsReport.vue";
import ReportContent from "@/components/orgstats/ReportContent.vue";
import {
    getOfferAgentReport,
} from '@/api/org-cabinets-backend';
@Component({
    components: {
        ReportContent,
        TabsReport,
    },
})
export default class OffersAgentReport extends Vue {
    getOfferAgentReport= getOfferAgentReport;
}
