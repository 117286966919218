
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { IEndpointItem, IStream } from '@/api/types/uapi';
import { getEndpoint } from '@/api/uapi';
import AppTable from '@/components/base/table/AppTable.vue';
import { showServerError } from '@/utils';

@Component({
    components: {
        AppTable,
    },
})
export default class StreamMain extends Vue {
    @Prop({ type: Object, required: true }) stream!: IStream;
    @Prop({ type: Boolean, default: true }) canEdit!: boolean;

    queryEndpoint;
    endpointTimerId = 0;
    endpointLoading = false;
    possibleEndpoints: IEndpointItem[] = [];
    streamId = null;

    get maskName(): {} {
        const arr: string[] = [];
        arr.length = 150;
        return {
            mask: arr.fill('F').join(''),
            tokens: {
                F: {
                    pattern: /[0-9a-zA-Zа-яА-Я]/,
                },
            },
        };
    }

    get maskLimit(): {} {
        const arr: string[] = [];
        arr.length = 8;
        return {
            mask: arr.fill('A').join(''),
            tokens: {
                A: {
                    pattern: /[0-9]+/,
                },
            },
        };
    }
    get maskSubID(): {} {
        const arr: string[] = [];
        arr.length = 15;
        return {
            mask: arr.fill('B').join(''),
            tokens: {
                B: {
                    pattern: /[a-z-_.:0-9]/,
                },
            },
        };
    }

    @Watch('stream.endpoints')
    getStreamEndpoints(endpoints: IEndpointItem[]): void {
        if (!endpoints) {
            return;
        }
        endpoints!.forEach(async ({ endpoint }) => {
            if (endpoint) {
                this.queryEndpoint = endpoint;
                await this.getStreamUrlEndpoint();
            }
        });
    }

    created(): void {
        this.queryEndpoint = '';
        this.debounceGetStreamUrlEndpoint();
    }

    debounceGetStreamUrlEndpoint(): void {
        clearTimeout(this.endpointTimerId);
        if (this.queryEndpoint) {
            this.endpointTimerId = window.setTimeout(() => {
                this.getStreamUrlEndpoint();
            }, 800);
        }
    }

    async getStreamUrlEndpoint(): Promise<void> {
        this.endpointLoading = true;
        try {
            const { data } = await getEndpoint({ query: this.queryEndpoint });
            data.forEach(({ id, endpoint }) => {
                this.possibleEndpoints.push({
                    id,
                    endpoint,
                });
            });
        } catch (err) {
            showServerError(err);
        }
        this.endpointLoading = false;
    }
}
