
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMasterAccount } from '@/api/types/org-cabinets-backend';

@Component({
    components: {},
})

export default class MasterAccountHeader extends Vue {
    @Prop({ default: () => ({}) }) masterAccount!: IMasterAccount;
}
