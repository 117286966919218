import { Module } from 'vuex';

import { actions } from '@/store/modules/auth/actions';
import { getters } from '@/store/modules/auth/getters';
import { mutations } from '@/store/modules/auth/mutations';

import { IAuthState } from '@/store/modules/auth/types';
import { IRootState } from '@/store/types';
import { IUser } from '@/api/types/user';

export const state: IAuthState = {
    status: '',
    password: '',
    email: '',
    user: {} as IUser,
    is_2fa_enabled: false,
    is_login_as_partner: false,
};

const namespaced = true;

export const authModule: Module<IAuthState, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
