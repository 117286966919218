
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IMessage } from '@/api/types/support';
import { SUPPORT_SIGNATURE } from "@/configs/global";

@Component
export default class Message extends Vue {
    @Prop({ type: Object, default: () => ({}) }) message!: IMessage;
    @Prop({ required: false, default: '' }) title!: string;
    @Prop({ default: 'ru' }) language!: 'ru' | 'en';

    get text(): string {
        return this.$props.message.message.replace(/\n/g, '<br />');
    }

    get signature(): string {
        // у партнера нет подписи
        if (this.message.user_type === 'affiliate') return '';
        // если подписи нет или от имени поддержки, берем дефолтную от имени поддержки
        if (!this.message.signature || !this.message.has_personal_signature) {
            return this.language === 'ru' ? SUPPORT_SIGNATURE.text_ru : SUPPORT_SIGNATURE.text_en;
        } else {
            return this.language === 'ru' ? this.message.signature?.text_ru : this.message.signature?.text_en;
        }
    }

}
