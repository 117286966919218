import { IFilter } from "@/types";
import { getUserSearch } from "@/api/user";
import { translateAdvertiserStatus } from "@/utils/translate";
import { CreatedFilters } from "@/services/filters/filters";
import { filterClass } from '@/services/filters/filterClass';
import { getAdvertisersSearch } from "@/api/revenue";
import store from "@/store";
import { IUser } from "@/api/types/user";
import { USER_ROLES } from "@/mappings/user-roles";

export class AdvertisersFilters extends CreatedFilters {
    constructor() {
        super();
        if (this.isSetUser) this.setUser();
    }

    get isAccountManager(): boolean {
        const user: IUser = store.getters['authModule/GET_USER'];
        return user?.roles?.includes(USER_ROLES.ACCOUNT_MANAGER);
    }

    get isSetUser(): boolean {
        return this.isAccountManager && (this.lengthQueryFilters <= 0 );
    }

    getFilters(): IFilter[] {
        return  [
            new filterClass.Select({
                id: 'status',
                label: 'Статус',
                items: ['active', 'deleted', 'blocked', 'rejected'],
                select: 'active',
                defaultValue: 'active',
                placeholder: '',
                translate: (status: string) => translateAdvertiserStatus(status).text,
            }),
            new filterClass.Autocomplete({
                id: 'account_manager_id',
                apiMethod: getUserSearch,
                label: 'Аккаунт-менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: '',
                items: [],
                remove: true,
                multiple: false,
            }),
            new filterClass.Autocomplete({
                id: 'advertiser_id',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                select: '',
                items: [],
                remove: true,
                multiple: false,
            }),
        ];
    }

    setUser(): void {
        const user: IUser = store.getters['authModule/GET_USER'];
        const filter =  this.filters.find(f=> f.id === 'account_manager_id');
        if (!filter) return;

        const value = {
            id: user.account_id,
            name: `${user.account_id} ${user.first_name} ${user.last_name}`,
        };

        filter!.select = value;
        filter!.items!.push(value);
    }

    clearFilters(): void {
        const user: IUser = store.getters['authModule/GET_USER'];
        if (this.isAccountManager) {
            store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', { account_manager_id: user.account_id });
        }
        super.clearFilters();
        if (this.isAccountManager) this.setUser();
    }

    setDefaultFilters(): any {
        if (store.state.isWithoutDefaultFilters) {
            this.preventSetDefaultFilters();
            return;
        }
        const query = this.getQueryFilters();
        const activeFilters: object[] = [];
        this.filters.forEach(filter => {
            if (filter.select) {
                if (filter.id === 'account_manager_id' && Object.keys(filter.select).length > 0) {
                    activeFilters.push({ [filter.id]: filter.select[filter.itemValue!] });
                } else if (filter.select.length > 0) {
                    activeFilters.push({ [filter.id]: filter.select });
                }
            }

            if (Object.keys(query).length > 0 && filter.select)  {
                filter.select = Array.isArray(filter.select) ? [] : '';
            }
        });

        const paramsObj = Object.assign({}, ...activeFilters);
        store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', paramsObj);

        if (Object.keys(query).length <= 0) {
            store.commit('SET_FILTERS_PARAMS_OBJECT', paramsObj);
        }
    }
}
