
import { Component, Vue, Prop } from 'vue-property-decorator';
import Loader from "@/components/base/Loader.vue";
import { IUser } from "@/api/types/user";
import UserCardMain from "@/components/administration/users/UserCardMain.vue";
import UserCardMessengers from "@/components/administration/users/UserCardMessengers.vue";
import UserCardImage from "@/components/administration/users/UserCardImage.vue";
import UserCardRoles from "@/components/administration/users/UserCardRoles.vue";
import { USER_ROLES } from "@/mappings/user-roles";

@Component({
    components: {
        UserCardRoles,
        UserCardImage,
        UserCardMessengers,
        UserCardMain,
        Loader,
    },
})
export default class UserMain extends Vue {
    @Prop({ required: true }) user!: IUser;
    @Prop({ required: true }) userRoles!: object;
    isLoading: boolean = false;

    get isVisible(): boolean {
        return this.user.roles.some((role: any) => role === USER_ROLES.EMPLOYEE);
    }

    get isUserEmpty(): boolean {
        return Object.keys(this.user)?.length <= 0;
    }
}
