
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import SvgCancelBtn from '@/assets/icons/form-modal/cancel-btn.svg';
import SvgDeleteBtn from '@/assets/icons/form-modal/delete-btn.svg';
import SvgSuccessBtn from '@/assets/icons/form-modal/success-btn.svg';
import SvgTrash from '@/assets/icons/trash-dark.svg';
import SvgMagick from '@/assets/icons/magic-wand-light.svg';

@Component({
    components: {
        SvgCancelBtn,
        SvgDeleteBtn,
        SvgSuccessBtn,
        SvgTrash,
        SvgMagick,
    },
})
export default class FormModal extends Vue {
    @Prop({ required: false, default: true }) showModal!: boolean;
    @Prop({ required: false, default: false }) readonly isDisabled!: boolean;
    @Prop({ required: false, default: false }) readonly isCancelBtn!: boolean;
    @Prop({ required: false, default: '' }) readonly title!: string;
    @Prop({ required: false, default: 'Сохранить' }) readonly nameBtnSave!: string;
    @Prop({ required: false, default: 'Отмена' }) readonly nameBtnDecline!: string;
    @Prop({ required: false, default: 'Сбросить' }) readonly nameBtnCancel!: string;
    @Prop({ required: false, default: '' }) readonly value!: string;
    @Prop({ type: Boolean, default: true }) needHideBtn!: boolean;
    @Prop({ type: Boolean, default: true }) needAcceptBtn!: boolean;
    @Prop({ type: Boolean, default: false }) needCancelBtn!: boolean;
    @Prop({ type: Boolean, default: false }) noNeedsTitle!: boolean;
    @Prop({ type: String, default: '500' }) maxWidth!: string;
    @Prop({ type: String, default: '90%' }) maxHeight!: string;
    @Prop({ required: false, default: true }) persistent!: boolean;
    @Prop({ required: false, default: false }) closable!: boolean;
    @Prop({ type: String, default: '' }) customClass!: string;
    @Prop({ default: false }) tooltip!: string;
    @Prop({ type: Boolean, default: true }) isNeedAlreadyClick!: boolean;
    @Prop({ type: Boolean, default: false }) isSuccessAsDelete!: boolean;
    @Prop({ type: Boolean, default: false }) isSuccessAsCreate!: boolean;

    alreadyClick = false;

    get isSaveBtnDisabled(): boolean {
        return !this.$props.value && this.$props.isDisabled;
    }

    changeShowModal(e: any): void {
        if (!e) {
            this.$emit('close-modal');
            this.$forceUpdate();
        }
    }

    @Watch('showModal')
    addFocus(): void {
        if (!this.isNeedAlreadyClick) return;
        if (this.showModal) {
            this.alreadyClick = false;
            if (this.$refs.activeButton) {
                setTimeout(() => ((this.$refs.activeButton as Vue)?.$el as SVGElement).focus(), 0);
            }
        } else {
            this.$emit('close-modal');
            this.$forceUpdate();
        }
    }
}
