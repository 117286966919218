
import {
    Component, Vue, Prop, PropSync,
} from 'vue-property-decorator';
import { PAGINATION_RIGHT_PER_PAGE } from '@/configs/global';

@Component

export default class PaginationRight extends Vue {
    @Prop({ type: Number, default: PAGINATION_RIGHT_PER_PAGE }) itemsPerPage!: number;
    @Prop({ type: Number, default: 0 }) totalItemsOfTable!: number;
    @Prop({ type: Number, default: PAGINATION_RIGHT_PER_PAGE }) pageCount!: number;
    @Prop({ type: Array, required: true }) itemsPerPageSelect!: object[];
    @PropSync('pagination', { type: Object }) paginationSync!: {page: number};

    get isShowSelect(): boolean {
        return this.itemsPerPageSelect.length > 0;
    }
}
