
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { getOfferRules, editOfferSettings } from '@/api/uapi';
import { IOfferSettings } from '@/api/types/uapi';
import { showServerError } from '@/utils';

@Component
export default class Terms extends Vue {
    @Mutation('SET_NOTIFICATION') setNotification;

    @Prop({ type: Number, required: true }) offerId!: number;

    rules: IOfferSettings = {};
    headers = ['Min', 'Max'];
    check: boolean = false;

    get isEmptyFields(): boolean {
        const tempArray = { ...this.rules };
        delete tempArray['duplicate'];
        const values = Object.values(tempArray);
        return values.some((value) => !value);
    }

    isCheckDuplicate(): void {
        this.check = !(this.rules.hasOwnProperty('duplicate')? this.rules.duplicate === null : true);
    }

    created(): void {
        this.getOfferRules();
    }

    async getOfferRules(): Promise<void> {
        try {
            const { data } = await getOfferRules(this.offerId);
            this.rules = data;
            this.isCheckDuplicate();
        } catch (err) {
            showServerError(err);
        }
    }

    async editOfferSettings(): Promise<void> {
        try {
            let duplicateParam  =  { is_check_duplicate: this.check };
            if (this.check) {
                duplicateParam = { ...duplicateParam, ...{ period: Number(this.rules.duplicate) } };
            }
            this.rules = { ...this.rules, ...duplicateParam };
            const { data } = await editOfferSettings(this.offerId, this.rules);
            this.rules = data;
            this.showNotification('Изменения сохранены');
        } catch (err) {
            showServerError(err);
        }
    }

    showNotification(text: string): void {
        this.setNotification({ text });
        setTimeout(() => {
            this.setNotification({ text: '' });
        }, 2300);
    }
}
