
import { Vue, Component, PropSync, Watch } from "vue-property-decorator";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import MaskedTextField from "@/components/base/form/MaskedTextField.vue";

@Component({
    components: { MaskedTextField, BaseSelect },
})

export default class CookiesSelect extends Vue {
    @PropSync('value', { required: true }) valueSync!: number;

    currentValue = 24;
    items = [
        { text: '1 день', id: 24 },
        { text: '1 неделя', id: 168 },
        { text: '2 недели', id: 336 },
        { text: '1 месяц', id: 672 },
        { text: '2 месяца', id: 1344 },
        { text: '3 месяца', id: 2016 },
        { text: '6 месяцев', id: 4032 },
        { text: '1 год', id: 8064 },
        { text: 'Пользовательский', id: 0 },
    ];

    select(val: number): void {
        this.currentValue = val;
        this.valueSync = val;
    }

    mounted(): void {
        setTimeout(() => {
            const item = this.items.find(i => i.id === this.valueSync);
            this.currentValue = item ? this.valueSync : 0;
        }, 1000);
    }

    @Watch('valueSync')
    replaceValue(): void {
        this.valueSync > 17119 && (this.valueSync = 17119);
    }
}

