
import { Component, Vue } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { IColumnFilter } from "@/types";
import BufferAccountsTable from "@/components/affiliate/buffer-accounts/BufferAccountsTable.vue";
import { getBufferAccounts } from "@/api/offers";
import { BufferAccountsColumns, BufferAccountsFilters } from "@/services/TablePage/bufferAccounts";
import FormCreateBufferAccount from "@/components/affiliate/buffer-accounts/FormCreateBufferAccount.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import { namespace } from "vuex-class";
const auth = namespace('authModule');
@Component({
    components: {
        TablePageWrapper,
    },
})

export default class BufferAccounts extends Vue {
    @auth.Getter('GET_USER') user;
    columns: IColumnFilter[] = new BufferAccountsColumns().columns;
    filters = new BufferAccountsFilters();
    table = {
        component: BufferAccountsTable,
        apiMethod: getBufferAccounts,
    };
    createPersonalRateForm = {
        component: FormCreateBufferAccount,
    };
    get showModal(): boolean {
        return this.user.roles.includes(USER_ROLES.ADMIN) ||
            this.user.roles.includes(USER_ROLES.BUFFER_MANAGER);
    }
    mounted(): void {
        this.filters.getAccounts();
    }
}

