
import { Vue, Component } from "vue-property-decorator";
import { getEmployeesProducts } from "@/api/offers";
import AdministrationOffersLayout from "@/components/layout/AdministrationOffersLayout.vue";

@Component({
    methods: { getEmployeesProducts },
    components: { AdministrationOffersLayout },
})

export default class Products extends Vue {}
