
import { Component, Vue, Prop } from 'vue-property-decorator';
import { translateOffersStatus, translatePersonalRateApproved } from '@/utils/translate';
import Status from '@/components/base/Status.vue';
import { IPersonalRates } from '@/api/types/offers';
import BaseCard from "@/components/base/BaseCard.vue";
import { approvePersonalRate } from '@/api/offers';
import SvgCheckBtn from '@/assets/icons/check-btn.svg';
import { showServerError } from "@/utils";
import { IStatus } from "@/types";

interface IPersonalRateHeader {
    col: number,
    name: string;
    value?: any;
    status?: string | boolean;
    supValue?: string;
    isApproved?: boolean;
    isCorrect?: boolean;
    route?: {
        name: string,
        param: string | number,
    }
}

@Component({
    components: {
        BaseCard,
        Status,
        SvgCheckBtn,
    },
})

export default class PersonalRateHeader extends Vue {
    @Prop( { required: true }) personalRate!: IPersonalRates;

    get getHeadersPersonalRate(): IPersonalRateHeader[] {
        return [
            {
                col: 1,
                name: 'Партнёр',
                value: this.mapFields(this.personalRate.affiliate, 'legacy_id'),
                route: {
                    name: 'affiliate',
                    param: this.personalRate.affiliate.legacy_id!,
                },
            },
            {
                col: 1,
                name: 'Буфер',
                value:  this.personalRate.affiliate.buffer_account_id ? `${this.personalRate.affiliate.buffer_account_id} ${this.personalRate.affiliate.buffer_account_name}` : 'Не добавлен',
                route: {
                    name: 'affiliate',
                    param: this.personalRate.affiliate.buffer_account_id,
                },
            },
            {
                col: 1,
                name: 'Оффер',
                value: this.mapFields(this.personalRate.offer, 'legacy_id'),
                status: this.personalRate.offer.status,
                route: {
                    name: 'offer',
                    param: this.personalRate.offer.legacy_id!,
                },
            },
            {
                col: 1,
                name: 'Цель',
                value: this.mapFields(this.personalRate.goal, 'legacy_id'),
                route: {
                    name: 'offer',
                    param: this.personalRate.offer.legacy_id!,
                },
            },
            { col: 2, name: 'Менеджер партнёра', value: this.personalRate.affiliate.manager.name },
            { col: 2,  name: 'Менеджер рекламодателя', value: this.personalRate.offer.advertiser.manager.name },
            { col: 2, name: 'Корректность', isApproved: this.isApproved, isCorrect: this.isCorrect },
        ];
    }

    get isApproved(): boolean {
        return this.personalRate.personal_rates.is_approved;
    }

    get isCorrect(): boolean {
        return this.personalRate.personal_rates.is_correct;
    }

    translateStatus(status: string | boolean): IStatus {
        status = status.toString();
        return translateOffersStatus(status);
    }

    translateRateApproved(isApproved: boolean | undefined, isCorrect: boolean | undefined): IStatus {
        return translatePersonalRateApproved(!!isApproved, !!isCorrect);
    }

    mapFields(item: any, key?: string): string {
        return (key ? item[key] : item.id) + ' ' + item.name;
    }

    async approvePersonalRate(): Promise<void> {
        try {
            await approvePersonalRate([this.personalRate.personal_rates.id]);
            this.$set(this.personalRate.personal_rates, 'is_approved', true);
        } catch (err) {
            showServerError(err, 'Персональная цена не подтверждена');
        }
    }
}
