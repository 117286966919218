
import { Component, Vue } from 'vue-property-decorator';
import StatTaskContent from '@/components/revenue/statTask/StatTaskContent.vue';

@Component({
    components: { StatTaskContent },
})

export default class StatTaskCreation extends Vue {
}
