
import { Component, PropSync, Vue } from 'vue-property-decorator';
import { getAffiliateSearch } from '@/api/user';
import FormModal from '@/components/base/FormModal.vue';
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import { showServerError } from '@/utils';
import Loader from '@/components/base/Loader.vue';
import { postCheckingAffiliates } from '@/api/antifraud';
import { IBaseAutocomplete } from "@/types";

@Component({
    components: {
        BaseAutocomplete, FormModal, Loader,
    },
})
export default class FormCheckingAffiliate extends Vue {
    @PropSync('showModal') showModalSync!: boolean;

    getAffiliateSearch = getAffiliateSearch;
    checkingAffiliateId: number | null = null;
    loading = false;

    closeModal(): void {
        this.showModalSync = false;
    }

    get getSelectData(): IBaseAutocomplete {
        return {
            errorText: 'Партнер не найден',
            apiMethod: getAffiliateSearch,
            itemValue: 'id',
            template: (item) => item.first_name + ' ' + item.last_name + ' ' + item.id,
            placeholder: 'Введите ID или имя партнёра',
        };
    }

    changeAffiliateId(id: number): void {
        this.checkingAffiliateId = id;
    }

    async postCheckingAffiliates(): Promise<void> {
        this.loading = true;
        try {
            await postCheckingAffiliates({ ids: [this.checkingAffiliateId!] });
            this.$emit('edit');
            this.showModalSync = false;
        } catch (e) {
            showServerError(e, 'Партнер не добавлен');
        } finally {
            this.loading = false;
            this.closeModal();
        }
    }

}
