
import { Component, Vue, Prop } from 'vue-property-decorator';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import SvgFileImage from "@/assets/icons/file-image.svg";
import SvgFileImageDelete from "@/assets/icons/file-image-delete.svg";
import SvgClip from "@/assets/icons/clip.svg";
import SvgFileDownload from "@/assets/icons/file-download.svg";
import SvgReload from "@/assets/icons/reload-double.svg";
import ImageInput from "@/components/base/form/ImageInput.vue";
import ImagePreview from "@/components/base/ImagePreview.vue";

@Component({
    components: {
        ImageNotesFile, ImageInput, TrashBtn, SvgFileImageDelete,
        SvgClip, SvgFileImage, SvgFileDownload, SvgReload, ImagePreview,
    },
})
export default class ImageNotesFile extends Vue {
    @Prop({ required: true }) image: any;
    @Prop({ default: false }) isEdit!: boolean;
    @Prop({ default: true }) isPreview!: boolean;

    updateImage(file: any): void {
        this.$emit('change', file);
    }

    deleteImage(): void {
        this.$emit('change', '');
    }

    changeImageFromPreview(image: any): void {
        this.$emit('change', image);
    }
}
