
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import EmployeeMethodAboutItem from "@/components/administration/employeesMethods/EmployeeMethodAboutItem.vue";
import { EmployeeMethod, IAboutItem, IEditableAboutItem } from "@/services/administration/EmployeeMethod";

@Component({
    components: { EmployeeMethodAboutItem, BaseCard },
})
export default class EmployeeMethodAbout extends Vue {
    @Prop({ required: true }) employeeMethod!: EmployeeMethod;
    @Ref() aboutItems!: EmployeeMethodAboutItem[];

    isEdit: boolean = false;
    dataToSend = {};

    get getItems(): IAboutItem[] | IEditableAboutItem[] {
        return this.isEdit ? this.employeeMethod.getAboutItemsForEdit : this.employeeMethod.getAboutItems;
    }

    editMode(isEdit: boolean): void {
        if (isEdit) {

        } else {
            this.dataToSend = Object.fromEntries(this.aboutItems.map(item => [item.item.id, item.value]));
            this.employeeMethod.updateEmployeeMethod(this.dataToSend);
        }
        this.isEdit = isEdit;
    }

}
