import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import { Vue } from "vue-property-decorator";

const socket = io(window.location.origin, {
    path: '/api/socket.io',
    transports: ['websocket'],
});

Vue.use(VueSocketIOExt, socket);
