
import { Vue, Component } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { getEmployeeMethods } from "@/api/payout";
import EmployeeMethodsTable from "@/components/administration/employeesMethods/EmployeeMethodsTable.vue";
import FormCreateEmployeeMethod from "@/components/administration/employeesMethods/FormCreateEmployeeMethod.vue";
import { EmployeesMethodsFilters } from "@/services/TablePage/employeesMethods";

@Component({
    components: { TablePageWrapper },
})

export default class List extends Vue {
    filters = new EmployeesMethodsFilters();
    table = {
        component: EmployeeMethodsTable,
        apiMethod: getEmployeeMethods,
    };
    createMethodForm = {
        component: FormCreateEmployeeMethod,
    };

}
