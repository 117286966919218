
import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgQuestion from "@/assets/icons/question-mark.svg";
import SvgExclamation from "@/assets/icons/exclamation.svg";

@Component({
    components: {
        SvgQuestion,
        SvgExclamation,
    },
})
export default class QuestionMarkTooltip extends Vue {
    @Prop({ default: '' }) tooltip?: string;
    @Prop({ default: 'top' }) offset?: 'top' | 'bottom' | 'left' | 'right';
    @Prop({ default: 'right' }) mobileOffset?: 'top' | 'bottom' | 'left' | 'right';
    @Prop({ default: '370' }) constantWidth?: string;
    @Prop({ default: false }) isExclamation?: boolean;
    @Prop({ default: 'blue' }) color?: 'blue' | 'black';

    isShow: boolean = false;
    scrollingPage: any;

    get getIcon(): any {
        return this.isExclamation ? SvgExclamation : SvgQuestion;
    }

    get getContentClass(): string {
        if (this.color === 'black') return `base-tooltip base-tooltip--${this.getOffset}`;
        return `base-tooltip --${this.color} --${this.color}--${this.getOffset}`;
    }

    get getOffset(): 'top' | 'bottom' | 'left' | 'right' {
        return this.$vuetify.breakpoint.width < 1264 ? this.mobileOffset! : this.offset!;
    }

    mounted(): void {
        this.scrollingPage = document.querySelector('.page-card');
        this.scrollingPage?.addEventListener('scroll', this.closeTooltip);
    }

    destroyed(): void {
        this.scrollingPage.removeEventListener('scroll', this.closeTooltip);
    }

    closeTooltip(): void {
        this.isShow && (this.isShow = false);
    }

}
