
import { Component, Vue } from 'vue-property-decorator';
import { addEndpoint, getEndpointById } from '@/api/uapi';
import StatsCard from '@/components/base/StatsCard.vue';
import MainEndpoint from '@/components/uapi/endpoint/Main.vue';
import ConditionsEndpoint from '@/components/uapi/endpoint/Conditions.vue';
import StreamsEndpoint from '@/components/uapi/endpoint/Streams.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import { showServerError } from '@/utils';
import { IEndpointItem } from '@/api/types/uapi';

@Component({
    components: {
        StatsCard,
        TooltipButton,
        MainEndpoint,
        ConditionsEndpoint,
        StreamsEndpoint,
    },
})
export default class Endpoint extends Vue {
    title = '';
    endpoint: IEndpointItem = {
        endpoint: '',
    };
    panels = [0, 1];

    get canEdit(): boolean {
        return !this.$route.query.version;
    }

    created(): void {
        if (this.$route.params.endpointId) {
            this.getEndpointById();
        } else {
            this.title = 'Создание кампании';
        }
    }

    async getEndpointById(): Promise<void> {
        try {
            const { version } = this.$route.query;
            const { data } = await getEndpointById(this.$route.params.endpointId, { version });
            this.endpoint = data;
            this.title = `Endpoint ${this.endpoint.id}`;
            if (version) {
                this.title += ` <span class="grey--text text--darken-2">(ver. ${version})</span>`;
            }
        } catch (err) {
            showServerError(err, 'Не удалось получить кампанию');
        }
    }

    async saveEndpoint(): Promise<void> {
        try {
            const { data } = await addEndpoint({ endpoint: this.endpoint.endpoint });
            this.endpoint = data;
            await this.$router.push({ name: 'edit-endpoint', params: { endpointId: String(data.id!) } });
        } catch (err) {
            showServerError(err, 'Не удалось сохранить кампанию');
        }
    }
}
