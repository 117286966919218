
import { Component, Vue } from 'vue-property-decorator';
import TabsReport from "@/components/orgstats/TabsReport.vue";

@Component({
    components: {
        TabsReport,
    },
})
export default class AffiliateReport extends Vue {
    tabs: { id: string, name: string, to: string }[] = [
        {
            id: '1c-invoice',
            name: 'Счёт из 1С',
            to: '/orgstats/offer-report/1c-invoice',
        },
        {
            id: 'agent-reports',
            name: 'Отчёт агента',
            to: '/orgstats/offer-report/agent-report',
        },
    ];
}
