import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';

export class MultiSelectFilter extends BaseFilter {
    multiselect? = true;
    itemValue?: string;
    itemText?: string | ((item: any) => string);
    template?: (item: any) => any;
    noDataText?: string;
    type?: 'number' | 'string';

    constructor(config: ConstructorType<MultiSelectFilter, 'multiselect'>) {
        super(config);
        this.select = Array.isArray(config.select)
            ? config.select
            : config.select
                ? [config.select]
                : [];
        this.items = config.items ?? [];
        this.itemValue = config.itemValue;
        this.itemText = config.itemText;
        this.template = config.template;
        this.noDataText = config.noDataText;
        this.type = config.type;
        this.defaultValue = config.defaultValue;
    }
}
