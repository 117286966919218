
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { IChangesHistoryItem } from "@/api/types/offers";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import { eventBus } from "@/eventbus";
import { OFFER_PRODUCT_PARAMS } from "@/mappings/offer";

@Component({
    components: { SvgArrow },
})

export default class OfferChangeHistoryItem extends Vue {
    @Prop({ required: true }) item!: IChangesHistoryItem;
    @Prop({ required: true }) field!: 'old_value' | 'new_value';

    isShowMenu: boolean = false;

    get isShowDropdown(): boolean {
        const fields = [ 'params', 'description', 'all', ...Object.keys(OFFER_PRODUCT_PARAMS)];
        return fields.includes(this.item.field) && this.item[this.field] !== '';
    }

    get isShowParamsList(): boolean {
        const fields = ['params', 'all', ...Object.keys(OFFER_PRODUCT_PARAMS)];
        return fields.includes(this.item.field) && this.item[this.field] !== '';
    }

    get showImg(): boolean {
        return this.item.field.includes('logo');
    }

    get getWidth(): string {
        return this.$vuetify.breakpoint.width <= 769 ? '100vw' : '700px';
    }
    get getHeight(): string {
        return this.$vuetify.breakpoint.width <= 769 ? '100vh' : '550px';
    }

    get getPreviewText(): string {
        if (!this.isShowDropdown) return '';
        const text = 'Раскрыть';
        return text.replace(/(<([^>]+)>)/gi, '').slice(0, 35) + '...';
    }

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(newVal: any, oldVal: any): void {
        if (oldVal && newVal.name === 'offer' && oldVal.name === 'offer' && newVal.params.id !== oldVal.params.id) {
            eventBus.$emit("update-offer");
            eventBus.$emit("change-tab", 0, true);
        }
    }
}
