
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getEmployeesOffersV2 } from "@/api/offers";
import { getAdvertisersSearch } from "@/api/revenue";
import { IOffer, IOfferSearch } from "@/api/types/offers";
import { showServerError } from "@/utils";
import BaseCard from "@/components/base/BaseCard.vue";
import { eventBus } from "@/eventbus";
import { IBaseAutocomplete } from "@/types";

@Component({
    components: {
        BaseCard,
        BaseAutocomplete,
    },
})

export default class NotificationOfferRecipient extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;

    advertiserOffers: IOffer[] = [];
    selectedOffers: IOffer[] = [];

    offerData: IBaseAutocomplete = {
        errorText: 'Оффер не найден',
        itemValue: 'legacy_id',
        template: (item: IOfferSearch): string => item.legacy_id + ' ' + item.name,
        label: 'Введите ID или название оффера',
        clearable: true,
        maxShowItems: 6,
        apiMethod: getEmployeesOffersV2,
        key: 'search',
        multiple: true,
        trashTitle: 'Сбросить',
        cashItems: false,
        multiPaste: true,
    };

    setOffers(e: IOffer[] ): void {
        this.selectedOffers = [...e];
    }

    clearOffersSelect(): void {
        this.selectedOffers = [];
        this.advertiserOffers = [];
        eventBus.$emit('clear');
    }

    addOffers(): void {
        this.notification.addOffers(this.selectedOffers, this.advertiserOffers);
        this.clearOffersSelect();
    }

    getAdvertisersSearch = getAdvertisersSearch;

    async getOffers(date: number) : Promise<void> {
        this.notification.advertiser = date;
        this.advertiserOffers = [];
        if (date) {
            try {
                const { data } = await getEmployeesOffersV2({ limit: 500, advertiser_legacy_id: [date], status: 'active' });
                this.advertiserOffers = [...data];
            } catch (err) {
                showServerError(err, 'Офферы не загружены');
            }
        }
    }
}
