
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import OfferGoals from "@/components/offers/offer/OfferGoals.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import EditIconBtn from "@/components/base/buttons/EditIconBtn.vue";
import { eventBus } from "@/eventbus";

@Component({
    components: { EditIconBtn, BaseCard, OfferGoals },
})
export default class OfferCardGoals extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    edit(): void {
        eventBus.$emit('edit-goal');
    }
}
