
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import { Affiliate } from "@/services/affiliates/Affiliate";
import { namespace } from "vuex-class";
import { getFormatDate } from "@/utils/formatDates";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import SvgCheck from "@/assets/icons/check-btn.svg";
import SvgReload from "@/assets/icons/reload-double.svg";
import SvgCreate from "@/assets/icons/magic-wand.svg";
import SvgRedirect from "@/assets/icons/logout.svg";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import FormMethod from "@/components/affiliate/affiliateMethods/FormMethod.vue";
import { sortBy } from "lodash-es";

const catalogue = namespace('catalogueModule');

interface IAction {
    value: any,
    items: any,
    select: Function,
    width?: number
    placeholder?: string,
    isAutocomplete?: boolean,
    isDisabled?: boolean,
}

@Component({
    components: { FormMethod, BaseSelect, SvgReload, SvgCheck, SvgArrow, SvgRedirect, SvgCreate },
})

export default class TitleActions extends Vue {
    @Prop({ required: true }) affiliate!: Affiliate;
    @Prop({ default: false }) isNeedReload!: boolean;
    @Prop({ default: false }) isBalance!: boolean;
    @Prop({ default: false }) isStatistic!: boolean;
    @Prop({ default: false }) isPayments!: boolean;
    @Prop({ default: false }) isDisabledSelect!: boolean;
    @PropSync('isLoading',{ default: false }) isLoadingSync!: boolean;

    @catalogue.Getter('GET_CURRENCIES') getCurrencies;

    selectedAffiliate: number | null | string = null;
    selectedCurrency: string = 'RUB';
    selectedStatusFilter: string = 'all';
    selectedCabinetFilter: boolean | null = null;
    isShowForm: boolean = false;

    mounted(): void {
        setTimeout(() => this.setMasterAcc(), 500);
    }

    setMasterAcc(): void {
        if (this.affiliate.is_master) {
            this.selectedAffiliate = 'as_master';
        }
    }

    get getActions():  IAction[] | [] {
        if (this.isBalance) {
            return this.getBalanceActions;
        } else if (this.isStatistic) {
            return this.getStatisticActions;
        } else if (this.isPayments) {
            return this.getPaymentsActions;
        } else {
            return [];
        }
    }

    get getBalanceActions(): IAction[] {
        return [
            {
                value: this.getAffiliateValue,
                items: this.getAffiliateItems,
                select: this.selectAffiliate,
                isAutocomplete: true,
                width: 280,
                isDisabled: this.isDisabledSelect,
            },
        ];
    }

    get getAffiliateItems(): {id: number | string, name: string}[] {
        const group = this.affiliate.master_group! || [];
        if (this.affiliate.is_master && this.affiliate.master_group?.length! > 1 ) {
            const sortedGroup = sortBy(group, (i) => { return i.id  !== this.affiliate.id; } );
            return [{ id: 'as_master', name: 'Все аккаунты' }, ...sortedGroup ];
        } else return group;
    }

    get getAffiliateValue(): number | string {
        return this.selectedAffiliate || this.affiliate.id!;
    }

    get getStatisticActions(): IAction[] {
        return [
            {
                value: this.getAffiliateValue,
                items: this.getAffiliateItems,
                select: this.selectAffiliate,
                isAutocomplete: true,
                isDisabled: this.isDisabledSelect,
                width: 280,
            },
            {
                value: this.selectedCurrency,
                items: this.getCurrencyItems,
                select: this.selectCurrency,
                width: 90,
            },
        ];
    }

    get getCurrencyItems(): {id: string, name: string}[] {
        if (!Array.isArray(this.affiliate.currencyList)) return [];
        return this.affiliate.currencyList.map(c => ({ id: c, name: c }));
    }

    get getPaymentsActions(): IAction[] {
        return [
            {
                value: this.selectedStatusFilter,
                items: this.getStatusFilterItems,
                select: this.selectStatusFilter,
                width: 120,
                placeholder: 'Статус',
            },
            {
                value: this.selectedCabinetFilter,
                items: this.getCabinetFilterItems,
                select: this.selectCabinetFilter,
                width: 100,
                placeholder: 'Кабинет',
            },
        ];
    }

    get getStatusFilter(): string[] {
        if (this.selectedStatusFilter === 'active') {
            return ['approved'];
        } else if (this.selectedStatusFilter === 'inactive') {
            return ['new', 'manager_rejected', 'partner_rejected', 'partner_edited', 'deleted'];
        } else {
            return [];
        }
    }

    get getStatusFilterItems(): {id: string, name: string}[] {
        return [{ id: 'active', name: 'Aктивные' }, { id: 'inactive', name: 'Неактивные' }, { id: 'all', name: 'Все' }];
    }

    get getCabinetFilterItems(): {id: boolean | null, name: string}[] {
        return [{ id: true, name: 'Кабинет' }, { id: false, name: 'Другие' }, { id: null, name: 'Все' }];
    }

    get getPaymentsFilters(): {statuses: string[], is_cabinet: boolean | null} {
        return { statuses: this.getStatusFilter, is_cabinet: this.selectedCabinetFilter };
    }

    selectAffiliate(id: number | string): void {
        this.selectedAffiliate = id;
        const isMaster = id === 'as_master';
        if (this.isBalance) {
            this.getBalance(isMaster);
        } else if (this.isStatistic) {
            this.getStatistic(isMaster);
        }
    }

    selectCurrency(cur: string): void {
        this.selectedCurrency = cur;
        this.selectedAffiliate === 'as_master' ? this.getStatistic(true) : this.getStatistic();
    }

    selectStatusFilter(value: string): void {
        this.selectedStatusFilter = value;
        this.$emit('change-status', this.getStatusFilter);
        this.getPayments();
    }

    selectCabinetFilter(value: boolean | null): void {
        this.selectedCabinetFilter = value;
        this.$emit('change-cabinet', value);
        this.getPayments();
    }

    async getBalance(as_master: boolean = false): Promise<void> {
        this.isLoadingSync = true;
        const affiliate = as_master ? this.affiliate.id! : this.getAffiliateValue;
        await this.affiliate.getBalance(affiliate as number, as_master);
        this.isLoadingSync = false;
    }

    async getStatistic(as_master: boolean = false): Promise<void> {
        this.isLoadingSync = true;
        const affiliate = as_master ? this.affiliate.master_id! : this.getAffiliateValue;
        await this.affiliate.getStatistic(affiliate as number, this.selectedCurrency, as_master ? 'dm_master_account' : 'dm_affiliate_id');
        this.isLoadingSync = false;
    }

    async getPayments(): Promise<void> {
        this.isLoadingSync = true;
        try {
            const { statuses, is_cabinet } =  this.getPaymentsFilters;
            await this.affiliate.getPaymentInfo(statuses, is_cabinet);
            this.isLoadingSync = false;
        } catch (e) {
            this.isLoadingSync = false;
        }
    }

    reloadTable(): void {
        const isMaster =  this.selectedAffiliate === 'as_master';
        if (this.isBalance) {
            this.getBalance(isMaster);
        } else if (this.isStatistic) {
            this.getStatistic(isMaster);
        }
    }

    statisticRedirect(): void {
        const columns = ['dm_offer_currency', 'dm_affiliate_name', 'dm_affiliate_id', 'mt_clicks_total', 'mt_clicks_unique', 'mt_leads_total', 'mt_leads_approved', 'mt_cr_u', 'mt_cr_t', 'mt_ar', 'mt_epc_u', 'mt_epc_t', 'mt_epl', 'mt_payout_approved', 'mt_revenue_approved', 'dm_lead_date'];
        const group = 'dm_lead_date';
        const sort_by = 'dm_lead_date';
        const date_from = getFormatDate(new Date(new Date().setDate(new Date().getDate() - 7)), 'yyyy-MM-dd');
        const date_to = getFormatDate(new Date(), 'yyyy-MM-dd');
        const affiliate_id = `${this.affiliate.id}`;
        const currency = this.selectedCurrency;
        const path = this.$router.resolve({
            name: 'extended-report',
            query: { columns, group, date_from, date_to, affiliate_id, currency, sort_by },
        });
        window.open(path.href, '_blank');
    }
}

