
import { Component, Mixins } from 'vue-property-decorator';
import TableMixin from '@/mixins/table';
import { getMasterAccounts } from '@/api/org-cabinets-backend';
import { showServerError } from '@/utils';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        SvgApplication,
    },
})
export default class MasterAccounts extends Mixins(TableMixin) {
    apiMethod = getMasterAccounts;
    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'master_account_ids',
            label: 'ID',
            select: [],
        }),
    ];
    headers = [
        { text: 'ID', value: 'id', align: 'center' },
    ];
    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'master-account',
            text: 'id',
        },
    ];
    settings = {
        title: 'Мастер-аккаунты',
        loading: false,
    };
    paginationItemsPerPage = PAGINATION_PER_PAGE;

    mounted(): void {
        this.getMasterAccountsPagination();
    }

    async getMasterAccountsPagination(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Список способов выплат не загружен');
        }
    }
}
