
import { Vue, Component, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PageLayout from "@/components/layout/PageLayout.vue";
import { ICreatePayment } from "@/api/types/payout";
import CreatePaymentHeader from "@/components/affiliate/payments/createPayment/CreatePaymentHeader.vue";
import CreatePaymentNearestTable
    from "@/components/affiliate/payments/createPayment/CreatePaymentNearestTable.vue";
import CreatePaymentsBody from "@/components/affiliate/payments/createPayment/CreatePaymentsBody.vue";
import SvgMagicWand from '@/assets/icons/magic-wand-light.svg';
import { postPayment } from "@/api/payout";
import { showServerError } from "@/utils";
import { NotificationMessage } from "@/utils/notification";

const auth = namespace('authModule');
@Component({
    beforeRouteEnter(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        next(vm => {
            const ref = vm.$refs.header;
            ref.defaultAffiliateValue = from.params!.id!;
        });
    },
    components: { CreatePaymentsBody, CreatePaymentNearestTable, CreatePaymentHeader, PageLayout, SvgMagicWand },
})

export default class CreatePayment extends Vue {
    @Ref() header!: CreatePaymentHeader;
    @auth.Getter('GET_USER') user;

    loadingBtn: boolean = false;
    payment: ICreatePayment = {
        period: '',
        comment: '',
    };
    isRequisiteFully: boolean = false;

    get isShowBodyPayment(): boolean {
        return !!(this.payment.payment_type && this.payment.affiliate_id && this.payment.receiver_id);
    }

    get isCanSave(): boolean {
        if ( ['paid_sum', 'currency', 'period', 'payment_method', 'requisite'].every(i => this.payment.hasOwnProperty(i))) {
            return !!this.payment.paid_sum && !!this.payment.currency && !!this.payment.period && !!this.payment.payment_method &&
                this.isRequisiteFully && !this.isExcesSum;
        } else {
            return false;
        }
    }

    get isExcesSum(): boolean {
        if (this.payment.payment_type === 'invoice_payment') {
            return this.payment.hasOwnProperty('paid_sum') && this.payment.hasOwnProperty('available_sum') ?
                this.payment.paid_sum! > this.payment.available_sum! : false;
        }
        return false;
    }

    async savePayment(): Promise<void> {
        this.loadingBtn = true;
        try {
            this.$set(this.payment, 'manager_id', this.user.account_id);
            await postPayment(this.payment);
            new NotificationMessage('Досрочка создана', 'success' );
            const receiver_id = this.payment.receiver_id;
            const affiliate_id = this.payment.affiliate_id;
            this.payment = {
                period: '',
                comment: '',
                receiver_id,
                affiliate_id,
            } as ICreatePayment;
            this.header.clearPaymentType();
        } catch (err) {
            showServerError(err, 'Досрочка не создана');
        }
        this.loadingBtn = false;
    }
}
