
import { Mixins, Component } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { IFileIntegrations } from '@/api/types/uapi';
import { getFileIntegrations } from '@/api/uapi';
import Filters from '@/components/base/filters/Filters.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { showServerError } from '@/utils';
import TableMixin from "@/mixins/table";
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        Filters,
        SvgApplication,
    },
})
export default class FileIntegrations extends Mixins(TableMixin) {
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamObject;

    apiMethod = getFileIntegrations;
    dataTable: IFileIntegrations[] = [];
    totalElementDataTable = 0;
    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'affiliate_id',
            label: 'ID партнера',
            select: [],
            supValue: '',
        }),
        new filterClass.Multiselect({
            id: 'status',
            items: ['unprocessed', 'processed'],
            type: 'string',
            label: 'Статус',
            select: [],
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Дата создания',
            pickerType: 'date',
            select: [],
            range: true,
        }),
    ];

    headers = [
        {
            text: 'ID партнера',
            value: 'affiliate_id',
            sortable: false,
        },
        {
            text: 'Статус',
            value: 'status',
            sortable: false,
        },
        {
            text: 'Всего заявок',
            value: 'total',
            sortable: false,
        },
        {
            text: 'Заявки с ошибками',
            value: 'failed',
            sortable: false,
        },
        {
            text: 'Дубли заявок',
            value: 'duplicate',
            sortable: false,
        },
        {
            text: 'Дата создания',
            value: 'created_at',
        },
    ];


    statuses = [
        {
            slot: 'item.status',
            key: 'status',
        },
    ];

    settings = {
        title: 'Файловые интеграции',
        loading: false,
    };

    created(): void {
        const activeFilters = {};
        this.filters.forEach((fl) => {
            if (fl.select) {
                if (fl.datepicker) {
                    activeFilters[`${fl.id}_from`] = fl.select[0];
                    activeFilters[`${fl.id}_to`] = fl.select[0];
                }
            }

        });
        const activeParams = JSON.parse(JSON.stringify({ ...activeFilters }));
        this.setFiltersParamObject(activeParams);
    }

    mounted(): void {
        this.getFileIntegrations();
    }

    async getFileIntegrations(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Список файловых интеграций не загружен');
        }
    }
}
