import { CreatedFilters } from "@/services/filters/filters";
import { IFilter } from "@/types";
import { filterClass } from "@/services/filters/filterClass";
import { translateOtherServiceStatus } from "@/utils/translate";
import { getActiveUserSearch } from "@/api/user";

export class OtherServicesFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Select({
                id: 'is_active',
                label: 'Статус',
                itemValue: 'value',
                itemText: 'name',
                items: ['true', 'false'],
                select: '',
                translate: (i) => translateOtherServiceStatus(i).text,
            }),
            new filterClass.Autocomplete({
                id: 'created_by_account_id',
                apiMethod: getActiveUserSearch,
                label: 'Кем создано',
                itemValue: 'id',
                itemText: 'name',
                template: (i) => i.name || `${i.id} ${i.first_name} ${i.last_name}`,
                select: '',
                items: [],
                multiple: false,
            }),
            new filterClass.Checkbox({
                id: 'is_finmedia',
                label: 'Финмедиа',
                select: false,
            }),
        ];
    }
}
