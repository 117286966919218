
import { Component, Vue } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import AffiliateTable from "@/components/affiliate/AffiliateTable.vue";
import { getEmployeesAffiliates } from "@/api/user";
import { downloadExcelFile, showServerError } from "@/utils";
import { Getter } from "vuex-class";
import { AffiliateColumns, AffiliateFilters } from "@/services/TablePage/affiliates";

@Component({
    components: { TablePageWrapper },
})

export default class AffiliatesList extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParamsObject;

    columns = new AffiliateColumns().columns;
    filters = new AffiliateFilters();

    table = {
        component: AffiliateTable,
        apiMethod: getEmployeesAffiliates,
        paramsModifier: this.paramsModifier,
    };

    downloadOptions = {
        loading: false,
        tooltip: 'Выберите хотя бы 1 фильтр для того, чтобы сформировать выгрузку',
        disabled: this.disabledDownload,
    };

    createForm = {
        route: 'create-affiliate',
    };

    get isMobile():boolean {
        return this.$vuetify.breakpoint.width < 680;
    }

    disabledDownload(): boolean {
        const storyFilters = { ...this.getDefaultFiltersParamsObject, ...this.getFiltersParamsObject };
        if (!storyFilters) return false;
        const filters = Object.values(storyFilters).every(i => !i);
        return filters && Object.keys(filters)?.length <= 0;
    }

    async getExcel(): Promise<void> {
        this.downloadOptions.loading = true;
        try {
            const params = this.paramsModifier(this.getFiltersParamsObject);
            const file = await getEmployeesAffiliates(params, 'xls');
            downloadExcelFile(file, `Партнёры`, 'xls');
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
        this.downloadOptions.loading = false;
    }

    paramsModifier(params: any): any {
        const result = { ...params };
        if (this.$route.query.hasOwnProperty('columns')) {
            const fields = Array.isArray(this.$route.query.columns) ?
                [...this.$route.query.columns] as string[] :
                [this.$route.query.columns];
            result.fields = fields.filter(i => !!i);
        }
        // если выбрана хотя бы одна колонка с utm, шлем в запрос поле utms(в ответе будут все ютмс)
        if (result.hasOwnProperty('fields') && result.fields.some(i => i.includes('utms'))) {
            const fields = result.fields.filter(i => !i.includes('utms'));
            fields.push('utms');
            result.fields = fields;
        }
        // Обработка master_id
        if (result.hasOwnProperty('fields') && result.fields.some(i => i.includes('master_id'))) {
            const fields = result.fields.filter(i => !i.includes('id'));
            fields.push('id');
            fields.push('user_id');
            fields.push('master_id');
            result.fields = fields;
        }
        // Обработка master_account_name
        if (result.hasOwnProperty('fields') && result.fields.some(i => i.includes('master_account_name'))) {
            const fields = result.fields.filter(i => !i.includes('full_name'));
            fields.push('full_name');
            fields.push('manager.full_name');
            result.fields = fields;
        }
        return result;
    }
}
