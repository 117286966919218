import Vue from 'vue';
import Component from 'vue-class-component';
import { ICondition } from "@/api/types/uapi";
import { getConditionById } from "@/api/uapi";
import { showServerError } from "@/utils";
import omit from "lodash-es/omit";

@Component({
})
export default class StreamCondition extends Vue {
    panel = true;
    condition: ICondition = {
        field: '',
        type: '',
        value: '',
    };

    get title(): string {
        return this.condition.id ? 'Редактирование условия' : 'Создание условия';
    }

    async created(): Promise<void> {
        if (this.$route.params.conditionId) {
            await this.getCondition();
        }
    }

    getConditionsToSend(): ICondition {
        const streamCondition: any = { ...omit(this.condition, 'field_name') };
        if (streamCondition.type === 'geo') {
            streamCondition.value_object.city_id = streamCondition.value_object.city_id.map((item) => item.id || item);
            streamCondition.value_object.region_id = streamCondition.value_object.region_id.map((item) => item.id || item);
            streamCondition.value_object.country_id = streamCondition.value_object.country_id.map((item) => item.id || item);
        }
        return streamCondition;
    }
    async getCondition(): Promise<void> {
        const { streamId, conditionId } = this.$route.params;
        try {
            const { data } = await getConditionById(streamId, conditionId);
            this.condition = data;
        } catch (err) {
            showServerError(err, 'Не удалось получить кампанию по ID');
        }
    }
}
