
import { Component, Mixins } from "vue-property-decorator";
import FiltersMixin from "@/mixins/filters";
import TablePageFilters from "@/components/base/filters/TablePageFilters.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { IFilter } from "@/types";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";

@Component({
    components: { BaseAutocomplete, BaseSelect, TablePageFilters },
})

export default class OtherServicesFiltersComponent extends Mixins(FiltersMixin) {

    getStatusItems(filter: IFilter): {text: string, id: boolean}[] {
        if (!filter.items) return [];
        return filter.items?.map(value => ({ text: filter.translate!(value), id: value }));
    }

    selectFilter(filter: IFilter, value: any): void {
        filter.select = value;
        this.submit();
    }
}

