
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';

@Component({
    components: {},
})

export default class ErrorModal extends Vue {
    isAdBlock = false;
    isModalShow = false;

    get error(): {} {
        return store.getters.GET_SERVER_ERROR;
    }

    created(): void {
        this.isAdBlock = !window.noAdBlock;
        this.isModalShow = Object.keys(this.error).length > 0;
        document.addEventListener('keydown', this.handleKeydown as EventListener);
    }

    handleKeydown(e: KeyboardEvent): void {
        if ((e.key === 'Enter') || (e.key === 'Escape')) {
            this.closeModal();
        }
    }

    closeModal(): void {
        this.isModalShow = false;
        store.commit('SET_SERVER_ERROR', {});
    }

    beforeDestroy(): void {
        document.removeEventListener('keydown', this.handleKeydown);
    }
}
