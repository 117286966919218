
import { Vue, Component, Prop } from "vue-property-decorator";
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";
import { eventBus } from "@/eventbus";
import OfferStatisticChart from "@/components/offers/offer/OfferStatisticChart.vue";
import { IOfferMetric } from "@/api/types/offers";

@Component({
    components: { OfferStatisticChart, FiltersDialog },
})

export default class OfferStatistic extends Vue {
    @Prop({ default:'.table-page' }) attachClass!: string;

    isShowDialog: boolean = false;
    offer: {id: number, name: string, metrics: IOfferMetric, isAPI: boolean} | {} = {};

    get getName(): string {
        return 'id' in this.offer ? `${this.offer.id} ${this.offer.name}` : '';
    }

    created(): void {
        eventBus.$on('show-offer-statistic', this.showOfferStatistic);
    }

    beforeDestroy(): void {
        eventBus.$off('show-offer-statistic', this.showOfferStatistic);
    }

    showOfferStatistic(offer: {id: number, name: string, metrics: IOfferMetric[], isAPI: boolean}): void {
        this.offer = offer;
        this.isShowDialog = true;
    }

    closeStatistic(): void {
        if (!this.isShowDialog) {
            this.offer = {};
        }
    }
}

