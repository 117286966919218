
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getFormatDate } from "@/utils/formatDates";
import { INotificationList } from "@/api/types/notifications";
import SvgPinFull from '@/assets/icons/pin-full.svg';
import Status from "@/components/base/Status.vue";

@Component({
    components: {
        Status,
        SvgPinFull,
    },
})

export default class TooltipBtnPinned extends Vue {
    @Prop({  default: '' }) attachClass!: string;
    @Prop({  default: '' }) title!: string;
    @Prop({ required: true }) notification!: INotificationList;

    getFormatDate = getFormatDate;

    get isStatus(): boolean {
        // Если дата начала уже наступила, а дата окончания ещё нет, то показываем статус
        if (this.notification.pinned_from) {
            const isStart = new Date(this.notification.pinned_from!) <= new Date();
            const isEnd = new Date(this.notification.pinned_to!) >= new Date();
            return !!(isStart && isEnd);
        } else return false;
    }
}
