import Vue from 'vue';
import { ResponseType } from 'axios';

import { Getter, Mutation } from 'vuex-class';
import { FilterDateParser } from "@/services/filters/dateParser";
import { IFilter } from "@/types";
import { Component } from "vue-property-decorator";
import { CreatedFilters } from "@/services/filters/filters";

export interface IExportFormat {
    format: string;
    headers: {
        Accept: string,
        lang?: string,
        'usernames-as-string'?: string,
    };
    responseType: ResponseType;
}

@Component({
    components: {},
})
export default class StatisticReportMixin extends Vue {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParamObject;

    // для экспорта отчета
    exportFormats: IExportFormat[] = [
        {
            format: 'xlsx',
            headers: { Accept: 'application/vnd.ms-excel' },
            responseType: 'blob',
        },
        {
            format: 'csv',
            headers: { Accept: 'text/csv' },
            responseType: 'text',
        },
    ];

    // изменение фильтров и их отправка с датапикера над таблицей который
    changeFiltersFromLayout(fls: IFilter[], date: any): void {
        let filters = { ...this.getFiltersParamObject };
        const filterDate = fls.find(i => i.id === 'date');
        const filterGroup = fls.find(i => i.id === 'group');
        if (date?.length > 0) {
            // @ts-ignore
            const paramDate = new FilterDateParser({ date })!.parse(filterDate);
            // проставляем в фильтры группировку
            const paramGroup = filterGroup ? { group: filterGroup.select } : {};
            const filterClass = new CreatedFilters(fls);
            const paramObj = filterClass.getActiveFiltersToSend();
            filters = { ...this.getFiltersParamObject, ...paramObj, ...paramDate, ...paramGroup };
            this.updateQueryString(filters);
        } else {
            // при сбросе, ставим дефолтную группировку и убираем чекбокс в колонке 'Дата'
            if (filterGroup) {
                this.$set(filterGroup, 'select', filterGroup.defaultValue);
                filterGroup.callback!(filterGroup);
            }
            delete filters.date_from;
            delete filters.date_to;
            const query = {
                ...filters,
                columns: this.$route.query.columns,
            };
            if (this.$route.query.sort_by) {
                query.sort_by = this.$route.query.sort_by;
            }
            if (this.$route.query.sort_desc !== undefined ) {
                query.sort_desc = this.$route.query.sort_desc;
            }
            this.$router.replace({ name: this.$router.currentRoute.name || '', query }).catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                    throw error;
                }
            });
        }
        this.setFiltersParamObject(filters);
    }

    updateQueryString(data: any): void {
        const query = { ...this.$route.query, ...data };
        this.$router.replace({
            ...this.$router.currentRoute,
            name: this.$router.currentRoute.name || '',
            query,
        }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

    // очищаем фильтры до дефолтных значений
    clearFilters(filters: IFilter[]): void {
        filters.map((filter) => {
            let group;
            if (filter.id === 'group') {
                group = this.$route.query.columns?.includes('dm_lead_date') ? filter.select : filter.defaultValue;
            }
            filter.clear();
            if (filter.id === 'group') {
                filter.select = group;
            }
        });
    }

    translateReportHeader(str: string, columns: Array<{ base: string, letters: string }>): any {
        for (const i of columns) {
            str = str.replace(i.letters, i.base);
        }
        return str;
    }
}
