
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})

export default class MultiButton extends Vue {
    @Prop({ type: String, default: '' }) icon!: string;
    @Prop({ type: String, default: 'primary' }) color!: string;
    @Prop({ type: String, default: '#000000' }) colorText!: string;
    @Prop({ type: String, default: '' }) direction!: string;
    @Prop({ type: String, default: '15px' }) sizeIcon!: string;
    @Prop({ type: Boolean, default: false }) disabled!: boolean;

    fab = false;
}
