
import {
    Component, Vue, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { INavigationItem } from '@/api/types/revenue';
import SvgPolygon from '@/assets/icons/polygon.svg';
import NavListItem from '@/components/layout/NavListItem.vue';

const auth = namespace('authModule');

@Component({
    name: 'nav-list',
    components: {
        NavListItem,
        SvgPolygon,
    },
})
export default class NavList extends Vue {
    @auth.Getter('GET_USER') user;
    @PropSync('service', { required: true }) serviceSync!: INavigationItem;
    @Prop({ type: Boolean, default: false }) subGroup!: boolean;
    @Prop({ type: Boolean, default: false }) mini!: boolean;
    @Prop({ type: Boolean, default: true }) isDesktop!: boolean;
    // нужно передавать serviceName только в самом первом родительском компоненте,
    // это нужно чтобы правильно отслеживать разделы
    // во вложенным пунктах меню
    @Prop({ type: String, default: '' }) serviceName!: string;

    navigationIconsColor: string = '#cfcfcf';

    showMenu = false;
    showTooltip = false;
    // проверяем если у всех вложенных serviceSync.value `show === true`
    // то не показываем целый пункт
    get isShowService(): boolean {
        const hiddenItems = this.serviceSync.value
            .filter((item: {show?: boolean}) => !item.show);
        return hiddenItems.length !== this.serviceSync.value.length;
    }

    get nudgeTop(): number {
        /* 33 - это высота в пикселях одного .v-list-item в меню */
        return (this.serviceSync.value.length * 33) / 2;
    }

    @Watch('$props.isDesktop')
    changeScreenWidth(): void {
        this.closeMenu();
    }

    @Watch('$props.isMini')
    changeMini(): void {
        this.closeMenu();
    }

    @Watch('showMenu')
    closeTooltip(value: boolean): void {
        if (value) {
            this.showTooltip = false;
        }
    }

    closeMenu(): void {
        this.showMenu = false;
    }

}
