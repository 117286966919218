
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import FormModal from "@/components/base/FormModal.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { IBaseAutocomplete, TableHeader } from "@/types";
import { Advertiser } from "@/services/advertiser/Advertiser";
import { IAffiliate } from "@/api/types/revenue";
import { eventBus } from "@/eventbus";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getAffiliateSearch } from "@/api/user";

@Component({
    components: {
        BaseAutocomplete,
        TableWithFrontPaginationDesign, FormModal, BaseCard },
})

export default class AdvertisersAffiliates extends Vue {
    @Prop({ required: true }) advertiser!: Advertiser;

    isShowModal: boolean = false;
    isShowOverwriteModal: boolean = false;
    isShowDeleteModal: boolean = false;
    isLoading: boolean = false;
    affiliateId: number | null = null;

    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'affiliate',
            text: 'id',
        },
    ];

    headers: TableHeader[]  = [
        { text: 'ID', sortable: false, value: 'id' },
        { text: 'Имя', sortable: false, value: 'name' },
        { text: 'Email', sortable: false, value: 'email' },
        { text: '', sortable: false, value: 'actions', align: 'center', width: '30px' },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: false,
            callback: (item: IAffiliate): void => {
                this.prepareDeleteForm(item.id);
            },
        },
    ];

    get getItems(): IAffiliate[] {
        return this.advertiser.affiliates || [];
    }

    get getAffiliateData(): IBaseAutocomplete {
        return {
            apiMethod: getAffiliateSearch,
            errorText: 'Партнер не найден',
            placeholder: 'Выберите партнёра',
            itemValue: 'id',
            template: (item) => `${item.id} ${item.first_name} ${item.last_name} ${item.email}`,
        };
    }

    closeModal(isOverwrite: boolean = false): void {
        this.isLoading = false;
        this.isShowModal = false;
        if (!isOverwrite) {
            this.affiliateId = null;
            eventBus.$emit('clear');
        }
        this.isShowOverwriteModal = isOverwrite;
        this.isShowDeleteModal = false;
    }

    changeAffiliate(id: number): void {
        this.affiliateId = id;
    }

    async addAffiliate(is_need_to_overwrite: boolean = false): Promise<void> {
        if (!this.affiliateId) {
            this.closeModal();
            return;
        }
        this.isLoading = true;
        const isOverwrite = await this.advertiser.addAffiliate(this.affiliateId, is_need_to_overwrite);
        this.closeModal(isOverwrite);
    }

    prepareDeleteForm(id: number): void {
        this.affiliateId = id;
        this.isShowDeleteModal = true;
    }

    async deleteAffiliate(): Promise<void> {
        if (!this.affiliateId) return;
        this.isLoading = true;
        await this.advertiser.deleteAffiliate(this.affiliateId);
        this.closeModal();
    }
}

