import { Module } from 'vuex';

import { getters } from '@/store/modules/payout/getters';
import { actions } from '@/store/modules/payout/actions';
import { mutations } from '@/store/modules/payout/mutations';

import { IPayoutState } from '@/store/modules/payout/types';
import { IRootState } from '@/store/types';

export const state: IPayoutState = {
    allAffiliatesMethods: [],
};

const namespaced = true;

export const payoutModule: Module<IPayoutState, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
