
import { Component, Vue } from 'vue-property-decorator';
@Component({
})

export default class MainContent extends Vue {
    get routerName(): string | void {
        return this.$route.name || '';
    }

    // Для принудительного ререндера детальной страницы партнёра при переходе по ссылке на другого партнёра
    get getKey(): string {
        return this.$route.name === 'affiliate' ? this.$route.params.id! : '';
    }
}
