
import { Vue, Component, PropSync } from "vue-property-decorator";
import FormModal from "@/components/base/FormModal.vue";
import { IOtherServiceCreate } from "@/api/types/revenue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import SvgCreate from "@/assets/icons/magic-wand-light.svg";
import Loader from "@/components/base/Loader.vue";
import { postInvoiceOtherServices } from "@/api/revenue";
import { showNotification, showServerError } from "@/utils";

@Component({
    components: { Loader, BaseSelect, FormModal, SvgCreate },
})

export default class CreateOtherService extends Vue {
    @PropSync('showModal') showModalSync!: boolean;

    service: IOtherServiceCreate = {
        name: '',
        is_active: true,
    };
    statusItems = [{ text: 'Активна', id: true }, { text: 'Не активна', id: false }];
    isLoading: boolean = false;

    clearInputs(): void {
        this.service.name = '';
        this.service.is_active = true;
    }

    closeModal(): void {
        this.clearInputs();
        this.showModalSync = false;
    }

    async saveOtherService(): Promise<void> {
        try {
            this.isLoading = true;
            await postInvoiceOtherServices(this.service);
            showNotification('Прочая услуга успешно создана');
            this.$emit('edit');
            this.closeModal();
        } catch (err) {
            showServerError(err, 'Прочая услуга не создана');
        } finally {
            this.isLoading = false;
        }
    }
}

