
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { ENV_TOGGLER } from '@/configs/global';
import { ENV_MODES } from '@/mappings/env-mode';

@Component({})
export default class EnvToggler extends Vue {
    @Getter('GET_ENV_MODE') getEnvMode;
    @Getter('IS_DEV_MODE') isDevMode;
    @Mutation('TOGGLE_ENV_MODE') toggleEnvMode;

    showEnvToggler = false;
    envMode = ENV_MODES.DEV;

    toggleMode(): void {
        this.envMode = this.envMode === ENV_MODES.DEV ? ENV_MODES.PROD : ENV_MODES.DEV;
        this.toggleEnvMode(this.envMode);
    }

    created(): void {
        if (ENV_TOGGLER) {
            this.envMode = this.getEnvMode;
            this.showEnvToggler = true;
        }
    }
}
