import { IAdvertiser, IAdvertiserManagers, IAffiliate, IContractor, IContractorAddress, ITag } from "@/api/types/revenue";
import { IUser } from "@/api/types/user";
import {
    addAdvertiserAddress,
    addAdvertiserManager,
    addContractor, currenciesChangeRequest,
    deleteAdvertiserManager,
    deleteContractor, deletePartnerAccounts,
    editAdvertiserAddress,
    getAdvertiserTags, patchAdvertisersChecker,
    patchAdvertisersTags,
    postPartnerAccounts, projectsChangeRequest,
    removeAdvertiserAddress,
    setDefaultContractor,
} from "@/api/revenue";
import { showNotification, showServerError } from "@/utils";
import { eventBus } from "@/eventbus";
import isEqual from "lodash-es/isEqual";
import { patchAdvertiserInfo } from "@/api/offers";

export interface IProject {
    abbr: string,
    text: string
}

export class Advertiser implements IAdvertiser {
    id: number;
    name: string;
    status: string;
    internal_id: number;
    is_checker_used?: boolean;
    tag: ITag;
    currency?: string[];
    projects?: string[];
    invoicing_dates?: number[] | null;
    managers: IAdvertiserManagers[];
    contractors?: IContractor[];
    account_manager: IUser | null;
    affiliates?: IAffiliate[] = [];
    allTags: ITag[] = [];

    constructor(advertiser: IAdvertiser) {
        this.id = advertiser.id;
        this.name = advertiser.name;
        this.status = advertiser.status;
        this.internal_id = advertiser.internal_id;
        this.is_checker_used = advertiser.is_checker_used;
        this.tag = advertiser.tag;
        this.currency = advertiser.currency;
        this.projects = advertiser.projects;
        this.invoicing_dates = advertiser.invoicing_dates;
        this.managers = advertiser.managers;
        this.contractors = advertiser.contractors;
        this.account_manager = advertiser.account_manager;
        this.affiliates = advertiser.affiliates;

        (async () => {
            await this.getTags();
        })();
    }

    async getTags(): Promise<void> {
        try {
            const { data } = await getAdvertiserTags();
            this.allTags = data;
        } catch (err) {
            showServerError(err, 'Список тегов не загружен');
        }
    }

    async editMainInfo(advertiserData: any): Promise<void> {
        const changedOptions: string[] = [];
        const apis = {
            tag: patchAdvertisersTags,
            projects: projectsChangeRequest,
            currency: currenciesChangeRequest,
            is_checker_used: patchAdvertisersChecker,
        };
        const mainInfoApi: {keys: string[], api: Function, data: any} = {
            keys: [ 'manager_id', 'name', 'status' ],
            api: patchAdvertiserInfo,
            data: {},
        };

        const isNotChanged = (key: string) => {
            if (key === 'tag') return advertiserData[key] === this[key].id;
            if (key === 'manager_id') {
                if (!this.managers || !this.account_manager) return !!advertiserData[key];
                return this.account_manager.id === advertiserData[key];
            }
            if (Array.isArray(advertiserData[key])) return isEqual(this[key], advertiserData[key]);
            return this[key] === advertiserData[key];
        };

        for (const key in advertiserData) {
            if (isNotChanged(key)) continue;
            if (mainInfoApi.keys.includes(key)) {
                mainInfoApi.data[key] = advertiserData[key];
                continue;
            }
            changedOptions.push(key);
        }

        if (changedOptions.length === 0 && !Object.keys(mainInfoApi.data).length) return;

        try {
            if (!!changedOptions.length)
                await Promise.all(changedOptions.map(key => apis[key](this.id, advertiserData[key])));
            if (!!Object.keys(mainInfoApi.data).length)
                await mainInfoApi.api(mainInfoApi.data, this.id);
            showNotification('Данные рекламодателя обновлены');
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, 'Не удалось обновить данные');
        }
    }

    async addManager(manager_id: string): Promise<void> {
        try {
            await addAdvertiserManager(this.id, { manager_id });
            showNotification('Менеджер успешно добавлен');
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, 'Не удалось добавить менеджера');
        }
    }

    async deleteManager(manager_id: number): Promise<void> {
        try {
            await deleteAdvertiserManager(this.id, manager_id);
            showNotification('Менеджер успешно удалён');
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, 'Не удалось удалить менеджера');
        }
    }

    async setDefaultContractor(item: IContractor): Promise<void> {
        try {
            await setDefaultContractor(this.id, item.id);
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, 'Не удалось установить контрагента по умолчанию');
        }
    }

    async addContractor(contractor_id: string): Promise<void> {
        try {
            await addContractor(this.id, contractor_id);
            showNotification('Контрагент успешно добавлен');
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, 'Не удалось добавить контрагента');
        }
    }

    async deleteContractor(contractor_id: string): Promise<void> {
        try {
            await deleteContractor(this.id, contractor_id);
            showNotification('Контрагент успешно удалён');
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, 'Не удалось удалить контрагента');
        }
    }

    async addOrEditAdvertiserAddress(advertiser_id: number, address: IContractorAddress, isEdit: boolean = false): Promise<void> {
        const apiMethod = isEdit ? editAdvertiserAddress : addAdvertiserAddress;
        const errText = isEdit ? 'изменить' : 'добавить';
        const successText = isEdit ? 'изменён' : 'добавлен';
        try {
            await apiMethod(this.id, advertiser_id, address);
            showNotification(`Адрес успешно ${successText}`);
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, `Не удалось ${errText} адрес`);
        }
    }

    async deleteAddress(contractorId: number, addressId: number): Promise<void> {
        try {
            await removeAdvertiserAddress(this.id, contractorId, addressId);
            showNotification('Адрес успешно удалён');
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, 'Не удалось удалить адрес');
        }
    }

    async addAffiliate(affiliate_id: number, is_need_to_overwrite: boolean): Promise<boolean> {
        try {
            await postPartnerAccounts(this.id, { affiliate_id, is_need_to_overwrite });
            showNotification('Партнёр успешно добавлен');
            eventBus.$emit("update-advertiser");
            return false;
        } catch (err) {
            if (err.status === 400 && err.data.error.message === 'Partner already linked with another advertiser') return true;
            showServerError(err, 'Не удалось добавить партнёра');
            return false;
        }
    }

    async deleteAffiliate(affiliate_id: number): Promise<void> {
        try {
            await deletePartnerAccounts(this.id, affiliate_id);
            showNotification('Партнёр успешно удалён');
            eventBus.$emit("update-advertiser");
        } catch (err) {
            showServerError(err, 'Не удалось удалить партнёра');
        }
    }
}

export const PROJECTS: IProject[] = [
    {
        abbr: '12',
        text: 'ВГО',
    },
    {
        abbr: 'IVR',
        text: 'IVR Амирова',
    },
    {
        abbr: 'LE',
        text: 'Лидгид Европа',
    },
    {
        abbr: 'LKz',
        text: 'Лидгид Казахстан',
    },
    {
        abbr: 'LR',
        text: 'Лидгид рубли',
    },
    {
        abbr: 'LU',
        text: 'Лидгид Украина (Гривны)',
    },
    {
        abbr: 'WO',
        text: 'Вебофис',
    },
    {
        abbr: 'WV',
        text: 'Вебворк',
    },
];
