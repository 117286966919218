
import { Component, Prop, Vue } from "vue-property-decorator";
import { PAGINATION_PER_PAGE } from "@/configs/global";
import { Mutation } from "vuex-class";
import { TicketsFilters } from "@/services/TablePage/tickets";
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";
import FiltersDesign from "@/components/base/filters/FiltersDesign.vue";

@Component({
    components: {
        FiltersDesign,
        FiltersDialog,
    },
})
export default class FilterTickets extends Vue {
    @Prop({ default: PAGINATION_PER_PAGE }) limit!: number;
    @Mutation("SET_DEFAULT_FILTERS_PARAMS_OBJECT") setDefaultFiltersParams;
    @Mutation("SET_FILTERS_PARAMS_OBJECT") setFiltersParamsObject;
    @Prop({ default: 500 }) colsWaxWidth!: number;
    @Prop({ default: 400 }) filtersWaxWidth!: number;
    @Prop({ default: false }) isGroupBy!: boolean;
    @Prop({ default: ".table-page" }) attachClass!: string;

    selected: string[] = [];
    isShowFilters = false;
    filters: TicketsFilters = new TicketsFilters();

    submitFilters(offset?: number, limit?: number): void {
        this.isShowFilters = false;
        this.$emit("submit", offset, limit);
    }

    created(): void {
        this.filters.setDefaultFilters();
    }
}
