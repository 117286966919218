import { ActionTree } from 'vuex';

import { IPayoutState } from '@/store/modules/payout/types';
import { IRootState } from '@/store/types';

import { getAffiliatesAvailableMethods } from '@/api/payout';

export const actions: ActionTree<IPayoutState, IRootState> = {

    GET_AFFILIATES_AVAILABLE_METHODS: async (
        { commit },
        id,
    ): Promise<void> => {
        try {
            const result = await getAffiliatesAvailableMethods(id);
            commit('SET_ALL_AFFILIATES_METHODS', result);
        } catch (error) {
            throw error;
        }
    },
};
