const projects: {abbr: string, text: string}[] = [
    {
        abbr: '12',
        text: 'ВГО',
    },
    {
        abbr: 'IVR',
        text: 'IVR Амирова',
    },
    {
        abbr: 'LE',
        text: 'Лидгид Европа',
    },
    {
        abbr: 'LKz',
        text: 'Лидгид Казахстан',
    },
    {
        abbr: 'LR',
        text: 'Лидгид рубли',
    },
    {
        abbr: 'LU',
        text: 'Лидгид Украина (Гривны)',
    },
    {
        abbr: 'WO',
        text: 'Вебофис',
    },
    {
        abbr: 'WV',
        text: 'Вебворк',
    },
];
export default projects;
