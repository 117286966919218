var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-card',{staticClass:"notification-table mb-0",attrs:{"title":_vm.tableTitle}},[_c('div',{staticClass:"notification-table__filters"},[_c('base-autocomplete',{staticClass:"--outline notification-table__manager mr-2",class:{'notification-table__manager--fix-width': !_vm.filterManager},attrs:{"data":{
                errorText: 'Менеджер не найден',
                apiMethod: _vm.getActiveUserSearch,
                multiple: false,
                itemValue: 'id',
                clearable: true,
                label: 'Менеджер',
                template: (item) => `${item.id} ${item.first_name} ${item.last_name}`,
                maxShowItems: 20,
                chipWidth: 120,
                isChip: true,
            }},on:{"change":_vm.changeHandler}}),_c('select-design',{staticClass:"notification-table__status select-design table-filter__select",attrs:{"filter":_vm.filterStatus}})],1),_c('table-with-front-pagination-design',{staticClass:"notification-table__table",attrs:{"loading":false,"items":_vm.filteredItems,"headers":_vm.tableHeaders,"unique-data":_vm.uniqueData,"statuses":_vm.statuses,"routes":_vm.routes,"actions":_vm.actions,"no-data-text":"Уведомления будут отправлены всем"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }