
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import lodashSortBy from 'lodash-es/sortBy';
import Pagination from '@/components/base/table/Pagination.vue';
import SvgExtendedReport from '@/assets/icons/nav-bar/extended-report.svg';
import CardMaterial from '@/components/base/CardMaterial.vue';
import TableScroll from '@/directives/TableScroll';
import { REPORT_PAGINATION_PER_PAGE } from '@/configs/global';
import ReportTableFooter from '@/components/statistics/extended-report/TableFooter.vue';
import SummaryRow from '@/components/statistics/extended-report/SummaryRow.vue';
import { TableHeader } from '@/types';
import ReportLayout from '@/components/layout/ReportLayout.vue';
import { IExtendedReport, IReport, ISummary } from '@/api/types/stats';
import SvgPin from '@/assets/icons/pin.svg';
import SvgPinFull from '@/assets/icons/pin-full.svg';
import SvgScrollArrNext from '@/assets/icons/scroll-arr-next.svg';
import SvgScrollArrPrev from '@/assets/icons/scroll-arr-prev.svg';
import { translateMonth } from '@/utils/translate';
import VueRouter from 'vue-router';
import isEqual from 'lodash-es/isEqual';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

@Component({
    components: { ReportLayout, ReportTableFooter, SvgExtendedReport, Pagination, CardMaterial, SvgPin, SvgScrollArrNext, SvgScrollArrPrev, SvgPinFull, SummaryRow },
    directives: { TableScroll },
})
export default class ExtendedReportTable extends Vue {
    @Prop({ required: true, default: { report: [], summary: [] } }) items!: IExtendedReport;
    @Prop({ required: true }) headers!: TableHeader[];
    @Prop({ default: false }) isNotFound!: boolean;
    @Prop({ default: false }) loading!: boolean;
    @Prop({ default: false }) loadingExport!: boolean;
    @Prop({ default: '' }) search!: string;
    @Prop({ default: '' }) convertCurrency!: string;
    @Prop() sortByFromUrl!: string;

    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    sortBy: string[] = [];
    sortDesc: boolean[] = [true];
    // для скрола
    scrollX = 0;
    pageCount = 0;
    itemsPerPage = REPORT_PAGINATION_PER_PAGE;
    pagination: { page: number } = { page: 1 };
    title = 'Расширенный отчет';
    isSticky = true;
    showFullSummary = false;
    canBeInactive = ['dm_offer_id', 'dm_offer_name', 'dm_offer_goal_id', 'dm_offer_goal_name'];
    private tableData = [];

    colsWithMonoFont = [
        'mt_clicks_total',
        'mt_clicks_unique',
        'mt_leads_total',
        'mt_leads_approved',
        'mt_leads_pending',
        'mt_leads_rejected',
        'mt_payout_total',
        'mt_payout_pending',
        'mt_payout_approved',
        'mt_payout_rejected',
        'mt_ppl',
        'mt_revenue_total',
        'mt_revenue_pending',
        'mt_revenue_approved',
        'mt_revenue_rejected',
        'mt_rpl',
        'mt_profit',
        'mt_cr_u',
        'mt_cr_t',
        'mt_ar',
        'mt_epc_u',
        'mt_epc_t',
        'mt_epc_adv',
        'mt_epl',
        'mt_bonus_amount',
        'mt_bonus_payout_approved',
        'mt_bonus_epc',
        'mt_bonus_epl',
    ];

    get isScrollBtns(): boolean {
        if (this.$refs["extend-table"]) {
            // @ts-ignore
            const overWidth = this.$refs["extend-table"].$el.offsetWidth + 50 > document.body.clientWidth;
            return this.$props.items?.report && (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) && overWidth;
        } else {
            return true;
        }
    }

    isInactiveOffer(item: IReport, header: string): boolean {
        return item.dm_offer_status !== 'active' && this.canBeInactive.includes(header);
    }

    scrollXNext(): void {
        this.scrollX += 250;
        // @ts-ignore
        this.$refs["vs"].scrollTo({ x: `${this.scrollX}` }, 500);
    }

    scrollXPrev(): void {
        if (this.scrollX >= 250) {
            this.scrollX -= 250;
        } else {
            this.scrollX = 0;
        }
        // @ts-ignore
        this.$refs["vs"].scrollTo({ x: `${this.scrollX}` }, 500);
    }

    handleOptionsUpdate({ sortBy, sortDesc }: {sortBy: string[], sortDesc: boolean[]}): void {
        if (
            sortBy[0] === this.sortBy[0] &&
            sortDesc[0] === this.sortDesc[0]
        ) return;

        if (sortBy[0] && sortDesc[0] === false) {
            this.sortDesc = [true];
            return;
        }

        if (sortBy.length === 0 && sortDesc.length === 0) {
            this.sortBy = [this.sortBy[0]];
            this.sortDesc = [false];
            return;
        }

        if (sortBy[0] && sortDesc[0] === true) {
            this.sortBy = [];
            this.sortDesc = [];
            return;
        }
    }

    isMonoFont(val: string): boolean {
        return this.colsWithMonoFont.includes(val);
    }

    numberFormatting = [
        { slot: 'item.mt_profit', key: 'mt_profit' },
        { slot: 'item.mt_payout_total', key: 'mt_payout_total' },
        { slot: 'item.mt_payout_pending', key: 'mt_payout_pending' },
        { slot: 'item.mt_payout_approved', key: 'mt_payout_approved' },
        { slot: 'item.mt_payout_rejected', key: 'mt_payout_rejected' },
        { slot: 'item.mt_ppl', key: 'mt_revenue_total' },
        { slot: 'item.mt_revenue_pending', key: 'mt_revenue_pending' },
        { slot: 'item.mt_revenue_approved', key: 'mt_revenue_approved' },
        { slot: 'item.mt_revenue_rejected', key: 'mt_revenue_rejected' },
        { slot: 'item.mt_rpl', key: 'mt_rpl' },
        { slot: 'item.mt_cr_u', key: 'mt_cr_u' },
        { slot: 'item.mt_cr_t', key: 'mt_cr_t' },
        { slot: 'item.mt_ar', key: 'mt_ar' },
        { slot: 'item.mt_epc_u', key: 'mt_epc_u' },
        { slot: 'item.mt_epc_t', key: 'mt_epc_t' },
        { slot: 'item.mt_epc_adv', key: 'mt_epc_adv' },
        { slot: 'item.mt_epl', key: 'mt_epl' },
        { slot: 'item.mt_bonus_amount', key: 'mt_bonus_amount' },
        { slot: 'item.mt_bonus_payout_approved', key: 'mt_bonus_payout_approved' },
        { slot: 'item.mt_bonus_epc', key: 'mt_bonus_epc' },
        { slot: 'mt_bonus_epl', key: 'mt_bonus_epl' },
    ];

    // для отображения роутов в таблице
    routes = [
        {
            getParams(item: {dm_offer_id: string}): {id: string} {
                return {
                    id: item.dm_offer_id!,
                };
            },
            routerName: 'offer',
            slot: 'item.dm_offer_goal_id',
            text: 'dm_offer_goal_id',
            key: 'dm_offer_goal_id',
            isChip: true,
            template: (item: {dm_offer_goal_id: string}): string => item.dm_offer_goal_id,
        },
        {
            getParams(item: {dm_offer_id: string}): {id: string} {
                return {
                    id: item.dm_offer_id!,
                };
            },
            routerName: 'offer',
            slot: 'item.dm_offer_goal_name',
            text: 'dm_offer_goal_name',
            key: 'dm_offer_goal_name',
            template: (item: {dm_offer_goal_name: string}): string => item.dm_offer_goal_name,
        },
        {
            getParams(item: {dm_offer_id: string}): {id: string} {
                return {
                    id: item.dm_offer_id!,
                };
            },
            routerName: 'offer',
            slot: 'item.dm_offer_name',
            text: 'dm_offer_name',
            key: 'dm_offer_name',
            template: (item: {dm_offer_name: string}): string => item.dm_offer_name,
        },
        {
            getParams(item: {dm_offer_id: string}): {id: string} {
                return {
                    id: item.dm_offer_id!,
                };
            },
            routerName: 'offer',
            slot: 'item.dm_offer_id',
            text: 'dm_offer_id',
            key: 'dm_offer_id',
            isChip: true,
            template: (item: {dm_offer_id: string}): string => item.dm_offer_id,
        },
        {
            template: (item: {dm_affiliate_name: string | null}): string => item.dm_affiliate_name || '',
            getParams(item: {dm_affiliate_id: string}): {id: string} {
                return {
                    id: item.dm_affiliate_id!,
                };
            },
            routerName: 'affiliate',
            slot: 'item.dm_affiliate_name',
            text: 'dm_affiliate_name',
            key: 'dm_affiliate_name',
        },
        {
            getParams(item: {dm_affiliate_id: string}): {id: string} {
                return {
                    id: item.dm_affiliate_id!,
                };
            },
            routerName: 'affiliate',
            slot: 'item.dm_affiliate_id',
            text: 'dm_affiliate_id',
            key: 'dm_affiliate_id',
            isChip: true,
            template: (item: {dm_affiliate_id: string}): string => item.dm_affiliate_id,
        },
        {
            getParams(item: {dm_buffer_account_id: string | number}): {id: string | number} {
                return {
                    id: item.dm_buffer_account_id!,
                };
            },
            routerName: 'affiliate',
            slot: 'item.dm_buffer_account_id',
            text: 'dm_buffer_account_id',
            key: 'dm_buffer_account_id',
            isChip: true,
            template: (item: {dm_buffer_account_id:  string | number}):  string | number => item.dm_buffer_account_id,
        },
        {
            template: (item: {dm_buffer_account_name: string | null}): string => item.dm_buffer_account_name || '',
            getParams(item: {dm_buffer_account_id: string}): {id: string} {
                return {
                    id: item.dm_buffer_account_id!,
                };
            },
            routerName: 'affiliate',
            slot: 'item.dm_buffer_account_name',
            text: 'dm_buffer_account_name',
            key: 'dm_buffer_account_name',
        },
        {
            template: (item: {dm_master_account_name: string | null}): string => item.dm_master_account_name || '',
            getParams(item: {dm_master_account: string}): {id: string} {
                return {
                    id: item.dm_master_account!,
                };
            },
            routerName: 'affiliate',
            slot: 'item.dm_master_account_name',
            text: 'dm_master_account_name',
            key: 'dm_master_account_name',
        },
        {
            getParams(item: {dm_master_account: string | number}): {id: string | number} {
                return {
                    id: item.dm_master_account!,
                };
            },
            routerName: 'affiliate',
            slot: 'item.dm_master_account',
            text: 'dm_master_account',
            key: 'dm_master_account',
            isChip: true,
            template: (item: {dm_master_account:  string | number}):  string | number => item.dm_master_account,
        },
    ];

    uniqueData = [
        {
            slot: 'item.dm_affiliate_manager_name',
            key: 'dm_affiliate_manager_name',
            template: (item: any): string => item.dm_affiliate_manager_name || '',
        },
        {
            slot: 'item.dm_buffer_account_used',
            key: 'dm_buffer_account_used',
            template: (item: any): string => item.dm_buffer_account_used ? 'Да' : 'Нет',
        },
        {
            slot: 'item.dm_advertiser_manager_name',
            key: 'dm_advertiser_manager_name',
            template: (item: any): string => item.dm_advertiser_manager_name || '',
        },
        {
            slot: 'item.dm_offer_country',
            key: 'dm_offer_country',
            template: (item: any): string => item.dm_offer_country ? item.dm_offer_country.join(', ') : '',
        },
        {
            slot: 'item.dm_lead_date_month',
            key: 'dm_lead_date_month',
            template: (item: any): string => translateMonth(item.dm_lead_date_month),
        },
        {
            slot: 'item.dm_affiliate_traffic_source_id',
            key: 'dm_affiliate_traffic_source_id',
            template: (item: any): string => item.dm_affiliate_traffic_source_id ? item.dm_affiliate_traffic_source_id.join(', ') : '',
        },
        {
            slot: 'item.dm_affiliate_traffic_source_name',
            key: 'dm_affiliate_traffic_source_name',
            template: (item: any): string => item.dm_affiliate_traffic_source_name ? item.dm_affiliate_traffic_source_name.join(', ') : '',
        },
        {
            slot: 'item.dm_offer_currency',
            key: 'dm_offer_currency',
            template: (item: any): string => item.dm_offer_currency,
        },
    ];

    getClassForCurrency(key: string, item?: any): string {
        if (key !== 'dm_offer_currency') {
            return '';
        }
        if (item) {
            return item.dm_offer_currency === this.convertCurrency ? 'currency-conversion-cell' : '';
        }
        return !!this.convertCurrency ? 'currency-conversion-head' : '';
    }

    get summaryTableItems(): ISummary[] {
        if (!this.$props.items?.summary) return [];
        const summary = [...this.$props.items?.summary];

        if (this.sortBy?.[0] && summary?.[0]?.[this.sortBy?.[0]]) {
            const result = lodashSortBy(summary, this.sortBy);
            return this.sortDesc?.[0] === false ? result : result.reverse();
        }
        return this.$props.items?.summary;
    }

    get total(): number {
        return this.$props.items.report?.length;
    }

    updateSortInQuery(): void {
        if (
            this.sortBy?.[0] === this.sortByFromUrl &&
            this.sortDesc?.[0]?.toString() === this.$route.query?.sort_desc
        ) return;

        const query: any = { ...this.$route.query };

        if (this.sortBy?.[0]) {
            query.sort_by = this.sortBy[0];
            query.sort_desc = this.sortDesc?.[0];
        } else {
            delete query.sort_by;
            delete query.sort_desc;
        }

        this.$router
            .push({ name: this.$router.currentRoute.name || '', query })
            .catch((e) => {
                if (!isNavigationFailure(e, NavigationFailureType.duplicated)) {
                    throw e;
                }
            });
    }

    @Watch('getFiltersParamsObject', { deep: true })
    changeSortBy(value: any, oldValue: any): void {
        if (isEqual(value, oldValue)) return;

        const columns = this.getFiltersParamsObject?.columns;
        if (!(columns && Array.isArray(columns)) || columns.includes(this.sortBy[0])) {
            return;
        }
        // иначе ставим дефолтную сортировку по дате или кликам
        if (columns.includes('dm_lead_date')) {
            this.sortBy = [this.getFiltersParamsObject.group] || ['dm_lead_date'];
            return;
        }
        if (columns.includes('mt_clicks_total')) {
            this.sortBy = ['mt_clicks_total'];
        }
        // если удалён столбец с сортировкой, отменяем сортировку по нему
        if (columns.length && !columns.includes(this.sortBy?.[0])) {
            this.sortBy = [this.getFiltersParamsObject.columns[0]];
        }
    }

    @Watch('items')
    setItems(): void {
        this.tableData = this.$props.items.report;
    }

    @Watch('sortBy')
    emitSortBy(): void {
        setTimeout(() => this.updateSortInQuery());
    }

    @Watch('sortDesc')
    emitSortDesc(): void {
        setTimeout(() => this.updateSortInQuery());
    }

    @Watch('sortByFromUrl', { immediate: true })
    setSortByQuery(value: string): void {
        this.sortBy = [value];
    }
}
