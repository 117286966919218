
import { Vue, Component } from "vue-property-decorator";
import { getEmployeesPayouts } from "@/api/offers";
import AdministrationOffersLayout from "@/components/layout/AdministrationOffersLayout.vue";

@Component({
    methods: { getEmployeesPayouts },
    components: { AdministrationOffersLayout },
})

export default class Payouts extends Vue {}
