
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { IOtherService } from "@/api/types/revenue";
import { translateOtherServiceStatus } from "@/utils/translate";
import FormModal from "@/components/base/FormModal.vue";
import { patchInvoiceOtherServices } from "@/api/revenue";
import { showNotification, showServerError } from "@/utils";
import { ITableStatus } from "@/types";

@Component({
    components: { FormModal, AppTableDesign },
})

export default class OtherServicesTable extends Vue {
    @Prop({ required: true }) items!: IOtherService[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    isShowModal: boolean = false;
    isLoading: boolean = false;
    editableItem: IOtherService | null = null;
    targetStatus: string = '';

    headers = [
        { text: '№', value: 'service_id', align: 'center', width: '40px' },
        { text: 'Название', value: 'service_name' },
        { text: 'Когда создано', value: 'created_at' },
        { text: 'Кем создано', value: 'created_by.full_name' },
        { text: 'Статус', value: 'is_active', align: 'left', width: '100px' },
        { text: 'Действия', value: 'actions', align: 'center', width: '40px' },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.is_active',
            key: 'is_active',
            translate: translateOtherServiceStatus,
        },
    ];

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
        },
    ];

    actions = [
        {
            isTooltip: true,
            color: '#1B1B1D',
            icon: 'SvgEdit',
            computedTitle: (i: IOtherService): string => i.is_active ? 'Деактивировать' : 'Активировать',
            callback: (i: IOtherService): void => this.openModal(i),
        },
    ];

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }

    openModal(item: IOtherService): void {
        this.editableItem = item;
        this.targetStatus = item.is_active ? 'Не активна' : 'Активна';
        this.isShowModal = true;
    }

    closeModal(): void {
        this.isShowModal = false;
        this.editableItem = null;
        this.targetStatus = '';
    }

    async editService(): Promise<void> {
        try {
            this.isLoading = true;
            await patchInvoiceOtherServices(this.editableItem?.service_id!, { is_active: !this.editableItem?.is_active });
            showNotification('Прочая услуга успешно изменена');
            this.$emit('update-table');
            this.closeModal();
        } catch (err) {
            showServerError(err, 'Прочая услуга не изменена');
        } finally {
            this.isLoading = false;
        }
    }
}

