
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translatePaymentsRequisite } from '@/utils/translate';
import Status from '@/components/base/Status.vue';
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import { searchWebmasters } from '@/api/org-cabinets-backend';

@Component({
    components: {
        Status,
        BaseAutocomplete,
    },
})
export default class ManagerFieldKeys extends Vue {
    @Prop({ required: true }) managerFields!: [];
    @Prop({ required: true }) managerData!: {};
    managerFieldsKeys = {};
    isLoading = false;
    searchTimerId = 0;
    translatePaymentsRequisite = translatePaymentsRequisite;
    searchWebmasters = searchWebmasters;

    created(): void {
        this.getManagerFieldsKeys();
    }

    getManagerFieldsKeys(): void {
        let requiredFields = {};
        this.$props.managerFields.forEach((item) => {
            const i = { [item]: '' };
            return requiredFields = { ...requiredFields, ...i };
        });

        this.managerFieldsKeys = requiredFields;
    }

    changeLegalId(name: string, id: string): void {
        this.managerFieldsKeys[name] = id;
        this.$emit('change', this.managerFieldsKeys);
    }
}
