import { AxiosResponse } from 'axios';
import {
    IApplicationsFilters,
    IApplicationsList,
    ICountry,
    IApplicationItem,
    IApplicationConversion,
    IBlackListItem,
    IOffersFilters,
    IConversionOffer,
    IOffer,
    IOfferSettings,
    IOfferGeo,
    IOfferLimits,
    IOfferLimit,
    IOfferAccess,
    IStatsError,
    ISubstitutionItem,
    IGeoRegions,
    IApplicationAnalytics,
    IStream,
    IStage,
    IStageAdded,
    ICondition,
    IConditionOption,
    IEndpointList,
    IEndpointItem,
    IGroupingStatsError,
    IOffersDelay,
    IOfferSchedule,
    IAnalyticApplication,
    IAnalyticField, IFileIntegrations,
} from '@/api/types/uapi';
import { IParamsObject, IServerResponse } from '@/api/types';

import { axiosInstance } from '@/api/index';
import { API_UAPI_URL } from '@/configs/global';
import { IFile } from '@/api/types/revenue';

/*
 * Получаем список доступных заявок
 */
export async function getApplications(
    params: IApplicationsFilters,
): Promise<IServerResponse<IApplicationsList[]>> {
    return axiosInstance.request<IServerResponse<IApplicationsList[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/applications`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IApplicationsList[]>>) => response.data);
}

/*
 * Выгрузка конверсий
 */
export async function getConversionsCsv(
    params: IApplicationsFilters,
): Promise<any> {
    return axiosInstance.request<any>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/conversions`,
        params: {
            ...params,
            format: 'csv',
        },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<any>) => response.data);
}

/*
 * Выгрузка заявок
 */
export async function getApplicationsCsv(
    params: IApplicationsFilters,
): Promise<any> {
    return axiosInstance.request<any>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/applications`,
        params: {
            ...params,
            format: 'csv',
        },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<any>) => response.data);
}

/*
 * Получаем список стран
 */
export async function getCountries(): Promise<IServerResponse<ICountry[]>> {
    return axiosInstance.request<IServerResponse<ICountry[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/geo/countries`,
    })
        .then((response: AxiosResponse<IServerResponse<ICountry[]>>) => response.data);
}

/*
 * Получаем список регионов в определенных странах
 */
export async function getRegions(
    params: {
        country_id?: number[],
        q: string,
    },
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/geo/regions`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/*
 * Получаем список городов в определенных странах и регионах
 */
export async function getCities(
    params: {
        country_id?: number[],
        region_id?: number[],
        q: string,
    },
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/geo/cities`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/*
 * Просмотр заявки по uuid
*/
export async function getApplicationItem(
    uuid: string,
): Promise<IApplicationItem> {
    return axiosInstance.request<IServerResponse<IApplicationItem>>({
        method: 'GET',
        url: `${API_UAPI_URL}/applications/${uuid}`,
    })
        .then((response: AxiosResponse<IServerResponse<IApplicationItem>>) => response.data.data);
}

/*
 * Просмотр аналитики заявки
 */
export async function getApplicationAnalytics(
    uuid: string,
): Promise<IServerResponse<IApplicationAnalytics>> {
    return axiosInstance.request<IServerResponse<IApplicationAnalytics>>({
        method: 'GET',
        url: `${API_UAPI_URL}/applications/${uuid}/analytics`,
    })
        .then((response: AxiosResponse<IServerResponse<IApplicationAnalytics>>) => response.data);
}

/*
 * Получить конверсии заявки по uuid
 */
export async function getConversionsList(
    params: {
        uuid?: string[],
        limit: number,
        offset: number,
    },
): Promise<IServerResponse<IApplicationConversion[]>> {
    return axiosInstance.request<IServerResponse<IApplicationConversion[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/conversions`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IApplicationConversion[]>>) => response.data);
}

/*
 * Детальная (базовая) информация по офферу
 */
export async function getOfferById(
    id: number,
): Promise<any> {
    return axiosInstance.request({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${id}`,
    })
        .then((response) => response.data);
}

/*
 * Вкладка "Условия" в деталке оффера
 */
export async function getOfferRules(
    id: number,
): Promise<any> {
    return axiosInstance.request({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${id}/settings`,
    })
        .then((response) => response.data);
}

/*
 * Редактирование оффер-правил
 */
export async function editOfferSettings(
    id: number,
    data: IOfferSettings,
): Promise<IServerResponse<IOfferSettings>> {
    return axiosInstance.request<IServerResponse<IOfferSettings>>({
        method: 'PATCH',
        url: `${API_UAPI_URL}/offers/${id}/settings`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferSettings>>) => response.data);
}

/*
 * Получение данных оффера
 */
export async function getOffer(
    id: number,
): Promise<IOffer> {
    return axiosInstance.request<IServerResponse<IOffer>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IOffer>>) => response.data.data);
}

/*
 * Вкладка "ГЕО" в деталке оффера
 */
export async function getOfferGeo(
    id: number,
): Promise<IOfferGeo> {
    return axiosInstance.request<IServerResponse<IOfferGeo>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${id}/geo`,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferGeo>>) => response.data.data);
}

/*
 * Вкладка "ГЕО" в деталке оффера - редактирование
 */
export async function editOfferGeo(
    id: number,
    data: IOfferGeo,
): Promise<IOfferGeo> {
    return axiosInstance.request<IServerResponse<IOfferGeo>>({
        method: 'PATCH',
        url: `${API_UAPI_URL}/offers/${id}/geo`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferGeo>>) => response.data.data);
}

/*
 * Вкладка "ГЕО" в деталке оффера - получение списка регионов
 */
export async function getOfferRegions(
    offerId: number,
): Promise<IServerResponse<IGeoRegions>> {
    return axiosInstance.request<IServerResponse<IGeoRegions>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${offerId}/geo/regions`,
    })
        .then((response: AxiosResponse<IServerResponse<IGeoRegions>>) => response.data);
}

export async function downloadOfferRegions(
    offerId: number,
): Promise<IServerResponse<IGeoRegions>> {
    return axiosInstance.request<IServerResponse<IGeoRegions>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${offerId}/geo/regions`,
        params: { csv : 1 },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<any>) => response.data);
}

/*
 * Вкладка "ГЕО" - обновление списка регионов
 */
export async function editOffersRegion(
    offerId: number,
    data: {
        region_id: number[],
    },
): Promise<IServerResponse<IGeoRegions>> {
    return axiosInstance.request<IServerResponse<IGeoRegions>>({
        method: 'POST',
        url: `${API_UAPI_URL}/offers/${offerId}/geo/regions`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IGeoRegions>>) => response.data);
}

export async function getOfferCities(
    offerId: number,
): Promise<IServerResponse<any[]>> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${offerId}/geo/cities`,
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}

export async function downloadOfferCities(
    offerId: number,
): Promise<IServerResponse<any[]>> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${offerId}/geo/cities`,
        params: { csv : 1 },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<any>) => response.data);
}

export async function editOfferCities(
    offerId: number,
    data: {
        city_id: number[],
        city_code: string[],
    },
): Promise<IServerResponse<any[]>> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'POST',
        url: `${API_UAPI_URL}/offers/${offerId}/geo/cities`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}

export async function deleteOfferCities(
    offerId: number,
    cityIds: number[],
): Promise<IServerResponse<any[]>> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'DELETE',
        url: `${API_UAPI_URL}/offers/${offerId}/geo/cities`,
        data: {
            city_id: cityIds,
        },
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}

/*
 * Вкладки "Лимиты" в деталке оффера - список
 */
export async function getOfferLimits(
    id: number,
): Promise<IOfferLimits> {
    return axiosInstance.request<IServerResponse<IOfferLimits>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${id}/limits`,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferLimits>>) => response.data.data);
}

/*
 * Вкладки "Лимиты" в деталке оффера - добавление
 */
export async function addOfferLimit(
    id: number,
    data: IOfferLimit,
): Promise<IOfferLimit> {
    return axiosInstance.request<IServerResponse<IOfferLimit>>({
        method: 'POST',
        url: `${API_UAPI_URL}/offers/${id}/limits`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferLimit>>) => response.data.data);
}

/*
 * Получение расписания оффера
 */
export async function getOfferSchedule(
    id: number,
): Promise<IServerResponse<IOfferSchedule>> {
    return axiosInstance.request<IServerResponse<IOfferSchedule>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers/${id}/schedule`,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferSchedule>>) => response.data);
}

/*
 * Редактировать расписание
 */
export async function editSchedule(
    offerId: number,
    schedule: object,
): Promise<IServerResponse<{offer_id: number}>> {
    return axiosInstance.request<IServerResponse<{offer_id: number}>>({
        method: 'POST',
        url: `${API_UAPI_URL}/offers/${offerId}/schedule`,
        data: {
            ...schedule,
        },
    })
        .then((response: AxiosResponse<IServerResponse<{offer_id: number}>>) => response.data);
}

/*
 * Сводный отчет по офферам
*/
export async function getStatsConversions(
    params: IOffersFilters,
): Promise<IServerResponse<IConversionOffer[]>> {
    return axiosInstance.request<IServerResponse<IConversionOffer[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stats/conversions`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IConversionOffer[]>>) => response.data);
}

/*
 * Ошибки правил оффера
*/
export async function getStatsErrorUnsatisfied(
    params: IOffersFilters,
): Promise<IServerResponse<IStatsError[]>> {
    return axiosInstance.request<IServerResponse<IStatsError[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stats/error/unsatisfied`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IStatsError[]>>) => {
            return response.data;
        });
}

/*
 * Получить список партнёров
 */
export async function getAffiliates(): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_UAPI_URL}/affiliates`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 *  Список доступов
 */
export async function getAccessList(
    params: {},
): Promise<IServerResponse<IOfferAccess[]>> {
    return axiosInstance.request<IServerResponse<IOfferAccess[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/settings/access`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferAccess[]>>) => response.data);
}

/*
 *  Удаление доступа
 */
export async function deleteAccessIds(
    ids: number[],
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_UAPI_URL}/settings/access`,
        params: {
            ids,
        },
    })
        .then((response) => response.data);
}

/*
 * Добавление нового доступа
 */
export async function addNewAccess(
    data: {
        offer_id?: number[];
        affiliate_id?: number[];
    },
): Promise<IServerResponse<IOfferAccess>> {
    return axiosInstance.request<IServerResponse<IOfferAccess>>({
        method: 'POST',
        url: `${API_UAPI_URL}/settings/access`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferAccess>>) => response.data);
}

/*
 * Получить список глобальных отложек (с фильтром по партнёру и по офферу):
 */
export async function getOffersDelay(
    params: {
        affiliate_id?: number;
        offer_id?: number;
    },
): Promise<IServerResponse<IOffersDelay[]>> {
    return axiosInstance.request<IServerResponse<IOffersDelay[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/affiliates/offers-delay`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IOffersDelay[]>>) => response.data);
}

/*
 * Добавление/редактирование глобальной отложки:
 */
export async function postDelay(
    {
        affiliate_id, offer_id, delay,
    }: IOffersDelay,
): Promise<IServerResponse<IOffersDelay>> {
    return axiosInstance.request<IServerResponse<IOffersDelay>>({
        method: 'POST',
        url: `${API_UAPI_URL}/affiliates/${affiliate_id}/offers/${offer_id}/delay`,
        data: {
            delay,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IOffersDelay>>) => response.data);
}

/*
 * Удаление глобальной отложки
 */
export async function deleteOfferDelay(
    affiliate_id: number,
    offer_id: number,
): Promise<IServerResponse<{id: number}>> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'DELETE',
        url: `${API_UAPI_URL}/affiliates/${affiliate_id}/offers/${offer_id}/delay`,
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data);
}

/*
 * Получить статистику по ошибкам
 */
export async function getStatisticErrors(
    params: IOffersFilters,
): Promise<IServerResponse<IStatsError[]>> {
    return axiosInstance.request<IServerResponse<IStatsError[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stats/error`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IStatsError[]>>) => response.data);
}

/*
 * Скачать статистику по ошибкам
 */
export async function downloadErrorFile(
    params: any,
): Promise<IFile> {
    return axiosInstance.request<IFile>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/applications_error`,
        params: {
            ...params,
            format: 'csv',
        },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IFile>) => response.data);
}/*
 * Скачать статистику по ошибкам
 */
export async function downloadErrorOfferRuleFile(
    params: any,
): Promise<IFile> {
    return axiosInstance.request<IFile>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/offer_rule_error`,
        params: {
            ...params,
            format: 'csv',
        },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IFile>) => response.data);
}

/*
 * Получить выгрузку статистики по ошибкам
 */
export async function exportStatisticErrors(
    params: IOffersFilters,
): Promise<IServerResponse<IStatsError[]>> {
    return axiosInstance.request<IServerResponse<IStatsError[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/statistic_error`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IStatsError[]>>) => response.data);
}

/*
 * Скачать статистику по ошибкам офферов
 */
export async function downloadErrorRuleFile(
    params: {
        error_type_id: number,
    },
): Promise<IFile> {
    return axiosInstance.request<IFile>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/offer_rule_error`,
        params: {
            ...params,
            format: 'csv',
        },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IFile>) => response.data);
}

/*
 * Скачать статистику по ошибкам (без группировок)
 */
export async function downloadFile(
    params: {
        error: string,
        offer_id: number[],
    },
): Promise<IFile> {
    return axiosInstance.request<IFile>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/applications_error-text`,
        params: {
            ...params,
            format: 'csv',
        },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IFile>) => response.data);
}

/*
 * Скачать статистику по ошибкам офферов
 */
export async function downloadErrorsRule(
    params: {
        error: string,
        offer_id: number[],
    },
): Promise<IFile> {
    return axiosInstance.request<IFile>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/offer_rule_error-text`,
        params: {
            ...params,
            format: 'csv',
        },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IFile>) => response.data);
}

/*
 * Добавить группировку ошибок (статистика по ошибкам)
 */
export async function addErrorType(
    data: IGroupingStatsError,
    unsatisfied?: boolean,
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'POST',
        url: `${API_UAPI_URL}/errortype${unsatisfied ? '?type=unsatisfied' : ''}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/*
 * Редактировать группировку ошибок (статистика по ошибкам)
 */
export async function editErrorType(
    id: number,
    data: IGroupingStatsError,
    unsatisfied?: boolean,
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'PATCH',
        url: `${API_UAPI_URL}/errortype/${id + (unsatisfied ? '?type=unsatisfied' : '')}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/*
 * Удаление статистики ошибки
 */
export async function deleteErrorType(
    id: string,
    unsatisfied?: boolean,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'DELETE',
        url: `${API_UAPI_URL}/errortype/${id + (unsatisfied ? '?type=unsatisfied' : '')}`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Получение списка обязательных полей
 */
export async function getObligatoryStreamFields(
    id: string,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams/${id}/offers-fields`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/*
 * Получение списка автоподстановки
 */
export async function getSubstitutionList(
    params: {
        affiliate_id?: number[];
        offer_id?: number[];
    },
    distribute?: boolean,
): Promise<IServerResponse<ISubstitutionItem[]>> {
    return axiosInstance.request<IServerResponse<ISubstitutionItem[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/settings/substitution${distribute ? '?type=distribute' : ''}`,
        params,

    })
        .then((response: AxiosResponse<IServerResponse<ISubstitutionItem[]>>) => response.data);
}

/*
 * Получение правила автоподстановки по id
 */
export async function getSubstitutionById(
    id: number,
): Promise<ISubstitutionItem[]> {
    return axiosInstance.request<IServerResponse<ISubstitutionItem[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/settings/substitution/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<ISubstitutionItem[]>>) => response.data.data);
}

/*
 * Получение правила автоподстановки по id
 */
export async function patchSubstitutionById(
    id: number,
    data: ISubstitutionItem,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_UAPI_URL}/settings/substitution/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Создание автоподстановки
 */
export async function createSubstitution(
    data: ISubstitutionItem,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_UAPI_URL}/settings/substitution`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Удаление автоподстановки
 */
export async function deleteSubstitution(
    substitutionId: number,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'DELETE',
        url: `${API_UAPI_URL}/settings/substitution/${substitutionId}`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Получение блэклиста
 */
export async function getBlackList(
    suffix: string,
    params: {
        offset: number,
        limit: number,
        value?: string,
    },
): Promise<IServerResponse<IBlackListItem[]>> {
    return axiosInstance.request<IServerResponse<IBlackListItem[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/settings/blacklist/${suffix}`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IBlackListItem[]>>) => response.data);
}

/*
 * Выгрузка блэклиста
 */
export async function getBlackListCsv(
    suffix: string,
    params?: {
        value?: string,
    },
): Promise<any> {
    return axiosInstance.request<any>({
        method: 'GET',
        url: `${API_UAPI_URL}/settings/blacklist/${suffix}`,
        params: {
            ...params,
            format: 'csv',
        },
        responseType: 'blob',
    })
        .then((response: AxiosResponse<any>) => response.data);
}

/*
 * Добавление элемента блэклиста
 */
export async function addBlackListItem(
    suffix: string,
    data: object,
): Promise<IServerResponse<{id: number}>> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'POST',
        url: `${API_UAPI_URL}/settings/blacklist/${suffix}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data);
}

/*
 * Удаление элемента блэклиста
 */
export async function deleteBlackListItem(
    suffix: string,
    itemID: number | string,
): Promise<IServerResponse<{id: number}>> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'DELETE',
        url: `${API_UAPI_URL}/settings/blacklist/${suffix}/${itemID}`,
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data);
}

/*
 * Список офферов
 */
export async function getOffersList(
    params?: IParamsObject,
): Promise<IServerResponse<IOffer[]>> {
    return axiosInstance.request<IServerResponse<IOffer[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/offers`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IOffer[]>>) => response.data);
}

/*
 * Добавить/изменить оффер
 */
export async function createOffer(
    data: object,
): Promise<IServerResponse<{id: number}>> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'POST',
        url: `${API_UAPI_URL}/offer`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data);
}

/*
 * Список вебов
 */
export async function getAffiliatesList(
    params: {
        query?: string;
        id?: string[],
    },
): Promise<object> {
    return axiosInstance.request({
        method: 'GET',
        url: `${API_UAPI_URL}/affiliates`,
        params,
    })
        .then((response) => response.data);
}

/* ПОТОКИ */

/*
 * Сводный отчет по потокам
*/
export async function getStreams(
    params?: {},
): Promise<IServerResponse<IStream[]>> {
    return axiosInstance.request<IServerResponse<IStream[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IStream[]>>) => response.data);
}

/*
 * Получение потока
 */
export async function getStream(
    streamId: string,
    version?: {},
): Promise<IServerResponse<IStream>> {
    return axiosInstance.request<IServerResponse<IStream>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams/${streamId}`,
        params: {
            ...version,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IStream>>) => response.data);
}

/*
 * Проверка наличия поля URL
 */
export async function getEndpoint(
    params: {
        query: string;
    },
): Promise<IServerResponse<IEndpointItem[]>> {
    return axiosInstance.request<IServerResponse<IEndpointItem[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stream_endpoints`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IEndpointItem[]>>) => response.data);
}

/*
 * Добавление нового потока
 */
export async function addNewStream(
    data: IStream,
): Promise<IServerResponse<IStream>> {
    return axiosInstance.request<IServerResponse<IStream>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IStream>>) => response.data);
}

/*
 * Редактирование потока
 */
export async function editStream(
    streamId: number,
    data: IStream,
): Promise<IServerResponse<IStream>> {
    return axiosInstance.request<IServerResponse<IStream>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams/${streamId}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IStream>>) => response.data);
}

// /*
//  * Условия потока
//  */
export async function getStreamsConditions(
    streamId: string,
): Promise<IServerResponse<ICondition[]>> {
    return axiosInstance.request<IServerResponse<ICondition[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams/${streamId}/conditions`,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition[]>>) => response.data);
}

/*
 * Условия стадии потока
 */
export async function getStageConditions(
    streamId: string,
    stageId: string,
): Promise<IServerResponse<ICondition[]>> {
    return axiosInstance.request<IServerResponse<ICondition[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams/${streamId}/stages/${stageId}/conditions`,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition[]>>) => response.data);
}

/*
 * Получение списка полей заявки
 */
export async function getApplicationsFields(type?: string): Promise<IServerResponse<Array<{field: string; name: string}>>> {
    return axiosInstance.request<IServerResponse<Array<{field: string; name: string}>>>({
        method: 'GET',
        url: `${API_UAPI_URL}/applications/fields${type ? `?type=${type}` : ``}`,
    })
        .then((response: AxiosResponse<IServerResponse<Array<{field: string; name: string}>>>) => response.data);
}

/*
 * Получение списка типов условий потоков для селекта
 */
export async function getPossibleStreamCondition(): Promise<IServerResponse<IConditionOption[]>> {
    return axiosInstance.request<IServerResponse<IConditionOption[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams/conditions`,
    })
        .then((response: AxiosResponse<IServerResponse<IConditionOption[]>>) => response.data);
}

/*
 * Получение списка типов условий кампаний для селекта
 */
export async function getPossibleEndpointCondition(): Promise<IServerResponse<IConditionOption[]>> {
    return axiosInstance.request<IServerResponse<IConditionOption[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/endpoints/conditions`,
    })
        .then((response: AxiosResponse<IServerResponse<IConditionOption[]>>) => response.data);
}

/*
 * Получение списка типов условий стадий для селекта
 */
export async function getPossibleStageCondition(): Promise<IServerResponse<IConditionOption[]>> {
    return axiosInstance.request<IServerResponse<IConditionOption[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stages/conditions`,
    })
        .then((response: AxiosResponse<IServerResponse<IConditionOption[]>>) => response.data);
}

/*
 * Получение списка типов условий стадий для селекта
 */
export async function getConversionStatuses(): Promise<IServerResponse<string[]>> {
    return axiosInstance.request<IServerResponse<string[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/conversion/statuses`,
    })
        .then((response: AxiosResponse<IServerResponse<string[]>>) => response.data);
}

/*
 * Получаем условие по id (потока или стадии)
 */
export async function getConditionById(
    streamId: string,
    conditionId: string,
): Promise<IServerResponse<ICondition>> {
    return axiosInstance.request<IServerResponse<ICondition>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams/${streamId}/conditions/${conditionId}`,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition>>) => response.data);
}

/*
 * Создание условия стадии
 */
export async function addStageCondition(
    streamId: string,
    stageId: string,
    data: {},
): Promise<IServerResponse<ICondition>> {
    return axiosInstance.request<IServerResponse<ICondition>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams/${streamId}/stages/${stageId}/conditions`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition>>) => response.data);
}

/*
 * Редактирование условия стадии
 */
export async function editStageCondition(
    streamId: string,
    stageId: string,
    conditionId: number,
    data: {},
): Promise<IServerResponse<ICondition>> {
    return axiosInstance.request<IServerResponse<ICondition>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams/${streamId}/stages/${stageId}/conditions/${conditionId}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition>>) => response.data);
}

/*
 * Создание условия потока
 */
export async function addStreamCondition(
    streamId: string,
    data: {},
): Promise<IServerResponse<ICondition>> {
    return axiosInstance.request<IServerResponse<ICondition>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams/${streamId}/conditions`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition>>) => response.data);
}

/*
 * Редактирование условия потока
 */
export async function editStreamCondition(
    streamId: string,
    conditionId: number,
    data: {},
): Promise<IServerResponse<ICondition>> {
    return axiosInstance.request<IServerResponse<ICondition>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams/${streamId}/conditions/${conditionId}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition>>) => response.data);
}

/*
 * Запрос на получение стадий потока
 */
export async function getStreamStagesList(
    streamId: string,
): Promise<IServerResponse<IStage[]>> {
    return axiosInstance.request<IServerResponse<IStage[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams/${streamId}/stages`,
    })
        .then((response: AxiosResponse<IServerResponse<IStage[]>>) => response.data);
}

/*
 * Удаление
 */
export async function deleteStreamStage(
    streamId: string,
    stageId: string,
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'DELETE',
        url: `${API_UAPI_URL}/streams/${streamId}/stages/${stageId}`,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/*
 * Запрос на получение стадии потока
 */
export async function getStreamStage(
    streamId: string,
    stageId: string,
): Promise<IServerResponse<IStage>> {
    return axiosInstance.request<IServerResponse<IStage>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams/${streamId}/stages/${stageId}`,
    })
        .then((response: AxiosResponse<IServerResponse<IStage>>) => response.data);
}

/*
 * Запрос на добавление стадии потока
 */
export async function addStreamStage(
    streamId: string,
    data: IStage,
): Promise<IServerResponse<IStageAdded>> {
    return axiosInstance.request<IServerResponse<IStageAdded>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams/${streamId}/stages`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IStageAdded>>) => response.data);
}

/*
 * Запрос на редактирование стадии потока
 */
export async function patchStreamStage(
    streamId: string,
    stageId: string,
    data: IStage,
): Promise<IServerResponse<object[]>> {
    return axiosInstance.request<IServerResponse<object[]>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams/${streamId}/stages/${stageId}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object[]>>) => response.data);
}

/*
 * Запрос на обновление сортировки
 */
export async function updateSortedStages(
    streamId: string,
    data: {
        sort: number[],
    },
): Promise<IServerResponse<object[]>> {
    return axiosInstance.request<IServerResponse<object[]>>({
        method: 'POST',
        url: `${API_UAPI_URL}/streams/${streamId}/stages/sort`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object[]>>) => response.data);
}

/*
 * Получение списка ендпоинтов
 */
export async function getEndpoints(
    params: {
        limit?: number,
        offset?: number,
    },
): Promise<IServerResponse<IEndpointList[]>> {
    return axiosInstance.request<IServerResponse<IEndpointList[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stream_endpoints`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IEndpointList[]>>) => response.data);
}

/*
 * Деталка ендпоинта
 */
export async function getEndpointById(
    endpointId: string,
    version?: {},
): Promise<IServerResponse<IEndpointItem>> {
    return axiosInstance.request<IServerResponse<IEndpointItem>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stream_endpoints/${endpointId}`,
        params: {
            ...version,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IEndpointItem>>) => response.data);
}

/*
 * Добавление ендпоинта
 */
export async function addEndpoint(
    data: {
        endpoint: string;
    },
): Promise<IServerResponse<IEndpointItem>> {
    return axiosInstance.request<IServerResponse<IEndpointItem>>({
        method: 'POST',
        url: `${API_UAPI_URL}/stream_endpoints`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IEndpointItem>>) => response.data);
}

/*
 * Получение условий ендпоинта
 */
export async function getEndpointConditions(
    endpointId: string,
): Promise<IServerResponse<ICondition[]>> {
    return axiosInstance.request<IServerResponse<ICondition[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stream_endpoints/${endpointId}/conditions`,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition[]>>) => response.data);
}

/*
 * Получение информации по одному условию ендпоинта
 */
export async function getEndpointCondition(
    endpointId: string,
    conditionId: string,
): Promise<IServerResponse<ICondition>> {
    return axiosInstance.request<IServerResponse<ICondition>>({
        method: 'GET',
        url: `${API_UAPI_URL}/stream_endpoints/${endpointId}/conditions/${conditionId}`,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition>>) => response.data);
}

/*
 * Создание условия у ендпоинта
 */
export async function addEndpointCondition(
    endpointId: string,
    data: ICondition,
): Promise<IServerResponse<ICondition>> {
    return axiosInstance.request<IServerResponse<ICondition>>({
        method: 'POST',
        url: `${API_UAPI_URL}/stream_endpoints/${endpointId}/conditions`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ICondition>>) => response.data);
}

/*
 * Редактирование условия у ендпоинта
 */
export async function editEndpointCondition(
    endpointId: string,
    conditionId: number,
    data: ICondition,
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'POST',
        url: `${API_UAPI_URL}/stream_endpoints/${endpointId}/conditions/${conditionId}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/*
 * Получение списка потоков
 */
export async function getStreamsList(
    params?: {
        query: string,
        limit?: number;
    },
): Promise<IServerResponse<IStream[]>> {
    return axiosInstance.request<IServerResponse<IStream[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/streams`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IStream[]>>) => response.data);
}

/*
 * Привязать поток к ендпоинту
 */
export async function plugStreamEndpoint(
    endpointId: string,
    data: {
        stream: string[],
    },
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'POST',
        url: `${API_UAPI_URL}/stream_endpoints/${endpointId}/plug`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/*
 * Отвязать поток от ендпоинта
 */
export async function unplugStreamEndpoint(
    endpointId: string,
    data: {
        stream: number[];
    },
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'POST',
        url: `${API_UAPI_URL}/stream_endpoints/${endpointId}/unplug`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/*
 * Удалить условие кампании
 */
export async function deleteCondition(
    conditionId: number,
): Promise<IServerResponse<void>> {
    return axiosInstance.request<IServerResponse<void>>({
        method: 'DELETE',
        url: `${API_UAPI_URL}/streams/conditions/${conditionId}`,
    })
        .then((response: AxiosResponse<IServerResponse<void>>) => response.data);
}

/*
 * Интерфейс для аналитики (Получить список полей (на обеих вкладках))
 */
export async function getAnalyticsApplication(): Promise<IServerResponse<IAnalyticField[]>> {
    return axiosInstance.request<IServerResponse<IAnalyticField[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/analytics/application`,
    })
        .then((response: AxiosResponse<IServerResponse<IAnalyticField[]>>) => response.data);
}

/*
 * Получить список заявок для рекламодателей/для партнеров
 */
export async function postAnalyticsApplication(
    type: string,
    data: {[key: string]: number | string | string[] | boolean},
): Promise<IServerResponse<IAnalyticApplication[]>> {
    return axiosInstance.request<IServerResponse<IAnalyticApplication[]>>({
        method: 'POST',
        url: `${API_UAPI_URL}/analytics/application/${type}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IAnalyticApplication[]>>) => response.data);
}

/*
 * Получение файла выгрузки
 */
export async function getFileReport(
    filename: string,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/file?filename=${filename}`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Получаем список файловых интеграций
 */
export async function getFileIntegrations(
    params: IApplicationsFilters,
): Promise<IServerResponse<IFileIntegrations[]>> {
    return axiosInstance.request<IServerResponse<IFileIntegrations[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/applications/import-tasks`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IFileIntegrations[]>>) => response.data);
}

export async function getReInitiate(
    params: any,
    data: any,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_UAPI_URL}/applications/reinitiate/list`,
        params,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

export async function getResendInitiate(
    params: any,
    data: any,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_UAPI_URL}/applications/reinitiate`,
        params,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Получаем список полей для выгрузки заявок
 */
export async function getFields(): Promise<IServerResponse<any[]>> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'GET',
        url: `${API_UAPI_URL}/report/applications/fields`,
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}
