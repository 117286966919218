
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TooltipButton from './TooltipButton.vue';

const invoice = namespace('invoiceModule');

@Component({
    components: {
        TooltipButton,
    },
})
export default class TimeoutRefreshBtn extends Vue {
    @invoice.Mutation('SET_TIMER') setTimerValue;
    @invoice.Mutation('CLEAR_TIMER') clearTimerValue;
    @invoice.Getter('GET_TIMER') getTimerValue;

    @Prop({ required: false, default: 0 }) id!: number;
    @Prop({ required: false, default: '' }) name!: string;
    @Prop({ required: false, default: 30 }) waitingTime!: number;

    timer: number = 0;
    interval: number = 0;

    get updateButton(): boolean {
        return this.timer > 0;
    }

    get updateMessage(): string {
        const seconds = `${this.timer >= 5 ? 'секунд' : this.timer === 1 ? 'секунду' : 'секунды'}`;
        const messageIfDisabled = `Повторно обновить ${this.name} можно через ${this.timer} ${seconds}`;
        return this.timer > 0 ? messageIfDisabled : `Обновить ${this.name}`;
    }

    update(): void {
        if (this.timer <= 0) {
            this.$emit('update');
        }
        this.timer = this.timer > 0 ? this.timer : this.waitingTime;
        this.setTimerValue({ id: this.id, value: new Date().getTime().toString() });
        this.interval = window.setInterval(() => {
            if (this.timer > 0) {
                this.timer--;
            } else {
                clearInterval(this.interval);
            }
        }, 1000);
    }

    created(): void {
        const timerForOrder = this.getTimerValue.find((timer) => timer.id === this.id);
        if (timerForOrder) {
            this.timer = this.waitingTime - Math.round((Date.now() - parseInt(timerForOrder.value, 10)) / 1000);
        }
        if (this.timer > 0) {
            this.update();
        }
    }

    beforeDestroy(): void {
        this.clearTimerValue();
        clearInterval(this.interval);
    }
}
