
import { Component, Mixins } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import SvgServicesReport from '@/assets/icons/nav-bar/services-report.svg';
import { IFilter } from '@/types';
import Filters from '@/components/base/filters/Filters.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import { getLegalEntitySearch } from '@/api/revenue';
import { translateServiceType } from '@/utils/translate';
import { getLegalEntitySearchGuid, getServiceStatsSummary } from '@/api/orgstats';
import { IServiceStatsSummary } from '@/api/types/orgstats';
import { showServerError } from '@/utils';
import FilterGuid from '@/mixins/filterGuid';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        SvgServicesReport,
        StatsCard,
        Filters,
    },
})
export default class ServicesStatsSummary extends Mixins(FilterGuid) {

    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;

    dataTable: IServiceStatsSummary[] = [];

    isNotFound = false;

    isParseRouteUrl = false;

    headers = [
        {
            text: 'Тип услуги',
            sortable: false,
            value: 'service_type',
            align: 'center',
            class: 'font-weight-bold',
        },
        {
            text: 'Количество услуг',
            sortable: false,
            value: 'total_services',
            align: 'center',
            class: 'font-weight-bold',
        },
        {
            text: 'Количество счетов',
            sortable: false,
            value: 'total_invoices',
            align: 'center',
            class: 'font-weight-bold',
        },
        {
            text: 'Общая сумма услуг',
            sortable: false,
            value: 'total_amount',
            align: 'right',
            class: 'font-weight-bold',
        },
    ];

    settings = {
        title: 'Отчёт по партнёрам',
        loading: false,
    };

    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'org_guids',
            label: 'Организация',
            apiMethod: getLegalEntitySearch,
            itemValue: 'external_id',
            itemText: 'name',
            select: [],
            multiple: true,
            apiParseUrl: false,
            isErrorIcon: true,
            type: 'string',
        }),
        new filterClass.Datepicker({
            id: 'period',
            label: 'Период',
            pickerType: 'month',
            select: [],
            isErrorIcon: true,
            range: true,
        }),
    ];

    translateServiceType = translateServiceType;

    clearFilters(): void {
        this.isNotFound = false;
        this.dataTable = [];
        this.setFiltersParamsObject({});
        this.$emit('show', this.isNotFound);
    }

    created(): void {
        this.setDefaultFiltersParams({});
        this.setFiltersParamsObject({});
    }

    mounted(): void {
        this.isParseRouteUrl = Object.keys(this.$route.query).length > 0;
        if (this.isParseRouteUrl) {
            this.getServiceStatsSummary();
        }
    }

    async getServiceStatsSummary(): Promise<void> {
        this.settings.loading = true;
        try {
            if (this.isParseRouteUrl) {
                await this.getFilterGuidItems(this.filters, 'org_guids', getLegalEntitySearchGuid);
            }
            this.dataTable = [];
            this.dataTable = await getServiceStatsSummary(this.getFiltersParamsObject);
            this.isNotFound = this.dataTable.length <= 0;
            this.$emit('show', !this.isNotFound);
        } catch (err) {
            showServerError(err, 'Отчет по услуге не загружен');
        } finally {
            this.settings.loading = false;
        }
    }
}
