
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import { IFilter, TableHeader } from "@/types";
import { translateGoalStatus, translateOffersStatus, translatePlannedChangeStatus } from "@/utils/translate";
import { IPlannedChangesItem } from "@/api/types/offers";
import NotesTooltip from "@/components/base/NotesTooltip.vue";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import { getFormatDate } from "@/utils/formatDates";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import { filterClass } from "@/services/filters/filterClass";

@Component({
    components: { SelectDesign, EditModeTextField, BaseSelect, ViewDateTime, DateTimePickerNew, NotesTooltip, TableWithFrontPaginationDesign, BaseCard },
})

export default class OfferCardPlannedChanges extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @PropSync('isLoading') isLoadingSync!: boolean;

    statusItems = [{ id: 'active' }, { id: 'paused' }, { id: 'deleted' }, { id: 'pending' }];
    editId: number | null = null;
    cashedItem = {} as IPlannedChangesItem;
    status: boolean | '' = '';

    filterStatus: IFilter = new filterClass.Select({
        id: 'status',
        label: 'Статус',
        itemValue: 'value',
        itemText: 'name',
        items: [true, false],
        select: '',
        callback: this.changeStatus,
        clearable: true,
        remove: () => this.status = '',
        translate: (i) => translatePlannedChangeStatus(i).text,
    });

    headers: TableHeader[] = [
        { text: 'Когда', sortable: false, value: 'action_date', width: '125' },
        { text: 'Изменение', sortable: false, value: 'changes_text' },
        { text: '', sortable: false, value: 'actions', align: 'center' },
    ];

    uniqueData = [
        { slot: 'item.action_date' },
        { slot: 'item.changes_text' },
    ];

    created(): void {
        this.status = false;
        this.filterStatus.select = false;
    }

    get filteredItems(): any {
        if (this.status === '') return this.offer.planned_changes;
        return this.offer.planned_changes.filter(i => i.is_done === this.status);
    }

    isSelect(item: IPlannedChangesItem): boolean {
        return ['revenue.type', 'payout.type', 'goal.status', 'offer.status'].includes(item.field);
    }

    getSelectItems(item: IPlannedChangesItem): { id: string | boolean, text: string }[] {
        const items = {
            goalStatus: [
                { id: true, text: translateGoalStatus(true).text },
                { id: false, text: translateGoalStatus(false).text },
            ],
            goalTypeOfResource: [
                { id: 'fixed', text: 'Фикс' },
                { id: 'percent', text: 'Процент' },
            ],
            offerStatus: this.statusItems.map(i => {
                return { id: i.id, text: translateOffersStatus(i.id).text };
            }),
        };

        if (['revenue.type', 'payout.type'].includes(item.field)) return items.goalTypeOfResource;
        if (item.field === 'goal.status') return items.goalStatus;
        if (item.field === 'offer.status') return items.offerStatus;
        return [{ id: '', text: '' }];
    }

    getPlannedChangeText(item: IPlannedChangesItem): string {
        if (['revenue.type', 'payout.type'].includes(item.field)) return item.value === 'fixed' ? '"Фикс"' : '"Процент"';
        if (item.field === 'goal.status') return `"${translateGoalStatus(item.value).text}"`;
        if (item.field === 'offer.status') return `"${translateOffersStatus(item.value).text}"`;
        return item.value;
    }

    disabledActions(item: IPlannedChangesItem): boolean {
        return this.editId !== item.id && this.cashedItem && Object.keys(this.cashedItem)?.length > 0;
    }

    cancelEdit(item: IPlannedChangesItem): void {
        for (const key in item) {
            item[key] = this.cashedItem[key];
        }
        this.clearEditId();
    }
    deleteItem(item: IPlannedChangesItem): void {
        this.offer.deletePlannedChanges(item.id);
        this.clearEditId();
    }
    editItem(item: IPlannedChangesItem): void {
        this.editId = item.id;
        this.cashedItem = { ...item };
    }
    saveItem(item: IPlannedChangesItem): void {
        if (JSON.stringify(item) === JSON.stringify(this.cashedItem)) {
            this.clearEditId();
            return;
        }
        const action_date = getFormatDate(item.action_date, 'yyyy-MM-dd HH:mm');
        const dto = { action_date, value: item.value };
        this.offer.patchPlannedChanges(item.id, dto);
        this.clearEditId();
    }
    clearEditId(): void {
        this.editId = null;
        this.cashedItem = {} as IPlannedChangesItem;
    }

    changeStatus(e: IFilter): void {
        this.status = e.select;
    }
}

