
import { Vue, Component, Prop } from "vue-property-decorator";
import { OfferSingle } from "@/services/offers/OfferSingle";
import BaseCard from "@/components/base/BaseCard.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getAffiliateSearch } from "@/api/user";
import { getBufferAccounts } from "@/api/offers";
import { showNotification, showServerError } from "@/utils";
import { getAffiliatePlatforms, getOfferCreatives, getOfferDefaultCreative } from "@/api/leadgid-ord";
import { ICreative, IDefaultCreative, IPlatform } from "@/api/types/leadgid-ord";
import SvgLink from "@/assets/icons/link.svg";
import SvgCopy from "@/assets/icons/copy.svg";
import TooltipButton from "@/components/base/buttons/TooltipButton.vue";

@Component({
    components: { TooltipButton, BaseAutocomplete, BaseSelect, BaseCard, SvgLink, SvgCopy },
})

export default class OfferLinksConstructor extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    platformsList: IPlatform[] = [];
    defaultCreative: IDefaultCreative | null = null;
    creativesList: ICreative[] = [];

    domain: string = '';
    url: number | null = null;
    affiliate: number | null = null;
    hash: string = '';
    platform: number | null = null;
    creative: number | null = null;
    erid: string = '';
    subs: string[] = [];
    isTestMode: boolean = false;

    get getBaseOptions(): any[] {
        return [
            {
                id: 'domain',
                text: 'Домен',
                type: 'select',
                items: this.offer.tracking_domains || [],
                itemValue: 'domain',
                itemText: 'domain',
            },
            {
                id: 'url',
                text: 'URL',
                type: 'select',
                items: this.getUrls,
                itemValue: 'legacy_id',
                itemText: 'name',
            },
            {
                text: 'Партнёр',
                type: 'autocomplete',
                apiMethod: getAffiliateSearch,
                template: (i) => this.getTemplate(i),
                itemValue: 'id',
                isDisabled: this.isTestMode,
            },
            {
                id: 'platform',
                text: 'Площадка',
                type: 'select',
                items: this.platformsList,
                itemValue: 'id',
                itemText: 'name',
                isClearable: true,
            },
            {
                id: 'creative',
                text: 'Креатив',
                type: 'select',
                items: [this.defaultCreative, ...this.creativesList],
                itemValue: 'id',
                itemText: 'label',
                isDisabled: !this.offer.ready_for_erir,
            },
            { text: 'ERID', type: 'erid' },
        ];
    }

    get getUrls(): any[] {
        const def = { name: 'URL оффера по-умолчанию', preview: this.offer.default_url, legacy_id: this.offer.legacy_id };
        return [def, ...this.offer.additional_urls!];
    }

    get getLink(): string {
        const url = (this.url && this.url === this.offer.legacy_id) ? '' : `url_id=${this.url}`;
        const affUrl = this.hash ? `click/${this.hash}` : 'aff_c';
        const affParams = this.hash ? '' : (this.affiliate || this.isTestMode) ? `aff_id=${this.isTestMode ? 1 : this.affiliate}&offer_id=${this.offer.legacy_id}` : '';
        const platform = this.platform ? `p=${this.platform}` : '';
        const erid = this.erid ? `erid=${this.erid}` : '';
        const subs = this.subs.map((i, index) => i ? index ? `aff_sub${index + 1}=${i}` : `aff_sub=${i}` : undefined);
        const params = [url, affParams, platform, erid, ...subs].filter(i => i).join('&');
        const link = `https://${this.domain}/${affUrl}${params.length > 0 ? '?' : ''}${params}`;
        return (this.affiliate || this.isTestMode) ? link : '';
    }

    get getCopyText(): string {
        return (this.affiliate || this.isTestMode) ? '' : 'Выберите партнёра';
    }

    getTemplate(i: any): string {
        const full = `${i.id} ${i.first_name} ${i.last_name} ${i.email}`;
        const short = `${i.id} ${i.email}`;
        return this.$vuetify.breakpoint.width <= 576 ? short : full;
    }

    mounted(): void {
        this.setDefaults();
    }

    setDefaults(): void {
        this.domain = this.offer.tracking_domains![0].domain;
        this.url = this.offer.legacy_id!;
    }

    selectOption(item: any, value: string | number): void {
        if (item?.id === 'creative') {
            const i = item.items.find((i: any) => i ? i[item.itemValue] === value : false);
            this.erid = i?.erid;
        }
    }

    async copyUrl(): Promise<void> {
        if (!!this.affiliate || this.isTestMode) {
            try {
                const link = this.getLink;
                await navigator.clipboard.writeText(link);
                showNotification('Ссылка скопирована');
            } catch (err) {
                console.error(err);
            }
        }
    }

    async selectAffiliate(id: number): Promise<void> {
        this.affiliate = id ? id : null;
        if (!id) return;
        await Promise.all([
            this.checkConnectToBuffer(),
            this.getPlatforms(),
            this.getDefaultCreative(),
            this.getCreatives(),
        ]);

    }

    async checkConnectToBuffer(): Promise<void> {
        try {
            const params = { affiliate_id: this.affiliate, offer_legacy_id: this.offer.legacy_id };
            const { data } = await getBufferAccounts(params);
            this.hash = data.length > 0 ? data[0].hash! : '';
        } catch (err) {
            showServerError(err, 'Ошибка загрузки буфера');
        }
    }

    async getPlatforms(): Promise<void> {
        try {
            const params = { affiliate_id: this.affiliate, with_leadcore: false, is_deleted: false, status: 'approved' };
            const { data } = await getAffiliatePlatforms(params);
            this.platformsList = data;
        } catch (err) {
            showServerError(err, 'Ошибка загрузки платформ');
        }
    }

    async getDefaultCreative(): Promise<void> {
        if (!this.offer.ready_for_erir) return;
        try {
            const params = { offer_id: this.offer.legacy_id };
            const { data } = await getOfferDefaultCreative(params);
            this.defaultCreative = data;
            this.creative = data.id;
            this.erid = data.erid;
        } catch (err) {
            showServerError(err, 'Ошибка загрузки креатива по умолчанию');
        }
    }

    async getCreatives(): Promise<void> {
        try {
            const params = { has_erid: true, is_deleted: false, offer_id: this.offer.legacy_id, affiliate_id: this.affiliate  };
            const { data } = await getOfferCreatives(params);
            this.creativesList = data;
        } catch (err) {
            showServerError(err, 'Ошибка загрузки креативов');
        }
    }
}

