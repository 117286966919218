
import { Component, Vue, Prop } from 'vue-property-decorator';
import Status from "@/components/base/Status.vue";
import { IStatus, ITableStatus } from "@/types";

@Component({
    components: { Status },
})

export default class TdStatus extends Vue {
    @Prop({ required: true }) item!: Object | any;
    @Prop({ required: true }) status!: ITableStatus;

    get getStatus(): IStatus {
        const status = this.status.sup ?
            this.status.addSup ?
                this.item[this.status.sup][this.status.addSup][this.status.key] :
                this.item[this.status.sup][this.status.key] :
            this.item[this.status.key];
        return this.status.translate(status, this.item);
    }
}
