
import { Component, Vue, Prop } from 'vue-property-decorator';
import FormModal from '@/components/base/FormModal.vue';
import PaginationRight from '@/components/base/table/PaginationRight.vue';
import { IMasterAccount } from '@/api/types/org-cabinets-backend';
import ContactorsTable from '@/components/orgstats/masterAccount/ContactorsTable.vue';

@Component({
    components: {
        ContactorsTable,
        FormModal,
        PaginationRight,
    },
})

export default class MasterAccountLegalEntity extends Vue {
    @Prop({ required: true }) readonly masterAccount!: IMasterAccount;
}
