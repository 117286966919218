
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BasicInformation from '@/components/revenue/statTask/report/BasicInformation.vue';
import ConversionsChange from '@/components/revenue/statTask/report/ConversionsChange.vue';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { IOfferService } from '@/api/types/revenue';

const revenue = namespace('revenueModule');

@Component({
    components: {
        BasicInformation,
        ConversionsChange,
    },
})
export default class FileBasicInformation extends Vue {
    @revenue.Getter('GET_FILE_REPORT') fileReport;
    @revenue.Getter('GET_GENERAL_REPORT_OFFERS') generalReportOffers;

    @Prop({ type: Number, required: true }) reportId!: number;
    @Prop({ default: null }) report!: any;
    @Prop({ default: true }) isGetData!: boolean;

    get period(): any {
        return this.report.hasOwnProperty('period') && this.report.period![0]
            ? getFormatDatesForDatepicker(this.report.period[0].date, 'month')
            : '';
    }

    // получаем список офферов для по файлу, или список офферов по всем файлам для общего отчета
    get offerServices(): IOfferService[] {
        return this.isFileReport ? this.fileReport.offer_services : this.generalReportOffers;
    }

    // проверяем является ли отчетом по всем файлам
    get isFileReport(): boolean {
        return this.$route.name === 'file-report';
    }
}
