
import { Component, Vue, Prop } from 'vue-property-decorator';
import OfferCardMain from "@/components/offers/offer/OfferCardMain.vue";
import OfferCardLogo from "@/components/offers/offer/OfferCardLogo.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import OfferCardCategory from "@/components/offers/offer/OfferCardCategory.vue";
import OfferCardGoals from "@/components/offers/offer/OfferCardGoals.vue";
import OfferCardMetrics from "@/components/offers/offer/OfferCardMetrics.vue";
import OfferCardSettings from "@/components/offers/offer/OfferCardSettings.vue";
import OfferCardTracking from "@/components/offers/offer/OfferCardTracking.vue";
import OfferCardNotes from "@/components/offers/offer/OfferCardNotes.vue";
import OfferCardDescription from "@/components/offers/offer/OfferCardDescription.vue";
import Loader from "@/components/base/Loader.vue";
import OfferCardTargeting from "@/components/offers/offer/OfferCardTargeting.vue";
import OfferCardPlannedChanges from "@/components/offers/offer/OfferCardPlannedChanges.vue";
import OfferCardErid from "@/components/offers/offer/OfferCardErid.vue";

@Component({
    components: {
        OfferCardPlannedChanges,
        OfferCardTargeting,
        OfferCardLogo,
        Loader,
        OfferCardDescription,
        OfferCardNotes,
        OfferCardTracking,
        OfferCardSettings,
        OfferCardMetrics,
        OfferCardGoals,
        OfferCardCategory,
        OfferCardMain,
        OfferCardErid,
    },
})
export default class OfferMain extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    isLoading: boolean = false;

    get isOfferEmpty(): boolean {
        return Object.keys(this.offer)?.length <= 0;
    }

    get isEridExist(): boolean {
        return !!this.offer.erid.id;
    }
}
