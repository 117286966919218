
import { Component, Vue } from 'vue-property-decorator';
import FormModal from "@/components/base/FormModal.vue";
import { namespace } from "vuex-class";
import { USER_ROLES } from "@/mappings/user-roles";
import { showServerError } from "@/utils";
import { getGlobalConfig, patchGlobalConfig } from "@/api/payout";
const auth = namespace('authModule');

@Component({
    components: {
        FormModal,
    },
})
export default class ChangeMethodSettings extends Vue {
    @auth.Getter('GET_USER') user;

    showModal = false;
    isLimited = '1';

    get textModal(): string {
        return this.isLimited === '0' ? 'Ограничить изменения? Партнёры не смогут добавлять новые способы выплат, а также назначать способы выплат по умолчанию. У сотрудников такая возможность останется.' : 'Снять ограничения?';
    }

    get nameSaveBtn(): string {
        return this.isLimited === '0' ? 'Ограничить' : 'Да';
    }

    get isShowSettings(): boolean {
        return this.user?.roles!.includes(USER_ROLES.SUPERVISOR) || this.user.roles!.includes(USER_ROLES.FINANCIER) || this.user.roles!.includes(USER_ROLES.ADMIN);
    }

    created(): void {
        this.getLimited();
    }

    changeIsLimited(): void {
        this.isLimited = this.isLimited === '0' ? '1' : '0';
    }

    cancel(): void {
        this.changeIsLimited();
        this.showModal = false;
    }

    async getLimited(): Promise<void> {
        try {
            const { value } = await getGlobalConfig('is_method_editing_allowed');
            this.isLimited = value;
        } catch (err) {
            showServerError(err, 'Ограничения изменений не загружены');
        }
    }

    async saveLimited(): Promise<void> {
        try {
            await patchGlobalConfig('is_method_editing_allowed', { value: this.isLimited });
        } catch (err) {
            showServerError(err, 'Ошибка при сохранении ограничений изменений');
            this.changeIsLimited();
        }
        finally {
            this.showModal = false;
        }
    }
}
