
import { Component, Vue, Prop, PropSync, Inject } from 'vue-property-decorator';
import RequiredFieldsList from '@/components/affiliate/employee/RequiredFieldsList.vue';
import { showServerError } from '@/utils';
import { addPaymentLogo, getOneEmployeeMethod } from '@/api/payout';
import SvgReload from "@/assets/icons/reload.svg";
import SvgTrash from "@/assets/icons/trash.svg";
import SvgUpdate from "@/assets/icons/update-dark.svg";

@Component({
    components: { SvgUpdate, SvgReload, SvgTrash, RequiredFieldsList },
})

export default class PaymentLogo extends Vue {
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String, default: '' }) image!: string;
    @Prop({ type: String, default: '' }) theme!: string;
    @Prop({ type: String, default: '' }) id!: string;
    @PropSync('edit', { required: false, default: false }) isEdit!: boolean;
    @Inject() readonly getEmployeeMethod!: any;
    logoFile: any = {};
    addFileLoader: boolean = false;

    async getFiles(action?: string): Promise<void> {
        try {
            if (action === 'delete') {
                await addPaymentLogo('', this.id, this.theme);
                await this.getEmployeeMethod();
                this.image = '';
            } else {
                const input = (this.$refs[this.theme] as Vue).$el as HTMLElement;
                if (input) {
                    input.blur();
                }
                if (this.logoFile) {
                    this.addFileLoader = true;
                    await addPaymentLogo(this.logoFile, this.id, this.theme);
                    const updateMethod = await getOneEmployeeMethod(this.id);
                    this.image = updateMethod.images[this.theme];
                }
            }
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        } finally {
            this.addFileLoader = false;
        }
    }
}
