import { BaseFilter } from "@/services/filters/classes/base.filter";
import { ConstructorType } from "@/services/filters/classes/types";

export enum COMPARE_OPERATORS {
    GT = 'gt',
    GTE = 'gte',
    LT = 'lt',
    LTE = 'lte',
    BETWEEN = 'between',
}

export class CompareFilter extends BaseFilter {
    constructor(config: ConstructorType<CompareFilter, 'compare'>) {
        super(config);
        this.compare = true;
        this.operator = COMPARE_OPERATORS.GT;
    }

    clear(): void {
        this.select = [];
        this.operator = COMPARE_OPERATORS.GT;
    }

    parseQueryAndGetChanges(query: any, currentQueryKey: any): any {
        const queryValue = query[currentQueryKey];

        if (Array.isArray(queryValue) && queryValue.length === 2) {
            this.select = queryValue;
            this.operator = COMPARE_OPERATORS.BETWEEN;
        } else {
            this.select = [queryValue];
            this.operator = query[`${currentQueryKey}_operator`];
        }

        return { [this.id]: this.select };
    }
}
