import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';
import { IFilter } from "@/types";

export class ComboboxArrayFilter extends BaseFilter {
    array? = true;
    supValue?: string;
    multiPaste?: boolean;
    type?: 'number' | 'string';

    constructor(config: ConstructorType<ComboboxArrayFilter, any>) {
        super(config);
        this.select = Array.isArray(config.select)
            ? config.select
            : config.select
                ? [config.select]
                : [];
        this.supValue = config.supValue ?? '';
        this.multiPaste = config.multiPaste ?? false;
        this.type = config.type;
    }

    getValueForSubmit(filter: IFilter = this): Record<string, any> {
        if (filter.select.hasOwnProperty('length') && !filter.select.length) {
            return {};
        }
        return { [filter.id]: filter.type=== 'number' ? filter.select.map(i => +i) : filter.select };
    }
}
