import { render, staticRenderFns } from "./Ticket.vue?vue&type=template&id=cca98634&"
import script from "./Ticket.vue?vue&type=script&lang=ts&"
export * from "./Ticket.vue?vue&type=script&lang=ts&"
import style0 from "@/style/components/tickets/ticket.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-872bafd3cb/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-753a6b6da2.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports