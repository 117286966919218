
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import Status from '@/components/base/Status.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import MultiButton from '@/components/base/buttons/MultiButton.vue';
import CardMaterial from '@/components/base/CardMaterial.vue';
import { getFormatDate } from '@/utils/formatDates';
import { eventBus } from '@/eventbus';
import PaginationRight from '@/components/base/table/PaginationRight.vue';
import { INettingItem, IOfferService } from '@/api/types/revenue';
import Pagination from '@/components/base/table/Pagination.vue';
import { PAGINATION_RIGHT_PER_PAGE } from '@/configs/global';

@Component({
    components: {
        PaginationRight,
        Status,
        TooltipButton,
        MultiButton,
        CardMaterial,
        Pagination,
    },
})

export default class OfferServicesSelectTable extends Vue {
    get editingMode(): boolean {
        return this.getEditingMode;
    }

    get getItemsWithoutConversions(): IOfferService[] {
        return this.$props.items.filter((item) => (item.conversions_total ? item.conversions_total === 0 : item));
    }

    get getItemsDeletedOffer(): IOfferService[] {
        return this.$props.items.filter((item) => (!item.goal_status));
    }

    get getItems(): IOfferService[] {
        if (this.showAllItems) {
            return this.showAllDeletedOfferItems || this.$props.type === 'netting' ?
                this.$props.items : this.$props.items.filter((item) => item.goal_status);
        } else {
            return this.showAllDeletedOfferItems ?
                this.$props.items.filter((item) => item.conversions_total && item.conversions_total > 0) :
                this.$props.items.filter((item) => item.conversions_total &&
                    item.conversions_total > 0 && item.goal_status);
        }
    }

    get textConversionBtn(): string {
        return this.showAllItems ? 'Скрыть офферы без конверсий' : 'Показать офферы без конверсий';
    }

    get textDeletedOfferBtn(): string {
        return this.showAllDeletedOfferItems ? 'Скрыть неактивные цели' : 'Показать неактивные цели';
    }

    get isShowConversionBtn(): boolean {
        return this.$props.type !== 'netting' && this.$props.items.length > 0 &&
            this.getItemsWithoutConversions.length > 0;
    }

    get isShowDeletedOfferBtn(): boolean {
        return this.$props.items.length > 0 &&
            this.getItemsDeletedOffer.length > 0 &&
            this.editingMode &&
            this.$props.type !== 'netting';
    }

    get totalItemsOfTable(): number {
        if (this.getItems.length > 0) {
            this.itemsPerPageSelect[2].value = this.getItems.length;
            return this.getItems.length;
        }
        this.itemsPerPageSelect[2].value = 0;
        return 0;
    }

    // Элементы, добавленные в таблицу
    @Prop({ required: false }) items!: IOfferService[];
    // Таблицу показываем, если нет загрузки
    @Prop({ required: false }) loading!: boolean;
    // Цвет заголовков таблицы
    @Prop({ required: false, default: 'primary' }) activeColor!: string;
    @Prop({ required: true }) headers!: [];
    @Prop({ required: false }) showItems!: boolean;
    @Prop({ default: false }) isPaginationServer!: boolean;
    @Prop({ required: false }) type!: string;
    @Prop({ required: false }) totalElementDataTable!: number;
    @Prop({ default: () => [] }) statuses!: [];
    @Prop({ type: Array, default: () => [] }) uniqueData!: Array<{}>;

    @Getter('GET_EDITING_MODE') getEditingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    showAllItems = false;
    showAllDeletedOfferItems = false;
    // for pagination
    pageCount = 0;
    itemsPerPage = PAGINATION_RIGHT_PER_PAGE;
    itemsPerPageSelect: any[] = [
        { value: PAGINATION_RIGHT_PER_PAGE, text: PAGINATION_RIGHT_PER_PAGE.toString() },
        { value: 20, text: '20' },
        { value: 30, text: 'Все' },
    ];

    // встроенный Vuetify объект
    pagination: {page: number} = {
        page: 1,
    };

    getFormatDate = getFormatDate;

    headerNumberFormat = [
        { name: 'goal_revenue_flat', type: 'money' },
        { name: 'offer_rate', type: 'money' },
        { name: 'amount', type: 'money' },
        { name: 'conversions_total', type: 'number' },
        { name: 'conversions_approved', type: 'number' },
    ];

    addServiceInTable(service: any): void {
        if (this.$props.isPaginationServer) {
            service.disabled = true;
        }
        if (this.$props.type === 'netting') {
            this.$emit('add-offer-service', service as INettingItem);
        } else {
            eventBus.$emit('add-offer-service', service as IOfferService);
        }

        this.setUnsavedDataState(true);
    }

    changeVisibilityConversion(): void {
        this.showAllItems = !this.showAllItems;
    }

    changeVisibilityDeletedOffer(): void {
        this.showAllDeletedOfferItems = !this.showAllDeletedOfferItems;
    }

    getRate(data: IOfferService[]): void {
        for (const item of data) {
            item.offer_rate = item.goal_revenue_type === 'cpa_flat' ? item.goal_revenue_flat : 0;
        }
    }

    changeItems(evt: string): void {
        this.itemsPerPage = parseInt(evt, 10);
    }

    beforeUpdate(): void {
        if (!this.showItems) {
            this.getRate(this.$props.items);
        }
    }

    created(): void {
        if (this.$props.type === 'netting') {
            this.showAllItems = true;
        }

        if (this.showItems) {
            this.showAllItems = true;
        } else {
            this.getRate(this.$props.items);
        }

        if (this.$props.isPaginationServer) {
            this.$parent?.$on('go-to-first-page', () => {
                this.pagination.page = 1;
            });
        }
    }

    getPaginationData(offset: number, limit: number, isChangeLimit?: boolean): void {
        this.itemsPerPage = limit;
        if (isChangeLimit) {
            this.$emit('get-page-data', 0, limit, isChangeLimit);
        } else {
            this.$emit('get-page-data', offset, limit);
        }
    }
}
