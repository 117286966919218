import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { showServerError } from '@/utils';

// Создаём константы для сторов разных модулей
const auth = namespace('authModule');

@Component
export default class Logout extends Vue {
    @auth.Action('AUTHORIZATION_LOGOUT_METHOD') logoutAuth;

    logout(): void {
        this.logoutAuth()
            .then(() => this.$router.push('/sign-in'))
            .catch((err) => showServerError(err, 'Ошибка авторизации'));
    }
}
