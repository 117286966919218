
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import FormModal from "@/components/base/FormModal.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import ImageNotesFile from "@/components/base/ImageNotesFile.vue";
import ImageInput from "@/components/base/form/ImageInput.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import NotesTooltip from "@/components/base/NotesTooltip.vue";
import { IBaseAutocomplete, TableHeader } from "@/types";
import TooltipButton from "@/components/base/buttons/TooltipButton.vue";
import SvgTrash from "@/assets/icons/trash.svg";
import SvgEdit from "@/assets/icons/tooltip-edit.svg";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import Loader from "@/components/base/Loader.vue";
import { IContractor, IContractorAddress } from "@/api/types/revenue";
import { Advertiser } from "@/services/advertiser/Advertiser";
import { getAllContractors } from "@/api/revenue";
import AddressChip from "@/components/revenue/advertiser/AddressChip.vue";

@Component({
    components: {
        AddressChip,
        Loader,
        BaseAutocomplete,
        SvgTrash,
        SvgEdit,
        TooltipButton,
        NotesTooltip,
        TableWithFrontPaginationDesign, ImageInput, ImageNotesFile, TrashBtn, FormModal, BaseCard },
})

export default class AdvertiserContractors extends Vue {
    @Prop({ required: true }) advertiser!: Advertiser;

    isLoading: boolean = false;

    isShowAddressModal: boolean = false;
    isEditAddress: boolean = false;
    editableContractor = {} as { id: number; name: string; };
    addressForm = [{ text: 'Название', key: 'name' }, { text: 'Адрес', key: 'address' }];
    newAddress = {} as IContractorAddress;

    isShowModal: boolean = false;
    newContractor: string = '';

    uniqueData = [
        { slot: 'item.is_default' },
        { slot: 'item.address' },
    ];

    headers: TableHeader[] = [
        { text: 'По умолч.', sortable: false, value: 'is_default', width: '75px', align: 'center' },
        { text: 'Название', sortable: false, value: 'name' },
        { text: 'ИНН', sortable: false, value: 'inn' },
        { text: 'Адреса', sortable: false, value: 'address' },
        { text: '', sortable: false, value: 'actions', align: 'center' },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: false,
            callback: async (item: any): Promise<void> => this.deleteContractor(item.external_id),
        },
        {
            title: 'Добавить адрес',
            color: '#1B1B1D',
            icon: 'SvgEdit',
            callback: (item: any): void => this.openAddressModal(item),
        },
    ];

    get getItems(): IContractor[] {
        return this.advertiser.contractors || [];
    }

    get isDisableSaveNewAddress(): boolean {
        const { name, address } = this.newAddress;
        return !name || !address;
    }

    closeModal(): void {
        this.isShowModal = false;
        this.isLoading = false;
    }

    changeContractor(id: number): void {
        this.newContractor = id.toString();
    }

    async setDefaultContractor(item: IContractor): Promise<void> {
        this.isLoading = true;
        await this.advertiser.setDefaultContractor(item);
        this.isLoading = false;
    }

    async addContractor(): Promise<void> {
        this.isLoading = true;
        await this.advertiser.addContractor(this.newContractor);
        this.closeModal();
    }

    async deleteContractor(contractor_id: string): Promise<void> {
        this.isLoading = true;
        await this.advertiser.deleteContractor(contractor_id);
        this.isLoading = false;
    }

    get getContractorData(): IBaseAutocomplete {
        return {
            apiMethod: getAllContractors,
            errorText: 'Контрагент не найден',
            placeholder: 'Введите контрагента',
            itemValue: 'external_id',
            template: (i: IContractor) => i.name,
        };
    }

    async deleteAddress(contractorId: number, addressId: number): Promise<void> {
        this.isLoading = true;
        await this.advertiser.deleteAddress(contractorId, addressId);
        this.isLoading = false;
    }

    setEditableDataToModal( item: IContractor, addressData: IContractorAddress): void {
        const { name, address, id } = addressData;
        this.newAddress.name = name;
        this.newAddress.address = address;
        this.newAddress.id = id;
        this.newAddress.id = id;
        this.isEditAddress = true;
        this.openAddressModal(item);
    }

    openAddressModal(item: IContractor): void {
        const { id, name } = item;
        this.editableContractor = { id, name };
        this.isShowAddressModal = true;
    }

    hideAddressModal(): void {
        this.isShowAddressModal = false;
        this.isEditAddress = false;
        this.newAddress.address = '';
        this.newAddress.name = '';
        this.newAddress.id = undefined;
    }

    async editOrAddAddress(): Promise<void> {
        if (!this.editableContractor.id) {
            return;
        }
        this.isLoading = true;
        await this.advertiser.addOrEditAdvertiserAddress(this.editableContractor.id, this.newAddress, this.isEditAddress);
        this.hideAddressModal();
        this.isLoading = false;
    }
}

