import { Component, Vue } from 'vue-property-decorator';
import FiltersBase from '@/components/base/filters/FiltersBase.vue';
import { getLastDayOfMonth } from '@/utils/formatDates';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { IFilter } from '@/types';

@Component({
    components: {
        FiltersBase,
    },
})
export default class Report extends Vue {
    filters: IFilter[] = [];

    loading = false;

    total = 0;

    limit = PAGINATION_PER_PAGE;

    pagination = {
        page: 1,
    };

    initialData: any[] = [];

    filteredData: any[] = [];

    filterDatesIds = ['updated', 'created', 'lead_date'];

    uniqueData = [
        {
            slot: 'item.conversion.payout',
            template: ({ conversion }: any): string => (Number(conversion.payout) / 100)
                .toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        },
        {
            slot: 'item.conversion.revenue',
            template: ({ conversion }: any): string => (Number(conversion.revenue) / 100)
                .toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        },
        {
            slot: 'item.profit',
            template: ({ conversion }: any): string => (Number(conversion.revenue / 100) - Number(conversion.payout / 100))
                .toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        },
        {
            slot: 'item.offer',
            template: ({ conversion }: any): string => `${conversion.offer_id}`,
        },
    ];

    getLastDayOfMonth = getLastDayOfMonth;

    fillFiltersItems(): void {
        this.filters.forEach((fl) => {
            if (fl.hasOptions) {
                // flatMap() избавляет от вложенного массива, к-ый получается
                // в результате `return el.conversions.map`
                fl.items = this.initialData.flatMap((el) => {
                    if (el.fl.id) return el[fl.id];
                    if (el.conversion) return el.conversion[fl.id];
                    if (el.conversions) return el.conversions.map((conv) => conv.conversion[fl.id]);
                });
            }
        });
    }

    submitFilters(): void {
        this.loading = true;
        const activeFilters = {};
        this.filters.forEach((fl) => {
            if ((fl.select || typeof (fl.select) === 'boolean') && !fl.datepicker) {
                activeFilters[fl.id] = fl.select;
            }
        });

        const currentHits = this.filterDatesIds.reduce((acc, id) => {
            const filter = this.filters.find((f) => f.id === id);
            if (filter?.select.length) acc += 1;
            return acc;
        }, Object.keys(activeFilters).length);

        // нужно скопировать исходный массив, тк дальше приходится менять
        // вложенный массив с конверсиями
        const copyInitialData = JSON.parse(JSON.stringify(this.initialData));
        this.filteredData = copyInitialData.filter((el) => {
            let hits = 0;

            for (const key in activeFilters) {
                if (el[key] !== undefined && el[key] === activeFilters[key]) {
                    hits++;
                } else if (el.conversion && el.conversion[key] === activeFilters[key]) {
                    hits++;
                } else if (el.conversions) {
                    el.conversions = el.conversions.filter((conv) => conv.conversion[key] === activeFilters[key]);
                    if (el.conversions.length) {
                        hits++;
                    }
                }
            }

            // при фильтрации по дате, ставим последний день месяца у второй даты.
            // Также выполняется проверка даты, какая из них является первой, а какая второй
            for (const id in this.filterDatesIds) {
                const filter = this.filters.find((f) => f.id === id);
                if (!filter) continue;

                let firstDate = new Date(filter.select[0]);
                let secondDate: Date;
                if (filter.select[1]) {
                    if (filter.select[0].valueOf() < filter.select[1].valueOf()) {
                        secondDate = this.getLastDayOfMonth(filter.select[1]);
                    } else {
                        firstDate = new Date(filter.select[1]);
                        secondDate = this.getLastDayOfMonth(filter.select[0]);
                    }
                } else {
                    secondDate = this.getLastDayOfMonth(firstDate);
                }

                if (el.conversion) {
                    const conversionDate = new Date(el.conversion[id]);
                    if (conversionDate >= firstDate && conversionDate <= secondDate) {
                        hits++;
                    }
                } else if (el.conversions) {
                    el.conversions = el.conversions.filter((conv) => {
                        return new Date(conv.conversion[id]) >= firstDate
                            && new Date(conv.conversion[id]) <= secondDate;
                    });
                    if (el.conversions.length) {
                        hits++;
                    }
                }
            }

            if (hits === currentHits) {
                return el;
            }
        });
        this.total = this.filteredData.length;
        this.getPaginationData(0, this.limit);
        this.loading = false;
    }

    clearFilters(): void {
        this.filters.forEach((f) => f.clear());
        this.filteredData = [...this.initialData];
        this.total = this.filteredData.length;
        this.getPaginationData(0, this.limit);
    }

    // pagination
    getPaginationData(offset: number, limit: number, isChangeLimit?: boolean): void {
        if (offset === 0 || isChangeLimit) {
            this.pagination.page = 1;
        }
        this.limit = limit;
    }
}
