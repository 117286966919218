
import { Component, Watch } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import omit from 'lodash-es/omit';
import { downloadExcelFile, showServerError } from '@/utils';
import { getEmployeeConversions, getEmployeeConversionsJob } from '@/api/conversions-report';
import AppTable from '@/components/base/table/AppTable.vue';
import { IFilter, ITableStatus } from '@/types';
import TableMixin from '@/mixins/table';
import ReportLayout from '@/components/layout/ReportLayout.vue';
import ConstructorConversion from '@/components/statistics/conversion-report/Constructor.vue';
import StatisticReportMixin, { IExportFormat } from '@/mixins/statisticReport';
import { mixins } from 'vue-class-component';
import AppTableDesign from '@/components/base/table/AppTableDesign.vue';
import { ENV_TOGGLER } from '@/configs/global';
import {
    conversionHeaders,
    conversionRoutes,
    columnsConversionsReport,
    ConversionFilters,
} from "@/services/TablePage/conversionReport";
import { translateConversionStatus } from "@/utils/translate";

const statistics = namespace('statisticsModule');

@Component({
    components: {
        AppTableDesign,
        AppTable,
        ReportLayout,
        ConstructorConversion,
    },
})
export default class EmployeeConversions extends mixins(TableMixin, StatisticReportMixin) {
    @Mutation('SET_NOTIFICATION') setNotification;
    @Mutation('RESET_NOTIFICATION') resetNotification;
    @Getter('IS_DEV_MODE') isDevMode;
    @statistics.Getter('GET_CONVERSIONS_REPORT_IN_PROGRESS') getInProgress;
    @statistics.Getter('GET_CONVERSIONS_REPORT_LOADING') getLoading;
    @statistics.Getter('GET_CONVERSIONS_REPORT_PROGRESS') getProgress;
    @statistics.Getter('GET_CONVERSIONS_REPORT_STATUS') getStatus;
    @statistics.Mutation('SET_CONVERSIONS_REPORT_IN_PROGRESS') setInProgress;
    @statistics.Mutation('SET_CONVERSIONS_REPORT_LOADING') setLoading;
    @statistics.Mutation('SET_CONVERSIONS_REPORT_PROGRESS') setProgress;
    @statistics.Mutation('SET_CONVERSIONS_REPORT_STATUS') setStatus;
    @statistics.Mutation('RESET_CONVERSIONS_REPORT') resetReport;
    @statistics.Action('EXPORT_BIG_CONVERSIONS_REPORT') exportBigReport;

    isNewDesignPage = true;
    attachClass = '.conversion-report';
    isNotFound = false;
    apiMethod = getEmployeeConversions;
    settings = {
        loading: false,
        title: 'Отчет по конверсиям',
        loadingExport: false,
        loadingUpdate: false,
    };

    get getEmptyConditions(): object {
        const necessaryDateFilters = ['offer_id','goal_id', 'affiliate_id', 'advertiser_id'];
        const necessaryFilters = ['affiliate_invoice_id', 'conversion_id', 'click_id'];
        const countOfDateFilters = this.filters.filter((filter) => (Array.isArray(filter.select)
            ? filter.select?.length > 0
            : !!filter.select) && necessaryDateFilters.includes(filter.id as string)).length || 0;
        const countOfFilters = this.filters.filter((filter) => (Array.isArray(filter.select)
            ? filter.select?.length > 0
            : !!filter.select) && necessaryFilters.includes(filter.id as string)).length || 0;
        const dateFilter = this.filters.find((filter) => filter.id === 'date')?.select.length || 0;
        const objErrors = {
            isEmptyFilters: (
                countOfFilters === 0
                && countOfDateFilters === 0
            )
                || (
                    countOfDateFilters >= 0
                    && dateFilter <= 0
                    && countOfFilters <= 0
                )
                || (
                    dateFilter > 0
                    && (countOfDateFilters <= 0)
                    && countOfFilters <= 0
                ),
        };
        for (const i in objErrors) {
            if (!objErrors[i]) {
                this.$delete(objErrors, i);
            }
        }
        return objErrors;
    }

    filters: IFilter[] = new ConversionFilters().filters;
    headers = conversionHeaders;

    numberFormatting = [
        {
            slot: 'item.payout',
            key: 'payout',
            isMono: true,
        },
        {
            slot: 'item.revenue',
            key: 'revenue',
            isMono: true,
        },
    ];

    // для отображения роутов в таблице
    routes = conversionRoutes;

    uniqueData = [
        {
            slot: 'item.buffer_account_used',
            template: (item: any): string => item.buffer_account_used ? 'Да' : 'Нет',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateConversionStatus,
        },
    ];

    transformParamsObjBeforeSubmit({ offset, limit, ...params }: {offset: number; limit: number;}): any {
        return this.getDataForRequest(params, offset, limit);
    }

    getDataForRequest(params: any = {}, offset: number = this.baseOffset, limit: number = this.rowsPerPage): any {
        const paramsObj = omit(params, 'fake');

        const period = {
            from: paramsObj.date_from,
            to: paramsObj.date_to,
        };
        let columns = paramsObj.columns || [];
        if (!columns.length && this.$route.query?.columns) {
            columns = Array.isArray(this.$route.query.columns)
                ? this.$route.query.columns
                : [this.$route.query.columns];
        }
        const paging = { limit, offset };
        const filters: { column: string, values: any[] }[] = [];

        delete paramsObj.date_from;
        delete paramsObj.date_to;
        delete paramsObj.columns;

        for (const param in paramsObj) {
            if (!paramsObj.hasOwnProperty(param)) continue;

            const values = this.normalizeValues(paramsObj[param]);
            if (values) {
                filters.push({
                    column: param,
                    values,
                });
            }
        }

        interface IRequestObj {
            columns: string[];
            filters: any[];
            paging: {
                offset: number;
                limit: number;
            };
            period: {
                from: any;
                to: any;
            };
            fake?: boolean;
        }

        const result: IRequestObj = { columns, filters, paging, period };

        if (params.fake) result.fake = true;

        return result;
    }

    normalizeValues(values: string | any[] | null): (string | number)[] | null {
        if (!values || (Array.isArray(values) && values.every((v) => !v && typeof v !== 'number'))) {
            return null;
        }
        if (!Array.isArray(values)) {
            return [values];
        }
        return values.filter((v) => v !== undefined && v !== null);
    }

    // удаляем только фильтры из ConstructorWrap
    clearBaseFilters(): void {
        this.clearFilters(this.filters);
        this.setFiltersParamsObject({});
        this.setDefaultFiltersParamObject({ status: ['approved'] });
        this.updateQueryString(this.getDefaultFiltersParamObject);
        this.clearAllFilters();
    }

    // удаляем все фильтры вместе с группировкой из компонента FiltersBaseDesign
    clearAllFilters(): void {
        this.dataTable = [];
        this.totalElementDataTable = 0;
        this.isNotFound = false;
    }

    created(): void {
        this.setFiltersParamsObject({});
        this.setDefaultParamsObject({});
        if (Object.keys(this.$route.query).length <= 0) {
            const activeFilters: object[] = [];
            this.filters.forEach((filter) => {
                if (filter.id === 'status') {
                    filter.select.push('approved');
                    activeFilters.push({
                        [filter.id]: filter.select,
                    });
                }
            });
            const paramsObj = JSON.parse(JSON.stringify(Object.assign({}, ...activeFilters)));
            this.setDefaultParamsObject(paramsObj);
        }
    }

    mounted(): void {
        // Без фильтра по дате:ID счёта || conversion ID || Transaction ID || (Оффер && sub_id рекламодателя)
        // Фильтр по дате && один из: (оффер || оффер && цель || партнёр || статус)
        const isGetReport = Object.keys(this.$route.query)?.length > 0
            && (
                (this.$route.query.date_from
                    && (
                        this.$route.query.affiliate_id
                        || this.$route.query.offer_id
                        || this.$route.query.status
                        || (this.$route.query.offer_id && this.$route.query.goal_id)
                    )
                )
                || this.$route.query.affiliate_invoice_id
                || this.$route.query.conversion_id
                || (this.$route.query.offer_id && this.$route.query.adv_sub)
                || this.$route.query.click_id
                || this.$route.query.advertiser_id
            );
        if (isGetReport) {
            this.getConversions();
        }
    }

    // изменение фильтров и их отправка с датапикера, который над таблицей
    changeFilters(e: any) : void {
        this.changeFiltersFromLayout(this.filters, e);
        // обновляем данные, если выполнены все условия для отправки отчета,
        // e == null при очистке дата пикера, поэтому данные не обновляем
        if (Object.keys(this.getEmptyConditions).length <= 0 && e) {
            this.getConversions(0);
        }
    }

    async getConversions(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
            this.isNotFound = this.dataTable.length <= 0;
        } catch (err) {
            showServerError(err, 'Отчет по конверсиям не загружен');
            this.isNotFound = true;
            this.dataTable = [];
        } finally {
            this.$set(this.settings, 'loading', false);
            this.$set(this.settings, 'loadingUpdate', false);
        }
    }

    async exportSmallReport(item: IExportFormat, params: any): Promise<void> {
        const { headers, responseType } = item;
        const report = await getEmployeeConversions(
            this.getDataForRequest(params),
            headers,
            responseType,
        );
        let file = report;
        if (item.format === 'csv') {
            file = this.translateReportHeader(report, columnsConversionsReport);
        }
        downloadExcelFile(file, 'Отчет по конверсиям', item.format);
    }

    async exportReport(item: IExportFormat): Promise<void> {
        this.setLoading(true);
        let params = { ...this.getFiltersParamsObject };
        const { headers } = item;
        params = ENV_TOGGLER && this.isDevMode ? { ...params, fake: true } : { ...params };
        try {
            if ((!!this.totalElementDataTable && this.totalElementDataTable < 500000) && !this.isDevMode) {
                this.setInProgress(false);
                await this.exportSmallReport(item, params);
            } else {
                const { jobId } = await getEmployeeConversionsJob(
                    this.getDataForRequest(params, 0, 10000),
                    headers,
                );
                this.setProgress(0);
                await this.exportBigReport(jobId);
            }
        } catch (err) {
            if (err.status !== 404) {
                showServerError(err, 'Отчет по конверсиям не загружен');
            }
        } finally {
            this.resetNotification();
            this.resetReport();
        }
    }

    @Watch('getLoading', { immediate: true })
    watchGetLoading(value: any): void {
        this.$set(this.settings, 'loadingExport', !!value);
    }
}
