
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IUserFromToken } from '@/api/types/user';

@Component({})

export default class UserSettings extends Vue {
    @Prop({ required: true }) readonly userCRM!: IUserFromToken;
    @Prop({ required: true }) readonly isShowUserSettings!: boolean;
}
