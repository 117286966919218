import { AxiosResponse } from 'axios';
import { axiosInstance } from '@/api/index';
import { API_NOTIFICATIONS_URL } from '@/configs/global';
import { IParamsObject, IServerResponse } from '@/api/types';
import {
    INotification,
    INotificationAffiliate,
    INotificationCategory,
    INotificationList,
    IServiceNotifications,
} from '@/api/types/notifications';
import { PostNotificationDTO } from "@/services/notifications/EmployeeNotification";

/**
 *  Получение пользовательский уведомлений
 */
export async function getNotifications(
): Promise<any> {
    return axiosInstance.request({
        method: 'GET',
        url: `${API_NOTIFICATIONS_URL}/notifications`,

    })
        .then((response: AxiosResponse<IServerResponse<IServiceNotifications[]>>) => response.data.data);
}


/**
 *  Отметить пользовательское уведомлений, как прочитанное
 */
export async function markNotificationsAsRead(
    params: {
        notification_ids: any,
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_NOTIFICATIONS_URL}/notifications`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Центр уведомлений: Получение уведомлений
 */
export async function getEmployeeNotifications(
    params: IParamsObject,
): Promise<IServerResponse<INotificationList[]>> {
    return axiosInstance.request<IServerResponse<INotificationList[]>>({
        method: 'GET',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications`,
        params,

    })
        .then((response: AxiosResponse<IServerResponse<INotificationList[]>>) => response.data);
}

/**
 *  Центр уведомлений: Получение категорий и подкатегорий
 */
export async function getNotificationEvents(
    params?: IParamsObject,
): Promise<INotificationCategory[]> {
    return axiosInstance.request<IServerResponse<INotificationCategory[]>>({
        method: 'GET',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications/events`,
        params,

    })
        .then((response: AxiosResponse<IServerResponse<INotificationCategory[]>>) => response.data.data);
}


/**
 *  Центр уведомлений: Получение одного уведомления
 */
export async function getOneNotification(
    id: number | string,
): Promise<INotification> {
    return axiosInstance.request<IServerResponse<INotification>>({
        method: 'GET',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<INotification>>) => response.data.data);
}


/**
 *  Центр уведомлений: Удаление одного уведомления
 */
export async function deleteNotification(
    id: number | string,
): Promise<[]> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'DELETE',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data.data);
}


/**
 *  Центр уведомлений: Получение партнеров
 */
export async function getNotificationUsers(
    id: number,
    params?: IParamsObject,
): Promise<IServerResponse<INotificationAffiliate[]>> {
    return axiosInstance.request<IServerResponse<INotificationAffiliate[]>>({
        method: 'GET',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications/${id}/users`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse< INotificationAffiliate[]>>) => response.data);
}

/**
 *  Центр уведомлений: Получатели уведомления
 */
export async function getNotificationUserIds(
    id: number,
    params?: IParamsObject,
): Promise<number[]> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'GET',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications/${id}/users-ids`,
        params,
    })
        .then(async(response: AxiosResponse<IServerResponse< any[]>>) => response.data.data);
}


/**
 *  Центр уведомлений: Создание уведомления
 */
export async function postNotification(
    data: PostNotificationDTO,
): Promise<INotification> {
    return axiosInstance.request<IServerResponse<INotification>>({
        method: 'POST',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<INotification>>) => response.data.data);
}


/**
 *  Центр уведомлений: Добавление пользователей к опубликованному уведомлению
 */
export async function addUsersToNotification(
    id: number,
    users: number[],
): Promise<INotification> {
    return axiosInstance.request<IServerResponse<INotification>>({
        method: 'POST',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications/${id}/users`,
        data: {
            users,
        },
    })
        .then((response: AxiosResponse<IServerResponse<INotification>>) => response.data.data);
}


/**
 *  Центр уведомлений: Редактирвоание уведомления
 */
export async function patchNotification(
    id: number,
    data: PostNotificationDTO,
): Promise<INotification> {
    return axiosInstance.request<IServerResponse<INotification>>({
        method: 'PATCH',
        url: `${API_NOTIFICATIONS_URL}/employees/notifications/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<INotification>>) => response.data.data);
}
