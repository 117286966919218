import { IParamsObject, IServerResponse } from "@/api/types";
import { axiosInstance } from "@/api/index";
import { API_LEADGID_ORD_URL } from "@/configs/global";
import { AxiosResponse } from "axios";
import { ICreative, IDefaultCreative, IPlatform, IContracts } from "@/api/types/leadgid-ord";
import { IOfferErid } from "@/api/types/offers";

export async function getAffiliatePlatforms(
    params: IParamsObject,
): Promise<IServerResponse<IPlatform[]>> {
    return axiosInstance.request<IServerResponse<IPlatform[]>>({
        method: 'GET',
        url: `${API_LEADGID_ORD_URL}/employees/platforms`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IPlatform[]>>) => response.data);
}

export async function getOfferDefaultCreative(
    params: IParamsObject,
): Promise<IServerResponse<IDefaultCreative>> {
    return axiosInstance.request<IServerResponse<IDefaultCreative>>({
        method: 'GET',
        url: `${API_LEADGID_ORD_URL}/employees/default-creative`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IDefaultCreative>>) => response.data);
}

export async function getOfferCreatives(
    params: IParamsObject,
): Promise<IServerResponse<ICreative[]>> {
    return axiosInstance.request<IServerResponse<ICreative[]>>({
        method: 'GET',
        url: `${API_LEADGID_ORD_URL}/employees/creatives`,
        params,
    }).then((response: AxiosResponse<IServerResponse<ICreative[]>>) => response.data);
}

export async function getOfferErid(
    id: string | number,
): Promise<IServerResponse<IOfferErid>> {
    return axiosInstance.request<IServerResponse<IOfferErid>>({
        method: 'GET',
        url: `${API_LEADGID_ORD_URL}/employees/offers/${id}`,
    }).then((response: AxiosResponse<IServerResponse<IOfferErid>>) => response.data);
}

export async function searchContracts(
    params?: IParamsObject,
): Promise<IServerResponse<IContracts[]>> {
    return axiosInstance.request<IServerResponse<IContracts[]>>({
        method: 'GET',
        url: `${API_LEADGID_ORD_URL}/employees/contracts/customers`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IContracts[]>>) => response.data);
}

export async function addingContract(
    id: number,
    data?: {
        contract_id: number,
        date: string
    },
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_LEADGID_ORD_URL}/employees/offers/${id}/contract`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}