
import { Component, Vue, Prop } from 'vue-property-decorator';
import SvgComment from "@/assets/icons/comment.svg";
import SvgPlus from "@/assets/icons/plus.svg";
import EditIconBtn from "@/components/base/buttons/EditIconBtn.vue";
import CancelBtn from "@/components/base/buttons/CancelBtn.vue";
import SvgReload from "@/assets/icons/reload.svg";
import SvgMagicWand from "@/assets/icons/magic-wand.svg";

@Component({
    components: { SvgReload, EditIconBtn, SvgPlus, SvgComment, CancelBtn, SvgMagicWand },
})

export default class BaseCard extends Vue {
    @Prop({ type: String, default: '100%' }) maxWidth!: string;
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String, default: '' }) comment!: string;
    @Prop({ type: Boolean, default: false }) isShowTitle!: boolean;
    @Prop({ type: Boolean, default: false }) isPlusButton!: boolean;
    @Prop({ type: Boolean, default: true }) isShowPlusButton!: boolean;
    @Prop({ type: Boolean, default: false }) isCreateButton!: boolean;
    @Prop({ type: Boolean, default: false }) isEditable!: boolean;
    @Prop({ type: Boolean, default: false }) isEditDisabled!: boolean;
    @Prop({ type: Boolean, default: false }) isEditOnMounted!: boolean;
    @Prop({ type: Number, default: 0 }) textHeight!: number;

    isEditMode = false;

    mounted(): void {
        if (this.isEditOnMounted) this.isEditMode = true;
    }

    toggleEdit(): void {
        this.isEditMode = !this.isEditMode;
        this.$emit('toggle', this.isEditMode);
    }

    cancel(): void {
        this.isEditMode = false;
        this.$emit('cancel');
    }
}
