
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import InvoiceContent from '@/components/revenue/invoice/InvoiceContent.vue';

@Component({
    beforeRouteLeave(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        if (this.getUnsavedDataState) {
            this.setAbortTransition(true);
            this.setRouteToTransition(to.name);
        } else {
            this.setAbortTransition(false);
            this.setRouteToTransition('');
            next();
        }
    },
    components: { InvoiceContent },
})

export default class Invoice extends Vue {
    @Getter('GET_UNSAVED_DATA_STATE') getUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;
    @Mutation('SET_ROUTE_TO_TRANSITION') setRouteToTransition;

    created(): void {
        this.setAbortTransition(false);
    }
}
