
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { ACCOUNT_TYPE } from '@/mappings/account-type';

const auth = namespace('authModule');

@Component({
    components: {},
})

export default class Reminder2fa extends Vue {
  @Getter('GET_EDITING_MODE') editingMode;
  @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
  @Mutation('TOGGLE_EDITING_MODE') toggleEditingMode;
  @auth.Getter('GET_USER') user;
  @auth.Getter('GET_TWO_FACTORS_STATUS') is2faEnabled;

  showReminder = true;

  get active(): boolean {
      return !this.is2faEnabled && this.user.account_type === ACCOUNT_TYPE.EMPLOYEE;
  }
}
