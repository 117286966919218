
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getFormatDate } from "@/utils/formatDates";
import { getMarginPrefix, getRatePrefix } from "@/utils/personalRate/rate";
import { namespace } from "vuex-class";
import { IPersonalRatesFinance } from "@/api/types/offers";

const catalogue = namespace('catalogueModule');

@Component({})

export default class TooltipBtnPlanned extends Vue {
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String, default: '' }) attachClass!: string;
    @Prop({ type: Boolean, default: false }) isLoading!: boolean;
    @Prop({ required: true }) rate!: IPersonalRatesFinance;
    @Prop({ type: Boolean, default: false }) disabled!: boolean;
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencyIcon;

    getFormatDate = getFormatDate;

    plannedList: {text: string,value: number | string | undefined, supValue?: number | string | undefined, prefix: string}[] = [];

    mounted(): void {
        this.plannedList = [
            { text: 'Доход:', value: this.rate.planned?.revenue, prefix: getRatePrefix(this.rate.revenue.type, this.currencyIcon(this.rate.revenue.currency)) },
            { text: 'Выплата:', value: this.rate.planned?.payout, prefix: getRatePrefix(this.rate.payout.type, this.currencyIcon(this.rate.payout.currency)) },
            { text: 'Прибыль:', value: this.rate.planned?.margin.amount, supValue: this.rate.planned?.margin.percent, prefix: getMarginPrefix('amount', this.rate.payout.type, this.currencyIcon(this.rate.payout.currency)) },
        ];
    }

    get dateAndTime(): {date: string | undefined; time: string | undefined;} {
        if (this.rate.planned !== null) {
            return {
                date: getFormatDate(this.rate.planned.action_date, "dd.MM.yyyy"),
                time: getFormatDate(this.rate.planned.action_date, "HH:mm"),
            };
        }

        return {
            date: undefined,
            time: undefined,
        };
    }
}
