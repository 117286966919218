
import { Component, Vue, Prop } from 'vue-property-decorator';
import AppTable from '@/components/base/table/AppTable.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import { translateForConversionForecast } from '@/utils/translate';
@Component({
    components: { TooltipButton, AppTable },
})
export default class OfferTable extends Vue {
    @Prop({ type: Array, default: () => [] }) items!: [];
    @Prop({ type: Boolean, default: false }) loading!: boolean;
    @Prop({ type: Number, default: 0 }) total!: number;

    headers = [
        {
            value: 'offer_id',
            text: 'ID оффера',
        },
        {
            value: 'offer_name',
            text: 'Название оффера',
        },
        {
            value: 'result',
            text: 'Статус',
        },
        {
            value: 'details',
            text: 'Детали',
        },
    ];

    uniqueData = [
        {
            slot: 'item.details',
        },
        {
            slot: 'item.result',
        },
    ];

    translateForConversionForecast = translateForConversionForecast;
}
