
import { Vue, Component, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { Affiliate } from "@/services/affiliates/Affiliate";
import { IReport } from "@/api/types/stats";
import TitleActions from "@/components/affiliate/TitleActions.vue";
import { getFormatDate } from "@/utils/formatDates";

@Component({
    components: { TitleActions, TableWithFrontPaginationDesign, BaseCard },
})

export default class AffiliateStatistic extends Vue {
    @Prop({ required: true }) affiliate!: Affiliate;

    isLoading: boolean = false;
    headers = [
        { text: 'Дата', value: 'dm_lead_date', sortable: true },
        { text: 'Кликов всего', value: 'mt_clicks_total', sortable: false },
        { text: 'Уники', value: 'mt_clicks_unique', sortable: false, align: 'center' },
        { text: 'Лидов всего', value: 'mt_leads_total', sortable: false, align: 'center' },
        { text: 'Лидов прин.', value: 'mt_leads_approved', sortable: false, align: 'center' },
        { text: 'Лид.на проверке', value: 'mt_leads_pending', sortable: false, align: 'center' },
        { text: 'Лид.отклонено', value: 'mt_leads_rejected', sortable: false, align: 'center' },
        { text: 'CRu, %', value: 'mt_cr_u', sortable: false, align: 'center' },
        { text: 'CRt, %', value: 'mt_cr_t', sortable: false, align: 'center' },
        { text: 'EPCu', value: 'mt_epc_u', sortable: false, align: 'center' },
        { text: 'EPCt', value: 'mt_epc_t', sortable: false, align: 'center' },
        { text: 'AR, %', value: 'mt_ar', sortable: false, align: 'center' },
        { text: 'Выпл.прин.', value: 'mt_payout_approved', sortable: false, align: 'center' },
    ];

    uniqueData = [
        {
            slot: 'item.dm_lead_date',
            template: (item: IReport): string => getFormatDate(item.dm_lead_date!, 'dd.MM.yyyy'),
        },
    ];

    get getItems(): IReport[] {
        return this.affiliate.statistic!;
    }

    get isLoadingTable(): boolean {
        return this.affiliate.isLoadingData || this.isLoading;
    }
}

