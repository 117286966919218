
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import NavAppBar from '@/components/layout/NavAppBar.vue';
import Reminder2fa from '@/components/layout/Reminder2fa.vue';
import NavDrawer from '@/components/layout/NavDrawer.vue';
import FormLoginAsPartner from "@/components/layout/FormLoginAsPartner.vue";

const auth = namespace('authModule');

@Component({
    components: {
        FormLoginAsPartner,
        NavAppBar,
        NavDrawer,
        Reminder2fa,
    },
})

export default class Navigation extends Vue {
    @Getter('IS_DEV_MODE') isDevMode;
    @auth.Getter('GET_LOGIN_AS_PARTNER') isLoginAsPartner;
    @auth.Mutation('SET_LOGIN_AS_PARTNER') setLoginAsPartner;

    toggleMenu = false;
    isShow = false;

    @Watch('$vuetify.breakpoint.lgAndUp')
    changeToggleMenu(): void {
        this.toggleMenu = false;
    }

    mounted(): void {
        this.setLoginAsPartner(false);
        this.isShow = true;
    }

    get isShowAppBar(): boolean {
        return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
}
