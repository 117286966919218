
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})

export default class MultiButton extends Vue {
    @Prop({ type: String, default: '' }) icon!: string;
    @Prop({ type: String, default: 'primary' }) color!: string;
    @Prop({ type: String, default: '' }) direction!: string;
    fab = false;
}
