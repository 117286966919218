
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { getFormatDate } from "@/utils/formatDates";
import { eventBus } from "@/eventbus";
import Status from "@/components/base/Status.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { translateBoolean, translateTwoFactor, translateUserStatus, translateUserType } from "@/utils/translate";
import { IUser } from "@/api/types/user";
import { massChangeAffiliatesStatus, patchUser, patchUserByTypeV3 } from "@/api/user";
import { showServerError } from "@/utils";
import { namespace } from 'vuex-class';
import BaseCard from "@/components/base/BaseCard.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import { ACCOUNT_TYPE } from "@/mappings/account-type";

const auth = namespace('authModule');

@Component({
    methods: { translateTwoFactor, translateBoolean },
    components: {
        BaseCard,
        BaseSelect,
        Status,
    },
})
export default class UserCardMain extends Vue {
    @Prop({ required: true }) user!: IUser;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @auth.Getter('GET_USER') currentUser;
    @auth.Mutation('SET_TWO_FACTORS_STATUS') setTwoFactorsStatus;
    getFormatDate = getFormatDate;
    statusItems = [{ id: 'active' }, { id: 'deleted' }];
    isEdit = false;
    userData: any = {
        status: '',
        is_two_factor_enabled: '',
        first_name: '',
        last_name: '',
    };
    resetCode = false;
    twoFactorsInitialStatus = false;

    get isEditableFields(): boolean {
        return [ACCOUNT_TYPE.ADVERTISER, ACCOUNT_TYPE.EMPLOYEE, ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.AFFILIATE].includes(this.user.account_type as any);
    }

    get getStatusItems(): { id: string, text: string }[] {
        return this.statusItems.map(i => {
            return { id: i.id, text: translateUserStatus(i.id).text };
        });
    }

    get getOptions(): any[] {
        return [
            {
                name: 'Имя',
                value: this.user.first_name,
                type: 'text',
                isEditable: this.isEditableFields,
                editValue: this.userData.first_name,
                update: (e) => this.userData.first_name = e,
            },
            {
                name: 'Почта',
                value: this.user.email,
                type: 'text',
                isEditable: false,
            },
            {
                name: 'Фамилия',
                value: this.user.last_name,
                type: 'text',
                isEditable: this.isEditableFields,
                editValue: this.userData.last_name,
                update: (e) => this.userData.last_name = e,
            },
            {
                name: 'Статус',
                value: this.user.status,
                status: translateUserStatus,
                type: 'status',
                isEditable: this.isEditableFields,
                editValue: this.userData.status,
                items: this.getStatusItems,
                update: (e) => this.userData.status = e,
            },
            {
                name: 'User ID',
                value: this.user.id,
                type: 'text',
                isEditable: false,
            },
            {
                name: '2fa',
                value: this.user.is_two_factor_enabled,
                isEditable: true,
                type: 'boolean',
                editValue: this.userData.is_two_factor_enabled,
                status: translateTwoFactor,
                update: (e) => this.userData.is_two_factor_enabled = e,
            },
            {
                name: 'Account ID',
                value: this.user.account_id,
                type: 'text',
                isEditable: false,
            },
            {
                name: 'Тип',
                value: translateUserType(this.user.account_type).text,
                type: 'text',
                isEditable: false,
            },
        ];
    }

    get isChange2fa(): boolean {
        return this.userData.is_two_factor_enabled !== this.user.is_two_factor_enabled;
    }

    get isAdmin(): boolean {
        return this.currentUser.roles.includes(USER_ROLES.ADMIN);
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllUserData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setDefaults(): void {
        this.userData.status = this.user.status;
        this.userData.is_two_factor_enabled = this.user.is_two_factor_enabled;
        this.userData.first_name = this.user.first_name;
        this.userData.last_name = this.user.last_name;
    }

    async updateAllUserData(): Promise<void> {
        this.isLoadingSync = true;
        try {
            if (this.isChange2fa) {
                const data = { reset_code: this.userData.is_two_factor_enabled };
                await patchUser(this.user.account_id as number, this.user.account_type!, data);
                if (!this.userData.is_two_factor_enabled) {
                    this.setTwoFactorsStatus(false);
                }
            }
            const editableItems = this.getOptions.filter(i => i.isEditable && i.name !== '2fa');
            if (editableItems.some(i => i.value !== i.editValue)) {
                const data = Object.assign({}, this.userData);
                delete data.is_two_factor_enabled;
                const accountId = this.user.account_type === ACCOUNT_TYPE.SYSTEM ? this.user.id : this.user.account_id;
                await patchUserByTypeV3(accountId as number, this.user.account_type as any, data);
            }
            // статус у партнера меняется по другому урлу
            if (this.userData.status !== this.user.status && this.user.account_type === ACCOUNT_TYPE.AFFILIATE) {
                const data = { affiliate_ids: [this.user.account_id as number], status: this.userData.status };
                await massChangeAffiliatesStatus(data);
            }
            eventBus.$emit("update-user");

        } catch (err) {
            showServerError(err, 'Ошибка сохранения');
        } finally {
            this.isLoadingSync = false;
        }
    }
}
