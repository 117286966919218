
import { Component, Vue } from 'vue-property-decorator';
import { namespace, Getter } from 'vuex-class';
import CardMaterial from '@/components/base/CardMaterial.vue';
import { getUser } from '@/api/user';
import { getUserData } from '@/api/auth';

const catalogue = namespace('catalogueModule');
// Создаём константы для сторов разных модулей
const auth = namespace('authModule');

@Component({
    components: { CardMaterial },
})

export default class Auth extends Vue {
  @auth.Action('AUTHORIZATION_LOGIN_METHOD') loginAuth;
  @Getter('GET_LAST_ROUTE') getLastRoute;
  @auth.Getter('GET_TWO_FACTORS_STATUS') getTwoFactorsStatus;
  @auth.Mutation('SET_USER') setUser;
  @auth.Mutation('SET_TWO_FACTORS_STATUS') setTwoFactorsStatus;
  @catalogue.Action('GET_DICTIONARY') getDictionary;

  email = '';
  error = '';
  password = '';
  qrCode = '';
  showPassword = false;
  validFormLogin = true;
  is2faLocalState = false;
  isLoading = false;
  rules: object[] = [
      (value: string): boolean | string => !!value || 'Обязательно для заполнения',
      (value: string): boolean | string => (value || '').length <= 35 || 'Не более 35 символов',
  ];

  created(): void {
      this.setTwoFactorsStatus(false);
  }

  focusInput(): void {
      const qrCodeInput = document.getElementsByName('qrCode');
      if (qrCodeInput) {
          qrCodeInput[0].focus();
      }
  }

  showError(err: any): void {
      if (err?.message === '2fa is enabled') return;

      if (err.status === 400 && err.message === 'request code is wrong') {
          this.error = 'Неправильный код. Попробуйте ещё раз';
      } else if (err.status === 400) {
          this.error = 'Неправильный логин или пароль';
      } else if (err.message === 'Неверный код') {
          this.error = err.message;
      } else {
          console.error(err);
      }
  }

  sendCode(): void {
      if (this.qrCode?.length < 6) {
          this.error = '';
      }
      if (this.qrCode?.length === 6 && !this.isLoading && !this.error) {
          this.login();
      }
  }

  async login(): Promise<void> {
      if (!(this.$refs.formLogin as Vue & { validate: () => boolean }).validate()) return;

      this.isLoading = true;
      const { email, password, qrCode } = this;
      try {
          await this.loginAuth({ email, password, code: qrCode });

          if (this.getTwoFactorsStatus && !this.is2faLocalState) {
              this.is2faLocalState = true;
              this.$nextTick(() => {
                  this.focusInput();
                  this.qrCode = '';
              });
              throw new Error('2fa is enabled');
          }

          const { account_id: accountId, account_type: accountType } = await getUserData();
          const data = await getUser(accountId, accountType);
          await this.getDictionary();
          // переподключаем сокет при логине
          this.$socket.client.disconnect().connect();
          this.setUser(data);
          await this.$router.push(this.getLastRoute).catch(console.error);
      } catch (err) {
          this.showError(err);
      } finally {
          this.isLoading = false;
      }
  }
}
