import { FilterRouteParser } from '@/services/filters/filterRouteParser';
import { showServerError } from '@/utils';
import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';
import { IFilter } from '@/types';

export class AutocompleteFilter extends BaseFilter {
    autocomplete? = true;
    multiple?: boolean;
    itemValue: string;
    itemText?: string;
    template?: (item: any) => any;
    apiMethod?: (...options: any) => Promise<any>;
    key?: string;
    minSearchLength?: number;
    noDataText?: string;
    type?: 'string' | 'number';
    autocompleteValue?: string | null = null;
    hideNoDataAutocomplete?: boolean;
    alsoSearchBy?: string;
    isExcluded?: boolean;
    loading?: boolean = false;

    constructor(config: ConstructorType<AutocompleteFilter, 'autocomplete'>) {
        super(config);
        this.multiple = config.multiple ?? true;
        this.select = config.select ?? (this.multiple ? [] : '');
        this.items = config.items ?? [];
        this.itemValue = config.itemValue;
        this.itemText = config.itemText ?? 'text';
        this.template = config.template;
        this.apiMethod = config.apiMethod;
        this.key = config.key;
        this.minSearchLength = config.minSearchLength;
        this.noDataText = config.noDataText;
        this.type = config.type ?? 'number';
        this.autocompleteValue = config.autocompleteValue;
        this.hideNoDataAutocomplete = config.hideNoDataAutocomplete;
        this.alsoSearchBy = config.alsoSearchBy;
        this.isExcluded = config.isExcluded;
        this.loading = config.loading;
    }

    clear(): void {
        super.clear();
        if (!!this.apiMethod) this.items = [];
    }

    getValueForSubmit(filter: IFilter = this): Record<string, any> {
        if (filter.id === 'account_manager_id' && filter.itemValue === 'account_id' && Array.isArray(filter.select)) {
            return { [filter.id]: filter.select.map((fl) => fl.account_id) };
        }
        return super.getValueForSubmit(filter);
    }

    onSelectChange(): void {
        this.updateHideNoDataAutocomplete();
    }

    onItemsChange(): void {
        this.updateHideNoDataAutocomplete();
    }

    updateHideNoDataAutocomplete(): void {
        this.hideNoDataAutocomplete = this.items?.length
            ? this.select?.length === this.items?.length
            : false;
    }

    parseQueryAndGetChanges(query: any, currentQueryKey: any, parser: FilterRouteParser): Record<string, any> {
        if (this.multiple) {
            const value = this.castQueryValueToType(query[currentQueryKey]);

            if (this.apiParseUrl !== undefined) {
                return { [this.id]: value };
            }

            if (this.apiMethod !== undefined) {
                if (Array.isArray(query[currentQueryKey])) {
                    query[currentQueryKey].forEach((q) => this.getItemsWithApiMethod(q, parser).then());
                } else {
                    this.getItemsWithApiMethod(query[currentQueryKey], parser).then();
                }
            } else {
                this.select = value;
            }

            return { [this.id]: value };
        }

        if (this.apiMethod && this.apiParseUrl === undefined) {
            const value = ['city', 'region'].includes(this.id)
                ? query[currentQueryKey]
                : Number(query[currentQueryKey]);

            this.getItemsWithApiMethod(query[currentQueryKey], parser).then();

            return { [this.id]: value };
        }

        return { [this.id]: query[currentQueryKey] };
    }

    async getItemsWithApiMethod(currentQueryKey: any, parser: FilterRouteParser): Promise<void> {
        if (!this.apiMethod) return;
        try {
            let select: any = {};
            let params: any = {};
            if (this.customParams !== undefined) {
                params = { ...this.customParams(this, currentQueryKey) };
            } else {
                params = {
                    [this.key || 'query']: this.queryValue ? this.queryValue(currentQueryKey) : currentQueryKey,
                };
            }
            params.limit = 500;
            const result = await this.apiMethod(params);
            const data = result.data.suggestions || result.data || result;

            if (!(data.length > 0 && this.itemValue !== undefined && this.itemText !== undefined)) {
                this.select = this.multiple ? this.items : select;
                return;
            }

            if (this.id === 'master_account' || this.id === 'master_account_id') {
                parser.getItemsMasterAccount(this, data, currentQueryKey);
                this.select = this.multiple ? this.items : select;
                return;
            }

            const tempItem = this.id === 'city'
                ? data[0]
                : data!.find((i) => i[this.itemValue!] === Number(currentQueryKey));

            if (this.dadata) {
                select = {
                    value: tempItem.data.city,
                };
            } else if (this.id === 'offer' || this.id === 'offer_id') {
                select = parser.getOfferSelect(this, tempItem);
            } else if (this.id === 'authors') {
                select = {
                    [this.itemValue!]: tempItem[this.itemValue!],
                    [this.itemText!]: this.template ? this.template(tempItem) : tempItem,
                };
            } else if (this.id === 'hash') {
                select = {
                    id: tempItem.id,
                    [this.itemValue!]: tempItem[this.itemValue!],
                    [this.itemText!]: `${tempItem.id} ${tempItem[this.itemValue!]}`,
                };
            } else if (this.id === 'buffer_id') {
                select = parser.getBufferSelect(this, data, currentQueryKey);
            } else if (this.supValue !== undefined) {
                select = parser.getSupSelect(this, tempItem);
            } else {
                select = parser.getBaseSelect(this, tempItem);
            }

            this.items.push(select);
            this.select = this.multiple ? this.items : select;
        } catch (err) {
            showServerError(err, err.message || 'Упс.. Что-то пошло не так');
        }
    }
}
