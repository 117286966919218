import { AxiosResponse } from 'axios';
import { IParamsObject, IServerResponse } from '@/api/types';
import {
    IAgentReport,
    IFinanceInvoice,
    IFinanceInvoiceSingle,
    IMasterAccount,
    IMasterAccountListItem,
} from '@/api/types/org-cabinets-backend';
import { axiosInstance } from '@/api/index';
import { API_ORG_CABINETS_BACKEND_URL } from '@/configs/global';
import { IMyLeadgidInvoice } from '@/api/types/payout';
import { IUser } from "@/api/types/user";

/*
 * Получение списка мастер-аакаунтов
 */
export async function getMasterAccounts(
    params: {
        master_account_ids?: number[];
        limit: number;
        offset: number;
    },
): Promise<IServerResponse<IMasterAccountListItem[]>> {
    return axiosInstance.request<IServerResponse<IMasterAccountListItem[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/master-accounts`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IMasterAccountListItem[]>>) => response.data);
}

/**
 * Получение мастер аккаунта
 */
export async function getOneMasterAccount(
    id?: string,
): Promise<IMasterAccount> {
    return axiosInstance.request<IServerResponse<IMasterAccount>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/master-accounts/${id}`,
    }).then((response: AxiosResponse<IServerResponse<IMasterAccount>>) => response.data.data);
}

/*
 * Получение списка 1с счетов у мастер аккаунта
 */
export async function getMasterAccountFinanceInvoices(
    id: string,
    params: IParamsObject,
): Promise<IServerResponse<IFinanceInvoice[]>> {
    return axiosInstance.request<IServerResponse<IFinanceInvoice[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/master-accounts/${id}/finance-invoices`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IFinanceInvoice[]>>) => response.data);
}

/*
 * Получение списка счетов my.leadgid у мастер-аккаунта
 */
export async function getMasterAccountMyLeadgidInvoices(
    id: number,
    params: IParamsObject,
): Promise<IServerResponse<IMyLeadgidInvoice[]>> {
    return axiosInstance.request<IServerResponse<IMyLeadgidInvoice[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/master-accounts/${id}/affiliate-invoices`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IMyLeadgidInvoice[]>>) => response.data);
}

/*
 * Получение списка 1с
 */
export async function getFinanceInvoices(
    id: string,
    params: IParamsObject,
): Promise<IServerResponse<IFinanceInvoice[]>> {
    return axiosInstance.request<IServerResponse<IFinanceInvoice[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/finance-invoices`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IFinanceInvoice[]>>) => response.data);
}

/*
 * Получение счета 1с у мастер аккаунта
 */
export async function getMasterAccountFinanceInvoice(
    id: string,
): Promise<IServerResponse<IFinanceInvoiceSingle>> {
    return axiosInstance.request<IServerResponse<IFinanceInvoiceSingle>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/finance-invoices/${id}`,
    }).then((response: AxiosResponse<IServerResponse<IFinanceInvoiceSingle>>) => response.data);
}

/*
 * Поиск веб-мастеров
 */
export async function searchWebmasters(
    params: {
        query: string,
    },
): Promise<IServerResponse<object[]>> {
    return axiosInstance.request<IServerResponse<object[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/webmasters/search`,
        params,
    }).then((response: AxiosResponse<IServerResponse<object[]>>) => response.data);
}

/**
 * Получение отчета по офферу для 1с-счета
 */
export async function getOfferReport(
    params?: IParamsObject,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/offer-stats`,
        params,
    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Получение отчета по портнеру для 1с-счета
 */
export async function getAffiliateReport(
    params?: IParamsObject,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/partner-stats`,
        params,
    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Получение отчета по офферу для agent-report
 */
export async function getOfferAgentReport(
    params?: IParamsObject,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/agent-reports/stats/by-offer`,
        params,
    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Получение отчета по по партнеру для agent-report
 */
export async function getAffiliateAgentReport(
    params?: IParamsObject,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/agent-reports/stats/by-partner`,
        params,
    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Получение отчетов агентов
 */
export async function getAgentReports(
    params?: IParamsObject,
): Promise<IServerResponse<IAgentReport[]>> {
    return axiosInstance.request<IServerResponse<IAgentReport[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/agent-reports`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IAgentReport[]>>) => response.data);
}

/**
 *  Получение отчета агента
 */
export async function getAgentReport(
    id: string | number,
    params?: IParamsObject,
): Promise<IAgentReport> {
    return axiosInstance.request<IServerResponse<IAgentReport>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/agent-reports/${id}`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IAgentReport>>) => response.data.data);
}

/**
 * Получение списка пользователей с организациями
 */
export async function getUsersWithOrganizations(
    params?: IParamsObject,
): Promise<IServerResponse<IUser[]>> {
    return axiosInstance.request<IServerResponse<IUser[]>>({
        method: 'GET',
        url: `${API_ORG_CABINETS_BACKEND_URL}/users`,
        params,
    }).then((response: AxiosResponse<IServerResponse<IUser[]>>) => response.data);
}

/**
 * Удаление организаций у пользователя
 */
export async function deleteOrganizationsFromUser(
    accountId: number,
    accountType: string,
    org_guids: string[],
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_ORG_CABINETS_BACKEND_URL}/users/${accountId}/${accountType}/organizations`,
        data: {
            org_guids,
        },
    });
}

/**
 * Добавление организаций пользователю
 */
export async function postUserOrganizations(
    accountId: number,
    accountType: string,
    org_guids: string[],
): Promise<IServerResponse<any[]>> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'POST',
        url: `${API_ORG_CABINETS_BACKEND_URL}/users/${accountId}/${accountType}/organizations`,
        params: {
            org_guids,
        },
    }).then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}
