
import { Vue, Component, Prop } from "vue-property-decorator";
import AffiliateAbout from "@/components/affiliate/AffiliateAbout.vue";
import AffiliateNotes from "@/components/affiliate/AffiliateNotes.vue";
import { Affiliate } from "@/services/affiliates/Affiliate";
import { namespace } from "vuex-class";
import { showServerError } from "@/utils";
import { getEmployeesOfferVerticals } from "@/api/offers";
import AffiliateBalance from "@/components/affiliate/AffiliateBalance.vue";
import AffiliatePaymentInfo from "@/components/affiliate/AffiliatePaymentInfo.vue";
import AffiliateStatistic from "@/components/affiliate/AffiliateStatistic.vue";

const offers = namespace('offersModule');

@Component({
    components: { AffiliateStatistic, AffiliatePaymentInfo, AffiliateBalance, AffiliateNotes, AffiliateAbout },
})

export default class AffiliateSingle extends Vue {
    @Prop({ required: true }) affiliate!: Affiliate;

    @offers.Getter('GET_VERTICALS') getVerticals;
    @offers.Mutation('SET_VERTICALS') setVerticals;

    created(): void {
        if (this.getVerticals.length === 0) {
            this.getEmployeesOfferVerticals();
        }
    }

    async getEmployeesOfferVerticals(): Promise<void> {
        try {
            const data = await getEmployeesOfferVerticals();
            this.setVerticals(data);
        } catch (err) {
            showServerError(err, 'Список вертикалей не загружен');
        }
    }
}

