
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IColumnFilter } from '@/types';
import TablePageFilters from '@/components/base/filters/TablePageFilters.vue';
import { AffiliateMethodsColumns, AffiliateMethodsFilters } from "@/services/TablePage/affiliateMethods";

@Component({
    components: {
        TablePageFilters,
    },
})
export default class FiltersMethods extends Vue {
    @Prop({ default: 100 }) limit!: number;

    filters: AffiliateMethodsFilters = new AffiliateMethodsFilters();

    columns: IColumnFilter[] = AffiliateMethodsColumns;

    submitFilters(offset: number, limit: number): void {
        this.$emit('submit', offset, limit);
    }
}
