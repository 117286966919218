
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Pagination from '@/components/base/table/Pagination.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import Filters from '@/components/base/filters/Filters.vue';
import SvgAffiliateReport from '@/assets/icons/nav-bar/affiliate-report.svg';
import SvgOfferReport from '@/assets/icons/nav-bar/offer-report.svg';
import { PAGINATION_PER_PAGE } from '@/configs/global';

@Component({
    components: {
        Pagination,
        StatsCard,
        Filters,
        SvgAffiliateReport,
        SvgOfferReport,
    },
})
export default class ReportTable extends Vue {
    @Prop({ default: 0 }) payoutTotal!: number;

    @Prop({ type: Array, default: () => [] }) dataTable!: [];

    @Prop({ type: Array, default: () => [] }) headers!: Array<{}>;

    @Prop({ type: Boolean, default: false }) loading!: boolean;

    @Prop({ type: Boolean, default: false }) isOffer!: boolean;

    @Prop({ type: Boolean, default: false }) isNotFound!: boolean;

    // // for pagination
    pageCount = 0;

    itemsPerPage = PAGINATION_PER_PAGE;

    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };

    @Watch('dataTable')
    toFirstPage(): void {
        this.pagination.page = 1;
    }

    get title(): string {
        return this.$props.isOffer ? 'Отчёт по офферам' : 'Отчёт по партнерам';
    }

    // изменить число строк в таблице
    changeItems(offset: number, limit: number): void {
        this.itemsPerPage = limit;
    }
}
