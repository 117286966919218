
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IFilter } from "@/types";
import { Getter } from "vuex-class";
import { eventBus } from "@/eventbus";
import ComboboxArrayDesign from "@/components/base/form/ComboboxArrayDesign.vue";
import uniq from "lodash-es/uniq";

@Component({
    components: { ComboboxArrayDesign },
})
export default class FilterSubIds extends Vue {
    @Prop({ required: true }) item!: IFilter;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    checked = false;
    selectSub: any[] = [];

    get isCheck(): boolean {
        return (this.item.select?.length > 0 || this.item.isSubmitEmptySelect || this.getFiltersParamsObject.hasOwnProperty(this.item.id));
    }

    get findMaxItems(): number {
        if (this.item.select?.length < 1) return 2;

        if (this.item.select?.length >= 2 && this.item.select![0]?.length <= 9 && this.item.select![1]?.length <= 9) {
            return 2;
        }

        const selectedChips = this.item.select?.map(i => i.slice(0, 20)).join('');
        if (selectedChips?.length >= 20) return 1;

        return 2;
    }

    mounted(): void {
        this.checked = this.isCheck;
        this.selectSub = [...this.item.select];
        eventBus.$on('clear-subs', this.clear);
    }

    toggleCheckbox(e: boolean | null): void {
        if (e) {
            return;
        } else {
            this.item.isSubmitEmptySelect = false;
            this.item.select = [];
            this.selectSub = [];
            this.$emit('change');
        }
    }

    switchSubmit(): void {
        this.changeChecker([]);
        this.$emit('change');
    }

    clear(): void {
        this.checked = false;
        this.selectSub = [];
    }

    addValue(search: string | null): void {
        if (search) {
            const items = uniq([...this.item.select, search]);
            this.changeChecker(items);
        }
    }

    changeChecker(items: string[]): void {
        if (items?.length > 0 && JSON.stringify(this.selectSub) === JSON.stringify(items)) return;
        this.selectSub = items;
        this.item.select = items;
        this.checked = !!(this.item.select?.length > 0 || this.item.isSubmitEmptySelect);
    }

    beforeDestroy(): void {
        eventBus.$off('clear-subs', this.clear);
    }
}
