import { AxiosResponse } from 'axios';
import {
    IAuthData,
    IAuthResponse,
} from '@/api/types/auth';
import {
    IServerResponse,
} from '@/api/types';
import { axiosInstance } from '@/api/index';
import { API_GATEWAY_URL, API_USERS_URL } from '@/configs/global';
import { deleteCookie } from '@/utils/cookie';

/**
 * Отправляем логин, пароль, получаем токен
 */
export async function postLogin(
    data: {
        password: string,
        email: string,
        code: string,
    },
): Promise<IAuthResponse> {
    return axiosInstance.request<IAuthData>({
        method: 'POST',
        url: `${API_GATEWAY_URL}/login`,
        data: {
            password: data.password,
            email: data.email,
            code: data.code,
        },
    })
        .then((response: {data: any}) => response.data.data);
}

/**
 * Разлогиниваемся
 */
export async function getLogout(): Promise<any> {
    deleteCookie('authenticated');
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_GATEWAY_URL}/logout`,
    })
        .then((response: {data: any}) => response.data.data);
}


/**
 *  Получение сервисов и ролей
 */
export async function getRoles(
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_USERS_URL}/users/roles`,

    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Получение данных пользователя из токена
 */
export async function getUserData(
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_GATEWAY_URL}/user-data`,

    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}
