import OfferDepartments from "@/views/administration/offers/Departments.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import OfferProduct from "@/views/administration/offers/Products.vue";
import OfferCountries from "@/views/administration/offers/Countries.vue";
import OfferTraffics from "@/views/administration/offers/Traffics.vue";
import OfferPayouts from "@/views/administration/offers/Payouts.vue";
import OfferStats from "@/views/administration/offers/Stats.vue";
import TargetActions from "@/views/administration/offers/TargetActions.vue";

export default [
    {
        path: 'departments',
        name: 'departments',
        component: OfferDepartments,
        meta: {
            title: 'Направления',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'products',
        name: 'products',
        component: OfferProduct,
        meta: {
            title: 'Типы продукта',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'countries',
        name: 'countries',
        component: OfferCountries,
        meta: {
            title: 'Страны',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'traffics',
        name: 'traffics',
        component: OfferTraffics,
        meta: {
            title: 'Типы трафика',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'payouts',
        name: 'payouts',
        component: OfferPayouts,
        meta: {
            title: 'Типы выплат',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'stats',
        name: 'stats',
        component: OfferStats,
        meta: {
            title: 'Типы статистики',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'target-actions',
        name: 'target-actions',
        component: TargetActions,
        meta: {
            title: 'Целевые действия',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
];
