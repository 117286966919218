import MainContent from '@/components/layout/MainContent.vue';
import Applications from '@/views/uapi/applications/List.vue';
import Application from '@/views/uapi/applications/Single.vue';
import OffersList from '@/views/uapi/offers/List.vue';
import OfferSingle from '@/views/uapi/offers/Single.vue';
import OffersReport from '@/views/uapi/offers-report/List.vue';
import Access from '@/views/uapi/settings/Access.vue';
import Delay from '@/views/uapi/settings/Delay.vue';
import Conversions from '@/views/uapi/conversions/List.vue';
import Errors from '@/views/uapi/errors/List.vue';
import ErrorsRules from '@/views/uapi/rules-errors/List.vue';
import SubstitutionList from '@/views/uapi/settings/substitution/List.vue';
import CreateSubstitution from '@/views/uapi/settings/substitution/Create.vue';
import SubstitutionSingle from '@/views/uapi/settings/substitution/Single.vue';
import BlackList from '@/views/uapi/settings/BlackList.vue';
import Streams from '@/views/uapi/settings/streams/List.vue';
import Stream from '@/views/uapi/settings/streams/Single.vue';
import Endpoints from '@/views/uapi/settings/endpoints/List.vue';
import Endpoint from '@/views/uapi/settings/endpoints/Single.vue';
import ConditionEndpoint from '@/views/uapi/settings/endpoints/Condition.vue';
import ConditionStream from '@/views/uapi/settings/streams/ConditionStream.vue';
import ConditionStage from '@/views/uapi/settings/streams/ConditionStage.vue';
import DistributionRulesList from '@/views/uapi/settings/distribution-rules/DistributionRulesList.vue';
import ConversionsForecast from '@/views/uapi/tools/ConversionsForecast.vue';
import ResendRequests from '@/views/uapi/tools/ResendRequests.vue';
import UapiReportFile from '@/views/uapi/reports/Single.vue';
import { USER_ROLES } from '@/mappings/user-roles';
import { Route } from "vue-router";
import FileIntegrations from "@/views/uapi/file-integrations/List.vue";

export default {
    path: '/uapi',
    name: 'uapi',
    component: MainContent,
    children: [
        {
            path: 'applications',
            name: 'applications',
            component: Applications,
            meta: {
                title: 'Заявки',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'applications/:uuid',
            name: 'show_application',
            component: Application,
            props: true,
            meta: {
                title: 'Просмотр заявки',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'offers',
            name: 'uapi-offers',
            component: OffersList,
            props: true,
            meta: {
                title: 'Список офферов',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'offers/:id',
            name: 'show-offer',
            component: OfferSingle,
            props: true,
            meta: {
                title: 'Оффер',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'offers-report',
            name: 'offers-report',
            component: OffersReport,
            props: true,
            meta: {
                title: 'Офферы',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'access',
            name: 'access',
            component: Access,
            props: true,
            meta: {
                title: 'Доступы',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'delay',
            name: 'delay',
            component: Delay,
            props: true,
            meta: {
                title: 'Отложка',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'conversions',
            name: 'conversions',
            component: Conversions,
            props: true,
            meta: {
                title: 'Конверсии',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'errors',
            name: 'errors',
            component: Errors,
            meta: {
                title: 'Отчет по ошибкам',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'rules-errors',
            name: 'rules-errors',
            component: ErrorsRules,
            meta: {
                title: 'Ошибки правил оффера',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'file-integrations',
            name: 'file-integrations',
            component: FileIntegrations,
            meta: {
                title: 'Файловые интеграции',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'endpoints',
            name: 'endpoints',
            component: Endpoints,
            meta: {
                title: 'Endpoints',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'endpoint/create',
            name: 'create-endpoint',
            component: Endpoint,
            meta: {
                title: 'Endpoint',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'endpoint/:endpointId',
            name: 'edit-endpoint',
            component: Endpoint,
            meta: {
                title: 'Endpoint',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'endpoint/:endpointId/condition',
            name: 'create-endpoint-condition',
            component: ConditionEndpoint,
            meta: {
                title: 'Create Endpoint Condition',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'endpoint/:endpointId/condition/:conditionId',
            name: 'edit-endpoint-condition',
            component: ConditionEndpoint,
            meta: {
                title: 'Edit Endpoint Condition',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'substitution',
            name: 'substitution',
            component: SubstitutionList,
            meta: {
                title: 'Автоподстановки',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'create-substitution',
            name: 'create-substitution',
            component: CreateSubstitution,
            meta: {
                title: 'Создание автоподстановки',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'substitution/:id',
            name: 'edit-substitution',
            component: SubstitutionSingle,
            props: true,
            meta: {
                title: 'Редактирование автоподстановки',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'blacklist',
            name: 'blacklist',
            component: BlackList,
            meta: {
                title: 'Блэклисты',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'streams',
            name: 'streams',
            component: Streams,
            props: true,
            meta: {
                title: 'Потоки',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'stream/create',
            component: Stream,
            name: 'stream-create',
            meta: {
                title: 'Поток',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'stream/:streamId',
            component: Stream,
            name: 'stream-edit',
            meta: {
                title: 'Поток',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'stream/:streamId/condition',
            name: 'create-stream-condition',
            component: ConditionStream,
            meta: {
                title: 'Create Stream Condition',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'stream/:streamId/condition/:conditionId',
            name: 'edit-stream-condition',
            component: ConditionStream,
            meta: {
                title: 'Edit Stream Condition',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'stream/:streamId/stage/:stageId/condition',
            name: 'create-stage-condition',
            component: ConditionStage,
            meta: {
                title: 'Create Stage Condition',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'stream/:streamId/stage/:stageId/condition/:conditionId',
            name: 'edit-stage-condition',
            component: ConditionStage,
            meta: {
                title: 'Edit Stage Condition',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'conversions-forecast',
            component: ConversionsForecast,
            name: 'conversions-forecast',
            hash: true,
            meta: {
                title: 'Прогноз конверсий',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'resend-requests',
            component: ResendRequests,
            name: 'resend-requests',
            hash: true,
            meta: {
                title: 'Переотправка заявок',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'report/file',
            name: 'uapi-report-file',
            component: UapiReportFile,
            props: (route: Route): any => ({ ...route.query }),
            meta: {
                title: 'UapiReportFile',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
        {
            path: 'distribution-rules',
            name: 'distribution-rules',
            component: DistributionRulesList,
            meta: {
                title: 'Правила распределения трафика',
                requiresAuth: true,
                roles: [USER_ROLES.ADMIN, USER_ROLES.API_OPERATOR],
            },
        },
    ],
};
