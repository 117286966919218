var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-card',{staticClass:"notification-recipient notification-recipient--offers mb-0",attrs:{"title":"Офферы"}},[_c('div',{staticClass:"notification-recipient--offers__wrap"},[_c('span',{staticClass:"notification-recipient--offers__info"},[_c('v-checkbox',{staticClass:"table-filter__checkbox table-filter__checkbox-design mt-0 notification-recipient--offers__checkbox",attrs:{"label":"Отправить всем","type":"checkbox","hide-details":"","color":"#1C2F45","ripple":false},on:{"change":function($event){_vm.notification.affiliates=[]}},model:{value:(_vm.notification.send_all),callback:function ($$v) {_vm.$set(_vm.notification, "send_all", $$v)},expression:"notification.send_all"}})],1),(_vm.notification.isOffer)?_c('base-autocomplete',{staticClass:"--outline notification-recipient--offers__advertiser",attrs:{"data":{
                errorText: 'Рекламодатель не найден',
                apiMethod: _vm.getAdvertisersSearch,
                multiple: false,
                itemValue: 'id',
                template: (item) => item.id + ' ' + item.name,
                label: 'Рекламодатель',
                clearable: true,
                chipWidth: 120,
                isChip: true,
            }},on:{"change":_vm.getOffers}}):_vm._e()],1),(_vm.notification.isOffer)?_c('base-autocomplete',{staticClass:"--outline --top",attrs:{"data":_vm.offerData,"is-show-unfounded":true,"is-return-object":true},on:{"change":_vm.setOffers}}):_vm._e(),_c('v-btn',{staticClass:"button button--outline notification-recipient--offers__btn-add",attrs:{"disabled":_vm.selectedOffers.length <= 0 && _vm.advertiserOffers.length <= 0},on:{"click":_vm.addOffers}},[_vm._v(" Добавить ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }