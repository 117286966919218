
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import SvgPlus from '@/assets/icons/plus.svg';
import SvgReload from "@/assets/icons/reload.svg";
import SvgCheck from "@/assets/icons/check.svg";
import SvgEdit from "@/assets/icons/pencil.svg";
import SvgTrash from "@/assets/icons/trash.svg";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { EmployeeMethod } from "@/services/administration/EmployeeMethod";
import { ILimit } from "@/api/types/payout";
import isEqual from "lodash-es/isEqual";

@Component({
    components: { BaseSelect, SvgTrash, SvgEdit, SvgCheck, SvgReload, BaseCard, SvgPlus },
})

export default class EmployeeMethodLimits extends Vue {
    @Prop({ required: true }) employeeMethod!: EmployeeMethod;
    @Inject() readonly translateCurrency!: any;
    @Inject() readonly getEmployeeMethod!: any;

    limits: ILimit[] = [];
    deletedLimitIds: number[] = [];
    isEdit = false;
    limitsTableHeaders = [
        { text: 'Валюта', value: 'currency', sortable: false },
        { text: 'Лимит', value: 'amount', sortable: false },
        { text: '', value: 'actions', sortable: false, width: '40px' },
    ];
    allCurrencies = ['RUB', 'EUR', 'USD', 'anything'];
    isSaveProcess = false;

    get isSaveDisabled(): boolean {
        return this.limits.some(limit => limit.amount <= 0) || this.isSaveProcess;
    }

    get availableCurrencies(): string[] {
        return this.allCurrencies.filter(currency => !this.limits.some(limit => limit.currency === currency));
    }

    mounted(): void {
        this.resetChanges();
    }

    getAvailableCurrenciesForItem(itemCurrency: string): { id: string }[] {
        const currencies = this.isEdit ? [...this.availableCurrencies, itemCurrency] : this.availableCurrencies;
        return currencies.sort().map(id => ({ id }));
    }

    deleteLimit(limit: ILimit): void {
        limit.id && this.deletedLimitIds.push(limit.id);
        this.limits = this.limits.filter(l => l.currency !== limit.currency);
    }

    addNewLimit(): void {
        if (this.availableCurrencies.length > 0) {
            this.limits.push({ currency: this.availableCurrencies[0], amount: 0 });
        }
    }

    getChangedLimits(): ILimit[] {
        return this.limits.map(limit => {
            limit.id && this.deletedLimitIds.push(limit.id);
            const { currency, amount } = limit;
            return { currency, amount };
        });
    }

    async editMode(isEdit: boolean): Promise<void> {
        if (isEdit) {
            this.limits.length === 0 && this.addNewLimit();
        } else if (!isEqual(this.limits, this.employeeMethod.monthly_limit)) {
            this.isSaveProcess = true;
            const monthly_limits_to_add = this.getChangedLimits();
            const monthly_limit_ids_to_delete = this.deletedLimitIds.length > 0 ? this.deletedLimitIds : undefined;
            const data = { monthly_limits_to_add, monthly_limit_ids_to_delete };
            await this.employeeMethod.updateEmployeeMethod(data);
            await this.getEmployeeMethod();
            this.resetChanges();
            this.isSaveProcess = false;
        }
        this.isEdit = isEdit;
    }

    resetChanges(): void {
        this.isEdit = false;
        this.deletedLimitIds = [];
        this.limits = JSON.parse(JSON.stringify(this.employeeMethod.monthly_limit));
    }
}
