
import { Vue, Component } from "vue-property-decorator";
import SvgChart from "@/assets/icons/chart.svg";
import SvgMagicWand from '@/assets/icons/magic-wand.svg';

@Component({
    components: { SvgMagicWand, SvgChart },
})

export default class InvoiceAndDownloadBtn extends Vue {
}
