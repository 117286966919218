
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import ListLayout from "@/components/layout/ListLayout.vue";
import TableMixin from "@/mixins/table";
import TablePageFilters from "@/components/base/filters/TablePageFilters.vue";
import { IColumnFilter, IFilter } from "@/types";
import { showServerError } from "@/utils";
import { Mutation } from "vuex-class";
import SvgFileDownloaded from '@/assets/icons/file-downloaded.svg';
import MonthPickerRange from "@/components/base/pickers/MonthPickerRange.vue";
import { eventBus } from "@/eventbus";
import { FilterDateParser } from "@/services/filters/dateParser";
import { DebtorsFilters } from "@/services/TablePage/debtors";

@Component({
    components: {
        TablePageFilters,
        ListLayout,
        MonthPickerRange,
        SvgFileDownloaded,
    },
})
export default class TablePageWrapper extends Mixins(TableMixin) {
    @Prop({ required: true }) title!: string;
    @Prop({ required: true }) settingsTitle!: string;
    @Prop({ default: false }) isGroupBy!: boolean;
    @Prop({ default: false }) showPeriod!: boolean;
    @Prop({ required: true }) columns!: IColumnFilter[];
    @Prop({ required: true }) filters!: DebtorsFilters;
    @Prop({ required: true }) tableOptions!: any;
    @Prop() createForm!: any;
    @Prop({ required: true }) errorText!: string;
    @Prop({ default: 500 }) colsWaxWidth!: number;
    @Prop({ default: 400 }) filtersWaxWidth!: number;
    @Prop({}) downloadOptions!: any;

    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;


    isNewDesignPage = true;
    tableComponent = this.tableOptions?.component;
    apiMethod = this.tableOptions?.apiMethod;
    itemsClass = this.tableOptions?.class;
    settings = { title: this.settingsTitle, loading: false };
    currentOffset = 0;
    isTableWithoutSections = false;

    @Watch('$props.tableOptions', { immediate: true })
    watchApiMethod(value: any): void {
        this.apiMethod = value.apiMethod;
        this.tableComponent = value.component;
    }

    created(): void {
        this.setUnsavedDataState(false);
        this.setAbortTransition(false);
    }

    mounted(): void {
        this.getPagination();
        this.isTableWithoutSections = !this.isTableSectionsAvailability;
        window.addEventListener('resize', this.adjustTableHeight);
        this.adjustTableHeight();
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.adjustTableHeight);
    }

    get periodFilter(): any {
        return this.filters.filters.find(i => i.id === 'period');
    }

    changeFilters(e: any): void {
        if( !e || e.length <= 0) return;
        const param = new FilterDateParser({ period: e })!.parse(this.periodFilter!);
        const filters = { ...this.getFiltersParamsObject, ...param };
        this.setFiltersParamsObject(filters);
        this.updateQueryString(filters);
        this.getPagination(0);
    }

    get isTableSectionsAvailability(): boolean {
        const el = this.$el;
        return !!el.querySelector('.extended-report-table__custom-header');
    }

    get getAttach(): string {
        return `.attach-${this.$route.name}`;
    }

    clearFilters(): void {
        this.clearFilter();
        this.$emit('update-total');
    }

    async getPagination(offset?: number): Promise<void> {
        eventBus.$emit('get-input');
        this.currentOffset = offset || 0;
        try {
            if (!!this.tableOptions.class) {
                this.itemsClass = this.tableOptions.class;
            }
            await this.getTableData(offset);
            this.$emit('update-total');
        } catch (err) {
            showServerError(err, this.errorText);
        }
        this.adjustTableHeight();
    }

    adjustTableHeight(): void {
        this.$nextTick(() => {
            setTimeout(() => {
                const mainTable = this.$el.querySelector('.table-page__table') as HTMLElement;
                const summaryTable = this.$el.querySelector('.summary-table') as HTMLElement;
                if (mainTable && summaryTable) {
                    const summaryTableHeight = summaryTable.clientHeight;
                    mainTable.style.setProperty('--summary-table-height', `${summaryTableHeight}px`);
                }
            }, 500);
        });
    }


    clearDateRangeFilter(filter:IFilter): void {
        filter.select = [];
        const filters = { ...this.getFiltersParamsObject };
        delete filters[`${filter.id}_from`];
        delete filters[`${filter.id}_to`];
        this.setFiltersParamsObject(filters);
    }
}
