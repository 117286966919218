import {
    differenceInDays,
    endOfWeek,
    format, lastDayOfMonth, parseISO, startOfWeek, subMonths, subWeeks,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { toDate, utcToZonedTime } from 'date-fns-tz';

export const getFormatDatesForDatepicker = (initialDates: string[] | string, pickerType: 'date' | 'month'): string | void => {
    if (!initialDates) { return; }
    const pattern = pickerType === 'month' ? 'LLLL yyyy' : 'd MMMM yyyy';
    if (typeof initialDates === 'string') {
        const formattingDate = initialDates?.length === 7 ? `${initialDates}-01` : initialDates;
        return format(parseISO(formattingDate), pattern, { locale: ru });
    }
    if (initialDates instanceof Array) {
        if (initialDates.length === 1) {
            initialDates[0] = initialDates[0]?.length === 7 ? `${initialDates[0]}-01` : initialDates[0];
            return format(parseISO(initialDates[0]), pattern, { locale: ru });
        }
        if (initialDates.length === 2) {
            initialDates[0] = initialDates[0]?.length === 7 ? `${initialDates[0]}-01` : initialDates[0];
            initialDates[1] = initialDates[1]?.length === 7 ? `${initialDates[1]}-01` : initialDates[1];
            const isNeedToSwap = initialDates[0].valueOf() > initialDates[1].valueOf();
            const yearOfFirstDate = parseISO(initialDates[`${isNeedToSwap ? 1 : 0}`]).getFullYear();
            const yearOfSecondDate = parseISO(initialDates[`${isNeedToSwap ? 0 : 1}`]).getFullYear();
            const formatSecondDate = format(
                parseISO(initialDates[`${isNeedToSwap ? 0 : 1}`]),
                pattern,
                { locale: ru },
            );
            let formatFirstDate;
            if (yearOfFirstDate === yearOfSecondDate) {
                const currentPattern = pickerType === 'month' ? 'LLLL' : 'd MMMM';
                formatFirstDate = format(
                    parseISO(initialDates[`${isNeedToSwap ? 1 : 0}`]),
                    currentPattern,
                    { locale: ru },
                );
            } else {
                formatFirstDate = format(parseISO(initialDates[`${isNeedToSwap ? 1 : 0}`]), pattern, { locale: ru });
            }
            return initialDates[0] === initialDates[1] ? `${formatSecondDate}` : `${formatFirstDate} - ${formatSecondDate}`;
        }
    }
};

// TODO вставить эту функцию во всех местах, где получаем текущую дату,
// например, вместо new Date().toISOString().substr(0, 7)

// Получить текущую дату, в приложении используем таймзону `Europe/Moscow`
export const getCurrentDate = (formatType: string = 'dd-MM-yyyy'): string => {
    const utcDate = toDate(new Date(), { timeZone: 'UTC' });
    const zonedDate = utcToZonedTime(utcDate, 'Europe/Moscow');
    return format(zonedDate, formatType);
};


export const getPreviousDate = (formatType: string = 'dd-MM-yyyy', days: number = 1): string => {
    const utcDate = toDate(new Date(), { timeZone: 'UTC' });
    const zonedDate = utcToZonedTime(utcDate.setDate(utcDate.getDate() - days), 'Europe/Moscow');

    return format(zonedDate, formatType);
};

export const getFullPreviousWeek = (formatType: string = 'dd-MM-yyyy'): string[] => {
    const utcDate = toDate(new Date(), { timeZone: 'UTC' });
    const zonedDateStart = utcToZonedTime(utcDate.setDate(utcDate.getDate() + 1 - utcDate.getDay()), 'Europe/Moscow');
    const start = format(subWeeks(zonedDateStart, 1), formatType);
    const zonedDateEnd = utcToZonedTime(utcDate.setDate(utcDate.getDate() - utcDate.getDay()), 'Europe/Moscow');
    const end = format(zonedDateEnd, formatType);
    return [start, end];
};

export const getCurrentWeek = (formatType: string = 'dd-MM-yyyy'): string[] => {
    const utcDate = toDate(new Date(), { timeZone: 'UTC' });
    const zonedDateStart = utcToZonedTime(utcDate.setDate(utcDate.getDate() - 6), 'Europe/Moscow');
    const start = format(zonedDateStart, formatType);
    const end = getCurrentDate(formatType);
    return [start, end];
};

export const getFullCurrentWeek = (formatType: string = 'dd-MM-yyyy'): string[] => {
    const utcDate = toDate(new Date(), { timeZone: 'UTC' });
    const zonedDateStart = utcToZonedTime(utcDate.setDate(utcDate.getDate() + 1 - utcDate.getDay()), 'Europe/Moscow');
    const start = format(zonedDateStart, formatType);
    const end = getCurrentDate(formatType);
    return [start, end];
};

export const getFullPreviousMonth = (formatType: string = 'dd-MM-yyyy', startDate: Date): string[] => {
    const utcDate = toDate(new Date(), { timeZone: 'UTC' });
    utcDate.setDate(1);
    const zonedDateStart = utcToZonedTime(utcDate, 'Europe/Moscow');
    const prevZonedDateStart = subMonths(zonedDateStart, 1);
    const start = format(prevZonedDateStart < startDate ? startDate : prevZonedDateStart, formatType);
    const zonedDateEnd = utcToZonedTime(utcDate.setDate(utcDate.getDate() - 1), 'Europe/Moscow');
    const end = format(startDate > zonedDateEnd ? startDate : zonedDateEnd , formatType);
    return [start, end];
};

export const getFullCurrentMonth = (formatType: string = 'dd-MM-yyyy'): string[] => {
    const start = getCurrentMonth(formatType);
    const end = getCurrentDate(formatType);
    return [start, end];
};

export const getPreviousMonth = (formatType: string = 'dd-MM-yyyy', month: number): string => {
    const utcDate = toDate(new Date(), { timeZone: 'UTC' });
    utcDate.setDate(1);
    const zonedDate = utcToZonedTime(utcDate, 'Europe/Moscow');
    return format(subMonths(zonedDate, month), formatType);
};

export const getCurrentMonth = (formatType: string = 'dd-MM-yyyy'): string => {
    const utcDate = toDate(new Date(), { timeZone: 'UTC' });
    utcDate.setDate(1);
    const zonedDate = utcToZonedTime(utcDate, 'Europe/Moscow');
    return format(zonedDate, formatType);
};

export const getCurrentYear = (formatType: string = 'dd-MM-yyyy', startDate: Date = new Date()): string[] => {
    const utcDate = toDate(new Date(new Date().getFullYear(), 0, 1), { timeZone: 'UTC' });
    const zonedDateStart = utcToZonedTime(utcDate, 'Europe/Moscow');
    const currentStart = zonedDateStart > startDate ? zonedDateStart : startDate;
    const start = format(currentStart, formatType);
    const end = getCurrentDate(formatType);
    return [start, end];
};

// Получить отформатированную дату в читаемом виде, например:
// 1 апреля 2020 15:14
export const getFormatDate = (date: string | Date, formatType: string = 'd MMMM yyyy HH:mm'): string => {
    if (typeof date === 'string') {
        return format(new Date(parseISO(date)), formatType, { locale: ru });
    }
    return format(new Date(date), formatType, { locale: ru });
};

export const getLastDayOfCurrentMonth = (formatType: string = 'yyyy-MM-dd'): string => {
    const lastDay = lastDayOfMonth(new Date());
    const zonedDate = utcToZonedTime(lastDay, 'Europe/Moscow');
    return format(zonedDate, formatType);
};

//  Получить последний день месяца
export const getLastDayOfMonth = (value: string | Date): Date => {
    const date = new Date(value);
    const y = date.getFullYear();
    const m = date.getMonth();
    return new Date(y, m + 1, 0);
};

export const formatDateInputPicker = (value: string, formatType="dd.MM.yy"): string =>{
    return value !== '' ? format(parseISO(value),formatType, { locale: ru }) : '';
};

export const getWeek = (value: string | Date, formatType: string = 'yyyy-MM-dd'): string[] | Date[] => {
    const date = value instanceof Date ? value : new Date(parseISO(value));
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = endOfWeek(date, { weekStartsOn: 1 });
    return [format(start, formatType), format(end, formatType)];
};

export const getDifferenceInDays = (dateOne: Date, dateTwo: Date): number => {
    return Math.abs(differenceInDays(dateOne, dateTwo));
};
