import { CreatedFilters } from "@/services/filters/filters";
import { IColumnFilter, IFilter } from "@/types";
import { CreatedColumns } from "@/services/columns/columns";
import { getAffiliateSearch, getActiveUserSearch } from "@/api/user";
import { translatePartnerStatus } from "@/utils/translate";
import { filterClass } from "@/services/filters/filterClass";

export class CheckingAffiliateColumns extends CreatedColumns {
    constructor() {
        super();
    }
    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'ID партнёра', value: 'account_id' },
                    { title: 'ФИО', value: 'name' },
                    { title: 'ID менеджера', value: 'manager_id' },
                    { title: 'ФИО менеджера', value: 'manager_name' },
                    { title: 'Зарегистрирован', value: 'signed_at' },
                    { title: 'Добавлен в список', value: 'created_at' },
                    { title: 'Кем добавлен', value: 'creator' },
                    { title: 'Удалён из списка', value: 'deleted_by' },
                    { title: 'Статус', value: 'status' },
                    { title: 'Действия', value: 'actions' },
                ],
            },
        ];
    }
}

export class CheckingAffiliateFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Autocomplete({
                id: 'account_id',
                label: 'Партнер',
                apiMethod: getAffiliateSearch,
                itemValue: 'id',
                itemText: 'name',
                select: '',
                items: [],
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
            }),
            new filterClass.Autocomplete({
                id: 'manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: '',
                items: [],
                multiple: false,
            }),
            new filterClass.Datepicker({
                id: 'created',
                label: 'Добавлен в список',
                pickerType: 'date',
                select: [],
                range: true,
            }),
            new filterClass.Datepicker({
                id: 'signup',
                label: 'Дата регистрации',
                pickerType: 'date',
                select: [],
                range: true,
            }),
            new filterClass.Select({
                id: 'status',
                label: 'Статус',
                items: ['active', 'deleted'],
                select: '',
                placeholder: '',
                translate: (i) => this.translateStatus(i, translatePartnerStatus),
            }),
        ];
    }
}
