import Vue from 'vue';
import Component from 'vue-class-component';
import AppTable from '@/components/base/table/AppTable.vue';
import { Getter, Mutation, namespace } from 'vuex-class';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { ISortFilters } from '@/store/modules/uapi/types';

const uapi = namespace('uapiModule');

@Component({
    components: { AppTable },
})
export default class SortTableMixin extends Vue {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParamObject;

    @uapi.Mutation('SET_SORT_FILTERS') setSortFilters;
    @uapi.Getter('GET_DEFAULT_OPTIONS') getDefaultOptions;
    @uapi.Getter('GET_SORT_FILTERS') getSortFilters;

    limit = PAGINATION_PER_PAGE || 100;
    searchTimerId = 0;

    field = 'created_at';
    description = false;

    get sortBy(): string {
        return this.field;
    }

    set sortBy(value: string) {
        this.field = value;
    }

    get sortDesc(): boolean {
        return this.description;
    }

    set sortDesc(value: boolean) {
        this.description = value;
    }

    mounted(): void {
        const { sortBy, sortDesc } = this;
        const options = {
            sortBy,
            sortDesc,
        };
        this.updateOptions(options);
    }

    updateSortDesc(sortDesc: boolean): void {
        const options = {
            sortBy: this.sortBy,
            sortDesc,
        };
        this.updateOptions(options);
    }

    updateSortBy(sortBy: string): void {
        const options = {
            sortDesc: this.sortDesc,
            sortBy,
        };
        this.updateOptions(options);
    }

    debounceGetDataTable(): void {
        clearTimeout(this.searchTimerId);
        this.searchTimerId = window.setTimeout(() => {
            // @ts-ignore
            this.getDataTable(+this.$route.query?.offset || 0, this.limit);
        }, 350);
    }

    updateOptions(options: { sortDesc: boolean; sortBy: string; }): void {
        const optionsFilters = this.transpilingOptionsToFilters(options);
        this.setSortFilters(optionsFilters);
        this.debounceGetDataTable();
    }

    clearFilters(): void {
        // @ts-ignore
        this.getDataTable(0, this.limit);
    }

    transpilingOptionsToFilters(options: { sortDesc: boolean; sortBy: string; }): ISortFilters {
        const { sortDesc, sortBy } = options;
        const optionsFilters: ISortFilters = {};
        optionsFilters.sort_column = sortBy;
        optionsFilters.sort_direction = this.getSortType(sortDesc);
        return optionsFilters;
    }

    getSortType(item: boolean): string {
        switch (item) {
        case true:
            return 'desc';
        case false:
            return 'asc';
        default:
            return item;
        }
    }

    beforeDestroy(): void {
        this.setSortFilters({});
    }
}
