
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mutation, namespace } from 'vuex-class';
import StatsCard from '@/components/base/StatsCard.vue';
import { editUserSettings } from '@/api/user';
import { IUser } from '@/api/types/user';
import store from '@/store';
import { showServerError } from '@/utils';

const auth = namespace('authModule');

@Component({
    components: {
        StatsCard,
    },
})
export default class OnOff2fa extends Vue {
    @Prop({ type: Object, default: null }) crmUser!: IUser;
    @Prop({ required: false }) readonly forShutdownOnly!: boolean;
    @Mutation('SET_NOTIFICATION') setNotification;
    @auth.Getter('GET_TWO_FACTORS_STATUS') is2faEnabled;
    @auth.Mutation('SET_TWO_FACTORS_STATUS') setTwoFactorsStatus;

    dialog = false;
    linkToQrCode: string = '';
    imgLoaded: boolean = false;
    twoFactorCode = '';

    async editUserSettings(): Promise<void> {
        try {
            await this.disableTwoFactor();
            const { account_id, account_type } = this.$props.crmUser;
            const { img } = await editUserSettings(
                account_id,
                account_type,
                { is_two_factor_enable: !this.is2faEnabled },
            );
            this.linkToQrCode = img;
            this.dialog = true;
        } catch (err) {
            showServerError(err);
        }
    }

    showNotification(text: string): void {
        this.setNotification({ text });
        setTimeout(() => {
            this.setNotification({ text: '' });
        }, 2000);
    }

    async disableTwoFactor(): Promise<void> {
        try {
            if (this.$props.forShutdownOnly) {
                if (this.crmUser.is_two_factor_enabled) {
                    this.showNotification('Двухфакторная аутентификация отключена');
                }
            } else {
                const { account_id, account_type } = this.$props.crmUser;
                await editUserSettings(
                    account_id,
                    account_type,
                    { is_two_factor_enable: false },
                );

                if (this.is2faEnabled) {
                    this.showNotification('Двухфакторная аутентификация отключена');
                }
            }

            this.twoFactorCode = '';
            this.setTwoFactorsStatus(false);
        } catch (err) {
            showServerError(err);
        }
    }

    async send2faCode(): Promise<void> {
        if (this.twoFactorCode.length < 7) {
            return;
        }
        try {
            const code = this.twoFactorCode.replace(/-/g, '');
            const { account_id, account_type } = this.$props.crmUser;
            const { is_two_factor_enabled } = await editUserSettings(
                account_id,
                account_type,
                { two_factor_code: code, is_two_factor_enable: !this.is2faEnabled },
            );

            this.dialog = false;
            this.twoFactorCode = '';
            this.setTwoFactorsStatus(is_two_factor_enabled);
            this.showNotification('Двухфакторная аутентификация включена');
        } catch (err) {
            const subtitle = `Для корректной работы синхронизируйте время
            в приложении Google Authenticator (Настройки -> Коррекция времени для кодов -> Синхронизация)`;
            store.commit('SET_SERVER_ERROR', {
                status: err.status,
                message: 'Неправильный код',
                subtitle,
            });
        }
    }
    async cancel(): Promise<void> {
        try {
            const { account_id, account_type } = this.$props.crmUser;
            await editUserSettings(
                account_id,
                account_type,
                { is_two_factor_enable: false },
            );
            this.dialog = false;
        } catch (err) {
            showServerError(err);
        }
    }
}
