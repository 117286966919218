var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShowPage)?_c('page-layout',{staticClass:"create-survey survey",class:{'survey-view': !_vm.isEditing},attrs:{"max-width":_vm.isEditing ? '800px' : '1137px',"title":_vm.titleSurvey,"back-route":"/support/surveys"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('status',{staticClass:"ml-3",attrs:{"status":_vm.getStatus,"large":true}})]},proxy:true}],null,false,633295291)},[(_vm.survey)?_c('survey-header',{staticClass:"survey__header",attrs:{"survey":_vm.survey,"is-edit":_vm.isEditing},scopedSlots:_vm._u([{key:"item",fn:function(){return [(!_vm.isEditing)?_c('div',{staticClass:"mb-0 base-list__item survey__select-affiliate"},[_c('span',{staticClass:"base-list__text text-left"},[_vm._v("Партнёр")]),_c('div',{staticClass:"base-list__value base-list__value--dashed"},[_c('base-autocomplete',{staticClass:"survey__item-affiliate",attrs:{"data":{
                            errorText: 'Партнер не найден',
                            itemValue: 'id',
                            itemText: 'itemText',
                            template: (item) => item.itemText,
                            placeholder: 'Выберите партнёра',
                            multiple: false,
                        },"possible-items":_vm.votingUsers,"is-return-object":true,"is-trash":_vm.isShowSurveyByAffiliate},on:{"update:possibleItems":function($event){_vm.votingUsers=$event},"update:possible-items":function($event){_vm.votingUsers=$event},"change":_vm.getSurveyByAffiliate}})],1)]):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [(!_vm.isEditing)?_c('div',{staticClass:"survey__actions"},[(_vm.survey.isSurveyActive)?_c('v-btn',{staticClass:"button survey__btn-finish button--outline ml-auto mr-2",on:{"click":_vm.closeSurvey}},[_c('span',[_vm._v("Завершить опрос")])]):_vm._e(),(_vm.survey.isCanEdit)?_c('edit-btn',{attrs:{"custom-btn-class":"survey__btn-toggle","is-edit":false,"is-tooltip":false},on:{"toggle":_vm.toggleEditing}}):_vm._e()],1):_vm._e()]},proxy:true}],null,false,767530945)}):_vm._e(),(_vm.survey.isSurveyQuestions && !_vm.isShowSurveyByAffiliate)?_c('survey-body',{staticClass:"survey__body",attrs:{"questions":_vm.survey.questions,"is-create-survey":_vm.isEditing,"is-title":_vm.isEditing},on:{"delete":_vm.deleteQuestion}}):_vm._e(),(_vm.isShowSurveyByAffiliate )?_c('h2',{staticClass:"survey__affiliate-title"},[_vm._v(" "+_vm._s(_vm.affiliateFilterTitle)+" ")]):_vm._e(),(_vm.isShowSurveyByAffiliate)?_c('survey-affiliate-table',{staticClass:"table-front-pagination",attrs:{"items":_vm.affiliateSurvey}}):_vm._e(),(_vm.isEditing)?_c('div',{staticClass:"create-survey__footer"},[_c('v-btn',{staticClass:"button button--outline ml-auto mr-2 create-survey__btn-create",on:{"click":function($event){return _vm.survey.addQuestion()}}},[_c('SvgPlusIcon',{staticClass:"mr-1"}),_c('span',[_vm._v("Новый вопрос")])],1),_c('edit-btn',{attrs:{"custom-btn-class":"survey__btn-toggle","is-edit":true,"is-tooltip":false},on:{"toggle":_vm.saveSurvey}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }