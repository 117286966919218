
import { Component, Vue } from 'vue-property-decorator';
import AffiliateInvoicesWrap from "@/components/affiliate/affiliateInvoices/AffiliateInvoicesWrap.vue";
import { getAffiliateInvoices, getAffiliateInvoicesSummary } from "@/api/payout";
import {
    AffiliateInvoicesColumns,
    AffiliateInvoicesFilters,
    AffiliateInvoicesHeaders,
} from "@/services/TablePage/affiliateInvoices";

@Component({
    components: {
        AffiliateInvoicesWrap,
    },
    methods: {
        getAffiliateInvoices,
        getAffiliateInvoicesSummary,
    },
})
export default class AffiliateInvoices extends Vue {
    filters = new AffiliateInvoicesFilters();
    columns = new AffiliateInvoicesColumns();
    tableHeaders = AffiliateInvoicesHeaders;

    transformParamsObjBeforeSubmit(params: any): any | Promise<any> {
        // получить поле affiliate
        params.is_need_affiliate = 'true';
        return params;
    }
}
