export enum TYPERATE {
    FIX = 'fixed',
    PER = 'percent'
}

export const getRateUtil = (type: string, fixedAmount: string, currencyIcon: string, amount: number): string => {
    if (type === TYPERATE.FIX) {
        return `${fixedAmount}${currencyIcon}`;
    } else {
        return `${amount}%`;
    }
};

export const getRateValue = (type: string, amount: number): string | number => {
    return type === TYPERATE.FIX ? amount.toLocaleString('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }) : amount;
};

export const getRatePrefix = (type: string, currencyIcon: string): string => {
    return  type === TYPERATE.FIX ? currencyIcon : '%';
};

//если в пейауте тип fixed, тогда  в прибыли ставим валюту пейута, иначе процент
export const getMarginUtil = (type: string, fixedAmount: string, currencyIcon: string, supKey: string, margin: number): string => {
    switch (supKey) {
    case 'amount':
        return getRateUtil(type, fixedAmount, currencyIcon, margin);
    case 'percent':
        return `${margin}%`;
    default: return '';
    }
};

//если в пейауте тип fixed, тогда  в прибыли ставим валюту пейута, иначе процент
export const getMarginPrefix = (supKey: string, currencyIcon: string, type: string): string => {
    switch (supKey) {
    case 'amount':
        return getRatePrefix(type,currencyIcon) ;
    case 'percent':
        return '%';
    default: return '';
    }
};

export const getMarginValue = (supKey: string, margin: number, type: string): string | number => {
    switch (supKey) {
    case 'amount':
        return getRateValue(type,margin) ;
    case 'percent':
        return margin;
    default: return '';
    }
};

export const getDigitsBeforeComma = (type: string): number => {
    return type === TYPERATE.FIX ? 6 : 2;
};


