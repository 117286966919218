import { GetterTree } from 'vuex';

import { IRootState } from '@/store/types';
import { IOffersState } from "@/store/modules/offers/types";

export const getters: GetterTree<IOffersState, IRootState> = {
    GET_VERTICALS: (state) => state.verticals,
    GET_VERTICAL_NAME: (state) => (id) => state.verticals.find(v => v.id === id)?.name || '',
    GET_CURRENT_OFFER: (state) => state.current_offer,
};
