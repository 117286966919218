
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
    },
})

export default class EditModeTextField extends Vue {
    @PropSync('value', { required: true }) valueSync!: string | number | null;
    @Prop({ default: null }) appendOuter!: object;
    @Prop({ default: '' }) suffix!: string;
    @Prop({ default: '' }) prefix!: string;
    @Prop({ default: 6 }) digitsBeforeComma!: number;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) readonly!: boolean;
    @Prop({ default: '' }) placeholder!: string;
    @Prop({ default: false }) isStickySuffix!: boolean;
    @Prop({ default: false }) isReturnNull!: boolean;
    @Prop({ default: false }) isOutline!: boolean;

    inputValue: string = '';

    get getSuffix(): string {
        return this.isStickySuffix ? '' : this.suffix;
    }

    get getSuffixPosition(): number {
        return this.inputValue.length * 0.68 ;
    }

    appendOuterClick(): void {
        this.$emit('append-outer-click');
    }

    input(value: string): void {
        const lastSymbol = value.slice(-1);
        const isWithComma = value.split(',').length - 1 > 1;
        const regex = (value.length === 1 || isWithComma) ? /\d/ : /[\d,]/;
        const newValue = (regex).test(lastSymbol) ? value : value.slice(0, -1);
        const comma = newValue.indexOf(',');
        const beforeComma = comma === -1 ? newValue : newValue.substring(0, comma);
        const afterComma = comma === -1 ? '' : newValue.substring(comma, newValue.length - 1);
        const writeResult = (result: string) => {
            this.$nextTick(() => {
                this.inputValue = result;
                this.change(false);
            });
        };
        if (beforeComma.length > this.digitsBeforeComma && Number(value) > 100 && comma === -1) {
            writeResult(newValue.slice(0,-1) + ',' + lastSymbol);
        } else if (afterComma.length > 2) {
            writeResult(newValue.slice(0,-1));
        } else {
            writeResult(newValue);
        }
    }

    change(emit: boolean = true): void {
        this.inputValue = this.inputValue.replaceAll(' ', '');
        this.valueSync = this.inputValue !== '' ?
            Number(this.inputValue.replaceAll(',', '.')) :
            this.isReturnNull ? null : '';
        if (emit) this.$emit('change');
    }

    enter(): void {
        this.$emit('enter');
    }

    created(): void {
        if (typeof this.valueSync === 'number' && Number.isInteger(this.valueSync)) {
            this.valueSync = this.valueSync.toFixed(2);
        }
        this.updateInputValue();
    }

    @Watch('valueSync')
    updateInputValue(): void {
        if (this.valueSync === null || this.valueSync === undefined) {
            this.inputValue = '';
        } else {
            const dot = String(this.valueSync).indexOf('.');
            if (dot !== -1) {
                const digitsAfterDot = String(this.valueSync).length - dot - 1;
                const value = Number(this.valueSync).toFixed(digitsAfterDot > 2 ? 2 : digitsAfterDot);
                this.inputValue = String(value).replaceAll('.', ',');
            } else {
                this.inputValue = String(this.valueSync);
            }
        }
    }
}
