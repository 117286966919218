
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { ITag as IAffiliateTag } from "@/api/types/user";
import { ITag as IOfferTag } from "@/api/types/offers";
import { showNotification, showServerError } from "@/utils";
import Loader from "@/components/base/Loader.vue";
import FormModal from "@/components/base/FormModal.vue";
import ComboboxArrayDesign from "@/components/base/form/ComboboxArrayDesign.vue";

@Component({
    components: {
        ComboboxArrayDesign,
        FormModal,
        Loader,
        BaseAutocomplete,
    },
})

export default class TagSelect extends Vue {
    @Prop({ required: true }) getTagsMethod!: Function;
    @Prop({ required: true }) createTagMethod!: Function;
    @Prop({ required: true }) defaultValue!: number[];

    @PropSync('value', { required: true }) valueSync!: number[];

    tags: IAffiliateTag[] | IOfferTag[] = [];
    newTags: string[] = [];
    isShowModal: boolean = false;
    isLoading: boolean = false;
    savedDefaultValue: number[] = [];

    blurTag(tag: string): void {
        !!tag && this.newTags.push(tag);
    }

    closeModal(): void {
        this.newTags = [];
        this.isShowModal = false;
    }

    async saveNewTag(): Promise<void> {
        try {
            this.isLoading = true;
            const timeTags: number[] = [];
            this.savedDefaultValue = this.valueSync;

            await Promise.all(this.newTags.map(tag => this.createTagMethod({ name: tag })));
            await this.getTags();

            this.tags.forEach((el: IAffiliateTag) => {
                if (this.newTags.find(f => f === el.name)) {
                    timeTags.push(el.id as number);
                }
            });

            this.savedDefaultValue = [...this.savedDefaultValue, ...timeTags];

            showNotification('Тег успешно создан');
            this.closeModal();
        } catch (err) {
            showServerError(err, 'Ошибка создания тега');
        } finally {
            this.isLoading = false;
        }
    }

    created(): void {
        this.savedDefaultValue = [...this.defaultValue];
        this.getTags();
    }

    async getTags(): Promise<void> {
        try {
            this.tags = await this.getTagsMethod();
        } catch (err) {
            showServerError(err, 'Ошибка загрузки тегов');
        }
    }
}

