import { RouteConfig } from 'vue-router';
import { INavigationExtraItems } from '@/api/types/revenue';
import { routes } from '@/router';
import store from '@/store/index';


export const checkRole = (inspectingRoutes: INavigationExtraItems[]): INavigationExtraItems[] => {
    const allRoutes = collectAllRoutes(routes);
    const routesWithRoles = allRoutes.filter(item => item.meta?.hasOwnProperty('roles'));
    inspectingRoutes.forEach((item:INavigationExtraItems) => {
        const currentRoute = routesWithRoles.find(route => route.name === item.routeName);
        item.show = currentRoute ?
            currentRoute.meta!.roles!.some((role) => store.getters['authModule/GET_USER'].roles.includes(role)) :
            !!item.routeName;
    });
    return inspectingRoutes;
};

const collectAllRoutes = (routes: RouteConfig[]): RouteConfig[] => {
    const parentRoutes = routes.filter(item => item.hasOwnProperty('meta') && item.hasOwnProperty('name'));
    const childRoutes = routes.reduce((acc: RouteConfig[], item: RouteConfig) => {
        if (item.hasOwnProperty('children')) {
            const items = collectAllRoutes(item.children!);
            return [ ...acc, ...items ];
        } else {
            return [ ...acc ];
        }
    }, []);

    return [ ...parentRoutes, ...childRoutes];
};
