
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import FormModal from '@/components/base/FormModal.vue';
import Status from '@/components/base/Status.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import MultiButton from '@/components/base/buttons/MultiButton.vue';
import CardMaterial from '@/components/base/CardMaterial.vue';
import { eventBus } from '@/eventbus';
import {
    IOfferService,
    IOtherService,
} from '@/api/types/revenue';
import { REPORT_STATUS } from '@/mappings/reportStatus';
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";

@Component({
    components: {
        EditModeTextField,
        Status,
        TooltipButton,
        MultiButton,
        CardMaterial,
        FormModal,
    },
})

export default class AddingServices extends Vue {
    // Заголовки таблицы
    @Prop({ required: true }) headers!: [];
    // Элементы, добавленные в таблицу
    @Prop({ required: false }) items!: [];
    // Таблицу показываем, если нет загрузки
    @Prop({ required: false }) loading!: boolean;
    // Цвет заголовков таблицы
    @Prop({ required: false, default: 'primary' }) activeColor!: string;
    // Передаем пропс, если таблица услуг внутри бухгалтерского счёта
    @Prop({ required: false }) isAccounting!: boolean;
    @Prop({ required: false }) isReadonly!: boolean;
    @Prop({ required: false }) accountingStatus!: string;
    // Возможность указать, что услуга относится к прямой выручке
    @Prop({ required: false }) directSupport!: boolean;
    // Тип услуги, потом передаем при удалении элемента
    @Prop({ required: true }) typeOfServices!: string;
    // Статус
    @Prop({ default: () => [] }) statuses!: [];
    @Prop({ default: () => [] }) actions!: [];
    @Prop({ required: false }) accountIndex!: number;
    @Prop({ required: false }) isCalendarsAdded!: boolean;
    @Prop({ required: false }) isClickable!: boolean;
    // Показывать или нет "Итого" в конце таблицы
    @Prop({ type: Boolean, default: false }) showTotal!: boolean;
    // Иконка валюты
    @Prop({ type: String, default: '' }) currencyIcon!: string;
    @Prop({ default: false }) isPrepayment!: boolean;
    @Prop({ required: false, default: false }) isFinmedia!: string;

    @Getter('GET_EDITING_MODE') getEditingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    REPORT_STATUS = REPORT_STATUS;
    // для маски
    mask = '';
    offerId: number | null | string = null;
    showModalForResetCalendar: {[key: string]: null | string} = {
        item: null,
        name: null,
    };

    get editingMode(): boolean {
        return this.getEditingMode;
    }

    get isDisabled(): boolean {
        if (this.$props.isReadonly) {
            return true;
        }
        if ((this.$props.isAccounting && (
            this.$props.accountingStatus === 'paid'
            || this.$props.accountingStatus === 'partly_paid')
            && !this.$props.isPrepayment
        )
        ) {
            return true;
        }
        return !this.getEditingMode;
    }

    get total(): number {
        const propertyName = this.$props.items[0]
            && this.$props.items[0].hasOwnProperty('total')
            ? 'total'
            : 'service_amount';
        return this.$props.items.reduce((acc, item) => acc + Number(item[propertyName]), 0);
    }

    checkRules(v: number | string): boolean | string {
        return !!Number(v) || '';
    }

    setShowModalForResetCalendar(item: string, name: string): void {
        this.showModalForResetCalendar.item = item;
        this.showModalForResetCalendar.name = name;
    }

    clearShowModalForResetCalendar(): void {
        this.showModalForResetCalendar.item = null;
        this.showModalForResetCalendar.name = null;
    }

    emittedDeleteOffer(): void {
        const { item, name } = this.showModalForResetCalendar;
        const deletedItemIndex = this.$props.items.indexOf(item);

        if (!this.$props.isAccounting) {
            eventBus.$emit('delete-offer', deletedItemIndex, name);
        } else {
            eventBus.$emit('delete-accounting-offer', deletedItemIndex, name, this.accountIndex);
        }
        this.setUnsavedDataState(true);
        this.clearShowModalForResetCalendar();
    }

    emitCustomerOrderAmountChanged(item: IOtherService): void {
        const currentItemIndex = this.$props.items.indexOf(item);
        if (this.$props.isFinmedia) {
            delete item.id;
            this.emitQuantityAndRateForCalc(item, 'service_amount', 9);
            return;
        }

        this.emitQuantityAndRateForCalc(item, 'service_amount', 9);

        if (this.$props.isAccounting) {
            eventBus.$emit('customer-order-amount-changed', currentItemIndex, item);
        }

        this.setUnsavedDataState(true);
    }

    changeStatus(isClickable: boolean, id: number, status: string): void {
        if (!isClickable) return;
        eventBus.$emit('change-status', id, status);
    }

    addReport(item: IOfferService): void {
        eventBus.$emit('add-report', item);
    }

    focusOnAccountingInput(): void {
        eventBus.$emit('focus-on-accounting-table-control', this.$props.accountIndex);
    }

    createMask(item: IOfferService | IOtherService, propertyName: string, numberOfSigns = 7): void {
        this.mask = String(item[propertyName]).replace(/[^\d.-]/g, '');
        this.mask = this.mask.split('.')[0].slice(0, numberOfSigns);
        this.mask = `${this.mask.replace(/\d/g, '#')}.##`;
        this.offerId = item.id ? item.id! : item.tempId!;
    }

    emitQuantityAndRateForCalc(
        item: IOfferService | IOtherService,
        propertyForMask?: string,
        numberOfSigns?: number,
    ): void {
        if (propertyForMask) {
            this.createMask(item, propertyForMask, numberOfSigns);
        }
        const currentItemIndex = this.$props.items.findIndex(
            (el) => (el.tempId ? el.tempId === item.tempId : el.id === item.id),
        );

        // Т.к. таблица - компонент отображения данных, емитим данные родительскому компоненту,
        // в котором выполняем вычисления.
        if (this.$props.isAccounting) {
            eventBus.$emit('calc-total-value-accounting', currentItemIndex, item);
        } else {
            eventBus.$emit('calc-total-value', currentItemIndex);
        }
        this.setUnsavedDataState(true);
    }

    openLinkStata(item: IOtherService): void {
        eventBus.$emit('open-link', item);
    }
}
