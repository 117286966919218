
import {
    Component, Vue, Prop, PropSync,
} from 'vue-property-decorator';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import StatTaskedStatisticFile from '@/components/revenue/statTask/StatTaskedStatisticFile.vue';
import StatTaskedStatisticFileComments from '@/components/revenue/statTask/StatTaskedStatisticFileComments.vue';
import { IFileComment, IFiles } from '@/api/types/revenue';
import { deleteStatTaskComment, patchStatTaskComment, postStatTaskComment } from '@/api/revenue';
import { showServerError } from '@/utils';

@Component({
    components: {
        TooltipButton,
        StatTaskedStatisticFile,
        StatTaskedStatisticFileComments,
    },
})

export default class StatTaskedStatisticList extends Vue {
    @Prop({ required: false }) readonly title!: string;
    @PropSync('statTaskedFiles') statTaskedFilesSync!: IFiles[];
    @Prop({ required: true }) readonly statTaskedFiles!: [];
    @Prop({ required: false }) readonly canBeDeleted!: boolean;
    @Prop({ required: false }) readonly commenting!: boolean;
    @Prop({ required: true }) statTaskId!: number;
    @Prop({ required: false, default: '' }) readonly statsTaskStatus!: string;

    get isStatTask(): boolean {
        return this.$route.name === 'stat-task';
    }

    async addNewComment(text: string, file: IFiles): Promise<void> {
        const fileComment: IFileComment = {
            text,
        };
        const currentIndex = this.statTaskedFilesSync.indexOf(file);
        try {
            const idComment: {id?: number} = await postStatTaskComment(this.statTaskId!, file.id!, fileComment);
            fileComment.id = idComment.id;
            this.$emit('add-comment', currentIndex, fileComment);
        } catch (err) {
            showServerError(err, 'Комментарий не добавлен');
        }
    }

    async editComment(data: any): Promise<void> {
        const {
            statTaskId, fileId, commentId, text, commentIndex, fileIndex,
        } = data;

        if (!data.fileId) {
            this.statTaskedFilesSync[fileIndex]!.comments![commentIndex].text = text;
        } else {
            try {
                this.statTaskedFilesSync[fileIndex]!.comments![commentIndex].text = text;
                await patchStatTaskComment(statTaskId, fileId, commentId, text);
            } catch (err) {
                showServerError(err, 'Ошибка редактирования файла');
            }
        }
    }

    async deleteComment(data: any): Promise<void> {
        const {
            statTaskId, fileId, commentId, fileIndex, commentIndex,
        } = data;

        if (!data.fileId) {
            this.statTaskedFilesSync[fileIndex].comments!.splice(commentIndex, 1);
        } else {
            try {
                await deleteStatTaskComment(statTaskId, fileId, commentId);
                this.statTaskedFilesSync[fileIndex].comments!.splice(commentIndex, 1);
            } catch (err) {
                showServerError(err, 'Ошибка удаления файла');
            }
        }
    }
}
