
import { Component, Vue, Prop } from 'vue-property-decorator';
import BackArrow from '@/assets/icons/short-back-arrow.svg';
import { Getter } from 'vuex-class';


@Component({
    components: {
        BackArrow,
    },
})

export default class PageLayout extends Vue {
    @Prop({ type: String, required: false }) icon!: string;
    @Prop({ type: String, default: 'secondary' }) color!: string;
    @Prop({ type: [Number, String], default: '1266px' }) maxWidth!: string;
    @Prop({ type: String, default: undefined }) subIcon!: string;
    @Prop({ type: String, default: undefined }) subIconColor!: string;
    @Prop({ type: String, default: undefined }) subTextColor!: string;
    @Prop({ type: String, default: undefined }) subText!: string;
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String, default: '' }) backRoute!: string;
    @Getter('GET_BEFORE_LAST_ROUTE') beforeLastRoute;

    goBack(): void {
        if (this.backRoute) {
            this.getBackAction();
        } else {
            this.$emit('back');
        }
    }

    getBackAction(): void {
        if (this.beforeLastRoute === '/') {
            this.$router.push({ path: this.backRoute });
        } else {
            this.$router.back();
        }
    }
}
