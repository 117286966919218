
import {
    Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { getOffersList } from '@/api/uapi';
import { IStage } from '@/api/types/uapi';
import { showServerError } from '@/utils';

@Component
export default class StageTab extends Vue {
    @PropSync('stage') stageSync!: IStage;

    autocomplete = {
        supValue: '',
        items: [] as Array<{[key: string]: string | number}>,
        loading: false,
    };
    searchTimerId: number = 0;

    @Watch('stageSync.id')
    changeSupValue(id: number): void {
        if (id) {
            this.autocomplete.supValue = String(this.stageSync.offer_id);
            this.getQuerySelections();
        }
    }

    created(): void {
        if (this.stageSync.offer_id) {
            this.autocomplete.supValue = String(this.stageSync.offer_id);
            this.getQuerySelections();
        }
    }

    debounceQuerySelections(): void {
        this.searchTimerId = window.setTimeout(() => {
            this.getQuerySelections();
        }, 800);
    }

    async getQuerySelections(): Promise<void> {
        try {
            if (this.autocomplete.supValue) {
                this.autocomplete.loading = true;
                const { data } = await getOffersList({ query: this.autocomplete.supValue });
                data.forEach((item) => {
                    this.autocomplete.items.push({
                        id: item.id,
                        offer_name: `${item.id} ${item.offer_name}`,
                    });
                });
                this.autocomplete.loading = false;
            }
        } catch (err) {
            this.autocomplete.loading = false;
            showServerError(err);
        }
    }
}
