
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class TabsReport extends Vue {
    @Prop({ required: true }) tabs!: any;

    tab = 0;
}
