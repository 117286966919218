

import { Vue, Component, Prop } from 'vue-property-decorator';
import { translateBankDetails } from "@/utils/translate";
import BaseCard from "@/components/base/BaseCard.vue";

@Component({
    components: { BaseCard },
})
export default class EmployeeMethodFields extends Vue {
    @Prop({ required: true, default: false }) readonly isManagerFields!: boolean;
    @Prop({ required: true, default: false }) readonly isPartnerFields!: boolean;
    @Prop({ required: true, default: () => ([]) }) readonly managerFields!: any[];
    @Prop({ required: true, default: () => ([]) }) readonly partnerFields!: any[];

    get getStyleForRoot(): string {
        const count = Math.max(this.managerFields?.length || 0, this.partnerFields?.length || 0);
        return `grid-template-rows: auto repeat(${count}, 22px);`;
    }

    getStyleForManagerColl(index: number): string {
        return `grid-row: ${index + 2}; grid-template-columns: minmax(130px, 170px); ${this.isPartnerFields ? 'grid-column: 2;' : 'grid-column: 1;'}`;
    }

    getTemplate(item: any): string {
        return translateBankDetails(item);
    }
}
