import { AxiosResponse } from 'axios';
import {
    IParamsObject,
    IServerResponse,
} from '@/api/types';
import { axiosInstance } from '@/api/index';
import {
    ITicket,
    IMessage, ISurvey, ISurveyList, IVotingUser, ISignature, ITicketDepartment,
} from '@/api/types/support';

import {
    API_SUPPORT_URL,
} from '@/configs/global';
import { PatchSurveyDTO, PostSurveyDTO } from "@/services/surveys/Survey";

/*
 * Получение списка тикетов
 */
export async function getTicketsList(
    params: {
        limit?: number,
        offset?: number,
        status?: string[],
        affiliate_id?: string[],
        manager_id?: string[],
    },
): Promise<IServerResponse<ITicket[]>> {
    return axiosInstance.request<IServerResponse<ITicket[]>>({
        method: 'GET',
        url: `${API_SUPPORT_URL}/employees/tickets`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<ITicket[]>>) => response.data);
}

/*
 * Получение подписи
 */
export async function getSignature(): Promise<ISignature > {
    return axiosInstance.request<IServerResponse<ISignature>>({
        method: 'GET',
        url: `${API_SUPPORT_URL}/employees/signature`,
    })
        .then((response: AxiosResponse<IServerResponse<ISignature>>) => response.data.data);
}

/*
 * Создание подписи
 */
export async function editSignature(
    data: ISignature ,
): Promise<ISignature> {
    return axiosInstance.request<IServerResponse<ISignature>>({
        method: 'PATCH',
        url: `${API_SUPPORT_URL}/employees/signature`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ISignature >>) => response.data.data);
}

/*
 * Получение сообщений по тикету
 */
export async function getTicket(
    ticketId: string,
    order: string,
): Promise<IServerResponse<{ticket: ITicket, messages: IMessage[]}>> {
    return axiosInstance.request<IServerResponse<{ticket: ITicket, messages: IMessage[]}>>({
        method: 'GET',
        url: `${API_SUPPORT_URL}/employees/tickets/${ticketId}/messages`,
        params: {
            order,
        },
    })
        .then((response: AxiosResponse<IServerResponse<{ticket: ITicket, messages: IMessage[]}>>) => response.data);
}

/*
 * Добавление сообщения по тикету
 */
export async function addTicketMessage(
    ticketId: string,
    message: string,
): Promise<IServerResponse<{id: number}>> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'POST',
        url: `${API_SUPPORT_URL}/employees/tickets/${ticketId}/messages`,
        data: {
            message,
        },
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data);
}

/*
 * Закрытие тикета
 */
export async function closeTicket(
    ticketId: number,
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'PATCH',
        url: `${API_SUPPORT_URL}/employees/tickets/${ticketId}/resolve`,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/*
 * Переоткрытие тикета
 */
export async function reopenTicket(
    ticketId: number,
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'PATCH',
        url: `${API_SUPPORT_URL}/employees/tickets/${ticketId}/reopen`,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/*
 * Получение списка отделов
 */
export async function getTicketDepartments(
): Promise<IServerResponse<ITicketDepartment[]>> {
    return axiosInstance.request<IServerResponse<ITicketDepartment[]>>({
        method: 'GET',
        url: `${API_SUPPORT_URL}/employees/tickets/departments`,
    })
        .then((response: AxiosResponse<IServerResponse<ITicketDepartment[]>>) => response.data);
}

/*
 * Получение списка опросов
 */
export async function getSurveys(
    params?: IParamsObject,
): Promise<IServerResponse<ISurveyList[]>>{
    return axiosInstance.request<IServerResponse<ISurveyList[]>>({
        method: 'GET',
        url: `${API_SUPPORT_URL}/employees/surveys`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<ISurveyList[]>>) => response.data);
}

/*
 * Получение одного опроса
 */
export async function getOneSurvey(
    id: string | number,
    user_id?: number,
): Promise<ISurvey> {
    return axiosInstance.request<IServerResponse<ISurvey>>({
        method: 'GET',
        url: `${API_SUPPORT_URL}/employees/surveys/${id}`,
        params: {
            user_id,
        },
    })
        .then((response: AxiosResponse<IServerResponse<ISurvey>>) => response.data.data);
}

/*
 * Скачать опрос
 */
export async function getOneSurveyDownload(
    id: string | number,
): Promise<IServerResponse<ISurvey>> {
    return axiosInstance.request<IServerResponse<ISurvey>>({
        method: 'GET',
        url: `${API_SUPPORT_URL}/employees/surveys/${id}`,
        responseType: 'blob',
        headers: { Accept: 'application/vnd.ms-excel' },
    })
        .then((response: AxiosResponse<IServerResponse<ISurvey>>) => response.data);
}

/*
 *  Создание опроса
 */
export async function postSurvey(
    data: PostSurveyDTO,
): Promise<IServerResponse<ISurvey>> {
    return axiosInstance.request<IServerResponse<ISurvey>>({
        method: 'POST',
        url: `${API_SUPPORT_URL}/employees/surveys`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ISurvey>>) => response.data);
}

/*
 *  Редактирвоание опроса
 */
export async function patchSurvey (
    id: number,
    data: PatchSurveyDTO,
): Promise<IServerResponse<ISurvey>> {
    return axiosInstance.request<IServerResponse<ISurvey>>({
        method: 'PATCH',
        url: `${API_SUPPORT_URL}/employees/surveys/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ISurvey>>) => response.data);
}

/*
 *  Изменение статуса опроса
 */
export async function patchStatusSurvey (
    id: number,
    data: {is_active: boolean},
): Promise<IServerResponse<ISurvey>> {
    return axiosInstance.request<IServerResponse<ISurvey>>({
        method: 'PATCH',
        url: `${API_SUPPORT_URL}/employees/surveys/${id}/status`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<ISurvey>>) => response.data);
}

/*
 *  Удаление опроса
 */
export async function deleteSurvey (
    id: number,
): Promise<IServerResponse<ISurvey>> {
    return axiosInstance.request<IServerResponse<ISurvey>>({
        method: 'DELETE',
        url: `${API_SUPPORT_URL}/employees/surveys/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<ISurvey>>) => response.data);
}

/**
 * Получение списка пользователей проголосовавших в опросе
 */
export async function getVotingUsers(
    id: number,
): Promise<IServerResponse<IVotingUser[]>> {
    return axiosInstance.request<IServerResponse<IVotingUser[]>>({
        method: "GET",
        url: `${API_SUPPORT_URL}/employees/surveys/${id}/voters`,
        params: {
            limit: 1000,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IVotingUser[]>>) => response.data);
}
