
import {
    Component, Prop, Vue, Watch, PropSync,
} from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { IPaymentCalendarsItem } from '@/api/types/revenue';

@Component
export default class PaymentCalendar extends Vue {
    @Getter('GET_EDITING_MODE') editingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    @PropSync('paymentCalendars', { type: Array }) paymentCalendarsSync!: IPaymentCalendarsItem[];
    @Prop({ type: String, default: '' }) customerOrderStatus!: string;
    @Prop({ default: false }) isPrepayment!: boolean;
    @Prop({ required: false }) accountIndex!: number;
    @Prop({ type: Number, default: 0 }) totalAmountOffersAndServicesList!: number;
    @Prop({ type: String, default: '' }) getCurrencyIcon!: string;
    @Prop({ default: true }) isCanEditDate!: boolean;

    private sumOfPercentages = 0 as number;
    private date = '';
    private percentage: number | string = '';
    private calendarAmount: number | string = '';
    private showDatePickerMenu = false;

    @Watch('paymentCalendarsSync')
    refreshCalendars(): void {
        this.sumOfAddedPercentage();
        this.percentage = '';
        this.calendarAmount = '';
        this.date = '';
    }

    get isCalendarButtonDisabled(): boolean {
        return this.calendarAmount === 0
            || Number(this.percentage) === 0
            || !this.date
            || !this.percentage
            || !this.calendarAmount
            || this.sumOfPercentages > 100
            || Number(this.percentage) + this.sumOfPercentages > 100
            || this.percentage > 100;
    }

    get calendarIsDisabled(): boolean {
        if (
            (this.$props.customerOrderStatus === 'paid'
            || this.$props.customerOrderStatus === 'partly_paid')
            && !this.$props.isPrepayment
        ) {
            return true;
        }
        return !this.editingMode;
    }

    created(): void {
        if (this.paymentCalendarsSync) {
            this.sumOfPercentages = this.getSumOfPercentages(this.paymentCalendarsSync);
        }
    }

    getSumFromPercentage(): void {
        if (this.$props.totalAmountOffersAndServicesList === 0) {
            this.calendarAmount = 0;
            return;
        }
        this.$nextTick(() => {
            if (this.percentage > 100) {
                this.percentage = 100;
            }

            this.calendarAmount = (
                this.$props.totalAmountOffersAndServicesList / 100 * Number(this.percentage ? this.percentage : 0)
            ).toFixed(2);
        });
    }

    getPercentageFromSum(): void {
        if (this.$props.totalAmountOffersAndServicesList === 0) {
            this.percentage = 0;
            return;
        }
        this.$nextTick(() => {
            if (this.calendarAmount > this.$props.totalAmountOffersAndServicesList) {
                this.calendarAmount = this.$props.totalAmountOffersAndServicesList;
            }

            this.percentage = (
                Number(this.calendarAmount ? this.calendarAmount : 0)
                / this.$props.totalAmountOffersAndServicesList
                * 100
            ).toFixed(2);
        });
    }

    getSumOfPercentages(items: IPaymentCalendarsItem[]): number {
        return items.map((item) => item.percentage).reduce((a, b) => a + b, 0);
    }

    // Добавить новую дату в календаре выплат
    addPayoutDate(): void {
        if (!this.date || !this.percentage) {
            return;
        }

        const payoutDateItem: IPaymentCalendarsItem = {
            date: this.date,
            percentage: Number(this.percentage),
            amount: Number(this.calendarAmount),
        };

        this.paymentCalendarsSync.push(payoutDateItem);
        this.sumOfAddedPercentage();

        this.percentage = '';
        this.calendarAmount = '';
        this.date = '';

        this.setUnsavedDataState(true);
    }

    sumOfAddedPercentage(): void {
        if (this.paymentCalendarsSync) {
            this.sumOfPercentages = Number(Object.assign(this.paymentCalendarsSync
                .map((item: any) => item.percentage))
                .reduce((a, b) => Number(a) + Number(b), 0));
        }
    }

    // Удалить дату в календаре выплат
    removePayoutDate(itemToRemove: IPaymentCalendarsItem): void {
        const indexOfItemToRemove = this.paymentCalendarsSync.indexOf(itemToRemove);
        this.paymentCalendarsSync.splice(indexOfItemToRemove, 1);
        this.sumOfAddedPercentage();

        this.setUnsavedDataState(true);
    }
}
