import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { showServerError } from '@/utils';

@Component({
    components: {},
})
export default class FilterGuid extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    async getFilterGuidItems(filters: any[], filterId: string, apiMethod: any): Promise<void> {
        if (this.getFiltersParamsObject.hasOwnProperty(filterId) && this.getFiltersParamsObject[filterId].length > 0) {
            try {
                const result = await apiMethod(this.getFiltersParamsObject[filterId]);

                const filter = filters.find((i) => i.id === filterId);
                if (filter) {
                    filter.select = [];
                    filter.select = result || [];
                    filter.items = result || [];
                }
            } catch (err) {
                showServerError(err, 'Не удалось найти');
            }
        }
    }
}
