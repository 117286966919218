
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import AddingServices from '@/components/revenue/AddingServices.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import Multiselect from '@/components/base/form/Multiselect.vue';
import StatTaskedStatisticList from '@/components/revenue/statTask/StatTaskedStatisticList.vue';
import ChangeEditingModeButton from '@/components/base/buttons/ChangeEditingModeButton.vue';
import SvgStatTaskCreation from '@/assets/icons/nav-bar/upload-creation.svg';
import StatTaskFiles from '@/components/revenue/statTask/StatTaskFiles.vue';
import { eventBus } from '@/eventbus';
import Status from '@/components/base/Status.vue';
import {
    deleteReport,
    getOfferServices,
    getOneStatTask,
    patchOfferStatus,
    patchStatTask,
    patchStatTaskStatus,
    postReport,
    postStatTask,
} from '@/api/revenue';

import {
    IAdvertiser,
    IFileComment,
    IFiles,
    IOfferService,
    IStatTask,
    IStatTasks,
} from '@/api/types/revenue';

import {
    API_GATEWAY_URL,
    CHANGE_ROUTE_DEBOUNCE,
} from '@/configs/global';
import { getRandomId, showServerError } from '@/utils';
import Header from '@/components/revenue/statTask/Header.vue';
import { translateOfferStatus, translateStatTaskStatus } from '@/utils/translate';
import FormModal from '@/components/base/FormModal.vue';
import StatTaskedFileItem from '@/components/revenue/statTask/StatTaskedFileItem.vue';
import SaveBtnTooltip from '@/components/base/buttons/SaveBtnTooltip.vue';
import { REPORT_STATUS } from '@/mappings/reportStatus';
import { STATS_TASK_STATUS } from '@/mappings/statsTaskStatus';
import { USER_ROLES } from '@/mappings/user-roles';
import { NotificationMessage } from "@/utils/notification";
import { IStatus } from "@/types";

const auth = namespace('authModule');
const revenue = namespace('revenueModule');

@Component({
    components: {
        SaveBtnTooltip,
        StatTaskedFileItem,
        AddingServices,
        ChangeEditingModeButton,
        FormModal,
        Header,
        Multiselect,
        StatTaskedStatisticList,
        StatsCard,
        Status,
        SvgStatTaskCreation,
        StatTaskFiles,
    },
})

export default class StatTaskContent extends Vue {
    @Prop() readonly isStatTask!: boolean;

    @auth.Getter('GET_ROLE') role;
    @auth.Getter('GET_USER') user;
    @Getter('GET_EDITING_MODE') editingMode;
    @Getter('GET_UNSAVED_DATA_STATE') getUnsavedDataState;
    @Getter('GET_ABORT_TRANSITION') getAbortTransition;
    @Getter('GET_ROUTE_TO_TRANSITION') getRouteToTransition;
    @Mutation('TOGGLE_EDITING_MODE') toggleEditingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;
    @Mutation('SET_NOTIFICATION') setNotification;
    @revenue.Mutation('SET_OFFER_REPORT') setOfferReport;
    @revenue.Mutation('SET_GENERAL_REPORT_OFFERS') setGeneralReportOffers;

    get translateStatTaskStatus(): IStatus {
        return translateStatTaskStatus(this.statTaskObject.status!);
    }

    get isAddFileButtonDisabled(): boolean {
        return this.addedFiles?.some((item) => item.offer_services!.length <= 0);
    }

    get isShowOffers(): boolean {
        return this.$props.isStatTask || this.showMainContent;
    }

    get statTaskID(): number | undefined {
        if (this.$route.name === 'stat-task') {
            return Number(this.$route.params.id!);
        }
    }

    get isCanEdit(): boolean  {
        return !!(this.statTaskObject.id && this.statTaskObject.status !== STATS_TASK_STATUS.DONE &&
            (this.role !== USER_ROLES.STAT_MANAGER && !this.isAnotherAdvertiser));
    }

    get isAnotherAdvertiser(): boolean {
        return (
            this.role === USER_ROLES.ACCOUNT_MANAGER
            && !!this.statTaskObject.advertiser!.account_manager
            && this.user.account_id !== this.statTaskObject.advertiser!.account_manager.id
            && !this.statTaskObject.advertiser!.managers.some((manager) => manager.manager_id === this.user.account_id)
        );
    }

    get isShowStatTask(): boolean {
        return (this.statTaskObject.id && this.$props.isStatTask) || !this.$props.isStatTask;
    }

    get itemsForSelect(): IOfferService[] {
        const result: IOfferService[] = [];
        const offersServiceWithFiles = this.statTaskObject.files!.flatMap(({ offer_services }) => offer_services);
        this.offerServicesFromFiles!.forEach((service: IOfferService) => {
            if (!offersServiceWithFiles.find((item) => item!.id === service!.id)) {
                result.push(service);
            }
        });
        return result;
    }

    get isStatManager(): boolean {
        return !!this.user.roles.find((role) => role === USER_ROLES.STAT_MANAGER);
    }

    get getAddedOffersCount(): number {
        let count = 0;
        this.statTaskObject.files!.forEach((item) => {
            item.offer_services?.forEach(() => count += 1);
        });
        return count;
    }

    get isDisabledSaveBtn(): boolean | void {
        return this.isShowError
            || this.itemsForSelect.length > 0
            || this.getAddedOffersCount !== this.statTaskObject.items?.length
            || !this.statTaskObject.period
            || !this.statTaskObject.advertiser!.id
            || this.isAddFileButtonDisabled
            || this.statTaskObject.items?.length === 0
            || this.statTaskObject.items!
                .some((item: IOfferService) => !item.offer_qty || !item.offer_rate);
    }

    // геттеры для передачи условий о заполнености полей
    get emptyConditions(): object {
        const { isEmptyOfferServices } = this;
        return {
            isEmptyOfferServices,
            isEmptyPeriod: !this.statTaskObject.period,
            isEmptyAdvertiser: !this.statTaskObject.advertiser!.id,
            isEmptyOffer: this.statTaskObject.items?.length === 0,
            isExtraOffer: this.statTaskObject.items!.length > 0
                    && (this.getAddedOffersCount !== this.statTaskObject.items?.length),
        };
    }

    // проверка у всех ли офферов статус done
    get isStatusDoneAllOffers(): boolean {
        return this.offerServicesFromFiles!.every((item) => item.status === REPORT_STATUS.DONE);
    }

    get showMainContent(): boolean {
        return !!(
            this.statTaskObject?.period
            && this.statTaskObject.advertiser?.id
            && !this.isAnotherAdvertiser
        );
    }

    get isEmptyOfferServices(): boolean {
        let isEmpty = false;
        if (!isEmpty && this.statTaskObject.items?.length) {
            isEmpty = this.statTaskObject.items
                .some((item: IOfferService) => !item.offer_qty || !item.offer_rate);
        }

        return isEmpty;
    }

    showUnsavedChangesModal = false;
    showCantBeSavedModal = false;
    addFileLoader = false;
    showStatusChangesModal = false;
    showReturnOfferModal = false;
    returnedOfferId: number | null = null;
    // отчет о проделанной работе
    report: string | null = '';
    reportOfferId: number | null = null;
    showModal = false;
    isLoading = false;
    loadingSaveStatTask = false;
    dialog = false;
    offerServices: IOfferService[] = [];
    // validate for save or edit of stat-task
    isShowError = false;
    // validate of status
    isShowErrorOfStatus = false;
    shownMessageOfStatus = '';
    initialDataRecieved = false;
    typeOfStatTask = 'new';
    // для модалки
    deletingFile: null | IFiles = null;
    headersForTableSelectInInvoice = [
        { text: 'ID оффера', value: 'offer_id' },
        { text: 'Оффер', value: 'offer_name' },
        { text: 'ID цели', value: 'goal_id' },
        { text: 'Цель', align: 'left', value: 'goal_name' },
        { text: 'Цена', align: 'right', value: 'goal_revenue_flat' },
        { text: 'Всего конверсий', align: 'right', value: 'conversions_total' },
        { text: 'Принято конверсий', align: 'right', value: 'conversions_approved' },
        { text: '', align: 'right', value: 'actions' },
    ];
    // Объект задачи на загрузку
    statTaskObject: IStatTasks = {
        invoice_id: null,
        period: '',
        assignee_to: null,
        created_by: null,
        advertiser: {} as IAdvertiser,
        files: [] as IFiles[],
        items: [] as IOfferService[],
        status: '',
    };
    // Временный массив для отображения офферов в таблице и селекте при редактировании
    offerServicesFromFiles: IOfferService[] | undefined = [];
    // Выбранные файлы
    addedFiles: IFiles[] = [];
    // Заголовки колонок таблицы
    addingServicesHeaders = [
        { text: 'Оффер', value: 'offer' },
        { text: 'Цель', align: 'left', value: 'goal' },
        { text: 'Ставка', align: 'right', value: 'offer_rate' },
        { text: 'Текущая ставка', align: 'right', value: 'offer_start_rate' },
        { text: 'Количество', align: 'right', value: 'offer_qty' },
        { text: 'Итого', align: 'right', value: 'total' },
        { text: '', align: 'right', value: 'actions' },
    ];

    // статусы таблицы
    statuses = [
        {
            slot: 'item.status',
            key: 'status',
            isClickable: true,
            translate: translateOfferStatus,
        },
    ];

    // действия в таблице в столбце report
    reportActions = [
        {
            title: 'Посмотреть отчет',
            color: 'success',
            icon: 'mdi-eye',
            callback: (item: IOfferService): void => {
                this.showReport(item);
            },
        },
        {
            title: 'Удалить отчет',
            color: 'error',
            icon: 'delete',
            callback: (item: IOfferService): void => {
                item.status = item.status === REPORT_STATUS.DONE ? REPORT_STATUS.PROGRESS : REPORT_STATUS.PENDING;
                this.deleteReport(item);
            },
        },
    ];

    setUnsavedData(state: boolean): void {
        if (this.typeOfStatTask === 'old') {
            this.setUnsavedDataState(state);
        }
    }

    beforeRouteLive(): void {
        if (this.getUnsavedDataState) {
            if (this.isDisabledSaveBtn || this.statTaskObject.items!.length === 0) {
                this.showCantBeSavedModal = true;
                return;
            }
            this.showUnsavedChangesModal = true;
        }
    }

    goToRouteWithoutSave(): void {
        this.showUnsavedChangesModal = false;
        this.setUnsavedDataState(false);

        this.$router.push({ name: this.getRouteToTransition });
    }

    async saveStatTaskFromModal(): Promise<void> {
        this.showUnsavedChangesModal = false;
        try {
            await this.saveStatTask();
            this.setUnsavedDataState(false);
            await this.$router.push({ name: this.getRouteToTransition });
        } catch (err) {
            showServerError(err, 'Ошибка при сохранении');
        }
        this.setUnsavedDataState(false);
    }

    stayOnCurrentPage(): void {
        this.showCantBeSavedModal = false;
        this.setAbortTransition(false);
    }

    // меняем статус загрузки на done, если у всех офферов статус done
    pushStatTaskStatusDone(): string | void {
        if (this.isStatusDoneAllOffers) {
            this.toggleEditingMode(false);
            this.pushStatTaskStatus(STATS_TASK_STATUS.DONE);
            return this.statTaskObject.status = STATS_TASK_STATUS.DONE;
        }
    }

    // изменить статус оффера в таблице офферов
    changeOfferStatus(id: number | null, status: string, isReturned = false): void {
        for (const item of this.offerServicesFromFiles!) {
            if (item.id === id) {
                if (status === REPORT_STATUS.PENDING) {
                    item!.status = REPORT_STATUS.PROGRESS;
                    try {
                        this.patchOfferStatus(id!, item!.status);
                        return;
                    } catch (err) {
                        item!.status = REPORT_STATUS.PENDING;
                        showServerError(err);
                    }
                }

                if (status === REPORT_STATUS.PROGRESS) {
                    item!.status = REPORT_STATUS.DONE;
                    try {
                        this.patchOfferStatus(id!, item!.status);
                        this.pushStatTaskStatusDone();
                        return;
                    } catch (err) {
                        item!.status = REPORT_STATUS.PROGRESS;
                        showServerError(err);
                    }
                }

                if (status === REPORT_STATUS.DONE) {
                    this.showReturnOfferModal = !isReturned;
                    this.returnedOfferId = isReturned ? null : id;
                    if (isReturned) {
                        item!.status = REPORT_STATUS.PROGRESS;
                        try {
                            this.patchOfferStatus(id!, item!.status);
                            return;
                        } catch (err) {
                            item!.status = REPORT_STATUS.DONE;
                            showServerError(err);
                        }
                    }
                }
            }
        }
    }

    // перейти в грузчик
    openLinkStataLeadgid(item: IOfferService): void {
        const url = `${API_GATEWAY_URL}/stata/redirect?task_id=${this.statTaskObject.id}&id=${item.id}`;
        window.open(url, '_blank');
    }

    // отправить на бэк статус оффера
    async patchOfferStatus(id: number, status: string): Promise<void> {
        this.isLoading = true;
        try {
            await patchOfferStatus(this.statTaskID!, id, { status });
        } catch (err) {
            showServerError(err, 'Статус не изменен');
            throw err;
        }
        this.isLoading = false;
    }

    // посмотреть отчет
    showReport(item: IOfferService): void {
        this.setOfferReport(item);
        this.goTo({ name: 'report', params: { id: this.statTaskID, reportId: item.report.id } });
    }

    // добавить отчет
    addReport(item: IOfferService): void {
        this.showModal = true;
        this.report = item.report;
        this.reportOfferId = item.id as number;
    }

    async saveReport(text: JSON): Promise<void> {
        const item = this.findItemOfferServicesFromFiles(this.reportOfferId);
        try {
            this.isLoading = true;
            if (text && item) {
                const { data: report } = await postReport({
                    stat_task_id: this.statTaskID!,
                    // @ts-ignore
                    offer_service_id: item!.id,
                    report: text,
                });
                item!.report = report;
            }
            this.showModal = false;
            if (this.isStatusDoneAllOffers) {
                this.statTaskObject.status = STATS_TASK_STATUS.DONE;
            }
        } catch (err) {
            showServerError(err, 'Статус не изменен');
            throw err;
        }
        this.isLoading = false;
    }

    // удалить отчет
    async deleteReport(item: IOfferService): Promise<void> {
        try {
            await deleteReport(item.report.id);
            await this.patchOfferStatus(item.id! as number, item!.status!);
            if (this.statTaskObject.status === STATS_TASK_STATUS.DONE) {
                this.pushStatTaskStatus(STATS_TASK_STATUS.DONE);
            }
        } catch (err) {
            showServerError(err, 'Отчет не удален');
            throw err;
        }
    }

    async updateStatTaskObject(): Promise<void> {
        const { advertiser } = this.statTaskObject;
        try {
            if (this.statTaskObject.period) {
                this.offerServices = await getOfferServices(advertiser!.id, { period: this.statTaskObject.period });
                this.offerServices.forEach((offer) => {
                    offer.id = getRandomId();
                });

                if (this.initialDataRecieved) {
                    this.setUnsavedData(true);
                }
                this.initialDataRecieved = true;
            } else { return; }
        } catch (err) {
            showServerError(err, 'Список офферов не загружен');
        }
    }

    @Watch('offerServicesFromFiles')
    returnItems(): IOfferService[] | void {
        if (this.$props.isStatTask) {
            this.statTaskObject.items = this.offerServicesFromFiles;
            return this.statTaskObject.items;
        }
    }

    @Watch('getAbortTransition')
    getAbortTransitionState(): void {
        if (this.getAbortTransition) {
            this.beforeRouteLive();
        }
    }

    clearValidate(): void {
        this.isShowError = false;
    }

    async created(): Promise<void> {
        if (!this.isCanEdit) {
            this.toggleEditingMode(false);
        }

        if (this.$props.isStatTask) {
            try {
                this.statTaskObject = await getOneStatTask(Number(this.statTaskID));
                if (this.statTaskObject.status === STATS_TASK_STATUS.DONE) {
                    this.toggleEditingMode(false);
                }

                this.statTaskObject!.period.substr(0, 7);
                // получение офферов для таблице и селекте
                const newArraysOffers: IOfferService[] = [];
                let concatArrayOffers: IOfferService[] = [];
                this.statTaskObject.files!.forEach((item) => {
                    newArraysOffers.push(item.offer_services as IOfferService);
                });
                newArraysOffers.forEach((item) => {
                    concatArrayOffers = concatArrayOffers.concat(item);
                });
                this.statTaskObject.items = concatArrayOffers;
                this.offerServicesFromFiles = this.statTaskObject.items;

                this.changeClickableOffer();
                this.addingServicesHeaders.unshift(
                    { text: '', align: 'center', value: 'stata' },
                    { text: 'Отчет', align: 'center', value: 'report' },
                    { text: 'Статус', align: 'center', value: 'status' },
                );
            } catch (err) {
                showServerError(err, 'Загрузка не найдена');
            }
            // Получаем список `оффер+цель` для селекта
            await this.updateStatTaskObject();
        } else {
            this.toggleEditingMode(true);
        }

        this.typeOfStatTask = this.statTaskObject.id ? 'old' : 'new';
    }

    toggleNameStatTaskStatus(status: string): string | void {
        switch (status) {
        case STATS_TASK_STATUS.PENDING: return STATS_TASK_STATUS.PROGRESS;
        case STATS_TASK_STATUS.PROGRESS: return STATS_TASK_STATUS.DONE;
        }
    }

    // вернуть загрузку в работу
    returnInProgress(status: string): void {
        this.pushStatTaskStatus(status);
        this.showStatusChangesModal = false;
    }

    // смена статуса загрузке, статус Done, вызываем диалоговое окно с подтвержение вернуть в работу загрузку
    toggleStatTaskStatus(status: string | undefined, isClickable: boolean): void {
        if (!isClickable) return;
        if (status === STATS_TASK_STATUS.DONE) {
            this.showStatusChangesModal = true;
        } else {
            const changedStatus = this.toggleNameStatTaskStatus(status!);

            if (changedStatus) {
                this.pushStatTaskStatus(changedStatus);
            }
        }
    }

    // изменить статус загрузки
    async patchStatTaskStatus(status: string): Promise<void> {
        try {
            await patchStatTaskStatus(this.statTaskID!, { status });
            this.showValidate(false, 'Статус сохранен');
        } catch (err) {
            this.showValidate(true, 'Статус не сохранен');
        }
    }

    onFileDelete(): void {
        this.clearValidate();
        const itemIndex = this.statTaskObject.files!.indexOf(this.deletingFile!);
        this.statTaskObject.files!.splice(itemIndex, 1);
        // при удалении файла меняем старый id оффера, который пришел с бэка, на рандомный,
        // чтобы при сохрании загрузки id стал null, так как это ожидает бэк
        this.deletingFile!.offer_services!.forEach((offer) => offer.id = getRandomId());
        this.deletingFile = null;
    }

    // при смене рекламодателя удаляем связанные данные
    clearAdvertiserRelatedData(): void {
        this.offerServices = [];
    }

    addNewComment(currentIndex: string, fileComment: IFileComment): void {
        this.clearValidate();
        this.statTaskObject.files![currentIndex].comments!.push(fileComment);
    }

    openGeneralReport(): void {
        this.setGeneralReportOffers(this.offerServicesFromFiles);
        this.$router.push({ name: 'stat-task-report', params: { id: this.statTaskID!.toString() } });
    }

    mounted(): void {
        eventBus.$on('add-offer-service', this.addOffer);
        eventBus.$on('calc-total-value', this.calcOffersTotalValue);
        eventBus.$on('delete-offer', this.deleteOffer);
        eventBus.$on('change-status', this.changeOfferStatus);
        eventBus.$on('open-link', this.openLinkStataLeadgid);
        eventBus.$on('add-report', this.addReport);
    }

    beforeDestroy(): void {
        eventBus.$off('add-offer-service', this.addOffer);
        eventBus.$off('calc-total-value', this.calcOffersTotalValue);
        eventBus.$off('delete-offer', this.deleteOffer);
        eventBus.$off('change-status', this.changeOfferStatus);
        eventBus.$off('open-link', this.openLinkStataLeadgid);
        eventBus.$off('add-report', this.addReport);
        this.toggleEditingMode(true);
    }

    addOffer(item: IOfferService): void {
        this.isShowError = false;
        // формируем объект для добавления в таблицу и генерируем у каждого оффера уникальный id
        const newItem: IOfferService = { ...item };
        newItem.total = 0;
        newItem.offer_qty = 0;
        newItem.id = getRandomId();
        newItem.offer_start_rate = item.goal_revenue_flat;
        newItem.goal_name = (item.goal_name !== '') ? item.goal_name : 'По умолчанию';
        this.statTaskObject.items!.push(newItem);
        this.offerServicesFromFiles = this.statTaskObject.items;
        this.pushStatTaskStatusDone();
    }

    goTo(route: { [key: string]: string | {} }, params?: any): void {
        this.$router.push(route, params);
    }

    showNotification(text: string): void {
        new NotificationMessage(text, 'success' );
    }

    async saveStatTask(): Promise<void> {
        this.loadingSaveStatTask = true;
        const postedStatTask: IStatTask = {
            invoice_id: this.statTaskObject.invoice_id,
            period: this.statTaskObject.period,
            advertiser_id: this.statTaskObject.advertiser!.id,
            files: this.statTaskObject.files,
        };
        if (this.itemsForSelect.length === 0) {
            try {
                if (!this.$props.isStatTask) {
                    for (const item of postedStatTask.files!) {
                            item.offer_services!.forEach((offer) => offer.id = null);
                    }
                    await postStatTask(postedStatTask);
                    this.isShowError = false;
                    this.showNotification('Задача успешно создана');
                    setTimeout(() => {
                        this.goTo({ name: 'stat-tasks' });
                    },
                    CHANGE_ROUTE_DEBOUNCE);
                } else {
                    for (const item of postedStatTask.files!) {
                        item.offer_services!.forEach((offer) => {
                            if (typeof offer.id === 'string') {
                                offer.id = null;
                            }
                        });
                    }
                    await patchStatTask(this.statTaskID!, postedStatTask!);
                    this.isShowError = false;
                    this.showNotification('Задача успешно отредактирована');
                    this.toggleEditingMode(false);
                    this.setUnsavedDataState(false);
                }
            } catch (err) {
                showServerError(err, 'Загрузка не сохранена');
            }
            this.loadingSaveStatTask = false;
        }
    }

    async addFiles(files: IFiles[]): Promise<void> {
        this.statTaskObject.files = [...this.statTaskObject.files!, ...files];
        this.clearValidate();
        this.setUnsavedData(true);
    }

    // сменить статус загрузки
    private pushStatTaskStatus(status: string): void {
        this.patchStatTaskStatus(status);
        this.statTaskObject.status = status;
        this.changeClickableOffer();
    }

    private findItemOfferServicesFromFiles(id: number | null): IOfferService | void {
        return this.offerServicesFromFiles!.find((item) => item.id === id);
    }

    private changeClickableOffer() {
        this.statuses.forEach((st) => {
            if (st.key === 'status') {
                st.isClickable = this.statTaskObject.status === STATS_TASK_STATUS.PROGRESS;
            }
        });
    }

    private showValidate(isError, message) {
        this.isShowErrorOfStatus = isError;
        this.shownMessageOfStatus = message;
        setTimeout(() => {
            this.isShowErrorOfStatus = false;
            this.shownMessageOfStatus = '';
        }, 850);
    }

    private calcOffersTotalValue(index): void {
        if (this.statTaskObject && this.statTaskObject.items![index]) {
            const currentItem = this.statTaskObject.items![index];
            currentItem.total = Number(currentItem.offer_rate! * currentItem.offer_qty!);

            this.setUnsavedData(true);
        }
    }

    private deleteItemOffers(items, index) {
        const newItem = items
            .find((item) => item.id === items[index].id);
        this.statTaskObject.files!.forEach((item: IFiles) => {
            if (item.offer_services) {
                const itemForDelete = item.offer_services.find((offer) => offer.id === newItem.id);

                if (itemForDelete) {
                    const indexOffer = item.offer_services.indexOf(itemForDelete);
                    item.offer_services.splice(indexOffer, 1);

                    if (item.offer_services && item.offer_services.length === 0) {
                        const indexItem = this.statTaskObject.files!.indexOf(item);
                        this.statTaskObject.files!.splice(indexItem, 1);
                    }
                }
            }
        });

        items!.splice(index, 1);
    }

    private deleteOffer(deletedItemIndex) {
        this.clearValidate();
        if (this.$props.isStatTask) {
            this.deleteItemOffers(this.offerServicesFromFiles, deletedItemIndex);
        } else {
            this.deleteItemOffers(this.statTaskObject.items, deletedItemIndex);
        }
    }
}
