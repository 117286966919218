
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SvgAdvertisers from '@/assets/icons/nav-bar/advertisers.svg';
import { getServiceStatsDetail } from '@/api/orgstats';
import { IServiceStatsDetail } from '@/api/types/orgstats';
import AppTable from '@/components/base/table/AppTable.vue';
import { showServerError } from '@/utils';
import Pagination from '@/components/base/table/Pagination.vue';
import { PAGINATION_PER_PAGE } from '@/configs/global';

@Component({
    components: {
        SvgAdvertisers,
        AppTable,
        Pagination,
    },
})
export default class ServicesStatsDetail extends Vue {
    @Prop({ type: String, required: true }) id!: string;

    @Prop({ type: String, required: true }) name!: string;

    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    headers = [
        {
            text: 'Счёт', sortable: false, value: 'invoice_id', align: 'center',
        },
        {
            text: 'Услуга', sortable: false, value: 'services.id', align: 'center', width: '50%',
        },
        {
            text: 'Сумма счёта', sortable: false, value: 'total_amount', align: 'right',
        },
    ];

    settings = {
        title: 'Отчёт по услугам',
        loading: false,
    };

    limit = PAGINATION_PER_PAGE;

    items: IServiceStatsDetail[] = [];

    // встроенный Vuetify объект
    pagination: {page: number} = {
        page: 1,
    };

    total = 0;

    @Watch('getFiltersParamsObject')
    applyFilters(): void {
        this.getServiceStatsDetail(0, PAGINATION_PER_PAGE, false);
    }

    created(): void {
        this.getServiceStatsDetail(0, PAGINATION_PER_PAGE, false);
    }

    async getServiceStatsDetail(offset: number, limit: number, isChangeLimit: boolean): Promise<void> {
        this.items = [];
        if (offset === 0 || isChangeLimit) {
            this.pagination.page = 1;
        }
        if (limit) {
            this.limit = limit;
        }
        try {
            const { meta, data } = await getServiceStatsDetail({
                offset,
                limit,
                ...({ type: this.id, ...this.getFiltersParamsObject }),
            });
            this.items = data;
            this.total = meta!.total || data.length;
        } catch (err) {
            showServerError(err, 'Отчет по услуге не загружен');
        }
        this.settings.loading = false;
    }
}
