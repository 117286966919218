
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import StatsCard from '@/components/base/StatsCard.vue';
import BasicInformation from '@/components/revenue/statTask/report/BasicInformation.vue';
import Adjusts from '@/components/revenue/statTask/report/Adjusts.vue';
import AcceptedAll from '@/components/revenue/statTask/report/AcceptedAll.vue';
import AcceptedNew from '@/components/revenue/statTask/report/AcceptedNew.vue';
import AcceptedPrev from '@/components/revenue/statTask/report/AcceptedPrev.vue';
import Extra from '@/components/revenue/statTask/report/Extra.vue';
import Losses from '@/components/revenue/statTask/report/Losses.vue';
import AdvertiserDuplicate from '@/components/revenue/statTask/report/AdvertiserDuplicate.vue';
import LeadDuplicate from '@/components/revenue/statTask/report/LeadDuplicate.vue';
import Clones from '@/components/revenue/statTask/report/Clones.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import { eventBus } from '@/eventbus';
import FileBasicInformation from '@/components/revenue/statTask/report/FileBasicInformation.vue';

@Component({
    components: {
        StatsCard,
        BasicInformation,
        FileBasicInformation,
        AdvertiserDuplicate,
        LeadDuplicate,
        AcceptedAll,
        AcceptedPrev,
        AcceptedNew,
        Extra,
        Clones,
        Adjusts,
        Losses,
        TooltipButton,
    },
})
export default class Report extends Vue {
  @Prop({ default: null }) report!: any;
  @Prop({ required: true }) tabs!: Array<{
    id: string;
    name: string;
    component: string;
    selector?: string;
  }> ;
  @Prop({ default: 'Oтчет' }) title!: string;

  tab = 0;

  goTo(router: Route & { name: string }, params: any): void {
      this.$router.push(router, params);
  }

  get reportId(): number {
      return this.isFileReport ? Number(this.$route.params.fileId) : Number(this.$route.params.reportId);
  }

  get isFileReport(): boolean {
      return !!this.$route.params.fileId;
  }

  get statTaskId(): number {
      return Number(this.$route.params.id);
  }

  changeTopScroll(): void {
      eventBus.$emit(`change-${this.tabs[this.tab].selector}`, `#${this.tabs[this.tab].selector}`);
  }
}
