
import { Component, PropSync, Vue } from 'vue-property-decorator';
import { showServerError } from '@/utils';
import { getCities, getCountries, getRegions } from '@/api/uapi';
import { SEARCH_VALUE_DEBOUNCE } from '@/configs/global';
import { IConditionGeo, ICountry } from '@/api/types/uapi';


interface IAutocompleteItem {
    country_id: number;
    region_id?: number;
    id: number;
    name: string;
    disabled?: boolean;
}

@Component
export default class ConditionGeo extends Vue {
    @PropSync('geo') geoSync!: IConditionGeo;

    possibleCountries: ICountry[] = [];
    autocomplete = {
        regions: {
            items: [] as IAutocompleteItem[],
            isLoading: false,
            timerId: 0,
            apiMethod: getRegions,
            searchValue: '',
        },
        cities: {
            items: [] as IAutocompleteItem[],
            isLoading: false,
            timerId: 0,
            apiMethod: getCities,
            searchValue: '',
        },
    };

    created(): void {

        // страны для мультиселекта по странам
        this.getPossibleCountries();

        // если уже были заданы регионы и города (обычно при редактировании условия),
        // то получаем их для автокомплита и выделяем
        const keys = [
            {
                server: 'region_id',
                autocomplete: 'regions',
            },
            {
                server: 'city_id',
                autocomplete: 'cities',
            },
        ];
        keys.forEach((key) => {
            if (this.geoSync[key.server].length) {
                this.geoSync[key.server].forEach((geo) => {
                    this.autocomplete[key.autocomplete].searchValue = String(geo.id || geo) || '';
                    this.getPossibleItems(key.autocomplete);
                });
            } else {
                this.autocomplete[key.autocomplete].searchValue = '';
            }
        });
    }

    async getPossibleCountries(): Promise<void> {
        try {
            const { data } = await getCountries();
            this.possibleCountries = data;
        } catch (err) {
            showServerError(err, 'Не удалось загрузить список стран');
        }
    }

    searchItems(key: string): void {
        if (this.autocomplete[key].searchValue) {
            clearTimeout(this.autocomplete[key].timerId);
            this.autocomplete[key].timerId = window.setTimeout(() => {
                this.getPossibleItems(key);
            }, SEARCH_VALUE_DEBOUNCE);
        }
    }

    async getPossibleItems(key: string): Promise<void> {
        this.autocomplete[key].isLoading = true;
        try {
            const params: {[key: string]: number[] | string} = {
                country_id: this.geoSync.country_id,
                q: this.autocomplete[key].searchValue,
            };
            if (key === 'cities') {
                params.region_id = this.geoSync.region_id.map(({ id }) => id);
            }
            const { data } = await this.autocomplete[key].apiMethod(params);
            this.$set(this.autocomplete[key], 'items', data);
        } catch (err) {
            showServerError(err);
        }
        this.autocomplete[key].isLoading = false;
    }

    changeSelect(items: number[] | IAutocompleteItem[], key: string): void {
        if (!items.length) {
            this.geoSync.region_id = [];
            this.geoSync.city_id = [];
            switch(key) {
            case ('country'):
                this.autocomplete.regions.items = [];
                break;
            case ('regions'):
                this.autocomplete.cities.items = [];
                break;
            }
        } else {
            switch(key) {
            case ('country'):
                this.filterRegionAndCitiesByCountry();
                this.filterAutocompleteItems();
                break;
            case ('regions'):
                this.filterCitiesByRegion();
                this.filterAutocompleteItems();
                break;
            }
        }
        if (!!this.autocomplete[key]) this.autocomplete[key].searchValue = '';
    }

    filterRegionAndCitiesByCountry(): void {
        this.geoSync.region_id = this.geoSync.region_id.filter((region) => {
            let hasRegionInCountry = false;
            hasRegionInCountry = this.geoSync.country_id.includes(region.country_id);
            return hasRegionInCountry;
        });

        this.geoSync.city_id = this.geoSync.city_id.filter((city) => {
            let hasCityInCountry = false;
            hasCityInCountry = this.geoSync.country_id.includes(city.country_id);
            return hasCityInCountry;
        });
    }

    filterCitiesByRegion(): void {
        this.geoSync.city_id = this.geoSync.city_id.filter((city) => {
            if (this.geoSync.region_id.length) {
                const region_id = this.geoSync.region_id.map((region) => region.id);
                return region_id.includes(city.region_id!);
            } else {
                return this.geoSync.country_id.includes(city.country_id);
            }
        });
    }

    filterAutocompleteItems(): void {
        this.autocomplete.regions.items.forEach((item) => {
            item.disabled = !this.geoSync.country_id.includes(item.country_id!);
        });

        this.autocomplete.cities.items.forEach((item) => {
            const hasCityInCountry = this.geoSync.country_id.includes(item.country_id);
            if (this.geoSync.region_id.length) {
                const regions_id = this.geoSync.region_id.map((region) => region.id);
                item.disabled = !regions_id.includes(item.region_id!);
            } else {
                item.disabled = !hasCityInCountry;
            }
        });
    }

}
