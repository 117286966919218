
import { Component, Prop, Vue } from "vue-property-decorator";
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";
import SvgColumns from "@/assets/icons/columns.svg";
import ColumnsWrapDesign from "@/components/base/filters/ColumnsWrapDesign.vue";
import { IColumnFilter, IFilter } from "@/types";
import FiltersDesign from "@/components/base/filters/FiltersDesign.vue";
import { eventBus } from "@/eventbus";
import { Mutation } from "vuex-class";
import { CreatedFilters } from '@/services/filters/filters';

@Component({
    components: {
        FiltersDesign,
        ColumnsWrapDesign,
        FiltersDialog,
        SvgColumns,
    },
})
export default class TablePageFilters extends Vue {
    @Prop({ default: 100 }) limit!: number;
    @Prop({ required: false }) columns!: IColumnFilter[];
    @Prop({ required: true }) filters!: IFilter[] | CreatedFilters | any;
    @Prop({ default: 500 }) colsWaxWidth!: number;
    @Prop({ default: 400 }) filtersWaxWidth!: number;
    @Prop({ default:'.table-page' }) attachClass!: string;
    @Prop({ default: false }) isGroupBy!: boolean;
    @Prop({ default: false }) isHideCols!: boolean;
    @Prop({ default: false }) dynamicColumns!: boolean;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    selected: string[] = [];
    isShowColumns = false;
    isShowFilters = false;
    classFilters: any;

    created(): void {
        eventBus.$on('clear-status', this.clearStatus);

        // добавлено объявление класса здесь, пока все страницы не приведены к общему формату передачи фильтров
        if (this.filters instanceof CreatedFilters) {
            this.classFilters = this.filters;
        } else {
            this.classFilters = new CreatedFilters(this.filters);
        }

        this.classFilters.setDefaultFilters();

        if (this.isHideCols) return;
        if (this.$route.query.columns) {
            this.selected = Array.isArray(this.$route.query.columns)
                ? this.$route.query.columns as string[]
                : [this.$route.query.columns];
        } else {
            this.selected = this.columns.flatMap((i) => i.items)
                .filter((j: any) => j.default !== false)
                .map((j: any) => j.value);
        }
    }

    submitFilters(offset?: number, limit?: number): void {
        this.isShowFilters = false;
        this.$emit('submit', offset, limit);
    }

    clearStatus(): void {
        const statusFilter = this.filters.filters?.find((i) => i.id === 'status');
        if (statusFilter) {
            this.$set(statusFilter, 'select', []);
            this.setFiltersParamsObject({ status: [] });
        }
    }

    beforeDestroy(): void {
        eventBus.$off('clear-status', this.clearStatus);
    }
}
