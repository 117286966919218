
import { Vue, Component, PropSync } from "vue-property-decorator";
import SvgTelegram from "@/assets/icons/telegram.svg";
import SvgSkype from "@/assets/icons/skype.svg";
import SvgWhatsapp from "@/assets/icons/whatsapp.svg";
import SvgWeChat from "@/assets/icons/wechat.svg";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import { IMessenger } from "@/api/types/user";

interface IMessengerItem {
    type: string;
    logo: SVGElement;
}

@Component({
    components: { SvgArrow, SvgTelegram, SvgSkype, SvgWhatsapp, SvgWeChat },
})

export default class MessengerSelect extends Vue {
    @PropSync('messenger', { required: true }) valueSync!: IMessenger[];
    selected: any = {};
    messengerItems: IMessengerItem[] = [
        { type: 'telegram', logo: SvgTelegram },
        { type: 'whatsapp', logo: SvgWhatsapp },
        { type: 'skype', logo: SvgSkype },
        { type: 'wechat', logo: SvgWeChat },
    ];

    messengerData: { [key: string]: string | undefined } = {
        telegram: '',
        whatsapp: '',
        skype: '',
        wechat: '',
    };

    selectType(item: IMessengerItem): void {
        this.selected = item;
        this.inputValue = this.messengerData[this.selected.type] || '';
    }

    get inputValue(): string {
        return this.messengerData[this.selected.type] || '';
    }

    set inputValue(value: string) {
        this.$set(this.messengerData, this.selected.type, value);
    }

    mounted(): void {
        this.selected = this.valueSync[0];
        this.inputValue = this.valueSync[0].account;
        this.messengerData.telegram = this.valueSync.find((item) => item.type === 'telegram')?.account;
        this.messengerData.whatsapp = this.valueSync.find((item) => item.type === 'whatsapp')?.account;
        this.messengerData.skype = this.valueSync.find((item) => item.type === 'skype')?.account;
        this.messengerData.wechat = this.valueSync.find((item) => item.type === 'wechat')?.account;
    }

    change(): void {
        const item = this.valueSync.find((item) => item.type === this.selected.type);
        if (item) {
            item.account = this.messengerData[this.selected.type] || '';
        }
    }

    clear(): void {
        this.selected = [{ type: '', account: '' }];
    }
}

