
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { getLeadDuplicates } from '@/api/revenue';
import CardMaterial from '@/components/base/CardMaterial.vue';
import Report from '@/mixins/report';
import DuplicateTable from '@/components/revenue/statTask/report/DuplicateTable.vue';
import TableScroll from '@/directives/TableScroll';
import { changeTopScrollWidth, showServerError } from '@/utils';
import { eventBus } from '@/eventbus';
import { IReportLeadDuplicate, IReportConversion } from '@/api/types/revenue';
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        DuplicateTable,
        CardMaterial,
    },
    directives: {
        TableScroll,
    },
})
export default class LeadDuplicate extends Mixins(Report) {
    @Prop({ type: Number, required: true }) reportId!: number;
    @Prop({ default: null }) report!: any;

    isExpand = true;
    filters: IFilter[] = [
        new filterClass.Select({
            id: 'key',
            label: 'Ключ',
            items: [],
            select: '',
            uuid: 'lead_duplicate',
        }),
        new filterClass.Select({
            id: 'id',
            label: 'ID',
            items: [],
            select: '',
            uuid: 'lead_duplicate',
        }),
        new filterClass.Input({
            id: 'lead_id',
            label: 'Lead ID',
            select: '',
            type: 'number',
        }),
        new filterClass.Input({
            id: 'affiliate_id',
            label: 'ID веба',
            select: '',
            type: 'number',
        }),
        new filterClass.Select({
            id: 'status',
            label: 'Статус',
            items: [],
            select: '',
            uuid: 'lead_duplicate',
        }),
        new filterClass.Select({
            id: 'payout',
            label: 'Выплата',
            items: [],
            select: '',
            uuid: 'lead_duplicate',
        }),
        new filterClass.Select({
            id: 'revenue',
            label: 'Доход',
            items: [],
            select: '',
            uuid: 'lead_duplicate',
        }),
        new filterClass.Datepicker({
            id: 'updated',
            label: 'Дата обновления',
            pickerType: 'month',
            select: '',
            range: true,
        }),
        new filterClass.Datepicker({
            id: 'lead_date',
            label: 'Дата создания',
            pickerType: 'month',
            select: '',
            range: true,
        }),
    ];

    headersDuplicate = [
        {
            text: '',
            value: 'data-table-expand',
            width: '50px',
            sortable: false,
        },
        {
            // text: 'Transaction ID',
            value: 'transaction_id',
            width: '355px',
            sortable: false,
        },
        {
            // text: 'Lead ID',
            value: 'lead_id',
            width: '150px',
            sortable: false,
        },
        {
            // text: 'ID веба',
            value: 'affiliate_id',
            width: '100',
            sortable: false,
        },
        {
            // text: 'Имя веба',
            value: 'affiliate_name',
            width: '150',
            sortable: false,
        },
        {
            // text: 'Статус лида',
            value: 'status',
            width: '100',
            sortable: false,
            align: 'center',
        },
        {
            // text: 'Выплата',
            value: 'payout',
            width: '100',
            sortable: false,
            align: 'right',
        },
        {
            // text: 'Доход',
            value: 'revenue',
            width: '100',
            sortable: false,
            align: 'right',
        },
        {
            // text: 'Прибыль',
            value: 'profit',
            width: '100',
            sortable: false,
            align: 'right',
        },
        {
            // text: 'Дата лида',
            value: 'lead_date',
            width: '150',
            sortable: false,
        },
        {
            // text: 'Дата обновления',
            value: 'updated',
            width: '150',
            sortable: false,
        },
    ];

    mounted(): void {
        this.getLeadDuplicates();
        eventBus.$on('change-leadgid-duplicate', changeTopScrollWidth);
    }

    updated(): void {
        changeTopScrollWidth('#leadgid-duplicate');
    }

    duplicateConversions(items: IReportLeadDuplicate): IReportConversion[] {
        const conversions = items.conversions.flatMap((el) => el.conversion);
        // удаляем 1 элемент, котоый является уникальной конверсией, оставляем только дубли
        conversions.splice(0, 1);
        return conversions;
    }

    get totalConversionItems(): Array<{id: number; conversion: IReportConversion}> {
        return this.filteredData.flatMap((el) => el.conversions);
    }

    get totalUniqConversionsLength(): number {
        return this.totalConversionItems?.filter((el) => el.conversion.status === 'approved')?.length;
    }

    allConversions(items: IReportLeadDuplicate): IReportConversion[] {
        return this.isExpand ? [items?.conversions[0].conversion] : items.conversions.flatMap((el) => el.conversion);
    }

    async getLeadDuplicates(): Promise<void> {
        this.loading = true;
        try {
            let duplicates;
            if (this.$props.report) {
                duplicates = this.$props.report.duplicates_leadgid;
                this.headersDuplicate.unshift({
                    text: 'Offer',
                    value: 'offer',
                    width: '50px',
                    sortable: false,
                    // @ts-ignore
                    class: 'offer',
                });
            } else {
                const { data } = await getLeadDuplicates(this.$props.reportId);
                duplicates = data;
            }
            this.total = duplicates.flatMap((el) => el.conversions).length;
            this.initialData = [...duplicates];
            this.filteredData = [...duplicates];
            this.fillFiltersItems();
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }

    applyFilters(): void {
        this.isExpand = false;
        this.submitFilters();
    }

    clearActiveFilters(): void {
        this.isExpand = true;
        this.clearFilters();
    }

    beforeDestroy(): void {
        eventBus.$off('change-leadgid-duplicate', changeTopScrollWidth);
    }
}
