
import { Component, Vue } from "vue-property-decorator";
import PageLayout from "@/components/layout/PageLayout.vue";
import Status from '@/components/base/Status.vue';
import { createOrUpdateStatusApproval, getOffersApproval } from "@/api/offers";
import { getEmployeesAffiliate } from "@/api/user";
import { showServerError } from "@/utils";
import { translateOfferStatusApprovals } from "@/utils/translate";
import ApprovalPageHeader from "@/components/offers/approvals/ApprovalPageHeader.vue";
import ApprovalPageInfo from "@/components/offers/approvals/ApprovalPageInfo.vue";
import { IApprovalInfoCard, IPostCreateOrUpdateApproval } from "@/api/types/offers";
import SvgCheck from '@/assets/icons/check.svg';
import SvgCancel from '@/assets/icons/cancel-white.svg';
import SvgPause from '@/assets/icons/pause-white.svg';
import Loader from "@/components/base/Loader.vue";
import ApprovalPageHistory from "@/components/offers/approvals/ApprovalPageHistory.vue";
import { IStatus } from "@/types";

@Component({
    components: {
        ApprovalPageHistory,
        Loader,
        ApprovalPageInfo,
        ApprovalPageHeader,
        PageLayout,
        Status,
        SvgCheck,
        SvgCancel,
        SvgPause,
    },
})

export default class Approval extends Vue {
    approvalRequestNumber: string = '';
    currentApproval: any = {};
    approvalInfoCards: IApprovalInfoCard[] = [];
    isLoading: boolean = false;

    get getTitle(): string {
        return `Запрос доступа №${this.approvalRequestNumber}`;
    }

    get getStatus(): IStatus {
        return translateOfferStatusApprovals(this.currentApproval.status);
    }

    get approvalID(): string {
        return this.$route.params.id;
    }

    async getCurrentApproval(): Promise<void> {
        try {
            this.currentApproval = await getOffersApproval(this.approvalRequestNumber);
            const affiliate = await getEmployeesAffiliate(this.currentApproval.affiliate.legacy_id);
            this.currentApproval.affiliate.status = affiliate.status;
            this.currentApproval.affiliate.email = affiliate.email;
            this.approvalInfoCards = this.getDataForApprovalInfoCards;
        } catch (err) {
            showServerError(err,  'Запрос на предоставление доступа не найден');
        }
    }

    get getDataForApprovalInfoCards(): IApprovalInfoCard[] {
        return [
            {
                card_title: 'Оффер',
                card_data: {
                    id: {
                        isRoute: true,
                        text: 'ID',
                        value: this.currentApproval.offer.legacy_id,
                    },
                    name: {
                        isRoute: true,
                        text: 'Название',
                        value: this.currentApproval.offer.name,
                    },
                    status: {
                        text: 'Статус',
                        value: this.currentApproval.offer.status,
                    },
                    confirmations: {
                        text: 'Требует согласования',
                        value: this.currentApproval.offer.confirmations.requires_advertiser_approval,
                    },
                    manager_name: {
                        text: 'Менеджер рекламодателя',
                        value: this.currentApproval.offer.advertiser.manager.name,
                    },
                },
                card_route: {
                    name: 'offer',
                    param: this.currentApproval.offer.legacy_id,
                },
            },
            {
                card_title: 'Партнёр',
                card_data: {
                    id: {
                        isRoute: true,
                        text: 'ID',
                        value: this.currentApproval.affiliate.legacy_id,
                    },
                    name: {
                        isRoute: true,
                        text: 'Имя',
                        value: this.currentApproval.affiliate.name,
                    },
                    email: {
                        text: 'Email',
                        value: this.currentApproval.affiliate.email,
                    },
                    status: {
                        text: 'Статус',
                        value: this.currentApproval.affiliate.status,
                    },
                    manager_name: {
                        text: 'Менеджер партнёра',
                        value: this.currentApproval.affiliate.manager.name,
                    },
                },
                card_route: {
                    name: 'affiliate',
                    param: this.currentApproval.affiliate.legacy_id,
                },
            },
        ];
    }

    created(): void {
        this.approvalRequestNumber = this.$route.params.id;
        this.getCurrentApproval();
    }

    async changeApprovalStatus(status: string): Promise<void> {
        this.isLoading = true;
        try {
            const data: IPostCreateOrUpdateApproval = {
                pairs: [{
                    affiliate_id: this.currentApproval.affiliate.legacy_id,
                    offer_id: this.currentApproval.offer.legacy_id,
                }],
                status };
            await createOrUpdateStatusApproval(data);
            await this.getCurrentApproval();
        } catch (err) {
            showServerError(err);
        } finally {
            this.isLoading = false;
        }
    }
}

