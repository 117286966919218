
import { Component, Mixins, Watch } from "vue-property-decorator";
import { IColumnFilter, IFilter } from "@/types";
import TableMixin from "@/mixins/table";
import PageTabs from "@/components/base/PageTabs.vue";
import PageLayout from "@/components/layout/PageLayout.vue";
import { Advertiser } from "@/services/advertiser/Advertiser";
import AdvertiserSingle from "@/components/revenue/advertiser/AdvertiserSingle.vue";
import { getAllInvoices, getAllStatTasks, getOneAdvertiserCompiled } from "@/api/revenue";
import { downloadExcelFile, showServerError } from "@/utils";
import { eventBus } from "@/eventbus";
import OffersTable from "@/components/offers/offer/OffersTable.vue";
import { getEmployeesOffersV2 } from "@/api/offers";
import { OffersColumns, OffersFilters } from "@/services/TablePage/offers";
import { Offer } from "@/services/offers/Offer";
import TablePageFilters from "@/components/base/filters/TablePageFilters.vue";
import SvgFileDownloaded from "@/assets/icons/file-downloaded.svg";
import Search from "@/components/base/Search.vue";
import debounce from "lodash-es/debounce";
import OfferPeriodSelect from "@/components/offers/offer/OfferPeriodSelect.vue";
import { namespace } from "vuex-class";
import OfferStatistic from "@/components/offers/offer/OfferStatistic.vue";
import { InvoicesAdvertisersColumns, InvoicesAdvertisersFilters } from "@/services/TablePage/invoicesAdvertisers";
import InvoicesTable from "@/components/revenue/invoice/InvoicesTable.vue";
import TasksTable from "@/components/revenue/statTask/TasksTable.vue";
import { StatTasksColumns, StatTasksFilters } from "@/services/TablePage/statTasks";
import Contracts from "@/components/revenue/advertiser/Contracts.vue";
import InvoiceAndDownloadBtn from "@/components/revenue/advertiser/InvoiceAndDownloadBtn.vue";

import CreateBtn from "@/components/base/buttons/CreateBtn.vue";

const revenue = namespace('revenueModule');
const invoice = namespace('invoiceModule');

interface ITabItem {
    tab: string,
    component?: any,
    additionalComponent?: any,
    additionalTitleAction?: any,
    apiMethod?: Function,
    itemClass?: any,
    columns?: IColumnFilter[],
    filters?: IFilter[],
    isGroupFilters?: boolean,
    errorText?: string,
    isDownload?: boolean,
    isCreateBtns?: boolean,
    downloadMethod?: Function,
    isCreate?: boolean,
    createMethod?: any,
    isSearch?: boolean,
    isNeedDownloadParams?: boolean,
    class?: string,
}

@Component({
    beforeRouteLeave(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        this.setAdvertiser(null);
        next();
    },
    beforeRouteEnter(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        next(vm => {
            if (from.fullPath !== '/') {
                vm.beforeListRoute = from.fullPath;
            }
        });
    },
    components: {
        CreateBtn,
        InvoiceAndDownloadBtn,
        Contracts,
        Search,
        SvgFileDownloaded,
        TablePageFilters,
        AdvertiserSingle,
        PageTabs,
        PageLayout,
    },
})

export default class AdvertiserSingleWrap extends Mixins(TableMixin) {
    @revenue.Mutation('SET_CURRENT_ADVERTISER') setAdvertiser!: (payload: any) => void;
    @revenue.Mutation('SET_ADVERTISER_FIELDS') setAdvertiserFields;
    @invoice.Mutation('SET_ADVERTISER') setAdvertiserInvoice;
    @revenue.Mutation('SET_ACCOUNT_MANAGER_NAME') setAccountManagerName;

    beforeListRoute = '/revenue/advertisers';
    // данные рекламодателя
    advertiserStore;
    // имя аккаунт-менеджера
    accountManagerNameStore: string | null | undefined = '';
    advertiser = {} as Advertiser;
    settings = { loading: false };
    tab: number = 0;
    currentFilters = [] as IFilter[];
    currentColumns = [] as IColumnFilter[];
    currentOffset = 0;
    isTableWithSections: boolean = false;
    isDownloadLoading: boolean = false;
    tabItems: ITabItem[] = [];
    get getTabs(): ITabItem[] {
        return [
            {
                tab: 'Основное',
                isCreateBtns: true,
            },
            {
                tab: 'Офферы',
                component: OffersTable,
                additionalComponent: OfferStatistic,
                apiMethod: getEmployeesOffersV2,
                additionalTitleAction: OfferPeriodSelect,
                isDownload: true,
                downloadMethod: getEmployeesOffersV2,
                isSearch: true,
                itemClass: Offer,
                columns: new OffersColumns().getColumnsForAdvertiserDetailPage,
                filters: new OffersFilters().filters,
                isGroupFilters: true,
                errorText: 'Ошибка загрузки списка офферов',
                class: 'offers',
            },
            {
                tab: 'Договоры',
            },
            {
                tab: 'Счета',
                component: InvoicesTable,
                apiMethod: getAllInvoices,
                isCreate: true,
                createMethod: this.redirectToCreateInvoice,
                columns: new InvoicesAdvertisersColumns().getColumnsForAdvertiserDetailPage,
                filters: new InvoicesAdvertisersFilters().filters,
                errorText: 'Ошибка загрузки списка счетов',
            },
            {
                tab: 'Загрузки',
                component: TasksTable,
                apiMethod: getAllStatTasks,
                isCreate: true,
                createMethod: this.redirectToCreateTask,
                columns: new StatTasksColumns().getColumnsForAdvertiserDetailPage,
                filters: new StatTasksFilters().filters,
                errorText: 'Ошибка загрузки списка загрузок',
            },
        ];
    }

    get getTitle(): string {
        return this.tab === 0 ? this.advertiser.name : `${this.advertiser.name} (${this.advertiser.id})`;
    }

    get isShowTableFilters(): boolean {
        return this.tab > 0 && !!this.tabItems[this.tab].columns && this.tabItems[this.tab].filters!.length > 0;
    }

    backToList(): void {
        this.$router.push({ path: this.beforeListRoute });
    }

    changeTab(tab: number): void {
        this.tab = tab;
        this.setDefaultParamsObject({});
        this.setFiltersParamsObject({});
        this.updateQueryString({});
        this.updateTable();
        this.clearSelectFilters(this.currentFilters);
    }

    async updateTable(): Promise<void> {
        if ([0, 2].includes(this.tab)) return;
        this.dataTable = [];
        const tab = this.tabItems[this.tab];
        this.currentFilters = tab.filters!;
        this.currentColumns = tab.columns!;
        this.itemsClass = tab.itemClass!;
        this.apiMethod = tab.apiMethod;
        await this.getPagination();
        this.isTableSectionsAvailability();
    }

    @Watch('$route.query')
    setTabToUrl(query?: object): void {
        if (Number(this.$route.query.tab!) === this.tab) return;

        const params = { ...query, ...{ tab: this.tab } };
        this.updateQueryString(params);
    }

    clearFilters(): void {
        this.clearFilter();
    }

    async getPagination(offset?: number): Promise<void> {
        this.currentOffset = offset || 0;
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, this.tabItems[this.tab].errorText!);
        }
    }

    async created(): Promise<void> {
        await this.getAdvertiser();
        this.tab = Number(this.$route.query.tab!) || 0;
        this.setTabToUrl();
        this.tabItems = this.getTabs;
        await this.updateTable();
        eventBus.$on('update-advertiser', this.getAdvertiser);
    }

    async getAdvertiser(): Promise<void> {
        const ID = Number(this.$route.params.id);
        try {
            const data = await getOneAdvertiserCompiled(ID);
            this.advertiser = new Advertiser(data);
            this.setAdvertiser({ id: this.advertiser.id, name: this.advertiser.name });
            document.title = `${this.advertiser.name}`;
        } catch (err) {
            showServerError(err, 'Рекламодатель не найден');
        }
    }

    async download(): Promise<void> {
        this.isDownloadLoading = true;
        try {
            const tab = this.tabItems[this.tab];

            if (!!tab.downloadMethod) {
                const params = this.getFiltersParamsObject;
                const columns = this.$route.query.columns as string[];
                params.fields = columns.join(',');
                const headers = { "Accept": "application/vnd.ms-excel" };
                const responseType = 'blob';
                const file = await tab.downloadMethod(params, headers, responseType);
                const name = tab.tab;
                downloadExcelFile(file, name, 'xlsx');
            }
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
        this.isDownloadLoading = false;
    }

    searchDebounce = debounce((search: string): void => {
        this.search = search;
        this.setCustomQuery({ search });
        this.getPagination(0);
    }, 500);

    setCustomQuery(customQuery: any): void {
        const query = this.getFiltersParamsObject;
        const offset = this.$route.query.offset;
        const allQuery = { ...query, ...customQuery, offset };
        this.setFiltersParamsObject(allQuery);
        this.updateQueryString(allQuery);
    }

    isTableSectionsAvailability(): void {
        const el = this.$el;
        this.isTableWithSections = !!el.querySelector('.extended-report-table__custom-header');
    }

    beforeDestroy(): void {
        this.setAdvertiser(null);
    }

    redirectToCreateInvoice(): void {
        this.setDataToStore();
        this.$router.push({ name: 'invoice-creation' });
    }

    redirectToCreateTask(): void {
        this.setDataToStore();
        this.$router.push({ name: 'stat-task-creation' });
    }

    private setDataToStore(): void {
        // данные для создания счета и загрузки
        this.advertiserStore = {
            id: this.advertiser.id,
            name: this.advertiser.name,
        };
        const advertiserInvoiceStore = {
            id: this.advertiser.id,
            name: this.advertiser.name,
            contractors: this.advertiser.contractors,
            status: this.advertiser.status,
            tag: this.advertiser.tag,
            account_manager: this.advertiser.account_manager,
            managers: this.advertiser.managers,
            projects: this.advertiser.projects,
            currency: this.advertiser.currency,
        };
        this.accountManagerNameStore = this.advertiser.account_manager
            ? this.advertiser.account_manager!.full_name : null;
        // передаем ву store данные рекламодателя и менеджера,
        // эти данные используем после перехода на другую страницу
        // в частности на странице создания счета, и странице создания задачи
        // на загрузку статистики
        this.setAdvertiserFields(this.advertiserStore);
        this.setAdvertiserInvoice(advertiserInvoiceStore);
        this.setAccountManagerName(this.accountManagerNameStore);
    }

}

