
import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from '@/components/base/form/Multiselect.vue';
import { Getter, Mutation, namespace } from 'vuex-class';
import { getCities, getCountries, getRegions } from '@/api/uapi';
import BaseAutocomplete from '@/components/base/form/BaseAutocomplete.vue';
import Filters from '@/components/base/filters/Filters.vue';
import { showServerError } from '@/utils';
import { IAnalyticField } from '@/api/types/uapi';
import { IFilter } from '@/types';
import Status from '@/components/base/Status.vue';
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import isEqual from 'lodash-es/isEqual';
import { eventBus } from '@/eventbus';
import { getAffiliateSearch } from '@/api/user';
import { filterClass } from '@/services/filters/filterClass';

const uapi = namespace('uapiModule');

@Component({
    components: {
        DateTimePickerNew,
        BaseAutocomplete,
        Multiselect,
        Filters,
        Status,
    },
})
export default class ApplicationFilters extends Vue {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    @uapi.Mutation('SET_CONVERSIONS_FORECAST_QUERY') setConversionsForecastQuery;

    @Prop({ type: String, required: true }) tabType!: '';
    @Prop({ type: Array, default: () => [] }) requiredFields!: [];
    @Prop({ type: Array, default: () => [] }) fields!: [];
    @Prop({ type: Array, default: () => [] }) preparedFilters!: [];
    @Prop({ type: Array, default: () => [] }) checkboxes!: [];
    @Prop({ type: Array, default: () => [] }) dates!: [];
    @Prop({ type: Array, default: () => [] }) multiselects!: [];
    @Prop({ type: Array, default: () => [] }) strings!: [];
    @Prop({ type: Array, default: () => [] }) singleIntegers!: [];
    @Prop({ type: Array, default: () => [] }) rangeIntegers!: [];
    @Prop({ type: Array, default: () => [] }) autocompletes!: [];
    @Prop({ required: false, default: false }) disabledSubmit!: boolean;

    filters: IFilter[] = [];
    panel = true;
    typing = {};
    loading = {};

    get emptyConditions(): {[key: string]: boolean} {
        const errors: {
            [key: string]: boolean;
        } = {};

        if (this.offersTabType) {
            const affiliateIdFilter = this.filters.find(filter => filter.id === 'affiliate_id');
            const countryFilter = this.filters.find(filter => filter.id === 'country');
            if (!countryFilter || !countryFilter.select || !countryFilter.select.length) {
                errors.isEmptyCountry = true;
            }
            if (!affiliateIdFilter || !affiliateIdFilter.select) {
                errors.isEmptyAffiliateId = true;
            }
        } else if (this.countTabType) {
            const periodFilter = this.filters.find(filter => filter.id === 'date');
            if (!periodFilter || !periodFilter.select) {
                errors.isEmptyPeriod = true;
            }
        }

        return errors;
    }

    get offersTabType(): boolean {
        return this.$props.tabType === 'offers';
    }

    get countTabType(): boolean {
        return this.$props.tabType === 'count';
    }

    get isShowFilters(): boolean {
        return !!this.filters.length;
    }

    async prepareFilters(): Promise<void> {
        const checkboxes: IFilter[] = [];
        const dates: IFilter[] = [];
        const autocompletes: IFilter[] = [];
        const multiselect: IFilter[] = [];
        const ranges: IFilter[] = [];
        const rest: IFilter[] = [];

        for (let i = 0; i < this.$props.fields.length; i++) {
            const item = this.$props.fields[i];

            if (item.onlyCountTab && this.offersTabType) {
                continue;
            }
            const filter = await this.getFilter(item);

            if (filter.checkbox) {
                checkboxes.push(filter);
            } else if (filter.datepicker) {
                dates.push(filter);
            } else if (filter.autocomplete) {
                autocompletes.push(filter);
            } else if (filter.multiple) {
                multiselect.push(filter);
            } else if (item.type === 'integer' && item.analytic === 'range') {
                filter.getValueForSubmit = (): any => {
                    if (!filter.select.length) {
                        return { [filter.id ]: [] };
                    }
                    return ({
                        [filter.id]: filter.select.map((v) => !v && v !== 0 ? null : Number(v)),
                    });
                };
                ranges.push(filter);
            } else {
                rest.push(filter);
            }
        }
        this.filters = this.filters.concat(checkboxes, dates, multiselect, autocompletes, rest, ranges);
        const countryIndex = this.filters.findIndex((fl) => fl.id === 'country');
        const newCountryIndex = this.filters.findIndex((fl) => fl.id === 'region');
        const country = this.filters.splice(countryIndex, 1);
        this.filters.splice(newCountryIndex, 0, country[0]);
        this.setDefaultFiltersParams({});
    }

    async created(): Promise<void> {
        await this.prepareFilters();
        //потому что всегда есть offset в урле
        if (Object.keys(this.$route.query)?.length > 1 && this.$route.name !== 'resend-requests') {
            setTimeout(() => {
                if (this.filters.find((el) => el.id === 'region')?.select instanceof Array) {
                    this.setFiltersParamsObject({ ...this.getFiltersParamsObject,
                        ...{ region: this.filters.find((el) => el.id === 'region')?.select
                            .map((fl) => fl.code !== undefined ? fl.code : fl.name.split(' ')[0]) } });
                }
                this.submitFilters();
            }, 3000);
        }
    }

    updateSearch(filter: any): void {
        if (filter.apiMethod === undefined) {
            return;
        }
        if (filter.autocompleteValue === this.$route.query[filter.id]) {
            return;
        }
        this.loading[filter.id] = true;
        const el = this.$refs[filter.id]?.[0];
        // @ts-ignore
        this.$refs.filters.debounceQuerySelections(filter.autocompleteValue, filter.id,
            // при множественной вставке в автокомплит
            // функция обратного вызова,
            // оставляющая в инпуте автокомплита только не найденные значения, не выбранные значения
            (items) => {
                if (!(items?.length > 1 && filter.select?.length > 0)) return;

                let missingValues = '';
                items.forEach((i) => {
                    const value = filter.select.some((f) => Number(i) === f![filter.itemValue]);
                    if (!value) {
                        missingValues = `${missingValues + i} `;
                    }
                });
                el.lazySearch = missingValues;
                filter.autocompleteValue = missingValues;
            },
        );
        this.loading[filter.id] = false;
    }

    clear(filter: any): void {
        filter.select = Array.isArray(filter.select) ? [] : '';
        filter.items = [];
    }

    removeItem(filter: any): void {
        if ('remove' in filter) {
            filter.select = '';
            const filters = { ...this.getFiltersParamsObject };
            delete filters[filter.id];
            this.setFiltersParamsObject(filters);
            const filtersDefault = { ...this.setDefaultFiltersParams };
            delete filtersDefault[filter.id];
            this.setDefaultFiltersParams(filters);
        }
    }

    isCheckboxItem(item: IAnalyticField): boolean {
        return item.analytic === 'is_exist_bool' || item.type === 'bool';
    }

    async getCountries(filter: IFilter): Promise<void> {
        try {
            const { data } = await getCountries();
            filter.items = data;
        } catch (err) {
            showServerError(err);
            filter.items = [];
        }
    }

    async getFilter(item: IAnalyticField): Promise<IFilter> {
        const props: any = {
            id: item.field,
            label: item.label,
            type: item.type === 'integer' ? 'number' : item.type,
            select: item.type === 'integer' && item.analytic === 'range' ? [] : '',
            items: item.choices,
            isErrorIcon: this.$props.requiredFields.includes(item.field),
            isSubmitEmptySelect: false,
            customSlot: (item.type === 'integer' && item.analytic === 'range') || item.type === 'date-time-picker',
        };

        if (item.field === 'country') {
            return new filterClass.Select(await this.makeCountryFilter(item, props));
        }

        if (item.analytic === 'autocomplete') {
            props.autocompleteValue = '';
            props.multiple = true;
            props.customSlot = true;
            props.select = [];
            this.loading[props.id] = false;
        }
        if (item.field === 'region') {
            return new filterClass.Autocomplete(this.makeRegionFilter(props));
        }
        if (item.field === 'city') {
            return new filterClass.Autocomplete(this.makeCityFilter(props));
        }
        if (item.field === 'affiliate_id') {
            props.apiMethod = getAffiliateSearch;
            props.key = 'query';
            props.itemValue = 'id';
            props.itemText = 'name';
            props.customSlot = false;

            if (item.type === 'integer') {
                props.select = '';
                return new filterClass.Input(props);
            } else {
                return new filterClass.Autocomplete(props);
            }
        }

        if (item.type === 'date') {
            props.select = [];
            props.pickerType = 'date';
            props.range = true;
            return new filterClass.Datepicker(props);
        }
        if (this.isCheckboxItem(item)) {
            props.select = false;
            return new filterClass.Checkbox(props);
        }
        if (item.analytic === 'multiselect') {
            props.multiselect = true;
            props.select = [];
            return new filterClass.Multiselect(props);
        }
        if (item.analytic === 'autocomplete') {
            return new filterClass.Autocomplete(props);
        }

        return new filterClass.Base(props);
    }

    async makeCountryFilter(item: any, filter: any): Promise<any> {
        await this.getCountries(filter);
        filter.itemText = 'name';
        filter.itemValue = 'isoCode';
        filter.hasOptions = true;
        filter.select = '';
        filter.type = 'string';
        filter.customSlot = true;
        if (item.analytic === 'multiselect' && this.countTabType) {
            filter.template = (country) => country.name;
        }

        return filter;
    }

    makeRegionFilter(filter: any): any {
        if (this.$route.query.region) {
            filter.autocompleteValue = this.$route.query.region;
        }
        filter.apiMethod = getRegions;
        filter.key = 'q';
        filter.itemValue = 'id';
        filter.itemText = 'name';
        filter.supValue = 'code';
        filter.type = 'string';
        filter.items = [];
        filter.select = [];
        filter.multiple = true;
        filter.customParams = (_, value) => {
            const params: Record<string, any> = { q: value };

            const countries = this.filters.find((f) => f.id === 'country');
            if (countries?.select) {
                const countriesArray = Array.isArray(countries.select) ? countries.select : countries.select.split(' ');
                params.country_id = countriesArray.map((country) => countries.items!.find((item) => item.isoCode === country).id);
            }

            return params;
        };

        return filter;
    }

    makeCityFilter(filter: any): any {
        filter.apiMethod = getCities;
        filter.key = 'q';
        filter.itemValue = 'id';
        filter.itemText = '1';
        filter.type = 'string';
        filter.items = [];
        filter.select = [];
        filter.multiple = true;
        filter.customParams = (_, value) => {
            const params: Record<string, any> = { q: value };

            const countries = this.filters.find((f) => f.id === 'country');
            if (countries?.select) {
                const countriesArray = Array.isArray(countries.select) ? countries.select : countries.select.split(' ');
                params.country_id = countriesArray.map((country) => countries.items!.find((item) => item.isoCode === country).id);
            }

            const region = this.filters.find((f) => f.id === 'region');
            if (region?.select) {
                params.region_id = region.select.map((region) => region.id);
            }

            return params;
        };

        return filter;
    }

    submitFilters(): void {
        const params = JSON.parse(JSON.stringify(this.getFiltersParamsObject));

        if (typeof params.country === 'string') {
            params.country = [params.country];
        }
        this.setConversionsForecastQuery({
            [this.$router.currentRoute.hash]: params,
        });

        this.$emit('submit-filters');
    }

    checkMinValue(item: {select: number[]}): void {
        if (!item.select[0] && item.select[0] !== 0) {
            this.$set(item.select, 0, null);
        }
    }

    rangeRules(item: IFilter, key: string): string | boolean | void {
        switch (key) {
        case 'max': {
            if (!+item.select[0] && +item.select[0] !== 0 ||
                ((+item.select[1] || +item.select[1] == 0) && +item.select[1] > +item.select[0]) ||
                this.typing[`${item.label}Min`]
            ) {
                return true;
            } else {
                if ((item.select[0] === "" || item.select[0] === null) && (item.select[1] === "" || item.select[1] === null)) {
                    return true;
                }
                if (item.select[0] !== "" && item.select[0] !== null) return `Значение должно быть больше, чем ${item.select[0]}`;
            }
            break;
        }
        case 'min': {
            if (!+item.select[1] && +item.select[1] !== 0 ||
                ((+item.select[0] || +item.select[0] == 0) && +item.select[0] < +item.select[1]) ||
                this.typing[`${item.label}Max`]
            ) {
                return true;
            } else {
                if ((item.select[0] === "" || item.select[0] === null) && (item.select[1] === "" || item.select[1] === null)) {
                    return true;
                }
                if (item.select[1] !== "" && item.select[1] !== null) return `Значение должно быть меньше, чем ${item.select[1]}`;
            }
            break;
        }
        }
    }

    get disabledSubmitFilters(): boolean {
        const obj = {};
        const previousObject = {};
        this.filters.filter((item) => item.select.toString().length > 0).forEach(item => {
            obj[item.id] = item.select;
        });
        for (const key in this.getFiltersParamsObject) {
            if (!!this.getFiltersParamsObject[key] && this.getFiltersParamsObject[key].toString().length > 0 ) {
                previousObject[key] = this.getFiltersParamsObject[key];
            }
        }
        const result = isEqual(previousObject, obj);
        eventBus.$emit('change-disabled', result);
        return result;
    }

    clearFilters(): void {
        this.$emit('clear');
    }

    beforeDestroy(): void {
        this.setConversionsForecastQuery({
            [this.$router.currentRoute.hash]: {},
        });
    }
}
