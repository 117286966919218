
import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { getFormatDate } from "@/utils/formatDates";
import Status from "@/components/base/Status.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { IAboutItem, IEditableAboutItem } from "@/services/administration/EmployeeMethod";

@Component({
    components: { BaseSelect, Status },
})

export default class EmployeeMethodAboutItem extends Vue {
    @Prop({ required: true }) item!: IAboutItem | IEditableAboutItem;
    @PropSync('edit', { required: false, default: false }) isEdit!: boolean;

    getFormatDate = getFormatDate;
    value: any = '';

    @Watch('isEdit')
    prepareEdit(): void {
        this.value = this.isEdit ? JSON.parse(JSON.stringify(this.item.value)) : '';
    }
}
