
import DownloadBtn from "@/components/base/buttons/DownloadBtn.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import { downloadExcelFile, showNotification, showServerError } from "@/utils";
import {
    getChargeRequestFile,
    getOrganizations,
    postChargeRequestFile,
    editChargeRequest,
    getCurrencies,
    patchChargeRequest,
} from "@/api/payout";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import SvgTrash from "@/assets/icons/trash-dark.svg";
import EditIconBtn from "@/components/base/buttons/EditIconBtn.vue";
import CancelBtn from "@/components/base/buttons/CancelBtn.vue";
import router from "@/router";

@Component({
    components: {
        EditModeTextField,
        BaseSelect,
        DownloadBtn,
        ViewDateTime,
        BaseCard,
        BaseAutocomplete,
        SvgTrash,
        EditIconBtn,
        CancelBtn,
    },
})
export default class ChargeRequestPageHeader extends Vue {
    @Prop({ required: true }) request!: any;

    @InjectReactive() readonly isNewRequest!: any;

    editingMode = false;
    file: any = {};
    org_guid: any = '';
    comment: string = '';
    organizations: any[] = [];
    amount: number | string = '';
    currencies: any[] = [];

    get headers(): any[] {
        return [
            { name: 'ID', value: this.request.id, editable: false, isShow: true },
            { name: 'Сумма выплаты', value: this.request.amount, editable: this.isNewRequest, isShow: true },
            { name: 'Внешний ID', value: this.request.code, editable: false, isShow: true },
            { name: 'Курс', value: this.request.rate_amount, editable: this.isNewRequest, isShow: true },
            { name: 'Дата создания', value: this.request.created_at, date: true, editable: false, isShow: true },
            { name: 'Статья ДДС', value: this.request.dds.name, editable: false, isShow: true },
            { name: 'Дата обновления', value: this.request.updated_at, date: true, editable: false, isShow: true },
            { name: 'Проект', value: this.request.project.name, editable: false, isShow: true },
            { name: 'Автор', value: this.request.creator, editable: false, isShow: true },
            { name: 'Файл', value: this.request.attachment, file: true, editable: true, isShow: true },
            { name: 'Наша организация', value: this.request.legal, editable: true, isShow: true },
            { name: 'Комментарий', value: this.request.comment || '', editable: true, isShow: true },
            // { name: 'Реквизиты', value: this.request.requisites, editable: false, isShow: this.request.requisites },
            { name: 'Способ выплаты', value: this.request.contractor.full_name, editable: false, isShow: true },
        ];
    }

    getNameCurrency(guid: string): string {
        return this.currencies.find(c => c.guid === guid)?.name || '';
    }

    async deleteChargeRequest(): Promise<void> {
        try {
            await patchChargeRequest([Number(router.currentRoute.params.id)], 'deleted');
            this.$emit('update-request');
            showNotification('Успешно! Заявка удалена');
        } catch (e) {
            showServerError(e, 'Не удалось удалить данную заявку');
        }
    }

    getNumberAmount(value: any): string{
        return value ?
            `${Number(value).toLocaleString('ru-RU', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })}` : '0,00';
    }

    setValues(): void{
        this.file = this.request.attachment;
        this.org_guid = this.request.legal.guid;
        this.comment = this.request.comment;
        this.amount = this.request.amount;
    }

    async getData(): Promise<void> {
        try {
            const currencies = await getCurrencies();
            this.currencies = currencies;
        } catch (err) {
            showServerError(err, 'Организации не загружены');
        }
    }

    async getDataForSelect(): Promise<void> {
        try {
            this.organizations = await getOrganizations();
        } catch (err) {
            showServerError(err, 'Организации не загружены');
        }
    }

    async downloadFile(): Promise<void> {
        try {
            const report = await getChargeRequestFile(this.request.id);
            downloadExcelFile(report, 'Заявка на расход', this.request.attachment.extension);
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
    }

    editInfo(): void {
        this.editingMode = !this.editingMode;

        if (this.editingMode) {
            this.getDataForSelect();
            this.setValues();
        } else {
            this.updateChargeRequest();
        }
    }

    async updateChargeRequest(): Promise<void> {
        this.org_guid = this.org_guid.hasOwnProperty('guid') ? this.org_guid.guid : this.org_guid;
        const updatedRequest = {} as { org_guid: string, comment: string, amount: number |string } ;

        if (this.org_guid !== this.request.legal.guid) {
            updatedRequest.org_guid = this.org_guid;
        }
        if (this.comment !== this.request.comment) {
            updatedRequest.comment = this.comment;
        }

        try {
            if (this.file !== this.request.attachment) {
                const fileName = this.file.name.replace(/[()]/g, '');
                const formData = new FormData();
                formData.append('attachment', this.file as Blob, fileName);
                await postChargeRequestFile(this.request.id, formData);
            }
            if (Object.keys(updatedRequest).length > 0) {
                await editChargeRequest(this.request.id, updatedRequest );
            }
            if (Object.keys(updatedRequest).length > 0 || this.file !== this.request.attachment) {
                this.$emit('update-request');
            }
            this.editingMode = false;

        } catch (err) {
            showServerError(err, 'Не удалось изменить заявку на расход');

        }
    }

    created(): void {
        this.getData();
    }
}

