
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgTrash from '@/assets/icons/trash.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import AutocompleteListItem from "@/components/base/form/AutocompleteListItem.vue";
import { IFilter } from "@/types";
import BaseCard from "@/components/base/BaseCard.vue";
import { eventBus } from "@/eventbus";
import SvgArrow from "@/assets/icons/arrow-down.svg";

@Component({
    components: { SvgArrow, BaseCard, AutocompleteListItem, TrashBtn, SvgTrash },
})

export default class ComboboxDesign extends Vue {
    @Prop({ required: true }) filter!: IFilter;

    search = '';
    notFoundIds: string[] = [];
    isShowModal: boolean = false;

    created(): void {
        eventBus.$on('get-input', this.getInput);
    }

    beforeDestroy(): void {
        eventBus.$off('get-input', this.getInput);
    }

    getInput(): void {
        if (!!this.search) {
            this.filter.select.push(this.search);
        }
    }

    get getModalText(): string {
        const ids = this.notFoundIds.join(', ');
        return `Не найдены: ${ids}`;
    }

    clearModal(): void {
        this.isShowModal = false;
        this.notFoundIds = [];
    }
    clearCombobox(filter: IFilter): void {
        Array.isArray(filter.select) ? filter.select = [] : filter.select = '';
    }

    remove(filter: any, item: any): void {
        if (Array.isArray(filter.select)) {
            const index = filter.select.findIndex(i => i === item);
            filter.select.splice(index, 1);
        } else {
            filter.select = '';
        }
    }

    multiPaste(): void {
        if (this.filter.multiPaste && !!this.search) {
            const arr = this.search.trim().split(/[ ,]+/);
            if (arr.length > 1) {
                arr.forEach(id => {
                    const item = this.filter.items!.find(i => i[this.filter.itemValue!] === Number(id));
                    if (item) {
                        if (!this.filter.select.includes(item[this.filter.itemValue!])) {
                            this.filter.select = [...this.filter.select, item[this.filter.itemValue!]];
                        }
                        this.search = this.search.replace(id, '').trim();
                    } else {
                        this.notFoundIds = this.notFoundIds.includes(id) ? this.notFoundIds : [...this.notFoundIds, id];
                    }
                });

                if (this.notFoundIds.length > 0) {
                    this.isShowModal = true;
                }
            }
        }
    }
}
