import { IColumnFilter } from "@/types";

export class CreatedColumns {
    columns: IColumnFilter[] = [];

    constructor() {
        this.columns = this.getColumns();
    }

    getColumns(): IColumnFilter[] {
        return [];
    }

    getFilteredColumns(blockedCols: string[]): IColumnFilter[] {
        return this.columns.map(group => ({
            ...group,
            items: group.items?.filter(i => !blockedCols.includes(i.value)),
        }));
    }
}
