import { MutationTree } from 'vuex';

import { IOffersState } from "@/store/modules/offers/types";

export const mutations: MutationTree<IOffersState> = {
    SET_VERTICALS(state, payload) {
        state.verticals = payload;
    },

    SET_PERIOD(state, payload) {
        state.statPeriodForList = payload;
    },

    SET_CURRENT_OFFER(state, payload) {
        state.current_offer = payload;
    },

    SET_SELECTS_ITEMS(state, payload) {
        state.selects_items = Object.assign(state.selects_items, payload);
    },

    SET_NEXT_UPDATE_SELECT_ITEMS(state, payload) {
        state.nextUpdateSelectItems = payload;
    },
};
