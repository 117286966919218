
import { Component, Vue, Prop } from 'vue-property-decorator';
import Search from '@/components/base/Search.vue';
import DateRange from '@/components/base/pickers/DateRange.vue';

@Component({
    components: { DateRange, Search },
})
export default class ListLayout extends Vue {
    @Prop({ default: 0 }) elevation!: number | string;
    @Prop({ required: true }) title!: string;
    @Prop({ default: '' }) subtitle!: string;
    @Prop({ default: '' }) titleClass!: string;
}
