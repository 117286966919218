import Users from "@/views/administration/users/List.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import UserDetailPage from "@/views/administration/users/Single.vue";
import EmployeeMethods from "@/views/administration/empoyeesMethods/List.vue";
import EmployeeMethod from "@/views/administration/empoyeesMethods/EmployeeMethodSingleWrap.vue";

export default [
    {
        path: 'users',
        name: 'users',
        component: Users,
        meta: {
            title: 'Пользователи',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'users/:id',
        name: 'user-detail-page',
        component: UserDetailPage,
        meta: {
            title: 'Детальная страница пользователя',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'user-creation',
        name: 'user-creation',
        component: Users,
        meta: {
            title: 'Пользователи',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
    },
    {
        path: 'employee-methods',
        name: 'employee-methods',
        meta: {
            title: 'Способы выплат',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
        component: EmployeeMethods,
    },
    {
        path: 'employee-method/:id',
        name: 'employee-method',
        props: true,
        meta: {
            title: 'Способ оплаты',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN],
        },
        component: EmployeeMethod,
    },
];
