
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { CreateGoal } from "@/services/offers/CreateGoal";
import CreateOfferFields from "@/components/offers/createOffer/createOfferFields.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import { CreateOffer, IOfferFiled } from "@/services/offers/CreateOffer";

@Component({
    components: { TrashBtn, CreateOfferFields },
})

export default class createOfferGoal extends Vue {
    @PropSync('goal', { required: true }) goalSync!: CreateGoal;
    @PropSync('offer', { required: true }) offerSync!: CreateOffer;
    @Prop({ required: true }) isDeletable!: boolean;
    @Prop({ required: true }) isDefaultGoal!: boolean;
    @Prop({ required: true }) goalIndex!: number;

    get getOptions(): IOfferFiled[] {
        return this.isDefaultGoal ? this.offerSync.getOptions.goal : this.goalSync.getOptions;
    }

    deleteGoal(): void {
        this.$emit('delete');
    }
}

