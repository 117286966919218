
import { Component, PropSync, Vue } from 'vue-property-decorator';
import NavDrawer from '@/components/layout/NavDrawer.vue';
import NavNotifications from '@/components/layout/NavNotifications.vue';
import NavUser from '@/components/layout/NavUser.vue';


@Component({
    components: {
        NavUser,
        NavNotifications,
        NavDrawer,
    },
})

export default class Navigation extends Vue {
    @PropSync('toggleMenu') toggleMenuSync!: boolean;

    menu = false;
}
