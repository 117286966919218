
import { Component, Mixins, Prop } from 'vue-property-decorator';
import AppTable from '@/components/base/table/AppTable.vue';
import Report from '@/mixins/report';
import LoadingList from '@/components/revenue/statTask/report/LoadingList.vue';
import { getFormatDate } from '@/utils/formatDates';
import Status from '@/components/base/Status.vue';

@Component({
    components: {
        AppTable,
        LoadingList,
        Status,
    },
})
export default class DuplicateTable extends Mixins(Report) {
    @Prop({ type: Boolean, default: true }) isExpand!: boolean;
    @Prop({ type: Array, required: true }) items!: [];
    @Prop({ type: Array, required: true }) headers!: [];

    getFormatDate = getFormatDate;
}
