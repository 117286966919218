
import { Component, PropSync, Vue } from "vue-property-decorator";
import FormModal from "@/components/base/FormModal.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { showNotification, showServerError } from "@/utils";
import { createUser } from "@/api/user";
import { getRoles } from "@/api/auth";
import SvgTrash from "@/assets/icons/trash.svg";

@Component({
    components: {
        FormModal,
        BaseSelect,
        SvgTrash,
    },
})
export default class FormCreateUser extends Vue {
    @PropSync('showModal') showModalSync!: boolean;
    user: any = { roles: [''] };
    userRoles: string[] = [];

    get getOptions(): any[] {
        const options = [
            {
                type: 'text',
                label: 'Имя',
                placeholder: 'Введите имя',
                editValue: this.user.first_name,
                updateUserData: (newValue) => this.user.first_name = newValue,
            },
            {
                type: 'text',
                label: 'Фамилия',
                placeholder: 'Введите фамилию',
                editValue: this.user.last_name,
                updateUserData: (newValue) => this.user.last_name = newValue,
            },
            {
                type: 'text',
                label: 'Email',
                placeholder: 'Введите email',
                editValue: this.user.email,
                updateUserData: (newValue) => this.user.email = newValue,
            },
        ];
        this.user.roles.forEach((role, index) => {
            if (role as any !== '' || index === 0 || this.user.roles[index - 1] as any !== '') {
                options.push({
                    type: 'select',
                    label: `Роль ${index + 1}`,
                    items: this.getUserRolesFromType,
                    editValue: role,
                    updateUserData: (selectedRole) => this.updateUserRoles(index, selectedRole),
                    delete: () => this.removeRole(index),
                } as any);
            }
        });
        return options;
    }

    closeModal(): void {
        this.showModalSync = false;
        this.resetFields();
    }

    addRole(): void {
        (this.user.roles as any).push('');
    }

    removeRole(index: number): void {
        this.user.roles.splice(index, 1);
        this.$set(this.user, 'roles', [...this.user.roles]);
    }

    resetFields(): void {
        const fieldsToReset = {
            first_name: '',
            last_name: '',
            email: '',
            account_type: 'system',
            roles: [''],
        };
        Object.keys(fieldsToReset).forEach(key => {
            this.$set(this.user, key, fieldsToReset[key]);
        });
    }

    get getUserRolesFromType(): string[] {
        return this.userRoles['system']?.ADDITIONAL.sort().map(i => {
            return { id: i, text: i };
        });
    }

    async created(): Promise<void> {
        try {
            this.userRoles = await getRoles();
            this.resetFields();
        } catch (err) {
            showServerError(err);
        }
    }

    get isDisabled(): boolean {
        const keysToCheck = ['first_name', 'last_name', 'email'];
        return keysToCheck.some(key => !this.user[key]);
    }

    async createUser(): Promise<void> {
        try {
            this.user.roles = this.user.roles.filter((item) => item as any !== '');
            await createUser({ ...this.user, ...{ type: 'system' } });
            showNotification('Пользователь успешно создан');
            this.closeModal();
        } catch (err) {
            showServerError(err);
            this.addRole();
        }
    }

    mounted(): void {
        this.checkModalHeight();
        window.addEventListener('resize', this.checkModalHeight);
    }

    updated(): void {
        this.checkModalHeight();
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.checkModalHeight);
    }

    checkModalHeight(): void {
        this.$nextTick(() => {
            setTimeout(() => {
                const modalCardComponent = this.$refs.modalCard as Vue;
                const modalCard = modalCardComponent?.$children[0]?.$children[0]?.$el as HTMLElement;
                const childElement = modalCard?.querySelector('.form-modal__v-card') as HTMLElement;
                if (childElement && childElement.style && (childElement.clientHeight > window.innerHeight * 0.75)) {
                    childElement.style.overflow = 'scroll';
                    childElement.style.maxHeight = '90vh';
                } else if (childElement && childElement.style) {
                    childElement.style.overflow = 'visible';
                    childElement.style.maxHeight = 'fit-content';
                }
            }, 500);
        });
    }

    updateUserRoles(index: number, data: any): void {

        this.$set(this.user.roles, index, data);
        this.addRole();
    }
}
