
import { Component, Vue } from 'vue-property-decorator';
import OfferPage from "@/components/offers/offer/OfferPage.vue";
import { namespace } from "vuex-class";
const offers = namespace('offersModule');

@Component({
    beforeRouteEnter(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        next(vm => {
            if (from.fullPath !== '/') {
                vm.beforeListRoute = from.fullPath;
            }
        });
    },
    beforeRouteLeave(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        this.setOffer(null);
        next();
    },
    components: { OfferPage },
})

export default class OffersSingle extends Vue {
    @offers.Mutation('SET_CURRENT_OFFER') setOffer;

    beforeListRoute = '/offers/all';

    get componentKey(): number {
        return Number(this.$route.params.id) || 0;
    }
}
