import { GetterTree } from 'vuex';

import { IRevenueState } from '@/store/modules/revenue/types';
import { IRootState } from '@/store/types';

export const getters: GetterTree<IRevenueState, IRootState> = {
    GET_ADVERTISER_FIELDS: (state) => state.advertiser,
    GET_ACCOUNT_MANAGER_NAME: (state) => state.accountManagerName,
    GET_ACCOUNTING_AMOUNT: (state) => state.accountingAmounts,
    GET_OFFER_REPORT: (state) => state.offerReport,
    GET_FILE_REPORT: (state) => state.fileReport,
    GET_GENERAL_REPORT_OFFERS: (state) => state.generalReportOffers,
    GET_CURRENT_ADVERTISER: (state) => state.currentAdvertiser,
};
