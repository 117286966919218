
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IInvoice } from '@/api/types/revenue';

@Component({})

export default class SaveBtnTooltipInvoice extends Vue {
    @Prop({ required: true }) readonly invoice!: IInvoice;
    @Prop({ required: true }) emptyConditions!: object;
    @Prop({ required: true }) invoiceEmptyConditions!: object;

    get invoiceEmptyConditionsText(): object {
        return {
            isEmptyProject: 'Необходимо выбрать проект',
            isEmptyContractor: 'Необходимо выбрать контрагента',
            isEmptyAccountingServices: 'В заказ необходимо добавить услуги',
            isEmptyAccountingOfferServices: 'В заказе в офферах не указаны ставка или количество',
            isEmptyAccountingOtherServices: 'В заказе в прочих услугах не указана сумма',
        };
    }

    get emptyConditionsText(): object {
        return {
            isEmptyServices: 'В счет необходимо добавить услуги',
            isEmptyRateQty: 'В услугах по офферам не указаны ставка или количество',
            isEmptyCurrency: 'Необходимо выбрать валюту',
            isEmptySomeOtherServices: 'В прочих услугах не указана сумма',
            isEmptyStatTaskFiles: 'К загрузке не прикреплен файл',
            isErrorOfNetting: 'Сумма взаимозачёта превышает сумму счёта рекламодателя',
            isClearServicesCustomerOrder: 'Cчёт рекламодателя полностью закрывается взаимозачётом, из заказа покупателя нужно удалить все услуги',
        };
    }
}
