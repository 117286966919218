import { CreatedFilters } from "@/services/filters/filters";
import { IColumnFilter, IFilter } from "@/types";
import { CreatedColumns } from "@/services/columns/columns";
import { getUserSearch } from "@/api/user";
import { getEmployeesOffersV2 } from "@/api/offers";
import {
    translateStatisticsStatus,
} from "@/utils/translate";
import { filterClass } from "@/services/filters/filterClass";
import { getAdvertisersSearch } from "@/api/revenue";
import { IOfferSearch } from "@/api/types/offers";
import store from "@/store";

export class StatTasksColumns extends CreatedColumns {
    constructor() {
        super();
    }
    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'ID', value: 'id' },
                    { title: 'Рекламодатель', value: 'advertiser.name' },
                    { title: 'Период', value: 'period' },
                    { title: 'Создатель', value: 'created_by.full_name' },
                    { title: 'Счёт', value: 'invoice_id' },
                    { title: 'Статус статистики', value: 'status' },
                    { title: 'Исполнитель', value: 'assignee_to' },
                    { title: 'Действия', value: 'actions' },
                ],
            },
        ];
    }

    get getColumnsForAdvertiserDetailPage(): IColumnFilter[] {
        const blockedCols = ['advertiser.name'];
        return this.getFilteredColumns(blockedCols);
    }
}

export class StatTasksFilters extends CreatedFilters {

    constructor() {
        super();
    }


    getTemplate(item: any): string {
        return item.name || item.code || item.master.id;
    }

    clearFilters(): void {
        super.clearFilters();
    }

    getFilters(): IFilter[] {
        const advertiser = store.getters['revenueModule/GET_CURRENT_ADVERTISER'];
        const isAdvertiser = advertiser && this.checkRoute('advertiser');
        return [
            new filterClass.Input({
                id: 'id',
                label: 'ID загрузки',
                type: 'number',
                clearable: false,
            }),
            new filterClass.Select({
                id: 'status',
                label: 'Статус статистики',
                items: ['pending', 'in_progress', 'done'],
                translate: (status) => this.translateStatus(status, translateStatisticsStatus),
            }),
            new filterClass.Autocomplete({
                id: 'advertiser_id',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                select: isAdvertiser ? [advertiser] : [],
                defaultValue: isAdvertiser ? [advertiser] : [],
                items: [] as Array<{id: number, name: string}>,
                multiple: true,
                isHidden: isAdvertiser,
            }),
            new filterClass.Autocomplete({
                id: 'offers',
                apiMethod: getEmployeesOffersV2,
                label: 'ID оффера',
                key: 'q',
                itemValue: 'legacy_id',
                template: (item: IOfferSearch): string => item.legacy_id + ' ' + item.name,
                select: [],
                items: [],
                multiple: true,
            }),
            new filterClass.Autocomplete({
                id: 'created_by',
                apiMethod: getUserSearch,
                label: 'ID создателя',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
            }),
            new filterClass.Autocomplete({
                id: 'assignee_to',
                apiMethod: getUserSearch,
                label: 'ID исполнителя',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
            }),
            new filterClass.Datepicker({
                id: 'period',
                label: 'Период',
                pickerType: 'month',
                isMonthPicker: true,
                range: true,
                select: [],
            }),
            new filterClass.Checkbox({
                id: 'show_deleted',
                label: 'Показать удалённые',
                select: false,
            }),
        ];
    }
}
