
import { Component, Vue, Prop } from 'vue-property-decorator';
import FormModal from '@/components/base/FormModal.vue';
import PaginationRight from '@/components/base/table/PaginationRight.vue';
import Status from '@/components/base/Status.vue';
import { ILeadgidInvoice, IMasterAccountAffiliate } from '@/api/types/payout';
import { translateMasterAccountStatus } from '@/utils/translate';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { PAGINATION_RIGHT_PER_PAGE } from '@/configs/global';

@Component({
    components: {
        Status,
        FormModal,
        PaginationRight,
    },
})

export default class AccountTable extends Vue {
    @Prop({ required: true }) readonly accounts!: IMasterAccountAffiliate[] | ILeadgidInvoice[] ;
    @Prop({ default: () => [] }) readonly headers!: [];

    // for pagination
    pageCount = 0;
    itemsPerPage = PAGINATION_RIGHT_PER_PAGE;
    itemsPerPageSelect: any[] = [
        {
            value: PAGINATION_RIGHT_PER_PAGE,
            text: PAGINATION_RIGHT_PER_PAGE.toString(),
        },
        {
            value: 20,
            text: '20',
        },
        {
            value: 100,
            text: 'Все',
        },
    ];

    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };

    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    translateMasterAccountStatus = translateMasterAccountStatus;

    // изменить число строк в таблице
    changeItems(evt: string): void {
        this.itemsPerPage = parseInt(evt, 10);
    }
}
