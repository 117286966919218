
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

@Component({})
export default class DateRangeOldWrapper extends Vue {
    @PropSync('filter', { required: true }) filterSync!: string[];
    @Prop({ type: String, default: '' }) readonly attachClass!: string;
    @Prop({ type: Boolean, default: false }) readonly clearable!: boolean;
    @Prop({ type: String, default: '' }) readonly label!: string;
    @Prop({ type: String, default: '' }) readonly pickerType!: 'date' | 'month';
    @Prop({ type: Boolean, default: false }) readonly isRange!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isErrorIcon!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isOnlyPlaceholder!: boolean;
    @Prop({
        type: Function,
        default: () => ({ start: null, end: null }),
    }) readonly availableDates!: Function;

    menu: boolean = false;
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;

    duplicateValue(): void {
        if (this.isRange && this.filterSync.length === 1) {
            this.filterSync.push(this.filterSync[0]);
        }
    }

    clearDate(): void {
        this.filterSync = [];
        this.$emit('update', null);
    }
}
