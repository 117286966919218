
import { Component, Mixins } from 'vue-property-decorator';
import ConditionContent from '@/components/uapi/conditions/ConditionContent.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import ConditionGeo from '@/components/uapi/conditions/ConditionGeo.vue';
import { addEndpointCondition, editEndpointCondition, getEndpointCondition } from '@/api/uapi';
import { showServerError } from '@/utils';
import StreamCondition from "@/mixins/streamCondition";

@Component({
    components: {
        ConditionContent,
        ConditionGeo,
        StatsCard,
        TooltipButton,
    },
})
export default class ConditionEndpoint extends Mixins(StreamCondition) {

    async getCondition(): Promise<void> {
        const { endpointId, conditionId } = this.$route.params;
        try {
            const { data } = await getEndpointCondition(endpointId, conditionId);
            this.condition = data;
        } catch (err) {
            showServerError(err, 'Не удалось получить кампанию по ID');
        }
    }

    async saveCondition(): Promise<void> {
        const streamCondition: any = this.getConditionsToSend();
        const { endpointId } = this.$route.params;
        try {
            if (this.condition!.id) {
                await editEndpointCondition(endpointId, this.condition!.id, streamCondition!);
            } else {
                const { data } = await addEndpointCondition(endpointId, streamCondition!);
                this.condition = data;
            }
            await this.goToEndpointPage();
        } catch (err) {
            showServerError(err);
        }
    }

    goToEndpointPage(): void {
        this.$router.push({ name: 'edit-endpoint', params: { endpointId: String(this.$route.params.endpointId) } });
    }
}
