
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class NotificationMessage extends Vue {
    @Prop({ required: true }) message!: string;
    @Prop({ required: true }) lang!: string;
}
