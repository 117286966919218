
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { MULTISELECT_TYPE } from '@/mappings/multiSelectDataType';
import Status from "@/components/base/Status.vue";

@Component({
    components: { Status },
})

export default class Multiselect extends Vue {
    get allItemsSelected(): boolean | void {
        if (this.$props.multiselectData.items) {
            return this.selectedItems.length === this.$props.multiselectData.items.length;
        }
    }

    get isShowPrependIcon(): boolean {
        return !this.$props.multiselectData.isNoToggle && this.$props.multiselectData.items?.length > 1;
    }

    get someItemsSelected(): boolean {
        return this.selectedItems.length > 0 && !this.allItemsSelected;
    }

    get iconForSelect(): string {
        if (this.allItemsSelected) {
            return 'mdi-close-box';
        }
        if (this.someItemsSelected) {
            return 'mdi-minus-box';
        }
        return 'mdi-checkbox-blank-outline';
    }

    @Prop({ required: true }) multiselectData!: {};
    @Prop({ default: false }) outlined!: boolean;

    selectedItems: Array<{[key: string]: any}> | string[] | number[] = [];

    /*
     * Чтобы при сбросе фильтров в родителе,
     * selectedItems тоже очищался до дефолтных значений
     */
    @Watch('multiselectData.value')
    clearSelectedItems(value: Array<{[key: string]: any}> | string[] | number[]): void {
        this.selectedItems = value;
    }

    remove(item: any): void {
        if (!this.$props.multiselectData.noNeedModified) {
            item = this.$props.multiselectData.itemValue ? item[this.$props.multiselectData.itemValue] : item;
            this.selectedItems = this.modifyItems(this.selectedItems);
        }
        if (typeof this.selectedItems[0] === typeof item) {
            const index = (this.selectedItems as any).indexOf(item);
            this.selectedItems.splice(index, 1);
            this.update(this.selectedItems);
        }
    }

    created(): void {
        this.selectedItems = this.$props.multiselectData.value
            ? this.$props.multiselectData.value
            : this.selectedItems;
    }

    toggleAllItemsSelected(): void {
        this.$nextTick(() => {
            if (this.allItemsSelected) {
                this.selectedItems = [];
            } else {
                this.selectedItems = this.$props.multiselectData.items.slice();
            }
            if (this.$props.multiselectData.type === 'StatTaskedFileItem') {
                this.$emit('change-multiselect-items', this.selectedItems);
            } else {
                this.update(this.selectedItems);
            }
        });
    }

    async update(items: any[]): Promise<void> {
        if (this.$props.multiselectData.noNeedModified) {
            this.$emit('change-multiselect-items', items);
            return;
        }
        this.$emit(
            'change-multiselect-items',
            {
                type: this.$props.multiselectData.type,
                items: (this.$props.multiselectData.type !== MULTISELECT_TYPE.SERVICES
                    && this.$props.multiselectData.type !== MULTISELECT_TYPE.OTHER_SERVICES)
                    ? this.modifyItems(items) : items,
            },
        );
    }

    private modifyItems(items) {
        const modifiedItems: string[] = [];
        items.map((item) => {
            if (typeof item === 'object') {
                modifiedItems.push(item[this.$props.multiselectData.itemValue]);
            } else {
                modifiedItems.push(item);
            }
        });
        return modifiedItems;
    }
}
