const cookiesSelectItems = [
    { text: '1 день', id: 24 },
    { text: '1 неделя', id: 168 },
    { text: '2 недели', id: 336 },
    { text: '1 месяц', id: 672 },
    { text: '2 месяца', id: 1344 },
    { text: '3 месяца', id: 2016 },
    { text: '6 месяцев', id: 4032 },
    { text: '1 год', id: 8064 },
    { text: 'Пользовательский', id: 0 },
];
export default cookiesSelectItems;
