
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import { translateOffersStatus } from "@/utils/translate";
import { IOffer, IOfferSearch, IRedirectToOffer } from "@/api/types/offers";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getEmployeesOffersV2 } from "@/api/offers";
import { eventBus } from "@/eventbus";
import { ITableStatus } from "@/types";
@Component({
    methods: { getEmployeesOffersV2 },
    components: { BaseAutocomplete, TableWithFrontPaginationDesign, BaseCard },
})

export default class OfferRedirects extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    redirectOffer: IOfferSearch | null = null;
    editMode = false;
    headers = [
        { text: 'ID оффера', value: 'legacy_id', align: 'center' },
        { text: 'Название', value: 'name' },
        { text: 'Рекламодатель', sortable: false, value: 'advertiser_name' },
        { text: 'Менеджер', sortable: false, value: 'manager_name' },
        { text: 'Статус', sortable: false, value: 'status' },
        { text: '', sortable: false, value: 'actions', align: 'center' },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            callback: (item: IRedirectToOffer): void => {
                this.deleteRedirect(item.id);
            },
        },
    ];

    async deleteRedirect(id: number): Promise<void> {
        await this.offer.deleteRedirectToOffer(id);
    }

    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.legacy_id',
            routerName: 'offer',
            text: 'legacy_id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.name',
            routerName: 'offer',
            text: 'name',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'advertiser_id',
            slot: 'item.advertiser_name',
            routerName: 'advertiser',
            text: 'advertiser_name',
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateOffersStatus,
        },
    ];

    get isOfferEmpty(): boolean {
        return Object.keys(this.offer)?.length <= 0;
    }

    get defaultSearchRedirectOffer(): string {
        return this.offer.redirect_offer_id ? String(this.offer.redirect_offer_id) : '';
    }

    getRedirectOfferStatus(item: IOffer): string {
        return item.status ? ' (' + translateOffersStatus(item.status).text + ')' : '';
    }

    getRedirectOffer(item: IOfferSearch): void {
        this.redirectOffer = item ? { ...item } : null;
    }

    async patchRedirectOffer(): Promise<void> {
        if (this.redirectOffer?.id === this.offer.redirectOffer?.id) return;
        const isUpdateRedirect = await this.offer.setRedirect(this.redirectOffer?.id!);
        if (isUpdateRedirect) eventBus.$emit("update-offer");
    }

    get templateOffer(): string {
        return this.offer.fullNameRedirectOffer;
    }

    editInfo(edit: boolean): void {
        if(!edit) {
            this.patchRedirectOffer();
        }
        this.editMode = edit;
    }

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(newVal: any, oldVal: any): void {
        if (oldVal && newVal.name === 'offer' && oldVal.name === 'offer' && newVal.params.id !== oldVal.params.id) {
            eventBus.$emit("update-offer");
            eventBus.$emit("change-tab", 0, true);
        }
    }
}

