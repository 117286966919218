
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LogoutMixin from '@/mixins/logout';
import UserSettings from '@/components/layout/UserSettings.vue';

// Создаём константы для сторов разных модулей
const auth = namespace('authModule');

@Component({
    components: { UserSettings },
})
export default class PopupUser extends Mixins(LogoutMixin) {
    @auth.Getter('GET_USER') userCRM;
    @auth.Getter('IS_LOGGED_IN') isLoggedIn;

    logoutUser(): void {
        this.$socket.client.disconnect();
        this.logout();
    }
}
