import { USER_ROLES } from "@/mappings/user-roles";
import Ticket from "@/views/support/Ticket.vue";
import Surveys from "@/views/support/surveys/List.vue";
import CreateSurvey from "@/views/support/surveys/CreateSurvey.vue";
import SingleSurvey from "@/views/support/surveys/Single.vue";
import Tickets from "@/views/support/Tickets.vue";

export default  [
    {
        path: 'tickets',
        name: 'tickets-list',
        component: Tickets,
        meta: {
            title: 'Поддержка',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.SUPPORT],
        },
    },
    {
        path: 'ticket/:id',
        component: Ticket,
        name: 'ticket',
        meta: {
            title: 'Тикет',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.SUPPORT],
        },
    },
    {
        path: 'surveys',
        name: 'surveys',
        component: Surveys,
        meta: {
            title: 'Опросы',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.SUPPORT, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.AFFILIATE_MANAGER],
        },
    },
    {
        path: 'create-survey',
        component: CreateSurvey,
        name: 'create-survey',
        meta: {
            title: 'Создание опроса',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.SUPPORT, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.AFFILIATE_MANAGER],
        },
    },
    {
        path: 'surveys/:id',
        component: SingleSurvey,
        name: 'survey',
        meta: {
            title: 'Детальная страница опроса',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.SUPPORT, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.AFFILIATE_MANAGER],
        },
    },
];
