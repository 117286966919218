
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import PaymentLogo from "@/components/affiliate/employee/PaymentLogo.vue";
import { EmployeeMethod } from "@/services/administration/EmployeeMethod";

@Component({
    components: { PaymentLogo, BaseCard },
})
export default class EmployeeMethodLogos extends Vue {
    @Prop({ required: true }) employeeMethod!: EmployeeMethod;
    @Inject() readonly getEmployeeMethod!: any;
    isEdit: boolean = false;

    editMode(isEdit: boolean): void {
        if (!isEdit) {
            this.getEmployeeMethod();
        }
        this.isEdit = isEdit;
    }

}
