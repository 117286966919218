
import {
    Component, Vue, Prop, Inject,
} from 'vue-property-decorator';
import Status from '@/components/base/Status.vue';
import Multiselect from '@/components/base/form/Multiselect.vue';
import { IFinanceInvoiceSingle } from '@/api/types/org-cabinets-backend';
import { getCurrencyIcon } from '@/utils/translate';

@Component({
    components: {
        Status,
        Multiselect,
    },
})

export default class FinanceInvoiceHeader extends Vue {
    @Prop({ required: true }) invoice!: IFinanceInvoiceSingle;

    @Inject() readonly masterAccountID!: any;

    formatPrice(item: number): string {
        const price = item
            ? item.toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
            : '0,00';
        return `${price} ${getCurrencyIcon(this.$props.invoice.currency)}`;
    }
}
