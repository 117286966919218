
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';
import { getCurrentDate } from '@/utils/formatDates';
import { IConversionOffer } from '@/api/types/uapi';
import { getStatsConversions } from '@/api/uapi';
import Filters from '@/components/base/filters/Filters.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { showServerError } from '@/utils';
import TableMixin from '@/mixins/table';
import { sortBy } from 'lodash-es';
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        Filters,
        SvgApplication,
    },
})
export default class OffersReport extends Mixins(TableMixin) {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamObject;

    currentDate = getCurrentDate('yyyy-MM-dd');
    sortByItem: string | null = null;
    sortDesc = false;
    items: any = [];

    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'affiliate_id',
            label: 'ID партнера',
            select: [],
            type: 'number',
        }),
        new filterClass.ComboboxArray({
            id: 'offer_id',
            label: 'ID оффера',
            select: [],
            type: 'string',
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Период',
            pickerType: 'date',
            range: true,
            select: [this.currentDate],
            defaultValue: [this.currentDate],
        }),
    ];
    headers = [
        { text: 'Оффер', value: 'offer_id', align: 'center' },
        { text: 'Название оффера', value: 'offer_name' },
        { text: 'Партнер', value: 'affiliate_id', align: 'center' },
        { text: 'Всего заявок', value: 'total', align: 'center' },
        { text: 'Не подошло', value: 'not_satisfied' },
        { text: 'Оффер: Принято', value: 'accepted', align: 'center' },
        { text: 'Рекл: Принято', value: 'adv_accepted', align: 'center' },
        { text: 'Рекл: Отклонено', value: 'adv_rejected', align: 'center' },
        { text: 'LeadGid: Принято', value: 'ldg_accepted', align: 'center' },
        { text: 'LeadGid: На рассмотрении ', value: 'ldg_pending', align: 'center' },
        { text: 'LeadGid: Отклонено', value: 'ldg_rejected', align: 'center' },
    ];
    filteredHeaders: any[] = [];

    routes = [
        {
            slot: 'item.offer_id',
            getTemplate: (item: IConversionOffer): string | number => item.offer_id,
            getColor: (): string => 'grey',
            isDisabled: (): boolean => false,
            routerName: 'offers-report',
            getQuery: (item: IConversionOffer): any => {
                if (item) {
                    return { offer_id: [String(item.offer_id)] };
                }
                return {};
            },
            click: (item: IConversionOffer): void => {
                const offerFilter = this.filters.find((el) => el.id === 'offer_id');
                if (offerFilter) {
                    this.setFiltersParamsObject({ ...this.getDefaultFiltersParamsObject, ...this.getFiltersParamsObject,
                        offer_id: [item.offer_id.toString()] });
                    offerFilter.select = [item.offer_id.toString()];
                    this.getDataTable();
                }
            },
        },
        {
            slot: 'item.total',
            getTemplate: (item: IConversionOffer): number => item.total,
            getColor: (): string => 'grey',
            isDisabled: (): boolean => false,
            routerName: 'applications',
            getQuery: (item: IConversionOffer): any => {
                if (item) {
                    const offerFilter = this.filters.find((el) => el.id === 'offer_id');
                    if (offerFilter) {
                        const affiliateFilter = this.filters.find((el) => el.id === 'affiliate_id');
                        if (affiliateFilter) {
                            return { offer_id: offerFilter.select,
                                affiliate_id: affiliateFilter.select };
                        }
                        return { offer_id: offerFilter.select };
                    }
                }
                return {};
            },
        },
        {
            slot: 'item.accepted',
            getTemplate: (item: IConversionOffer): number => item.accepted,
            getColor: (): string => 'grey',
            isDisabled: (item: IConversionOffer): boolean =>  item.accepted === 0,
            routerName: 'conversions',
            getQuery: (item: IConversionOffer): any => {
                if (item) {
                    const offerFilter = this.filters.find((el) => el.id === 'offer_id');
                    if (offerFilter) {
                        const affiliateFilter = this.filters.find((el) => el.id === 'affiliate_id');
                        if (affiliateFilter) {
                            return { offer_id: offerFilter.select,
                                affiliate_id: affiliateFilter.select };
                        }
                        return { offer_id: offerFilter.select };
                    }
                }
                return {};
            },
        },
        {
            slot: 'item.adv_accepted',
            getTemplate: (item: IConversionOffer): string => `${item.adv_accepted} (${Math.round(item.adv_accepted_pct)}%)`,
            getColor: (item: IConversionOffer): string => this.getColorError(item, 'adv_accepted_pct'),
            isDisabled: (item: IConversionOffer): boolean => item.adv_accepted === 0,
            routerName: 'conversions',
            getQuery: (item: IConversionOffer): any => {
                if (item) {
                    const offerFilter = this.filters.find((el) => el.id === 'offer_id');
                    if (offerFilter) {
                        const affiliateFilter = this.filters.find((el) => el.id === 'affiliate_id');
                        if (affiliateFilter) {
                            return { offer_id: offerFilter.select,
                                affiliate_id: affiliateFilter.select,
                                status: 'accepted' };
                        }
                        return { offer_id: offerFilter.select,
                            status: 'accepted' };
                    }
                }
                return { status: 'accepted' };
            },
        },
        {
            slot: 'item.adv_rejected',
            getTemplate: (item: IConversionOffer): string => `${item.adv_rejected} (${Math.round(item.adv_rejected_pct)}%)`,
            getColor: (item: IConversionOffer): string => this.getColorError(item, 'adv_rejected_pct'),
            isDisabled: (item: IConversionOffer): boolean => item.adv_rejected === 0,
            routerName: 'conversions',
            getQuery: (item: IConversionOffer): any => {
                if (item) {
                    const offerFilter = this.filters.find((el) => el.id === 'offer_id');
                    if (offerFilter) {
                        const affiliateFilter = this.filters.find((el) => el.id === 'affiliate_id');
                        if (affiliateFilter) {
                            return { offer_id: offerFilter.select,
                                affiliate_id: affiliateFilter.select,
                                status: 'rejected' };
                        }
                        return { offer_id: offerFilter.select,
                            status: 'rejected' };
                    }
                }
                return { status: 'rejected' };
            },
        },
    ];

    uniqueFields: Record<'slot' | 'key' | 'pctKey', string>[] = [
        {
            slot: 'item.ldg_accepted',
            key: 'ldg_accepted',
            pctKey: 'ldg_accepted_pct',
        },
        {
            slot: 'item.ldg_rejected',
            key: 'ldg_rejected',
            pctKey: 'ldg_rejected_pct',
        },
    ];

    settings = {
        title: 'Отчет по офферам',
        loading: false,
    };
    apiMethod = getStatsConversions;

    created(): void {
        const activeFilters = {};
        this.filters.forEach((fl) => {
            if (fl.select) {
                if (fl.array) {
                    if (fl.select.length) {
                        activeFilters[fl.id] = fl.select;
                    }
                } else if (fl.datepicker) {
                    activeFilters[`${fl.id}_from`] = fl.select[0];
                    activeFilters[`${fl.id}_to`] = fl.select[0];
                } else {
                    activeFilters[fl.id] = fl.select;
                }
            }
        });

        const paramsObj = JSON.parse(JSON.stringify({ ...activeFilters }));
        this.setDefaultFiltersParamObject(paramsObj);
    }

    mounted(): void {
        this.getDataTable();
    }

    getColorClass(item: IConversionOffer, { pctKey }: {pctKey: string}): string {
        const color = this.getColorError(item, pctKey);
        return `${color}--text`;
    }

    getTemplate(item: IConversionOffer, { key, pctKey }: Record<'key' | 'pctKey', string>): string {
        return `${item[key]} (${Math.round(item[pctKey])}%)`;
    }

    async getDataTable(offset?: number): Promise<void> {
        this.settings.loading = true;
        try {
            await this.getTableData(offset);
            if (!this.dataTable.find((item) => !!item.affiliate_id)) {
                this.filteredHeaders = this.headers.filter((header) => header.value !== 'affiliate_id');
            } else {
                this.filteredHeaders = this.headers;
            }
            this.items = this.dataTable;
        } catch (err) {
            showServerError(err);
        }
        this.settings.loading = false;
    }

    getColorError(item: IConversionOffer, value: string): string {
        const status = value.includes('accepted') ? 'accepted' : 'rejected';
        if (item[value] === 0) {
            return 'grey';
        }
        if (status === 'accepted' && item[value] <= 50) {
            return 'green';
        } else if (status === 'rejected' && item[value] >= 50) {
            return 'red';
        }
        return 'grey';
    }

    @Watch('sortDesc')
    updateSortDesc(): void {
        this.items = this.items.reverse();
    }

    @Watch('sortByItem')
    updateSortBy(sortByItem: string): void {
        this.items = sortBy(this.dataTable, sortByItem);
    }

    clearFilters(): void {
        // @ts-ignore
        this.getDataTable(0, this.limit);
    }
}
