
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getApplicationAnalytics } from '@/api/uapi';
import { IApplicationAnalytics } from '@/api/types/uapi';
import { showServerError } from '@/utils';
import Trace from '@/components/uapi/Trace.vue';
import Status from '@/components/base/Status.vue';

@Component({
    components: {
        Status,
        Trace,
    },
})
export default class ApplicationAnalytics extends Vue {
    @Prop({ type: String, default: '' }) uuid!: string;

    singleExpand = false;
    expanded = [];
    headers = [
        { text: '', value: 'data-table-expand' },
        { text: 'Поток', value: 'stream' },
        { text: 'Статус', value: 'status' },
        { text: 'Дата создания', value: 'created_at' },
    ];

    stagesHeaders = [
        { text: 'ID', value: 'id' },
        { text: 'Время', value: 'status_time' },
        { text: 'Статус', value: 'status' },
        { text: 'ID оффера', value: 'offer_id' },
        { text: 'Название оффера', value: 'offer_name' },
        { text: 'Информация', value: 'info' },
        { text: 'Конверсия', value: 'conversion' },
    ];

    analytics: IApplicationAnalytics = {};

    created(): void {
        this.getApplicationAnalytics();
    }

    async getApplicationAnalytics(): Promise<void> {
        try {
            const { data } = await getApplicationAnalytics(this.$props.uuid);
            this.analytics = data;
        } catch (err) {
            showServerError(err);
        }
    }
}
