import { GetterTree } from 'vuex';
import { IAuthState } from '@/store/modules/auth/types';
import { IRootState } from '@/store/types';
import { USER_ROLES } from '@/mappings/user-roles';
import { getCookie } from '@/utils/cookie';

export const isAuthenticated = (): boolean => getCookie('authenticated') === 'true';

export const getters: GetterTree<IAuthState, IRootState> = {
    IS_LOGGED_IN: () => getCookie('authenticated') === 'true',
    GET_USER: (state) => state.user,
    GET_ROLE: (state) => {
        if (Object.keys(state.user).length > 0) {
            if (state.user.roles.find((role) => role === USER_ROLES.SUPERVISOR)) {
                return USER_ROLES.SUPERVISOR;
            }
            if (state.user.roles.find((role) => role === USER_ROLES.ACCOUNT_MANAGER)) {
                return USER_ROLES.ACCOUNT_MANAGER;
            }
            if (state.user.roles.find((role) => role === USER_ROLES.STAT_MANAGER)) {
                return USER_ROLES.STAT_MANAGER;
            }
            if (state.user.roles.find((role) => role === USER_ROLES.ADMIN)) {
                return USER_ROLES.ADMIN;
            }
            return null;
        }
    },
    GET_TWO_FACTORS_STATUS: (state) => state.is_2fa_enabled,
    GET_LOGIN_AS_PARTNER: (state) => state.is_login_as_partner,
};
