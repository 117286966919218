
import { Component, Vue } from 'vue-property-decorator';
import SvgOfferList from '@/assets/icons/nav-bar/offer-list.svg';
import { IColumnFilter } from '@/types';
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { InvoicesAdvertisersColumns, InvoicesAdvertisersFilters } from "@/services/TablePage/invoicesAdvertisers";
import { getAllInvoices } from "@/api/revenue";
import InvoicesTable from "@/components/revenue/invoice/InvoicesTable.vue";

@Component({
    components: {
        TablePageWrapper,
        SvgOfferList,
    },
})
export default class Invoices extends Vue {
    columns: IColumnFilter[] = new InvoicesAdvertisersColumns().columns;
    filters: InvoicesAdvertisersFilters = new InvoicesAdvertisersFilters();

    invoicesTable = {
        component: InvoicesTable,
        apiMethod: getAllInvoices,
    };

    createForm = {
        route: 'invoice-creation',
    };
}
