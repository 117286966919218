
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Constructor extends Vue {
    @PropSync('search', { required: true }) searchSync!: string;
    @Prop( { default: '' }) activatorClass!: string;
    @Prop({ default: '687' }) nextRowOn!: string | number;

    isShowSearch = false;

    clearSearch(): void {
        if (!this.searchSync) this.isShowSearch = false;
    }

    get isSmallMobile(): boolean {
        return this.$vuetify.breakpoint.width <= +this.nextRowOn;
    }

    mounted(): void {
        this.$nextTick(() => {
            if (this.searchSync) this.isShowSearch = true;
        });
    }
}
