
import { Component, Vue, Prop } from 'vue-property-decorator';
import Pagination from '@/components/base/table/Pagination.vue';
import { IOfferLimit } from '@/api/types/uapi';

@Component({
    components: {
        Pagination,
    },
})
export default class PlannedLimits extends Vue {
    @Prop({ type: Array, default: () => ([]) }) limits!: [];
    @Prop({ type: Array, default: () => ([]) }) names!: [];

    headers = [
        { text: 'Дата начала', value: 'start_time', class: 'font-weight-regular' },
        { text: 'Дата окончания', value: 'end_time', class: 'font-weight-regular' },
        { text: 'Создатель', value: 'created_by', class: 'font-weight-regular' },
        { text: 'Дата создания', value: 'created_at', class: 'font-weight-regular' },
        { text: 'Общий лимит', value: 'limit_data.total', class: 'font-weight-regular' },
        { text: 'Новые лимиты', value: 'data-table-expand', class: 'font-weight-regular' },
    ];

    limit = 25;
    pagination = { page: 1 };

    getTemplateCreatedBy(item: IOfferLimit): string | number {
        const createdBy = this.$props.names.find(({ account_id }) => account_id === item.created_by);
        if (createdBy) {
            return `${createdBy.account_id} ${createdBy.first_name} ${createdBy.last_name}`;
        }
        return item.created_by!;
    }

    getPaginationData(offset: number, limit: number, isChangeLimit?: boolean): void {
        if (offset === 0 || isChangeLimit) {
            this.pagination.page = 1;
        }
        this.limit = limit;
    }
}
