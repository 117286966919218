
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BaseTooltip extends Vue {
    @Prop({ default: 'top' }) position!: 'top' | 'bottom' | 'left' | 'right';
    @Prop({ default: '0' }) minWidth!: string;
    @Prop({ default: false }) isClickToShow!: boolean;

    positionStyle: string = '';
    isShowTooltip: boolean = false;

    mounted(): void {
        this.setPosition();
    }

    get getStyle(): string {
        const visibility = `visibility: ${this.isShowTooltip ? 'visible' : 'hidden'};`;
        const minWidth = `min-width: ${this.minWidth}${isNaN(Number(this.minWidth)) ? '' : 'px'};`;
        return `${this.positionStyle} ${visibility} ${minWidth}`;
    }

    setPosition(): void {
        const tooltip = this.$el.querySelector('.main-tooltip__tooltip');
        const activator = this.$el.querySelector('.main-tooltip__activator')?.firstElementChild;
        if (!tooltip || !activator) {
            this.positionStyle = '';
            return;
        }

        const width = tooltip.clientWidth;
        const height = tooltip.clientHeight;

        if (['top', 'bottom'].includes(this.position)) {
            const activatorWidth = activator.clientWidth;
            this.positionStyle = `${this.position}: -${height + 3}px; left: ${(activatorWidth - width) / 2}px;`;
        } else {
            const activatorHeight = activator.clientHeight;
            this.positionStyle = `${this.position}: -${width + 3}px; top: ${(activatorHeight - height) / 2}px;`;
        }
    }

    enterOnActivator(): void {
        if (this.isClickToShow) return;
        this.isShowTooltip = true;
    }

    leaveOnActivator(): void {
        if (this.isClickToShow) return;
        this.isShowTooltip = false;
    }

    openTooltip(): void {
        if (!this.isClickToShow) return;
        // setTimeout(() => this.isShowTooltip = true, 100);
        this.isShowTooltip = true;
    }
    closeTooltip(): void {
        if (!this.isClickToShow) return;
        this.isShowTooltip = false;
    }
}
