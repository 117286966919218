
import { Vue, Component } from "vue-property-decorator";
import AdvertisersTable from "@/components/revenue/AdvertisersTable.vue";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { AdvertisersFilters } from "@/services/TablePage/advertisers";
import { getAllAdvertisers } from "@/api/revenue";


@Component({
    components: { TablePageWrapper },
})

export default class AdvertisersList extends Vue {

    filters: AdvertisersFilters = new AdvertisersFilters();
    table = {
        component: AdvertisersTable,
        apiMethod: getAllAdvertisers,
    };
    loading = false;
    createForm = {
        route: 'create-advertiser',
    };
}

