
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgPinFull from '@/assets/icons/pin-full.svg';
import Status from "@/components/base/Status.vue";
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import { IEvents } from "@/api/types/notifications";
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        TrashBtn,
        BaseAutocomplete,
        DateTimePickerNew,
        SelectDesign,
        Status,
        SvgPinFull,
    },
})

export default class NotificationCreateHeader extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;
    @Prop({ default: false }) isEditing!: boolean;

    get filters(): IFilter[] {
        return [
            new filterClass.Select({
                id: 'category',
                label: 'Категория',
                itemValue: 'value',
                itemText: 'name',
                items: this.notification.categories.map(i => i.category),
                select: this.notification.category,
                callback: this.changeCategory,
                disabled: this.isEditing,
                clearable: true,
                remove: () => this.notification.category = '',
                class: 'mr-1 ',
            }),
            new filterClass.Select({
                id: 'event',
                label: 'Подкатегория',
                itemValue: 'value',
                itemText: 'name',
                items: this.possibleEvents,
                select: this.notification.event,
                callback: ({ select }) => this.notification.event = select,
                disabled: !this.notification.category || this.isEditing,
                class: 'mr-1',
                remove: () => this.notification.event = '',
                clearable: true,
            }),
            new filterClass.Base({
                id: 'published_at',
                label: 'Дата публикации',
                select: this.notification.published_at,
                disabled: this.notification.isDisabledEditField,
                callback: (data) => this.notification.published_at = data,
                class: 'mr-1',
                isDefaultDate: true,
            }),
            new filterClass.Base({
                id: 'pinned_from',
                label: 'Начало закрепления',
                select: this.notification.pinned_from,
                disabled: !this.notification.category,
                callback: (data) => this.notification.pinned_from = data,
                class: 'mr-1',
                isDefaultDate: false,
            }),
            new filterClass.Base({
                id: 'pinned_to',
                label: 'Окончание закрепления',
                select: this.notification.pinned_to,
                disabled: !this.notification.category,
                callback: (data) => this.notification.pinned_to = data,
                class: 'mr-1',
                isDefaultDate: false,
            }),
        ];
    }

    changeCategory(filter: any): void {
        this.notification.category = filter.select;
        this.notification.send_all = false;
        if (this.notification.isOffer) {
            this.notification.affiliates = [];
        } else {
            this.notification.advertiser = null;
            this.notification.offers = [];
        }
    }

    get possibleEvents(): IEvents[] {
        return this.notification.id ?
            this.notification.getPossibleEvents() || [] :
            this.notification.getPossibleManualEvents() || [];
    }
}
