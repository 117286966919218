
import { Vue, Component, Prop } from "vue-property-decorator";
import SvgTrash from "@/assets/icons/trash-white.svg";
import SvgEdit from "@/assets/icons/tooltip-edit.svg";
import SvgReload from "@/assets/icons/reload-white.svg";
import SvgCheck from "@/assets/icons/check.svg";
import SvgFileDownload from "@/assets/icons/file-download.svg";
import { downloadFileToDisk, downloadImageFromUrl } from "@/api";
import { OfferNote } from "@/services/offers/OfferSingle";
import { INote } from "@/api/types/user";

@Component({
    components: { SvgFileDownload, SvgEdit, SvgTrash, SvgReload, SvgCheck },
})

export default class NotesTooltip extends Vue {
    @Prop({ required: true }) item!: OfferNote | INote;
    @Prop({ default: false }) isDisabledSave!: boolean;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop({ default: 'Удалить заметку?' }) deleteText!: string;

    isShow: boolean = false;
    isEdit: boolean = false;
    isDelete: boolean = false;

    get isDownLoad(): boolean {
        // @ts-ignore
        return !!(this.item.images || this.item.image);
    }

    editNote(): void {
        this.isEdit = true;
        this.isShow = false;
        this.$emit('edit');
    }

    saveNote(): void {
        this.isEdit = false;
        this.isShow = false;
        this.$emit('save');
    }

    cancel(): void {
        this.isEdit = false;
        this.isDelete = false;
        this.isShow = false;
    }

    cancelEdit(): void {
        this.cancel();
        this.$emit('cancel');
    }

    deleteHandler(): void {
        this.isDelete = true;
    }

    deleteNote(): void {
        this.isDelete = false;
        this.$emit('delete');
    }

    downloadImage(): void {
        // @ts-ignore
        const image = this.item.image || this.item.images;
        if (typeof image === 'string') {
            downloadImageFromUrl(image);
        } else {
            downloadFileToDisk(image, image.name);
        }
    }
}

