import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/style/_custom.scss';
import '@/style/_media.scss';
import colors from 'vuetify/lib/util/colors.js';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);
export default new Vuetify({
    iconfont: 'mdi',
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                pictionBlue: '#0085FF',
                primary: '#0085FF',
                success: colors.green.darken1,
                secondary: colors.blue.lighten3,
                accent: colors.blue.darken4,
                error: colors.deepOrange.darken3,
                info: colors.cyan.accent3,
                warning: colors.yellow.darken2,
                default: colors.grey.darken2,
            },
        },
    },
    breakpoint: {
        mobileBreakpoint: 'xs',
    },
});

