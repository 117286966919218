import { GetterTree } from 'vuex';
import { IRootState } from '@/store/types';
import { IStatisticsState } from './types';

export const getters: GetterTree<IStatisticsState, IRootState> = {
    GET_CONVERSIONS_REPORT_ID: (state) => state.conversionsReportId,
    GET_CONVERSIONS_REPORT_IN_PROGRESS: (state) => state.conversionsReportInProgress,
    GET_CONVERSIONS_REPORT_LOADING: (state) => state.conversionsReportLoading,
    GET_CONVERSIONS_REPORT_PROGRESS: (state) => state.conversionsReportProgress,
    GET_CONVERSIONS_REPORT_STATUS: (state) => state.conversionsReportStatus,
    GET_CONVERSIONS_REPORT_SSE: (state) => state.conversionsReportSSE,
};
