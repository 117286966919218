
import { Component, Mixins } from "vue-property-decorator";
import TablePageFilters from "@/components/base/filters/TablePageFilters.vue";
import WeekPicker from "@/components/base/pickers/WeekPicker.vue";
import FiltersMixin from "@/mixins/filters";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { IFilter } from "@/types";

@Component({
    components: { BaseAutocomplete, SelectDesign, WeekPicker, TablePageFilters },
})

export default class TopOfWeekFilters extends Mixins(FiltersMixin) {
    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 768;
    }

    submitCountry(filter: IFilter, value: any): void {
        filter.select = value;
        this.submit();
    }

    mounted(): void {
        this.submit();
    }
}

