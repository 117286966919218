
import { Vue, Component, Prop } from "vue-property-decorator";
import { ITableFooterButton, ITableStatus, TableHeader } from "@/types";
import { IAuthorization } from "@/api/types/user";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { endUserSessions } from "@/api/user";
import { showNotification, showServerError } from "@/utils";
import { translateGoalStatus as translateAuthorizationStatus } from "@/utils/translate";

@Component({
    components: { TableWithFrontPaginationDesign },
})

export default class AffiliateAuthorizationsTable extends Vue {
    @Prop({ required: true }) items!: IAuthorization[];
    @Prop({ default: true }) loading!: boolean;
    @Prop({ required: true }) userId!: number;

    selected: IAuthorization[] = [];
    isLoading: boolean = false;

    headers: TableHeader[] = [
        { text: 'Дата авторизации', value: 'created_at', sortable: false, align: 'center' },
        { text: 'Срок действия', value: 'expires_at', sortable: false, align: 'center' },
        { text: 'IP', value: 'ip', sortable: false, align: 'center', width: '300px' },
        { text: 'Устройство', value: 'user_agent', sortable: false },
        { text: 'Статус', value: 'status', sortable: false, align: 'center' },
    ];

    footerButtons: ITableFooterButton[] = [
        {
            text: 'Завершить сессии',
            textOfConfirm: 'Подтвердить?',
            color: 'blue',
            icon: 'logout',
            action: () => this.endSession(),
        },
    ];

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
        {
            slot: 'item.expires_at',
            key: 'expires_at',
            isLine: false,
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateAuthorizationStatus,
        },
    ];

    get isLoadingTable(): boolean {
        return this.loading || this.isLoading;
    }

    get getItems(): any[] {
        return this.items.map(i => {
            const curDate = new Date();
            const endDate = new Date(i.expires_at);
            i.status = curDate < endDate;
            return i;
        });
    }

    async endSession(): Promise<void> {
        try {
            this.isLoading = true;
            const token_ids = this.selected.map(i => i.id);
            const id = this.userId;
            await endUserSessions(id, token_ids);
            showNotification('Сессии успешно закрыты');
            await this.$emit('update-table', id);
        } catch (err) {
            showServerError(err, 'Ошибка закрытия сессий');
        } finally {
            this.isLoading = false;
        }
    }
}

