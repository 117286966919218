
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { showServerError } from '@/utils';
import { getOneEmployeesOffer } from '@/api/offers';
import OfferMain from "@/components/offers/offer/OfferMain.vue";
import PageLayout from "@/components/layout/PageLayout.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import PersonalRates from "@/views/offers/personalRate/PersonalRates.vue";
import Approvals from "@/views/offers/approval/Approvals.vue";
import OfferMetrics from "@/components/offers/offer/OfferMetrics.vue";
import OfferGoalsTab from "@/components/offers/offer/OfferGoalsTab.vue";
import OfferLimitsTab from "@/components/offers/offer/OfferLimitsTab.vue";
import OfferLinks from "@/components/offers/offer/OfferLinks.vue";
import OfferProducts from "@/components/offers/offer/OfferProducts.vue";
import { eventBus } from "@/eventbus";
import OfferRedirects from "@/components/offers/offer/OfferRedirects.vue";
import PageTabs from "@/components/base/PageTabs.vue";
import Status from "@/components/base/Status.vue";
import DownloadBtn from "@/components/base/buttons/DownloadBtn.vue";
import SvgCopy from "@/assets/icons/copy.svg";
import SvgMagicWand from "@/assets/icons/magic-wand.svg";
import OfferChangeHistory from "@/components/offers/offer/OfferChangeHistory.vue";
import { OFFER_PRODUCT_PARAMS } from "@/mappings/offer";
import intersection from 'lodash-es/intersection';

const offers = namespace('offersModule');

@Component({
    components: {
        DownloadBtn,
        Status,
        PageTabs,
        OfferMain,
        PageLayout,
        SvgCopy,
        SvgMagicWand,
    },
})

export default class OfferPage extends Vue {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParamsObject;
    @offers.Mutation('SET_CURRENT_OFFER') setOffer;
    @offers.Getter('GET_CURRENT_OFFER') getOffer;
    @Prop({ required: true }) beforeListRoute!: string;


    offer: OfferSingle | null = null;
    productTabName = 'Параметры продукта';
    tabItems = [
        { tab: 'Основное', component: OfferMain, class: 'tab-main' },
        { tab: 'Цели', component: OfferGoalsTab, class: 'tab-goals' },
        { tab: 'Лимиты', component: OfferLimitsTab, class: 'tab-limits' },
        { tab: 'Показатели', component: OfferMetrics, class: 'tab-metrics' },
        { tab: 'Индивидуальные цены', component: PersonalRates, class: 'tab-personal-rates' },
        { tab: 'Доступы партнёров', component: Approvals, class: 'tab-approvals' },
        { tab: 'Ссылки', component: OfferLinks, class: 'tab-links' },
        { tab: 'Редиректы', component: OfferRedirects, class: 'tab-redirects' },
        { tab: 'История изменений', component: OfferChangeHistory, class: 'tab-history' },
    ];
    tab: number = 0;
    isEditGoals: boolean = false;
    isShowTabs: boolean = false;

    get legacyOfferId(): string {
        return this.$route.params.id;
    }

    get bodyComponent(): any {
        return this.tabItems[this.tab].component;
    }

    get tabClass(): string {
        return this.tabItems[this.tab].class;
    }

    get title(): string {
        return `${this.offer?.legacy_id} ${this.offer?.name}`;
    }

    async created(): Promise<void> {
        this.setDefaultParamsObject({});
        this.setFiltersParamsObject({});
        await this.getOneOffer();
        this.tab = Number(this.$route.query.tab!) || 0;
        this.changeTab(this.tab, true);
        eventBus.$on("update-offer", this.getOneOffer);
        eventBus.$on("edit-goal", this.goToGoals);
        eventBus.$on("change-tab", this.changeTab);
    }

    // скрываем Таб параметры продукта, если продукты в оффере пустые или если нет пересений с OFFER_PRODUCT_PARAMS,
    // так как виджеты подготовлены только для 5 типов продуктов
    toggleProductTab(): void {
        const offerProductIds = this.offer?.products?.map(i => i.id) || [];
        const intersectionArray = intersection(Object.values(OFFER_PRODUCT_PARAMS), offerProductIds);
        const isNeedProductTab = !!this.offer?.products?.length || !!intersectionArray?.length;
        const productTab = { tab: this.productTabName, component: OfferProducts, class: 'tab-products' };
        const productTabIndex = this.tabItems.findIndex(i => i.tab === this.productTabName);

        if (isNeedProductTab) {
            productTabIndex === -1 && this.tabItems.splice(8,0, productTab);
        } else if (productTabIndex !== -1) {
            this.tabItems.splice(8, 1);
        }
    }

    async getOneOffer(): Promise<void> {
        try {
            this.isShowTabs = false;
            const data = await getOneEmployeesOffer(this.legacyOfferId);
            this.offer = new OfferSingle(data);
            await this.offer.downloadOfferData();
            this.setOffer({ legacy_id: this.offer.legacy_id, name: this.offer.name });
            this.toggleProductTab();
        } catch (err) {
            showServerError(err, 'Оффер не найден');
        } finally {
            this.isShowTabs = true;
        }
    }

    changeTab(tab: number, isHistory: boolean): void {
        this.tab = tab;
        if (isHistory) {
            this.setTabToUrl();
        }
        if (document.title !== this.title) {
            this.$nextTick(() => document.title = this.title);
        }
    }

    goToGoals(): void {
        this.isEditGoals = true;
        this.changeTab(1, true);
        setTimeout(() => this.isEditGoals = false, 1000);
    }

    @Watch('$route.query')
    setTabToUrl(query?: object): void {
        if (this.tab === 4 || this.tab === 5) {
            this.setFiltersParamsObject(
                { ...this.getFiltersParamsObject, ...{ offer_legacy_id: [this.offer?.legacy_id] } },
            );
        }
        if (Number(this.$route.query.tab!) === this.tab) return;
        const params = { ...query, ...{ tab: this.tab } };

        this.offer?.updateQueryString(params);
    }

    backToList(): void {
        if (this.tab === 1 && this.$route.query['is_create_goals']) {
            this.$router.replace({ query: { 'tab': '1' } });
        } else {
            this.$router.push({ path: this.beforeListRoute });
        }
    }

    beforeDestroy(): void {
        this.setOffer(null);
        eventBus.$off("update-offer", this.getOneOffer);
        eventBus.$off("edit-goal", this.goToGoals);
        eventBus.$off("change-tab", this.changeTab);
    }

    copyOffer(): void {
        this.$router.push({ name: 'create-offer', query: { copy: `${this.legacyOfferId}` } });
    }
}
