
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { MULTISELECT_TYPE } from '@/mappings/multiSelectDataType';
import differenceWith from 'lodash-es/differenceWith';
import intersectionWith from 'lodash-es/intersectionWith';
import SvgTrash from '@/assets/icons/trash.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import AutocompleteListItem from "@/components/base/form/AutocompleteListItem.vue";
import SvgArrow from "@/assets/icons/arrow-down.svg";

@Component({
    components: { SvgArrow, AutocompleteListItem, TrashBtn, SvgTrash },
})

export default class MultiselectDesign extends Vue {
    @Prop({ required: true }) multiselectData!: any;
    @Prop({ default: false }) outlined!: boolean;

    selectedItems: any[] = [];

    get allItemsSelected(): boolean | void {
        if (this.multiselectData.items) {
            return this.selectedItems.length === this.multiselectData.items.length;
        }
    }

    get isShowPrependIcon(): boolean {
        return !this.multiselectData.isNoToggle && this.multiselectData.items?.length > 1;
    }

    get someItemsSelected(): boolean {
        return this.selectedItems.length > 0 && !this.allItemsSelected;
    }

    get iconForSelect(): string {
        if (this.allItemsSelected) {
            return 'mdi-close-box';
        }
        if (this.someItemsSelected) {
            return 'mdi-minus-box';
        }
        return 'mdi-checkbox-blank-outline';
    }

    /*
     * Чтобы при сбросе фильтров в родителе,
     * selectedItems тоже очищался до дефолтных значений
     */
    @Watch('multiselectData.value')
    clearSelectedItems(value: any[]): void {
        this.selectedItems = value;
    }

    get sortedItems(): any[] {
        if (this.selectedItems?.length > 0) {
            const difference = differenceWith(
                this.multiselectData.items,
                this.selectedItems,
                (arrValue: any, othValue) => arrValue[this.multiselectData.itemValue] === othValue,
            );
            const intersection = intersectionWith(
                this.multiselectData.items,
                this.selectedItems,
                (arrValue: any, othValue) => arrValue[this.multiselectData.itemValue] === othValue,
            );
            return [...intersection, ...difference];
        } else return this.multiselectData.items;
    }

    deleteItem(item: number): void {
        const index = (this.selectedItems as any).indexOf(item);
        this.selectedItems.splice(index, 1);
        this.update(this.selectedItems);
    }

    remove(item: any): void {
        if (!this.multiselectData.noNeedModified) {
            item = this.multiselectData.itemValue ? item[this.multiselectData.itemValue] : item;
            this.selectedItems = this.modifyItems(this.selectedItems);
        }
        if (typeof this.selectedItems[0] === typeof item) {
            this.deleteItem(item);
        }
    }

    created(): void {
        this.selectedItems = this.multiselectData.value
            ? this.multiselectData.value
            : this.selectedItems;
    }

    //увеличить lastItem свойство VSelect, которое управляет длиной виртуального списка и изначально равно 20
    mounted(): void {
        (this.$refs["select"] as any).lastItem = 300;
    }

    toggleAllItemsSelected(): void {
        this.$nextTick(() => {
            if (this.allItemsSelected) {
                this.selectedItems = [];
            } else {
                this.selectedItems = this.multiselectData.items.slice();
            }
            if (this.multiselectData.id === 'StatTaskedFileItem') {
                this.$emit('change-multiselect-items', this.selectedItems);
            } else {
                this.update(this.selectedItems);
            }
        });
    }

    async update(items: any[]): Promise<void> {
        if (this.multiselectData.noNeedModified) {
            this.$emit('change-multiselect-items', items);
            return;
        }
        this.$emit(
            'change-multiselect-items',
            {
                type: this.multiselectData.id,
                items: (this.multiselectData.id !== MULTISELECT_TYPE.SERVICES
                    && this.multiselectData.id !== MULTISELECT_TYPE.OTHER_SERVICES)
                    ? this.modifyItems(items) : items,
            },
        );
    }

    private modifyItems(items: any[]) {
        const modifiedItems: any[] = [];
        items.map((item) => {
            if (item === null || item === undefined) return;
            if (typeof item === 'object' && this.multiselectData.id) {
                modifiedItems.push(item[this.multiselectData.itemValue]);
            } else {
                modifiedItems.push(item);
            }
        });
        return modifiedItems;
    }
}
