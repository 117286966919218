import { IColumnFilter, IFilter } from "@/types";
import { getAffiliateSearch } from "@/api/user";
import { translateMyLeadgidInvoiceStatus } from "@/utils/translate";
import { CreatedColumns } from "@/services/columns/columns";
import { showServerError } from "@/utils";
import { getEmployeeMethods } from "@/api/payout";
import { CreatedFilters } from "@/services/filters/filters";
import { filterClass } from '@/services/filters/filterClass';
import store from "@/store";

export class InvoicesFilters extends CreatedFilters {
    constructor() {
        super();

        (async () => {
            await Promise.all([
                this.setEmployeeMethods(),
                this.setSlaves('affiliate_id'),
            ]);
        })();
    }

    getFilters(): IFilter[] {
        const affiliate = store.getters['GET_CURRENT_AFFILIATE'];
        const isMaster = store.state.isMasterAffiliate;
        const isAffiliate = affiliate && this.checkRoute('affiliate');
        return [
            new filterClass.ComboboxArray({
                id: 'invoice_ids',
                label: 'ID счёта',
                select: [],
                checkMultiPaste: true,
                multiPaste: true,
            }),
            new filterClass.Autocomplete({
                id: 'master_ids',
                apiMethod: getAffiliateSearch,
                label: 'Мастер-аккаунт',
                itemValue: 'id',
                itemText: 'name',
                select: isAffiliate && isMaster ? [affiliate] : [],
                defaultValue: isAffiliate && isMaster ? [affiliate] : [],
                items: [],
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
                isHidden: true,
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_id',
                apiMethod: isAffiliate && !isMaster ? getAffiliateSearch : undefined,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                select: isAffiliate && !isMaster ? [affiliate] : [],
                defaultValue: isAffiliate && !isMaster ? [affiliate] : [],
                items: [],
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
                isHidden: isAffiliate && !isMaster,
            }),
            new filterClass.Multiselect({
                id: 'currency',
                label: 'Валюта',
                noDataText: 'Нет выбранной валюты',
                select: [],
                items: [],
                itemValue: 'code',
                itemText: 'code',
                type: 'string',
                isCurrencyCatalogue: true,
            }),
            new filterClass.Multiselect({
                id: 'paid_status',
                type: 'string',
                label: 'Статус',
                items: [
                    { id: 'paid' },
                    { id: 'not_paid' },
                    { id: 'partial_paid' },
                ],
                select: [],
                template: (i) => translateMyLeadgidInvoiceStatus(i.id).text,
                itemText: (i) => translateMyLeadgidInvoiceStatus(i.id).text,
                itemValue: 'id',
            }),
            new filterClass.Multiselect({
                id: 'method_ids',
                type: 'string',
                label: 'Способ выплаты',
                noDataText: 'Нет выбранного способа выплаты',
                select: [],
                items: [],
                itemValue: 'id',
                itemText: (i) => i.name.ru,
                template: (i) => i.name.ru,
            }),
            new filterClass.Datepicker({
                id: 'period',
                label: 'Период оплаты',
                pickerType: 'date',
                select: [],
                range: true,
                clearable: true,
                allowedDates: () => ({ start: null, end: new Date(), days: [1, 15] }),
            }),
            new filterClass.Checkbox({
                label: 'Мастер-счёт',
                id: 'is_master',
                select: [],
                itemText: 'false',
                type: 'string',
            }),
        ];
    }

    async setEmployeeMethods(): Promise<void> {
        try {
            const filter = this.filters.find(f => f.id === 'method_ids')!;
            if (filter?.items?.length! > 0) return;
            const { data } = await getEmployeeMethods();
            filter.items = data;
        } catch (err) {
            showServerError(err, 'Список способов выплат не загружен');
        }
    }
}

export class InvoicesColumns extends CreatedColumns {
    constructor() {
        super();
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'ID счёта', value: 'id' },
                    { title: 'Валюта', value: 'currency' },
                    { title: 'Сумма счёта', value: 'total_amount' },
                    { title: 'Выплачено', value: 'partials_amount' },
                    { title: 'Остаток', value: 'rest_amount' },
                    { title: 'Способ выплаты', value: 'method' },
                    { title: 'Реквизиты', value: 'requisites' },
                    { title: 'Период оплаты', value: 'pay_period' },
                    { title: 'Дата создания', value: 'created_at' },
                    { title: 'Дата обновления', value: 'updated_at' },
                    { title: 'Статус', value: 'paid_status' },
                ],
            },
        ];
    }
}
