
import { Component, Vue } from 'vue-property-decorator';
import SvgAffiliatesList from '@/assets/icons/nav-bar/affiliates-list.svg';
import { IColumnFilter } from '@/types';
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { CheckingAffiliateColumns, CheckingAffiliateFilters } from "@/services/TablePage/checkingAffiliate";
import checkAffiliateTable from "@/components/antifraud/CheckAffiliateTable.vue";
import { getCheckingAffiliates } from "@/api/antifraud";
import FormCheckingAffiliate from "@/components/antifraud/FormCheckingAffiliate.vue";

@Component({
    components: {
        TablePageWrapper,
        SvgAffiliatesList,
    },
})
export default class CheckingAffiliates extends Vue {
    columns: IColumnFilter[] = new CheckingAffiliateColumns().columns;
    filters: CheckingAffiliateFilters = new CheckingAffiliateFilters();

    checkAffiliateTable = {
        component: checkAffiliateTable,
        apiMethod: getCheckingAffiliates,
    };

    createAffiliateForm = {
        component: FormCheckingAffiliate,
    };
}
