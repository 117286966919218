
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgEdit from "@/assets/icons/pencil.svg";
import SvgCheck from "@/assets/icons/check.svg";

@Component({
    components: { SvgCheck, SvgEdit },
})

export default class EditBtn extends Vue {
    @Prop({ default: true }) readonly isTooltip!: boolean;
    @Prop({ default: true }) readonly isHideTextOnMobile!: boolean;
    @Prop({ default: true }) readonly isTextBtn!: boolean;
    @Prop({ default: false }) readonly isHideTextOnEdit!: boolean;
    @Prop({ default: false }) readonly isIcon!: boolean;
    @Prop({ default: false }) readonly isTransparent!: boolean;
    @Prop({ default: '' }) readonly textBtn!: string;
    @Prop({ default: false }) readonly isDisabled!: boolean;
    @Prop({ default: true }) readonly isOutline!: boolean;
    @Prop({ default: false }) readonly isEdit!: boolean;
    @Prop({ default: false }) readonly customBtnClass!: string;
    @Prop({ default: 0 }) readonly nudgeTop!: number;
}
