
import { Component, Mixins } from 'vue-property-decorator';
import { getCurrentDate } from '@/utils/formatDates';
import { getStreams, getEndpoint } from '@/api/uapi';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { Getter, Mutation } from 'vuex-class';
import { IStream } from '@/api/types/uapi';
import { showServerError } from '@/utils';
import { IFilter } from '@/types';
import { GetInfo } from '@/utils/getInfo';
import { getUsers } from '@/api/user';
import { IUser } from '@/api/types/user';
import SortTableMixin from '@/mixins/sortTableMixin';
import Filters from '@/components/base/filters/Filters.vue';
import { eventBus } from '@/eventbus';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        Filters,
        SvgApplication,
    },
})
export default class Applications extends Mixins(SortTableMixin) {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    currentDate = getCurrentDate('yyyy-MM-dd');

    authorNames: IUser[] = [];
    dataTable: IStream[] = [];
    totalElementDataTable = 0;

    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'endpoint_id',
            label: 'Кампания',
            placeholder: 'Выберите ID или название',
            itemValue: 'id',
            itemText: 'endpoint',
            apiMethod: getEndpoint,
            multiple: true,
            items: [],
            select: [],
        }),
        new filterClass.Autocomplete({
            id: 'streams',
            label: 'Потоки',
            placeholder: 'Выберите ID или название',
            itemValue: 'id',
            itemText: 'name',
            apiMethod: getStreams,
            multiple: true,
            items: [],
            select: [],
        }),
    ];
    settings = {
        title: 'Потоки',
        loading: true,
    };
    headers = [
        {
            text: 'ID',
            value: 'id',
            align: 'center',
        },
        {
            text: 'Название потока',
            value: 'name',
        },
        {
            text: 'Кампании',
            value: 'endpoints',
            sortable: false,
        },
        {
            text: 'Количество стадий',
            value: 'stages',
            align: 'center',
        },
        {
            text: 'Лимит',
            value: 'quota',
        },
        {
            text: 'Дата создания',
            value: 'created_at',
        },
        {
            text: 'Автор',
            value: 'created_by',
        },
    ];
    // для отображения роутов в таблице
    routes = [
        {
            routeParam: 'streamId',
            slot: 'item.id',
            extraRouteParamName: 'id',
            routerName: 'stream-edit',
            text: 'id',
        },
    ];
    uniqueData = [
        {
            slot: 'item.endpoints',
            template: (item: any): number => (item.endpoints ? item.endpoints.length : 0),
        },
    ];

    getTemplate(item: IStream): number | string {
        const authorName = this.authorNames.find(({ account_id }) => account_id === item.created_by);
        if (authorName) {
            return `${authorName.first_name} ${authorName.last_name}`;
        }
        return item.created_by!;
    }

    created(): void {
        // используется для сортировки
        // нужен в sortTableMixins
        this.field = 'id';
        const activeFilters = {};
        this.filters.forEach((fl) => {
            if (fl.select) {
                if (fl.array) {
                    if (fl.select.length) {
                        activeFilters[fl.id] = fl.select;
                    }
                } else {
                    activeFilters[fl.id] = fl.select;
                }
            }
        });

        const paramsObj = { ...activeFilters };
        this.setFiltersParamsObject(paramsObj);
        this.setDefaultFiltersParamsObject({});
    }

    async getDataTable(offset?: number, limit?: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        this.settings.loading = true;
        const paramsObj = { ...this.getFiltersParamsObject, ...this.getSortFilters };
        try {
            const { meta, data } = await getStreams({ offset, limit, ...paramsObj });
            this.dataTable = data;
            this.totalElementDataTable = meta!.total!;
            this.authorNames = await new GetInfo(this.dataTable, 'created_by', getUsers).getInfoByFields();
        } catch (err) {
            showServerError(err);
        }
        this.settings.loading = false;
    }

    beforeDestroy(): void {
        this.setFiltersParamsObject({});
        this.setDefaultFiltersParamsObject({});
    }
}
