
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})

export default class ItemTableList extends Vue {
    @Prop({ default: [] }) items!: string[] | number[];
    @Prop({ required: false }) affiliateId!: string | number;
    @Prop({ required: false }) linkName!: string;
    @Prop({ required: false, default: true }) isSmallItems!: boolean;

    isShowAllItems = false;

    toggleShowItems(): void {
        this.$emit('click', this.affiliateId);
        this.isShowAllItems = !this.isShowAllItems;
    }

    get filterItems(): any[] | [] {
        return this.isShowAllItems ?  this.items || [] :  this.items.slice(0, 2) || [];
    }
}
