
import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
import { getAffiliateSearch } from '@/api/user';
import FormModal from '@/components/base/FormModal.vue';
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import {
    getEmployeesOffersV2,
    getOneEmployeesOffer, postEmployeesPersonalRates,
} from '@/api/offers';
import { ICreatePersonalRate, IOfferGoal, IPersonalRate } from '@/api/types/offers';
import { showServerError } from '@/utils';
import Loader from '@/components/base/Loader.vue';
import DateTimePicker from '@/components/base/pickers/DateTimePickerNew.vue';
import TrashBtn from '@/components/base/buttons/TrashBtn.vue';
import { namespace } from 'vuex-class';
import SvgExpandMore from '@/assets/icons/expand-more.svg';
import AutocompleteListItem from "@/components/base/form/AutocompleteListItem.vue";
import RateItem from '@/components/offers/personal-rates/RateItem.vue';
import { NotificationMessage } from "@/utils/notification";

const catalogue = namespace('catalogueModule');
const offers = namespace('offersModule');

@Component({
    methods: { getEmployeesOffersV2 },
    components: {
        AutocompleteListItem,
        BaseAutocomplete,
        FormModal,
        Loader,
        DateTimePicker,
        TrashBtn,
        SvgExpandMore,
        RateItem,
    },
})
export default class FormPersonalRate extends Vue {
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencyIcon;
    @offers.Getter('GET_CURRENT_OFFER') getCurrentOffer;

    @PropSync('showModal') showModalSync!: boolean;
    @Prop() defaultPartner!: number;
    @Ref() affiliateInput!: BaseAutocomplete;
    @Ref() offersInput!: BaseAutocomplete;

    getAffiliateSearch = getAffiliateSearch;

    personalRate: IPersonalRate = {
        affiliates: [],
        rates: [],
    };
    offerGoals: IOfferGoal[] = [];
    isGoalSelect = false;
    loading = false;
    offer: string | number = '';
    startDateValue: string = '';
    isTimeChanged: boolean = false;
    isShowCloseModal: boolean = false;

    get title(): string {
        return 'Создание индивидуальной цены';
    }

    get isScroll(): boolean {
        return this.personalRate.rates?.length > 1;
    }
    get isShowFields(): boolean {
        return this.isGoalSelect;
    }

    get isDisabledSaveBtn(): boolean {
        return !(
            !!this.personalRate.affiliates.length && !!this.personalRate.rates.length &&
                this.personalRate.rates.every(rate => rate.goal?.payout?.type! === 'percent' ?
                    rate.revenue !== null && !!rate.percent :
                    rate.payout !== null && rate.revenue !== null && rate.percent !== null)
        );
    }

    get getId(): number {
        return this.personalRate.rates.length === 0 ? 0 :  Math.max.apply(null, this.personalRate.rates.map(r => r.id!)) + 1;
    }

    setDateTime(date: string): void {
        this.isTimeChanged = new Date(date) > new Date;
        this.startDateValue = date;
    }

    updateAffiliates(items: number[]): void {
        this.personalRate.affiliates = items;
    }

    addGoal(): void{
        this.$set(this.personalRate.rates, this.personalRate.rates.length, { id: this.getId, payout: null, offer_id: this.offer, goal_id: null, revenue: null, is_expand: true });
        if (this.personalRate.rates.length > 2) {
            for (let i = 0; i < this.personalRate.rates.length; i++) {
                if (i < this.personalRate.rates.length - 1) {
                    this.$set(this.personalRate.rates[i], 'is_expand', false);
                }
            }
        }
    }

    async getGoals(item: number): Promise<void> {
        if (!item) {
            this.personalRate.rates = [];
            return;
        }
        try {
            this.isGoalSelect = false;
            const { goals, legacy_id } = await getOneEmployeesOffer(item, { by_legacy_id: 1 });
            this.offer = legacy_id!;
            this.offerGoals = goals!.filter(item => item.active);
            this.isGoalSelect = true;
            this.$set(this.personalRate.rates, 0, { id: this.getId, offer_id: legacy_id, payout: null, goal_id: null, revenue: null, is_expand: true });
        } catch (err) {
            showServerError(err, 'Список целей оффера не загружен');
        }
    }

    closeModal(): void {
        const inputs = [...this.personalRate.rates, ...this.personalRate.affiliates, ...this.offerGoals];
        inputs.length > 0 ? this.isShowCloseModal = true : this.showModalSync = false;
    }

    approveCloseModal(): void {
        this.clearInputs();
        this.showModalSync = false;
        this.isShowCloseModal = false;
    }

    clearInputs(): void {
        this.personalRate.rates = [];
        this.personalRate.affiliates = [];
        this.offerGoals = [];
        this.isGoalSelect = false;
        this.affiliateInput.clearModel();
        this.offersInput.clearModel();
    }

    async postPersonalRates(): Promise<void> {
        this.loading = true;
        try {
            const pairs: ICreatePersonalRate[] = this.personalRate.affiliates.flatMap(a => {
                return this.personalRate.rates.map(r => {
                    return {
                        affiliate_id: a,
                        goal_id: r.goal_id || r.goal!.legacy_id!,
                        payout: r.goal?.revenue?.type === 'percent' ? +r.percent! : +r.payout!,
                        revenue: +r.revenue!,
                        offer_id: r.offer_id!,
                        ...(this.isTimeChanged && { start_date: this.startDateValue }),
                    };
                });
            });
            await postEmployeesPersonalRates({ pairs });
            this.$emit('edit');
            this.showNotification();
            this.clearInputs();
            this.closeModal();
        } catch (e) {
            showServerError(e, 'Индивидуальная цена не сохранена');
        } finally {
            this.loading = false;
        }
    }

    showNotification(): void {
        const notificationText = `Цена успешно создана`;
        new NotificationMessage(notificationText, 'success' );
    }
}
