
import { Component, Mixins, Prop } from 'vue-property-decorator';
import AppTableDesign from '@/components/base/table/AppTableDesign.vue';
import TableMixin from '@/mixins/table';
import TableWithFrontPaginationDesign from '@/components/base/table/TableWithFrontPaginationDesign.vue';
import PageTabs from "@/components/base/PageTabs.vue";
import { TableHeader } from "@/types";
import { ISingleMyLeadgidInvoice } from "@/api/types/payout";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import DownloadBtn from "@/components/base/buttons/DownloadBtn.vue";
import { namespace } from "vuex-class";
import { downloadExcelFile, showServerError } from "@/utils";
import { getMasterPaymentDetailsFile } from "@/api/payout";
import { IParamsObject } from '@/api/types';

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        BaseSelect,
        DownloadBtn,
        PageTabs,
        AppTableDesign,
        TableWithFrontPaginationDesign,
    },
})
export default class MyLeadgidInvoiceLeadsTable extends Mixins(TableMixin) {
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencySymbol;
    @Prop({ required: true }) invoice!: ISingleMyLeadgidInvoice;

    tab = 0;
    settings = { loading: false };
    filterValue: '' | number = '';

    offerHeaders: TableHeader[] = [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'ID оффера', value: 'offer_id', sortable: true, width: '150px', align: 'center' },
        { text: 'Название оффера', value: 'name', sortable: true },
        { text: 'ID цели', value: 'goal_id', sortable: true, width: '150px', align: 'center' },
        { text: 'Количество', value: 'leads_num', sortable: true, width: '150px' },
        { text: 'Доход', value: 'revenue', sortable: true },
        { text: 'Выплата', value: 'amount', sortable: true },
        { text: 'Прибыль', value: 'profit', sortable: true },
    ];

    affiliateHeaders: TableHeader[] = [
        { text: 'ID партнёра', value: 'affiliate_id', sortable: true, width: '150px', align: 'center' },
        { text: 'Имя партнёра', value: 'affiliate_name', sortable: true },
        { text: 'Количество', value: 'leads_num', sortable: true, width: '150px' },
        { text: 'Доход', value: 'revenue', sortable: true },
        { text: 'Выплата', value: 'payout', sortable: true },
        { text: 'Прибыль', value: 'profit', sortable: true },
    ];

    routes = [
        {
            routerName: 'offer',
            routeParam: 'id',
            extraRouteParamName: 'offer_id',
            slot: 'item.offer_id',
            text: 'offer_id',
        },
        {
            routerName: 'affiliate',
            routeParam: 'id',
            extraRouteParamName: 'affiliate_id',
            slot: 'item.affiliate_id',
            text: 'affiliate_id',
        },
    ];

    get isShowFilter(): boolean {
        return !!this.invoice.affiliates && this.invoice.affiliates.length > 0;
    }

    get getTotalElements(): number {
        return this.invoice.items?.length! || 0;
    }

    get getFilterByAffiliateItems(): any[] {
        if (!this.invoice) return [];
        return this.invoice.affiliates.map(item => ({
            affiliate_id: item.affiliate_id,
            affiliate_name: item.affiliate_name,
        }));
    }
    get getFilterByOfferItems(): any[] {
        if (!this.invoice) return [];
        return this.invoice.items.map(item => ({
            offer_id: item.offer_id,
            name: item.name,
        }));
    }

    get getFilterOptions(): any {
        const filterByAffiliate = {
            itemText: 'affiliate_name',
            itemValue: 'affiliate_id',
            items: this.getFilterByAffiliateItems,
            template: (item) => `${item.affiliate_id} ${item.affiliate_name}`,
            placeholder: 'Выберите партнёра',
        };

        const filterByOffer = {
            itemText: 'name',
            itemValue: 'offer_id',
            items: this.getFilterByOfferItems,
            template: (item) => `${item.offer_id} ${item.name}`,
            placeholder: 'Выберите оффер',
        };

        return this.tab ? filterByOffer : filterByAffiliate;
    }


    get numberFormatting(): any[] {
        return [
            {
                slot: 'item.revenue',
                key: 'revenue',
                currency: this.invoice.currency,
                currencySymbol: true,
            },
            {
                slot: 'item.profit',
                key: 'profit',
                currency: this.invoice.currency,
                currencySymbol: true,
            },
            {
                slot: 'item.amount',
                key: 'amount',
                currency: this.invoice.currency,
                currencySymbol: true,
            },
            {
                slot: 'item.payout',
                key: 'payout',
                currency: this.invoice.currency,
                currencySymbol: true,
            },
        ];
    }

    get getTabItems(): { tab: string, isDisabled?: boolean }[] {
        return [
            { tab: 'Офферы' },
            { tab: 'Партнёры', isDisabled: !this.invoice.is_master },
        ];
    }

    get getFilteredTableItems(): any[] {
        const affiliates = this.invoice.affiliates;
        if (this.filterValue === '') return this.tab ? affiliates : this.invoice.items;

        if (this.tab) {
            const offers = affiliates.flatMap(a => a.items.map(i => ({ ...i, affiliate_id: a.affiliate_id, affiliate_name: a.affiliate_name })));
            return offers.filter(i => i.offer_id === +this.filterValue);
        } else {
            const curAffiliate = affiliates.find(i => i.affiliate_id === +this.filterValue);
            return curAffiliate!.items;
        }
    }

    get getHeaders(): TableHeader[] {
        if (this.tab) {
            return this.affiliateHeaders;
        } else {
            const payoutHead = this.offerHeaders.find(i => i.text === 'Выплата');
            payoutHead!.value = this.filterValue === '' ? 'amount' : 'payout';
            return this.offerHeaders;
        }
    }

    changeTab(tab: number): void {
        this.tab = tab;
        this.filterValue = '';
    }

    getTotals(col: string): string | number {
        const totalCols = ['id', 'affiliate_id'];
        const emptyCols = ['offer_id', 'name', 'goal_id', 'affiliate_name'];
        const sumCols = ['leads_num', 'revenue', 'amount', 'profit', 'payout'];

        if (totalCols.includes(col)) return 'Итого';
        if (emptyCols.includes(col)) return '';
        if (sumCols.includes(col)) {
            const sum = this.getFilteredTableItems?.reduce((acc, cur) => acc + Number(cur[col] || 0), 0);
            if (col === 'leads_num') return sum;

            const currency = this.currencySymbol(this.invoice.currency);
            return Number(sum).toLocaleString('ru-RU', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }) + ` ${currency}`;
        }
        return col;
    }

    async downloadReportDetails(): Promise<void> {
        try{
            const reportType = this.tab ? 'affiliates' : 'offers';
            const params: IParamsObject = this.filterValue
                ? { [this.tab === 0 ? 'affiliate_id' : 'offer_id']: this.filterValue }
                : {};

            const report = await getMasterPaymentDetailsFile( this.invoice.id, reportType, params );
            downloadExcelFile(report,  `${this.invoice.id} - детализация по счёту (${this.tab ? 'партнёры' : 'офферы'})`, 'xls', true);
        }catch ( err ){
            showServerError(err, 'Ошибка загрузки файла');
        }
    }
}
