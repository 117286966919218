
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IFilter } from '@/types';
import AutocompleteListItem from "@/components/base/form/AutocompleteListItem.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import SvgArrow from "@/assets/icons/arrow-down.svg";

@Component({
    components: { SvgArrow, AutocompleteListItem,TrashBtn },
})

export default class AutocompleteFilter extends Vue {
    @Prop({ required: true }) filter!: IFilter;
    @Prop({ type: Boolean, default: true }) readonly isUsedBlurAutocomplete!: boolean;

    deleteFilterChips(select: any[], id: string | number, filter: IFilter): void {
        if (Array.isArray(select) && filter.multiple) {
            const index = select.findIndex(i => i[filter.itemValue!] === id);
            select.splice(index, 1);
        } else {
            filter.select = '';
        }
        this.$emit('delete-item-autocomplete', filter);
    }

    clear(filter: IFilter): void {
        filter.clear();
    }

    get hideNoData(): boolean {
        if (this.filter.select?.length) return false;
        return  !this.filter.autocompleteValue || !!this.filter.loading;
    }

    updateSearch(filter: IFilter, value: string | null): void {
        if (typeof value !== 'string') return;
        if (filter.multiple && filter.autocompleteValue === null && !!filter.apiMethod) {
            filter.items = filter.select;
        }
        filter.autocompleteValue = value;
        const minSearchLength = filter.minSearchLength !== undefined ? filter.minSearchLength : 1;
        if (filter.autocompleteValue.length >= minSearchLength) {
            this.$emit('debounce-query-selections', filter.id);
        }
        if (filter.autocompleteValue?.length! <= 0 && !!filter.apiMethod) {
            filter.items = filter.select?.length! >= 0 ?  [...filter.select] : [];
        }
    }

    filterItems(item: any, queryText: string, filter: IFilter): boolean {
        const textOne = item[filter.itemText].toString().toLowerCase();
        const searchText = queryText.toLowerCase();
        if (!filter.alsoSearchBy) return textOne.indexOf(searchText) > -1;
        const textTwo = item[filter.alsoSearchBy].toLowerCase();
        return textOne.indexOf(searchText) > -1 || textTwo!.indexOf(searchText) > -1;
    }
}
