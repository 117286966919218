
import { Vue, Component, PropSync, Ref } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getAffiliateSearch } from "@/api/user";
import { translatePaymentType } from '@/utils/translate';
import { ICreatePayment } from "@/api/types/payout";
import paymentType from '@/mappings/payment-type';
@Component({
    components: { BaseAutocomplete, BaseCard },
})

export default class CreatePaymentHeader extends Vue {
    @PropSync('payment', { required: true }) paymentSync!: ICreatePayment;
    @Ref() input!: BaseAutocomplete[];

    paymentType = paymentType;
    defaultAffiliateValue = '';

    get getPaymentTypeItems(): any {
        return this.paymentType.map(i => {
            return { itemText: translatePaymentType(i), itemValue: i };
        });
    }

    get getHeaderInputs(): any {
        return [
            {
                text: 'Аккаунт списания',
                model: 'affiliate_id',
                defaultValue: this.defaultAffiliateValue,
                data: {
                    errorText: 'Вебмастера не найдены',
                    apiMethod: getAffiliateSearch,
                    itemValue: 'id',
                    template: (item) => `${item.first_name} ${item.last_name} ${item.id}`,
                    placeholder: 'Введите ID или имя партнёра',
                },
            },
            {
                text: 'Зачислить вебу',
                model: 'receiver_id',
                data: {
                    errorText: 'Вебмастера не найдены',
                    apiMethod: getAffiliateSearch,
                    itemValue: 'id',
                    template: (item) => `${item.first_name} ${item.last_name} ${item.id}`,
                    placeholder: 'Введите ID или имя партнёра',
                },
            },
            {
                text: 'Тип выплаты',
                model: 'payment_type',
                data: {
                    itemValue: 'itemValue',
                    itemText: 'itemText',
                    template: (item) => item.itemText,
                },
                items: this.getPaymentTypeItems,
            },
        ];
    }

    updatePayment(key: string, value: any): void {
        this.$set(this.paymentSync, key, value);
    }

    clearPaymentType(): void {
        this.input.forEach(i => i.clearModel());
    }
}
