
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { showServerError } from '@/utils';
import PopupUser from '@/components/layout/PopupUser.vue';
import SvgExpandMore from '@/assets/icons/expand-more.svg';

const auth = namespace('authModule');
@Component({
    components: {
        PopupUser,
        SvgExpandMore,
    },
})
export default class NavUser extends Vue {
    @Prop({ type: Boolean, default: true }) mini!: boolean;

    @auth.Action('AUTHORIZATION_LOGOUT_METHOD') logoutAuth;
    @auth.Getter('GET_USER') user;

    showPopupUser = false;

    service = {
        active: false,
    };

    services = [
        {
            title: 'Профиль',
            router: true,
            click: (): void => this.goToProfile(),
        },
        {
            title: 'Выход',
            click: (): void => this.logout(),
        },
    ];

    @Watch('$props.mini')
    toggleUserMenu(value: boolean): void {
        if (value) {
            this.service.active = false;
        } else if (this.isProfileRoute) {
            this.service.active = true;
        }
    }

    get userName(): string {
        return `${this.user.last_name} ${this.user.first_name}`;
    }

    get initials(): string {
        const { last_name, first_name } = this.user;
        return `${last_name.charAt(0).toUpperCase()}${first_name.charAt(0).toUpperCase()}`;
    }

    get isProfileRoute(): boolean {
        return this.$route.name === 'profile';
    }

    get isDesktop(): boolean {
        return this.$vuetify.breakpoint.lgAndUp;
    }

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.xs;
    }

    goToProfile(): void {
        this.$router.push({ name: 'profile' });
    }

    logout(): void {
        this.$socket.client.disconnect();
        this.logoutAuth()
            .then(() => this.$router.push('/sign-in'))
            .catch((err) => showServerError(err, 'Ошибка авторизации'));
    }
}
