
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgNotification from '@/assets/icons/nav-bar/notification.svg';
import SvgNotifications from '@/assets/icons/nav-bar/notifications.svg';
import PopupMenu from '@/components/layout/PopupMenu.vue';
import { getNotifications } from '@/api/notifications';
import { showServerError } from '@/utils';
import { DURATION_NOTIFICATION } from '@/configs/global';
import { Getter } from 'vuex-class';

@Component({
    components: {
        SvgNotification,
        SvgNotifications,
        PopupMenu,
    },
})
export default class NavNotifications extends Vue {
    @Prop({ type: Boolean, default: true }) mini!: boolean;

    @Getter('IS_DEV_MODE') isDevMode;

    menu = false;
    notifications: Array<{id: number, created_at: string, message: string, sender: object}> = [];
    timerId = 0;

    get classes(): {[key: string]: boolean} {
        return {
            'small': !this.isDesktop,
            'notifications_full': !this.$props.mini,
        };
    }

    get notificationsExist(): boolean {
        return this.notifications.length > 0;
    }

    get isDesktop(): boolean {
        return this.$vuetify.breakpoint.lgAndUp;
    }

    mounted(): void {
        // clearInterval(this.timerId);
        // this.updateNotifications();
        // this.getNewNotifications();
    }

    beforeDestroy(): void {
        clearInterval(this.timerId);
    }

    getNewNotifications(): void {
        this.timerId = window.setInterval(() => {
            if (!this.isDevMode) {
                this.updateNotifications();
            }
        }, DURATION_NOTIFICATION);
    }

    async updateNotifications(): Promise<void> {
        try {
            this.notifications = await getNotifications();
        } catch (err) {
            showServerError(err, 'Новые уведомления не получены');
        }
    }

    readNotifications(id: number): void {
        this.notifications = this.notifications.filter((item) => item.id !== id);
    }

}
