
import BaseCard from '@/components/base/BaseCard.vue';
import ManagerFieldKeys from '@/components/affiliate/affiliateMethods/ManagerFieldKeys.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IEmployeeAffiliateMethod } from '@/api/types/payout';
import { translatePaymentsRequisite } from '@/utils/translate';

@Component({
    components: {
        BaseCard,
        ManagerFieldKeys,
    },
})
export default class PayoutMethodRequisites extends Vue {
    @Prop( { required: true }) employeeAffiliateMethod!: IEmployeeAffiliateMethod;
    @Prop( { required: false }) isShowManagerFields!: boolean;

    translatePaymentsRequisite = translatePaymentsRequisite;

    get getManagerData(): object {
        return this.employeeAffiliateMethod?.manager_data || {};
    }

    get getFields(): any {
        return {
            ...this.employeeAffiliateMethod.partner_data,
            ...this.getManagerData,
        };
    }
}
