
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

interface ITdNumber {
    slot: string,
    key: string,
    sup?: string,
    format?: string,
    currency?: string,
    currencySymbol?: boolean,
    isMono?: boolean,
}

const catalogue = namespace('catalogueModule');

@Component({
    components: { },
})
export default class TdNumber extends Vue {
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencySymbol;
    @Prop({ required: true }) item!: Object | any;
    @Prop({ required: true }) number!: ITdNumber;
}
