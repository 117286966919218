
import { Component, Vue, Prop } from 'vue-property-decorator';
import Conditions from '@/components/uapi/conditions/List.vue';
import { ICondition } from '@/api/types/uapi';
import {
    addStreamCondition,
    editStreamCondition,
    getStreamsConditions,
    deleteCondition,
} from '@/api/uapi';
import { showServerError } from '@/utils';

@Component({
    components: {
        Conditions,
    },
})
export default class ConditionsStream extends Vue {
    @Prop({ type: Boolean, default: true }) canEdit!: boolean;

    conditions: ICondition[] = [];
    streamId: string = '';
    modal = false;
    loading = false;

    created(): void {
        this.streamId = this.$route.params.streamId;
        this.getStreamConditions();
    }

    async getStreamConditions(): Promise<void> {
        this.loading = true;
        try {
            const { data } = await getStreamsConditions(this.streamId);
            this.conditions = data;
        } catch (err) {
            showServerError(err, 'Не удалось загрузить список условий');
        } finally {
            this.loading = false;
        }
    }

    async saveCondition(condition: ICondition): Promise<void> {
        this.loading = true;
        try {
            if (condition.id) {
                await editStreamCondition(this.streamId, condition.id, condition);
            } else {
                await addStreamCondition(this.streamId, condition);
            }
            this.modal = false;
            await this.getStreamConditions();
        } catch (err) {
            this.modal = false;
            showServerError(err);
        } finally {
            this.loading = false;
        }
    }

    async deleteCondition(conditionId: number): Promise<void> {
        try {
            await deleteCondition(conditionId);
            await this.getStreamConditions();
        } catch (err) {
            showServerError(err, 'Не удалось удалить условие');
        }
    }

    pushToCreateRouter(): void {
        this.$router.push({ name: 'create-stream-condition', params: { streamId:  this.streamId } });
    }

    pushToEditRouter(conditionId: string): void {
        const { streamId } = this.$route.params;
        this.$router.push({ name: 'edit-stream-condition', params: { streamId, conditionId } });
    }
}
