
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import Status from "@/components/base/Status.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { showServerError } from "@/utils";
import Editor from '@crm-ui/ckeditor';

@Component({
    components: {
        TrashBtn,
        Status,
        ckeditor: CKEditor.component,
    },
})

export default class NotificationEditor extends Vue {
    @Prop({ required: true }) label!: string;
    @Prop({ default: 'Введите текст уведомления ...' }) placeholder!: string;
    @Prop({ default: false }) isActive!: boolean;
    @PropSync('value', { required: true }) editorData!: HTMLElement | string;

    isEditorActive = false;
    editor = Editor;

    get config():any {
        return {
            placeholder: this.placeholder,
            language: 'ru',
        };
    }

    toggleEditorActive(): void {
        if (!this.isEditorActive) {
            this.clearEditor();
        }
    }

    clearEditor(): void {
        this.editorData = '';
    }

    created(): void {
        this.isEditorActive = this.isActive;
    }

    mounted(): void {
        window.addEventListener('show-error-notification', (({ detail } : CustomEvent) => {
            showServerError(detail.data, detail.error);
        }) as EventListener);
    }
}
