
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import FormModal from '@/components/base/FormModal.vue';
import Multiselect from '@/components/base/form/Multiselect.vue';
import {
    IFiles,
    IOfferService,
} from '@/api/types/revenue';
import { getNumberOfLines } from '@/utils';
import { MULTISELECT_TYPE } from '@/mappings/multiSelectDataType';

@Component({
    components: {
        FormModal,
        Multiselect,
    },
})

export default class StatTaskFileItem extends Vue {
    @Prop() item!: IFiles;
    @Prop() possibleItemsForSelect!: IOfferService[];
    @Prop({ type: String, default: 'id' }) itemValue!: string;

    @Getter('GET_EDITING_MODE') editingMode;

    getNumberOfLines = getNumberOfLines;
    MULTISELECT_TYPE = MULTISELECT_TYPE.STATSTASK;
    comment = { text: '' };

    get multiSelectLabel(): string {
        return this.item.offer_services!.length > 0 ? '' : 'Выбрать услугу + цель';
    }

    getTemplate(item: IOfferService): string {
        const {
            offer_name = '', offer_id = '', goal_name = '', goal_id = '', offer_rate = '',
        } = item;
        return `${offer_name} (${offer_id}) -
            ${goal_name} (${goal_id}) ${offer_rate ? `: ${offer_rate}` : offer_rate}`;
    }

    addComment(): void {
        this.item.comments![0] = this.comment;
    }

    changeSelect(items: IOfferService[]): void {
        this.item.offer_services = items;
        // формируем возможный массив для селекта через id офферов в таблице
        let newItem: IOfferService | undefined = {};
        newItem[this.$props.itemValue] = '';
        let arr: IOfferService[] = this.possibleItemsForSelect;
        this.possibleItemsForSelect.forEach((item: IOfferService) => {
            if (items.find((offer) => offer[this.$props.itemValue] === item[this.$props.itemValue])) {
                newItem = items.find((offer) => offer[this.$props.itemValue] === item[this.$props.itemValue]);
                arr = arr.filter((arrItem) => arrItem[this.$props.itemValue] !== newItem![this.$props.itemValue]);
            }
        });

        this.$emit('change', arr);
    }

    remove(offer: IOfferService): void {
        const index = this.item.offer_services!.indexOf(offer);
        this.item.offer_services!.splice(index, 1);
        this.possibleItemsForSelect.push(offer);
    }

    deleteFile(): void {
        this.$emit('delete');
    }
}
