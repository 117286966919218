var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',[_c('ul',{staticClass:"base-list pl-0"},_vm._l((_vm.managerFieldsKeys),function(value,name){return _c('li',{staticClass:"base-list__item"},[_c('p',{staticClass:"base-list__text text-left"},[_vm._v(" "+_vm._s(_vm.translatePaymentsRequisite(name))+": ")]),(name !== 'legal_id')?_c('v-text-field',{staticClass:"base-list__value base__value--full-width",attrs:{"label":""},on:{"change":function($event){return _vm.$emit('change', _vm.managerFieldsKeys )}},model:{value:(_vm.managerFieldsKeys[name]),callback:function ($$v) {_vm.$set(_vm.managerFieldsKeys, name, $$v)},expression:"managerFieldsKeys[name]"}}):_c('base-autocomplete',{attrs:{"data":{
                    errorText: 'Вебмастера не найдены',
                    apiMethod: _vm.searchWebmasters,
                    itemValue: 'guid',
                    itemText: 'name',
                    template: (item) => item.name,
                    placeholder: 'Введите id или название организации',
                    rules: [(v) => !!v || 'Поле обязательно для заполнения'],
                    clearable: true,
                }},on:{"change":function($event){return _vm.changeLegalId(name, $event)}},model:{value:(_vm.managerFieldsKeys[name]),callback:function ($$v) {_vm.$set(_vm.managerFieldsKeys, name, $$v)},expression:"managerFieldsKeys[name]"}})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }