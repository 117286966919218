var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"checkboxes"},[(_vm.title)?_c('div',{staticClass:"checkboxes__title"},[_c('h3',{staticClass:"pb-2 pt-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isTrashBtn && !_vm.isMobile)?_c('div',{staticClass:"checkboxes__trash"},[_c('trash-btn',{attrs:{"title":_vm.trashText,"is-icon":false,"is-grey":true},on:{"clear":_vm.clearSelectedItems}})],1):_vm._e()]):_vm._e(),_c('ul',{staticClass:"checkboxes__column-list"},[_vm._l((_vm.checkboxes),function(column,index){return _c('li',{key:index,staticClass:"checkboxes__column-item",class:[{'d-none': column.items.length <= 0},
                     {'--is-scrollable': column.isScrollable},
                     {'--is-multiselect': column.isMultiselect},
                     column.colClass]},[(!_vm.isHideColTitle)?_c('h4',{staticClass:"no-wrap",class:{'checkboxes__column-title': _vm.isTitleCheckbox && column.hasOwnProperty('value') && column.items.length > 0}},[(_vm.isTitleCheckbox && column.hasOwnProperty('value') && column.items.length > 0 )?_c('v-checkbox',{staticClass:"table-filter__checkbox checkboxes__checkbox checkboxes__checkbox--parent font-weight-light no-wrap mt-0 pt-0",attrs:{"label":"","color":"white","off-icon":"","on-icon":"done","hide-details":""},on:{"change":function($event){return _vm.toggleSectionCheckboxes(column)}},model:{value:(column.value),callback:function ($$v) {_vm.$set(column, "value", $$v)},expression:"column.value"}}):_vm._e(),(column.items.length > 0)?_c('span',[_vm._v(_vm._s(column.name))]):_vm._e()],1):_vm._e(),(column.isMultiselect)?_c('multiselect-design',{key:column.key,staticClass:"checkboxes__multiselect-item",attrs:{"multiselect-data":{
                    value: _vm.getSelect(column),
                    id: column.key,
                    items: column.items,
                    label: column.name,
                    itemValue: 'value',
                    itemText: 'title',
                    isNoToggle: true,
                    template: (item) => {return item.title},
                }},on:{"change-multiselect-items":_vm.changeSelect}}):_c('div',_vm._l((column.items),function(item){return _c('v-checkbox',{key:`${item.title}_${item.value}`,staticClass:"table-filter__checkbox checkboxes__checkbox font-weight-light ml-0 no-wrap mt-0",attrs:{"label":item.title,"type":"checkbox","value":item.value,"ripple":false,"hide-details":"","disabled":item.disabled || false,"color":"white","off-icon":"","on-icon":"done"},on:{"change":function($event){return _vm.toggleTitleCheckbox(column)}},model:{value:(_vm.selectedSync),callback:function ($$v) {_vm.selectedSync=$$v},expression:"selectedSync"}})}),1)],1)}),_c('li',{staticClass:"checkboxes__custom-item checkboxes__custom-item--custom"},[_vm._t("default")],2)],2),((_vm.isTrashBtn && _vm.isMobile) || _vm.isTrashBtnImportant)?_c('div',{staticClass:"checkboxes__trash"},[_c('trash-btn',{attrs:{"title":_vm.trashText,"is-icon":false,"is-grey":true},on:{"clear":_vm.clearSelectedItems}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }