
import { Component, Vue } from 'vue-property-decorator';
import SvgOfferList from '@/assets/icons/nav-bar/offer-list.svg';
import { IColumnFilter } from '@/types';
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { getAllStatTasks } from "@/api/revenue";
import { StatTasksColumns, StatTasksFilters } from "@/services/TablePage/statTasks";
import TasksTable from "@/components/revenue/statTask/TasksTable.vue";

@Component({
    components: {
        TablePageWrapper,
        SvgOfferList,
    },
})
export default class StatTasks extends Vue {
    columns: IColumnFilter[] = new StatTasksColumns().columns;
    filters: StatTasksFilters = new StatTasksFilters();

    invoicesTable = {
        component: TasksTable,
        apiMethod: getAllStatTasks,
    };

    createForm = {
        route: 'stat-task-creation',
    };
}
