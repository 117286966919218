
import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
import { VForm } from 'vuetify/lib';
import { getAffiliateSearch } from '@/api/user';
import FormModal from '@/components/base/FormModal.vue';
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import { showServerError } from '@/utils';
import Loader from '@/components/base/Loader.vue';
import DateTimePicker from '@/components/base/pickers/DateTimePickerNew.vue';
import TrashBtn from '@/components/base/buttons/TrashBtn.vue';
import SvgExpandMore from '@/assets/icons/expand-more.svg';
import AutocompleteListItem from "@/components/base/form/AutocompleteListItem.vue";
import { NotificationMessage } from "@/utils/notification";
import { getEmployeeMethods, postAffiliateMethod } from '@/api/payout';
import { translatePaymentsRequisite } from '@/utils/translate';
import MultiselectDesign from '@/components/base/form/MultiselectDesign.vue';
import SelectDesign from '@/components/base/form/SelectDesign.vue';
import { IEmployeeMethod } from '@/api/types/payout';
import { searchWebmasters } from '@/api/org-cabinets-backend';
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";

interface IRules {
    [key: string]: {
        [key: string]: ((v: string) => boolean | string)[];
    };
}

@Component({
    components: {
        BaseSelect,
        AutocompleteListItem,
        BaseAutocomplete,
        FormModal,
        Loader,
        DateTimePicker,
        TrashBtn,
        SvgExpandMore,
        MultiselectDesign,
        SelectDesign,
        DateTimePickerNew,
    },
})
export default class FormMethod extends Vue {
    @Prop() defaultAffiliate!: string;
    @PropSync('showModal') showModalSync!: boolean;

    @Ref() affiliateInput!: BaseAutocomplete;
    @Ref() form!: typeof VForm;

    getAffiliateSearch = getAffiliateSearch;
    searchWebmasters = searchWebmasters;

    loading = false;
    offer: string | number = '';
    methods: IEmployeeMethod[] = [];
    affiliates: number[] = [];
    method = '';
    fields: any[] = [];
    isMakeDefault = false;
    valid = false;

    mounted(): void {
        if (!!this.defaultAffiliate) {
            this.affiliates.push(+this.defaultAffiliate);
        }
    }

    get isDisabledSaveBtn(): boolean {
        return !this.valid;
    }

    get currentMethod(): IEmployeeMethod | undefined {
        return this.methods.find((m) => m.id === this.method);
    }

    rules(): IRules {
        const required = (v: string): boolean | string => !!v || 'Поле обязательно для заполнения';
        const onlyLetters = (v: string): boolean | string => /^\p{Letter}+$/u.test(v) || 'Поле должно состоять из букв';
        const cardNumber = (v: string): boolean | string => /^\d{14,18}$/.test(v) || 'Поле должно состоять из 14-18 цифр';
        const dateDDMMYYYY = (v: string): boolean | string => /^\d{2}-\d{2}-\d{4}$/.test(v) || 'Введите дату в формате ДД-ММ-ГГГГ';
        const onlyEmail = (v: string): boolean | string => /^.*@.*\..*$/.test(v) || 'Поле должно содержать корректный email';

        const rules = {
            adsfin_v1: {
                account_id: [],
            },
            bank_card_europe_v1: {
                card_number: [cardNumber],
                card_expiration: [
                    (v: string): boolean | string => /^\d{2}\/\d{2}$/.test(v) || 'Введите дату в формате ММ/ГГ',
                ],
                cardholder_name: [onlyLetters],
                cardholder_lastname: [onlyLetters],
                cardholder_birthday: [dateDDMMYYYY],
                cardholder_address: [],
                cardholder_city: [],
                cardholder_country: [],
            },
            bank_card_ru_v1: {
                card_number: [cardNumber],
            },
            bank_card_ua_v1: {
                card_number: [cardNumber],
            },
            capitalist_kz_v1: {
                account_id: [],
            },
            capitalist_v1: {
                account_id: [],
            },
            contract_eur_individual_v1: {
                address: [],
                code: [],
                IBAN: [],
                SWIFT: [],
            },
            contract_eur_legal_v1: {
                name: [],
                vat: [],
                address: [],
                reg_number: [],
                IBAN: [],
                SWIFT: [],
            },
            contract_v1: {
                legal_id: [],
            },
            custom_v1: {},
            dmp_one_v1: {
                email: [onlyEmail],
            },
            facebook_cabinet_v1: {
                cabinet_id: [],
            },
            getuniq_v1: {
                cabinet_id: [],
            },
            google_cabinet_v1: {
                cabinet_id: [],
            },
            mytarget_cabinet_v1: {
                cabinet_id: [],
            },
            payoneer_v1: {
                IBAN: [],
            },
            paypal_v1: {
                email: [onlyEmail],
            },
            qiwi_v1: {
                account_id: [],
            },
            tether_v1: {
                account_id: [],
            },
            traffic_bank_v1: {
                cabinet_id: [],
            },
            transfer_v1: {
                IBAN: [],
            },
            vk_cabinet_v1: {
                cabinet_id: [],
            },
            webmoney_v1: {
                account_id: [
                    (v: string): boolean | string => /^[a-zA-Z]\d{12}$/.test(v) || 'Поле должно состоять из латинской буквы и 12 цифр',
                ],
            },
            yandex_cabinet_v1: {
                cabinet_id: [],
            },
            yandex_v1: {
                account_id: [],
            },
            yoomoney_v1: {
                account_id: [],
            },
            zaleycash_cabinet_v1: {
                cabinet_id: [],
            },
            zvonobot_v1: {
                account_id: [],
            },
            zvonok_v1: {
                cabinet_id: [],
            },
        };

        for (const method of Object.keys(rules)) {
            for (const rule of Object.keys(rules[method])) {
                rules[method][rule].unshift(required);
            }
        }

        return rules;
    }

    placeholders = {
        bank_card_europe_v1: {
            card_expiration: '00/00',
        },
    };
    masks = {
        bank_card_europe_v1: {
            card_expiration: '##/##',
        },
    };

    getPaymentMethodsTemplate(item: any): string {
        return item.name.ru;
    }

    getAffiliateSearchTemplate(item: any): string {
        return `${item.id} ${item.first_name} ${item.last_name}`;
    }

    makeCurrentFields(): void {
        if (!this.method) {
            this.fields = [];
            return;
        }

        if (this.currentMethod) {
            const fields = [
                ...this.currentMethod?.partner_fields_keys,
                ...this.currentMethod?.manager_fields_keys,
            ];
            this.fields = fields.map((f: string) => ({
                id: f,
                name: translatePaymentsRequisite(f, this.currentMethod?.id),
                value: '',
                rules: this.rules()[this.method][f],
                placeholder: this.placeholders[this.method]?.[f],
                mask: this.masks[this.method]?.[f],
            }));
        } else {
            this.fields = [];
        }
    }

    closeModal(): void {
        this.clearInputs();
        this.showModalSync = false;
    }

    updateAffiliates(affiliates: number[]): void {
        this.affiliates = affiliates;
    }

    updateMethod(method: string): void {
        this.method = method;
        this.makeCurrentFields();
    }

    clearInputs(): void {
        this.affiliateInput.clearModel();
        this.affiliates = [];
        this.method = '';
        this.fields = [];
        this.isMakeDefault = false;
    }

    getFieldsByFieldsKeys(key: string): { [key: string]: string } {
        return Object.fromEntries(
            this.fields
                .filter((f) => this.currentMethod?.[key]?.includes(f.id))
                .map((f) => {
                    if (f.id === 'card_expiration') {
                        // 02/30 => 2030-02
                        return [f.id, `20${f.value.split('/')[1]}-${f.value.split('/')[0]}`];
                    }
                    return [f.id, f.value];
                }),
        );
    }

    async createRequestMethod(): Promise<void> {
        // @ts-ignore
        this.$refs.form.validate();
        if (!this.valid) return;

        this.loading = true;
        try {
            const data = {
                partner_id: this.affiliates,
                method_id: this.method,
                partner_data: this.getFieldsByFieldsKeys('partner_fields_keys'),
                manager_data: this.getFieldsByFieldsKeys('manager_fields_keys'),
                is_default: this.isMakeDefault,
            };

            await postAffiliateMethod(data);

            await this.$emit('edit');
            this.showNotification();
            this.clearInputs();
            this.closeModal();
        } catch (err) {
            showServerError(err, 'Способ выплат не создан');
        } finally {
            this.loading = false;
        }
    }

    showNotification(): void {
        const notificationText = `Способ выплат успешно создан`;
        new NotificationMessage(notificationText, 'success' );
    }

    async created(): Promise<void> {
        try {
            const { data } = await getEmployeeMethods({ is_active: 1 });
            this.methods = data;
        } catch (err) {
            showServerError(err, 'Список методов не загружен');
        }
    }
}
