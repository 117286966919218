
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import SvgUpload from "@/assets/icons/upload.svg";
import SvgReload from "@/assets/icons/reload-double.svg";
import SvgNoImage from "@/assets/icons/no-image.svg";
import { eventBus } from "@/eventbus";

@Component({
    components: {
        SvgUpload,SvgReload,SvgNoImage,
    },
})

export default class ImageInput extends Vue {
    @Prop({ required: true }) item!: any;
    @Prop({ default: true }) isEdit!: boolean;
    @Prop({ default: false }) isContain!: boolean;
    @Prop({ default: false }) isRound!: boolean;
    @Prop({ default: true }) isPreview!: boolean;
    @Prop({ default: false }) isLeftImage!: boolean;
    @Prop({ default: true }) isShowNoImageText!: boolean;
    @Prop({ default: 'отсутствует' }) noImageText!: string;
    @Prop({ default: 'Заменить' }) changeBtnText!: string;
    @Prop({ default: 'Добавить' }) addBtnText!: string;
    @Prop({ default: false }) isShowTooltip!: boolean;
    @Prop({ default: false }) isValidateAbsolute!: boolean;
    @Prop({ default: () => (['png']) }) allowTypes!: string[];
    @Prop({ default: false }) isWithoutTitle!: boolean;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop({ default: false }) isBorderless!: boolean;
    @Prop({ default: false }) isBigPlaceholder!: boolean;
    @Prop({ default: false }) isFullSize!: boolean;
    @Prop({ default: 5000000 }) maxSize!: number;
    @Prop({ default: 114 }) maxWidth!: number;
    @PropSync('isValid', { default: false }) isValidSync!: boolean;

    private _uid: any;
    file: File | null  = null;
    isShowNotify = false;

    get urlImg(): any {
        if (!this.file) return this.item;

        return URL.createObjectURL(this.file);
    }

    get isNoImage(): boolean {
        return !this.file && !this.item;
    }
    get titleBtn(): string {
        return this.isWithoutTitle ? '' : this.file || this.item ? this.changeBtnText : this.addBtnText;
    }

    get fileType(): string {
        if (this.file?.type === 'image/svg+xml') return 'svg';
        return this.file ? this.file.type?.split('/')?.[1] : '';
    }

    get isValidType(): boolean {
        return this.allowTypes.includes(this.fileType);
    }

    get isValidSize(): boolean {
        return  this.file!.size <= this.maxSize;
    }

    chooseImage(): void {
        document!.getElementById(`uploader-${this._uid}`)!.click();
    }

    deleteImage(): void {
        this.file = null;
    }

    showNotification(): void {
        this.isValidSync = false;
        this.isShowNotify = true;
        setTimeout(() => {
            this.isShowNotify = false;
            this.deleteImage();
            this.isValidSync = true;
        }, 3000);
    }

    onSelectFile(e: any): void {
        if (!this.isValidType || !this.isValidSize) {
            this.showNotification();
            return;
        }
        this.isShowNotify = false;
        this.$emit('upload', e);
    }

    // очищение выбранного файла из вне
    created(): void {
        eventBus.$on('clear-image-input', this.deleteImage);
        if (this.item instanceof File) {
            this.file = this.item;
        }
    }

    beforeDestroy(): void {
        eventBus.$off('clear-image-input', this.deleteImage);
    }
}
