
import { Vue, Component, Prop } from "vue-property-decorator";
import { OfferSingle } from "@/services/offers/OfferSingle";
import { getOfferParams } from "@/api/offers";
import { showServerError } from "@/utils";
import OfferParamsForm from "@/components/offers/offer/products/OfferParamsForm.vue";
import intersection from "lodash-es/intersection";
import { OFFER_PRODUCT_PARAMS } from "@/mappings/offer";
import Loader from "@/components/base/Loader.vue";

@Component({
    components: { Loader, OfferParamsForm },
})

export default class OfferProducts extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    products: any[] = [];
    isShowForm = false;

    async getOfferParams(): Promise<void> {
        this.isShowForm = true;
        try {
            const { data } = await getOfferParams(this.offer.id as number);
            this.products = data;
        } catch (err) {
            showServerError(err, 'Параметры оффера не загружены');
        } finally {
            this.isShowForm = false;
        }
    }

    get availableProducts(): any {
        const offerProductIds = this.offer?.products?.map(i=>i.id) || [];
        const intersectionArray = intersection(Object.values(OFFER_PRODUCT_PARAMS),offerProductIds);
        return intersectionArray.map(i => Number(i));
    }

    mounted():void {
        this.getOfferParams();
    }
}
