
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAdvertiser, IInvoice } from '@/api/types/revenue';

@Component({
    components: {},
})

export default class HeaderError extends Vue {
    @Prop({ required: true }) currentAdvertiser!: IAdvertiser;
    @Prop({ required: true }) invoice!: IInvoice;
    @Prop({ required: true })isCanEdit!: boolean;
    @Prop({ required: true }) currencyItems!: any;

    get isAdvertiserContractors(): boolean | void {
        if (this.currentAdvertiser instanceof Object) {
            if (Object.keys(this.currentAdvertiser).length !== 0 && this.currentAdvertiser.contractors) {
                return this.currentAdvertiser.contractors!.length <= 0;
            }
        }
    }

    get isErrorAnotherAdvertiser(): boolean {
        return !this.isCanEdit
                && (!!this.currentAdvertiser.account_manager
                        || !!(this.$props.invoice.advertiser && this.$props.invoice.advertiser.account_manager));
    }
}
