
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import BaseCard from "@/components/base/BaseCard.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import {
    translateOffersStatus,
    translatePartnerStatus,
} from "@/utils/translate";
import { getActiveUserSearch } from "@/api/user";
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import { IFilter, ITableStatus, TableHeader } from "@/types";
import { affiliateTableDetail, offersTableDetail } from "@/services/TablePage/notifications";
import { IOffer } from "@/api/types/offers";
import { affiliateStatuses, offerStatuses } from "@/mappings/statuses";
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        SelectDesign,
        TableWithFrontPaginationDesign,
        BaseCard, BaseAutocomplete,
    },
})

export default class NotificationTable extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;

    filterManager = '';
    status = '';
    getActiveUserSearch = getActiveUserSearch;

    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.legacy_id',
            routerName: 'offer',
            text: 'legacy_id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'account_id',
            slot: 'item.account_id',
            routerName: 'affiliate',
            text: 'account_id',
        },
    ];
    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: this.notification?.isOffer ? translateOffersStatus : translatePartnerStatus,
        },
    ];
    uniqueData = [
        {
            slot: 'item.manager',
            template: (item: any): string => this.getManager(item),
        },
        {
            slot: 'item.name',
            template: (item: any) : string => `${item!.first_name || item.name} ${item!.last_name || ''}`,
        },
        {
            slot: 'item.advertiser',
            template: (item: IOffer): string =>  item.advertiser ? `${item.advertiser.name || item.advertiser}` : '',
        },
    ];

    getManager(item: any): string {
        if (item.manager) {
            return typeof item.manager === 'string' ? item.manager : `${item.manager.first_name || item.manager.name} ${item.manager.last_name || ''}`;
        } else {
            return '';
        }
    }

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            disabled: (item: any): boolean => {
                return this.notification?.isDisabledEditField && !item.id;
            },
            callback: ({ id }: { id: number }): void => {
                this.deleteItem(id);
            },
        },
    ];

    get filterStatus(): IFilter {
        return new filterClass.Select({
            id: 'status',
            label: 'Статус',
            itemValue: 'value',
            itemText: 'name',
            items: this.notification?.isOffer ? offerStatuses : affiliateStatuses,
            select: this.status,
            callback: (e) => this.status = e.select,
            clearable: true,
            remove: () => this.status = '',
            translate: this.notification?.isOffer ?
                (i) => translateOffersStatus(i).text :
                (i) => translatePartnerStatus(i).text,
        });
    }

    get tableHeaders(): TableHeader[] {
        return this.notification?.isOffer ? offersTableDetail : affiliateTableDetail;
    }

    get tableTitle(): string {
        return this.notification?.isOffer ? 'Добавить офферы' : 'Добавить партнёров';
    }

    get items(): any {
        return this.notification?.isOffer ? this.notification?.offers || [] : this.notification?.affiliates || [];
    }

    get filteredItems(): any {
        return this.items.filter(i => (this.status ? i.status === this.status : true)
            && (this.filterManager ? i.manager!.account_id === this.filterManager : true));
    }

    deleteItem(id: number): void {
        this.notification?.isOffer ? this.notification?.deleteOffer(id) : this.notification?.deleteAffiliate(id);
    }

    changeHandler(data: any): void {
        this.filterManager = data;
    }
}
