
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import {
    getStreamsList,
    plugStreamEndpoint,
    unplugStreamEndpoint,
} from '@/api/uapi';
import { IStream } from '@/api/types/uapi';
import AppTable from '@/components/base/table/AppTable.vue';
import { showServerError } from '@/utils';
import { MAX_LIMIT_FOR_SELECT, SEARCH_VALUE_DEBOUNCE } from '@/configs/global';

@Component({
    components: {
        AppTable,
    },
})
export default class StreamsEndpoint extends Vue {
    @Prop({ type: Number, required: true }) endpointId!: number;
    @Prop({ type: Boolean, default: true }) canEdit!: boolean;
    @Prop({ type: Array, default: () => [] }) streams! :IStream[];

    searchStreamTimerId = 0;
    newStreamIds = [];
    streamAutocompleteValue = '';
    possibleStreams: Array<{[key: string]: string | number}> = [];
    autocompleteLoading = false;
    streamsTableLoading = false;

    get headers(): DataTableHeader[] {
        const items: DataTableHeader[] = [
            { text: 'ID', value: 'id' },
            { text: 'Название потока', value: 'name' },
            { text: 'Лимит', value: 'quota' },
        ];
        if (this.$props.canEdit) {
            items.push({
                text: '',
                value: 'actions',
            });
        }
        return items;
    }

    actions = [
        {
            title: 'Удалить',
            color: 'error',
            icon: 'delete',
            callback: (item: IStream): void => {
                this.unplugStream(item);
            },
        },
    ];

    get streamsItems(): Array<{[key: string]: string | number}> {
        return this.possibleStreams.filter((item) => {
            if (
                !this.$props.streams
                || !this.$props.streams.find((stream) => stream.id === item.id)
            ) {
                return item;
            }
        });
    }

    debounceQueryStream(): void {
        if (this.streamAutocompleteValue) {
            clearTimeout(this.searchStreamTimerId);
            this.searchStreamTimerId = window.setTimeout(this.getStreamsList, SEARCH_VALUE_DEBOUNCE);
        }
    }

    async getStreamsList(): Promise<void> {
        this.autocompleteLoading = true;
        try {
            const { data } = await getStreamsList({ query: this.streamAutocompleteValue, limit: MAX_LIMIT_FOR_SELECT });
            data.forEach(({ id, name }) => {
                this.possibleStreams.push({
                    id: id!,
                    name: `${id} ${name}`,
                });
            });
        } catch (err) {
            showServerError(err);
        }
        this.autocompleteLoading = false;
    }

    async addStreamEndpoint(): Promise<void> {
        try {
            await plugStreamEndpoint(this.$props.endpointId, { stream: this.newStreamIds });
            await this.getUpdatedEndpoint();
        } catch (err) {
            showServerError(err);
        }
    }

    async unplugStream(item: IStream): Promise<void> {
        try {
            await unplugStreamEndpoint(this.$props.endpointId, { stream: [item.id!] });
            await this.getUpdatedEndpoint();
        } catch (err) {
            showServerError(err);
        }
    }

    async getUpdatedEndpoint(): Promise<void> {
        this.streamsTableLoading = true;
        try {
            this.$emit('get-endpoint');
            this.streamAutocompleteValue = '';
            this.newStreamIds = [];
        } catch (err) {
            showServerError(err);
        }
        this.streamsTableLoading = false;
    }
}
