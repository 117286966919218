
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
    createSubstitution,
    getSubstitutionById,
    patchSubstitutionById,
    getAffiliatesList,
    getOffersList,
    getApplicationsFields,
} from '@/api/uapi';
import StatsCard from '@/components/base/StatsCard.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { IServerResponse } from '@/api/types';
import {
    ISubstitutionItem,
    IAffiliate,
    IOffer,
} from '@/api/types/uapi';
import { showServerError } from '@/utils';
import { MAX_LIMIT_FOR_SELECT, SEARCH_VALUE_DEBOUNCE } from '@/configs/global';

interface IAutocompleteField {
    id: string;
    name: string;
    autocompleteValue: string;
    items: Array<{[key: string]: string | number}>;
    loading: boolean;
    itemValue: string;
    apiMethod: (params: {query: string, limit: number}) =>
        Promise<IServerResponse<IOffer[]>> | Promise<IServerResponse<IAffiliate[]>>;
}

@Component({
    components: {
        StatsCard,
        SvgApplication,
    },
})
export default class CreateSubstitution extends Vue {
    @Prop({ type: Boolean, default: false }) isStatTask!: boolean;

    affiliates = [];
    integerType = 'absolute';
    // for autocomplete
    searchTimerId = 0;
    autocompleteFields: IAutocompleteField[] = [
        {
            id: 'offer_id',
            name: 'Оффер',
            autocompleteValue: '',
            items: [],
            loading: false,
            itemValue: 'offer_name',
            apiMethod: getOffersList as () => Promise<IServerResponse<IOffer[]>>,
        },
        {
            id: 'affiliate_id',
            name: 'Партнёр',
            autocompleteValue: '',
            items: [],
            loading: false,
            itemValue: 'name',
            apiMethod: getAffiliatesList as () => Promise<IServerResponse<IAffiliate[]>>,
        },
    ];

    substitution: ISubstitutionItem = {
        offer_id: 0,
        affiliate_id: 0,
        substitute_type: '',
        parameters: {},
    };
    applicationFields: Array<{field: string; name: string}> = [];

    fixedTypes = [
        { value: 'fixed', text: 'Фиксированное значение' },
        { value: 'adjust', text: 'Корректировка суммы\\срока' },
    ];

    adjustParameters = [
        { value: 'term.amount', text: 'Сумма' },
        { value: 'term.term', text: 'Срок' },
    ];

    valueTypes = [
        { value: 'absolute', text: 'Абсолютное' },
        { value: 'relative', text: 'Относительное' },
        { value: 'fixed', text: 'Фиксированное' },
    ];

    get showSaveBtn(): boolean {
        return !!(this.substitution.offer_id && this.substitution.substitute_type);
    }

    get isDisabled(): boolean {
        if (this.substitution.substitute_type === 'fixed') {
            return !this.substitution.parameters.field || !this.substitution.parameters.value;
        } if (this.substitution.substitute_type === 'adjust') {
            return !this.substitution.parameters.field
                    || (!this.substitution.parameters.min || !this.substitution.parameters.max);
        }
        return true;
    }

    created(): void {
        if (this.$route.params.id) {
            this.getSubstitutionById();
        }
        this.getApplicationsFields();
    }

    async getApplicationsFields(): Promise<void> {
        try {
            const { data } = await getApplicationsFields('substitutions');
            this.applicationFields = data;
        } catch (err) {
            showServerError(err);
        }
    }

    changeParameter(): void {
        this.$set(this.substitution, 'parameters', { ...this.substitution.parameters });
    }

    clearParameters(): void {
        if (!Object.keys(this.substitution.parameters).length) {
            return;
        }
        this.substitution.parameters = {
            field: '',
        };
        this.integerType = '';
    }

    async getSubstitutionById(): Promise<void> {
        try {
            const data = await getSubstitutionById(Number(this.$route.params.id));
            // TODO сейчас с бека приходит массив с объектом
            // нужно будет исправить
            this.substitution = data[0];

            this.substitution.parameters = JSON.parse(data[0].parameters);

            this.autocompleteFields.forEach((field) => {
                field.autocompleteValue = String(this.substitution[field.id]);
                this.getDataForSelect(field);
            });

            if (this.substitution.substitute_type === 'adjust') {
                this.integerType = String(this.substitution.parameters.min).startsWith('%') ? 'relative' : 'absolute';
                this.substitution.parameters.min = this.getValueViaType(this.substitution.parameters.min);
                this.substitution.parameters.max = this.getValueViaType(this.substitution.parameters.max);
            }
            if (this.substitution.substitute_type === 'fixed_range') {
                this.integerType = 'fixed';
                this.substitution.substitute_type = 'adjust';
                this.substitution.parameters.min = this.getValueViaType(this.substitution.parameters.min);
                this.substitution.parameters.max = this.getValueViaType(this.substitution.parameters.max);
            }
        } catch (err) {
            showServerError(err);
        }
    }

    getValueViaType(value: string | number): string | number {
        // если value type === relative, то
        // value приходит в формате `%#.##`, где # - цифры
        // нам нужно отбросить "%" и вернуть значение, помноженное на 100
        if (typeof value === 'string' && value.startsWith('%')) {
            const num = Number(value.slice(1)) * 100;
            return isNaN(num) ? value : num;
        }
        return value;
    }

    async createSubstitution(): Promise<void> {
        const json = { ...this.substitution };
        delete json.offer_name;
        if (this.substitution.substitute_type === 'adjust') {
            // если выбран относительный тип значения, то отправвлять мы должны
            // прямой множитель в формате `%###`, где # - цифры
            if (this.integerType === 'relative') {
                json.parameters.min = `%${+this.substitution.parameters.min! / 100}`;
                json.parameters.max = `%${+this.substitution.parameters.max! / 100}`;
                delete json.parameters?.value;
            } else {
                delete json.parameters?.value;
            }
        } else {
            // если у нас выбран тип подстановки "фиксированное значение",
            // то название поля нужно отправлять в snake_case
            this.substitution.parameters.field = this.substitution.parameters.field!.split(' ').join('_');
        }
        if (this.integerType === 'fixed') {
            json.substitute_type = 'fixed_range';
        }
        // параметры приходят и уходят в формате json
        json.parameters = JSON.stringify(this.substitution.parameters);

        try {
            if (this.$route.params.id) {
                await patchSubstitutionById(Number(this.$route.params.id), json);
            } else {
                await createSubstitution(json);
            }
            await this.$router.push({ name: 'substitution' });
        } catch (err) {
            showServerError(err);
        }
    }

    debounceQuerySelections(field: IAutocompleteField): void {
        if (field.autocompleteValue) {
            clearTimeout(this.searchTimerId);
            this.searchTimerId = window.setTimeout(() => {
                this.getDataForSelect(field);
            }, SEARCH_VALUE_DEBOUNCE); /* 850ms задержка */
        }
    }

    async getDataForSelect(field: IAutocompleteField): Promise<void> {
        field.loading = true;
        try {
            if (!field.items) {
                this.$set(field, 'items', []);
            }

            const { data: result } = await field.apiMethod({ query: field.autocompleteValue, limit: MAX_LIMIT_FOR_SELECT });

            result.forEach((item) => {
                field.items.push({
                    id: item.id,
                    [field.itemValue]: `${item.id} ${item[field.itemValue]}`,
                });
            });
        } catch (err) {
            showServerError(err, `Список ${field.name}ов не загружен`);
        }
        field.loading = false;
    }
}
