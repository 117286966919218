var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h3',{staticClass:"book-keeping__title title font-weight-light mt-5"},[_vm._v(" Запланировано выплат "),_c('small',[_vm._v(_vm._s(_vm.sumOfPercentages)+"% / 100%")])]),_c('div',{staticClass:"book-keeping__item accounting-invoice payout-calendar-wrapper",class:{'--not-disabled-editability': _vm.$props.customerOrderStatus !== 'paid'}},[_c('v-card',{staticClass:"payout-calendar",attrs:{"outlined":""}},[(_vm.isCanEditDate)?_c('div',{staticClass:"payout-calendar__inner"},[_c('v-menu',{ref:"menu",attrs:{"transition":"scale-transition","attach":`.datepicker-${_vm.$props.accountIndex}`,"close-on-content-click":false,"offset-y":"","max-width":"290px","min-width":"290px","disabled":_vm.calendarIsDisabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({staticClass:"font-weight-light pt-0 pt-md-3 date-picker date-picker--payout-calendar",class:[{ '--error' :
                                (!_vm.date && _vm.percentage) ||
                                (!_vm.date && _vm.calendarAmount)
                            },`payout-calendar__date-menu datepicker-${_vm.$props.accountIndex}`],attrs:{"label":"Выбор даты","prepend-icon":"event","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}],null,false,2883410430),model:{value:(_vm.showDatePickerMenu),callback:function ($$v) {_vm.showDatePickerMenu=$$v},expression:"showDatePickerMenu"}},[_c('v-date-picker',{attrs:{"locale":"Ru-ru","no-title":"","scrollable":""},on:{"input":function($event){_vm.showDatePickerMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-text-field',{staticClass:"payout-calendar__percentage",class:{ '--error' :
                        (!_vm.percentage && _vm.date) ||
                        (!_vm.percentage && _vm.calendarAmount) ||
                        _vm.percentage === '0.00' ||
                        _vm.percentage === 0
                    },attrs:{"type":"number","label":"%","disabled":_vm.calendarIsDisabled},on:{"input":function($event){if($event.target !== $event.currentTarget)return null;return _vm.getSumFromPercentage.apply(null, arguments)}},model:{value:(_vm.percentage),callback:function ($$v) {_vm.percentage=_vm._n($$v)},expression:"percentage"}}),_c('v-text-field',{ref:"calendarAmount",staticClass:"payout-calendar__calendar-amount",class:{ '--error' :
                        (!_vm.calendarAmount && _vm.date) ||
                        (!_vm.calendarAmount && _vm.percentage) ||
                        _vm.calendarAmount === '0.00' ||
                        _vm.calendarAmount === 0
                    },attrs:{"type":"number","label":"сумма","disabled":_vm.calendarIsDisabled},on:{"input":function($event){if($event.target !== $event.currentTarget)return null;return _vm.getPercentageFromSum.apply(null, arguments)}},model:{value:(_vm.calendarAmount),callback:function ($$v) {_vm.calendarAmount=_vm._n($$v)},expression:"calendarAmount"}}),_c('v-btn',{staticClass:"payout-calendar__add-new-item",attrs:{"color":"pictionBlue","depressed":"","small":"","disabled":_vm.isCalendarButtonDisabled},on:{"click":_vm.addPayoutDate}},[_c('v-icon',[_vm._v("add")])],1)],1):_vm._e(),(_vm.paymentCalendarsSync.length > 0)?_c('ul',{staticClass:"payout-calendar__list"},_vm._l((_vm.paymentCalendarsSync),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"payout-calendar__list-item black--text"},[_c('p',{staticClass:"payout-calendar__list-item-date"},[_vm._v(" "+_vm._s(item.date)+" ")]),_c('p',{staticClass:"payout-calendar__list-item-percentage"},[_vm._v(" "+_vm._s(item.percentage)+" % ")]),_c('p',{staticClass:"payout-calendar__list-item-amount"},[_vm._v(" "+_vm._s(item.amount.toLocaleString('ru-RU'))+" "+_vm._s(_vm.getCurrencyIcon)+" ")]),_c('v-btn',{staticClass:"payout-calendar__remove-item",attrs:{"color":"pictionBlue","depressed":"","small":"","disabled":_vm.calendarIsDisabled},on:{"click":function($event){return _vm.removePayoutDate(item)}}},[_c('v-icon',[_vm._v("remove")])],1)],1)])}),0):_vm._e(),_c('v-fade-transition',[(_vm.percentage > 100 || _vm.percentage + _vm.sumOfPercentages > 100)?_c('small',{staticClass:"payout-calendar__note"},[_vm._v(" Суммарный процент не должен превышать 100% ")]):_vm._e()]),_c('v-fade-transition',[(Number(_vm.calendarAmount) === 0 && _vm.calendarAmount !== '')?_c('small',{staticClass:"payout-calendar__note"},[_vm._v(" Сумма планируемой оплаты должна быть больше нуля ")]):_vm._e()])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }