
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import {
    getSubstitutionList, getOffersList, getAffiliatesList, deleteSubstitution,
} from '@/api/uapi';
import { ISubstitutionItem } from '@/api/types/uapi';
import AppTable from '@/components/base/table/AppTable.vue';
import FormModal from '@/components/base/FormModal.vue';
import Filters from '@/components/base/filters/Filters.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { eventBus } from '@/eventbus';
import { showServerError } from '@/utils';
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        AppTable,
        TooltipButton,
        FormModal,
        SvgApplication,
        Filters,
    },
})
export default class SubstitutionList extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    settings = {
        title: 'Автоподстановки',
        loading: false,
    };
    headers = [
        { text: 'Оффер', value: 'offer_id' },
        { text: 'Название оффера', value: 'offer_name' },
        { text: 'ID партнёра', value: 'affiliate_id' },
        { text: 'Название параметра', value: 'field_key' },
        { text: 'Тип подстановки', value: 'substitute_type' },
        { text: 'Активность', value: 'is_active' },
        { text: '', value: 'actions' },
    ];
    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'offer_id',
            label: 'ID или название оффера',
            placeholder: 'Выберите ID или название оффера',
            select: [],
            items: [],
            apiMethod: getOffersList,
            itemValue: 'id',
            itemText: 'offer_name',
            multiple: true,
        }),
        new filterClass.Autocomplete({
            id: 'affiliate_id',
            label: 'ID или имя партнёра',
            placeholder: 'Выберите ID или имя партнёра',
            itemValue: 'id',
            itemText: 'name',
            apiMethod: getAffiliatesList,
            select: [],
            items: [],
            multiple: true,
        }),
    ];
    uniqueData = [
        {
            slot: 'item.substitute_type',
            template: ({ substitute_type }: ISubstitutionItem): string => {
                switch (substitute_type) {
                case 'fixed':
                    return 'Фиксированное значение';
                case 'adjust':
                    return 'Корректировка суммы\\срока';
                case 'fixed_range':
                    return 'Корректировка суммы\\срока';
                default:
                    return '';
                }
            },
        },
    ];
    items: ISubstitutionItem[] = [];
    total = 0;
    limit = 25;
    deletingSubstitution = 0;
    booleanItems = [
        {
            slot: 'item.is_active',
            key: 'is_active',
            translate: (value: boolean): string => (value ? 'Активен' : 'Не активен'),
        },
    ];
    actions = [
        {
            title: 'Редактировать',
            color: 'success',
            icon: 'edit',
            callback: ({ id }: ISubstitutionItem): void => {
                if (id) {
                    this.routerPush(id);
                }
            },
        },
        {
            title: 'Удалить',
            color: 'error',
            icon: 'delete',
            callback: (item: ISubstitutionItem): void => this.showModal(item),
        },
    ];

    routerPush(id: number): void {
        this.$router.push({ name: 'edit-substitution', params: { id: String(id) } });
    }

    created(): void {
        this.setFiltersParamsObject({});
        this.getSubstitutionList(0, this.limit);
    }

    async getSubstitutionList(offset: number, limit: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        try {
            const { meta, data } = await getSubstitutionList({ offset, limit, ...this.getFiltersParamsObject });
            this.items = data;
            this.total = meta!.total!;
        } catch (err) {
            showServerError(err, 'Список автоподстановок не загружен');
        }
    }

    showModal(item: ISubstitutionItem): void {
        this.deletingSubstitution = item.id!;
    }

    async deleteSubstitution(): Promise<void> {
        if (!this.deletingSubstitution) { return; }
        try {
            await deleteSubstitution(this.deletingSubstitution);
            const itemIndex = this.items.findIndex((item) => item.id === this.deletingSubstitution);
            if (itemIndex !== -1) {
                this.items.splice(itemIndex!, 1);
            }
            this.deletingSubstitution = 0;
        } catch (err) {
            showServerError(err, 'Не удалось удалить автоподстановку');
        }
    }

    beforeDestroy(): void {
        this.setFiltersParamsObject({});
    }

    clearFilters(): void {
        this.filters.forEach((fl) => fl.clear());
        this.setFiltersParamsObject({});
        this.getSubstitutionList(0, this.limit);
    }
}
