
import { Component, Vue, Prop } from 'vue-property-decorator';
import AppTable from '@/components/base/table/AppTable.vue';
import { getAdvertiserDuplicates } from '@/api/revenue';
import { showServerError } from '@/utils';

@Component({
    components: {
        AppTable,
    },
})
export default class AdvertiserDuplicate extends Vue {
    @Prop({ type: Number, required: true }) reportId!: number;
    @Prop({ default: null }) report!: any;

    duplicates = [];
    total = 0;
    loading = false;
    headers = [
        { text: 'Ключ', value: 'key' },
        { text: 'Количество', value: 'num' },
    ];

    uniqueData = [
        {
            slot: 'item.offer',
            template: ({ conversion }: any): string => `${conversion.offer_id}`,
        },
    ];

    created(): void {
        this.getAdvertiserDuplicates();
    }

    async getAdvertiserDuplicates(): Promise<void> {
        this.loading = true;
        try {
            if (this.$props.report) {
                this.duplicates = this.$props.report.duplicates_adv;
                this.total = this.$props.report.duplicates_adv!.length || 0;
                this.headers.unshift({
                    text: 'Offer',
                    value: 'offer',
                });
            } else {
                const { meta, data } = await getAdvertiserDuplicates(this.$props.reportId);
                this.duplicates = data;
                this.total = meta!.total;
            }
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }
}
