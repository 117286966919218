
import { Component, Mixins } from 'vue-property-decorator';
import SvgOfferList from '@/assets/icons/nav-bar/offer-list.svg';
import { showServerError } from '@/utils';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import ItemTableList from '@/components/base/TableUI/ItemTableList.vue';
import { getPayoutMasterAccounts } from '@/api/payout';
import TableWithFrontPagination from '@/components/base/table/TableWithFrontPagination.vue';
import Filters from "@/components/base/filters/Filters.vue";
import TableMixin from "@/mixins/table";
import { eventBus } from "@/eventbus";
import { IFilter } from "@/types";
import { getAffiliateSearch } from "@/api/user";
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        Filters,
        ItemTableList,
        SvgOfferList,
        TableWithFrontPagination,
    },
})
export default class MasterAccountList extends Mixins(TableMixin) {
    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'affiliate_ids',
            label: 'Аккаунт',
            apiMethod: getAffiliateSearch,
            itemValue: 'id',
            itemText: 'name',
            select: [],
            items: [],
            multiple: true,
            multiPaste: true,
            alsoSearchBy: 'email',
        }),
    ];
    headers = [
        { text: 'ID', sortable: false, value: 'id' },
        { text: 'Мастер-аккаунт', sortable: false, value: 'master.id' },
        { text: 'Партнёры', sortable: false, value: 'slaves' },
    ];
    uniqueData = [
        { slot: 'item.slaves' },
    ];
    paginationItemsPerPage = PAGINATION_PER_PAGE;
    apiMethod = getPayoutMasterAccounts;
    settings = { title: 'Мастер-аккаунты', loading: true };

    mounted(): void {
        this.getMasterAccountsPagination();
    }

    async getMasterAccountsPagination(): Promise<void> {
        try {
            await this.getTableData();
        } catch (err) {
            showServerError(err, 'Список мастер-аккаунтов не загружен');
        }
    }

    async clearFilter(): Promise<void> {
        await this.getTableData(0);
        await eventBus.$emit('go-to-first-page');
    }
}
