
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { ITopOfWeek, ITopOfWeekOffer } from "@/api/types/offers";
import { getCurrencyIcon } from "@/utils/translate";
import { namespace } from "vuex-class";
import { ICountry } from "@/api/types/catalogue";
import { formatDateInputPicker, getWeek } from "@/utils/formatDates";
import SvgTrash from "@/assets/icons/trash-dark.svg";
import SvgCheck from "@/assets/icons/check.svg";
import SvgPlus from "@/assets/icons/plus.svg";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import ImageInput from "@/components/base/form/ImageInput.vue";
import { eventBus } from "@/eventbus";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import {
    addOfferLogo, deleteOffersTopOfWeek, getEmployeesOffersV2,
    getOneEmployeesOffer,
    patchOffersTopOfWeek,
    patchStatusOffersTopOfWeek,
} from "@/api/offers";
import { showNotification, showServerError } from "@/utils";
import FormModal from "@/components/base/FormModal.vue";
import EditBtn from "@/components/base/buttons/EditBtn.vue";
import CancelBtn from "@/components/base/buttons/CancelBtn.vue";

enum STATUS {
    DRAFT = 'draft',
    PUBLISHED = 'published',
}

const catalogue = namespace('catalogueModule');
@Component({
    methods: { getEmployeesOffersV2, getCurrencyIcon },
    components: { CancelBtn, EditBtn, FormModal, BaseAutocomplete, ImageInput, EditModeTextField, TableWithFrontPaginationDesign, BaseCard, SvgTrash, SvgCheck, SvgPlus },
})
export default class TopOffersTable extends Vue {
    @catalogue.Getter('GET_COUNTRIES') countries: ICountry[] | undefined;

    @Prop({ required: true }) topData!: ITopOfWeek;

    isEdit: boolean = false;
    tableCopy: ITopOfWeekOffer[] = [];
    newOffer: any = {};
    STATUS = STATUS;
    isShowDeleteModal: boolean = false;


    headers = [
        { text: '№', sortable: false, value: 'index', align: 'center', width: '20px' },
        { text: 'Логотип', sortable: false, value: 'logo', width: '80px' },
        { text: 'ID', sortable: false, value: 'id',  width: '110px', align: 'center' },
        { text: 'Название', sortable: false, value: 'name' },
        { text: 'EPC', sortable: false, value: 'epc', width: '120px', align: 'right' },
        { text: '', value: 'actions', align: 'center', width: '20px', class: '', cellClass: '' },
    ];

    routes = [
        { routeParam: 'id', slot: 'item.id', routerName: 'offer', text: 'id' },
    ];

    uniqueData = [
        { slot: 'item.epc' },
        { slot: 'item.logo' },
        { slot: 'item.index' },
        { slot: 'item.name' },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            callback: ( item: ITopOfWeekOffer): void => this.deleteOffer(item),
        },
    ];

    get getHeaders(): any[] {
        const actionsHeader = this.headers.find(h => h.value === 'actions');
        actionsHeader!.class = this.isEdit ? '' : 'd-none';
        actionsHeader!.cellClass = this.isEdit ? '' : 'd-none';
        return this.headers;
    }

    get getItems(): ITopOfWeekOffer[] {
        return this.isEdit ? this.tableCopy : this.topData.offers;
    }

    get getCountry(): ICountry {
        return this.countries?.find((c: ICountry) => c.code === this.topData.country)!;
    }

    get getPeriod(): string {
        const week = getWeek(this.topData.period) as string[];
        return `${formatDateInputPicker(week[0], 'd')} - ${formatDateInputPicker(week[1], 'd MMMM')}`;
    }

    get isNewOffer(): boolean {
        return Object.keys(this.newOffer).length > 0;
    }

    get isDisableSaveNewOffer(): boolean {
        return !this.newOffer.epc || !this.newOffer.id;
    }

    get isNotChanged(): boolean {
        return JSON.stringify(this.topData.offers) === JSON.stringify(this.tableCopy);
    }

    get isPublished(): boolean {
        return this.topData.status === STATUS.PUBLISHED;
    }

    get isDisabledSwitch(): boolean {
        return !this.topData.offers.every(i => typeof i.logo === 'string');
    }

    get isAllLogoUpload(): boolean {
        return this.tableCopy.every(i => !!i.logo);
    }

    get isDisableSaveTop(): boolean {
        return (this.isEdit && this.isNewOffer && this.isDisableSaveNewOffer) || !this.isAllLogoUpload;
    }

    resetTable(): void {
        this.tableCopy = JSON.parse(JSON.stringify(this.topData.offers));
        eventBus.$emit('clear-image-input');
        this.isEdit = false;
        this.newOffer = {};
    }

    async editTable(): Promise<void> {
        this.isEdit = !this.isEdit;
        if (this.isEdit) {
            this.tableCopy = JSON.parse(JSON.stringify(this.topData.offers));
        } else {
            if (this.isNotChanged) return;
            try {
                await this.uploadLogo();
                const offers = this.tableCopy.map(({ id, epc }) => ({ id, epc }));
                await patchOffersTopOfWeek(this.topData.id, { offers });
                showNotification('Изменения сохранены');
                this.$emit('update');
                this.newOffer = {};
            } catch (err) {
                showServerError(err, 'Список офферов не изменен');
            }
        }
    }

    async uploadLogo(): Promise<void> {
        try {
            const offersWithNewLogo = this.tableCopy.filter(i => i.logo instanceof File);
            await Promise.all(offersWithNewLogo.map(({ id, logo }) => addOfferLogo(logo as File, id as number)));
        } catch (err) {
            showServerError(err, 'Ошибка загрузки логотипа');
        }
    }

    uploadImage(item: ITopOfWeekOffer, image: File): void {
        item.logo = image;
    }

    addOffer(): void {
        this.newOffer = { epc: 0, name: '' };
        this.tableCopy.push(this.newOffer);
    }

    changeOffer(item: ITopOfWeekOffer, offerId: number): void {
        if (!this.tableCopy.some(i => +i.id! === +offerId)) {
            item.id = offerId;
        }
    }

    async approveNewOffer(): Promise<void> {
        if (!this.newOffer.id || !this.newOffer.epc) return;
        try {
            const { logo_toprec, name } = await getOneEmployeesOffer(this.newOffer.id, { by_legacy_id: 1 });
            const offer = { id: this.newOffer.id, logo: logo_toprec, name, epc: this.newOffer.epc };
            const index = this.tableCopy.indexOf(this.newOffer);
            this.tableCopy.splice(index, 1, offer);
            this.newOffer = {};
        } catch (err) {
            showServerError(err, 'Оффер не добавлен');
        }
    }

    deleteOffer(item: ITopOfWeekOffer): void {
        const index = this.tableCopy.findIndex((i) => i.id === item.id);
        this.tableCopy.splice(index, 1);
        if (!item.name) this.newOffer = {};
    }

    async changeStatus(status: string): Promise<void> {
        try {
            await patchStatusOffersTopOfWeek(this.topData.id, status);
        } catch (err) {
            showServerError(err, 'Статус топа не изменён');
        }
    }

    async deleteTop(): Promise<void> {
        try {
            await deleteOffersTopOfWeek(this.topData.id);
            showNotification('Топ успешно удалён');
            this.$emit('update');
        } catch (err) {
            showServerError(err, 'Топ не удален');
        }
    }
}

