
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ReportWrap from '@/components/revenue/statTask/report/ReportWrap.vue';
import { getFileReport } from '@/api/revenue';
import { IFileReportSummary } from '@/api/types/revenue';
import Loader from '@/components/base/Loader.vue';
import { showServerError } from '@/utils';

const revenue = namespace('revenueModule');

@Component({
    components: {
        Loader,
        ReportWrap,
    },
})
export default class FileReport extends Vue {
    @revenue.Getter('GET_FILE_REPORT') fileReport;

    data = {} as IFileReportSummary;

    loading = false;

    tabs: Array<{
        id: string;
        name: string;
        component: string;
        selector?: string;
    }> = [
            {
                id: 'summary',
                name: 'Сводная информация',
                component: 'FileBasicInformation',
            },
            {
                id: 'all',
                name: 'Принято: Все',
                component: 'AcceptedAll',
                selector: 'accepted-all',
            },
            {
                id: 'approved_old',
                name: 'Принято: Ранее',
                component: 'AcceptedPrev',
                selector: 'accepted-prev',
            },
            {
                id: 'approved_new',
                name: 'Принято: Новые',
                component: 'AcceptedNew',
                selector: 'accepted-new',
            },
            {
                id: 'adjust',
                name: 'Принято: Adjust',
                component: 'Adjusts',
                selector: 'adjusts',
            },
            {
                id: 'clone',
                name: 'Принято: Клоны',
                component: 'Clones',
                selector: 'clones',
            },
            {
                id: 'advertiser_duplicate',
                name: 'Дубли: у рекла',
                component: 'AdvertiserDuplicate',
            },
            {
                id: 'lead_gid_duplicate',
                name: 'Дубли: в LeadGid',
                component: 'LeadDuplicate',
                selector: 'leadgid-duplicate',
            },
            {
                id: 'loss',
                name: 'Потери',
                component: 'Losses',
            },
            {
                id: 'extra',
                name: 'Лишние',
                component: 'Extra',
                selector: 'extra',
            },
        ];

    async getSummary(): Promise<void> {
        this.loading = true;
        try {
            if (Object.keys(this.fileReport)?.length > 0) {
                const { data } = await getFileReport(this.fileReport.id);
                this.data = data;
            }
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }

    mounted(): void {
        this.getSummary();
    }
}
