
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardMaterial from './CardMaterial.vue';

@Component({
    components: { CardMaterial },
})

export default class StatsCard extends Vue {
    @Prop({ type: String, required: false }) icon!: string;
    @Prop({ type: String, default: 'secondary' }) color!: string;
    @Prop({ type: [Number, String], default: 10 }) elevation!: number | string;
    @Prop({ type: String, default: undefined }) subIcon!: string;
    @Prop({ type: String, default: undefined }) subIconColor!: string;
    @Prop({ type: String, default: undefined }) subTextColor!: string;
    @Prop({ type: String, default: undefined }) subText!: string;
    @Prop({ type: [Number, String], default: undefined }) title!: number | string;
    @Prop({ type: [Number, String], default: undefined }) idTitle!: number | string;
    @Prop({ type: [Number, String], default: 11 }) cols!: number | string;
}
