
import { Component, Mixins, Prop } from 'vue-property-decorator';
import FiltersBase from '@/components/base/filters/FiltersBase.vue';
import FiltersMixin from '@/mixins/filters';

@Component({
    components: { FiltersBase },
})

export default class Filters extends Mixins(FiltersMixin) {
    @Prop({ type: String, default: '' }) readonly attachPrefixClass!: string;
    @Prop({ type: Boolean, default: false }) readonly isOpenPanel!: boolean;
    @Prop({ type: Object, default: () => ({}) }) readonly loading!: {[key: string]: boolean};
}
