import { Module } from 'vuex';

import { actions } from '@/store/modules/revenue/actions';
import { mutations } from '@/store/modules/revenue/mutations';
import { getters } from '@/store/modules/revenue/getters';

import { IRevenueState } from '@/store/modules/revenue/types';
import { IRootState } from '@/store/types';
import {
    IAccountingAmounts, IFiles, IOfferService,
} from '@/api/types/revenue';

export const state: IRevenueState = {
    advertiser: {
        id: null,
        name: '',
    },
    accountManagerName: '',
    accountingAmounts: {
        amountOfOffers: [],
        amountOfServices: [],
        amountOfTotals: [],
    } as IAccountingAmounts,
    offerReport: {} as IOfferService,
    fileReport: {} as IFiles,
    generalReportOffers: [],
    currentAdvertiser: null,
};

const namespaced = true;

export const revenueModule: Module<IRevenueState, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
