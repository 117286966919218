
import { Component, Vue } from 'vue-property-decorator';
import { deleteOfferDelay, getOffersDelay, getOffersList, postDelay } from '@/api/uapi';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { showServerError } from '@/utils';
import Filters from '@/components/base/filters/Filters.vue';
import AppTable from '@/components/base/table/AppTable.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import FormModal from '@/components/base/FormModal.vue';
import DelayModalContent from '@/components/uapi/DelayModal.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import { Getter, Mutation } from 'vuex-class';
import { getAffiliateSearch } from '@/api/user';
import { IOffersDelay } from '@/api/types/uapi';
import { eventBus } from '@/eventbus';
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        Filters,
        AppTable,
        SvgApplication,
        FormModal,
        DelayModalContent,
        TooltipButton,
    },
    beforeRouteLeave(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        if (this.getUnsavedDataState) {
            this.setAbortTransition(true);
            this.setRouteToTransition(to.name);
            this.showUnsavedChangesModal = true;
        } else {
            this.setAbortTransition(false);
            this.setRouteToTransition('');
            next();
        }
    },
})
export default class Delay extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    @Getter('GET_UNSAVED_DATA_STATE') getUnsavedDataState;
    @Mutation('SET_ABORT_TRANSITION') setAbortTransition;
    @Mutation('SET_ROUTE_TO_TRANSITION') setRouteToTransition;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Getter('GET_ABORT_TRANSITION') getAbortTransition;
    @Getter('GET_ROUTE_TO_TRANSITION') getRouteToTransition;

    showUnsavedChangesModal = false;
    limit = PAGINATION_PER_PAGE || 25;
    total = 0;
    items: IOffersDelay[]= [];
    delay: IOffersDelay = {};
    deletingDelay = 0;
    addDelayModal = false;
    // для сохранения промежуточных значений при открытии модалки
    currentLimit = this.limit;
    currentOffset = 0;

    editing = false;
    editingDelays: {[key: number]: IOffersDelay} = {};

    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'offer_id',
            label: 'Оффер',
            select: [],
            items: [],
            apiMethod: getOffersList,
            itemValue: 'id',
            itemText: 'offer_name',
            multiple: true,
            multiPaste: true,
        }),
        new filterClass.Autocomplete({
            id: 'affiliate_id',
            label: 'Партнер',
            apiMethod: getAffiliateSearch,
            itemValue: 'id',
            itemText: 'name',
            select: [],
            items: [],
            multiple: true,
        }),
    ];

    headers = [
        {
            text: 'ID партнёра',
            value: 'affiliate_id',
        },
        {
            text: 'ID оффера',
            value: 'offer_id',
        },
        {
            text: 'Название оффера',
            value: 'offer_name',
        },
        {
            text: 'Отложка',
            value: 'delay',
            width: '20%',
        },
        {
            text: '',
            value: 'actions',
            width: '20%',
        },
    ];

    uniqueData = [
        {
            slot: 'item.delay',
        },
    ];

    settings = {
        title: 'Отложка',
        loading: false,
    };

    get isEmptyDelay(): boolean {
        return !(
            this.delay.offer_id ||
            this.delay.affiliate_id ||
            (this.delay.delay === 0 || this.delay.delay! > 0)
        );
    }

    created(): void {
        this.setFiltersParamsObject({});
        this.showUnsavedChangesModal = false;
        this.setUnsavedDataState(false);
        this.getDataTable(0, this.limit);
    }

    changeDelay(item: IOffersDelay): void {
        this.$set(this.editingDelays, item.id!, item);
        this.setUnsavedDataState(true);
    }

    async getDataTable(offset: number, limit: number, isChangeLimit?: boolean): Promise<void> {
        if (this.getUnsavedDataState) {
            this.showUnsavedChangesModal = true;
            // запоминаем офсет и лимит, чтобы после показа модалки выполнить нужное дейсвие
            this.currentLimit = limit;
            this.currentOffset = offset;
            return;
        }

        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        this.settings.loading = true;
        try {
            const { data, meta } = await getOffersDelay({ offset, limit, ...this.getFiltersParamsObject });
            this.items = data;
            this.total = meta!.total!;
        } catch (err) {
            showServerError(err);
        }
        this.settings.loading = false;
    }

    showAddModal(): void {
        if (this.getUnsavedDataState) {
            this.showUnsavedChangesModal = true;
            return;
        }
        this.addDelayModal = true;
    }

    hideAddModal(): void {
        this.addDelayModal = false;
        this.delay = {};
    }

    toggleEditing(): void {
        this.editing = !this.editing;
        this.editDelays();
    }

    editDelays(): void {
        if (!this.editing && Object.keys(this.editingDelays).length) {
            Object.keys(this.editingDelays).forEach(key => {
                this.postDelay(this.editingDelays[key]);
            });
        }
        this.$set(this, 'editingDelays', {});
    }

    async postDelay(item: IOffersDelay, updateList?: boolean): Promise<void> {
        this.settings.loading = true;
        try {
            if (this.getUnsavedDataState) {
                this.setUnsavedDataState(false);
            }
            if (!item.hasOwnProperty('affiliate_id')) {
                item.affiliate_id = 0;
            }
            await postDelay(item);
            if (updateList) {
                await this.getDataTable(0, this.limit);
            }
        } catch (err) {
            showServerError(err);
        }
        this.$set(this, 'delay', {});
        this.addDelayModal = false;
        this.settings.loading = false;
    }

    clearFiltersDelay(): void {
        if (this.getUnsavedDataState) {
            this.showUnsavedChangesModal = true;
            return;
        }
        this.getDataTable(0, this.limit);
    }

    async deleteDelay(): Promise<void> {
        if (!this.deletingDelay) { return; }
        try {
            const itemIndex = this.items.findIndex((item) => item.id === this.deletingDelay);
            if (itemIndex !== -1) {
                await deleteOfferDelay(this.items[itemIndex].affiliate_id!, this.items[itemIndex].offer_id!);
                await this.getDataTable(0, this.limit);
            }
            this.deletingDelay = 0;
        } catch (err) {
            showServerError(err, 'Не удалось удалить автоподстановку');
        }
    }

    // изменения не сохраняются, страница возвращается в режим просмотра, и происходит действие, инициированное пользователем (переход на другую страницу, пагинашка,или применение фильтров)
    cancelEditDelayFromModal(): void {
        this.showUnsavedChangesModal = false;
        this.setUnsavedDataState(false);
        this.editing = false;
        this.$set(this, 'editingDelays', {});
        if (this.getAbortTransition) {
            this.$router.push({ name: this.getRouteToTransition });
        } else {
            this.getDataTable(this.currentOffset, this.currentLimit);
            this.setAbortTransition(false);
        }
    }

    // сохраняет изменения и происходит действие, инициированное пользователем (переход на другую страницу, пагинашка,или применение фильтров)
    editDelayFromModal(): void {
        this.showUnsavedChangesModal = false;
        this.editing = false;
        this.editDelays();
        if (this.getAbortTransition) {
            this.$router.push({ name: this.getRouteToTransition });
        }
    }

    hideModal(): void {
        this.showUnsavedChangesModal = false;
        eventBus.$emit('go-to-first-page');
    }
}
