
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getAffiliateSearch } from '@/api/user';
import { IAffiliate } from "@/api/types/revenue";
import BaseCard from "@/components/base/BaseCard.vue";
import { eventBus } from "@/eventbus";
import FormModal from "@/components/base/FormModal.vue";
import { IUser } from "@/api/types/user";
import { IBaseAutocomplete } from "@/types";

@Component({
    components: {
        FormModal,
        BaseCard,
        BaseAutocomplete,
    },
})

export default class NotificationAffiliateRecipient extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;

    affiliates:  IAffiliate[] = [];
    partnerData: IBaseAutocomplete = {
        errorText: 'Партнер не найден',
        apiMethod: getAffiliateSearch,
        itemValue: 'id',
        clearable: true,
        label: 'Выберите партнера',
        maxShowItems: 6,
        template: (item: IUser): string => `${item.id} ${item.first_name} ${item.last_name}`,
        multiple: true,
        trashTitle: 'Сбросить',
        multiPaste: true,
    };

    setAffiliates(e: IAffiliate[]): void {
        this.affiliates = [...e];
    }

    addAffiliate(): void {
        this.notification.addAffiliates(this.affiliates);
        this.affiliates = [];
        // слушаем событие сбросить все вне компонента
        eventBus.$emit('clear');
    }
}
