
import { Component, Prop, Vue } from 'vue-property-decorator';
import Conditions from '@/components/uapi/conditions/List.vue';
import {
    editEndpointCondition, addEndpointCondition, getEndpointConditions, deleteCondition,
} from '@/api/uapi';
import { ICondition } from '@/api/types/uapi';
import { showServerError } from '@/utils';

@Component({
    components: {
        Conditions,
    },
})
export default class ConditionsEndpoint extends Vue {
    @Prop({ type: Boolean, default: true }) canEdit!: boolean;

    endpointId = '';
    conditions: ICondition[] = [];
    loading = false;

    created(): void {
        this.endpointId = this.$route.params.endpointId;
        this.getEndpointConditions();
    }

    pushToEditRouter(conditionId: string): void {
        const { endpointId } = this.$route.params;
        this.$router.push({ name: 'edit-endpoint-condition', params: { endpointId, conditionId } });
    }

    pushToCreateRouter(): void {
        this.$router.push({ name: 'create-endpoint-condition', params: { endpointId:  this.endpointId } });
    }

    async getEndpointConditions(): Promise<void> {
        this.loading = true;
        try {
            const { data } = await getEndpointConditions(this.endpointId);
            this.conditions = data;
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }

    async saveCondition(condition: ICondition): Promise<void> {
        try {
            if (condition.id) {
                await editEndpointCondition(this.endpointId, condition.id, condition);
            } else {
                await addEndpointCondition(this.endpointId, condition);
            }
            await this.getEndpointConditions();
        } catch (err) {
            showServerError(err);
        }
    }

    async deleteCondition(conditionId: number): Promise<void> {
        try {
            await deleteCondition(conditionId);
            await this.getEndpointConditions();
        } catch (err) {
            showServerError(err, 'Не удалось удалить условие');
        }
    }
}
