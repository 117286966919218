import { Module } from 'vuex';

import { actions } from '@/store/modules/offers/actions';
import { mutations } from '@/store/modules/offers/mutations';
import { getters } from '@/store/modules/offers/getters';

import { IRootState } from '@/store/types';
import { IOffersState } from "@/store/modules/offers/types";

export const state: IOffersState = {
    verticals: [],
    statPeriodForList: 30,
    // текущий оффер (при заходе на детальную страницу оффера)
    current_offer: null,
    selects_items: {},
    nextUpdateSelectItems: 0,
};

const namespaced = true;

export const offersModule: Module<IOffersState, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
