
import { Getter, namespace } from 'vuex-class';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { ISingleMyLeadgidInvoice } from '@/api/types/payout';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { translateMyLeadgidInvoiceStatus } from '@/utils/translate';
import AffiliateInfo from '@/components/affiliate/affiliateInvoices/AffiliateInfo.vue';
import BackArrow from '@/assets/icons/short-back-arrow.svg';
import BaseCard from '@/components/base/BaseCard.vue';
import FormUpdatePaidStatus from '@/components/affiliate/affiliateInvoices/FormUpdatePaidStatus.vue';
import InvoicePayments from '@/components/affiliate/affiliateInvoices/Payments.vue';
import Status from '@/components/base/Status.vue';
import SvgCancelPay from '@/assets/icons/payout/cancel-pay.svg';
import SvgPlus from '@/assets/icons/plus.svg';
import SvgFindConversions from '@/assets/icons/payout/find-conversions.svg';
import SvgPay from '@/assets/icons/payout/pay.svg';
import SvgUser from '@/assets/icons/payout/user.svg';
import SvgMasterInvoice from '@/assets/icons/master-invoice.svg';
import FormAddPayout from "@/components/affiliate/affiliateInvoices/FormAddPayout.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import { IUser } from "@/api/types/user";

const catalogue = namespace('catalogueModule');
const auth = namespace('authModule');
@Component({
    components: {
        FormAddPayout,
        AffiliateInfo,
        BackArrow,
        BaseCard,
        FormUpdatePaidStatus,
        InvoicePayments,
        Status,
        SvgCancelPay,
        SvgPlus,
        SvgFindConversions,
        SvgPay,
        SvgUser,
        SvgMasterInvoice,
    },
})
export default class MyLeadgidInvoiceHeader extends Vue {
    @auth.Getter('GET_USER') user!: IUser;
    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencySymbol!: string;
    @Getter('GET_BEFORE_LAST_ROUTE') beforeLastRoute!: string;

    @PropSync('invoice', { required: true, default: () => ({}) }) invoiceSync!: ISingleMyLeadgidInvoice;
    @Prop({ default: false }) isShowBtn!: boolean;

    isUpdatePaidStatus = false;
    isAddPayout = false;

    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    translateMyLeadgidInvoiceStatus = translateMyLeadgidInvoiceStatus;

    get canAddPayment(): boolean {
        const ROLES: string[] = [
            USER_ROLES.SUPERVISOR,
            USER_ROLES.FINANCIER,
            USER_ROLES.ADMIN,
        ];
        return this.user.roles.some((role) => ROLES.includes(role));
    }

    get isPaid(): boolean | undefined {
        return this.invoiceSync?.is_paid;
    }

    goBack(): void {
        if (this.beforeLastRoute === '/') {
            this.$router.push({ path: '/affiliates/affiliate-invoices' });
        } else {
            this.$router.back();
        }
    }

    hideAndReload(): void {
        this.isUpdatePaidStatus = false;
        this.isAddPayout = false;
        this.$emit('update-invoice');
    }

    findConversions(): void {
        const params = new URLSearchParams();
        params.append('offset', '0');
        params.append('affiliate_invoice_id', String(this.invoiceSync?.id));

        const columns = [
            'adv_sub', 'affiliate_id', 'affiliate_invoice_id', 'affiliate_name', 'click_id', 'client_id',
            'conversion_id', 'creation_date', 'currency', 'goal_id', 'goal_name', 'offer_id', 'offer_name',
            'payout', 'revenue', 'status',
        ];
        columns.forEach((col) => {
            params.append('columns', col);
        });

        const queryString = params.toString();
        window.open(`/statistics/conversions-report?${queryString}`, '_blank');
    }
}
