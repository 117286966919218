
import { Component, Prop, Vue } from 'vue-property-decorator';
import TablePageFilters from '@/components/base/filters/TablePageFilters.vue';
import { CreatedFilters } from "@/services/filters/filters";
import { CreatedColumns } from "@/services/columns/columns";

@Component({
    components: {
        TablePageFilters,
    },
})
export default class FiltersInvoices extends Vue {
    @Prop({ default: 100 }) limit!: number;
    @Prop({ required: true }) filters!: CreatedFilters;
    @Prop({ required: true }) columns!: CreatedColumns;

    submitFilters(offset: number, limit: number): void {
        this.$emit('submit', offset, limit);
    }
}
