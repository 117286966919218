
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgPinFull from '@/assets/icons/pin-full.svg';
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import BaseCard from "@/components/base/BaseCard.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import SvgRangeCalendar from '@/assets/icons/range-calendar.svg';
import SvgEdit from '@/assets/icons/pencil.svg';
import Status from "@/components/base/Status.vue";

@Component({
    components: {
        Status,
        ViewDateTime,
        BaseCard,
        SvgPinFull,
        SvgRangeCalendar,
        SvgEdit,
    },
})

export default class NotificationDetailHeader extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;
}
