
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { IBlackListItem } from '@/api/types/uapi';
import {
    getBlackList, addBlackListItem, deleteBlackListItem, getBlackListCsv,
} from '@/api/uapi';
import { eventBus } from '@/eventbus';
import StatsCard from '@/components/base/StatsCard.vue';
import AppTable from '@/components/base/table/AppTable.vue';
import FormModal from '@/components/base/FormModal.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { showServerError } from '@/utils';
import { downloadFileToDisk } from '@/api';
import SortTableMixin from '@/mixins/sortTableMixin';
import { Mutation } from 'vuex-class';
import Filters from "@/components/base/filters/Filters.vue";
import { ISortFilters } from "@/store/modules/uapi/types";
import { filterClass } from '@/services/filters/filterClass';
import { IFilter } from '@/types';

@Component({
    components: {
        Filters,
        AppTable,
        StatsCard,
        FormModal,
        SvgApplication,
    },
})
export default class BlackList extends Mixins(SortTableMixin) {
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    headers = [
        {
            text: 'Дата добавления',
            value: 'created_at',
        },
        {
            text: 'Пользователь',
            value: 'created_by',
        },
        {
            text: '',
            value: 'actions',
            sortable: false,
        },
    ];

    filters: IFilter[] = [
        new filterClass.Input({
            id: 'value',
            label: 'Поиск...',
            select: '',
        }),
    ];

    tabs = [
        {
            text: 'E-mail',
            name: 'E-mail',
            suffix: 'email',
            headers: [
                {
                    text: 'E-mail',
                    value: 'value',
                },
                ...this.headers,
            ],
            filters: [
                new filterClass.Datepicker({
                    id: 'created_email',
                    label: 'Период',
                    pickerType: 'date',
                    range: true,
                    select: [],
                }),
                ...this.filters,
            ],
            search: '',
            items: [] as IBlackListItem[],
            total: 0,
            limit: 25,
            sortBy: 'created_at',
            sortDesc: true,
        },
        {
            name: 'Телефон',
            suffix: 'phone',
            headers: [
                {
                    text: 'Телефон',
                    value: 'value',
                },
                ...this.headers,
            ],
            filters: [
                new filterClass.Datepicker({
                    id: 'created_phone',
                    label: 'Период',
                    pickerType: 'date',
                    range: true,
                    select: [],
                }),
                ...this.filters,
            ],
            search: '',
            items: [] as IBlackListItem[],
            total: 0,
            limit: 25,
            sortBy: 'created_at',
            sortDesc: true,
        },
        {
            name: 'Слово',
            suffix: 'stopword',
            headers: [
                {
                    text: 'Слово',
                    value: 'value',
                },
                ...this.headers,
            ],
            filters: [
                new filterClass.Datepicker({
                    id: 'created_stopword',
                    label: 'Период',
                    pickerType: 'date',
                    range: true,
                    select: [],
                }),
                ...this.filters,
            ],
            search: '',
            items: [] as IBlackListItem[],
            total: 0,
            limit: 25,
            sortBy: 'created_at',
            sortDesc: true,
        },
    ];

    newItem = {
        suffix: '',
        value: '',
    };
    showAddModal = false;
    loadingDownload = false;
    deletingItem: {id: string} = {
        id: '',
    };
    actions = [
        {
            title: 'Удалить',
            color: 'danger',
            icon: 'delete',
            callback: ({ id }: IBlackListItem): void => {
                this.$set(this.deletingItem, 'id', id);
            },
        },
    ];
    selectedTab: number = 0;
    searchTimerId: number = 0;
    settings = {
        loading: false,
    };

    @Watch('selectedTab')
    getList(index: number): void {
        this.tabs.forEach((elem) => elem.filters.forEach((filter) => filter.clear()));
        this.setFiltersParamsObject({});
        this.newItem.suffix = this.tabs[index].suffix;
        this.newItem.value = '';
        this.clearFilters();
    }

    changeField(value: string): void {
        this.field = value;
        this.updateSortByBlackList(value);
    }

    updateSortByBlackList(sortBy: string): void {
        const options = {
            sortDesc: this.sortDesc,
            sortBy: sortBy === 'created_by' ? 'user': sortBy,
        };
        this.updateOptions(options);
    }

    changeDescription(value: boolean): void {
        this.description = value;
        this.updateSortDescBlackList(value);
    }

    updateSortDescBlackList(sortDesc: boolean): void {
        const options = {
            sortBy: this.sortBy,
            sortDesc,
        };
        this.updateOptionsBlackList(options);
    }

    updateOptionsBlackList(options: { sortDesc: boolean; sortBy: string; }): void {
        const optionsFilters = this.transpilingOptionsToFiltersBlackList(options);
        this.setSortFilters(optionsFilters);
        this.debounceGetDataTable();
    }

    transpilingOptionsToFiltersBlackList(options: { sortDesc: boolean; sortBy: string; }): ISortFilters {
        const { sortDesc, sortBy } = options;
        const optionsFilters: ISortFilters = {};
        optionsFilters.sort_column = sortBy === 'created_by' ? 'user': sortBy;
        optionsFilters.sort_direction = this.getSortType(sortDesc);
        return optionsFilters;
    }

    async getDataTable(offset: number, limit?: number, isChangeLimit?: boolean): Promise<void> {
        const tab = this.tabs[this.selectedTab!];
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        tab.limit = limit || tab.limit;
        const paramsObj = { ...this.getFiltersParamObject };
        const uniqueCreatedDates = ['created_email_to', 'created_email_from',
            'created_phone_to', 'created_phone_from', 'created_stopword_to', 'created_stopword_from'];
        uniqueCreatedDates.forEach((elem) => {
            if (paramsObj.hasOwnProperty(elem)) {
                paramsObj['created_' + elem.split('_')[2]] = paramsObj[elem];
                delete paramsObj[elem];
            }
        });
        const params: {
            offset: number,
            limit: number,
            value?: string,
        } = {
            offset,
            limit: tab.limit,
            ...paramsObj,
            ...this.getSortFilters,
        };
        this.settings.loading = true;
        try {
            const { suffix } = tab;
            this.newItem.suffix = suffix;
            const { meta, data } = await getBlackList(suffix, params);
            tab.items = data;
            tab.total = meta!.total || data.length;
        } catch (err) {
            showServerError(err, 'Не удалось загрузить блэклист');
        }
        this.settings.loading = false;
    }

    async addItem(): Promise<void> {
        try {
            const { suffix } = this.newItem;
            await addBlackListItem(suffix, { values: this.newItem.value.split(/\r?\n/).filter(item => item.trim() !== '') });
            await this.getDataTable(0);
            this.newItem.suffix = '';
            this.newItem.value = '';
            this.showAddModal = false;
            this.selectedTab = this.selectedTab;
        } catch (err) {
            showServerError(err, 'Не удалось добавить элемент');
        }
    }

    async deleteItem(): Promise<void> {
        try {
            const tab = this.tabs[this.selectedTab!];
            const index = tab.items.findIndex((item) => item.id === this.deletingItem.id);
            await deleteBlackListItem(tab.suffix, this.deletingItem.id);
            tab.items.splice(index, 1);
            this.deletingItem.id = '';
        } catch (err) {
            showServerError(err, 'Не удалось добавить элемент');
        }
    }

    async downloadBlacklist(): Promise<void> {
        this.loadingDownload = true;
        try {
            const tab = this.tabs[this.selectedTab!];
            const { suffix } = tab;
            const params: {
                value?: string,
            } = {
                ...this.getFiltersParamObject,
                ...this.getSortFilters,
            };
            const file = await getBlackListCsv(suffix, params);
            downloadFileToDisk(file, { name: `Выгрузка блэклиста по ${suffix}`, extension: 'csv' });
        } catch (err) {
            showServerError(err, 'Не удалось получить выгрузку');
        }
        this.loadingDownload = false;
    }
}
