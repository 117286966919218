
import { Vue, Component, Ref } from "vue-property-decorator";
import PageLayout from "@/components/layout/PageLayout.vue";
import Status from "@/components/base/Status.vue";
import { IEmployeePayment } from "@/api/types/payout";
import { translatePaymentsStatus } from "@/utils/translate";
import PaymentDetail from "@/components/affiliate/payments/PaymentDetail.vue";
import SvgCancel from '@/assets/icons/cancel-white.svg';
import SvgCheck from '@/assets/icons/check.svg';
import { namespace } from "vuex-class";
import { showNotification, showServerError } from "@/utils";
import { USER_ROLES } from "@/mappings/user-roles";
import { changeStatusConfirmation } from "@/api/payout";
import { IStatus } from "@/types";

const auth = namespace('authModule');
@Component({
    components: { PaymentDetail, Status, PageLayout, SvgCheck, SvgCancel },
})

export default class Payment extends Vue {
    @auth.Getter('GET_USER') user;
    @Ref() detail!: PaymentDetail;

    earlyPaymentID: string = '';
    payment: IEmployeePayment = {};
    isButtonLoading: boolean = false;

    get getTitle(): string {
        return `Досрочка №${this.earlyPaymentID}`;
    }

    get getStatus(): IStatus {
        return translatePaymentsStatus(this.payment.status!);
    }

    get isShowButtons(): boolean {
        return this.payment.status_confirmation === 'pending';
    }

    get isShowChangeStatusButtons(): boolean {
        if (this.payment.status !== 'new') return false;
        const roles: string[] = [USER_ROLES.ADMIN, USER_ROLES.SYSTEM_1C, USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.SUPERVISOR];
        return this.user.roles.some((role: string) => roles.includes(role));
    }

    created(): void {
        this.earlyPaymentID = this.$route.params.id;
        document.title = this.getTitle;
    }

    async changeStatus(status: string): Promise<void> {
        try {
            this.isButtonLoading = true;
            await changeStatusConfirmation(this.earlyPaymentID, status);
            showNotification('Статус подтверждения успешно изменён');
            await this.detail.getPayment();
        } catch (err) {
            showServerError(err, 'Статус подтверждения не изменён');
        } finally {
            this.isButtonLoading = false;
        }
    }
}

