
import { Component, Vue, Prop, InjectReactive, Watch } from "vue-property-decorator";
import PageLayout from "@/components/layout/PageLayout.vue";
import Status from '@/components/base/Status.vue';
import { showServerError } from "@/utils";
import ChargeRequestPageHeader from "@/components/affiliate/charge-requests/ChargeRequestPageHeader.vue";
import { editChargeRequest, getAffiliateInvoices } from "@/api/payout";
import Loader from "@/components/base/Loader.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { getAffiliateSearch } from "@/api/user";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { translateMyLeadgidInvoiceStatus, translatePartnerStatus } from "@/utils/translate";
import { Affiliate } from "@/services/affiliates/Affiliate";
import SvgStar from "@/assets/icons/star.svg";
import {
    AffiliateInvoicesNumberFormatting,
    AffiliateInvoicesRoutes,
    AffiliateInvoicesTimeData,
} from "@/services/TablePage/affiliateInvoices";
import { IMyLeadgidInvoice } from "@/api/types/payout";
import { ITdAction } from "@/components/base/TableUI/TdActions.vue";
import MultiselectDesign from "@/components/base/form/MultiselectDesign.vue";
import pullAllWith from "lodash-es/pullAllWith";
import isEqual from "date-fns/isEqual";
import { eventBus } from "@/eventbus";
import uniq from "lodash-es/uniq";
import { sortBy } from "lodash-es";

@Component({
    methods: { getAffiliateSearch },
    components: {
        MultiselectDesign,
        TableWithFrontPaginationDesign,
        ChargeRequestPageHeader,
        PageLayout,
        Status, Loader, BaseCard,
        BaseAutocomplete, BaseSelect,
    },
})

export default class ChargeRequestBody extends Vue {
    @Prop({ required: true }) currentRequest!: any;

    @InjectReactive() readonly isNewRequest!: any;

    isUpdateMenu = true;
    chargeRequestNumber: string = '';
    isLoading: boolean = false;
    unpaidInvoices: IMyLeadgidInvoice[] = [];
    newInvoice: number[] = [];
    newAffiliate: number[] = [];
    invoiceRoutes = AffiliateInvoicesRoutes;
    numberFormatting = AffiliateInvoicesNumberFormatting;
    timeData = AffiliateInvoicesTimeData;

    affiliatesHeaders = [
        { text: 'ID пар-ра', sortable: false, value: 'id', align: 'center' },
        { text: 'Имя', sortable: false, value: 'full_name', align: 'left', width: 150 },
        { text: 'Мастер-аккаунт', sortable: false, value: 'master_id', align: 'center' },
        { text: 'Почта', sortable: false, value: 'email', align: 'left' },
        { text: 'Аккаунт-менеджер', sortable: false, value: 'manager.full_name', align: 'left', width: 150 },
        { text: 'Новый статус', sortable: false, value: 'status', align: 'left' },
        { text: '', sortable: false, align: 'center', value: 'actions', width: '50px', fixed: true },
    ];

    invoicesHeaders = [
        { text: 'ID счёта', value: 'id', align: 'center', width: 64, fixed: true },
        { text: 'Партнёр', value: 'affiliate_id', align: 'center' },
        { text: 'Валюта', value: 'currency', fixed: true },
        { text: 'Доход', value: 'total_revenue', align: 'right' },
        { text: 'Выплата', value: 'total_amount', align: 'right' },
        { text: 'Выплачено', value: 'partials_amount', align: 'right' },
        { text: 'Остаток', value: 'rest_amount', align: 'right' },
        { text: 'Прибыль', value: 'total_profit', align: 'right' },
        { text: 'Период оплаты', value: 'pay_period', align: 'right' },
        { text: 'Дата создания', value: 'created_at', width: 90, align: 'right' },
        { text: 'Дата обновления', value: 'updated_at', width: 90, align: 'right' },
        { text: 'Статус', value: 'paid_status' },
        { text: '', sortable: false, align: 'center', value: 'actions', width: '50px', fixed: true },
    ];
    statuses = [
        {
            slot: 'item.paid_status',
            key: 'paid_status',
            translate: translateMyLeadgidInvoiceStatus,
        },
        {
            slot: 'item.status',
            key: 'status',
            translate: translatePartnerStatus,
        },
    ];
    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'affiliate',
            text: 'id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'master_id',
            slot: 'item.master_id',
            routerName: 'affiliate',
            template: (item: Affiliate): any => {
                return item.is_master === 0 && !item.master_id ? item.id : item.master_id;
            },
            icon: SvgStar,
            showIcon: (item: Affiliate): boolean => {
                return !!item.master_id && item.id !== item.master_id;
            },
            tooltip: (item: Affiliate): string => {
                return !!item.master_id && item.id !== item.master_id ? 'Мастер' : '';
            },
            tooltipClass: 'base-tooltip',
        },
    ];

    get summaryRows(): string[] {
        const defaultOrder = ['RUB', 'KZT', 'USD', 'EUR', 'PLN'];
        const uniqueCurrencies: string[] = uniq(this.currentRequest.invoices.map(i => i.currency));
        return sortBy(uniqueCurrencies, (item) => {
            const i = defaultOrder.indexOf(item);
            return i === -1 ? defaultOrder.length : i;
        });
    }

    get invoiceActions(): ITdAction[] {
        return [
            {
                title: 'Удалить',
                color: '#1B1B1D',
                icon: 'SvgTrashWhite',
                disabled: (): boolean => {
                    return !this.isNewRequest;
                },
                callback: (item: any): Promise<void> => this.updateChargeRequest([], [item.id]),
            },
        ];
    }

    get affiliateActions(): ITdAction[] {
        return [
            {
                title: 'Удалить',
                color: '#1B1B1D',
                icon: 'SvgTrashWhite',
                disabled: (): boolean => {
                    return !this.isNewRequest;
                },
                callback: (item: any): Promise<void> => this.updateChargeRequest([item.id]),
            },
        ];
    }

    async updateAffiliates(search: string, model: { value: number }[]): Promise<void> {
        eventBus.$emit('clear');
        this.newAffiliate = model ? model.map(i => i.value) : [];
        if (this.newAffiliate?.length <= 0) return;
        await this.updateChargeRequest();
    }

    async updateInvoices(items: []): Promise<void> {
        if (!items || items.length <= 0) return;
        await this.updateChargeRequest();
    }

    getUniqInvoices(data: any[]): any[] {
        return pullAllWith(data, this.currentRequest.invoices, this.isEqual);
    }

    isEqual(objValue: any, othValue: any): boolean {
        return isEqual(objValue.id, othValue.id);
    }

    sumField(key: string, cur: string): number {
        return this.currentRequest.invoices.filter(c=> c.currency === cur).reduce((a, b) => a + Number(b[key] || 0), 0)! || 0;
    }

    @Watch('currentRequest.affiliates', { immediate: true, deep: true })
    async getUnpaidInvoice(): Promise<void> {
        try {
            const aff_ids = this.currentRequest.affiliates.map(i => i.id);
            if (aff_ids?.length > 0) {
                const { data } = await getAffiliateInvoices({
                    offset: 0, limit: 500,
                    affiliate_id: aff_ids, paid_status: ['partial_paid', 'not_paid'],
                });
                this.unpaidInvoices = data;
            } else {
                this.unpaidInvoices = [];
            }
        } catch (err) {
            showServerError(err, 'Список неоплаченных счетов не загружен');
        }
    }

    async updateChargeRequest(removeAffiliate: any = [], removeInvoice: any = []): Promise<void> {
        const updatedRequest = {
            affiliate_ids: {
                add: this.newAffiliate,
                remove: removeAffiliate,
            },
            invoice_ids: {
                add: this.newInvoice,
                remove: removeInvoice,
            },
        };
        try {
            await editChargeRequest(this.currentRequest.id, updatedRequest);
            await this.$emit('update-request');
            this.newInvoice = [];
            this.newAffiliate = [];

        } catch (err) {
            showServerError(err, 'Не удалось изменить заявку на расход');
        }
    }
}
