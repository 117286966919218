var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pb-0 base__item_border"},[_c('div',{staticClass:"d-flex col-12 px-0"},[_c('div',{staticClass:"stat-task__multiselect"},[_c('ul',{staticClass:"stat-task__multiselect-list pa-0 mr-6 mb-n3"},_vm._l((_vm.item.offer_services),function(offer){return _c('li',{key:offer.id},[_c('v-chip',{staticClass:"multiselect-chips",attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.remove(offer)}}},[_c('span',{staticClass:"multiselect-chips__content"},[_vm._v(_vm._s(_vm.getTemplate(offer)))])])],1)}),0),_c('multiselect',{staticClass:"d-block",attrs:{"single-line":"","multiselect-data":{
                    value: _vm.item.offer_services,
                    type: _vm.MULTISELECT_TYPE,
                    noNeedModified: true,
                    items: _vm.possibleItemsForSelect,
                    label: _vm.multiSelectLabel,
                    noDataText: 'Нет добавленных офферов',
                    itemValue: _vm.$props.itemValue,
                    template: _vm.getTemplate,
                    required: false,
                    isNoToggle: false,
                }},on:{"change-multiselect-items":_vm.changeSelect}})],1),_c('v-btn',{staticClass:"mt-n3 ml-auto",attrs:{"type":"button","title":"Удалить","text":"","fab":"","small":"","disabled":!_vm.editingMode},on:{"click":_vm.deleteFile}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('div',{staticClass:"d-flex flex-wrap mb-2"},[_c('p',{staticClass:"base__title document__title text--primary font-weight-light pa-0 pb-2 ma-0 mr-4 col-6 col-lg-4 text-truncate text-left"},[_vm._v(" "+_vm._s(_vm.item.file.name)),_c('br')]),_c('small',{staticClass:"d-inline-block ml-auto text--secondary pb-2"},[_vm._v(_vm._s(_vm.item.file.extension))]),_c('small',{staticClass:"d-inline-block ml-6 text--secondary pb-2"},[_vm._v(_vm._s((_vm.item.file.size / 1000).toFixed(1) + 'Kb'))]),_c('v-textarea',{staticClass:"font-weight-light col-12 mb-0",attrs:{"label":"Комментарий к загружаемому файлу","outlined":"","no-resize":"","rows":_vm.getNumberOfLines(_vm.comment.text),"row-height":"25","disabled":!_vm.editingMode,"hide-details":""},on:{"change":_vm.addComment},model:{value:(_vm.comment.text),callback:function ($$v) {_vm.$set(_vm.comment, "text", $$v)},expression:"comment.text"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }