
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IPayment } from '@/api/types/revenue';
import CardMaterial from '@/components/base/CardMaterial.vue';

@Component({
    components: {
        CardMaterial,
    },
})
export default class Payments extends Vue {
    @Prop({ type: Array, default: () => ({}) }) payments!: IPayment[];

    accountingInvoiceHeaders = [
        {
            text: ' Дата платежа',
            align: 'left',
            value: 'date',
            class: 'primary--text subtitle-2 font-weight-regular',
            width: '20%',
        },
        {
            text: 'Сумма оплаты',
            align: 'right',
            value: 'amount',
            class: 'primary--text subtitle-2 font-weight-regular',
            sortable: false,
            width: '20%',
        },
        {
            text: 'Валюта',
            align: 'left',
            value: 'currency',
            class: 'primary--text subtitle-2 font-weight-regular',
            sortable: false,
            width: '10%',
        },
        {
            text: 'Комментарий',
            align: 'center',
            value: 'comment',
            sortable: false,
            class: 'primary--text subtitle-2 font-weight-regular',
            width: '50%',
        },
    ];
}
