import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { IRootState } from '@/store/types';
import { revenueModule } from '@/store/modules/revenue';
import { payoutModule } from '@/store/modules/payout';
import { authModule } from '@/store/modules/auth';
import { uapiModule } from '@/store/modules/uapi';
import { invoiceModule } from '@/store/modules/invoice';
import { administrationModule } from '@/store/modules/administration';
import { catalogueModule } from '@/store/modules/catalogue';
import { offersModule } from  '@/store/modules/offers';
import { statisticsModule } from '@/store/modules/statistics';
import { ENV_MODES } from '@/mappings/env-mode';
import { getCookie } from '@/utils/cookie';

Vue.use(Vuex);

export default new Vuex.Store<IRootState>({
    // https://vuex.vuejs.org/ru/guide/strict.html
    strict: process.env.NODE_ENV !== 'production',

    state: {
        filtersParamObject: {},
        defaultFiltersParamObject: {},
        totalElementsDataTable: 0,
        isLoading: false,
        isEditModeEnabled: true,
        serverError: {},
        lastRoute: '/',
        beforeLastRoute: '',
        isWithoutDefaultFilters: false,
        envMode: ENV_MODES.PROD,
        isUnsavedData: false,
        abortTransition: false,
        routeToTransition: '',
        notification: {
            text: '',
            type: '',
            progress: 0,
            closeText: 'Закрыть',
            isConversionsReport: false,
        },
        isMiniNav: false,
        currentAffiliate: null,
        isMasterAffiliate: false,
    },

    plugins: [
        createPersistedState({
            key: 'vuex',
            reducer(store) {
                // если юзер вышел из CRM, то очищаем vuex в localStorage
                return getCookie('authenticated') === 'true' ? store : {};
            },
        }),
    ],

    mutations: {
        SET_FILTERS_PARAMS_OBJECT(state, payload) {
            return state.filtersParamObject = payload;
        },
        SET_DEFAULT_FILTERS_PARAMS_OBJECT(state, payload) {
            return state.defaultFiltersParamObject = payload;
        },
        SET_TOTAL_ELEMENTS_DATA_TABLE(state, payload) {
            return state.totalElementsDataTable = payload;
        },
        TOGGLE_EDITING_MODE(state, payload) {
            state.isEditModeEnabled = payload;
        },
        SET_SERVER_ERROR(state, payload) {
            state.serverError = payload;
        },
        TOGGLE_ENV_MODE(state, payload) {
            state.envMode = payload;
        },
        SET_LAST_ROUTE(state, payload) {
            state.lastRoute = payload;
        },
        SET_BEFORE_LAST_ROUTE(state, payload) {
            state.beforeLastRoute = payload;
        },

        SET_WITHOUT_DEFAULT_FILTERS(state, payload) {
            state.isWithoutDefaultFilters = payload;
        },

        SET_UNSAVED_DATA_STATE(state, payload) {
            state.isUnsavedData = payload;
        },
        SET_ABORT_TRANSITION(state, payload) {
            state.abortTransition = payload;
        },
        SET_ROUTE_TO_TRANSITION(state, payload) {
            state.routeToTransition = payload;
        },
        SET_NOTIFICATION(state, payload) {
            state.notification.text = payload.text;
            state.notification.type = payload.type ?? 'success';
            if (Number.isFinite(payload.progress)) state.notification.progress = payload.progress;
            if (typeof payload.closeText === 'string') state.notification.closeText = payload.closeText;
            state.notification.isConversionsReport = !!payload.isConversionsReport;
        },
        RESET_NOTIFICATION(state) {
            state.notification = {
                text: '',
                type: '',
                progress: 0,
                closeText: '',
                isConversionsReport: false,
            };
        },
        SET_IS_MINI_NAV(state, payload) {
            state.isMiniNav = payload;
        },
        SET_CURRENT_AFFILIATE(state, payload) {
            state.currentAffiliate = payload;
        },
        SET_IS_MASTER_AFFILIATE(state, payload) {
            state.isMasterAffiliate = payload;
        },
    },

    getters: {
        GET_FILTERS_PARAMS_OBJECT(state) {
            return state.filtersParamObject;
        },
        GET_DEFAULT_FILTERS_PARAMS_OBJECT(state) {
            return state.defaultFiltersParamObject;
        },
        GET_TOTAL_ELEMENTS_DATA_TABLE(state) {
            return state.totalElementsDataTable;
        },
        GET_SERVER_ERROR(state) {
            return state.serverError;
        },
        GET_EDITING_MODE(state) {
            return state.isEditModeEnabled;
        },
        GET_ENV_MODE(state) {
            return state.envMode;
        },
        IS_DEV_MODE(state) {
            return state.envMode === ENV_MODES.DEV;
        },
        GET_LAST_ROUTE(state) {
            return state.lastRoute;
        },
        GET_BEFORE_LAST_ROUTE(state) {
            return state.beforeLastRoute;
        },
        GET_UNSAVED_DATA_STATE(state) {
            return state.isUnsavedData;
        },
        GET_ABORT_TRANSITION(state) {
            return state.abortTransition;
        },
        GET_ROUTE_TO_TRANSITION(state) {
            return state.routeToTransition;
        },
        GET_NOTIFICATION(state) {
            return state.notification;
        },
        IS_MINI_NAV(state) {
            return state.isMiniNav;
        },
        GET_CURRENT_AFFILIATE(state) {
            return state.currentAffiliate;
        },
    },

    modules: {
        revenueModule,
        payoutModule,
        authModule,
        uapiModule,
        administrationModule,
        invoiceModule,
        catalogueModule,
        offersModule,
        statisticsModule,
    },
});
