
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import FormModal from "@/components/base/FormModal.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getUsersV3 } from "@/api/user";
import SvgMagicWandLight from '@/assets/icons/magic-wand-light.svg';
import Loader from "@/components/base/Loader.vue";
import { getLegalEntitySearch } from "@/api/revenue";
import { showServerError } from "@/utils";
import { postUserOrganizations } from "@/api/org-cabinets-backend";
import { IUser } from "@/api/types/user";
import { IServerResponse } from "@/api/types";
import { ILegalEntityOrg } from "@/api/types/revenue";

@Component({
    methods: { getUsersV3, getLegalEntitySearch },
    components: {
        Loader,
        BaseAutocomplete,
        FormModal,
        SvgMagicWandLight,
    },
})
export default class FormCreateAccess extends Vue {
    @PropSync('showModal') showModalSync!: boolean;
    @Prop({ default: [] }) legalEntities!: ILegalEntityOrg[];

    user = {} as IUser;
    orgs: string[] = [];

    get isSaveDisabled(): boolean {
        return !this.user?.account_id || this.orgs.length <= 0;
    }

    updateUsers(item: IUser): void {
        this.user = item;
    }

    updateOrgs(items: string[]): void {
        this.orgs = items;
    }

    async createAccess(): Promise<void> {
        try {
            await postUserOrganizations(this.user.account_id as number, this.user.account_type, this.orgs);
            this.showModalSync = false;
            this.$emit('create');
        } catch (err) {
            if (err.status === 400 && err.data?.error?.message.includes('User already has following guid')) {
                showServerError({} as IServerResponse<any>, this.parseErrorMessage(err));
            } else showServerError(err, 'Доступ не создан');
        }
    }

    parseErrorMessage(err: IServerResponse<any>): string {
        const ids = err.data?.error?.message.split(':')[1];
        const externalIds = ids.trim().split(", ");
        const orgNames: string[] = [];
        externalIds.forEach( id => {
            const name = this.legalEntities.find(i => i.external_id === id)?.name || id;
            orgNames.push(name);
        });
        const message = `Этот пользователь уже имеет доступ в ${externalIds?.length === 1 ? 'организацию' : 'организации :' } ${orgNames}`;
        return message || err.data.error.message;
    }
}
