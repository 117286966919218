
import { Component, Prop, Mixins } from 'vue-property-decorator';
import Report from '@/mixins/report';
import AppTable from '@/components/base/table/AppTable.vue';
import { getReportApproved, getAdjustsReport, getClonesReport } from '@/api/revenue';
import { translateConversionStatus, translateLeadStatus } from '@/utils/translate';
import { IReportAdjustOrClone, IReportApproved, IReportConversion } from '@/api/types/revenue';
import { eventBus } from '@/eventbus';
import { changeTopScrollWidth, showServerError } from '@/utils';
import { IFilter, ITableStatus } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        AppTable,
    },
})
export default class AcceptedAll extends Mixins(Report) {
    @Prop({ type: Number, required: true }) reportId!: number;
    @Prop({ default: null }) report!: any;

    filters: IFilter[] = [
        new filterClass.Input({
            id: 'transaction_id',
            label: 'Transaction ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'lead_id',
            label: 'Lead ID',
            select: '',
            type: 'number',
        }),
        new filterClass.Input({
            id: 'advertiser_sub_id',
            label: 'Sub ID',
            select: '',
        }),
        new filterClass.Input({
            id: 'affiliate_id',
            label: 'ID веба',
            select: '',
            type: 'number',
        }),
        new filterClass.Select({
            id: 'status',
            label: 'Статус',
            items: [],
            select: '',
            uuid: 'accepted_all',
        }),
        new filterClass.Select({
            id: 'is_paid',
            label: 'Статус оплаты',
            items: [],
            select: '',
            translate: (i) => translateLeadStatus(i).text,
            uuid: 'accepted_all',
        }),
        new filterClass.Select({
            id: 'payout',
            label: 'Выплата',
            items: [],
            select: '',
            uuid: 'accepted_all',
        }),
        new filterClass.Select({
            id: 'revenue',
            label: 'Доход',
            items: [],
            select: '',
            uuid: 'accepted_all',
        }),
        new filterClass.Select({
            id: 'type',
            label: 'Тип',
            items: [],
            select: '',
            uuid: 'accepted_all',
        }),
        new filterClass.Datepicker({
            id: 'updated',
            label: 'Дата обновления',
            pickerType: 'month',
            select: '',
            range: true,
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Дата создания',
            pickerType: 'month',
            select: '',
            range: true,
        }),
    ];

    headers = [
        { text: 'Transaction ID', value: 'conversion.transaction_id' },
        { text: 'Sub ID', value: 'conversion.advertiser_sub_id' },
        { text: 'Lead ID', value: 'conversion.lead_id' },
        { text: 'ID веба', value: 'conversion.affiliate_id' },
        { text: 'Имя веба', value: 'conversion.affiliate_name' },
        { text: 'Статус лида', value: 'conversion.status' },
        { text: 'Статус оплаты', value: 'conversion.is_paid' },
        { text: 'Выплата', value: 'conversion.payout' },
        { text: 'Доход', value: 'conversion.revenue' },
        { text: 'Прибыль', value: 'profit' },
        { text: 'Дата лида', value: 'conversion.created' },
        { text: 'Дата обновления', value: 'conversion.updated' },
        { text: 'Тип', value: 'conversion.type' },
    ];

    // для отображения статусов в таблице
    statuses: ITableStatus[] = [
        {
            slot: 'item.conversion.is_paid',
            sup: 'conversion',
            key: 'is_paid',
            translate: translateLeadStatus,
        },
        {
            slot: 'item.conversion.status',
            sup: 'conversion',
            key: 'status',
            translate: translateConversionStatus,
        },
    ];

    mounted(): void {
        this.getAllData();
        eventBus.$on('change-accepted-all', changeTopScrollWidth);
    }

    async getAllData(): Promise<void> {
        this.loading = true;
        try {
            if (this.$props.report) {
                this.initialData = [
                    ...this.setTypeData(this.$props.report.approved_old, 'Принято: ранее'),
                    ...this.setTypeData(this.$props.report.approved_new, 'Принято: новые'),
                    ...this.setTypeData(this.$props.report.adjust_link, 'Принято: аджасты'),
                    ...this.setTypeData(this.$props.report.clones_link, 'Принято: клоны'),
                ];
                this.headers.unshift({
                    text: 'Offer',
                    value: 'offer',
                });
            } else {
                const { data: approvedOld } = await getReportApproved(this.$props.reportId, 'old');
                const { data: approvedNew } = await getReportApproved(this.$props.reportId, 'new');
                const { data: adjusts } = await getAdjustsReport(this.$props.reportId);
                const { data: clones } = await getClonesReport(this.$props.reportId);
                this.initialData = [
                    ...this.setTypeData(approvedOld, 'Принято: ранее'),
                    ...this.setTypeData(approvedNew, 'Принято: новые'),
                    ...this.setTypeData(adjusts, 'Принято: аджасты'),
                    ...this.setTypeData(clones, 'Принято: клоны'),
                ];
            }

            this.filteredData = [...this.initialData];
            this.total = this.filteredData.length;
            this.getPaginationData(0, this.limit);
            this.fillFiltersItems();
        } catch (err) {
            showServerError(err);
        }
        this.loading = false;
    }

    setTypeData(data: IReportApproved[] | IReportAdjustOrClone[], type: string): IReportConversion[] {
        return (data as any[]).flatMap((el) => {
            if (el.conversion) {
                el.conversion.type = type;
                return el;
            } if (el.conversions) {
                el.conversions.forEach((conv) => {
                    conv.conversion.type = type;
                });
                return el.conversions;
            }
        });
    }

    beforeDestroy(): void {
        eventBus.$off('change-accepted-all', changeTopScrollWidth);
    }
}
