var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('page-layout',{staticClass:"offer-single",class:[
        {'medium-width': _vm.tab !== 0},
        {'--get-excel': [4, 5].includes(_vm.tab)},
        {'--rates': _vm.tab === 4 },
        {'--approvals': _vm.tab === 5 },
        {'--goals': _vm.tab === 1}
    ],attrs:{"title":_vm.title,"max-width":"100%"},on:{"back":_vm.backToList},scopedSlots:_vm._u([{key:"tabs",fn:function(){return [(_vm.isShowTabs)?_c('PageTabs',{staticClass:"offer-single__tabs",attrs:{"tab":_vm.tab,"tab-items":_vm.tabItems},on:{"change":_vm.changeTab}}):_vm._e()]},proxy:true},{key:"header",fn:function(){return [(_vm.tab === 0)?_c('v-btn',{staticClass:"offer-single__copy-btn button button--transparent button--with-icon button--mobile",on:{"click":_vm.copyOffer}},[_c('SvgCopy'),_c('span',[_vm._v("Создать подобный")])],1):_vm._e()]},proxy:true}])},[(_vm.offer)?[_c(_vm.bodyComponent,{tag:"component",class:_vm.tabClass,attrs:{"offer":_vm.offer,"is-edit-on-mounted":_vm.isEditGoals}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }