
import { Component, Vue, Prop } from 'vue-property-decorator';
import AppTableRouter from "@/components/base/TableUI/AppTableRouter.vue";
import SvgArrowDown from '@/assets/icons/arrow-down.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";

interface ITdRoute {
    slot: string,
    routerName: string,
    routeParam: string,
    text: string,
    tooltip?: Function,
    template?: Function,
    sup?: string,
    getParams?: Function,
    getQuery?: Function,
    extraRouteParamName?: string,
    readonly?:boolean,
    remove?: Function,
    style?: string,
}
@Component({
    components: { TrashBtn, AppTableRouter, SvgArrowDown },
})
export default class TdDropDown extends Vue {
    @Prop({ required: true }) item!: Object | any;
    @Prop({ required: true }) route!: ITdRoute;

    isOpen: boolean = false;

    get isShow(): boolean {
        return !!this.item[this.route.text] &&  this.item[this.route.text].length > 0;
    }

    displayText(index: number | string): any {
        if (typeof this.route.template === 'function') {
            return this.route.template(this.item[this.route.text][index]);
        }
        return this.item[this.route.text][index];
    }

    handleClickOutside(): void {
        this.isOpen = false;
    }
}
