
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({})

export default class MaskedTextField extends Vue {
    @PropSync('value') valueSync!: string | number;
    @Prop({ default: true }) isInteger!: boolean;
    @Prop({ default: '' }) mask!: any;
    @Prop({ default: '' }) prefix!: string;
    @Prop({ default: 10 }) maxLength!: number;

    get currentMask(): any {
        if (this.isInteger) {
            return this.maskInteger;
        } else return this.mask;
    }

    get maskInteger(): {} {
        const arr: string[] = [];
        arr.length = this.maxLength;
        return {
            mask: arr.fill('A').join(''),
            tokens: {
                A: {
                    pattern: /[0-9]+/,
                },
            },
        };
    }

}
