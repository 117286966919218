
import { Component, Vue, Prop } from 'vue-property-decorator';
import { INavigationExtraItems, INavigationItem } from '@/api/types/revenue';
import { eventBus } from '@/eventbus';
import SvgForCreating from '@/assets/icons/nav-bar/page-for-creating.svg';
import { USER_ROLES } from "@/mappings/user-roles";
import { namespace } from "vuex-class";
import { IUser } from "@/api/types/user";

const auth = namespace('authModule');

@Component({
    name: 'nav-list-item',
    components: {
        SvgForCreating,
    },
})
export default class NavListItem extends Vue {
    @Prop({ }) serviceSync!: INavigationItem;
    @Prop({ type: Boolean, default: false }) mini!: boolean;
    @Prop({ type: Boolean, default: false }) isModal!: boolean;
    @auth.Getter('GET_USER') getUser!: IUser;

    lastRouterName: string = '';
    LINK_TO_PAGE = 'toPage';
    LINK_TO_CREATING_PAGE = 'toCreatingPage';

    created(): void {
        this.lastRouterName = this.$route.name as string;
    }

    get getItem(): Array<INavigationExtraItems> {
        const items = this.serviceSync.value as INavigationExtraItems[];
        items.forEach(item => {
            if (!item.addName) return;
            const { route } = this.$router.resolve({ name: item.addName });
            const routeRoles = route.meta?.roles! as USER_ROLES[];
            if (this.getUser.roles.some(role => routeRoles.includes(role))) return;
            delete item.addName;
        });
        return items;
    }

    setQueryParams(item: INavigationExtraItems):void {
        // эмитим событие клика по навбару, если роуты совпадают, но разные квери
        const isSameRoute = this.lastRouterName === item.routeName && ((item.query || this.lastRouterName === 'personal-rates'));
        if (isSameRoute || item.routeName.includes('extended-report')) {
            eventBus.$emit('click-nav-drawer', item);
        }
        this.lastRouterName = item.routeName;
    }

    goToPage(item: INavigationExtraItems, type: string, newTab: boolean): void {
        const link = type === this.LINK_TO_PAGE ?
            item.query ? { name: item.routeName , query: item.query } : { name: item.routeName } || '' :
            item.query ? { name: item.addName , query: item.query } : { name: item.addName } || '';
        if (newTab) {
            const path = this.$router.resolve(link);
            window.open(path.href);
        } else {
            this.$router.push(link);
        }
    }

    showSectionTitle(item: INavigationExtraItems): boolean {
        // @ts-ignore
        const { routes } = this.$router.options;
        const currentSection = routes?.find((route) => route.name === this.$props.serviceName);

        if (currentSection) {
            // Проверяем, если хоть какой-то item в разделе видимый, то название раздела
            // тоже показываем
            // пока актуально только для UAPI
            return this.serviceSync.value
                .some(value =>
                    item.section === (value as INavigationExtraItems).section
                    && value.show);
        }
        return false;
    }
}
