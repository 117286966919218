
import { Component, Vue } from 'vue-property-decorator';
import ApplicationFilters from '@/components/uapi/analytics/ApplicationFilters.vue';
import OffersTable from '@/components/uapi/analytics/OffersTable.vue';
import StatsCard from '@/components/base/StatsCard.vue';
import Count from '@/components/uapi/analytics/Count.vue';
import Filters from '@/components/base/filters/Filters.vue';
import { getAnalyticsApplication, postAnalyticsApplication } from '@/api/uapi';
import { showServerError } from '@/utils';
import { Mutation, namespace } from 'vuex-class';
import { IAnalyticApplication, IAnalyticField } from '@/api/types/uapi';

const uapi = namespace('uapiModule');

@Component({
    components: {
        ApplicationFilters,
        OffersTable,
        StatsCard,
        Count,
        Filters,
    },
})
export default class ConversionsForecast extends Vue {
    @uapi.Getter('GET_CONVERSIONS_FORECAST_QUERY') getConversionsForecastQuery;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @uapi.Mutation('SET_CONVERSIONS_FORECAST_QUERY') setConversionsForecastQuery;

    fields: IAnalyticField[] = [];
    selectedTab = 0;
    tabs = [
        {
            name: 'Для партнёров',
            type: 'offers',
            requiredFields: ['affiliate_id', 'country'],
            items: [] as IAnalyticApplication[],
            total: 0,
            loading: false,
        },
        {
            name: 'Для рекламодателей',
            type: 'count',
            items: [] as IAnalyticApplication[],
            requiredFields: ['date'],
            total: 0,
            loading: false,
        },
    ];

    async created(): Promise<void> {
        if (this.$route.hash) {
            const tabIndex = this.tabs.findIndex((tab) => tab.type === this.$route.hash.slice(1));
            this.selectedTab = Number(tabIndex);
        } else {
            this.changeHash(this.selectedTab);
        }

        await this.getFields();
    }

    async submitFilters(): Promise<void> {
        const buttons = document.querySelectorAll('.v-btn.success');
        buttons.forEach((button) => (button as HTMLElement).classList.add('v-btn--disabled'));
        this.tabs[this.selectedTab].loading = true;
        try {
            const { type } = this.tabs[this.selectedTab];
            const params = {};
            const filters = { ...this.getConversionsForecastQuery[this.$route.hash] };
            for (const key in filters) {
                if (filters[key] instanceof Array && !filters[key].length) {
                    continue;
                } else if (typeof filters[key] === 'boolean' && filters[key] === false) {
                    continue;
                }
                if (key === 'city') {
                    params[key] = filters[key].map((fl) => fl.hasOwnProperty('1') ?  typeof fl === 'object' ? fl[1].replace(/^\d+\s?/, '') : fl : fl);
                } else if (key === 'region') {
                    params[key] = filters[key].map((fl) => fl.hasOwnProperty('code') ?
                        fl.code : typeof fl === 'object' ? fl.name.split(' ')[0] : fl);
                } else {
                    params[key] = filters[key];
                }
            }
            const { data, meta } = await postAnalyticsApplication(type, params);
            this.tabs[this.selectedTab].items = data;
            this.tabs[this.selectedTab].total = meta!.total!;
        } catch (err) {
            showServerError(err);
        }
        this.tabs[this.selectedTab].loading = false;
        buttons.forEach((button) => (button as HTMLElement).classList.remove('v-btn--disabled'));
    }

    changeHash(value: number): void {
        this.setFiltersParamsObject({});
        this.setConversionsForecastQuery({});
        this.$router.replace({
            name: this.$route.name || '',
            query: this.$route.hash ? this.getConversionsForecastQuery[`#${this.tabs[value].type}`] : {},
            hash: `#${this.tabs[value].type}`,
        });
    }

    async getFields(): Promise<void> {
        try {
            const { data } = await getAnalyticsApplication();
            data.push(this.addPeriodField());
            this.$set(this, 'fields', data);
        } catch (err) {
            showServerError(err);
        }
    }

    addPeriodField(): IAnalyticField {
        return ({
            analytic: 'range',
            field: 'date',
            label: 'Период',
            type: 'date',
            onlyCountTab: true,
        });
    }

    clearFilters(): void {
        this.tabs.forEach((tab) => {
            tab.items = [] as IAnalyticApplication[];
            tab.total = 0;
        });
    }
}
