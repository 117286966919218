
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { eventBus } from "@/eventbus";
import { translateBoolean, translateTwoFactor } from "@/utils/translate";
import { IUser } from "@/api/types/user";
import { patchUser } from "@/api/user";
import { showServerError } from "@/utils";
import { namespace } from 'vuex-class';
import ImageInput from "@/components/base/form/ImageInput.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import SvgTrash from "@/assets/icons/trash.svg";

const auth = namespace('authModule');

@Component({
    methods: { translateTwoFactor, translateBoolean },
    components: {
        SvgTrash,
        BaseCard,
        ImageInput,
    },
})
export default class UserCardImage extends Vue {
    @Prop({ required: true }) user!: IUser;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @auth.Getter('GET_USER') currentUser;
    logoFile: any = null;
    uploadPhoto(image: any): void {
        this.logoFile = image;
        this.updateAllUserData();
    }
    async updateUser(): Promise<void> {
        const accountId = this.user.account_id as number;
        try {
            await patchUser(accountId, this.user.account_type!,
                {
                    roles: this.user.roles,
                    image: this.logoFile,
                    remove_image: this.logoFile ? false : this.user.photo ? null : true,
                });
        } catch (err) {
            showServerError(err, 'Ошибка сохранения');
        }
    }

    async deleteImage(): Promise<void> {
        const accountId = this.user.account_id as number;
        try {
            await patchUser(accountId, this.user.account_type!,
                {
                    roles: this.user.roles,
                    remove_image: true,
                });
            eventBus.$emit("update-user");
            eventBus.$emit('clear-image-input');
            this.user.photo = null;
            this.logoFile = null;
        } catch (err) {
            showServerError(err, 'Ошибка сохранения');
        }
    }

    async updateAllUserData(): Promise<void> {
        this.isLoadingSync = true;
        await this.updateUser();
        eventBus.$emit("update-user");
        eventBus.$emit('clear-image-input');
        this.isLoadingSync = false;
    }
}
