
import { Component, Mixins } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { getCurrentDate } from '@/utils/formatDates';
import {
    addErrorType,
    getStatisticErrors,
    downloadErrorFile,
    editErrorType,
    deleteErrorType,
    exportStatisticErrors,
    downloadFile,
} from '@/api/uapi';
import { IGroupingStatsError, IStatsError } from '@/api/types/uapi.d';
import Filters from '@/components/base/filters/Filters.vue';
import AppTable from '@/components/base/table/AppTable.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import CardMaterial from '@/components/base/CardMaterial.vue';
import { showServerError } from '@/utils';
import FormModal from '@/components/base/FormModal.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import { eventBus } from '@/eventbus';
import Status from '@/components/base/Status.vue';
import SortTableMixin from '@/mixins/sortTableMixin';
import { PAGINATION_PER_PAGE } from "@/configs/global";
import { IFilter } from '@/types';
import { filterClass } from '@/services/filters/filterClass';

const auth = namespace('authModule');

@Component({
    components: {
        Filters,
        Status,
        TooltipButton,
        CardMaterial,
        SvgApplication,
        FormModal,
        AppTable,
    },
})
export default class Errors extends Mixins(SortTableMixin) {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParams;
    @auth.Getter('GET_USER') user;

    dataTable: IStatsError[] = [];
    totalElementDataTable = 0;
    currentDate = getCurrentDate('yyyy-MM-dd');
    items: IStatsError[] = [];
    total: number = 0;
    errorId: number | string = '';
    groupingModal = false;
    deletingModal = false;
    showModal = false;
    canExport = true;
    grouping: IGroupingStatsError = {
        offer_id: null,
        name: '',
        regex: '',
    };
    loadingDownload = false;
    paginationItemsPerPage = PAGINATION_PER_PAGE;
    // встроенный Vuetify объект
    pagination: {page: number} = {
        page: 1,
    };

    filters: IFilter[] = [
        new filterClass.ComboboxArray({
            id: 'offer_id',
            label: 'ID оффера',
            select: [],
            supValue: '',
        }),
        new filterClass.ComboboxArray({
            id: 'affiliate_id',
            label: 'ID партнера',
            select: [],
            supValue: '',
        }),
        new filterClass.Datepicker({
            id: 'created',
            label: 'Период',
            pickerType: 'date',
            range: true,
            select: [this.currentDate],
            defaultValue: [this.currentDate],
        }),
    ];

    routes = [
        {
            routeParam: 'offer_id',
            slot: 'item.offer_id',
            routerName: 'offer_id',
            text: 'ID оффера',
        },
    ];

    readonly headersWithAffiliateId = [
        {
            text: 'ID оффера',
            value: 'offer_id',
            align: 'center',
        },
        {
            text: 'Название оффера',
            value: 'offer_name',
            align: 'left',
        },
        {
            text: 'ID партнера',
            value: 'affiliate_id',
            align: 'center',
            sortable: false,
        },
        {
            text: 'Ошибка',
            value: 'error',
        },
        {
            text: 'Количество',
            value: 'count',
            align: 'center',
        },
        {
            text: '',
            value: 'actions',
        },
    ];

    readonly headersWithoutAffiliateId = [
        {
            text: 'ID оффера',
            value: 'offer_id',
            align: 'center',
        },
        {
            text: 'Название оффера',
            value: 'offer_name',
            align: 'left',
            sortable: false,
        },
        {
            text: 'Ошибка',
            value: 'error',
        },
        {
            text: 'Количество',
            value: 'count',
            align: 'center',
        },
        {
            text: '',
            value: 'actions',
        },
    ];

    headers = this.headersWithoutAffiliateId;

    settings = {
        title: 'Отчет по ошибкам',
        loading: false,
    };

    uniqueData = [
        {
            slot: 'item.count',
        },
        {
            slot: 'item.offer_id',
        },
    ];

    created(): void {
        // используется для сортировки
        // нужен в sortTableMixins
        this.field = 'offer_id';
        const activeFilters = {};
        this.filters.forEach((fl) => {
            if (fl.select) {
                if (fl.array) {
                    if (fl.select.length) {
                        activeFilters[fl.id] = fl.select;
                    }
                } else if (fl.datepicker) {
                    activeFilters[`${fl.id}_from`] = fl.select[0];
                    activeFilters[`${fl.id}_to`] = fl.select[0];
                } else {
                    activeFilters[fl.id] = fl.select;
                }
            }
        });


        const paramsObj = JSON.parse(JSON.stringify({ ...activeFilters }));
        this.setDefaultFiltersParamsObject(paramsObj);
        this.setFiltersParamsObject(paramsObj);
    }

    changeHeaders(): void {
        const selectedFilters = this.getFiltersParamsObject;
        if (selectedFilters.affiliate_id && selectedFilters.affiliate_id.length) {
            this.headers = this.headersWithAffiliateId;
        } else {
            this.headers = this.headersWithoutAffiliateId;
        }
    }

    async getDataTable(offset?: number, limit?: number, isChangeLimit?: boolean): Promise<void> {
        this.canExport = true;
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        if (limit) {
            this.limit = limit;
        }
        this.settings.loading = true;
        const paramsObj = { ...this.getFiltersParamsObject, ...this.getSortFilters };
        try {
            const { meta, data } = await getStatisticErrors({ offset, limit, ...paramsObj });
            this.dataTable = data;
            this.totalElementDataTable = meta!.total!;
            this.changeHeaders();
        } catch (err) {
            showServerError(err, 'Список ошибок не найден');
        }
        this.settings.loading = false;
    }

    showDeletingStageModal(item: IStatsError): void {
        this.errorId = item.error_type_id!;
        this.deletingModal = true;
    }

    async deleteError(): Promise<void> {
        try {
            await deleteErrorType(String(this.errorId));
            await this.getDataTable(0, this.limit);
            this.deletingModal = false;
        } catch (err) {
            showServerError(err);
        }
    }

    showGroupingModal(item: IStatsError): void {
        if (item.error_type_id) {
            this.grouping.error_id = item.error_type_id;
            this.grouping.offer_id = item.offer_id;
            this.grouping.regex = item.pattern!;
            this.grouping.name = item.error!;
        } else {
            this.grouping.offer_id = item.offer_id;
            this.grouping.regex = item.error;
        }
        this.groupingModal = true;
    }

    async downloadError(item: IStatsError): Promise<void> {
        try {
            const { error_type_id } = item;
            if (error_type_id) {
                const paramsObj = { ...this.getDefaultFiltersParams, ...this.getFiltersParamsObject, error_type_id };
                await downloadErrorFile({ ...paramsObj });
            }
        } catch (err) {
            showServerError(err, 'Не удалось скачать выгрузку');
        }
        this.showModal = true;
    }

    hideGroupingModal(): void {
        for (const key in this.grouping) {
            if (key in this.grouping) {
                this.grouping[key] = '';
            }
        }
        this.groupingModal = false;
    }

    async saveGrouping(): Promise<void> {
        try {
            if (this.grouping.error_id) {
                await editErrorType(this.grouping.error_id, this.grouping);
            } else {
                await addErrorType(this.grouping);
            }
            this.hideGroupingModal();
            await this.getDataTable(0, this.limit);
        } catch (err) {
            showServerError(err);
        }
    }

    goTo(item: IStatsError): void {
        this.$router.push({ name: 'conversions', query: { offer_id: [String(item.offer_id)], status: 'rejected' } });
    }

    async downloadErrors(): Promise<void> {
        this.loadingDownload = true;
        try {
            const paramsObj = { ...this.getFiltersParamsObject, ...this.getSortFilters };
            await exportStatisticErrors({ ...paramsObj, format : 'csv' });
        } catch (err) {
            showServerError(err, 'Не удалось получить выгрузку по заявкам');
        }
        this.loadingDownload = false;
        this.showModal = true;
        this.canExport = false;
    }

    async downloadFile(item: IStatsError): Promise<void> {
        try {
            const { error, offer_id } = item;
            const paramsObj = { ...this.getFiltersParamsObject };
            await downloadFile({ ...paramsObj, error, offer_id: [offer_id] });
            this.showModal = true;
        } catch (err) {
            showServerError(err, 'Не удалось скачать выгрузку');
        }
    }
    beforeDestroy(): void {
        this.setFiltersParamsObject({});
        this.setDefaultFiltersParamsObject({});
    }
}
