
import { Component, ProvideReactive, Vue } from "vue-property-decorator";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import FormChargeRequest from "@/components/affiliate/charge-requests/FormChargeRequest.vue";
import { IColumnFilter } from "@/types";
import {
    ChargeRequestFilters,
    chargeRequestFiltersColumns,
} from "@/services/TablePage/chargeRequests";
import { getChargeRequests, getContractorsV2, postChargeRequests } from "@/api/payout";
import AppTableChargeRequests from "@/components/affiliate/charge-requests/AppTableChargeRequests.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import { namespace } from "vuex-class";
import { showServerError } from "@/utils";
const auth = namespace('authModule');

@Component({
    components: { TablePageWrapper, AppTableChargeRequests },
})
export default class ChargeRequests extends Vue {
    @auth.Getter('GET_USER') user;

    chargeRequestsTable = {
        component: AppTableChargeRequests,
        apiMethod: getChargeRequests,
        updateItemsMethod: postChargeRequests,
    };
    createChargeRequestForm = {
        component: FormChargeRequest,
    };
    filters = new ChargeRequestFilters();
    @ProvideReactive() contractors = [];

    async mounted(): Promise<void>{
        try {
            this.contractors = await getContractorsV2();
            this.filters.setContractors(this.contractors);
        } catch (err) {
            showServerError(err, 'Список способов выплат не загружен');
        }
    }

    get getChargeRequestFilterColumns(): IColumnFilter[] {
        return chargeRequestFiltersColumns;
    }

    get hasNeededRoles(): boolean {
        return this.user?.roles?.includes(USER_ROLES.SUPERVISOR) || this.user?.roles?.includes(USER_ROLES.ADMIN);
    }
}
