
import { Vue, Component, Prop } from "vue-property-decorator";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { translateOfferStatusApprovals } from "@/utils/translate";
import BaseCard from "@/components/base/BaseCard.vue";
import { ITableStatus } from "@/types";

@Component({
    components: { BaseCard, TableWithFrontPaginationDesign },
})

export default class ApprovalPageHistory extends Vue {
    @Prop ({ required: true }) history!: any[];

    headers = [
        { text: 'Дата изменения', sortable: false, value: 'changed_at', align: 'left' },
        { text: 'Кем изменено', sortable: false, value: 'changed_by' },
        { text: 'Новый статус', sortable: false, value: 'status', align: 'right' },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateOfferStatusApprovals,
        },
    ];

    timeData = [
        {
            slot: 'item.changed_at',
            key: 'changed_at',
        },
    ];
}

