
import { Vue, Component, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import { postOfferParams } from "@/api/offers";
import { showNotification, showServerError } from "@/utils";
import FormLogo from "@/components/offers/offer/products/FormLogo.vue";
import FormTextField from "@/components/offers/offer/products/FormTextField.vue";
import FormSelect from "@/components/offers/offer/products/FormSelect.vue";
import FormLink from "@/components/offers/offer/products/FormLink.vue";
import FormRange from "@/components/offers/offer/products/FormRange.vue";
import EditBtn from "@/components/base/buttons/EditBtn.vue";
import { cloneDeep } from "lodash-es";
import CancelBtn from "@/components/base/buttons/CancelBtn.vue";

@Component({
    components: { CancelBtn, EditBtn, BaseCard, FormLogo, FormTextField, FormSelect, FormLink, FormRange },
})

export default class OfferParamsForm extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @Prop({ default: () => [] }) products!: any;
    @Prop({ required: true }) productId!: number;

    isEdit = false;
    form: any[] = [];
    currentProduct: any = null;
    oldProductParams: any = {};

    get mainCardTitle(): string {
        return this.currentProduct?.product.name || this.offer?.products.find(i => i.id === this.productId)?.name || 'Главная';
    }

    checkNumberForOdd(number: number): boolean {
        return number % 2 !== 0;
    }

    async getForm(): Promise<void> {
        try {
            this.form = this.offer.offerParamsForms![this.productId];
        } catch (err) {
            showServerError(err, 'Параметры оффера не загружены');
        }
    }

    mounted(): void {
        if (!this.productId) return;
        this.getForm();

        // ранее заполненный/частично заполненный виджет
        const product = this.products.find(i=>i.product.id === this.productId);
        // Костыль, когда параметров нет, приходит пустой массив, когда есть, тогда объект.Нужно преобразовать в объект.
        if (product?.params && Array.isArray(product.params) && !product.params.length) product.params = {};

        this.currentProduct = product || { product: { id: this.productId }, params: {} as any };
        if (product) this.oldProductParams = cloneDeep(product.params);
    }

    async postOfferParams(): Promise<void> {
        try {
            if (JSON.stringify(this.oldProductParams) !== JSON.stringify(this.currentProduct.params)) {
                const productDTO = { product_id: this.currentProduct.product.id, params: this.currentProduct!.params };
                await postOfferParams(this.offer.id as number, productDTO);
                this.$emit('update');
                showNotification('Параметры оффера сохранены');
            }
            this.isEdit = !this.isEdit;
        } catch (err) {
            showServerError(err, 'Параметры оффера не загружены');
        }
    }

    editParams(): void {
        if (this.isEdit) {
            this.postOfferParams();
        } else {
            this.isEdit = !this.isEdit;
        }
    }

    cancel(): void {
        this.isEdit = false;
        this.currentProduct.params = JSON.parse(JSON.stringify(this.oldProductParams));
    }

    getComponent(type: 'file' | 'text' | 'url' | 'object' | 'select' | 'multi_select'): any {
        switch (type) {
        case 'file':
            return FormLogo;
        case 'text':
            return FormTextField;
        case 'object':
            return FormRange;
        case 'select':
        case 'multi_select':
            return FormSelect;
        case 'url':
            return FormLink;
        default:
            return FormTextField;
        }
    }
}
