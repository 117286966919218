
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import CardMaterial from '@/components/base/CardMaterial.vue';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import {
    addStageCondition,
    addStreamStage, editStageCondition,
    getStreamStagesList,
    patchStreamStage,
    updateSortedStages,
    deleteCondition,
    deleteStreamStage, getObligatoryStreamFields,
} from '@/api/uapi';
import { ICondition, IStage } from '@/api/types/uapi';
import { showServerError } from '@/utils';
import FormModal from '@/components/base/FormModal.vue';
import Stage from '@/components/uapi/streams/stages/Single.vue';
import Condition from '@/components/uapi/conditions/ConditionContent.vue';
import Conditions from '@/components/uapi/conditions/List.vue';

@Component({
    components: {
        FormModal,
        CardMaterial,
        SvgApplication,
        TooltipButton,
        Stage,
        Condition,
        Conditions,
    },
})
export default class Stages extends Vue {
    @Prop({ type: Boolean }) canEdit!: boolean;

    sortable = null;
    disableSortTable = true;
    dragItemIndex: null | number = null;
    sortableStages: IStage[] = [];
    totalElementDataTable = 0;
    stages: IStage[] = [];
    modal = false;
    stage: IStage = { notes: '' };
    stageId: number | null = null;
    loading = false;
    condition: ICondition = { field: '', value: '' };
    conditionModal = false;
    deletingModal = false;
    expanded: IStage[] = [];
    expand = false;
    offerFields: object = {};

    get headers(): DataTableHeader[] {
        const items: DataTableHeader[] = [
            {
                text: 'Номер',
                value: 'rank',
                align: 'center',
                width: '5%',
            },
            {
                text: 'Оффер',
                value: 'offer_id',
            },
            {
                text: 'Название оффера',
                value: 'offer_name',
            },
            {
                text: 'Примечания',
                value: 'notes',
            },
        ];
        if (this.disableSortTable) {
            items.push({
                text: 'Условия',
                value: 'data-table-expand',
            });
        }
        if (this.$props.canEdit) {
            items.push({
                text: '',
                value: 'actions',
            });
        }
        return items;
    }

    preventDefault(ev: Event): void {
        ev.preventDefault();
    }

    created(): void {
        this.getStages();
        this.getOfferFields();
    }

    enableSorting(): void {
        const table = this.$el.querySelector('table');
        const tbody = table!.querySelector('tbody');
        let trs = [...tbody!.querySelectorAll('tr')];
        // выбираем строки в таблице без вложенности
        trs = trs.filter((tr) => !tr.closest('.conditions-table'));

        tbody!.classList.add('drop-zone');
        tbody!.addEventListener('dragover', this.preventDefault);
        tbody!.addEventListener('drop', this.preventDefault);
        tbody!.addEventListener('dragenter', this.onDragEnter);
        trs.forEach((tr, index) => {
            tr.classList.add('drag-e');
            tr.draggable = true;
            tr.setAttribute('index', String(index));
            tr.addEventListener('dragstart', this.startDrag);
        });
    }

    disableSorting(): void {
        const table = this.$el.querySelector('table');
        const tbody = table!.querySelector('tbody');
        const trs = tbody!.querySelectorAll('tbody tr');

        tbody!.classList.remove('drop-zone');
        tbody!.removeEventListener('dragover', this.preventDefault);
        tbody!.removeEventListener('drop', this.preventDefault);
        tbody!.removeEventListener('dragenter', this.onDragEnter);

        trs.forEach((tr) => {
            tr.classList.remove('drag-e');
            (tr as HTMLElement).draggable = false;
            tr.setAttribute('index', '');
            (tr as HTMLElement).removeEventListener('dragstart', this.startDrag);
        });
    }

    sortStages(): void {
        this.disableSortTable = !this.disableSortTable;
        if (this.disableSortTable) {
            this.disableSorting();
            this.saveSortStages();
        } else {
            this.expanded = [];
            this.enableSorting();
        }
    }

    startDrag(event: DragEvent): void {
        if (this.disableSortTable) {
            return;
        }
        const index = (event.target! as Element).getAttribute('index');
        event.dataTransfer!.dropEffect = 'move';
        event.dataTransfer!.effectAllowed = 'move';
        event.dataTransfer!.setData('itemIndex', index!);
        this.dragItemIndex = Number(index);
    }

    onDragEnter(event: DragEvent): void {
        // узнаем над каким элементом находится перемещаемый элемент
        // обычно это <td>
        const targetElement = document.elementFromPoint(event.clientX, event.clientY);
        // находим строку в таблице, над которой находится перемещаемый элемент
        const targetTableRow = targetElement!.closest('tr');

        // находим индексы элементов в исходном массиве и меняем их местами
        const itemIndex = this.dragItemIndex;
        const targetItemIndex = Number(targetTableRow!.getAttribute('index'));

        // находим элементы от индекса
        const item = this.sortableStages[itemIndex!];
        const targetItem = this.sortableStages[targetItemIndex];

        // меняем местами
        this.$set(this.sortableStages, itemIndex!, targetItem);
        this.$set(this.sortableStages, targetItemIndex, item);
    }

    async getStages(): Promise<void> {
        this.loading = true;
        try {
            const { streamId } = this.$route.params;
            const { data, meta } = await getStreamStagesList(streamId);
            this.stages = data;
            this.sortableStages = data;
            this.totalElementDataTable = meta!.total!;
        } catch (err) {
            showServerError(err, 'Не удалось загрузить список стадий');
        }
        this.loading = false;
    }

    async saveSortStages(): Promise<void> {
        const sortedIds: number[] = this.sortableStages.map((stage) => stage.id!);
        try {
            await updateSortedStages(this.$route.params.streamId, { sort: sortedIds });
            await this.getStages();
        } catch (err) {
            this.sortableStages = [];
            showServerError(err, 'Не удалось обновить сортировку');
        }
    }

    toggleExpanded(item: IStage): void {
        const index = this.expanded.findIndex((expItem) => expItem.id === item.id);
        if (index === -1) {
            this.expanded.push(item);
        } else {
            this.expanded.splice(index, 1);
        }
    }

    showModal(stage: IStage): void {
        if (stage.id) {
            this.stage = stage;
        }
        this.modal = true;
    }

    get createConditionRouter(): {name: string, params: {[key: string]: string}} {
        const { streamId } = this.$route.params;
        return { name: 'create-stage-condition', params: { streamId, stageId: String(this.stageId!) } };
    }

    pushToEditConditionRoute(conditionId: string, stageId: string): void {
        const { streamId } = this.$route.params;
        this.$router.push({ name: 'edit-stage-condition', params: { streamId, stageId, conditionId } });
    }

    showAddStageCondition(stageId: number): void {
        this.stageId = stageId;
        this.condition = {
            field: '',
            value: '',
        };
    }

    showDeletingStageModal(stage: IStage): void {
        this.stageId = stage.id!;
        this.deletingModal = true;
    }

    async getOfferFields(): Promise<void> {
        try {
            const { streamId } = this.$route.params;
            this.offerFields = await getObligatoryStreamFields(streamId);
        } catch (err) {
            showServerError(err);
        }
    }
    async deleteStage(): Promise<void> {
        try {
            const { streamId } = this.$route.params;
            await deleteStreamStage(streamId, String(this.stageId));
            await this.getStages();
            await this.getOfferFields();
            this.deletingModal = false;
        } catch (err) {
            showServerError(err);
        }
    }

    async saveStage(): Promise<void> {
        try {
            const { streamId } = this.$route.params;
            if (this.stage.id) {
                // edit
                await patchStreamStage(streamId, String(this.stage.id), this.stage);
            } else {
                await addStreamStage(streamId, this.stage);
            }
            await this.getOfferFields();
            this.clearStage();
            await this.getStages();
        } catch (err) {
            showServerError(err);
        }
        this.modal = false;
    }

    clearStage(): void {
        this.stage = {
            notes: '',
        };
    }

    async saveStageCondition(condition: ICondition, stageId = this.stageId): Promise<void> {
        if (condition) {
            this.condition = condition;
        }
        try {
            const { streamId } = this.$route.params;
            if (this.condition.id) {
                await editStageCondition(streamId, String(stageId!), this.condition.id, this.condition);
            } else {
                await addStageCondition(streamId, String(stageId!), this.condition);
            }
            await this.getStages();
        } catch (err) {
            showServerError(err);
        }
    }

    async deleteCondition(conditionId: number): Promise<void> {
        try {
            await deleteCondition(conditionId);
            await this.getStages();
        } catch (err) {
            showServerError(err, 'Не удалось удалить условие');
        }
    }
}
