
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IStatus } from "@/types";

@Component({})

export default class Status extends Vue {
    @Prop({ required: false }) status!: IStatus;
    @Prop({ required: false, default: '' }) text!: string;
    @Prop({ required: false, default: false }) close!: boolean;
    @Prop({ required: false, default: false }) large!: boolean;
    @Prop({ required: false, default: '' }) color!: string;

    remove(): void {
        this.$emit('remove');
    }
}
