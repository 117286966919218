
import { Component, Mixins } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import SvgOfferList from '@/assets/icons/nav-bar/offer-list.svg';
import { IFilter } from '@/types';
import { showServerError } from '@/utils';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { getAffiliateSearch, getActiveUserSearch } from '@/api/user';
import { getCurrentMonth, getFormatDatesForDatepicker } from '@/utils/formatDates';
import ItemTableList from '@/components/base/TableUI/ItemTableList.vue';
import Status from '@/components/base/Status.vue';
import TableWithFrontPagination from "@/components/base/table/TableWithFrontPagination.vue";
import Filters from '@/components/base/filters/Filters.vue';
import { getCashbackEcom } from "@/api/campaigns";
import AppTable from "@/components/base/table/AppTable.vue";
import TableMixin from "@/mixins/table";
import { filterClass } from '@/services/filters/filterClass';

@Component({
    components: {
        AppTable,
        Status,
        SvgOfferList,
        ItemTableList,
        TableWithFrontPagination,
        Filters,
    },
})
export default class CashbackEcom extends Mixins(TableMixin) {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;

    // для отображения роутов в таблице
    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'affiliate_id',
            slot: 'item.affiliate_id',
            routerName: 'cashback-ecom-detail',
            text: 'affiliate_id',
        },
    ];
    paginationItemsPerPage = PAGINATION_PER_PAGE || 100;
    filters: IFilter[] = [
        new filterClass.Autocomplete({
            id: 'affiliate_id',
            label: 'Партнёр',
            apiMethod: getAffiliateSearch,
            itemValue: 'id',
            itemText: 'name',
            select: '',
            items: [],
            multiple: false,
            remove: true,
            isSubmitEmptySelect: false,
        }),
        new filterClass.Autocomplete({
            id: 'manager_id',
            apiMethod: getActiveUserSearch,
            label: 'Менеджер партнёра',
            itemValue: 'id',
            itemText: 'name',
            select: '',
            items: [],
            multiple: false,
            remove: true,
            isSubmitEmptySelect: false,
        }),
        new filterClass.Datepicker({
            id: 'month',
            label: 'Месяц',
            range: false,
            pickerType: 'month',
            select: getCurrentMonth('yyyy-MM-dd'),
            defaultValue: getCurrentMonth('yyyy-MM-dd'),
        }),
    ];
    headers = [
        { text: 'ID партнёра', sortable: false, value: 'affiliate_id', align: 'center'  },
        { text: 'ФИО партнёра', sortable: false, value: 'affiliate_name' },
        { text: 'Менеджер партнёра', sortable: false, value: 'manager_name' },
        { text: 'Месяц', sortable: false, value: 'month',  width: '100px'  },
        { text: 'План', sortable: false, value: 'payout_current',align: 'right' },
        { text: 'Выполнение', sortable: false, value: 'payout_goal', align: 'right'  },
        { text: 'Сумма прироста', sortable: false, value: 'payout_growth', align: 'right'  },
        { text: 'Гарантированный кэшбек', sortable: false, value: 'guaranteed_amount', align: 'right'  },
        { text: 'Кэшбек от прироста', sortable: false, value: 'payout_goal', align: 'right'  },
        { text: 'Общий кэшбек', sortable: false, value: 'total_amount', align: 'right' },
    ];
    settings = { title: 'Суперкэшбек на eCom', loading: true };
    uniqueData = [
        {
            slot: 'item.manager_name',
            template: (item: any): string => `${item.manager_id} ${item.manager_name}`,
        },
    ];
    numberFormatting = [
        { slot: 'item.payout_current', key: 'payout_current' },
        { slot: 'item.payout_goal', key: 'payout_goal' },
        { slot: 'item.payout_growth', key: 'payout_growth' },
        { slot: 'item.guaranteed_amount', key: 'guaranteed_amount' },
        { slot: 'item.payout_goal', key: 'payout_goal' },
        { slot: 'item.total_amount', key: 'total_amount' },
    ];
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    apiMethod = getCashbackEcom;

    getTemplate(item: any): string {
        return item.name || item.code || item.master.id;
    }

    async created(): Promise<void> {
        this.setFiltersParamsObject({});
        const activeFilters: object[] = [];

        this.filters.forEach((filter) => {
            if (filter.select && filter.select!.length > 0) {
                activeFilters.push({
                    [filter.id]: filter.select,
                });
            }
        });

        const paramsObj = Object.assign({}, ...activeFilters);
        this.setDefaultFiltersParams(paramsObj);
    }

    mounted(): void {
        this.getCashBack();
    }

    async getCashBack(offset?: number): Promise<void> {
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Суперкэшбек на eCom не загружен');
        }
    }

    goToDetailPage(evt: any): void {
        this.$router.push({ name: 'cashback-ecom-detail', params: { id: evt.affiliate_id } });
    }
}
