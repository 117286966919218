
import { Component, Mixins } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import TableMixin from '@/mixins/table';
import { showServerError } from '@/utils';
import ListLayout from "@/components/layout/ListLayout.vue";
import FormCreateAccess from '@/components/orgstats/FormCreateAccess.vue';
import FiltersDialog from "@/components/base/filters/FiltersDialog.vue";
import FiltersDesign from "@/components/base/filters/FiltersDesign.vue";
import {
    AccessesFilters,
    accessesHeaders,
    routes,
    statuses,
    uniqueDate,
} from "@/services/TablePage/employeesAccesses";
import { deleteOrganizationsFromUser, getUsersWithOrganizations } from "@/api/org-cabinets-backend";
import { getLegalEntities } from "@/api/revenue";
import { ILegalEntityOrg } from "@/api/types/revenue";
import FilterGuid from "@/mixins/filterGuid";
import { getLegalEntitySearchGuid } from "@/api/orgstats";
import CreateBtn from "@/components/base/buttons/CreateBtn.vue";

@Component({
    components: {
        CreateBtn,
        FiltersDesign, FiltersDialog,
        ListLayout,
        FormCreateAccess,
    },
})
export default class Accesses extends Mixins(TableMixin, FilterGuid) {
    @Getter('GET_ENV_MODE') getEnvMode;
    @Mutation('SET_TOTAL_ELEMENTS_DATA_TABLE') setTotalElementsDataTable;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;

    isNewDesignPage = true;
    headers = accessesHeaders;
    apiMethod = getUsersWithOrganizations;
    settings = {
        title: 'Доступы',
        loading: false,
    };
    isShowModal = false;
    isShowFilters = false;
    filters = new AccessesFilters();
    uniqueData = uniqueDate;
    statuses = statuses;
    routes = routes;
    dropDownItems = [
        {
            slot: 'item.org_names',
            text: 'org_names',
            readonly: true,
            remove: (item: any, org: string): Promise<void> => this.deleteAccesses(item, org),
        },
    ];
    actions = [
        {
            title: 'Удалить все доступы',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: true,
            disabled: (item: any): boolean => item.org_guids?.length <= 0,
            callback: (item: any): Promise<void> => this.deleteAccesses(item),
        },
    ];
    legalEntities: ILegalEntityOrg[] = [];
    isParseRouteUrl = false;

    async mounted(): Promise<void> {
        this.isParseRouteUrl = !!(Object.keys(this.$route.query).length > 0 && this.$route.query.org_guids);
        await this.getLegalEntities();
        await this.getUsers();
    }

    deleteOrg(item: any, org: string): void {
        const index = item.org_names.findIndex(i => i === org);
        item.org_names.splice(index, 1);
        item.org_guids.splice(index, 1);
    }

    async deleteAccesses(item: any, org?: string): Promise<void> {
        try {
            const org_guids = org ? [this.legalEntities.find(i=> i.name === org)?.external_id || org] : item.org_guids;
            await deleteOrganizationsFromUser(item.account_id as number, item.account_type, org_guids);

            if (org) await this.deleteOrg(item,org);
            else await this.getUsers();

        } catch (err) {
            showServerError(err, 'Организация не удалена');
        }
    }

    async getLegalEntities(): Promise<void> {
        try {
            const { data } = await getLegalEntities({ limit: 500 });
            this.legalEntities = data;
        } catch (err) {
            showServerError(err, 'Организации не загружены');
        }
    }

    async getUsers(offset?: number): Promise<void> {
        try {
            if (this.isParseRouteUrl) {
                await this.getFilterGuidItems(this.filters.filters, 'org_guids', getLegalEntitySearchGuid);
            }
            await this.getTableData(offset);
            this.dataTable.forEach( item => {
                const names = item.org_guids.map( guid => (this.legalEntities.find(i => i.external_id === guid)?.name) || guid);
                return this.$set(item, 'org_names', names);
            });
        } catch (err) {
            showServerError(err, 'Доступы не загружен');
        } finally {
            this.settings.loading = false;
            this.isShowFilters = false;
        }
    }
}
