import { IColumnFilter, IFilter } from "@/types";
import { getAffiliateSearch, getActiveUserSearch } from "@/api/user";
import { getEmployeesOffersV2 } from "@/api/offers";
import { getAdvertisersSearch } from "@/api/revenue";
import { translateOfferStatusApprovals } from "@/utils/translate";
import { CreatedColumns } from "@/services/columns/columns";
import { CreatedFilters } from "@/services/filters/filters";
import store from "@/store";
import router from "@/router";
import { filterClass } from '@/services/filters/filterClass';

export class ApprovalsFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        const offer = store.getters['offersModule/GET_CURRENT_OFFER'];
        const affiliate = store.getters['GET_CURRENT_AFFILIATE'];
        return [
            new filterClass.Multiselect({
                id: 'status',
                label: 'Статус запроса',
                type: 'string',
                select: (offer && this.checkRoute('offer')) || (affiliate && this.checkRoute('affiliate')) ? [] : ['pending', 'paused'],
                items: [{ id: 'approved' }, { id: 'pending' }, { id: 'paused' }, { id: 'rejected' }, { id: 'expired' }],
                template: ({ id }) => this.translateStatus(id, translateOfferStatusApprovals),
                itemText: ({ id }) => this.translateStatus(id, translateOfferStatusApprovals),
                itemValue: 'id',
                defaultValue: (offer && this.checkRoute('offer')) || (affiliate && this.checkRoute('affiliate')) ? [] : ['pending', 'paused'],
                noDataText: 'Нет статусов',
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_id',
                apiMethod: getAffiliateSearch,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                select: affiliate && this.checkRoute('affiliate') ? [affiliate] : [],
                defaultValue: affiliate && this.checkRoute('affiliate') ? [affiliate] : [],
                items: [],
                multiple: true,
                multiPaste: true,
                isHidden: this.checkRoute('affiliate'),
            }),
            new filterClass.Autocomplete({
                id: 'offer_legacy_id',
                apiMethod: getEmployeesOffersV2,
                key: 'search',
                label: 'Оффер',
                itemValue: 'legacy_id',
                itemText: 'name',
                select: offer && this.checkRoute('offer') ? [offer] : [],
                defaultValue: offer && this.checkRoute('offer') ? [offer] : [],
                items: [],
                multiple: true,
                multiPaste: true,
                supValue: 'legacy_id',
                isHidden: this.checkRoute('offer'),
            }),
            new filterClass.Autocomplete({
                id: 'advertiser_manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер рекламодателя',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер партнера',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
            }),
            new filterClass.Autocomplete({
                id: 'advertiser_legacy_id',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
            }),
        ];
    }
}

export class ApprovalsColumns extends CreatedColumns {
    constructor() {
        super();
    }

    get getColumnsForAffiliateDetailPage(): IColumnFilter[] {
        if (store.state.isMasterAffiliate)  return this.columns;
        return this.columns.filter(group => group.id !== 'affiliate');
    }

    get isDetailOffer(): boolean {
        return router.currentRoute.name === 'offer';
    }

    getColumns(): IColumnFilter[] {
        const columns = [
            {
                id: 'offer',
                value: false,
                name: 'Оффер',
                items: [
                    { title: 'Рекл-тель', value: 'offer.advertiser.name' },
                    { title: 'ID оффера', value: 'offer.legacy_id' },
                    { title: 'Название оффера', value: 'offer.name' },
                    { title: 'Статус', value: 'offer.status' },
                    { title: 'Требует согласования', value: 'offer.confirmations.requires_advertiser_approval' },
                    { title: 'Менеджер рекламодателя', value: 'offer.advertiser.manager.name' },
                ],
            },
            {
                id: 'affiliate',
                value: false,
                name: 'Партнёр',
                items: [
                    { title: 'ID партнёра', value: 'affiliate.legacy_id' },
                    { title: 'ФИО', value: 'affiliate.name' },
                    { title: 'Менеджер партнера', value: 'affiliate.manager.name' },
                ],
            },
        ];
        return this.isDetailOffer ? columns.filter(group => group.id !== 'offer') : columns;
    }
}
